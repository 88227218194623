import { FC, ReactNode } from 'react';

const MobileFramePreview: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={'inline-block rounded-3xl border-2 border-solid border-black p-6 pt-0'}>
      <div className={'mx-auto mt-6 h-[20px] w-[70px] rounded-2xl border-2 border-solid border-black'}></div>
      <div className="no-scrollbar relative m-auto mt-6 h-[667px] w-screen max-w-[375px] overflow-y-auto border-2 border-solid border-black mobile-frame-preview">
        {children}
      </div>
      <div className={'mx-auto mt-6 h-[45px] w-[45px] rounded-2xl border-2 border-solid border-black'}></div>
    </div>
  );
};

export default MobileFramePreview;
