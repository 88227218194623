import happyEmoji from '@assets/img/happy-emoji-animated.gif';
import Heading from '@components/UI/Heading';
import MyModal from '@components/UI/MyModal';
import { ROUTES } from '@constants/routes';
import { Button } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ClientOfferModalProps {
  isOpened: boolean;
  title: string;
  content: string;
  setIsPromotionDrawerOpened: Dispatch<SetStateAction<boolean>>;
}

const ClientPromotionModal: FC<ClientOfferModalProps> = ({ isOpened, title, content, setIsPromotionDrawerOpened }) => {
  const { t } = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState(isOpened);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsModalOpened(false);
    navigate(`${ROUTES.root}`);
  };

  const handleClick = () => {
    setIsModalOpened(false);
    setIsPromotionDrawerOpened(true);
    navigate(`${ROUTES.root}`);
  };

  return (
    <MyModal
      wrapClassName={'client-offer-modal'}
      width={'90%'}
      className={'top-0 max-w-[560px]'}
      centered={false}
      getContainer={false}
      open={isModalOpened}
      onCancel={handleClose}
      maskClosable={false}
      footer={
        <Button type={'primary'} onClick={handleClick}>
          {t('i_want')}
        </Button>
      }
    >
      <div className={'mt-6 text-center'}>
        <img className={'mb-2 h-[52px] w-[52px]'} src={happyEmoji} alt="" />
        <Heading>{title}</Heading>
        {content}
      </div>
    </MyModal>
  );
};

export default ClientPromotionModal;
