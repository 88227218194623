import AccountSettings from '@components/AccountSettings';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const AccountSettingsPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.dashboard'));

  return <AccountSettings />;
};

export default AccountSettingsPage;
