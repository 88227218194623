import DishOption from '@api/models/DishOption';
import { useGetDishTypesQuery } from '@api/publicApi';
import { useLazyGetRestaurantInfoByIdQuery } from '@api/restaurantInfoApi';
import {
  setActiveTabDishModal,
  setDishImage,
  setDishInfo,
  setDishParentCategoryId,
  setIsModalDishOpen,
} from '@base/redux/features/menuSlice';
import { setRestTakeAway } from '@base/redux/features/restaurantSettingsSlice';
import { useAppSelector } from '@base/redux/store';
import DishForm from '@components/Menus/DishForm';
import useMenuDishModal from '@components/Menus/MenuDishModal/useMenuDishModal';
import MyModal from '@components/UI/MyModal';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const OPTIONS_NAME_FIELD = 'options';
export const EXTRAS_NAME_FIELD = 'extras';

const MenuDishModal: FC<{ isOpened: boolean }> = ({ isOpened }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [isFormInit, setIsFormInit] = useState(false);

  const [isErrorInput, setIsErrorInput] = useState(false);

  // last saved dish form values (needed to compare with current form changes)
  const [savedFormValues, setSavedFormValues] = useState('');

  const restID = useAppSelector((state) => state.restaurantSettingsState.id);
  const parentMenuId = useAppSelector((state) => state.menuSlice.dishParentCategoryId);
  const { dishInfo } = useAppSelector((state) => state.menuSlice);

  const { data: dishTypeList } = useGetDishTypesQuery();
  const [getRestInfo, { data: restInfo, isFetching, isError }] = useLazyGetRestaurantInfoByIdQuery();
  const { isOpenModal, setIsOpenModal, update, create, isLoading } = useMenuDishModal(isOpened);

  const dishId = dishInfo?.id;

  useEffect(() => {
    getRestInfo(restID);
  }, [dishInfo]);

  useEffect(() => {
    if (isError) {
      console.error('error of getting restaurant take away');
    }
  }, [isError]);

  // create dish
  useEffect(() => {
    form.setFieldValue('menu_id', parentMenuId);
    setIsFormInit(true);
  }, [parentMenuId]);

  // edit dish
  useEffect(() => {
    if (dishInfo?.id && restInfo) {
      const isRestTakeAway = restInfo?.is_take_away;

      form.setFieldValue('id', dishInfo.id);
      form.setFieldValue('menu_id', dishInfo?.menu.id);
      form.setFieldValue('dish_name', dishInfo?.dish_name);
      form.setFieldValue('description', dishInfo?.description);
      form.setFieldValue('price', dishInfo?.price);
      form.setFieldValue('is_portion_available', !!dishInfo?.is_portion_available);
      form.setFieldValue('is_take_away', isRestTakeAway ? !!dishInfo?.is_take_away : false);
      form.setFieldValue(
        'dish_type_id',
        dishInfo?.dish_type?.id
          ? dishInfo?.dish_type?.id
          : dishTypeList?.find((dishType) => dishType.code === 'no_type')?.id
      );

      form.setFieldValue('allergies', dishInfo?.allergies.map((allergy) => allergy.id));
      form.setFieldValue('updated_at', dishInfo?.updated_at);
      form.setFieldValue('inventory_quantity', dishInfo.inventory_quantity);
      form.setFieldValue('is_inventory_unlimited', dishInfo.is_inventory_unlimited);

      form.setFieldValue(OPTIONS_NAME_FIELD, dishInfo.options || []);
      form.setFieldValue(EXTRAS_NAME_FIELD, dishInfo.extras || []);

      dispatch(setRestTakeAway(isRestTakeAway)); // for Take away Switch in DishForm

      setIsFormInit(true);
    } else {
      form.setFieldValue('dish_type_id', dishTypeList?.find((dishType) => dishType.code === 'no_type')?.id);
    }

    setSavedFormValues(JSON.stringify(form.getFieldsValue()));
  }, [dishInfo, isFetching]);

  const onFinish = async (value: any) => {
    // change description to null if value is just === ''
    if (value.description !== null && value.description?.trim() === '') {
      value.description = null;
    }

    // remove temp_id keys from object
    const currentFormValues = JSON.stringify(value, (key, value) => (key === 'temp_id' ? undefined : value));

    // check if form was changed, if no => do not send req to backend
    if (savedFormValues === currentFormValues) {
      return;
    }

    // rewrite options values to null if [] or undefined for server
    if (value.options && value.options.length > 0) {
      value.options = value.options.map((option: DishOption) =>
        option.values && option.values.length > 0 ? option : { ...option, values: null }
      );
    }

    if (value.image) {
      value.image = value.image.file.originFileObj;
    }

    if (value.is_inventory_unlimited && !value.inventory_quantity) {
      value.inventory_quantity = 0;
    }

    if (value.is_inventory_unlimited === undefined) {
      value.is_inventory_unlimited = false;
    }

    if (value.id) {
      // remove options and extras if update dish
      const { image, options, extras, ...restValue } = value;
      await update(value.id, restValue, image);
    } else {
      await create(value);
    }
  };

  const onFinishFailed = (values: any) => {
    const tabsPopupElement = document.getElementsByClassName('dish-form-tabs')?.item(0);
    const errorDishTabKeys: string[] = [];

    // set error state true (needed to render the error text under the submit button)
    setIsErrorInput(true);

    // remove all attributes to clear the error state for all tabs
    document.querySelectorAll(`.dish-form-tabs .ant-tabs-tab`)?.forEach((item) => {
      item.removeAttribute('data-error');
    });

    // get all names of error inputs
    const errorList: string[] = values.errorFields.map((item: any) => {
      // if error.name array length > 1 => join all elements with '_' character and return as a name of input error
      if (item.name.length > 1) {
        return item.name.join('_');
      }

      // return the name of the error input
      return item.name[0];
    });

    if (tabsPopupElement) {
      errorList.forEach((errorInputId) => {
        // get invalid input that matches the error name as id
        const errorInputElement = document.getElementById(errorInputId);

        if (errorInputElement) {
          // get the closest parent element with the specified data-tab-key attribute
          const errorDishTabKey = errorInputElement.closest('.dish-tab-wrapper')?.getAttribute('data-tab-key');

          // push unique data-tab-key to error tab keys array
          if (errorDishTabKey && !errorDishTabKeys.includes(errorDishTabKey)) {
            errorDishTabKeys.push(errorDishTabKey);
          }
        }
      });

      // find all tabs by error tab keys and add data attribute to them (data-error)
      // it is not recommended to add a class, as they are overwritten when navigating between tabs
      if (errorDishTabKeys.length > 0) {
        errorDishTabKeys.forEach((errorDishTabKey) => {
          document.querySelector(`.ant-tabs-tab[data-node-key="${errorDishTabKey}"]`)?.setAttribute('data-error', '1');
        });
      }
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleAfterClose = async () => {
    if (!isOpenModal) {
      // reset form
      await form.resetFields();
      await form.setFieldValue(OPTIONS_NAME_FIELD, []);
      await form.setFieldValue(EXTRAS_NAME_FIELD, []);
      await dispatch(setDishInfo(null));
      await dispatch(setDishParentCategoryId(null));
      await dispatch(setActiveTabDishModal(null));
      await dispatch(setDishImage(null));

      dispatch(setIsModalDishOpen(false));
    }
  };

  return (
    <MyModal
      title={dishId ? t('edit_dish') : t('add_dish')}
      open={isOpenModal}
      afterClose={handleAfterClose}
      width={960}
      footer={
        <>
          <Button type={'primary'} onClick={form.submit} loading={isLoading}>
            {t('save')}
          </Button>
          {isErrorInput && <div className={'mt-2 text-red-30'}>{t('error_in_one_of_the_fields')}</div>}
        </>
      }
      onCancel={handleClose}
    >
      <Form form={form} layout={'vertical'} datatype={'formData'} onFinishFailed={onFinishFailed} onFinish={onFinish}>
        {isFormInit && <DishForm />}
      </Form>
    </MyModal>
  );
};

export default MenuDishModal;
