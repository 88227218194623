import { SVGProps } from 'react';
const PackageFreeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={65} height={61} viewBox="0 0 65 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M59.5823 56.3235C53.0254 62.5713 42.3774 62.5713 35.7925 56.3235L5.41769 27.3541C-1.13923 21.1063 -1.13923 10.9603 5.41769 4.68584C11.9746 -1.56195 22.6226 -1.56195 29.2075 4.68584L59.5823 33.6286C66.1392 39.903 66.1392 50.049 59.5823 56.3235Z"
      fill="url(#paint0_linear_9021_67755)"
    />
    <path
      opacity={0.3}
      d="M5.41769 56.3215C-1.13923 50.0709 -1.13923 39.9205 5.41769 33.6433L35.7925 4.68789C42.3494 -1.56263 52.9974 -1.56263 59.5823 4.68789C66.1392 10.9384 66.1392 21.0888 59.5823 27.366L29.2075 56.3215C22.6226 62.572 11.9746 62.572 5.41769 56.3215Z"
      fill="url(#paint1_linear_9021_67755)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9021_67755"
        x1={38.0547}
        y1={3.57715}
        x2={27.9535}
        y2={57.6168}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9021_67755"
        x1={60.7479}
        y1={35.7762}
        x2={4.43738}
        y2={24.1881}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default PackageFreeLogo;
