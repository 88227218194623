import useWorkerSwitcher from '@components/Workers/WorkerSwitcher/useWorkerSwitcher';
import { Switch } from 'antd';
import { FC } from 'react';

interface WorkerSwitcherProps {
  id: number;
  isActive: boolean;
  disabled?: boolean;
}

const WorkerSwitcher: FC<WorkerSwitcherProps> = ({ id, isActive, disabled = false }) => {
  const { isChecked, isLoading, handleSwitch } = useWorkerSwitcher(isActive);

  return (
    <Switch
      defaultChecked={isChecked}
      checked={isChecked}
      onChange={(checked: boolean) => handleSwitch(id, checked)}
      disabled={disabled}
      loading={isLoading}
    />
  );
};

export default WorkerSwitcher;
