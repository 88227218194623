import { SVGProps } from 'react';
const PackageBasicLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={65} height={61} viewBox="0 0 65 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M59.5823 56.3235C53.0254 62.5713 42.3774 62.5713 35.7925 56.3235L5.41769 27.3541C-1.13923 21.1063 -1.13923 10.9603 5.41769 4.68584C11.9746 -1.56195 22.6226 -1.56195 29.2075 4.68584L59.5823 33.6286C66.1392 39.903 66.1392 50.049 59.5823 56.3235Z"
      fill="url(#paint0_linear_9021_67746)"
    />
    <path
      opacity={0.3}
      d="M5.41769 56.3215C-1.13923 50.0709 -1.13923 39.9205 5.41769 33.6433L35.7925 4.68789C42.3494 -1.56263 52.9974 -1.56263 59.5823 4.68789C66.1392 10.9384 66.1392 21.0888 59.5823 27.366L29.2075 56.3215C22.6226 62.572 11.9746 62.572 5.41769 56.3215Z"
      fill="url(#paint1_linear_9021_67746)"
    />
    <path
      d="M33.5802 22.8613L35.5292 26.8132C35.5866 26.9308 35.6721 27.0319 35.778 27.1087C35.8839 27.1855 36.0063 27.2361 36.1355 27.2545L40.4931 27.8835C40.6408 27.9059 40.7788 27.9691 40.8925 28.0663C41.0061 28.1625 41.0907 28.2889 41.1373 28.4309C41.1839 28.5728 41.1898 28.7245 41.1548 28.8703C41.1198 29.0151 41.045 29.1483 40.9391 29.2533L37.7901 32.3283C37.6969 32.4197 37.6269 32.5325 37.5861 32.6569C37.5463 32.7813 37.5366 32.9135 37.5589 33.0419L38.3031 37.3846C38.3284 37.5333 38.3119 37.6859 38.2555 37.825C38.1992 37.965 38.104 38.0855 37.9825 38.174C37.8611 38.2624 37.7163 38.3149 37.5667 38.3256C37.4161 38.3363 37.2665 38.3052 37.1333 38.2342L33.2363 36.1791C33.1206 36.1188 32.9914 36.0867 32.8612 36.0867C32.731 36.0867 32.6018 36.1188 32.4862 36.1791L28.5891 38.2342C28.456 38.3042 28.3064 38.3354 28.1558 38.3247C28.0062 38.314 27.8624 38.2605 27.7409 38.172C27.6195 38.0836 27.5252 37.963 27.4679 37.823C27.4115 37.684 27.395 37.5314 27.4203 37.3826L28.1636 33.0399C28.1859 32.9106 28.1762 32.7784 28.1364 32.6549C28.0956 32.5305 28.0256 32.4177 27.9323 32.3264L24.7834 29.2523C24.6775 29.1473 24.6027 29.0142 24.5677 28.8693C24.5327 28.7245 24.5385 28.5718 24.5852 28.4299C24.6318 28.2879 24.7163 28.1616 24.83 28.0653C24.9437 27.9691 25.0826 27.9049 25.2293 27.8826L29.587 27.2497C29.7162 27.2312 29.8386 27.1806 29.9445 27.1038C30.0504 27.027 30.1359 26.9259 30.1932 26.8083L32.1432 22.8574C32.2112 22.7252 32.3142 22.6134 32.4415 22.5366C32.5678 22.4578 32.7145 22.418 32.8632 22.418C33.0118 22.418 33.1576 22.4598 33.2848 22.5385C33.4112 22.6163 33.5141 22.7281 33.5812 22.8613H33.5802Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9021_67746"
        x1={38.0547}
        y1={3.57715}
        x2={27.9535}
        y2={57.6168}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9021_67746"
        x1={60.7479}
        y1={35.7762}
        x2={4.43738}
        y2={24.1881}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default PackageBasicLogo;
