import { useSendPromotionMutation } from '@api/promotionApi';
import ConfirmModalContent from '@components/ConfirmModalContent';
import MBAlertCircleIcon from '@components/icons/MBAlertCircleIcon';
import PromotionCardSwitcher from '@components/Promotions/PromotionCard/PromotionCardSwitcher';
import usePromotionCard from '@components/Promotions/PromotionCard/usePromotionCard';
import { HandleEditProps } from '@components/Promotions/usePromotions';
import EditIconButton from '@components/UI/EditIconButton';
import Heading from '@components/UI/Heading';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { App, Button, Card, Tooltip } from 'antd';
import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface NotificationCardProps {
  form: any;
  id: number;
  title: string;
  description?: string;
  modal: any;
  is_active: boolean;
  setIsOpen: (value: boolean) => void;
  setFormValues: (data: HandleEditProps) => void;
  isPromotionAvailable: boolean;
}

const PromotionCard: FC<NotificationCardProps> = ({
  id,
  title,
  description = '',
  is_active,
  isPromotionAvailable,
  modal,
  setIsOpen,
  setFormValues,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const { showModalConfirm, remove, getPromotionDetails, promotionDetails, isLoading, isFetching, isSuccess, isError } =
    usePromotionCard();
  const [send, { isLoading: isSending }] = useSendPromotionMutation();

  const isButtonDisabled = !is_active || !isPromotionAvailable;

  const handleOpenModal = async () => {
    await getPromotionDetails(id);
  };

  const handleRemove = (id: number) => {
    showModalConfirm({
      modal,
      title: t('deleting'),
      content: <ConfirmModalContent title={t('do_you_want_delete_promotion')} />,
      okCallback: () => remove(id),
    });
  };

  const handleSend = () => {
    send(id);
  };

  useEffect(() => {
    if (!isFetching && isSuccess && promotionDetails) {
      const dish = promotionDetails.dish;

      setFormValues({
        id,
        name: promotionDetails.name,
        description: promotionDetails.description,
        old_price: promotionDetails.old_price,
        new_price: promotionDetails.new_price,
        promotion_type_id: promotionDetails.promotion_type.id,
        dish_id: dish?.id,
        dish_price: dish?.price,
        options: dish?.options || [],
        extras: dish?.extras || [],
        selected_options: promotionDetails.selected_options,
        selected_extras: promotionDetails.selected_extras,
      });

      setIsOpen(true);
    }

    if (isError) {
      message.error(t('error'));
    }
  }, [isFetching, isError]);

  return (
    <>
      <Card className={'w-full p-2 pb-4'} bodyStyle={{ padding: 0 }}>
        <header className={'mb-4 flex items-center justify-between rounded bg-bg-input px-2'}>
          <div>
            <RemoveIconButton onClick={() => handleRemove(id)} />
            <EditIconButton onClick={handleOpenModal} loading={isLoading} />
          </div>
          <div className={'flex items-center gap-2'}>
            {!isPromotionAvailable && (
              <Tooltip title={t('dish_is_out_of_stock_or_unavailable')}>
                <MBAlertCircleIcon className={'flex-shrink-0 text-orange-30'} />
              </Tooltip>
            )}
            <PromotionCardSwitcher id={id} is_active={is_active} disabled={!isPromotionAvailable} />
          </div>
        </header>
        <div className={'text-center'}>
          <Heading>{title}</Heading>
          {description && <p>{description}</p>}

          <Button
            type={'primary'}
            className={'mt-3'}
            disabled={isButtonDisabled}
            onClick={handleSend}
            loading={isSending}
          >
            {t('send')}
          </Button>
          {/* TODO: server has to return promotions with dish information to finish this feature (if dish of promotions is over, dish has to have disable state) */}
          {/* {promotionDetails && promotionDetails.dish ? (
            <Button
              type={'primary'}
              className={'mt-3'}
              disabled={isButtonDisabled}
              onClick={handleSend}
              loading={isSending}
            >
              {t('send')}
            </Button>
          ) : (
            <Button className={'mt-3'} disabled={isButtonDisabled} onClick={handleOpenModal} loading={isLoading}>
              {t('complete_promotion')}
            </Button>
          )}*/}
        </div>
      </Card>
    </>
  );
};

export default memo(PromotionCard);
