import { setIsUnauthorized } from '@base/redux/features/userSlice';
import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

export const authInterceptor: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.error && action.payload.status === 401) {
      api.dispatch(setIsUnauthorized(true));
    }
  }

  return next(action);
};
