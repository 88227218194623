import DishOptionValue from '@api/models/DishOptionValue';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import usePromotionModalForm from '@components/Promotions/PromotionModalForm/usePromotionModalForm';
import { DISH_ID_NAME_FIELD } from '@components/Promotions/Promotions';
import {
  DISH_PRICE_NAME_FIELD,
  HandleEditProps,
  OLD_PROMOTION_PRICE_NAME_FIELD,
} from '@components/Promotions/usePromotions';
import { CURRENCY } from '@constants/constants';
import { regExPrice } from '@constants/regexp';
import { Checkbox, Form, Input, Radio, Switch, TreeSelect } from 'antd';
import { FC, memo, useEffect, useMemo } from 'react';

interface NotificationFormFieldType {
  id?: number;
  name: string;
  description: string;
  dish_id: number;
  dish_price: number;
  old_price: number;
  new_price: number;
  promotion_type_id: number;
}

interface NotificationFormProps {
  form: any;
  onFinish: (values: any) => void;
  setFormValues: (data: HandleEditProps) => Promise<void>;
}

const CategoryTitle = ({ name }: { name: string }) => (
  <div className={'overflow-x-hidden text-center'}>
    <div className={'dish-three-title inline-block px-2 text-center font-bold text-indigo-40'}>{name}</div>
  </div>
);

const SubcategoryTitle = ({ name }: { name: string }) => (
  <div className={'w-full bg-indigo-15 px-2 text-sm font-bold text-indigo-40'}>{name}</div>
);

const PromotionModalForm: FC<NotificationFormProps> = ({ form, onFinish, setFormValues }) => {
  const {
    t,
    menuDishList,
    isFetching,
    isError,
    getDishById,
    dish,
    isDish,
    setIsDish,
    isFetchingDish,
    isErrorDish,
    changedFields,
    onCalculateTotal,
    totalDishPrice,
  } = usePromotionModalForm(form);

  const dishThree = useMemo(() => {
    return menuDishList.map((item) => {
      const isCategory = item.menu_tree.level === 1;
      const pId = isCategory ? 0 : `cat-${item.menu_tree.ancestor}`;

      return {
        id: `cat-${item.id}`,
        value: `cat-${item.id}`,
        title: isCategory ? <CategoryTitle name={item.name} /> : <SubcategoryTitle name={item.name} />,
        children: item.dishes.map((dish) => ({
          id: dish.id,
          value: dish.id,
          title: dish.dish_name,
          price: dish.price,
        })),
        disabled: true,
        selectable: false,
        pId,
      };
    });
  }, [menuDishList]);

  const itemsIds = useMemo(() => dishThree.map((item) => item.id), [dishThree]);

  const isOptions =
    (dish && dish.options && dish.options?.length > 0) || form.getFieldValue(OPTIONS_NAME_FIELD)?.length > 0;

  const isExtras =
    (dish && dish.extras && dish.extras?.length > 0 && dish.extras.find((extra) => extra.is_readily_available)) ||
    form.getFieldValue(EXTRAS_NAME_FIELD)?.length > 0;

  const onSelect = async (value: number) => {
    await getDishById(value);
  };

  const onClear = () => {
    form.setFieldValue(DISH_ID_NAME_FIELD, null);
    form.setFieldValue(OPTIONS_NAME_FIELD, []);
    form.setFieldValue(EXTRAS_NAME_FIELD, []);
    setIsDish(false);
  };

  const renderThreeSelect = () =>
    useMemo(() => {
      return (
        <TreeSelect
          treeDataSimpleMode={{ id: 'id', pId: 'pId', rootPId: 0 }}
          showSearch
          placeholder="Please select"
          allowClear={true}
          treeDefaultExpandedKeys={itemsIds}
          onSelect={(value: number) => onSelect(value)}
          onClear={onClear}
          treeData={dishThree}
          treeNodeFilterProp="title"
          switcherIcon={false}
          style={{ width: '100%' }}
          dropdownStyle={{ padding: '16px' }}
          className={'max-h-[400px] w-full overflow-auto'}
          popupClassName={'popup-dish-tree-select'}
          loading={isFetching}
        />
      );
    }, [menuDishList]);

  const renderOptions = () => (
    <div className={'flex flex-col gap-2'}>
      <p className={'mb-0'}>{t('promotion_dish_options_des')}</p>

      <Form.List name={OPTIONS_NAME_FIELD}>
        {(fields) => (
          <>
            {fields.map((field, i) => {
              const opts = form.getFieldValue(OPTIONS_NAME_FIELD);

              return (
                <div key={`fields-${i}`}>
                  {opts[i].values.find((value: DishOptionValue) => value.is_readily_available) && (
                    <>
                      <div className={'mb-2 font-bold text-indigo-40'}>
                        {opts && opts[field.key].name}
                        <Form.Item name={[field.name, 'name']} hidden>
                          <Input />
                        </Form.Item>
                      </div>
                      <div className={'rounded bg-grey-5 p-4'}>
                        <Form.Item className={'client-dish-option mb-0'}>
                          <Form.List name={[field.name, 'values']}>
                            {(subFields) => (
                              <div className={'flex flex-col gap-6'}>
                                {subFields.map((subField, indexValueOpt) => {
                                  const optValue = opts[field.key].values[indexValueOpt];

                                  // if is_readily_available is true
                                  if (optValue.is_readily_available) {
                                    const id = opts[field.key].values[subField.name].id;
                                    const toggleName = opts[field.key].values[subField.name].value;
                                    const price = opts[field.key].values[subField.name].additional_charge;
                                    const selected = opts[field.key].values[subField.name].selected;

                                    return (
                                      <Form.Item
                                        key={`opt-value-${toggleName}-${i}`}
                                        noStyle
                                        name={[subField.name, 'selected']}
                                        valuePropName={'checked'}
                                      >
                                        <Radio value={id}>
                                          <div className={'flex items-center justify-between gap-4'}>
                                            <span>{toggleName}</span>
                                            <span className={selected ? 'font-semibold text-indigo-40' : ''}>
                                              {CURRENCY}
                                              {price}
                                            </span>
                                          </div>
                                        </Radio>
                                      </Form.Item>
                                    );
                                  }
                                })}
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </div>
  );

  const renderExtras = () => (
    <div className={'flex flex-col gap-2'}>
      <p className={'mb-0'}>{t('promotion_dish_extras_des')}</p>
      <div className={'font-bold text-indigo-40'}>{t('extras')}</div>

      <div className={'rounded bg-grey-5 p-4'}>
        <Form.List name={EXTRAS_NAME_FIELD}>
          {(fields) => (
            <div className={'flex flex-col gap-6'}>
              {fields.map(({ key, name }, i) => {
                const extras = form.getFieldValue(EXTRAS_NAME_FIELD);

                if (extras[i].is_readily_available) {
                  const toggleName = extras && (extras[key].name as string);
                  const price = extras && extras[key].price;
                  const checked = extras && extras[key].checked;

                  return (
                    <div key={`extra-${name}-${i}`} className={'flex items-center justify-between gap-4'}>
                      <Form.Item noStyle name={[name, 'checked']} initialValue valuePropName={'checked'}>
                        <Checkbox>{toggleName}</Checkbox>
                      </Form.Item>
                      <span className={checked ? 'font-semibold text-indigo-40' : ''}>
                        {CURRENCY}
                        {price}
                      </span>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </Form.List>
      </div>
    </div>
  );

  const renderFooterPrice = (form: any) => (
    <div>
      <div className={'mb-3 rounded bg-grey-5 p-4'}>
        <div className={'flex items-center justify-between gap-4'}>
          <div>{t('regular_price')}</div>
          <span className={'font-semibold text-indigo-40'}>
            {CURRENCY}
            {totalDishPrice}
          </span>
        </div>
      </div>
      <div className={'flex items-center gap-3 rounded bg-grey-5 px-4 py-2'}>
        <div className={'flex-grow'}>
          <div>{t('new_price')}</div>
        </div>
        <div className={'promotion-total-price max-w-[100px] flex-1'}>
          <Form.Item<NotificationFormFieldType>
            name="new_price"
            noStyle
            rules={[
              { required: true, message: t('required_field') },
              {
                validator(_, newPrice) {
                  if (!isNaN(newPrice) && newPrice < form.getFieldValue(OLD_PROMOTION_PRICE_NAME_FIELD)) {
                    return Promise.resolve();
                  }

                  if (!regExPrice.test(newPrice)) {
                    return Promise.reject(new Error(t('input_errors.invalid_price_format')));
                  }

                  return Promise.reject(new Error(t('input_errors.new_price_higher_than_original_price')));
                },
              },
            ]}
          >
            <Input suffix={<span className={'text-sm'}>{CURRENCY}</span>} />
          </Form.Item>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const setDishState = async () => {
      if (!isFetchingDish && dish) {
        await setFormValues({
          id: form.getFieldValue('id'),
          dish_id: dish.id,
          dish_price: dish.price,
          options: dish.options,
          extras: dish.extras,
        });
        await onCalculateTotal();
      }
    };

    setDishState();
  }, [dish, isFetchingDish]);

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFieldsChange={changedFields}
      onValuesChange={onCalculateTotal}
      onFinish={onFinish}
    >
      <Form.Item<NotificationFormFieldType> name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item<NotificationFormFieldType> name="promotion_type_id" initialValue={1} hidden>
        <Input />
      </Form.Item>
      {/* TODO: temporary - remove it when server can get data without this key - need to check */}
      <Form.Item name="is_active" initialValue={false} hidden>
        <Switch checked={false} />
      </Form.Item>
      <Form.Item label={t('title')} name="name" rules={[{ required: true, message: t('required_field') }]}>
        <Input />
      </Form.Item>
      <Form.Item<NotificationFormFieldType>
        label={t('description')}
        name="description"
        rules={[{ required: true, message: t('required_field') }]}
      >
        <Input />
      </Form.Item>

      {isError ? (
        <div>{t('error')}</div>
      ) : (
        <Form.Item<NotificationFormFieldType> label={t('link_dish')} name={DISH_ID_NAME_FIELD}>
          {renderThreeSelect()}
        </Form.Item>
      )}

      <Form.Item<NotificationFormFieldType> name={OLD_PROMOTION_PRICE_NAME_FIELD} hidden>
        <Input />
      </Form.Item>
      <Form.Item<NotificationFormFieldType> name={DISH_PRICE_NAME_FIELD} hidden>
        <Input />
      </Form.Item>

      {isErrorDish ? (
        <p className={'text-center'}>{t('error')}</p>
      ) : isFetchingDish ? (
        <p className={'text-center'}>{t('loading')}</p>
      ) : (
        <div className={'mx-auto mb-10 flex max-w-[550px] flex-col gap-10'}>
          {isOptions && renderOptions()}
          {isExtras && renderExtras()}
          {isDish && renderFooterPrice(form)}
        </div>
      )}
    </Form>
  );
};

export default memo(PromotionModalForm);
