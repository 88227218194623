import { useChangeOrderWaiterBySupervisorMutation, useChangeOrderWaiterMutation } from '@api/ordersApi';
import emptyWaiterOrdersImage from '@assets/img/empty_water_orders.png';
import MBChevronDownIcon from '@base/components/icons/MBChevronDownIcon';
import MBChevronUpIcon from '@base/components/icons/MBChevronUpIcon';
import OrderDishList from '@base/components/OrdersMonitoring/MobileOrdersMonitoringList/OrderDishList';
import WhiteBackgroundSelect from '@base/components/UI/WhiteBackgroundSelect';
import UserAccess from '@base/hoc/UserAccess';
import { Order, OrderStatus } from '@base/redux/api/models/Order';
import { Waiter } from '@base/redux/api/models/Waiter';
import MobileOrderItemHeader from '@components/OrdersMonitoring/MobileOrdersMonitoringList/MobileOrderItemHeader';
import useMobileOrdersOnboarding from '@components/OrdersMonitoring/MobileOrdersMonitoringList/MobileOrdersOnboarding/useMobileOrdersOnboarding';
import useCheckRole from '@hooks/useCheckRole';
import { Collapse, CollapseProps, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MobileOrdersMonitoringListProps {
  orders: Order[];
  waiters: Waiter[];
  statuses: OrderStatus[];
  isWaiters2Tables: boolean;
}

const MobileOrdersMonitoringList: FC<MobileOrdersMonitoringListProps> = ({
  orders,
  waiters,
  statuses,
  isWaiters2Tables,
}) => {
  const { isSupervisor, isWaiter } = useCheckRole();
  const { t } = useTranslation();
  const { isShowTour, renderTour, obStepRef1 } = useMobileOrdersOnboarding();

  const mutationChangeWaiter = isSupervisor
    ? useChangeOrderWaiterBySupervisorMutation()
    : useChangeOrderWaiterMutation();

  const [changeOrderWaiter] = mutationChangeWaiter;

  const handleChangeWaiter = (order_id: number, order_waiter_id: number) => {
    changeOrderWaiter({ order_id, order_waiter_id });
  };

  let collapseItems: CollapseProps['items'];

  const renderEmptyOrders = () => (
    <div className={'mb-4 flex flex-col items-center gap-4 text-center'}>
      <p className={'mb-0 max-w-[200px]'}>{t('empty_waiter_orders')}</p>
      <img src={emptyWaiterOrdersImage} alt="" />
    </div>
  );

  const renderChildren = (order: Order, i: number) => (
    <>
      <UserAccess roles={['restaurant-manager', 'supervisor']}>
        <div className={'mb-6 flex items-center gap-1'}>
          <span>{t('waiter')}: </span>
          {isWaiters2Tables ? (
            <WhiteBackgroundSelect
              key={`waiter-select-${i}-${order.waiter?.id || null}`}
              defaultValue={order.waiter?.id || null}
              className={'w-full min-w-[100px] max-w-[270px]'}
              onChange={(value) => handleChangeWaiter(order.id, value)}
            >
              <Select.Option key={`waiter-key-0`} id={0} value={null}>
                {t('no_one')}
              </Select.Option>
              {waiters &&
                waiters.map((waiter) => (
                  <Select.Option key={`waiter-key-${waiter.id}`} id={waiter.id} value={+waiter.id}>
                    {waiter?.first_name} {waiter?.last_name}
                  </Select.Option>
                ))}
            </WhiteBackgroundSelect>
          ) : (
            <>
              {order.waiter ? (
                <span>
                  {order.waiter?.first_name} {order.waiter?.last_name}
                </span>
              ) : (
                <span>{t('no_one')}</span>
              )}
            </>
          )}
        </div>
      </UserAccess>
      <span className={'mb-4 block text-black-custom'}>
        {t('order_number')}: #{order.id}
      </span>
      <OrderDishList order={order} />
    </>
  );

  if (orders.length > 0) {
    collapseItems = orders.map((order, i) => {
      return {
        key: `order-item-${order.id}`,
        label: <MobileOrderItemHeader order={order} statuses={statuses} stepRef={i === 0 ? obStepRef1 : undefined} />,
        collapsible: 'icon',
        children: renderChildren(order, i),
      };
    });
  }

  return (
    <>
      {orders.length > 0 ? (
        <>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <MBChevronUpIcon width={'16px'} height={'16px'} />
              ) : (
                <MBChevronDownIcon width={'16px'} height={'16px'} />
              )
            }
            className="order-monitoring-collapse"
            items={collapseItems}
          />
          {isWaiter && isShowTour && renderTour()}
        </>
      ) : (
        renderEmptyOrders()
      )}
    </>
  );
};

export default MobileOrdersMonitoringList;
