import { SVGProps } from 'react';
const PaymentSuccessLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={159} height={67} viewBox="0 0 159 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M126.168 52.8111C124.895 54.0687 122.828 54.0687 121.55 52.8111L115.654 46.9798C114.381 45.7222 114.381 43.6799 115.654 42.4169C116.926 41.1592 118.993 41.1592 120.272 42.4169L126.168 48.2428C127.441 49.5058 127.441 51.5481 126.168 52.8111Z"
      fill="url(#paint0_linear_4430_60839)"
    />
    <path
      opacity={0.3}
      d="M115.654 52.8107C114.381 51.5525 114.381 49.5093 115.654 48.2458L121.55 42.4173C122.823 41.1591 124.89 41.1591 126.168 42.4173C127.441 43.6754 127.441 45.7186 126.168 46.9822L120.272 52.8107C118.993 54.0689 116.926 54.0689 115.654 52.8107Z"
      fill="url(#paint1_linear_4430_60839)"
    />
    <g opacity={0.5}>
      <path
        opacity={0.3}
        d="M31.6053 20.2458C30.5871 21.2519 28.9335 21.2519 27.9109 20.2458L23.1939 15.5807C22.1757 14.5746 22.1757 12.9408 23.1939 11.9304C24.2122 10.9243 25.8657 10.9243 26.8883 11.9304L31.6053 16.5911C32.6235 17.6015 32.6235 19.2354 31.6053 20.2458Z"
        fill="url(#paint2_linear_4430_60839)"
      />
      <path
        opacity={0.3}
        d="M23.1939 20.2454C22.1757 19.2389 22.1757 17.6043 23.1939 16.5935L27.9109 11.9307C28.9291 10.9241 30.5827 10.9241 31.6053 11.9307C32.6235 12.9372 32.6235 14.5718 31.6053 15.5826L26.8883 20.2454C25.8657 21.252 24.2122 21.252 23.1939 20.2454Z"
        fill="url(#paint3_linear_4430_60839)"
      />
    </g>
    <g opacity={0.5}>
      <path
        opacity={0.3}
        d="M100.726 8.36492C99.8685 9.21211 98.4761 9.21211 97.6151 8.36492L93.6431 4.43671C92.7857 3.58952 92.7857 2.21374 93.6431 1.36293C94.5005 0.515742 95.8929 0.515742 96.754 1.36293L100.726 5.28752C101.583 6.13833 101.583 7.51411 100.726 8.36492Z"
        fill="url(#paint4_linear_4430_60839)"
      />
      <path
        opacity={0.3}
        d="M93.6431 8.36464C92.7857 7.51708 92.7857 6.1407 93.6431 5.28951L97.6151 1.36321C98.4725 0.515649 99.8648 0.515649 100.726 1.36321C101.583 2.21077 101.583 3.58715 100.726 4.43833L96.754 8.36464C95.8929 9.2122 94.5005 9.2122 93.6431 8.36464Z"
        fill="url(#paint5_linear_4430_60839)"
      />
    </g>
    <path
      d="M137.976 18.3065L139.265 21.0388C139.303 21.1202 139.359 21.1901 139.429 21.2432C139.499 21.2963 139.58 21.3312 139.666 21.344L142.548 21.7789C142.645 21.7944 142.737 21.838 142.812 21.9053C142.887 21.9718 142.943 22.0592 142.974 22.1573C143.004 22.2555 143.008 22.3603 142.985 22.4611C142.962 22.5613 142.913 22.6534 142.843 22.726L140.76 24.852C140.698 24.9152 140.652 24.9932 140.625 25.0792C140.599 25.1652 140.592 25.2567 140.607 25.3454L141.099 28.3479C141.116 28.4508 141.105 28.5563 141.068 28.6524C141.03 28.7492 140.967 28.8326 140.887 28.8937C140.807 28.9549 140.711 28.9912 140.612 28.9986C140.513 29.006 140.414 28.9845 140.326 28.9354L137.748 27.5144C137.672 27.4728 137.586 27.4506 137.5 27.4506C137.414 27.4506 137.328 27.4728 137.252 27.5144L134.675 28.9354C134.587 28.9838 134.488 29.0053 134.388 28.9979C134.289 28.9905 134.194 28.9535 134.114 28.8924C134.033 28.8312 133.971 28.7479 133.933 28.6511C133.896 28.555 133.885 28.4494 133.902 28.3466L134.393 25.344C134.408 25.2546 134.402 25.1632 134.375 25.0779C134.348 24.9918 134.302 24.9139 134.24 24.8507L132.158 22.7253C132.088 22.6527 132.038 22.5606 132.015 22.4605C131.992 22.3603 131.996 22.2548 132.026 22.1566C132.057 22.0585 132.113 21.9711 132.188 21.9046C132.264 21.838 132.356 21.7937 132.453 21.7782L135.335 21.3406C135.42 21.3279 135.501 21.2929 135.571 21.2398C135.641 21.1867 135.698 21.1168 135.736 21.0355L137.025 18.3038C137.07 18.2124 137.138 18.1351 137.222 18.082C137.306 18.0276 137.403 18 137.501 18C137.6 18 137.696 18.0289 137.78 18.0833C137.864 18.1371 137.932 18.2144 137.976 18.3065H137.976Z"
      fill="white"
      stroke="url(#paint6_linear_4430_60839)"
      strokeOpacity={0.2}
    />
    <path
      d="M156.259 32.1672L156.963 33.6576C156.983 33.7019 157.014 33.74 157.052 33.769C157.09 33.798 157.135 33.817 157.181 33.824L158.753 34.0612C158.807 34.0696 158.856 34.0935 158.897 34.1301C158.938 34.1664 158.969 34.2141 158.986 34.2676C159.002 34.3212 159.005 34.3784 158.992 34.4333C158.979 34.488 158.952 34.5382 158.914 34.5778L157.778 35.7375C157.745 35.7719 157.719 35.8145 157.705 35.8614C157.69 35.9083 157.687 35.9582 157.695 36.0066L157.963 37.6443C157.972 37.7004 157.966 37.758 157.946 37.8104C157.926 37.8632 157.891 37.9087 157.848 37.942C157.804 37.9754 157.752 37.9952 157.698 37.9992C157.643 38.0033 157.589 37.9915 157.541 37.9648L156.135 37.1897C156.094 37.167 156.047 37.1549 156 37.1549C155.953 37.1549 155.906 37.167 155.865 37.1897L154.459 37.9648C154.411 37.9912 154.357 38.0029 154.303 37.9989C154.249 37.9948 154.197 37.9747 154.153 37.9413C154.109 37.9079 154.075 37.8625 154.054 37.8097C154.034 37.7572 154.028 37.6997 154.037 37.6436L154.305 36.0058C154.313 35.9571 154.31 35.9072 154.296 35.8607C154.281 35.8137 154.256 35.7712 154.222 35.7367L153.086 34.5774C153.048 34.5378 153.021 34.4876 153.008 34.433C152.996 34.3784 152.998 34.3208 153.014 34.2673C153.031 34.2137 153.062 34.1661 153.103 34.1298C153.144 34.0935 153.194 34.0693 153.247 34.0608L154.819 33.8222C154.865 33.8152 154.91 33.7961 154.948 33.7672C154.986 33.7382 155.017 33.7001 155.038 33.6557L155.741 32.1657C155.766 32.1159 155.803 32.0737 155.849 32.0447C155.894 32.015 155.947 32 156.001 32C156.054 32 156.107 32.0158 156.153 32.0455C156.198 32.0748 156.236 32.117 156.26 32.1672H156.259Z"
      fill="url(#paint7_linear_4430_60839)"
      fillOpacity={0.3}
    />
    <path
      d="M4.34589 56.2229L5.28337 58.2101C5.31094 58.2692 5.35207 58.3201 5.40301 58.3587C5.45394 58.3973 5.51283 58.4227 5.57499 58.432L7.67099 58.7483C7.74202 58.7595 7.80839 58.7913 7.86306 58.8402C7.91774 58.8886 7.9584 58.9521 7.98083 59.0235C8.00327 59.0949 8.00607 59.1711 7.98925 59.2445C7.97242 59.3173 7.93644 59.3843 7.8855 59.4371L6.37086 60.9833C6.326 61.0292 6.29235 61.0859 6.27272 61.1485C6.25356 61.2111 6.24888 61.2776 6.25963 61.3421L6.61761 63.5258C6.62976 63.6006 6.62182 63.6773 6.59471 63.7472C6.56761 63.8176 6.52181 63.8782 6.46339 63.9227C6.40497 63.9672 6.33534 63.9936 6.26337 63.999C6.19094 64.0043 6.11897 63.9887 6.05494 63.953L4.18045 62.9196C4.12484 62.8893 4.06268 62.8732 4.00006 62.8732C3.93744 62.8732 3.87528 62.8893 3.81967 62.9196L1.94518 63.953C1.88116 63.9882 1.80919 64.0039 1.73675 63.9985C1.66478 63.9931 1.59561 63.9662 1.5372 63.9217C1.47878 63.8772 1.43345 63.8166 1.40588 63.7462C1.37877 63.6763 1.37083 63.5996 1.38298 63.5248L1.74049 61.3411C1.75124 61.2761 1.74656 61.2096 1.7274 61.1475C1.70778 61.085 1.67413 61.0283 1.62926 60.9823L0.114625 59.4366C0.0636856 59.3838 0.0277007 59.3168 0.0108765 59.244C-0.00594758 59.1711 -0.00314356 59.0944 0.0192886 59.023C0.0417207 58.9516 0.082379 58.8881 0.137057 58.8397C0.191736 58.7913 0.258565 58.759 0.329133 58.7478L2.42514 58.4296C2.48729 58.4203 2.54618 58.3949 2.59712 58.3562C2.64806 58.3176 2.68918 58.2668 2.71675 58.2076L3.6547 56.221C3.68741 56.1545 3.73695 56.0983 3.79817 56.0596C3.85893 56.02 3.92949 56 4.001 56C4.0725 56 4.1426 56.021 4.20382 56.0606C4.26457 56.0997 4.31411 56.1559 4.34636 56.2229H4.34589Z"
      fill="url(#paint8_linear_4430_60839)"
      fillOpacity={0.3}
    />
    <path
      d="M33.2349 42.4737L35.2271 46.6964C35.2857 46.8221 35.3731 46.9301 35.4813 47.0122C35.5896 47.0943 35.7147 47.1483 35.8468 47.168L40.3008 47.8401C40.4517 47.864 40.5928 47.9315 40.7089 48.0354C40.8251 48.1382 40.9115 48.2733 40.9592 48.4249C41.0069 48.5766 41.0128 48.7387 40.9771 48.8945C40.9413 49.0493 40.8649 49.1916 40.7566 49.3038L37.538 52.5895C37.4427 52.6871 37.3712 52.8076 37.3295 52.9406C37.2887 53.0736 37.2788 53.2148 37.3017 53.352L38.0624 57.9922C38.0882 58.1512 38.0713 58.3143 38.0137 58.4628C37.9561 58.6124 37.8588 58.7412 37.7346 58.8358C37.6105 58.9303 37.4625 58.9864 37.3096 58.9978C37.1557 59.0092 37.0027 58.976 36.8667 58.9002L32.8834 56.7041C32.7652 56.6397 32.6331 56.6055 32.5001 56.6055C32.367 56.6055 32.2349 56.6397 32.1167 56.7041L28.1334 58.9002C27.9974 58.9749 27.8445 59.0082 27.6905 58.9968C27.5376 58.9853 27.3906 58.9282 27.2665 58.8337C27.1423 58.7391 27.046 58.6103 26.9874 58.4607C26.9298 58.3122 26.9129 58.1491 26.9388 57.9902L27.6985 53.3499C27.7213 53.2117 27.7114 53.0704 27.6707 52.9385C27.629 52.8055 27.5575 52.685 27.4621 52.5874L24.2435 49.3027C24.1353 49.1905 24.0588 49.0482 24.023 48.8934C23.9873 48.7387 23.9933 48.5756 24.0409 48.4239C24.0886 48.2722 24.175 48.1372 24.2912 48.0344C24.4074 47.9315 24.5494 47.863 24.6993 47.8391L29.1533 47.1628C29.2854 47.1431 29.4106 47.0891 29.5188 47.007C29.6271 46.9249 29.7144 46.8169 29.773 46.6912L31.7662 42.4695C31.8357 42.3283 31.941 42.2088 32.071 42.1267C32.2001 42.0426 32.3501 42 32.502 42C32.654 42 32.803 42.0447 32.933 42.1288C33.0621 42.2119 33.1674 42.3314 33.2359 42.4737H33.2349Z"
      fill="white"
      stroke="url(#paint9_linear_4430_60839)"
      strokeOpacity={0.3}
    />
    <path
      opacity={0.3}
      d="M98.3118 63.3133C93.3941 68.2289 85.4081 68.2289 80.4694 63.3133L57.6883 40.5212C52.7706 35.6057 52.7706 27.6232 57.6883 22.6867C62.606 17.7711 70.592 17.7711 75.5307 22.6867L98.3118 45.4578C103.229 50.3943 103.229 58.3768 98.3118 63.3133Z"
      fill="url(#paint10_linear_4430_60839)"
    />
    <path
      opacity={0.3}
      d="M57.6883 63.3117C52.7706 58.394 52.7706 50.408 57.6883 45.4693L80.4694 22.6883C85.3871 17.7706 93.3731 17.7706 98.3118 22.6883C103.229 27.606 103.229 35.5919 98.3118 40.5306L75.5307 63.3117C70.592 68.2294 62.606 68.2294 57.6883 63.3117Z"
      fill="url(#paint11_linear_4430_60839)"
    />
    <path
      d="M76.92 34.696V38.6804H76.2V34.7204C76.2 34.5217 76.0388 34.3604 75.84 34.3604C75.6413 34.3604 75.48 34.5217 75.48 34.7204V38.6804H74.76V34.7075C74.76 34.2442 74.04 34.26 74.04 34.6956V38.6804H73.32V34.724C73.32 34.2334 72.6 34.2514 72.6 34.7046V39.9145C72.6 40.6658 73.32 41.1612 74.04 41.1612V50.5604C74.04 52.0004 76.2 52.0004 76.2 50.5604V41.1616C76.92 41.1616 77.64 40.5632 77.64 39.9998V34.7075C77.64 34.2485 76.92 34.2661 76.92 34.696ZM80.16 35.8004V44.8004H80.88V50.5604C80.88 52.0004 83.4 52.0004 83.4 50.5604V35.8004C83.4 34.0004 80.16 34.0004 80.16 35.8004Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4430_60839"
        x1={121.989}
        y1={42.1937}
        x2={119.886}
        y2={53.0439}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4430_60839"
        x1={126.394}
        y1={48.6751}
        x2={115.432}
        y2={46.4997}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4430_60839"
        x1={28.2622}
        y1={11.7518}
        x2={26.5797}
        y2={20.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4430_60839"
        x1={31.7863}
        y1={16.937}
        x2={23.0167}
        y2={15.1966}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4430_60839"
        x1={97.9109}
        y1={1.2126}
        x2={96.4941}
        y2={8.5217}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4430_60839"
        x1={100.878}
        y1={5.57873}
        x2={93.4939}
        y2={4.11328}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4430_60839"
        x1={142.355}
        y1={24.4505}
        x2={132.641}
        y2={22.5448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4430_60839"
        x1={158.648}
        y1={35.5184}
        x2={153.35}
        y2={34.479}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_4430_60839"
        x1={7.53104}
        y1={60.6912}
        x2={0.466034}
        y2={59.3053}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_4430_60839"
        x1={40.0034}
        y1={51.9689}
        x2={24.9903}
        y2={49.0237}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_4430_60839"
        x1={82.1661}
        y1={21.8144}
        x2={73.8574}
        y2={64.1872}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_4430_60839"
        x1={99.186}
        y1={47.1474}
        x2={56.7959}
        y2={38.8317}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default PaymentSuccessLogo;
