import checkIsSubdomain from '@helpers/checkIsSubdomain';

function addTenantToEndpoint(endpoint: string): string {
  const subdomain = checkIsSubdomain();

  if (subdomain) {
    return `/tenant${endpoint}`;
  }

  return endpoint;
}

export default addTenantToEndpoint;
