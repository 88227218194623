import waitingStatusGif from '@assets/img/cook-hat-animated.gif';
import inProgressStatusGif from '@assets/img/knife-animated.gif';
import placeholderRestLogo from '@assets/img/logo-restaurant-placeholder.png';
import { ClientOrder } from '@base/redux/api/models/Order';
import ClientOrderDishList from '@components/Client/ClientOrders/ClientOrdersMonitoringList/ClientOrderDishList';
import { Card, Image } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const GuestOrder: FC<{ order: ClientOrder }> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={`w-full border-solid ${
        order.status.status !== 'completed' ? 'border-2 border-indigo-40' : 'border-[1px] border-grey-15 bg-grey-5'
      }`}
    >
      <div className={'mb-6 flex flex-col gap-6'}>
        <header className={'flex items-center gap-4 border-0 border-b-[1px] border-solid border-grey-15 pb-1'}>
          <Image
            preview={false}
            width={40}
            height={40}
            src={order.restaurant.profile_picture || placeholderRestLogo}
            alt={`Logo of ${order.restaurant.name} restaurant.`}
            className={'rounded-full'}
          />
          <div className={'font-bold'}>{order.restaurant.name}</div>
        </header>
      </div>
      <div className={'flex flex-col gap-2 pb-6'}>
        <div className={'flex flex-wrap items-center justify-between gap-x-4 gap-y-2'}>
          <div className={'text-sm'}>
            {t('order_number')}: #{order.id}
          </div>
          <div className={'text-sm text-grey-30'}>{order.order_date}</div>
        </div>
        {order.status.status === 'waiting' && (
          <>
            <img className={'m-auto h-[52px] w-[52px] object-cover'} src={waitingStatusGif} alt="order accepted" />
            <span className={'m-auto w-full bg-cyan-10 px-4 py-2 text-center font-bold leading-[120%] text-cyan-40'}>
              {t('order_accepted')}
            </span>
          </>
        )}
        {order.status.status === 'in_progress' && (
          <>
            <img
              className={'m-auto h-[52px] w-[52px] object-cover'}
              src={inProgressStatusGif}
              alt="order in progress"
            />
            <span
              className={'m-auto w-full bg-[#C7DFFF] px-4 py-2 text-center font-bold leading-[120%] text-indigo-40'}
            >
              {t('order_in_preparation')}
            </span>
          </>
        )}
      </div>
      <div className={'flex flex-col gap-4'}>
        <ClientOrderDishList order={order} />
      </div>
    </Card>
  );
};

export default GuestOrder;
