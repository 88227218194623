import { Client } from '@api/models/Client';
import useSimpleClientLoginForm from '@components/AuthForm/ClientLoginForm/SimpleClientLoginForm/useSimpleClientLoginForm';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import { SERVER_GOOGLE_AUTH_ERROR } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { FC, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

interface ClientRegFormFieldType {
  email: string;
  password: string;
}

interface SimpleClientLoginFormProps {
  initLoggedClient: (data: Client) => Promise<void>;
  redirectAfterLogin: () => void;
}

const SimpleClientLoginForm: FC<SimpleClientLoginFormProps> = ({ initLoggedClient, redirectAfterLogin }) => {
  const { isLoading, isSuccess, isError, error, data, onFinish, t, paymentUrlParam } = useSimpleClientLoginForm();

  useLayoutEffect(() => {
    if (!isLoading && isSuccess && data) {
      const result = initLoggedClient(data);

      result.then(() => {
        redirectAfterLogin();
      });
    }

    if (!isLoading && isError && error) {
      const errorMessage = (error as any)?.data?.data?.error_message;

      if (errorMessage && errorMessage === SERVER_GOOGLE_AUTH_ERROR) {
        message.error(t('server_errors.account_uses_google_auth'));

        return;
      }

      message.error(t('server_errors.bed_credentials'));
    }
  }, [isLoading, isSuccess, isError, error]);

  return (
    <Form name="basic" layout="vertical" initialValues={{ remember: false }} onFinish={onFinish} autoComplete="off">
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item<ClientRegFormFieldType>
            label={t('email')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
          >
            <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item<ClientRegFormFieldType>
            label={t('password')}
            name="password"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Link to={ROUTES.client_forgot_password}>{t('forget_password')}</Link>

      <Form.Item className={'mb-0'}>
        <Button
          type="primary"
          htmlType="submit"
          className={'mt-6 w-full justify-center lg:w-[206px]'}
          loading={isLoading}
        >
          {paymentUrlParam && paymentUrlParam === 'true' ? t('next') : t('login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SimpleClientLoginForm;
