import { useLoginClientMutation } from '@api/clientApi';
import getQueryParam from '@helpers/getQueryParam';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useSimpleClientLoginForm = () => {
  const { t } = useTranslation();
  const paymentUrlParam = getQueryParam('payment');

  const [loginClient, { isLoading, isError, error, isSuccess, data }] = useLoginClientMutation();

  const onFinish = async (values: any) => {
    await loginClient(values);
  };

  useLayoutEffect(() => {
    scrollTo(0, 0);
  }, []);

  return {
    t,
    paymentUrlParam,
    isLoading,
    isSuccess,
    isError,
    error,
    onFinish,
    data,
  };
};

export default useSimpleClientLoginForm;
