import { Layout } from '@api/models/Restaurant';
import { RestaurantInfo } from '@api/models/RestaurantInfo';
import { PackagePayment } from '@api/models/SubscriptionPackage';
import { WorkHours } from '@base/types/WorkHours';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  id: number | null;
  domain: string | null;
  name: string | null;
  secondary_title: string | null;
  google_account_link: string | null;
  address: string | null;
  instagram_link: string | null;
  facebook_link: string | null;
  profile_picture: string | null;
  background_picture: string | null;
  description: string | null;
  phone_number: string | null;
  is_active: boolean | null;
  is_take_away: boolean | null;
  description_schedule: string | null;
  layout: Layout | null;
  work_hours: WorkHours | null;
  package_payment: PackagePayment | null;
  payment_method: boolean | null;
}

const initialState: InitialState = {
  id: null,
  domain: null,
  name: null,
  secondary_title: null,
  google_account_link: null,
  address: null,
  instagram_link: null,
  facebook_link: null,
  profile_picture: null,
  background_picture: null,
  description: null,
  phone_number: null,
  is_take_away: null,
  is_active: null,
  description_schedule: null,
  layout: null,
  work_hours: null,
  package_payment: null,
  payment_method: null,
};

export const restaurantSlice = createSlice({
  initialState,
  name: 'restaurant',
  reducers: {
    resetRestaurantSlice: () => initialState,
    setRestaurantInfo: (state, action: PayloadAction<RestaurantInfo>) => {
      state.id = action.payload.id;
      state.domain = action.payload.domain;
      state.name = action.payload.name;
      state.secondary_title = action.payload.secondary_title;
      state.google_account_link = action.payload.google_account_link;
      state.address = action.payload.address;
      state.instagram_link = action.payload.instagram_link;
      state.facebook_link = action.payload.facebook_link;
      state.profile_picture = action.payload.profile_picture;
      state.background_picture = action.payload.background_picture;
      state.description = action.payload.description;
      state.phone_number = action.payload.phone_number;
      state.is_take_away = action.payload.is_take_away;
      state.is_active = action.payload.is_active;
      state.description_schedule = action.payload.description_schedule;
      state.layout = action.payload.layout;
      state.work_hours = action.payload.work_hours;
      state.package_payment = action.payload.package_payment;
      state.payment_method = action.payload.payment_method;
    },
  },
});

export default restaurantSlice.reducer;

export const { resetRestaurantSlice, setRestaurantInfo } = restaurantSlice.actions;
