import { useGetRestaurantPromotionsQuery } from '@api/promotionApi';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import getQueryParam from '@helpers/getQueryParam';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PROMOTION_ID_PARAM = 'id';

const useClientPromotions = () => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const [promotionID, setPromotionID] = useState<number | null>(null);
  const { isOrderPlacementPayment, isPushNotifications } = useAvailableFeatures();

  const { data: promotions = [], isLoading, isSuccess, isError } = useGetRestaurantPromotionsQuery();

  useEffect(() => {
    const promotions_id = getQueryParam(PROMOTION_ID_PARAM);

    if (promotions_id) {
      setPromotionID(+promotions_id);
    }
  }, [location]);

  return {
    isOpened,
    setIsOpened,
    promotions,
    isLoading,
    isSuccess,
    isError,
    promotionID,
    isOrderPlacementPayment,
    isPushNotifications,
  };
};

export default useClientPromotions;
