import MBLockIcon from '@base/components/icons/MBLockIcon';
import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import OrdersMonitoringView from '@components/OrdersMonitoring/OrdersMonitoringView';
import useOrdersMonitoring from '@components/OrdersMonitoring/useOrdersMonitoring';
import SecondaryButton from '@components/UI/SecondaryButton';
import { ROUTES } from '@constants/routes';
import { Card } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const DashboardOrdersMonitoring = () => {
  const { t } = useTranslation();
  const {
    orders,
    waiterOrders,
    orderStatuses,
    waiters,
    isLoading,
    isError,
    isWaiters2Tables,
    isOrderPlacementPayment,
    isPaymentMethod,
  } = useOrdersMonitoring();

  return (
    <>
      <div className={'mb-6 flex items-center gap-4'}>
        <h2 className={'mb-0 text-[26px] font-bold text-indigo-40'}>{t('last_orders')}</h2>
        {isOrderPlacementPayment ? (
          <Link to={ROUTES.orders}>
            <SecondaryButton className={'min-w-[60px] justify-center'}>{t('more')}</SecondaryButton>
          </Link>
        ) : (
          <MBLockIcon className={'min-w-[16px] text-base text-grey-30'} />
        )}
      </div>
      {isOrderPlacementPayment ? (
        <OrdersMonitoringView
          orders={orders}
          waiterOrders={waiterOrders}
          pagination={false}
          orderStatuses={orderStatuses}
          waiters={waiters}
          isLoading={isLoading}
          isError={isError}
          isWaiters2Tables={isWaiters2Tables}
          isDashboard
          isPaymentMethod={!!isPaymentMethod}
        />
      ) : (
        <Card className={'relative min-h-[345px]'}>
          <BluredUnavailableFeature isWithoutBlure={true} />
        </Card>
      )}
    </>
  );
};

export default memo(DashboardOrdersMonitoring);
