import { SVGProps } from 'react';
const SmileFaceRatingOneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.746 40C31.7578 40 40.6845 31.0457 40.6845 20C40.6845 8.9543 31.7578 0 20.746 0C9.73432 0 0.807556 8.9543 0.807556 20C0.807556 31.0457 9.73432 40 20.746 40Z"
      fill="#FF3863"
    />
    <path
      d="M13.6015 26.9982C16.2544 24.5707 22.0731 20.8074 28.1575 27.0376C28.7152 27.6087 28.308 28.5721 27.5116 28.5721H14.2075C13.3827 28.5721 12.9929 27.5558 13.6021 26.9982H13.6015Z"
      fill="currentColor"
    />
    <path
      d="M27.9954 18.3022C29.5991 18.3022 30.8992 16.9981 30.8992 15.3894C30.8992 13.7807 29.5991 12.4766 27.9954 12.4766C26.3916 12.4766 25.0915 13.7807 25.0915 15.3894C25.0915 16.9981 26.3916 18.3022 27.9954 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M13.4959 18.3022C15.0997 18.3022 16.3998 16.9981 16.3998 15.3894C16.3998 13.7807 15.0997 12.4766 13.4959 12.4766C11.8921 12.4766 10.592 13.7807 10.592 15.3894C10.592 16.9981 11.8921 18.3022 13.4959 18.3022Z"
      fill="currentColor"
    />
  </svg>
);
export default SmileFaceRatingOneIcon;
