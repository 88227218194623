import { useGetDishByCategoryIdQuery } from '@api/dishApi';
import { useAppSelector } from '@base/redux/store';
import DishCard from '@components/DishCard';
import useCheckRole from '@hooks/useCheckRole';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const MobileMenuDishList: FC<{ categoryId: number }> = ({ categoryId }) => {
  const { t } = useTranslation();
  const { isClient } = useCheckRole();
  const isFilteredMenu = useAppSelector((state) => state.clientSlice.isFilteredMenu);
  const isTakeAway = !!useAppSelector((state) => state.clientSlice.table.table_take_away_id);
  const { allergies, dishTypes } = useAppSelector((state) => state.clientSlice);

  const {
    data = [],
    isLoading,
    isError,
  } = isClient
    ? isFilteredMenu
      ? useGetDishByCategoryIdQuery({ allergies, dishTypes, isTakeAway, id: categoryId })
      : useGetDishByCategoryIdQuery({ isTakeAway, id: categoryId })
    : useGetDishByCategoryIdQuery({ id: categoryId });

  return (
    <>
      {isError ? (
        <span className={'text-error text-sm'}>{t('error_loading_dishes')}</span>
      ) : isLoading ? (
        <span className={'text-sm'}>{t('loading')}</span>
      ) : (
        data.length > 0 && (
          <div className={'flex flex-col gap-4 pt-3'}>
            {data.map((dish, index) => (
              <DishCard key={`${dish.id}-${index}`} dish={dish} />
            ))}
          </div>
        )
      )}
    </>
  );
};

export default MobileMenuDishList;
