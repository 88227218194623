import { ClientOrdersRestaurant } from '@api/models/Restaurant';
import MBCloseIcon from '@components/icons/MBCloseIcon';
import DishPlaceholderLogo from '@components/svg/DishPlaceholderLogo';
import SecondaryButton from '@components/UI/SecondaryButton';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ClientOrdersRestaurantFilterProps {
  restaurants?: ClientOrdersRestaurant[] | null;
  filteredRestaurants: number[] | null;
  onChangeFilter?: (e: CheckboxChangeEvent) => void;
  onClickClearBtn: () => void;
}

const ClientOrdersRestaurantFilter: FC<ClientOrdersRestaurantFilterProps> = ({
  restaurants,
  filteredRestaurants,
  onChangeFilter,
  onClickClearBtn,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { t } = useTranslation();

  const handleClickFilter = () => {
    setIsModalOpened((prevIsModalOpened) => !prevIsModalOpened);
  };

  return (
    <>
      {restaurants && restaurants.length > 0 && (
        <>
          {isModalOpened ? (
            <div className={'relative flex justify-start'}>
              <SecondaryButton type="default" className={'h-auto px-2 py-2'} onClick={handleClickFilter}>
                <MBCloseIcon />
              </SecondaryButton>
              <div
                className={
                  'absolute left-0 top-10 z-[1] flex w-[311px] flex-col rounded border-2 border-solid border-indigo-30 bg-white p-4 shadow-custom'
                }
              >
                {restaurants.map((restaurant) => (
                  <Checkbox
                    onChange={onChangeFilter}
                    key={`key-restaurant-${restaurant.id}`}
                    value={restaurant.id}
                    className={
                      'flex items-center border-0 border-b-[1px] border-solid border-grey-15 py-2 font-bold leading-[120%] text-black-custom'
                    }
                    checked={filteredRestaurants ? filteredRestaurants.includes(restaurant.id) : false}
                  >
                    <div className={'flex items-center'}>
                      {restaurant.profile_picture ? (
                        <img
                          src={restaurant.profile_picture}
                          alt="Restaurant logo"
                          className={'mx-2 h-10 w-10 rounded-full object-cover'}
                        />
                      ) : (
                        <div className={'mx-2 flex h-10 w-10 items-center justify-center rounded-full bg-grey-20'}>
                          <DishPlaceholderLogo width={25} height={25} />
                        </div>
                      )}

                      {restaurant.name}
                    </div>
                  </Checkbox>
                ))}
                <SecondaryButton
                  type="default"
                  onClick={onClickClearBtn}
                  className={'mt-2 h-max w-max p-2 text-sm leading-[120%]'}
                >
                  {t('defiltering')}
                </SecondaryButton>
              </div>
            </div>
          ) : (
            <SecondaryButton
              type="default"
              className={'h-auto self-start px-2 text-sm leading-[120%]'}
              onClick={handleClickFilter}
            >
              {t('filter_by_restaurant')}{' '}
              {filteredRestaurants && filteredRestaurants.length > 0 && `(${filteredRestaurants.length})`}
            </SecondaryButton>
          )}
        </>
      )}
    </>
  );
};

export default ClientOrdersRestaurantFilter;
