import { Order } from '@api/models/Order';
import KitchenReceiptOrderDetails from '@components/KitchenReceipt/KitchenReceiptOrderDetails';
import { DATE_FORMAT } from '@constants/constants';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const KitchenReceipt: FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  const headerFooterClasses = 'text-white bg-black text-center p-2 text-sm';
  const typeOrder = order?.table ? t('dine_in') : t('take_away');
  const tableName = order?.table ? order.table.name : t('take_away');

  const renderOrderInfo = () => {
    const date = convertServerDateToClient(order.order_date, DATE_FORMAT);
    const [orderDate, orderTime] = date.split(',');

    return (
      <>
        {t('order_date_time')}:
        <div className={'mt-1 flex items-center justify-between gap-2'}>
          <span>
            {t('time')}: <b>{orderTime}</b>
          </span>
          <span>
            {t('date')}: <b>{orderDate}</b>
          </span>
        </div>
      </>
    );
  };
  const renderCustomerInfo = () => {
    const clientName = order.client?.first_name || t('guest');

    return (
      <div className={'border-0 border-y border-dashed border-black py-4'}>
        <div className={'mb-1'}>{t('customer_info')}:</div>
        <dl className={'m-0 flex flex-col gap-1 p-0'}>
          <div className={'inline-flex gap-1'}>
            <dt className={'m-0'}>{t('name')}:</dt>
            <dd className={'m-0 font-bold'}>{clientName}</dd>
          </div>
          <div className={'inline-flex gap-1'}>
            <dt className={'m-0'}>{t('table')}:</dt>
            <dd className={'m-0 font-bold'}>{tableName}</dd>
          </div>
        </dl>
      </div>
    );
  };

  return (
    <div className={'w-full max-w-[200px] bg-white text-black'}>
      <header className={headerFooterClasses}>{typeOrder}</header>
      <main className={'flex flex-col gap-4 px-2 py-4 text-[10px]'}>
        <div>{renderOrderInfo()}</div>
        <div>{renderCustomerInfo()}</div>
        <div>
          <KitchenReceiptOrderDetails orderItems={order.order_items} />
        </div>
      </main>
      <footer className={headerFooterClasses}>
        {t('order_number')}: #{order.id}
      </footer>
    </div>
  );
};

export default KitchenReceipt;
