import { useToggleWorkerMutation } from '@api/workersApi';
import { useEffect, useState } from 'react';

const useWorkerSwitcher = (isActive: boolean) => {
  const [isChecked, setIsChecked] = useState(isActive);

  const [toggleWorker, { isLoading, isSuccess, isError }] = useToggleWorkerMutation();

  const handleSwitch = async (id: number, checked: boolean) => {
    await toggleWorker({ id, is_active: checked });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsChecked((prev) => !prev);
    }
  }, [isLoading, isError]);

  return {
    isChecked,
    isLoading,
    handleSwitch,
  };
};

export default useWorkerSwitcher;
