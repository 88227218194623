export function generateQueryStringFromArrayObjects(array: [], parameterName: string, objectKey: string = ''): string {
  return array
    ? array
        .filter((item) => item && (objectKey === '' || item[objectKey]))
        .map((item) => {
          const value = objectKey === '' ? encodeURIComponent(item) : encodeURIComponent(item[objectKey]);

          return `${parameterName}[]=${value}`;
        })
        .join('&')
    : '';
}
