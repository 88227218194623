import { useRemoveMenuMutation, useUpdateMenuMutation } from '@api/menuApi';
import { Menu } from '@api/models/Menu';
import useModalConfirm from '@hooks/useModalConfirm';
import { App, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useMenuCategory = (item: Menu) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [updateMenu, { isSuccess: isSuccessUpdate, isError: isErrorUpdate }] = useUpdateMenuMutation();
  const [removeMenu, { isSuccess: isSuccessRemove, isError: isErrorRemove }] = useRemoveMenuMutation();
  const { showModalConfirm } = useModalConfirm();

  const [form] = useForm();
  const [modal, contextHolder] = Modal.useModal();

  const { id, name, menus_trees } = item;
  const MenuInfo = menus_trees;
  const isSubcategory = MenuInfo.level === 2;

  const [title, setTitle] = useState(name);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    form.setFieldValue('name', title);
  }, [title]);

  useEffect(() => {
    if (isSuccessUpdate) {
      message.success(t('updated'));
    }
  }, [isSuccessUpdate, isSuccessRemove]);

  useEffect(() => {
    if (isErrorUpdate || isErrorRemove) {
      message.error(t('error'));
    }
  }, [isErrorUpdate, isErrorRemove]);

  const edit = () => {
    setIsEdit(true);
  };

  const update = async (value: any) => {
    const data = {
      name: value.name,
      is_sub_category: isSubcategory,
      level: MenuInfo.level,
      ancestor: MenuInfo.ancestor,
      position: MenuInfo.position,
    };

    if (title !== value.name) {
      setTitle(value.name);
      await updateMenu({ id, data });
    }

    setIsEdit(false);
  };

  const remove = async (id: number) => {
    await removeMenu(id);
  };

  return {
    showModalConfirm,
    form,
    modal,
    contextHolder,
    edit,
    update,
    remove,
    isEdit,
    setIsEdit,
    isSubcategory,
    title,
  };
};

export default useMenuCategory;
