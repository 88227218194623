import { useCreateMenuMutation } from '@api/menuApi';
import { setCollapsedActiveKeys } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { FC, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MBCheckCircleIcon from '../../icons/MBCheckCircleIcon';
import RemoveIconButton from '../../UI/RemoveIconButton';

interface MenuCreateCategoryFormProps {
  setIsShowed: React.Dispatch<SetStateAction<boolean>>;
  parentCategory?: number;
}

const MenuCreateCategoryForm: FC<MenuCreateCategoryFormProps> = ({ setIsShowed, parentCategory }) => {
  const dispatch = useDispatch();
  const { collapsedActiveKeys } = useAppSelector((state) => state.menuSlice);
  const [createMenu, { isLoading, isSuccess, data }] = useCreateMenuMutation();
  const [form] = useForm();

  useEffect(() => {
    if (isSuccess) {
      setIsShowed(false);
      form.resetFields();

      if (data?.data?.menus_trees?.level !== 1) {
        dispatch(setCollapsedActiveKeys([...collapsedActiveKeys, data.data.ancestor]));
      }
    }
  }, [isSuccess]);

  const hideForm = () => {
    setIsShowed(false);
    form.resetFields();
  };

  const onFinish = async (value: any) => {
    const data = {
      name: value.name,
      is_sub_category: !!parentCategory || false,
      level: parentCategory ? 2 : 1,
      ancestor: parentCategory || null,
      position: 1,
    };

    createMenu(data);
  };

  return (
    <>
      <div className={'flex items-center gap-4'}>
        <div className={'flex gap-2'}>
          <RemoveIconButton className={'!h-8 !w-auto !p-2'} onClick={hideForm} />
          <Button
            type={'text'}
            className={'h-8 justify-center p-2'}
            onClick={form.submit}
            loading={isLoading}
            icon={<MBCheckCircleIcon type={'link'} className={'shrink-0 text-cyan-30'} />}
          />
        </div>
        <Form form={form} onFinish={onFinish} className={'w-full'}>
          <Form.Item rules={[{ required: true }]} name={'name'} noStyle className={'grow'}>
            <Input placeholder={'name'} className={'h-8 p-2'} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default MenuCreateCategoryForm;
