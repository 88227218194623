import { SVGProps } from 'react';
const DishPlaceholderLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={68} height={68} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M62.775 62.7772C55.8082 69.7409 44.4947 69.7409 37.4982 62.7772L5.22504 30.4884C-1.74168 23.5247 -1.74168 12.2162 5.22504 5.22276C12.1918 -1.74092 23.5053 -1.74092 30.5017 5.22276L62.775 37.4818C69.7417 44.4753 69.7417 55.7838 62.775 62.7772Z"
      fill="white"
    />
    <path
      opacity={0.3}
      d="M5.22504 62.775C-1.74168 55.8082 -1.74168 44.4947 5.22504 37.4982L37.4982 5.22504C44.465 -1.74168 55.7785 -1.74168 62.775 5.22504C69.7417 12.1918 69.7417 23.5052 62.775 30.5017L30.5017 62.775C23.5053 69.7417 12.1918 69.7417 5.22504 62.775Z"
      fill="white"
    />
    <g clipPath="url(#clip0_2904_38681)">
      <path
        d="M32.4696 22.2352V27.8799H31.4496V22.2699C31.4496 21.9884 31.2211 21.7599 30.9396 21.7599C30.6581 21.7599 30.4296 21.9884 30.4296 22.2699V27.8799H29.4096V22.2515C29.4096 21.5952 28.3896 21.6176 28.3896 22.2347V27.8799H27.3696V22.275C27.3696 21.5799 26.3496 21.6054 26.3496 22.2475V29.6282C26.3496 30.6925 27.3696 31.3943 28.3896 31.3943V44.7099C28.3896 46.7499 31.4496 46.7499 31.4496 44.7099V31.3948C32.4696 31.3948 33.4896 30.5472 33.4896 29.749V22.2515C33.4896 21.6013 32.4696 21.6263 32.4696 22.2352ZM37.0596 23.7999V36.5499H38.0796V44.7099C38.0796 46.7499 41.6496 46.7499 41.6496 44.7099V23.7999C41.6496 21.2499 37.0596 21.2499 37.0596 23.7999Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2904_38681">
        <rect width={25.5} height={25.5} fill="white" transform="translate(21.25 21.25)" />
      </clipPath>
    </defs>
  </svg>
);
export default DishPlaceholderLogo;
