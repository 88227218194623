import PaymentResponse from '@api/models/PaymentResponse';
import SubscriptionPackage from '@api/models/SubscriptionPackage';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';
import { PaymentRequestBody } from './models/PaymentRequestBody';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPackages: builder.query<SubscriptionPackage[], void>({
      query: () => addTenantToEndpoint(`/packages`),
      transformResponse: (response: { data: SubscriptionPackage[] }) => response.data,
    }),
    setFreeSubscriptionPackage: builder.mutation<any, { restId: number; managerId: number }>({
      query: ({ restId, managerId }) => ({
        url: `restaurant/${restId}/manager/${managerId}/subscribe/package/2`,
        method: 'POST',
      }),
      transformResponse: (response: { data: any }) => response.data.package as SubscriptionPackage,
    }),
    createPackagePayment: builder.mutation<
      PaymentResponse,
      { requestBody: PaymentRequestBody; restId: number; managerId: number; packageId: number }
    >({
      query: ({ requestBody, restId, managerId, packageId }) => ({
        url: `/payment/restaurant/${restId}/manager/${managerId}/package/${packageId}`,
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: { data: PaymentResponse }) => response.data,
    }),
  }),
});

export const {
  useGetSubscriptionPackagesQuery,
  useSetFreeSubscriptionPackageMutation,
  useCreatePackagePaymentMutation,
} = extendedApiSlice;
