import { SVGProps } from 'react';
const NapkinHolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 17H28.5M2.5 17V27C2.5 28.1046 3.39543 29 4.5 29H26.5C27.6046 29 28.5 28.1046 28.5 27V17M2.5 17L5.93446 13.9948C6.29904 13.6758 6.76702 13.5 7.25147 13.5H9M28.5 17L25.5858 14.0858C25.2107 13.7107 24.702 13.5 24.1716 13.5H22.5"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 5L10.4472 12.8944C10.786 13.572 11.4785 14 12.2361 14H18.7639C19.5215 14 20.214 13.572 20.5528 12.8944L24.5 5"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 5L8.47153 3.68565C9.10184 3.26544 9.91548 3.23741 10.5732 3.61326L12.0077 4.43298C12.6226 4.78434 13.3774 4.78434 13.9923 4.43298L15.3198 3.67441C16.0321 3.26736 16.9208 3.33664 17.5614 3.84916L17.858 4.08642C18.5381 4.6305 19.4921 4.67191 20.2168 4.18879L20.7832 3.81121C21.5079 3.32809 22.4619 3.36949 23.142 3.91358L24.5 5"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 13.5L13 9.5M17.5 13.5L18.5 9.5"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NapkinHolderIcon;
