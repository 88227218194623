import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpLazyLoad from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
export enum Language {
  en = 'en-US',
  he = 'he',
}

i18n
  .use(LanguageDetector)
  .use(HttpLazyLoad)
  .use(initReactI18next)
  .init(
    {
      lng: 'he',
      fallbackLng: Language.he,
      supportedLngs: [Language.he, Language.en],
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
      backend: {
        loadPath: '/locales/{{lng}}.json',
        allowMultiLoading: false,
        crossDomain: false,
        withCredentials: false,
        reloadInterval: false,
      },
      // useStorage: true,
      // localStorageKey: 'language',
    },
    () => {}
  );

export default i18n;
