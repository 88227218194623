import { useLazyGetPromotionByIdQuery } from '@api/promotionApi';

const useClientPromotionItem = () => {
  const [getPromotionDetails, { data: promotionDetails, isFetching, isError }] = useLazyGetPromotionByIdQuery();

  return {
    getPromotionDetails,
    promotionDetails,
    isFetching,
    isError,
  };
};

export default useClientPromotionItem;
