export const serverUrl = process.env.REACT_APP_PUBLIC_API_URL as string;
export const domain = process.env.REACT_APP_DOMAIN as string;
export const port = process.env.REACT_APP_PORT as string;
export const centralDomain = process.env.REACT_APP_CENTRAL_URL_DOMAIN as string;

export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY as string;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER as string;
export const PUSHER_APP_INSTANCE_ID = process.env.REACT_APP_PUSHER_APP_INSTANCE_ID as string;

export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

export const REACT_APP_SALT = process.env.REACT_APP_SALT!;
