import { SVGProps } from 'react';
const MBTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.00586 8.91016L2.99371 17.9102M12.0058 12.9102L11.9937 21.9102M21.0058 8.91016L20.9937 17.9102M12 3L2 8L12 13L22 8L12 3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MBTableIcon;
