import { SVGProps } from 'react';
const MobileCategoryLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M44.3117 44.3133C39.394 49.2289 31.4081 49.2289 26.4693 44.3133L3.68827 21.5212C-1.22942 16.6057 -1.22942 8.62319 3.68827 3.68665C8.60595 -1.22888 16.5919 -1.22888 21.5306 3.68665L44.3117 26.4578C49.2294 31.3943 49.2294 39.3768 44.3117 44.3133Z"
      fill="url(#paint0_linear_2794_8058)"
    />
    <path
      opacity={0.3}
      d="M3.68827 44.3117C-1.22942 39.394 -1.22942 31.408 3.68827 26.4693L26.4693 3.68827C31.387 -1.22942 39.373 -1.22942 44.3117 3.68827C49.2294 8.60595 49.2294 16.5919 44.3117 21.5306L21.5306 44.3117C16.5919 49.2294 8.60595 49.2294 3.68827 44.3117Z"
      fill="url(#paint1_linear_2794_8058)"
    />
    <path
      d="M22.9201 15.6955V19.68H22.2001V15.72C22.2001 15.5212 22.0388 15.36 21.8401 15.36C21.6414 15.36 21.4801 15.5212 21.4801 15.72V19.68H20.7601V15.707C20.7601 15.2437 20.0401 15.2595 20.0401 15.6951V19.68H19.3201V15.7236C19.3201 15.2329 18.6001 15.2509 18.6001 15.7041V20.914C18.6001 21.6654 19.3201 22.1607 20.0401 22.1607V31.56C20.0401 33 22.2001 33 22.2001 31.56V22.1611C22.9201 22.1611 23.6401 21.5628 23.6401 20.9994V15.707C23.6401 15.248 22.9201 15.2656 22.9201 15.6955ZM26.1601 16.8V25.8H26.8801V31.56C26.8801 33 29.4001 33 29.4001 31.56V16.8C29.4001 15 26.1601 15 26.1601 16.8Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2794_8058"
        x1={28.166}
        y1={2.81438}
        x2={19.8573}
        y2={45.1872}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2794_8058"
        x1={45.1859}
        y1={28.1474}
        x2={2.79584}
        y2={19.8317}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default MobileCategoryLogo;
