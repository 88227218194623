import { ClientOrder } from '@api/models/Order';
import MBWaiterIcon from '@components/icons/MBWaiterIcon';
import SuprisedLogo from '@components/svg/SuprisedLogo';
import OrderSection from '@components/UI/OrderSection';
import colors from '@constants/colors';
import { CURRENCY } from '@constants/constants';
import { List } from 'antd';
import { t } from 'i18next';
import { FC } from 'react';

const ClientOrderDishList: FC<{ order: ClientOrder }> = ({ order }) => {
  const waiterName = order.waiter?.first_name ? `${order.waiter.first_name} ${order.waiter.last_name}` : null;

  return (
    <>
      {order.status.status !== 'completed' && (
        <div
          className={`mb-6 flex flex-wrap items-center gap-3 px-[6px] ${
            waiterName ? 'justify-between' : 'justify-center'
          }`}
        >
          {waiterName && (
            <div className={'flex items-center gap-[5px]'}>
              <MBWaiterIcon color={colors.indigo30} />
              <div className={'w-full leading-[150%] text-grey-40'}>
                <span className={'text-grey-30'}>{t('your_waiter')}: </span>
                {waiterName}
              </div>
            </div>
          )}
          <span className={'min-w-max rounded bg-indigo-40 px-2 py-1 text-sm leading-[150%] text-white'}>
            {order.table?.name || 'TakeAway'}
          </span>
        </div>
      )}
      {order.order_items && (
        <div className={'flex flex-col gap-4'}>
          {order.order_items.map((orderItem) => (
            <OrderSection
              key={`order-key-${orderItem.id}-${orderItem.order_id}`}
              isWhiteBg={order.status.status === 'completed'}
            >
              {orderItem.promotion && <SuprisedLogo />}
              <div>
                <span className={'font-bold text-indigo-40'}>
                  {orderItem.dish?.dish_name || orderItem.promotion?.dish?.dish_name}
                </span>
                <p className={'mb-0'}>{orderItem.dish?.description || orderItem.promotion?.dish?.description}</p>
              </div>
              {((orderItem.options && orderItem.options?.length > 0) ||
                (orderItem.extras && orderItem.extras?.length > 0)) && (
                <List>
                  {orderItem.options?.map((option) => (
                    <li key={`opt-${option.name}-${order.id}-${option.id}`}>{option.values?.value}</li>
                  ))}
                  {orderItem.extras?.map((extra) =>
                    extra.is_readily_available ? (
                      <li key={`ext-${extra.name}-${order.id}-${extra.id}`}>{extra.name}</li>
                    ) : (
                      ''
                    )
                  )}
                </List>
              )}
              <div className={'flex items-center justify-between gap-4'}>
                <div>{t('amount')}:</div>
                <div className={'flex items-center gap-4'}>{orderItem.quantity}</div>
              </div>
              <div className={'flex items-center justify-between gap-4'}>
                <div>{t('cost')}:</div>
                <div>
                  {CURRENCY}
                  {orderItem.price}
                </div>
              </div>
            </OrderSection>
          ))}
          <OrderSection key={`order-key-${order.id}`} isWhiteBg={order.status.status === 'completed'}>
            <div className={'flex items-center justify-between gap-4 leading-[120%] text-black-custom'}>
              <div>{t('total_cost')}:</div>
              <div className={'font-bold'}>
                {CURRENCY}
                {order.total}
              </div>
            </div>
          </OrderSection>
        </div>
      )}
    </>
  );
};

export default ClientOrderDishList;
