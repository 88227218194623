import CutleryIcon from '@base/components/icons/CutleryIcon';
import MBCloseIcon from '@base/components/icons/MBCloseIcon';
import BigJarIcon from '@components/icons/BigJarIcon';
import MBCallWaiterIcon from '@components/icons/MBCallWaiterIcon';
import NapkinHolderIcon from '@components/icons/NapkinHolderIcon';
import useHeaderCallToWaiterModal from '@layout/Header/HeaderCallToWaiterModal/useHeaderCallToWaiterModal';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import CallToWaiterOption from './CallToWaiterOption';

const iconsServices = {
  cutlery: <CutleryIcon width={32} height={32} className={'call-waiter-option__cutlery-icon'} />,
  water: <BigJarIcon width={32} height={32} className={'call-waiter-option__jar-icon'} />,
  napkins: <NapkinHolderIcon width={32} height={32} className={'call-waiter-option__napkin-icon'} />,
  service: <MBCallWaiterIcon width={32} height={32} className={'call-waiter-option__call-waiter-icon'} />,
};

interface HeaderCallToWaiterModalProps {
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

const HeaderCallToWaiterModal: FC<HeaderCallToWaiterModalProps> = ({ setIsOpened }) => {
  const { t } = useTranslation();
  const { services, isLoading, isError } = useHeaderCallToWaiterModal();

  const renderServicesElements = () =>
    services?.map(({ id, name, isRequested }) => {
      const title = t(`call_${name}_title`);
      const icon = iconsServices[name];

      return <CallToWaiterOption key={id} service_id={id} icon={icon} title={title} isActive={isRequested} />;
    });

  return (
    <div className={'header-modal header-modal--sm-gap site-header__modal'}>
      <div
        className={'header-modal__close'}
        onClick={() => {
          setIsOpened(false);
        }}
      >
        <MBCloseIcon />
      </div>
      <div className={'header-modal__header'}>
        <span className="header-modal__title">{t('call_the_waiter')}?</span>
      </div>

      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : (
        services.length > 0 && <div className={'modal-call-waiter'}>{renderServicesElements()}</div>
      )}
    </div>
  );
};

export default HeaderCallToWaiterModal;
