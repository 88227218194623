import colors from '@base/utils/constants/colors';

export const whiteBackgroundSelect = {
  components: {
    Select: {
      colorBgContainer: '#FFF',
      colorBorder: colors.grey15,
      controlHeight: 29,
      controlPaddingHorizontal: 8,
      borderRadius: 4,
      fontSize: 14,
      colorText: colors.grey30,
    },
  },
};
