import { useGetPopularPromotionsQuery } from '@api/DashboardApi';

const useDashboardPromotions = (filter: string) => {
  const { data = [], isLoading, isError } = useGetPopularPromotionsQuery(filter, { refetchOnMountOrArgChange: true });

  const promotions = data.map((promotion) => ({
    id: promotion.promotion_id,
    name: promotion.promotion.name,
    value: +promotion.total_quantity,
  }));

  return {
    promotions,
    isLoading,
    isError,
  };
};

export default useDashboardPromotions;
