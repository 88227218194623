import { WorkHours } from '@base/types/WorkHours';
import MBPhoneFilledIcon from '@components/icons/MBPhoneFilledIcon';
import { weekDaysArray } from '@constants/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RestaurantContactsProps {
  work_hours: WorkHours | null;
  description_schedule: string | null;
  address: string | null;
  phone_number: string | null;
}

const RestaurantContacts: FC<RestaurantContactsProps> = ({
  work_hours,
  description_schedule,
  phone_number,
  address,
}) => {
  const { t } = useTranslation();

  function getArraySchedule(daysHours: WorkHours): string[] {
    const result: string[] = [];

    weekDaysArray.forEach((day) => {
      const currentDay = daysHours[day];
      if (currentDay.day_off && currentDay.from && currentDay.to) {
        result.push(`${currentDay.from} - ${currentDay.to}, ${t(day)}`);
      }
    });

    return result;
  }

  let restaurantWorkHours: string[] = [];

  if (work_hours) {
    restaurantWorkHours = getArraySchedule(work_hours);
  }

  return (
    <div className={'restaurant-footer-contacts'}>
      {(restaurantWorkHours.length > 0 || description_schedule) && (
        <>
          <h6 className={'restaurant-footer-contacts__title text-md font-bold leading-[1.5]'}>
            {t('restaurant_work_hours')}
          </h6>
          <ul className={'restaurant-footer-work-hours list-none p-0 leading-normal'}>
            {restaurantWorkHours &&
              restaurantWorkHours.map((item, i) => {
                return <li key={`hour-${i}`}>{item}</li>;
              })}
            {description_schedule && <li>{description_schedule}</li>}
          </ul>
        </>
      )}

      {phone_number && (
        <a
          href={`tel:${phone_number}`}
          className={
            'restaurant-footer-contacts__telephone my-4 flex items-center justify-center gap-2 text-md font-bold text-white'
          }
        >
          <MBPhoneFilledIcon className={'text-2xl'} />
          {phone_number}
        </a>
      )}

      {address && (
        <>
          <h6 className={'restaurant-footer-contacts__address-title mb-0 text-md font-bold leading-[1.5]'}>
            {t('restaurant_address')}
          </h6>
          <address className={'restaurant-footer-contacts__address'}>{address}</address>
        </>
      )}
    </div>
  );
};

export default RestaurantContacts;
