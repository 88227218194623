import colors from './utils/constants/colors';
import stylesProperties from './utils/constants/styles';

const antdTheme = {
  token: {
    colorPrimary: colors.cyan30,
    colorError: colors.red,
    fontSize: 16,
    colorText: colors.grey40,
    colorLink: colors.cyan30,
    screenSM: 640,
    fontFamily: 'Inter, system-ui, sans-serif',
  },

  components: {
    Typography: {
      lineHeight: 1.5,
      colorTextHeading: colors.indigo40,
    },
    Button: {
      colorPrimary: colors.cyan30,
      colorPrimaryHover: colors.cyan40,
      fontSize: 16,
      fontSizeLG: 18,
      paddingContentHorizontal: 24,
      controlHeight: 40,
      borderRadius: 4,
      fontWeight: 700,
      dangerShadow: '',
      defaultShadow: '',
      primaryShadow: '',
      colorBgContainerDisabled: 'rgb(214, 216, 225)',
      colorTextDisabled: colors.grey20,
      marginXS: 0,
      defaultBorderColor: colors.cyan30,
      colorText: colors.cyan30,
      paddingInlineSM: 8,
      controlHeightSM: 36,
      textHoverBg: 'rgba(0, 0, 0, 0.06)',
    },
    Switch: {
      handleSize: 14,
      trackHeight: 18,
      trackMinWidth: 36,
      colorTextQuaternary: 'rgb(224, 229, 242)',
    },

    Checkbox: {
      colorBorder: colors.cyan30,
    },

    Radio: {
      colorWhite: colors.cyan30,
      colorBorder: colors.cyan30,
      radioSize: 14,
      dotSize: 9,
    },
    Input: {
      colorBgContainer: colors.colorBgInput,
      colorBorder: 'rgb(243, 244, 246)',
      colorTextPlaceholder: 'rgba(100, 107, 135, 0.5)',
      controlHeight: 40,
      controlHeightLG: 40,
      paddingBlock: 7.2,
      paddingInline: 16,
    },

    Select: {
      colorBgContainer: colors.colorBgInput,
      colorBorder: 'rgb(243, 244, 246)',
      controlHeight: 40,
      borderRadius: 10,
      optionSelectedBg: 'rgba(0, 0, 0, 0)',
      fontWeightStrong: 400,
    },

    Form: {
      labelFontSize: 14,
      verticalLabelPadding: '0 0 4px',
      marginLG: 16,
      labelRequiredMarkColor: colors.cyan30,
    },

    Dropdown: {
      borderRadiusLG: 4,
      boxShadowSecondary: stylesProperties.boxShadow,
      boxShadowPopoverArrow: stylesProperties.boxShadow,
      colorText: colors.grey30,
      controlPaddingHorizontal: 8,
      paddingXXS: 0,
      lineHeight: 1,
      controlItemBgHover: colors.grey10,
    },

    Menu: {
      controlItemBgActive: 'rgb(230, 240, 255)',
      marginXXS: 0,
      itemBorderRadius: 0,
      itemSelectedColor: colors.indigo40,
      iconSize: 24,
      collapsedIconSize: 24,
      itemHoverBg: 'rgba(0, 0, 0, 0)',
      itemHoverColor: colors.indigo40,
    },
    Card: {
      borderRadiusLG: 10,
    },
    Modal: {
      colorBgMask: 'rgba(0, 0, 0, 0.8)',
      colorIcon: colors.grey40,
      titleFontSize: 26,
    },

    Tabs: {
      inkBarColor: 'rgb(46, 181, 254)',
      itemColor: colors.indigo40,
      colorFillAlter: 'rgba(230, 240, 255, 0.5)',
      colorPrimary: colors.indigo40,
      fontSize: 16,
      lineWidth: 0,
      marginXXS: 0,
      borderRadius: 0,
      borderRadiusLG: 0,
      lineWidthBold: 4,
    },
    Alert: {
      colorErrorBg: 'rgb(255, 245, 245)',
      colorErrorBorder: 'rgb(245, 101, 101)',
      defaultPadding: '15px 24px',
      lineHeight: 1.5,
      borderRadiusLG: 10,
    },
    Pagination: {
      colorText: colors.indigo40,
      colorBorder: 'rgba(217, 217, 217, 0)',
      colorPrimaryBorder: 'rgba(153, 158, 163, 0)',
      borderRadius: 50,
      colorBgContainer: 'rgb(66, 134, 251)',
      colorPrimaryHover: colors.white,
      colorPrimary: colors.white,
      controlHeight: 28,
      fontSize: 14,
    },
    Badge: {
      indicatorHeight: 24,
      textFontSize: 14,
      colorBorderBg: 'none'
    },
  },
};

export default antdTheme;
