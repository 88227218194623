import RestaurantSettingsForm from '@components/RestaurantSettings/RestaurantSettingsForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const RestaurantSettingsPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.restaurant_settings'));

  return <RestaurantSettingsForm />;
};

export default RestaurantSettingsPage;
