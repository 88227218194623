import emptyMenusImage from '@assets/img/empty_cabinet_menus.png';
import UserAccess from '@base/hoc/UserAccess';
import DndDraggableCategory from '@components/Menus/Dnd/DndDraggableCategory';
import DndDraggableDish from '@components/Menus/Dnd/DndDraggableDish';
import DndDraggableSubcategory from '@components/Menus/Dnd/DndDraggableSubcategory';
import useDndMenuCategories from '@components/Menus/Dnd/DndMenuCategories/useDndMenuCategories';
import MenuCreateCategory from '@components/Menus/MenuCreateCategory';
import { ROUTES } from '@constants/routes';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { menuCollapseTheme } from '@pages/MenusPage/menuCollapse.antd';
import { Button, ConfigProvider } from 'antd';
import { FC } from 'react';
import { createPortal } from 'react-dom';

export const DND_CATEGORY_TYPE = 'Category';
export const DND_SUBCATEGORY_TYPE = 'Subcategory';
export const DND_DISH_TYPE = 'Dish';

export const overClasses = 'border-2 border-solid border-grey-20';

const DndMenuCategories: FC = () => {
  const {
    t,
    categories,
    categoriesDndIDs,
    activeCategory,

    subcategories,
    activeSubcategory,

    dishes,
    activeDish,

    isLoading,
    isError,

    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = useDndMenuCategories();

  const renderEmptyMenus = () => (
    <div className={'mb-4 flex flex-col items-center gap-4'}>
      <p className={'mb-0'}>{t('empty_menus_title')}</p>
      <img src={emptyMenusImage} alt="" />
      <p className={'mb-0'}>{t('empty_menus_desc')}</p>
    </div>
  );

  const renderCategories = () => (
    <ConfigProvider theme={menuCollapseTheme}>
      <DndContext onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
        <div className={'mb-4 flex flex-col gap-4'}>
          <SortableContext items={categoriesDndIDs} strategy={verticalListSortingStrategy}>
            {categories.map((category, i) => (
              <DndDraggableCategory
                key={category.dnd_id}
                category={category}
                subcategories={subcategories.filter((sub) => sub.dnd_ancestor_id === category.dnd_id)}
                categoryDishes={dishes.filter((dish) => dish.dnd_ancestor_id === category.dnd_id)}
                dishes={dishes}
                isFirstCategory={i === 0}
              />
            ))}
          </SortableContext>
        </div>
        {createPortal(
          <DragOverlay>
            {activeCategory && (
              <DndDraggableCategory
                key={activeCategory.dnd_id}
                category={activeCategory}
                subcategories={subcategories.filter((sub) => sub.dnd_ancestor_id === activeCategory.dnd_id)}
              />
            )}
            {activeSubcategory && (
              <DndDraggableSubcategory key={activeSubcategory.dnd_id} subcategory={activeSubcategory} />
            )}
            {activeDish && <DndDraggableDish key={activeDish.dnd_id} dish={activeDish} />}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </ConfigProvider>
  );

  return (
    <>
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : categories.length > 0 ? (
        <>{renderCategories()}</>
      ) : (
        renderEmptyMenus()
      )}

      <UserAccess roles={['restaurant-manager']}>
        <MenuCreateCategory />
      </UserAccess>

      {categories.length > 0 && (
        <UserAccess roles={['restaurant-manager']}>
          <div className={'mt-6 text-center'}>
            <Button href={ROUTES.menu_preview} target={'_blank'}>
              {t('preview')}
            </Button>
          </div>
        </UserAccess>
      )}
    </>
  );
};

export default DndMenuCategories;
