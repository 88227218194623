import { resetCallsCounter, setCallsDrawerOpen } from '@base/redux/features/waiterSlice';
import getQueryParam from '@helpers/getQueryParam';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const QUERY_PUSH_NOTIFY_PARAM = 'show_call';

const useCallToWaiterRequests = () => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const showCallID = getQueryParam(QUERY_PUSH_NOTIFY_PARAM);

  function openDrawer(flag: boolean): void {
    dispatch(setCallsDrawerOpen(flag));
    setIsOpened(flag);

    if (flag) {
      dispatch(resetCallsCounter());
    }
  }

  useEffect(() => {
    if (showCallID) {
      openDrawer(true);
    }
  }, [showCallID]);

  return {
    isOpened,
    setIsOpened,
    openDrawer,
    showCallID: showCallID ? +showCallID : undefined,
  };
};

export default useCallToWaiterRequests;
