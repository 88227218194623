import { useRemoveWaiterCallMutation } from '@api/callToWaiterApi';
import { useAppSelector } from '@base/redux/store';

const useCallToWaiterRequest = () => {
  const [removeCall] = useRemoveWaiterCallMutation();
  const waiter_id = useAppSelector((state) => state.userState.id);

  const removeWaiterCall = async (client_id: number, table_id: number) => {
    if (!table_id || !waiter_id || !client_id) {
      return;
    }

    await removeCall({ table_id, waiter_id, client_id });
  };

  return {
    removeWaiterCall,
    waiter_id,
  };
};

export default useCallToWaiterRequest;
