import { COOKIE_TOKEN_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const removeTokenFromCookies = () => {
  Cookies.remove(COOKIE_TOKEN_KEY, {
    path: '/',
    domain,
  });
};
