import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useSteps = () => {
  const { t } = useTranslation();

  const obStepRef1 = useRef(null);
  const obStepRef2 = useRef(null);
  const obStepRef3 = useRef(null);

  const steps = [
    {
      title: t('onboarding.menus.step_1.title'),
      description: t('onboarding.menus.step_1.desc'),
      target: () => obStepRef1.current,
    },
    {
      title: t('onboarding.menus.step_2.title'),
      description: t('onboarding.menus.step_2.desc'),
      target: () => obStepRef2.current,
    },
    {
      title: t('onboarding.menus.step_3.title'),
      description: t('onboarding.menus.step_3.desc'),
      target: () => obStepRef3.current,
    },
  ];

  return {
    obStepRef1,
    obStepRef2,
    obStepRef3,
    steps,
  };
};

export default useSteps;
