import {
  useCreateWorkerMutation,
  useGetWorkersQuery,
  useRemoveWorkerMutation,
  useUpdateWorkerMutation,
} from '@api/workersApi';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

const useWorkers = () => {
  const [form] = useForm();
  const { data, isLoading: isGetting, isError: isGettingError } = useGetWorkersQuery();

  const [createWorker, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] = useCreateWorkerMutation();
  const [updateWorker, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] = useUpdateWorkerMutation();
  const [removeWorkers] = useRemoveWorkerMutation();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const workers = data?.data;

  useEffect(() => {
    if ((!isLoadingCreate && isSuccessCreate) || (!isLoadingUpdate && isSuccessUpdate)) {
      setIsModalOpened(false);
    }
  }, [isLoadingCreate, isLoadingUpdate]);

  const handleAddUser = () => {
    form.resetFields();
    setIsModalOpened(true);
  };

  const handleCreate = async (value: any) => {
    await createWorker(value);
  };

  const handleUpdate = async (value: any) => {
    await updateWorker({ id: value.id, data: value });
  };

  const handleRemove = async (id: string) => {
    await removeWorkers(id);
  };

  return {
    form,
    isModalOpened,
    setIsModalOpened,
    workers,
    isGetting,
    isGettingError,
    isLoading: isLoadingCreate || isLoadingUpdate,
    handleAddUser,
    handleCreate,
    handleUpdate,
    handleRemove,
  };
};

export default useWorkers;
