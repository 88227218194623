import SmileFaceRatingFiveIcon from '@components/icons/SmileFaceRatingFiveIcon';
import SmileFaceRatingFourIcon from '@components/icons/SmileFaceRatingFourIcon';
import SmileFaceRatingOneIcon from '@components/icons/SmileFaceRatingOneIcon';
import SmileFaceRatingThreeIcon from '@components/icons/SmileFaceRatingThreeIcon';
import SmileFaceRatingTwoIcon from '@components/icons/SmileFaceRatingTwoIcon';
import { Form, Radio } from 'antd';
import { Rule } from 'antd/es/form';
import { FC } from 'react';

interface OrderFeedbackRatingRadioProps {
  name: string;
  title: string;
  rules: Rule[];
}

const FeedbackRadioGroup: FC<{ title: string }> = ({ title, ...props }) => {
  return (
    <div className={'flex flex-col gap-6 py-4'}>
      <p className={'m-0 text-base text-grey-40'}>{title}</p>
      <Radio.Group className={'radio-group-feedback flex items-center justify-center gap-4'} {...props}>
        <Radio value={5} className={'radio-group-feedback__rate-five'}>
          <SmileFaceRatingFiveIcon fontSize={40} />
        </Radio>
        <Radio value={4} className={'radio-group-feedback__rate-four'}>
          <SmileFaceRatingFourIcon fontSize={40} />
        </Radio>
        <Radio value={3} className={'radio-group-feedback__rate-three'}>
          <SmileFaceRatingThreeIcon fontSize={40} />
        </Radio>
        <Radio value={2} className={'radio-group-feedback__rate-two'}>
          <SmileFaceRatingTwoIcon fontSize={40} />
        </Radio>
        <Radio value={1} className={'radio-group-feedback__rate-one'}>
          <SmileFaceRatingOneIcon fontSize={40} />
        </Radio>
      </Radio.Group>
    </div>
  );
};

const OrderFeedbackRatingRadio: FC<OrderFeedbackRatingRadioProps> = ({ name, title, rules }) => {
  return (
    <Form.Item className={'m-0 w-full border-0 border-b-[1px] border-solid border-grey-15'} name={name} rules={rules}>
      <FeedbackRadioGroup title={title} />
    </Form.Item>
  );
};

export default OrderFeedbackRatingRadio;
