import { Allergy, Dish, DishType } from '@api/models/Dish';
import { MenuDish } from '@api/models/Menu';
import { generateQueryStringFromArrayObjects } from '@base/utils/helpers/generateQueryStringFromArrayObjects';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Dish'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllDishes: builder.query<MenuDish[], number | null>({
        query(restaurant_id) {
          return {
            url: addTenantToEndpoint(`/dishes-all/${restaurant_id}`),
          };
        },
        transformResponse: (response: { data: MenuDish[] }) => response.data,
        providesTags: ['Dish'],
      }),

      getDishById: builder.query<Dish, number>({
        query: (id) => addTenantToEndpoint(`/dish/${id}`),
        transformResponse: (response: { data: Dish }) => response.data,
      }),
      getDishByCategoryId: builder.query<
        Dish[],
        { allergies?: Allergy[] | []; dishTypes?: DishType[] | []; isTakeAway?: boolean; id: number }
      >({
        query: ({ allergies, dishTypes, isTakeAway, id }) => {
          // TODO: make research about passing arrays as params in RTK
          const queryParams = [];
          const allergiesStringQuery = generateQueryStringFromArrayObjects(allergies as [], 'allergies', 'code');
          const dishTypesStringQuery = generateQueryStringFromArrayObjects(dishTypes as [], 'dish_type', 'code');

          if (allergiesStringQuery) {
            queryParams.push(allergiesStringQuery);
          }

          if (dishTypesStringQuery) {
            queryParams.push(dishTypesStringQuery);
          }

          if (isTakeAway) {
            queryParams.push('is_take_away=true');
          }

          const queryParamsString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

          return addTenantToEndpoint(`/dishes/by-menu/${id}${queryParamsString}`);
        },
        transformResponse: (response: { data: Dish[] }) => response.data,
        // providesTags: ['Dish'],
      }),
      createDish: builder.mutation<Dish, any>({
        query(data) {
          const formData = new FormData();
          formData.append('data', JSON.stringify(data));

          if (data.image) {
            formData.append('image', data.image);
          }

          return {
            url: `/dish`,
            method: 'POST',
            body: formData,
            formData: true,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Dish'],
      }),

      updateDish: builder.mutation<any, { id: number; data: Dish }>({
        query({ id, data }) {
          return {
            url: `/dish/${id}`,
            method: 'PUT',
            body: data,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Dish'],
      }),

      updateMobileDish: builder.mutation<any, { id: number; data: Dish }>({
        query({ id, data }) {
          return {
            url: `/dish/toggle/${id}`,
            method: 'PATCH',
            body: data,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Dish'],
      }),

      updateDishBySupervisor: builder.mutation<any, { id: number; data: Dish }>({
        query({ id, data }) {
          return {
            url: `/tenant/dish/toggle/${id}`,
            method: 'PATCH',
            body: data,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Dish'],
      }),

      removeDish: builder.mutation<void, number>({
        query(id) {
          return {
            url: `/dish/${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['Dish'],
      }),

      updateDishImage: builder.mutation<Dish, any>({
        query({ id, image }) {
          const formData = new FormData();
          formData.append('image', image);

          return {
            url: `/dish/image/update/${id}`,
            method: 'POST',
            body: formData,
            formData: true,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Dish'],
      }),
      changePositionDish: builder.mutation<any, { id: number; data: { menu_id: number; position: number } }>({
        query({ id, data }) {
          return {
            url: `/dish/position/${id}`,
            method: 'PATCH',
            body: data,
          };
        },
        invalidatesTags: ['Dish'],
      }),
    }),
  });

export const {
  useGetDishByIdQuery,
  useGetDishByCategoryIdQuery,
  useUpdateDishMutation,
  useCreateDishMutation,
  useRemoveDishMutation,
  useUpdateDishImageMutation,
  useUpdateDishBySupervisorMutation,
  useUpdateMobileDishMutation,
  useLazyGetDishByIdQuery,
  useLazyGetDishByCategoryIdQuery,
  useChangePositionDishMutation,
  useLazyGetAllDishesQuery,
  useGetAllDishesQuery,
} = extendedApiSlice;
