import { Worker } from '@api/models/Worker';
import ConfirmModalContent from '@components/ConfirmModalContent';
import WorkerSwitcher from '@components/Workers/WorkerSwitcher';
import useModalConfirm from '@hooks/useModalConfirm';
import { Button, ConfigProvider, FormInstance, Modal, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import MBEditIcon from '../../icons/MBEditIcon';
import RemoveIconButton from '../../UI/RemoveIconButton';
import usersTableTheme from './workersTable.antd';
interface DataType extends Omit<Worker, 'role'> {
  key: string;
  role: string;
  isEdit: boolean;
}

function getColumns(
  editAction: (user: DataType) => void,
  removeConfirmAction: (id: string, firstName: string) => void,
  t: (key: any) => any
) {
  const columns: ColumnsType<DataType> = [
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          {record.isEdit && (
            <Space className={'gap-0'}>
              <RemoveIconButton
                className={'text-2xl'}
                onClick={() => removeConfirmAction(record.id, record.first_name)}
              />
              <Button type={'text'} className={'w-8 justify-center text-2xl'} onClick={() => editAction(record)}>
                <MBEditIcon type={'link'} className={'shrink-0 text-indigo-30'} />
              </Button>
            </Space>
          )}
        </>
      ),
      width: 100,
    },
    {
      title: t('first_name'),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: t('last_name'),
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: t('phone'),
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: t('availability'),
      key: 'is_active',
      width: 100,
      fixed: 'right',
      align: 'left',
      className: 'bg-white',
      render: (_, record) => (
        <>
          {record.isEdit && (
            <Tooltip
              key={`is-available-${record.id}-${record.is_active}`}
              title={!record.is_verified ? t('employee_not_registered_yet') : ''}
            >
              <span>
                <WorkerSwitcher id={+record.id} isActive={record.is_active} disabled={!record.is_verified} />
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return columns;
}

interface UsersTableProps {
  setIsModalOpened: React.Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  workers: Worker[];
  handleRemove: (id: string) => void;
}

const WorkersTable: FC<UsersTableProps> = ({ setIsModalOpened, form, workers, handleRemove }) => {
  const { t } = useTranslation();
  const { showModalConfirm } = useModalConfirm();
  const [modal, contextHolder] = Modal.useModal();

  const data: DataType[] = workers.map((item, index) => ({
    ...item,
    key: `${index + 1}`,
    role: item.roles[0].name,
    isEdit: index !== 0,
  }));

  const handleDeleteConfirm = (id: string, firstName: string) => {
    showModalConfirm({
      modal,
      title: t('deleting_user'),
      content: <ConfirmModalContent subtitle={t('do_you_down_remove_user_name', { n: firstName })} />,
      okCallback: () => handleRemove(id),
    });
  };

  const handleEdit = (user: DataType) => {
    if (form) {
      form.resetFields();
      form.setFields([
        { name: 'id', value: user.id },
        { name: 'first_name', value: user.first_name },
        { name: 'last_name', value: user.last_name },
        { name: 'phone_number', value: user.phone_number },
        { name: 'email', value: user.email },
        { name: 'is_active', value: user.is_active },
        { name: 'role', value: user.role },
      ]);
      setIsModalOpened(true);
    }
  };

  const columns = getColumns(handleEdit, handleDeleteConfirm, t);

  return (
    <ConfigProvider theme={usersTableTheme}>
      <Table size={'small'} columns={columns} dataSource={data} pagination={false} scroll={{ x: 1000 }} />
      {contextHolder}
    </ConfigProvider>
  );
};

export default React.memo(WorkersTable);
