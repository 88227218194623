import SuccessfulGuy from '@components/icons/SuccessfulGuy';
import { ROUTES } from '@utils/constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SuccessfulRegistration: FC<{ loginLink?: string }> = ({ loginLink }) => {
  const { t } = useTranslation();

  return (
    <div className={'lg: flex h-full p-6 text-center lg:px-14 lg:pb-10 lg:pt-20'}>
      <div className={'m-auto max-w-[448px] p-4'}>
        <div className={'mb-1 text-[26px] font-bold text-indigo-40'}>{t('we_are_glad_you_chose_us')}!</div>
        <p>{t('sent_you_login_link')}.</p>
        <SuccessfulGuy className={'mb-10 mt-10 lg:mb-[84px]'} />
        <Link to={loginLink || ROUTES.login} className={'block'}>
          {t('login')}
        </Link>
      </div>
    </div>
  );
};

export default SuccessfulRegistration;
