import { Inventory, InventoryDish as InventoryDishType } from '@api/models/Inventory';
import MBChevronDownIcon from '@components/icons/MBChevronDownIcon';
import { inventoryCollapseTheme } from '@components/Inventory/inventoryCollapse.antd';
import InventoryDishList from '@components/Inventory/InventoryDishList';
import InventorySubmenuList from '@components/Inventory/InventorySubmenuList';
import { Button, Collapse, CollapseProps, ConfigProvider } from 'antd';
import { FC, ReactNode } from 'react';

interface InventoryBoardProps {
  category: Inventory;
  subCategoryList: Inventory[];
  dishList: InventoryDishType[];
}

export function genExpandBtn(isActive: boolean): ReactNode {
  return (
    <Button
      type={'link'}
      className={'h-[2em] w-[1.8em] justify-center gap-0 bg-grey-5 p-1 text-center hover:bg-grey-5'}
    >
      <MBChevronDownIcon
        className={'m-0 text-2xl text-grey-20'}
        style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
      />
    </Button>
  );
}

const InventoryBoard: FC<InventoryBoardProps> = ({ category, subCategoryList, dishList }) => {
  const { name } = category;

  const filteredSubCategoryList = subCategoryList.filter((submenu) => submenu.menu_tree.ancestor === category.id);

  const isChildExist =
    category.dishes.length || filteredSubCategoryList.some((submenu) => submenu.dishes.length) || dishList.length;

  const renderChildren = () => {
    return (
      <div className={'mt-[-15px] flex flex-col gap-4 border-0 border-t-2 border-solid border-grey-15 pt-4'}>
        {filteredSubCategoryList.length > 0 && <InventorySubmenuList submenuList={filteredSubCategoryList} />}

        {dishList.length > 0 && <InventoryDishList dishList={dishList} />}
      </div>
    );
  };

  const collapseItems: CollapseProps['items'] = [
    {
      key: 1,
      label: <div className={'text-xl font-bold'}>{name}</div>,
      children: renderChildren(),
      collapsible: 'icon',
    },
  ];

  if (!isChildExist) {
    return null;
  }

  return (
    <ConfigProvider theme={inventoryCollapseTheme}>
      <Collapse
        className={'flex flex-col gap-4 text-md'}
        bordered={false}
        expandIconPosition={'end'}
        expandIcon={({ isActive }) => genExpandBtn(isActive as boolean)}
        items={collapseItems}
        defaultActiveKey={1}
      />
    </ConfigProvider>
  );
};

export default InventoryBoard;
