import { FC } from 'react';

const TourCounter: FC<{ current: number; total: number }> = ({ current, total }) => {
  return (
    <div className={'text-cyan-30'}>
      {current}/{total}
    </div>
  );
};

export default TourCounter;
