import api from '@api/api';
import { Restaurant } from '@api/models/Restaurant';
import { ClientTable } from '@api/models/Table';
import { ClientTableTakeAway } from '@api/models/TableTakeAway';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getRestaurantInfoById: builder.query<Restaurant, number | null>({
      query: (id) => addTenantToEndpoint(`/restaurant-info/${id}`),
      transformResponse: (response: { data: Restaurant }) => response.data,
    }),
    getRestaurantTables: builder.query<ClientTable[], void>({
      query: () => addTenantToEndpoint(`/tables-to-waiters`),
      transformResponse: (response: { data: ClientTable[] }) => response.data,
    }),
    getRestaurantTakeAwayTable: builder.query<ClientTableTakeAway | null, void>({
      query: () => addTenantToEndpoint(`/take-away-tables-to-waiters`),
      transformResponse: (response: { data: ClientTableTakeAway }) =>
        Array.isArray(response.data) && response.data.length === 0 ? null : response.data,
    }),
  }),
});

export const {
  useGetRestaurantInfoByIdQuery,
  useGetRestaurantTablesQuery,
  useGetRestaurantTakeAwayTableQuery,
  useLazyGetRestaurantInfoByIdQuery,
} = extendedApiSlice;
