import { SVGProps } from 'react';
const StatisticsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={49} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M44.3117 45.0633C39.394 49.9789 31.4081 49.9789 26.4693 45.0633L3.68827 22.2712C-1.22942 17.3557 -1.22942 9.37319 3.68827 4.43665C8.60595 -0.478884 16.5919 -0.478884 21.5306 4.43665L44.3117 27.2078C49.2294 32.1443 49.2294 40.1268 44.3117 45.0633Z"
      fill="currentColor"
    />
    <path
      opacity={0.3}
      d="M3.68827 45.0617C-1.22942 40.144 -1.22942 32.158 3.68827 27.2193L26.4693 4.43827C31.387 -0.479422 39.373 -0.479422 44.3117 4.43827C49.2294 9.35595 49.2294 17.3419 44.3117 22.2806L21.5306 45.0617C16.5919 49.9794 8.60595 49.9794 3.68827 45.0617Z"
      fill="currentColor"
    />
    <path
      d="M31.3333 20.75L25 27.0833L21.6667 23.75L16.6667 28.75"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M27.3333 20.75H31.3333V24.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default StatisticsLogo;
