import MBUserLoggedInIcon from '@base/components/icons/MBUserLoggedInIcon';
import { useAppSelector } from '@base/redux/store';
import { centralDomain } from '@base/utils/constants/environments';
import { ROUTES } from '@base/utils/constants/routes';
import MBBurgerIcon from '@components/icons/MBBurgerIcon';
import MBLogoutIcon from '@components/icons/MBLogoutIcon';
import useAuth from '@hooks/useAuth';
import useCheckRole from '@hooks/useCheckRole';
import useLogout from '@hooks/useLogout';
import ClientSidepanel from '@layout/ClientSidepanel';
import Sidepanel from '@layout/Sidepanel';
import { Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const MobileNav = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const { isClient } = useCheckRole();
  const { logout } = useLogout();
  const { isSubdomain, subdomain, restaurantDomain4Client } = useAppSelector((state) => state.commonSlice);
  const { isAuth } = useAuth();

  useEffect(() => {
    setIsOpened(false);
  }, [pathname]);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className={'site-header__burger flex h-10 w-10'}>
      <MBBurgerIcon className={'m-auto h-6 w-6 text-indigo-40'} onClick={() => setIsOpened((prev) => !prev)} />
      <Drawer
        placement={isClient ? 'left' : 'right'}
        open={isOpened}
        className={`${isClient && 'menu-page-sidebar'} ${!isAuth && isSubdomain && 'without-footer'}`}
        onClose={() => setIsOpened(false)}
        width={273}
        footer={
          <>
            {isAuth ? (
              <Button
                type="text"
                className={'flex w-full gap-[10px] text-sm font-normal text-grey-20'}
                onClick={handleLogout}
              >
                <MBLogoutIcon className={'flex text-2xl'} />
                {t('logout')}
              </Button>
            ) : (
              <div className={'flex gap-2'}>
                {isSubdomain && restaurantDomain4Client ? (
                  <>
                    <Link
                      to={`${centralDomain}${ROUTES.client_registration}?restaurant=${
                        subdomain || restaurantDomain4Client
                      }`}
                    >
                      <Button type={'primary'}>{t('signup')}</Button>
                    </Link>
                    <Link
                      to={`${centralDomain}${ROUTES.client_login}?restaurant=${subdomain || restaurantDomain4Client}`}
                    >
                      <Button>
                        {t('login')}
                        <MBUserLoggedInIcon />
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={ROUTES.registration}>
                      <Button type={'primary'}>{t('signup')}</Button>
                    </Link>
                    <Link to={ROUTES.login}>
                      <Button>
                        {t("companie's")}
                        <MBUserLoggedInIcon />
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </>
        }
      >
        {isClient ? <ClientSidepanel restaurant={subdomain || restaurantDomain4Client} /> : <Sidepanel />}
      </Drawer>
    </div>
  );
};

export default MobileNav;
