import MBMinusIcon from '@components/icons/MBMinusIcon';
import MBPlusIcon from '@components/icons/MBPlusIcon';
import { Button } from 'antd';
import { FC } from 'react';

interface AmountCounterProps {
  amount: number;
  handleUpdateProductAmount?: (quantity: number) => void;
  bgColorBtnClass?: string;
  inventoryQuantity: number;
  isInventoryUnlimited: boolean;
}

const AmountCounter: FC<AmountCounterProps> = ({
  amount,
  bgColorBtnClass = 'bg-grey-5',
  handleUpdateProductAmount,
  inventoryQuantity,
  isInventoryUnlimited,
}) => {
  const isMaximumQuantity = !isInventoryUnlimited && amount === inventoryQuantity;

  const handleIncrement = async () => {
    if (isMaximumQuantity) {
      return;
    }

    if (handleUpdateProductAmount) {
      handleUpdateProductAmount(amount + 1);
    }
  };

  const handleDecrement = async () => {
    if (handleUpdateProductAmount && amount > 1) {
      if (handleUpdateProductAmount) {
        handleUpdateProductAmount(amount - 1);
      }
    }
  };

  return (
    <Button.Group className={'count-button-group h-[44px] items-center gap-4'}>
      <Button
        size={'small'}
        className={`flex min-h-[44px] w-[44px] rounded border-none font-normal text-indigo-30 ${bgColorBtnClass} count-button-group__button`}
        onClick={handleIncrement}
        disabled={isMaximumQuantity}
      >
        <MBPlusIcon className={'text-2xl'} />
      </Button>
      <div className={'text-indigo-40 count-button-group__text-number'}>{amount}</div>
      <Button
        size={'small'}
        className={`flex min-h-[44px] w-[44px] rounded border-none font-normal text-indigo-30 ${bgColorBtnClass} count-button-group__button`}
        onClick={handleDecrement}
      >
        <MBMinusIcon className={'text-2xl'} />
      </Button>
    </Button.Group>
  );
};

export default AmountCounter;
