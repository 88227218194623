import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import useModalConfirm from '@base/hooks/useModalConfirm';
import { Table } from '@base/redux/api/models/Table';
import {
  useCreateTableMutation,
  useGetTablesQuery,
  useRemoveTableMutation,
  useUpdateTableMutation,
} from '@base/redux/api/tableApi';
import { useGetWaitersQuery } from '@base/redux/api/waiterApi';
import { message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

const useWaiterTables = () => {
  const [form] = useForm();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const { showModalConfirm } = useModalConfirm();
  const [modal, contextHolder] = Modal.useModal();

  const { isWaiters2Tables } = useAvailableFeatures();

  const {
    data: dataTables,
    isFetching: isFetchingTables,
    isError: isErrorTables,
  } = useGetTablesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: dataWaiters,
    isFetching: isFetchingWaiters,
    isError: isErrorWaiters,
  } = useGetWaitersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [createTable, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate }] =
    useCreateTableMutation();
  const [updateTable, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] =
    useUpdateTableMutation();
  const [removeTable, { isLoading: isLoadingRemove, isSuccess: isSuccessRemove, isError: isErrorRemove }] =
    useRemoveTableMutation();

  // show 'loading...' until all fetch requests are finished
  useEffect(() => {
    setIsLoadingData(true);
    if (!isFetchingWaiters && !isFetchingTables) {
      setIsLoadingData(false);
    }

    // TODO: add an error handler and display the correct error messages to the user
    // TODO: remove checkIsSubdomain() after demo + API fix (get_waiters_list)
    if (isErrorTables || isErrorWaiters) {
      setIsLoadingData(false);
      message.error(t('error'));
    }
  }, [isFetchingTables, isFetchingWaiters]);

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate || isSuccessRemove) {
      setIsLoadingAction(false);
      message.success(t('saved'));
      setIsModalOpened(false);
    }

    if (isErrorCreate || isErrorUpdate || isErrorRemove) {
      setIsLoadingAction(false);
      message.error(t('error'));
    }
  }, [isLoadingCreate, isLoadingUpdate, isLoadingRemove]);

  const handleAddTable = () => {
    form.resetFields();
    setIsModalOpened(true);
  };

  const handleEditTable = (table: Table) => {
    if (form) {
      const waiter = table.waiter ? table.waiter.id : null;

      form.resetFields();
      form.setFields([
        { name: 'id', value: table.id },
        { name: 'name', value: table.name },
        { name: 'user_id', value: waiter },
      ]);
      setIsModalOpened(true);
    }
  };

  const handleCreate = async (value: any) => {
    setIsLoadingAction(true);
    await createTable({ name: value.name, user_id: value.user_id });
  };

  const handleUpdate = async (value: any) => {
    setIsLoadingAction(true);
    await updateTable({ data: { name: value.name, user_id: value.user_id }, id: value.id });
  };

  const handleRemove = async (id: number) => {
    setIsLoadingAction(true);
    await removeTable(id);
  };

  return {
    form,
    modal,
    contextHolder,
    showModalConfirm,
    isModalOpened,
    setIsModalOpened,
    dataTables,
    dataWaiters,
    isLoadingAction,
    isLoadingData,
    isWaiters2Tables,
    handleAddTable,
    handleEditTable,
    handleCreate,
    handleUpdate,
    handleRemove,
  };
};

export default useWaiterTables;
