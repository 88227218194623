const workersTableTheme = {
  components: {
    Table: {
      headerBg: 'transparent',
      rowHoverBg: 'transparent',
      paddingXS: 8,
    },
  },
};

export default workersTableTheme;
