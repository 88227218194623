import RestaurantExistence from '@base/hoc/RestaurantExistence';
import RestaurantTheme from '@base/hoc/RestaurantTheme';
import { useAppSelector } from '@base/redux/store';
import DndMenuCategories from '@components/Menus/Dnd/DndMenuCategories';
import MenuDishModal from '@components/Menus/MenuDishModal';
import MobileMenu from '@components/MobileMenu';
import SimpleSection from '@components/UI/SimpleSection';
import useCheckRole from '@hooks/useCheckRole';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const MenusPage = () => {
  const { t } = useTranslation();
  const { isLG } = useGetMediaQuery();
  const { isManager, isSupervisor } = useCheckRole();
  useSetTitlePage(t('pages.menus'));

  const isModalDishOpen = useAppSelector((state) => state.menuSlice.isModalDishOpen);

  return (
    <RestaurantExistence>
      {isLG && (isManager || isSupervisor) ? (
        <>
          <SimpleSection title={t('pages.menus')}>
            <DndMenuCategories />
          </SimpleSection>
          {isModalDishOpen && <MenuDishModal isOpened={isModalDishOpen} />}
        </>
      ) : (
        <RestaurantTheme>
          <MobileMenu />
        </RestaurantTheme>
      )}
    </RestaurantExistence>
  );
};

export default MenusPage;
