import { useLazyGetDishByIdQuery, useRemoveDishMutation } from '@api/dishApi';

const useDndMenuDish = () => {
  const [getDish, { data, isSuccess, isError, error, isFetching, isLoading }] = useLazyGetDishByIdQuery();
  const [removeDish] = useRemoveDishMutation();

  const remove = async (id: number) => {
    await removeDish(id);
  };

  return {
    remove,
    getDish,
    dishInfo: data,
    isSuccess,
    isError,
    error,
    isLoading,
    isFetching,
  };
};

export default useDndMenuDish;
