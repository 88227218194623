import {
  useUpdateClientAccountPasswordMutation,
  useUpdateManagerPasswordMutation,
  useUpdateWorkerAccountPasswordMutation,
} from '@api/accauntSettingsApi';
import { useAppSelector } from '@base/redux/store';
import useCheckRole from '@hooks/useCheckRole';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useAccountPassword = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { message } = App.useApp();
  const userID = useAppSelector((state) => state.userState.user?.id);
  const { isSupervisor, isWaiter, isClient } = useCheckRole();
  const isWorker = isSupervisor || isWaiter;

  const [updatePassword, { isLoading: isUpdating, isSuccess: isSuccessUpdating, isError: isErrorUpdating, error }] =
    isWorker
      ? useUpdateWorkerAccountPasswordMutation()
      : isClient
      ? useUpdateClientAccountPasswordMutation()
      : useUpdateManagerPasswordMutation();

  const onFinish = async (values: any) => {
    if (!userID) {
      return;
    }

    await updatePassword({
      id: userID,
      data: values,
    });
  };

  useEffect(() => {
    if (isUpdating) {
      return;
    }

    if (isSuccessUpdating) {
      form.resetFields();
      message.success(t('updated'));
    }

    if (isErrorUpdating) {
      const status = error && 'status' in error && error.status;

      if (status === 400) {
        message.error(t('server_errors.current_password_is_incorrect'));

        return;
      }

      if (status === 422) {
        message.error(t('server_errors.new_password_should_not_be_the_same_as_old_one'));

        return;
      }

      message.error(t('error'));
    }
  }, [isUpdating, isErrorUpdating, isSuccessUpdating]);

  return { t, form, isUpdating, onFinish };
};

export default useAccountPassword;
