import RestaurantTheme from '@base/hoc/RestaurantTheme';
import MenuPreviewHeader from '@components/MobileMenu/MenuPreviewHeader';
import RestaurantFooter from '@components/MobileMenu/RestaurantFooter';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import MobileFramePreview from '../../components/MobileFramePreview';
import MobileMenu from '../../components/MobileMenu';
import SimpleSection from '../../components/UI/SimpleSection';

const MobileMenuPreviewPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.menu_preview'));

  return (
    <SimpleSection title={t('pages.menu_preview')}>
      <div className={'flex justify-center'}>
        <RestaurantTheme>
          <MobileFramePreview>
            <>
              <MenuPreviewHeader />
              <MobileMenu />
              <RestaurantFooter />
            </>
          </MobileFramePreview>
        </RestaurantTheme>
      </div>
    </SimpleSection>
  );
};

export default MobileMenuPreviewPage;
