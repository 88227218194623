import { useAppSelector } from '@base/redux/store';

const useAuth = () => {
  const { id, token, role, restaurantId, originalHost, user } = useAppSelector((state) => state.userState);

  return {
    isAuth: !!token,
    userId: id,
    token,
    role,
    restaurantId,
    originalHost,
    isGoogleAccount: user?.is_google_account,
  };
};

export default useAuth;
