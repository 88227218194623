import { useLazyGetPromotionByIdWithDishQuery, useRemovePromotionMutation } from '@api/promotionApi';
import useModalConfirm from '@hooks/useModalConfirm';

const usePromotionCard = () => {
  const { showModalConfirm } = useModalConfirm();
  const [removeNotification] = useRemovePromotionMutation();
  const [getPromotionDetails, { data: promotionDetails, isLoading, isFetching, isSuccess, isError }] =
    useLazyGetPromotionByIdWithDishQuery();

  const remove = async (id: number) => {
    await removeNotification(id);
  };

  return {
    showModalConfirm,
    remove,
    getPromotionDetails,
    promotionDetails,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  };
};

export default usePromotionCard;
