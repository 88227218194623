import placeholder from '@assets/img/bg-qr-builder-print.jpg';
import { useAppSelector } from '@base/redux/store';
import { QR_SCAN_GET_PARAMETER } from '@constants/constants';
import addSubdomainToUrl from '@helpers/addSubdomainToUrl';
import { Button, Input, QRCode } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import MBPrinterIcon from '../../icons/MBPrinterIcon';
import MenuBitIcon from '../../icons/MenuBitIcon';

const QRPrint = () => {
  const { t } = useTranslation();
  const subdomain = useAppSelector((state) => state.commonSlice.subdomain);
  const restaurantHeroImg = useAppSelector((state) => state.restaurantSettingsState.heroSrc);

  const [tableText, setTableText] = useState<any>(`${t('table')} 1`);
  const { foregroundColor, backgroundColor } = useAppSelector((state) => state.qrCodeSlice);
  const printableComponentRef = useRef(null);

  const subdomainUrl = `${addSubdomainToUrl(subdomain)}?${QR_SCAN_GET_PARAMETER}=true`;

  const handlePrintClick = useReactToPrint({
    content: () => printableComponentRef.current,
    copyStyles: true,
    bodyClass: document.documentElement.getAttribute('dir') === 'rtl' ? 'printable-qrcode-rtl-direction' : '',
    pageStyle: `
      @page {
        /* Remove browser default header (title) and footer (url) */
        margin: 0;
        }
      @media print {
        body {
          /* Tell browsers to print background colors */
          -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
          color-adjust: exact; /* Firefox */

          /* Scale printable component to fit a4 */
          transform: scale(2.61);
        }

        /* Center printable component */
        #printable-component {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }
      }
    `,
  });

  const handleInputChange = (e: any) => {
    if (isNaN(e.target.value)) {
      setTableText(e.target.value);
    } else if (e.target.value.trim() === '' || e.target.value < 0) {
      setTableText(`${t('table')} 1`);
    } else {
      setTableText(`${t('table')} ${e.target.value}`);
    }
  };

  return (
    <>
      <h5 className={'mb-4 text-center text-base font-bold leading-[1.2]'}>{t('printable_qr_code')}</h5>
      <div
        id="printable-component"
        className={
          'relative mb-4 flex h-[530px] w-[457px] flex-col items-center justify-start gap-y-6 rounded bg-grey-5 px-10 py-10'
        }
        ref={printableComponentRef}
      >
        {/* TODO: get background image from restaraunt-settings */}
        <img
          className={'absolute left-0 top-0 h-full w-full rounded-[6px] object-cover'}
          src={restaurantHeroImg || placeholder}
          alt=""
        />
        <div
          className={
            'custom-backdrop-blur z-[1] flex w-max min-w-[265px] max-w-full justify-center rounded-[10px] bg-black/50 p-[40px]'
          }
        >
          <div className={'flex h-full w-max flex-col items-center gap-y-[31px]'}>
            <div className={'flex items-center justify-center gap-x-4'}>
              <MenuBitIcon width={'57px'} height={'39px'} className={'min-w-max'} />
              <span className={'block h-full w-[1px] bg-white'}></span>
              <div className={'flex flex-col gap-y-2 text-start text-white'}>
                <span className={'break-all font-notosans text-[26px] font-black leading-[100%]'}>{tableText}</span>
                <span className={'text-[14px] leading-[100%]'}>{t('digital_menu')}</span>
              </div>
            </div>
            <QRCode value={subdomainUrl} errorLevel="H" size={180} color={foregroundColor} bgColor={backgroundColor} />
          </div>
        </div>
      </div>
      <div className={'mx-auto flex w-max flex-col items-center justify-center gap-4'}>
        <label className={'flex items-center gap-x-4 text-sm font-bold'}>
          {t('table_number')}
          <Input showCount maxLength={20} onChange={handleInputChange} />
        </label>
        <Button icon={<MBPrinterIcon />} onClick={handlePrintClick}>
          {t('print')}
        </Button>
      </div>
    </>
  );
};

export default QRPrint;
