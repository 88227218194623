import emptyInventoryImage from '@assets/img/empty_inventory.png';
import InventoryBoard from '@components/Inventory/InventoryBoard';
import useInventoryOnboarding from '@components/Inventory/InventoryOnboarding/useInventoryOnboarding';
import useInventory from '@components/Inventory/useInventory';
import SimpleSection from '@components/UI/SimpleSection';
import { ROUTES } from '@constants/routes';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-layout-masonry';
import { Link } from 'react-router-dom';

const Inventory = () => {
  const { t } = useTranslation();
  const { categoryList, subCategoryList, isLoading, isError, isUnlimitedWarning, isDishes, isPaymentMethod } =
    useInventory();

  const { isShowTour, renderTour, obStepRef1 } = useInventoryOnboarding();

  const renderEmptyInventory = () => (
    <div className={'flex flex-col items-center gap-4'}>
      <p className={'mb-0'}>{t('empty_inventory_title')}</p>
      <img src={emptyInventoryImage} alt="" />
      <Link to={ROUTES.menus} className={'text-indigo-30 !underline underline-offset-4 hover:text-indigo-40'}>
        {t('create_menu')}
      </Link>
    </div>
  );

  return (
    <SimpleSection ref={obStepRef1} title={t('pages.inventory')}>
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : isDishes ? (
        <>
          {!isPaymentMethod && (
            <Alert
              className={'mb-6'}
              message={
                <>
                  {t('information_not_automatically_updated')}. {t('you_must_add_clearing_system')}{' '}
                  <Link
                    className={'text-indigo-30 underline underline-offset-4 hover:text-indigo-30'}
                    to={ROUTES.account_settings}
                  >
                    {t('in_account_settings')}
                  </Link>
                </>
              }
              type={'error'}
            />
          )}
          {isUnlimitedWarning && (
            <div className={'mb-6 flex flex-col gap-4'}>
              <Alert message={t('inventory_dish_unlimited_warning')} type="error" />
            </div>
          )}

          <Masonry columns={{ 640: 1, 768: 2 }} gap={10}>
            {categoryList.map((category) => (
              <div key={category.id}>
                <InventoryBoard category={category} subCategoryList={subCategoryList} dishList={category.dishes} />
              </div>
            ))}
          </Masonry>

          {isShowTour && renderTour()}
        </>
      ) : (
        renderEmptyInventory()
      )}
    </SimpleSection>
  );
};

export default Inventory;
