import { Card } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantPreview from '../../RestaurantPreview';
import RestaurantHeroUpload from '../RestaurantHeroUpload';
import RestaurantLogoUpload from '../RestaurantLogoUpload';

const RestaurantDesign: FC = () => {
  const { t } = useTranslation();

  return (
    <Card size={'default'}>
      <div className={'flex w-full flex-wrap justify-between gap-10'}>
        <div className={'w-full max-w-[266px] shrink-0 px-2 py-6 text-center'}>
          <RestaurantHeroUpload />
        </div>
        <div className={'w-full max-w-[266px] shrink-0 px-2 py-6 text-center'}>
          <RestaurantLogoUpload />
        </div>
        <div className={'shrink-0 grow px-2 py-6 text-center'}>
          <h5 className={'mb-3 text-base font-bold leading-[1.2]'}>{t('restaurant_preview')}</h5>
          <div className={'flex justify-center'}>
            <RestaurantPreview />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RestaurantDesign;
