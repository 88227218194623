import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import SecondaryButton from '@base/components/UI/SecondaryButton';
import SimpleSection from '@base/components/UI/SimpleSection';
import { regExOnlyNumbersLetters } from '@base/utils/constants/regexp';
import { ROUTES } from '@base/utils/constants/routes';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import useRestaurantPaymentSystem from './useRestaurantPaymentSystem';

interface RestaurantPaymentSystemFieldType {
  payment_provider_id: number;
  meshulam_user_id: string;
}

const RestaurantPaymentSystem = () => {
  const {
    t,
    paymentProviders,
    form,
    restId,
    isOrderPlacementPayment,
    isLoadingUpdatePaymentCredential,
    isLoading,
    isErrorPaymentProviders,
    onFinish,
  } = useRestaurantPaymentSystem();

  return (
    <SimpleSection title={t('integrations')}>
      {isLoading ? (
        <p>{t('loading')}</p>
      ) : !restId ? (
        <div className={'text-center'}>
          <p className={'text-xl'}>{t('first_you_should_create_restaurant')}</p>

          <Link to={ROUTES.restaurant}>{t('create_restaurant')}</Link>
        </div>
      ) : !isOrderPlacementPayment ? (
        <div className={'relative h-24'}>
          <BluredUnavailableFeature isWithoutBlure={true}>
            <div className={'z-10 flex flex-col items-center justify-center gap-4'}>
              <p className={'mb-0 text-center text-sm leading-[1.5] text-grey-30'}>
                {t('your_package_not_include_this_functionality')}
              </p>
              <Link to={ROUTES.packages}>
                <SecondaryButton className={'min-w-[60px] justify-center'}>{t('to_upgrade_package')}</SecondaryButton>
              </Link>
            </div>
          </BluredUnavailableFeature>
        </div>
      ) : isErrorPaymentProviders || (paymentProviders && paymentProviders.length === 0) ? (
        <p>{t('error')}</p>
      ) : (
        <Card>
          <Form
            form={form}
            name="restaurant_payment_system"
            layout="vertical"
            initialValues={{ remember: false }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={[24, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item<RestaurantPaymentSystemFieldType>
                  label={t('restaurant_connection_payment_system')}
                  name={'payment_provider_id'}
                  rules={[
                    {
                      required: true,
                      message: t('required_field'),
                    },
                  ]}
                >
                  <Select defaultValue={null}>
                    {paymentProviders &&
                      paymentProviders.map((paymentProvider) => (
                        <Select.Option
                          key={`key-provider-${paymentProvider.id}`}
                          value={paymentProvider.id}
                          id={paymentProvider.id}
                        >
                          {paymentProvider.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item<RestaurantPaymentSystemFieldType>
                  label={'ID'}
                  name={'meshulam_user_id'}
                  rules={[
                    {
                      required: true,
                      message: t('required_field'),
                    },
                    {
                      pattern: regExOnlyNumbersLetters,
                      message: t('the_field_contains_invalid_characters'),
                    },
                    {
                      max: 16,
                      min: 16,
                      message: t('input_errors.only_n_symbols', { n: 16 }),
                    },
                  ]}
                >
                  <Input placeholder={t('meshulam_user_id_placeholder')} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button type={'primary'} size={'small'} htmlType={'submit'} loading={isLoadingUpdatePaymentCredential}>
                  {t('save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </SimpleSection>
  );
};

export default RestaurantPaymentSystem;
