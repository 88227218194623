import MBRemoveIcon from '@components/icons/MBRemoveIcon';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { FC } from 'react';

const RemoveIconButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      type={'text'}
      icon={<MBRemoveIcon className={'text-red'} />}
      className={`h-[40px] justify-center ${className}`}
      {...props}
    />
  );
};

export default RemoveIconButton;
