import { App as AntdApp, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PortionsProvider } from '@base/contexts/PortionsProvider';
import { PusherProvider } from '@base/contexts/PusherProvider';
import { PushNotificationsProvider } from '@base/contexts/PushNotificationsProvider';
import { RestaurantInitProvider } from '@base/contexts/RestaurantInitProvider';
import { SubscriptionProvider } from '@base/contexts/SubscriptionProvider';
import Auth from '@base/hoc/Auth';
import { REACT_APP_GOOGLE_CLIENT_ID } from '@constants/environments';
import scrollWidthInit from '@helpers/scrollWidthInit';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import antdTheme from './antdTheme';
import App from './App';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';
import i18n from './utils/translation/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

scrollWidthInit();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider direction={i18n.dir()} theme={antdTheme}>
        <BrowserRouter>
          <Suspense fallback={<p>loading...</p>}>
            <Auth>
              <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                <RestaurantInitProvider>
                  <SubscriptionProvider>
                    <PusherProvider>
                      <PushNotificationsProvider>
                        <PortionsProvider>
                          <AntdApp>
                            <App />
                          </AntdApp>
                        </PortionsProvider>
                      </PushNotificationsProvider>
                    </PusherProvider>
                  </SubscriptionProvider>
                </RestaurantInitProvider>
              </GoogleOAuthProvider>
            </Auth>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
