import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import {
  useGetPaymentProvidersQuery,
  useLazyGetPaymentCredentialQuery,
  useUpdatePaymentCredentialMutation,
} from '@base/redux/api/restaurantPaymentSystemApi';
import { setRestPaymentMethod } from '@base/redux/features/restaurantSettingsSlice';
import { useAppSelector } from '@base/redux/store';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useRestaurantPaymentSystem = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm();
  const dispatch = useDispatch();
  const restId = useAppSelector((state) => state.restaurantSettingsState.id || state.restaurantState.id);
  const { isOrderPlacementPayment } = useAvailableFeatures();

  const {
    data: dataPaymentProviders,
    isLoading: isLoadingDataPaymentProviders,
    isError: isErrorPaymentProviders,
  } = useGetPaymentProvidersQuery();

  const [
    getPaymentCredential,
    { data: dataPaymentCredential, isError: isErrorPaymentCredential, isLoading: isLoadingPaymentCredential },
  ] = useLazyGetPaymentCredentialQuery();

  const [
    updatePaymentCredential,
    {
      isSuccess: isSuccessUpdatePaymentCredential,
      isError: isErrorUpdatePaymentCredential,
      isLoading: isLoadingUpdatePaymentCredential,
    },
  ] = useUpdatePaymentCredentialMutation();

  useEffect(() => {
    if (restId) {
      getPaymentCredential(restId);
    }
  }, [restId]);

  useEffect(() => {
    if (dataPaymentCredential) {
      initFormValues();
    }
  }, [dataPaymentCredential]);

  useEffect(() => {
    if (isLoadingUpdatePaymentCredential) {
      return;
    }

    if (isSuccessUpdatePaymentCredential) {
      message.success(t('updated'));
    }

    if (isErrorUpdatePaymentCredential) {
      message.error(t('error'));
    }
  }, [isSuccessUpdatePaymentCredential, isErrorUpdatePaymentCredential, isLoadingUpdatePaymentCredential]);

  useEffect(() => {
    if (isLoadingPaymentCredential) {
      return;
    }

    if (isErrorPaymentCredential) {
      message.error(t('error_getting_credential'));
    }
  }, [isLoadingPaymentCredential, isErrorPaymentCredential]);

  useEffect(() => {
    if (isLoadingPaymentCredential || isLoadingUpdatePaymentCredential) {
      return;
    }

    if (isSuccessUpdatePaymentCredential) {
      dispatch(setRestPaymentMethod(true));
    }
  }, [isLoadingPaymentCredential, isLoadingUpdatePaymentCredential, isSuccessUpdatePaymentCredential]);

  const onFinish = async (values: any) => {
    const currentData = {
      payment_provider_id: dataPaymentCredential?.payment_provider?.id,
      meshulam_user_id: dataPaymentCredential?.meshulam_user_id,
    };

    if (!restId || JSON.stringify(currentData) === JSON.stringify(values)) {
      return;
    }

    await updatePaymentCredential({
      restId,
      payment_provider_id: values.payment_provider_id,
      meshulam_user_id: values.meshulam_user_id,
    });
  };

  const initFormValues = () => {
    if (!dataPaymentCredential) {
      return;
    }

    form.setFieldsValue({
      payment_provider_id: dataPaymentCredential.payment_provider?.id,
      meshulam_user_id: dataPaymentCredential.meshulam_user_id,
    });
  };

  return {
    t,
    form,
    restId,
    paymentProviders: dataPaymentProviders,
    isOrderPlacementPayment,
    isErrorPaymentProviders,
    isLoadingUpdatePaymentCredential,
    isLoading: isLoadingDataPaymentProviders || isLoadingPaymentCredential,
    onFinish,
  };
};

export default useRestaurantPaymentSystem;
