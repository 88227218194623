import { setRestaurantDomain4Client } from '@base/redux/features/commonSlice';
import NoMatchPage from '@pages/NoMatchPage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useSearchParams } from 'react-router-dom';

const CheckParamRestaurant = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const restaurant = searchParams.get('restaurant');

  useEffect(() => {
    if (restaurant) {
      dispatch(setRestaurantDomain4Client(restaurant));
    }
  }, [dispatch, restaurant]);

  if (restaurant) {
    return <Outlet />;
  }

  return <NoMatchPage />;
};

export default CheckParamRestaurant;
