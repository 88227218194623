import { LOCALSTORAGE_CLIENT_ORDER_KEY } from '@constants/constants';
import { centralDomain } from '@constants/environments';
import { useEffect } from 'react';

/*
  The component is called in the iframe to transfer customer order data from a subdomain to the main domain for payment
* */
const GetOrderClientPage = () => {
  useEffect(() => {
    const orderData = localStorage.getItem(LOCALSTORAGE_CLIENT_ORDER_KEY);

    if (!orderData) {
      return;
    }

    window.parent.postMessage(orderData, centralDomain);
  }, []);

  return <></>;
};

export default GetOrderClientPage;
