import {
  useResetPasswordClientMutation,
  useResetPasswordMutation,
  useResetPasswordWorkerMutation,
} from '@base/redux/api/authApi';
import extractQueryParameter from '@base/utils/helpers/extractQueryParameter';
import useAuth from '@hooks/useAuth';
import { regExPassword } from '@utils/constants/regexp';
import { ROUTES } from '@utils/constants/routes';
import { App, Button, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessfulPasswordReset from '../SuccessfulPasswordReset';

interface FieldType {
  password?: string;
  password_confirmation?: string;
}

interface ResetPasswordFormProps {
  type: 'worker' | 'manager' | 'client';
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ type }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = useForm();
  const { isAuth, restaurantId } = useAuth();
  const [isSuccessReq, setisSuccessReq] = useState<boolean>(false);

  // API usage depending on prop 'type' (client | manager | worker)
  const resetPasswordHook =
    type === 'worker'
      ? useResetPasswordWorkerMutation
      : type === 'client'
      ? useResetPasswordClientMutation
      : useResetPasswordMutation;

  const [resetPassword, { isLoading, isError, isSuccess }] = resetPasswordHook();

  // Login route on successful password reset
  const successfulPasswordResetLoginRoute =
    type === 'worker' ? ROUTES.worker_login : type === 'manager' ? ROUTES.login : null;

  const { token, email } = extractQueryParameter(window.location.href);

  useLayoutEffect(() => {
    if (isAuth) {
      const from =
        ((location.state as any)?.from.pathname as string) || restaurantId ? ROUTES.dashboard : ROUTES.restaurant;

      navigate(from);
    } else if (!token || !email) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setisSuccessReq(true);
    }

    if (!isLoading && isError) {
      message.error(t('error_cant_reset_password'));
    }
  }, [isLoading]);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values: any) => {
    values.email = email;
    values.token = token;

    resetPassword(values);
  };

  return (
    <>
      {isSuccessReq ? (
        <SuccessfulPasswordReset
          loginLink={successfulPasswordResetLoginRoute}
          additionalText={type === 'client' ? t('scan_qrcode_again') : null}
        />
      ) : (
        <div className={' w-full p-6 lg:mx-auto lg:box-content lg:max-w-[400px] lg:self-center lg:px-10 lg:py-14'}>
          <div className={'flex flex-col gap-10 lg:py-[100px]'}>
            <header>
              <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('new_password')}</div>
            </header>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item<FieldType>
                    label={t('password')}
                    name="password"
                    rules={[{ required: true, pattern: regExPassword, message: t('input_errors.password_validation') }]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item<FieldType>
                    label={t('password_verify')}
                    name="password_confirmation"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: t('required_field'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error(t('input_errors.password_not_match')));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className={'mb-0'}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'mt-6 w-full justify-center lg:w-[206px]'}
                  loading={isLoading}
                >
                  {t('save')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPasswordForm;
