import { SVGProps } from 'react';
const BigJarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.4304 7.87639C23.3994 7.87639 23.3695 7.88099 23.3385 7.88214C23.3833 5.95459 23.3431 4.02015 23.1834 2.13281H2.94995C2.94995 2.13281 5.18995 7.52144 5.18995 10.2117C5.1911 28.0031 9.66879 29.702 9.66879 29.702H18.8355C18.8355 29.702 21.133 27.1174 21.8153 21.6323C21.9658 21.6472 22.1163 21.661 22.2817 21.661C25.0283 21.661 29.0453 18.3423 30.0746 11.3811C30.2354 10.3013 30.1458 7.87639 28.0253 7.87639C25.9048 7.87639 26.6032 7.87639 23.4304 7.87639ZM22.2817 18.2148C22.2817 18.2148 22.9928 13.8382 22.9928 11.3225H26.3838C26.1115 17.3395 22.2817 18.2148 22.2817 18.2148Z"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5028 7.87632C18.8471 7.87632 20.0842 6.79883 15.533 6.79883C10.6016 6.79883 8.7843 9.09626 5.12219 9.09626"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BigJarIcon;
