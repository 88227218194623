import { SVGProps } from 'react';
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={131} height={30} viewBox="0 0 131 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.7323 23.3096H36.999V7.08203H42.0292L46.627 19.0033L51.1725 7.08203H56.2682V23.3096H52.5349V11.7035L48.0549 23.3096H45.1861L40.7323 11.7035V23.3096Z"
      fill="currentColor"
    />
    <path
      d="M65.136 11.1797C68.8694 11.1797 71.4106 13.7267 71.4106 17.4291C71.4106 17.7311 71.4106 18.2038 71.3713 18.4532L62.2542 18.427C62.5555 20.0681 63.6689 21.1447 65.2146 21.1447C66.4853 21.1447 67.5594 20.4094 67.9262 19.3854L70.9915 19.8186C70.4282 22.1031 67.9655 23.7574 65.1229 23.7574C61.3765 23.7574 58.7305 21.1315 58.7305 17.3766C58.7305 13.7399 61.3896 11.1797 65.136 11.1797ZM67.9655 16.3788C67.7559 14.8952 66.6294 13.8055 65.0837 13.8055C63.5772 13.8055 62.5424 14.8296 62.2411 16.3788H67.9655Z"
      fill="currentColor"
    />
    <path
      d="M76.7428 11.6378V13.2133C77.555 11.966 78.9304 11.1914 80.45 11.1914C83.0698 11.1914 84.8382 13.2133 84.8382 16.0229V23.3096H81.4324V16.6137C81.4324 15.1958 80.6202 14.198 79.271 14.198C77.9349 14.198 76.8345 15.2746 76.8345 16.745V23.3096H73.4287V11.6378H76.7428Z"
      fill="currentColor"
    />
    <path
      d="M95.2126 23.3104V21.7087C94.4004 22.9822 93.025 23.7568 91.5055 23.7568C88.8856 23.7568 87.1172 21.735 87.1172 18.9253V11.6387H90.523V18.3345C90.523 19.7525 91.3352 20.7503 92.6844 20.7503C94.0205 20.7503 95.1209 19.6737 95.1209 18.2032V11.6387H98.5267V23.3104H95.2126Z"
      fill="currentColor"
    />
    <path
      d="M101.526 23.3096V7.08203H109.373C112.739 7.08203 115.097 8.94636 115.097 11.4803C115.097 13.1477 114.141 14.4868 112.752 14.8807C114.416 15.2877 115.543 16.8632 115.543 18.8063C115.543 21.524 113.106 23.3227 109.635 23.3227H101.526V23.3096ZM105.338 9.97043V13.5941H108.679C110.133 13.5941 111.181 12.8588 111.181 11.7954C111.181 10.7319 110.133 9.97043 108.679 9.97043H105.338ZM108.862 16.1936H105.338V20.4081H108.862C110.473 20.4081 111.639 19.5416 111.639 18.3337C111.639 17.0864 110.486 16.1936 108.862 16.1936Z"
      fill="currentColor"
    />
    <path
      d="M117.809 8.15904C117.809 7.04306 118.621 6.24219 119.787 6.24219C120.952 6.24219 121.765 7.04306 121.765 8.15904C121.765 9.30127 120.952 10.1021 119.787 10.1021C118.621 10.1021 117.809 9.28814 117.809 8.15904ZM121.49 11.6382V23.31H118.084V11.6382H121.49Z"
      fill="currentColor"
    />
    <path
      d="M124.83 14.2377H123.192V11.6382H124.987L125.38 8.69727H128.249V11.6382H130.882V14.2377H128.249V19.1874C128.249 20.1721 128.838 20.8286 129.794 20.8286C130.109 20.8286 130.607 20.7629 131 20.6447V23.4019C130.292 23.6251 129.388 23.7695 128.655 23.7695C126.336 23.7695 124.83 22.0102 124.83 19.5288V14.2377Z"
      fill="currentColor"
    />
    <path
      opacity={0.78}
      d="M27.62 27.6958C24.5547 30.7681 19.577 30.7681 16.4986 27.6958L2.29893 13.4508C-0.766312 10.3786 -0.766312 5.3895 2.29893 2.30416C5.36418 -0.768053 10.3419 -0.768053 13.4203 2.30416L27.62 16.5361C30.6852 19.6214 30.6852 24.6105 27.62 27.6958Z"
      fill="url(#paint0_linear_1958_8152)"
    />
    <path
      opacity={0.78}
      d="M2.29893 27.6964C-0.766312 24.6242 -0.766312 19.6351 2.29893 16.5498L16.4986 2.31783C19.5639 -0.754381 24.5416 -0.754381 27.62 2.31783C30.6852 5.39004 30.6852 10.3791 27.62 13.4644L13.4203 27.6964C10.3419 30.7686 5.36418 30.7686 2.29893 27.6964Z"
      fill="url(#paint1_linear_1958_8152)"
    />
    <path d="M19.4717 10.2871H10.4463V12.1383H19.4717V10.2871Z" fill="white" />
    <path d="M19.4717 14.0801H10.4463V15.9313H19.4717V14.0801Z" fill="white" />
    <path d="M19.4717 17.875H10.4463V19.7262H19.4717V17.875Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_1958_8152"
        x1={17.5562}
        y1={1.75899}
        x2={12.3502}
        y2={28.2367}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1958_8152"
        x1={28.1648}
        y1={17.5981}
        x2={1.73824}
        y2={12.4257}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default Logo;
