import { Layout, Restaurant } from '@api/models/Restaurant';
import { PackagePayment } from '@api/models/SubscriptionPackage';
import { WorkHours } from '@base/types/WorkHours';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  id: number | null;
  domain: string | null;
  name: string | null;
  secondaryTitle: string | null;
  description: string | null;
  googleAccountLink: string | null;
  heroSrc: string | null;
  logoSrc: string | null;
  address: string | null;
  work_hours: WorkHours | null;
  description_schedule: string | null;
  layout: Layout | null;
  isTakeAway: boolean | null;
  isActive: boolean | null;
  phone_number: string | null;
  facebook_link: string | null;
  instagram_link: string | null;
  package_payment: PackagePayment | null;
  payment_method: boolean | null;
}

const initialState: InitialState = {
  id: null,
  domain: null,
  name: null,
  secondaryTitle: null,
  description: null,
  googleAccountLink: null,
  heroSrc: null,
  logoSrc: null,
  address: null,
  work_hours: null,
  description_schedule: null,
  layout: null,
  isActive: null,
  isTakeAway: null,
  phone_number: null,
  facebook_link: null,
  instagram_link: null,
  package_payment: null,
  payment_method: null,
};

export const restaurantSettingsSlice = createSlice({
  initialState,
  name: 'restaurantSettings',
  reducers: {
    resetRestaurantSlice: () => initialState,
    setRestId: (state, action: PayloadAction<number | null>) => {
      state.id = action.payload;
    },
    setRestDomain: (state, action: PayloadAction<string | null>) => {
      state.domain = action.payload;
    },
    setRestName: (state, action: PayloadAction<string | null>) => {
      state.name = action.payload;
    },
    setRestSecondaryTitle: (state, action: PayloadAction<string | null>) => {
      state.secondaryTitle = action.payload;
    },
    setRestGoogleAccountLink: (state, action: PayloadAction<string | null>) => {
      state.googleAccountLink = action.payload;
    },
    setRestDescription: (state, action: PayloadAction<string | null>) => {
      state.description = action.payload;
    },
    setRestHeroSrc: (state, action: PayloadAction<string | null>) => {
      state.heroSrc = action.payload;
    },
    setRestLogoSrc: (state, action: PayloadAction<string | null>) => {
      state.logoSrc = action.payload;
    },
    setRestAddress: (state, action: PayloadAction<string | null>) => {
      state.address = action.payload;
    },
    setRestWorkHours: (state, action: PayloadAction<WorkHours | null>) => {
      state.work_hours = action.payload;
    },
    setRestScheduleDes: (state, action: PayloadAction<string | null>) => {
      state.description_schedule = action.payload;
    },
    setRestLayout: (state, action: PayloadAction<Layout | null>) => {
      state.layout = action.payload;
    },
    setRestActive: (state, action: PayloadAction<boolean | null>) => {
      state.isActive = action.payload;
    },
    setRestTakeAway: (state, action: PayloadAction<boolean | null>) => {
      state.isTakeAway = action.payload;
    },
    setRestPhone: (state, action: PayloadAction<string | null>) => {
      state.phone_number = action.payload;
    },
    setRestFacebook: (state, action: PayloadAction<string | null>) => {
      state.facebook_link = action.payload;
    },
    setRestInstagram: (state, action: PayloadAction<string | null>) => {
      state.instagram_link = action.payload;
    },
    setRestPackage: (state, action: PayloadAction<PackagePayment>) => {
      state.package_payment = action.payload;
    },
    setRestPaymentMethod: (state, action: PayloadAction<boolean | null>) => {
      state.payment_method = action.payload;
    },
    setRestSettings: (state, action: PayloadAction<Restaurant>) => {
      state.id = action.payload.id;
      state.domain = action.payload.domain;
      state.name = action.payload.name;
      state.secondaryTitle = action.payload.secondary_title;
      state.description = action.payload.description;
      state.googleAccountLink = action.payload.google_account_link;
      state.heroSrc = action.payload.background_picture;
      state.logoSrc = action.payload.profile_picture;
      state.address = action.payload.address;
      state.work_hours = action.payload.work_hours;
      state.description_schedule = action.payload.description_schedule;
      state.layout = action.payload.layout;
      state.isActive = action.payload.is_active;
      state.isTakeAway = action.payload.is_take_away;
      state.phone_number = action.payload.phone_number;
      state.facebook_link = action.payload.facebook_link;
      state.instagram_link = action.payload.instagram_link;
      state.package_payment = action.payload.package_payment;
      state.payment_method = action.payload.payment_method;
    },
  },
});

export default restaurantSettingsSlice.reducer;

export const {
  resetRestaurantSlice,
  setRestId,
  setRestDomain,
  setRestName,
  setRestSecondaryTitle,
  setRestDescription,
  setRestHeroSrc,
  setRestLogoSrc,
  setRestAddress,
  setRestWorkHours,
  setRestScheduleDes,
  setRestLayout,
  setRestActive,
  setRestTakeAway,
  setRestPhone,
  setRestFacebook,
  setRestInstagram,
  setRestGoogleAccountLink,
  setRestSettings,
  setRestPackage,
  setRestPaymentMethod,
} = restaurantSettingsSlice.actions;
