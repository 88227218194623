import WorkerRegistrationForm from '@components/AuthForm/WorkerRegistrationForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';
// import AuthRestaurantInfo from '../../components/AuthRestaurantInfo';

const WorkerRegistrationPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.registration'));

  // TODO: decide what to do with AuthRestaurantInfo - remove AuthRestaurantInfo
  return (
    // <AuthForm sideBarContent={<AuthRestaurantInfo />}>
    //   <WorkerRegistrationForm />
    // </AuthForm>
    <AuthForm>
      <WorkerRegistrationForm />
    </AuthForm>
  );
};

export default WorkerRegistrationPage;
