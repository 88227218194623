import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { FC } from 'react';

const FullWidthButton: FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button type={'text'} className={'w-full justify-center rounded bg-white p-6 font-bold text-indigo-30'} {...props}>
      {children}
    </Button>
  );
};

export default FullWidthButton;
