import { Dish } from '@api/models/Dish';
import { DishKeysTabs, setActiveTabDishModal } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';

import DishFormDetails from '@components/Menus/DishForm/DishFormDetails';
import { Tabs, TabsProps } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DishFormExtras from './DishFormExtras';
import DishFormOptions from './DishFormOptions';

export interface DishFormFieldType extends Omit<Dish, 'menu' | 'dish_type_id' | 'allergies'> {
  menu_id: number;
  dish_type_id: number;
  allergies: number[];
  inventory_quantity: number;
  is_inventory_unlimited: boolean;
}

const TabContentWrapper: FC<{ children: ReactNode; tabKey: string }> = ({ children, tabKey }) => (
  <div data-tab-key={tabKey} className={'mb-6 border-0 border-b border-solid border-grey-5 px-6 pb-10 pt-6 dish-tab-wrapper'}>
    {children}
  </div>
);

const DishForm = () => {
  const { t } = useTranslation();
  const { activeTabDishModal } = useAppSelector((state) => state.menuSlice);
  const dispatch = useDispatch();

  const activeTabKey: DishKeysTabs | null = activeTabDishModal || 'dish-tab-1';

  const items: TabsProps['items'] = [
    {
      key: 'dish-tab-1',
      label: t('dish_details'),
      children: (
        <TabContentWrapper tabKey={'dish-tab-1'}>
          <DishFormDetails />
        </TabContentWrapper>
      ),
      forceRender: true,
    },
    {
      key: 'dish-tab-2',
      label: t('options'),
      children: (
        <TabContentWrapper tabKey={'dish-tab-2'}>
          <DishFormOptions />
        </TabContentWrapper>
      ),
      forceRender: true,
    },
    {
      key: 'dish-tab-3',
      label: t('extras'),
      children: (
        <TabContentWrapper tabKey={'dish-tab-3'}>
          <DishFormExtras />
        </TabContentWrapper>
      ),
      forceRender: true,
    },
  ];

  const handleChange = (activeKey: any) => {
    dispatch(setActiveTabDishModal(activeKey));
  };

  return (
    <Tabs
      defaultActiveKey={`dish-tab-1`}
      activeKey={activeTabKey as string}
      className={`tabs-full dish-form-tabs`}
      centered={true}
      items={items}
      onChange={handleChange}
      animated={{ inkBar: false }}
    />
  );
};

export default DishForm;
