import { InventoryDish } from '@api/models/Inventory';
import useInventory from '@components/Inventory/useInventory';

const useDashboardInventory = () => {
  const { categoryList, subCategoryList, isLoading, isError } = useInventory();

  const dishList = [...categoryList, ...subCategoryList]
    .reduce((acc, category) => [...acc, ...category.dishes], [] as InventoryDish[])
    .filter((dish) => {
      const isDisplayDish = dish.is_portion_available && !dish.is_inventory_unlimited;

      if (isDisplayDish) {
        return dish;
      }
    });

  return {
    isLoading,
    isError,
    dishList,
  };
};

export default useDashboardInventory;
