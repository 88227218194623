import { OnboardingKeys } from '@base/redux/features/userSlice';
import useTour from '@components/UI/Tour/useTour';
import useSteps from '@components/WaiterTables/TablesOnboarding/useSteps';
import useGetMediaQuery from '@hooks/useGetMediaQuery';

const useTablesOnboarding = () => {
  const { isXL } = useGetMediaQuery();
  const onboardingKey: OnboardingKeys = 'cabinet_tables';

  const { steps, obStepRef1 } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard && isXL;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
  };
};

export default useTablesOnboarding;
