import emptyWaiterCallsImage from '@assets/img/empty_waiter_calls.png';
import CallToWaiterRequest from '@components/CallToWaiterRequests/CallToWaiterRequest';
import useCallToWaiterRequestList from '@components/CallToWaiterRequests/CallToWaiterRequestList/useCallToWaiterRequestList';
import useWaiterCallsOnboarding from '@components/CallToWaiterRequests/CallToWaiterRequestList/WaiterCallsOnboarding/useWaiterCallsOnboarding';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CallToWaiterRequestList: FC<{ showCallID?: number; isAfterOpened: boolean }> = ({
  showCallID,
  isAfterOpened,
}) => {
  const { t } = useTranslation();
  const { calls = [], isLoading, isError } = useCallToWaiterRequestList();

  const { isTourFinished, isShowTour, renderTour, obStepRef1 } = useWaiterCallsOnboarding();

  const renderCallsElements = () =>
    calls.map((call, i) => {
      return (
        <CallToWaiterRequest
          ref={i === 0 ? obStepRef1 : null}
          key={i}
          client_id={call.client_id}
          client_name={call.client_name}
          table_id={call.table_id}
          table_name={call.table_name}
          services={call.services_slugs}
          createdAt={call.createdAt}
          isHighlighted={showCallID === call.id}
        />
      );
    });

  const renderEmptyCalls = () => (
    <div className={'flex flex-col items-center gap-4'}>
      <p className={'mb-0'}>{t('empty_waiter_calls')}</p>
      <img src={emptyWaiterCallsImage} alt="" />
    </div>
  );

  return (
    <>
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : calls.length > 0 ? (
        <>
          {isAfterOpened && isShowTour && renderTour()}
          <div className={'flex flex-col items-center gap-2'}>{renderCallsElements()}</div>
        </>
      ) : isTourFinished ? (
        <p className={'text-center'}>{t('list_is_empty')}</p>
      ) : (
        renderEmptyCalls()
      )}
    </>
  );
};

export default CallToWaiterRequestList;
