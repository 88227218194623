import MBCloseIcon from '@base/components/icons/MBCloseIcon';
import useCallToWaiterOption from '@layout/Header/HeaderCallToWaiterModal/CallToWaiterOption/useCallToWaiterOption';
import { FC, MouseEvent } from 'react';

interface CallToWaiterOptionProps {
  service_id: number;
  icon: JSX.Element;
  title: string;
  isActive: boolean;
}

const CallToWaiterOption: FC<CallToWaiterOptionProps> = ({ service_id, icon, title, isActive }) => {
  const { isRequested, createRequest, removeRequest } = useCallToWaiterOption(isActive);

  const handleCreateServiceRequest = async () => {
    await createRequest(service_id);
  };

  const handleRemoveServiceRequest = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    await removeRequest(service_id);
  };

  return (
    <div
      className={`modal-call-waiter__option call-waiter-option ${isRequested && 'call-waiter-option--active'}`}
      onClick={handleCreateServiceRequest}
    >
      {icon}
      <span>{title}</span>
      <div className={'call-waiter-option__cancel'} onClick={handleRemoveServiceRequest}>
        <MBCloseIcon width={32} height={32} />
      </div>
    </div>
  );
};

export default CallToWaiterOption;
