import { CSSProperties, FC, ReactNode } from 'react';

interface HeadingLargeProps {
  children: ReactNode;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  className?: string;
  style?: CSSProperties;
}

const Heading: FC<HeadingLargeProps> = ({ component, children, className, style }) => {
  const classesList = `text-[1.25rem] font-bold text-indigo-40 leading-[1.2] ${className}`;

  let element: ReactNode | null;

  switch (component) {
    case 'h1':
      element = (
        <h1 className={classesList} style={style}>
          {children}
        </h1>
      );
      break;
    case 'h2':
      element = (
        <h2 className={classesList} style={style}>
          {children}
        </h2>
      );
      break;
    case 'h3':
      element = (
        <h3 className={classesList} style={style}>
          {children}
        </h3>
      );
      break;
    case 'h4':
      element = (
        <h4 className={classesList} style={style}>
          {children}
        </h4>
      );
      break;
    case 'h5':
      element = (
        <h5 className={classesList} style={style}>
          {children}
        </h5>
      );
      break;
    case 'h6':
      element = (
        <h6 className={classesList} style={style}>
          {children}
        </h6>
      );
      break;
    case 'span':
      element = (
        <span className={classesList} style={style}>
          {children}
        </span>
      );
      break;
    default:
      element = (
        <div className={classesList} style={style}>
          {children}
        </div>
      );
  }

  return element;
};

export default Heading;
