import { SVGProps } from 'react';
const MBUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.5 10.942v2.667a1.334 1.334 0 0 1-1.333 1.333H3.833A1.334 1.334 0 0 1 2.5 13.61v-2.667M11.833 6.276 8.5 2.942 5.167 6.276M8.5 2.942v8"
    />
  </svg>
);
export default MBUploadIcon;
