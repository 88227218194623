import { useCreateOrderByClientMutation } from '@api/clientApi';
import { setGuestOrders } from '@base/redux/features/clientSlice';
import { setRestaurantDomain4Client } from '@base/redux/features/commonSlice';
import { useAppSelector } from '@base/redux/store';
import { ClientOrderData } from '@base/types/ClientOrder';
import { COOKIE_CLIENT_IS_ORDER_SUCCESS_KEY } from '@base/utils/constants/constants';
import { getClientOrderFromLocalstorage } from '@base/utils/helpers/getClientOrderFromLocalstorage';
import { domain } from '@constants/environments';
import { ROUTES } from '@constants/routes';
import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { setClientOrderToLocalstorage } from '@helpers/setClientOrderToLocalstorage';
import useAuth from '@hooks/useAuth';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LOAD_WAIT_TIME = 2000;

const useClientPayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const orders = useAppSelector((state) => state.clientSlice.guestOrders);
  const client = useAppSelector((state) => state.userState.user);
  const restLink = getRestaurantLinkFromCookies();

  const [orderData, setOrderData] = useState<{ clientOrder: ClientOrderData; restId: number } | null>(null);
  const [isPaymentCreditCard, setIsPaymentCreditCard] = useState(false);
  const [isOrderQuantityError, setIsOrderQuantityError] = useState(false);

  const [isIframeOrdersLoading, setIsIframeOrdersLoading] = useState(true);
  const [isIframeOrdersLoaded, setIsIframeOrdersLoaded] = useState(false);
  const [isIframeOrdersError, setIsIframeOrdersError] = useState(false);

  const [createOrder, { data: newOrder, isLoading, isSuccess, isError, error }] = useCreateOrderByClientMutation();

  const iframeOrdersLink = `${restLink as string}${ROUTES.get_order}`;

  const onFinish = async (values: any) => {
    if (!orderData?.clientOrder?.order || !orderData.restId) {
      return;
    }

    const clientFullName = isAuth
      ? `${client?.first_name} ${client?.last_name}`
      : `${values?.first_name} ${values?.last_name}`;
    const clientEmail = isAuth ? client?.email : values?.email;
    const clientPhone = isAuth ? client?.phone_number : values?.phone_number;

    // TODO: replace hardcoded data with real one
    if (!clientFullName || !clientEmail || !clientPhone) {
      return;
    }

    await createOrder({
      clientOrder: {
        payment: {
          chargeType: '1',
          sum: orderData.clientOrder.order.cart_total_price.toString(),
          pageField: { fullName: clientFullName, phone: clientPhone, email: clientEmail },
          cField1: 'my_key123',
          cField2: 'next456',
          description: `רכישה במסעדת ${orderData.clientOrder.restName} דרך אפליקציית מניוביט`, // TODO: add this to translations
        },
        ...orderData.clientOrder.order,
      },
      restId: orderData.restId,
    });
  };

  const onIframeOrdersLoad = () => {
    setIsIframeOrdersLoaded(true);
  };

  // TODO: remove this if it will be unnecessary
  // useEffect(() => {
  //   if (!isLoading && isSuccess && newOrder) {
  //     const localstorageClientOrder = getClientOrderFromLocalstorage();

  //     //dispatch(setGuestOrders([...orders, { id: newOrder.id, order_date: Date.now() }]));

  //     if (localstorageClientOrder) {
  //       // add info of created order
  //       const newClientOrder = {
  //         ...localstorageClientOrder,
  //         id: newOrder.id,
  //         order_date: newOrder.order_date,
  //       };

  //       setClientOrderToLocalstorage(newClientOrder);

  //       navigate(ROUTES.client_payment_success);
  //     } else {
  //       message.error(t('error getting order from local store'));
  //     }
  //   }

  //   if (isError) {
  //     setIsOrderQuantityError(true);
  //   }
  // }, [isLoading, isError]);

  useEffect(() => {
    function receiveMessage(event: any) {
      if (event.origin !== 'https://meshulam.co.il' && event.origin !== 'https://sandbox.meshulam.co.il') {
        return;
      }

      const data = event.data;

      if (data.status === 1 && newOrder) {
        const localstorageClientOrder = getClientOrderFromLocalstorage();

        dispatch(setGuestOrders([...orders, { id: newOrder.id, order_date: Date.now() }]));

        if (localstorageClientOrder?.clientOrder && localstorageClientOrder.restId) {
          // add info of created order
          const newClientOrder = {
            ...localstorageClientOrder.clientOrder,
            id: newOrder.id,
            order_date: newOrder.order_date,
          };

          setClientOrderToLocalstorage(newClientOrder, localstorageClientOrder.restId);

          // set cookies for subdomain to understand that order is successfuly payed
          Cookies.set(COOKIE_CLIENT_IS_ORDER_SUCCESS_KEY, 'true', {
            expires: 1,
            path: '/',
            domain,
          });

          navigate(ROUTES.client_payment_success);
        } else {
          message.error(t('error getting order from local store'));
        }
      }

      window.removeEventListener('message', receiveMessage);
    }
    if (!isLoading && isSuccess) {
      setIsPaymentCreditCard(true);
      window.addEventListener('message', receiveMessage, false);
    }

    // TODO: change error handler to more unified version
    if (isError) {
      const serverError = error as any;
      const meshulamErrorMessage = serverError?.data?.data?.error_message as string;

      if (meshulamErrorMessage && meshulamErrorMessage.startsWith('error_payment')) {
        message.error(t(`meshulam_payment_errors.${meshulamErrorMessage}`));
      } else {
        setIsOrderQuantityError(true);
      }
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (orderData) {
      dispatch(setRestaurantDomain4Client(orderData.clientOrder.order.restaurant_domain));
    }
  }, [orderData, dispatch]);

  useEffect(() => {
    const st = setTimeout(() => {
      setOrderData((currentOrderData) => {
        if (!currentOrderData) {
          setIsIframeOrdersError(true);
        }

        return currentOrderData;
      });
    }, LOAD_WAIT_TIME);

    return () => {
      clearTimeout(st);
    };
  }, [isIframeOrdersLoaded]);

  useEffect(() => {
    function receiveMessage(event: any) {
      if (event.origin !== restLink) {
        return;
      }

      const data: { clientOrder: ClientOrderData; restId: number } = event.data ? JSON.parse(event.data) : null;
      setOrderData(data);
      setIsIframeOrdersLoading(false);
    }

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [isIframeOrdersLoaded, restLink]);

  useEffect(() => {
    if (orderData) {
      setClientOrderToLocalstorage(orderData.clientOrder, orderData.restId);
    }
  }, [orderData]);

  return {
    t,
    isAuth,
    iframeOrdersLink,
    restLogo: orderData?.clientOrder.restLogo || null,
    restName: orderData?.clientOrder.restName as string,
    orderData,
    order: orderData?.clientOrder.order,
    cart_total_price: orderData?.clientOrder.order.cart_total_price as number,
    all_products: orderData?.clientOrder.order.all_products || [],
    isLoading,
    isIframeOrdersLoading,
    isIframeOrdersError,
    onIframeOrdersLoad,
    onFinish,
    isPaymentCreditCard,
    setIsPaymentCreditCard,
    isOrderQuantityError,
    iframePaymentUrl: newOrder?.meta?.data?.url,
  };
};

export default useClientPayment;
