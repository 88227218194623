import MBBurgerIcon from '@components/icons/MBBurgerIcon';
import { Button } from 'antd';

const MenuPreviewHeader = () => {
  return (
    <header className={'bg-white px-4 py-3 text-left menu-preview-header'}>
      <Button
        type={'text'}
        className={'justify-center text-2xl'}
        icon={<MBBurgerIcon className={'text-indigo-40'} />}
      />
    </header>
  );
};

export default MenuPreviewHeader;
