import { Roles } from '@base/types/Roles';
import { COOKIE_USER_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

interface setUserToCookieProps {
  id: number;
  role: Roles;
  token: string;
  restaurant_id?: number | null;
}

function setUserToCookie(user: setUserToCookieProps) {
  const data = {
    ...user,
    original_host: window.location.origin,
  };

  Cookies.set(COOKIE_USER_KEY, JSON.stringify(data), {
    expires: 1,
    path: '/',
    domain,
  });
}

export default setUserToCookie;
