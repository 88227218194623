import { FC } from 'react';

interface ConfirmModalContentProps {
  title?: string;
  subtitle?: string;
}

const ConfirmModalContent: FC<ConfirmModalContentProps> = ({ title, subtitle }) => {
  return (
    <div className={'text-center'}>
      {title && <div className={'text-2xl'}>{title}</div>}
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
};

export default ConfirmModalContent;
