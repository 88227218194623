import { ROUTES } from '@base/utils/constants/routes';
import FeedbackSentSuccessful from '@components/icons/FeedbackSentSuccessful';
import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const SuccessfulOrderFeedback = () => {
  const { t } = useTranslation();
  const restLink = getRestaurantLinkFromCookies();

  return (
    <>
      <div className={'flex flex-col items-center rounded bg-white p-6 text-center shadow-custom'}>
        <span className={'mb-2 text-xl font-bold leading-[1.2] text-indigo-40'}>{t('thanks')}!</span>
        <p className={'mb-6 text-base text-grey-30'}>{t('your_message_was_sent_successfully')}</p>
        <FeedbackSentSuccessful />
      </div>
      {restLink && (
        <Button
          href={`${restLink}${ROUTES.restaurant_menu}`}
          type="primary"
          className={'m-auto mt-4 h-[44px] w-[295px] justify-center no-underline'}
        >
          {t('go_to_menu')}
        </Button>
      )}
    </>
  );
};

export default SuccessfulOrderFeedback;
