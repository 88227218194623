import { workers } from '@constants/constants';
import { regExPhone } from '@constants/regexp';
import useAvailableFeatures from '@hooks/useAvailableFeatures';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import React, { FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MBHandsetIcon from '../../icons/MBHandsetIcon';
import MBLetterIcon from '../../icons/MBLetterIcon';
import MyModal from '../../UI/MyModal';

interface FieldType {
  id?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  role: string;
  is_active: boolean | null;
}

interface UserModalProps {
  isModalOpen: boolean;
  setIsModalOpened: React.Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  handleCreate: (value: any) => void;
  handleUpdate: (value: any) => void;
  isLoading: boolean;
}

interface RolesOptionsProps {
  value: string;
  label: string;
}

const WorkerModal: FC<UserModalProps> = ({
  isModalOpen,
  setIsModalOpened,
  form,
  handleCreate,
  handleUpdate,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { availableWorkers } = useAvailableFeatures();
  const rolesOptions: RolesOptionsProps[] = workers
    .filter((role) => availableWorkers.includes(role))
    .map((role) => ({ value: role, label: t(role) }));

  const userID = form.getFieldValue('id');

  useEffect(() => {
    if (isModalOpen && !userID && rolesOptions[0]) {
      form.setFieldValue('role', rolesOptions[0].value);
    }
  }, [isModalOpen]);

  const onFinish = (value: any) => {
    if (userID) {
      handleUpdate(value);
    } else {
      handleCreate(value);
    }
  };

  const WorkerForm = () => (
    <Form
      form={form}
      name={'worker-form'}
      layout={'vertical'}
      onFinish={onFinish}
      className={'rounded border border-solid border-grey-20 px-6 py-10'}
    >
      <Form.Item<FieldType> name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item<FieldType> name="is_active" hidden>
        <Input />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item<FieldType>
            label={t('first_name')}
            name="first_name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('first_name_placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item<FieldType>
            label={t('last_name')}
            name="last_name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('last_name_placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item<FieldType>
            label={t('phone')}
            name="phone_number"
            rules={[
              {
                required: true,
                pattern: regExPhone,
                message: t('input_errors.phone_only_numbers'),
              },
              {
                max: 15,
                message: t('input_errors.phone_max'),
              },
            ]}
          >
            <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item<FieldType>
            label={t('email')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
          >
            <Input
              placeholder={'mail@simmmple.com'}
              suffix={<MBLetterIcon className={'text-grey-20'} />}
              readOnly={userID}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item<FieldType> label={t('role')} name={'role'} required>
            <Select>
              {rolesOptions.length > 0 &&
                rolesOptions.map((opt, index) => (
                  <Select.Option key={index} value={opt.value}>
                    {opt.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <MyModal
      getContainer={false}
      title={t(userID ? 'edit_user' : 'add_new_user')}
      open={isModalOpen}
      onCancel={() => setIsModalOpened(false)}
      footer={
        <Button type={'primary'} loading={isLoading} onClick={() => form.submit()}>
          {t('save')}
        </Button>
      }
    >
      <WorkerForm />
    </MyModal>
  );
};

export default WorkerModal;
