import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface stepsElements {
  callToWaiterStep: HTMLElement | null;
  filterStep: HTMLElement | null;
  table: HTMLElement | null;
}

const useSteps = () => {
  const { t } = useTranslation();

  const [stepsElements, setStepsElements] = useState<stepsElements>({
    callToWaiterStep: null,
    filterStep: null,
    table: null,
  });

  const steps = [
    {
      title: t('onboarding.client_menu.step_1.title'),
      description: t('onboarding.client_menu.step_1.desc'),
      target: () => stepsElements.callToWaiterStep as HTMLElement,
      contentWidth: 335,
    },
    {
      title: t('onboarding.client_menu.step_2.title'),
      description: t('onboarding.client_menu.step_2.desc'),
      target: () => stepsElements.filterStep as HTMLElement,
      contentWidth: 335,
    },
    {
      title: t('onboarding.client_menu.step_3.title'),
      description: t('onboarding.client_menu.step_3.desc'),
      target: () => stepsElements.table as HTMLElement,
      contentWidth: 350,
    },
  ];

  // init steps elements by ids instead refs because of the elements are in the separate components (header, mobile menu)
  useEffect(() => {
    const callToWaiterStep = document.getElementById('client-call-waiter');
    const filterStep = document.getElementById('client-filter-menu');
    const table = document.getElementById('onboarding-table');

    setStepsElements({ callToWaiterStep, filterStep, table });
  }, []);

  return {
    steps,
  };
};

export default useSteps;
