import { ClientTableWaiter } from '@api/models/Waiter';
import paymentHeroImg from '@assets/img/bg-header-auth-form.jpg';
import placeholderRestLogo from '@assets/img/logo-restaurant-placeholder.png';
import { ClientOrder } from '@base/types/ClientOrder';
import CartDishItem from '@components/CartDishItem';
import MBWaiterIcon from '@components/icons/MBWaiterIcon';
import ChefHatLogo from '@components/svg/ChefHatLogo';
import PaymentSuccessLogo from '@components/svg/PaymentSuccessLogo';
import Heading from '@components/UI/Heading';
import OrderSection from '@components/UI/OrderSection';
import { CURRENCY, DATE_FORMAT } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import { removeClientOrderFromCookies } from '@helpers/removeClientOrderFromCookies';
import { removeClientOrderFromLocalstorage } from '@helpers/removeClientOrderFromLocalstorage';
import { Button, Card, Image } from 'antd';
import { FC, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ClientPaymentSuccessProps {
  orderCreatedAt: string;
  orderID: number;
  restName: string;
  restLogo: string | null;
  order: ClientOrder;
  waiter: ClientTableWaiter;
  tableName: string;
  restaurantLink: string;
}

const ClientPaymentSuccess: FC<ClientPaymentSuccessProps> = ({
  orderCreatedAt,
  orderID,
  restName,
  restLogo,
  order,
  waiter,
  tableName,
  restaurantLink,
}) => {
  const { t } = useTranslation();
  const { cart_total_price, all_products } = order;
  const navigate = useNavigate();

  const orderDate = convertServerDateToClient(orderCreatedAt, DATE_FORMAT);
  const waiterName = waiter?.first_name ? `${waiter.first_name} ${waiter.last_name}` : null;

  useLayoutEffect(() => {
    if (!order) {
      navigate(ROUTES.client_payment);
    }
  }, [order]);

  const handleClick = async () => {
    await removeClientOrderFromCookies();
    await removeClientOrderFromLocalstorage();

    window.location.href = `${restaurantLink}${ROUTES.restaurant_menu}/?order=success`;
  };

  return (
    <div>
      <img className={'h-[160px] w-full object-cover'} src={paymentHeroImg} alt={''} />
      <div className={'mx-auto mt-[-100px] max-w-2xl px-4 pb-10'}>
        <Card className={'mb-6 text-center'}>
          <Heading className={'text-center'}>{t('thanks')},</Heading>
          <p>{t('order_successfully_received')}!</p>
          <PaymentSuccessLogo />
        </Card>

        <Card className={'border-2 border-solid border-indigo-40'}>
          <div className={'flex flex-col gap-6'}>
            <header className={'flex items-center gap-4 pb-1'} style={{ borderBottom: '1px solid var(--grey-15)' }}>
              <Image
                preview={false}
                width={40}
                height={40}
                src={restLogo || placeholderRestLogo}
                alt={`Logo of ${restName} restaurant.`}
              />
              <div className={'font-bold'}>{restName}</div>
            </header>

            <div className={'flex flex-col gap-2'}>
              <div className={'flex flex-wrap items-center justify-between gap-x-4 gap-y-2'}>
                <div className={'text-sm'}>
                  {t('order_number')}: #{orderID}
                </div>
                <div className={'text-sm text-grey-30'}>{orderDate}</div>
              </div>
              <ChefHatLogo className={'mx-auto'} />
              <div className={'bg-cyan-10 px-4 py-2 text-center font-bold'}>{t('order_accepted')}</div>
            </div>
            <div
              className={`flex flex-wrap items-center gap-4 px-6 ${waiterName ? 'justify-between' : 'justify-center'}`}
            >
              {waiterName && (
                <div className={'flex flex-wrap items-center gap-1'}>
                  <MBWaiterIcon className={'min-w-[16px] text-base text-indigo-30'} />
                  <div className={'text-grey-30'}>{t('your_waiter')}:</div>
                  <div>{`${waiter.first_name} ${waiter.last_name}`}</div>
                </div>
              )}
              <div className={'inline-block rounded bg-indigo-40 px-2 py-1 leading-normal text-white'}>{tableName}</div>
            </div>
            <div className={'flex flex-col gap-2'}>
              {all_products.map((product, i) => (
                <CartDishItem
                  key={`cart-item-${i}`}
                  cartProductID={product.card_id}
                  name={product.dish_name}
                  description={product.description}
                  price={product.product_total_price}
                  options={product.options}
                  extras={product.extras}
                  amount={product.quantity}
                  removable={false}
                  counter={false}
                  isPromotion={product.isPromotion}
                  isWithoutBEMClasses={true}
                />
              ))}

              <OrderSection>
                <div className={'flex items-center justify-between gap-4'}>
                  <div>{t('cost')}:</div>
                  <div className={'font-bold'}>
                    {CURRENCY}
                    {cart_total_price}
                  </div>
                </div>
              </OrderSection>
            </div>
          </div>
        </Card>

        <Button type={'primary'} className={'mt-10 h-[52px] w-full justify-center'} onClick={handleClick}>
          {t('go_to_menu')}
        </Button>
      </div>
    </div>
  );
};

export default ClientPaymentSuccess;
