import { serverUrl } from '@constants/environments';
import checkIsSubdomain from '@helpers/checkIsSubdomain';

function getBaseUrlApi(): string {
  const subdomain = checkIsSubdomain();

  const url = serverUrl;

  if (subdomain) {
    const parts = url.split('://');

    return `${parts[0]}://${subdomain}.${parts[1]}`;
  }

  return url;
}

export default getBaseUrlApi;
