import api from '@api/api';
import { RequestedTableServices } from '@api/models/RequestedTableServices';
import { TableService } from '@api/models/TableService';
import WaiterCalls from '@api/models/WaiterCalls';
import { Call2WaiterRequest, RemoveWaiterCallRequest } from '@base/types/Call2Waiter';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';
import { t } from 'i18next';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['CallToWaiter'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTableServices: builder.query<TableService[], void>({
        query() {
          return {
            url: addTenantToEndpoint(`/table/waiter/calls/types`),
            method: 'GET',
          };
        },
        transformResponse: (response: { data: TableService[] }) => response.data,
      }),

      getRequestedServices: builder.query<RequestedTableServices, number>({
        query(client_id) {
          return {
            url: addTenantToEndpoint(`/table/client/waiter/calls/${client_id}`),
            method: 'GET',
          };
        },
        transformResponse: (response: { data: RequestedTableServices }) => response.data,
      }),

      createServiceRequest: builder.mutation<void, Call2WaiterRequest>({
        query({ table_id, client_id, waiter_id, service_id }) {
          return {
            url: addTenantToEndpoint(
              `/table/${table_id}/client/${client_id}/call/waiter/${waiter_id}/service/${service_id}`
            ),
            method: 'POST',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error'));
          }
        },
      }),

      removeServiceRequest: builder.mutation<void, Call2WaiterRequest>({
        query({ table_id, client_id, waiter_id, service_id }) {
          return {
            url: addTenantToEndpoint(
              `/table/${table_id}/client/${client_id}/recall/waiter/${waiter_id}/service/${service_id}`
            ),
            method: 'DELETE',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error'));
          }
        },
      }),

      removeAllServicesRequests: builder.mutation<void, RemoveWaiterCallRequest>({
        query({ table_id, waiter_id, client_id }) {
          return {
            url: addTenantToEndpoint(`/table/${table_id}/client/${client_id}/recall/services/waiter/${waiter_id}`),
            method: 'DELETE',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error'));
          }
        },
      }),

      getWaiterCalls: builder.query<WaiterCalls[], number>({
        query(waiter_id) {
          return {
            url: addTenantToEndpoint(`/table/waiter/calls/${waiter_id}`),
            method: 'GET',
          };
        },
        providesTags: ['CallToWaiter'],
        transformResponse: (response: { data: WaiterCalls[] }) => response.data,

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error'));
          }
        },
      }),

      removeWaiterCall: builder.mutation<void, RemoveWaiterCallRequest>({
        query({ table_id, waiter_id, client_id }) {
          return {
            url: addTenantToEndpoint(`/table/${table_id}/recall/waiter/${waiter_id}/client/${client_id}`),
            method: 'DELETE',
          };
        },
        invalidatesTags: ['CallToWaiter'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error'));
          }
        },
      }),
    }),
  });

export const {
  useGetTableServicesQuery,
  useLazyGetRequestedServicesQuery,
  useCreateServiceRequestMutation,
  useRemoveServiceRequestMutation,
  useLazyGetWaiterCallsQuery,
  useRemoveWaiterCallMutation,
  useRemoveAllServicesRequestsMutation,
} = extendedApiSlice;
