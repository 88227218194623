import getBaseUrlApi from '@helpers/getBaseUrlApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../store';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrlApi(),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userState.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('accept', `application/json`);
        headers.set('contentType', `application/json`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default api;
