import api from '@api/api';
import Onboarding from '@base/types/Onboarding';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    updateOnboardingManager: builder.mutation<void, { id: number; data: Onboarding }>({
      query({ id, data }) {
        return {
          method: 'PATCH',
          url: `/onboarding/manager/${id}`,
          body: { onboarding: data },
        };
      },
    }),
    updateOnboardingWorker: builder.mutation<void, { id: number; data: Onboarding }>({
      query({ id, data }) {
        return {
          method: 'PATCH',
          url: addTenantToEndpoint(`/onboarding/worker/${id}`),
          body: { onboarding: data },
        };
      },
    }),
    updateOnboardingClient: builder.mutation<void, { id: number; data: Onboarding }>({
      query({ id, data }) {
        return {
          method: 'PATCH',
          url: addTenantToEndpoint(`/onboarding/client/${id}`),
          body: { onboarding: data },
        };
      },
    }),
  }),
});

export const {
  useUpdateOnboardingManagerMutation,
  useUpdateOnboardingWorkerMutation,
  useUpdateOnboardingClientMutation,
} = extendedApiSlice;
