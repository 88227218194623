import { useAppSelector } from '@base/redux/store';
import useAuth from '@hooks/useAuth';

const useCheckIsWorkerRestConnected = () => {
  const { restaurantId: userRestId } = useAuth();
  const restInfoId = useAppSelector((state) => state.restaurantState.id);

  return userRestId === restInfoId;
};

export default useCheckIsWorkerRestConnected;
