import { SVGProps } from 'react';
const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35669 2.31255C0.5 3.60729 0.5 5.40486 0.5 9C0.5 12.5951 0.5 14.3927 1.35669 15.6874C1.73925 16.2656 2.23439 16.7608 2.81255 17.1433C4.10729 18 5.90486 18 9.5 18C13.0951 18 14.8927 18 16.1874 17.1433C16.7656 16.7608 17.2608 16.2656 17.6433 15.6874C18.5 14.3927 18.5 12.5951 18.5 9C18.5 5.40486 18.5 3.60729 17.6433 2.31255C17.2608 1.73439 16.7656 1.23925 16.1874 0.856692C14.8927 0 13.0951 0 9.5 0C5.90486 0 4.10729 0 2.81255 0.856692C2.23439 1.23925 1.73925 1.73439 1.35669 2.31255ZM14.3436 5.20137C14.9483 5.20137 15.4385 4.7112 15.4385 4.10654C15.4385 3.50189 14.9483 3.01172 14.3436 3.01172C13.739 3.01172 13.2488 3.50189 13.2488 4.10654C13.2488 4.7112 13.739 5.20137 14.3436 5.20137ZM9.50023 13.6587C12.0736 13.6587 14.1596 11.5726 14.1596 8.99925C14.1596 6.42593 12.0736 4.33984 9.50023 4.33984C6.92691 4.33984 4.84082 6.42593 4.84082 8.99925C4.84082 11.5726 6.92691 13.6587 9.50023 13.6587ZM12.5832 8.99925C12.5832 10.7019 11.2029 12.0823 9.50023 12.0823C7.79753 12.0823 6.41723 10.7019 6.41723 8.99925C6.41723 7.29656 7.79753 5.91625 9.50023 5.91625C11.2029 5.91625 12.5832 7.29656 12.5832 8.99925Z"
      fill="currentColor"
    />
  </svg>
);
export default InstagramIcon;
