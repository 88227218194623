import MBChevronDownIcon from '@base/components/icons/MBChevronDownIcon';
import { OrderStatus } from '@base/redux/api/models/Order';
import { Select, SelectProps } from 'antd';
import { t } from 'i18next';
import { FC, MouseEventHandler } from 'react';

interface ChangeOrderStatusSelectProps extends SelectProps {
  defaultStatus: OrderStatus;
  statuses: OrderStatus[];
  className?: string;
  onChange?: (value: any) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ChangeOrderStatusSelect: FC<ChangeOrderStatusSelectProps> = ({
  id,
  defaultStatus,
  statuses,
  className,
  onChange,
  ...props
}) => {
  return (
    <Select
      key={`status-select-${id}-${defaultStatus.status}`}
      defaultValue={defaultStatus.id}
      className={`${className} order-monitoring-status-select order-monitoring-status-${defaultStatus.status}`}
      onChange={onChange}
      suffixIcon={<MBChevronDownIcon />}
      {...props}
    >
      {statuses &&
        statuses.map((status) => (
          <Select.Option key={`status-key-${status.status}`} id={status.id} value={status.id}>
            {t(`${status.status}`)}
          </Select.Option>
        ))}
    </Select>
  );
};

export default ChangeOrderStatusSelect;
