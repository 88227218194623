import useClientAccountPreferences from '@components/AccountSettings/ClientAccountPreferences/useClientAccountPreferences';
import SimpleSection from '@components/UI/SimpleSection';
import { Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';

interface ClientAccountPreferencesFrom {
  allergies: number[];
  dish_types: number[];
}

const ClientAccountPreferences = () => {
  const {
    t,
    form,
    dishTypeList,
    isLoadingTypeList,
    isErrorTypeList,
    allergyList,
    isLoadingAllergyList,
    isErrorAllergyList,
    onFinish,
    isUpdating,
  } = useClientAccountPreferences();

  const renderTypes = () => {
    return (
      <Form.Item name={'dish_types'}>
        <Checkbox.Group>
          <Row gutter={[12, 12]}>
            {dishTypeList.map(
              (dishType) =>
                dishType.code !== 'no_type' && (
                  <Col xs={24} key={dishType.id}>
                    <Checkbox value={dishType.id}>{t(dishType.name)}</Checkbox>
                  </Col>
                )
            )}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  };

  return (
    <SimpleSection title={t('personal_settings')}>
      <Card>
        <Form
          form={form}
          name="client_account_preferences"
          layout="vertical"
          initialValues={{ remember: false }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item<ClientAccountPreferencesFrom> label={t('allergies')} name={'allergies'}>
            <Select
              mode="multiple"
              allowClear
              placeholder={t('allergies_placeholder')}
              className={'select-checkbox w-full'}
              popupClassName={'select-checkbox-list'}
              loading={isLoadingAllergyList}
            >
              {allergyList &&
                allergyList.map((allergy, i) => (
                  <Select.Option key={`allergy-${i}`} value={allergy.id}>
                    {allergy.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {isErrorAllergyList && <p>{t('error')}</p>}

          {isErrorTypeList ? (
            <p>{t('error')}</p>
          ) : isLoadingTypeList ? (
            <p>{t('loading')}</p>
          ) : (
            <div className={'mt-6 flex flex-col'}>{renderTypes()}</div>
          )}

          <Button type={'primary'} size={'small'} htmlType={'submit'} loading={isUpdating}>
            {t('save')}
          </Button>
        </Form>
      </Card>
    </SimpleSection>
  );
};

export default ClientAccountPreferences;
