import { useSetTitlePage } from '@base/hooks/useSetTitlePage';
import ClientOrderFeedback from '@components/Client/ClientOrderFeedback';
import { useTranslation } from 'react-i18next';

const ClientOrderFeedbackPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.sending_feedback'));

  return <ClientOrderFeedback />;
};

export default ClientOrderFeedbackPage;
