import SimpleSection from '@components/UI/SimpleSection';
import useTablesOnboarding from '@components/WaiterTables/TablesOnboarding/useTablesOnboarding';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import MBPlusIcon from '../icons/MBPlusIcon';
import useWaiterTables from './useWaiterTables';
import WaiterTable from './WaiterTable';
import WaiterTablesModal from './WaiterTablesModal';
import WaiterTakeAwayTable from './WaiterTakeAwayTable';

const WaiterTables = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.tables'));

  const {
    form,
    modal,
    contextHolder,
    showModalConfirm,
    isModalOpened,
    setIsModalOpened,
    dataTables,
    dataWaiters,
    handleAddTable,
    handleEditTable,
    handleCreate,
    handleUpdate,
    handleRemove,
    isLoadingAction,
    isLoadingData,
    isWaiters2Tables,
  } = useWaiterTables();

  const { isShowTour, renderTour, obStepRef1 } = useTablesOnboarding();

  return (
    <SimpleSection ref={obStepRef1} title={t('pages.tables')}>
      {isLoadingData ? (
        <Card className={'w-full max-w-[526px]'}>
          <p>{t('loading')}</p>
        </Card>
      ) : (
        <>
          {isShowTour && renderTour()}
          <WaiterTakeAwayTable waiters={dataWaiters} isWaiters2Tables={isWaiters2Tables} />
          {contextHolder}
          <div className={'mb-4 flex w-full max-w-[526px] flex-col rounded-[10px] bg-white p-4 shadow-custom md:p-6'}>
            <div className={'flex max-h-[55vh] flex-col overflow-y-auto'}>
              {dataTables &&
                dataTables.map((table) => (
                  <WaiterTable
                    key={table.id}
                    table={table}
                    handleEditTable={handleEditTable}
                    handleRemove={handleRemove}
                    showModalConfirm={showModalConfirm}
                    modal={modal}
                  />
                ))}
            </div>
            <div className={'flex justify-center'}>
              <Button type="default" icon={<MBPlusIcon />} className={'mt-4 w-max px-2'} onClick={handleAddTable}>
                {t('add_new_table')}
              </Button>
            </div>
          </div>
          <WaiterTablesModal
            isModalOpen={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            form={form}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            isLoading={isLoadingAction}
            waiters={dataWaiters}
            isWaiters2Tables={isWaiters2Tables}
          />
        </>
      )}
    </SimpleSection>
  );
};

export default WaiterTables;
