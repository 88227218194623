import api from '@api/api';
import { Feedback } from './models/Feedback';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Feedback'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFeedbackByOrderId: builder.query<Feedback, number>({
        query: (id) => `/client/order/${id}/feedback`,
        transformResponse: (response: { data: Feedback }) => response.data,
        providesTags: ['Feedback'],
      }),
      sendFeedback: builder.mutation<void, { data: any; restaurantId: number; clientId: number; orderId: number }>({
        query({ restaurantId, clientId, orderId, data }) {
          return {
            url: `/restaurant/${restaurantId}/client/${clientId}/order/${orderId}/feedback`,
            method: 'POST',
            body: data,
          };
        },
      }),
      editFeedback: builder.mutation<Feedback, { id: number; data: any }>({
        query({ id, data }) {
          return {
            url: `/client/order/feedback/${id}`,
            method: 'PATCH',
            body: data,
          };
        },
        invalidatesTags: ['Feedback'],
      }),
    }),
  });

export const { useGetFeedbackByOrderIdQuery, useSendFeedbackMutation, useEditFeedbackMutation } = extendedApiSlice;
