import { useDeleteAccountByManagerMutation } from '@api/accauntSettingsApi';
import { useAppSelector } from '@base/redux/store';
import useLogout from '@hooks/useLogout';
import useModalConfirm from '@hooks/useModalConfirm';
import { App, Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useAccountRemoving = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const userID = useAppSelector((state) => state.userState.user?.id);
  const [modal, contextHolder] = Modal.useModal();
  const { showModalConfirm } = useModalConfirm();
  const { logout } = useLogout();

  const [deleteAccountByManager, { isError, isSuccess }] = useDeleteAccountByManagerMutation();

  const onRemoveAccount = async () => {
    if (!userID) {
      return;
    }

    await deleteAccountByManager(userID);
  };

  useEffect(() => {
    if (isSuccess) {
      logout();
    }

    if (isError) {
      message.error(t('error'));
    }
  }, [isSuccess, isError]);

  return {
    t,
    modal,
    contextHolder,
    showModalConfirm,
    onRemoveAccount,
  };
};

export default useAccountRemoving;
