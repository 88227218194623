import { WorkHours } from '@base/types/WorkHours';
import { weekDaysArray } from '@constants/constants';
import { useTranslation } from 'react-i18next';

export const useRestaurantHoursUpdate = () => {
  const { t } = useTranslation();

  function getSchedule(daysHours: WorkHours): string {
    let result = '';

    weekDaysArray.forEach((day) => {
      const currentDay = daysHours[day];
      if (currentDay.day_off && currentDay.from && currentDay.to) {
        result += `${currentDay.from} - ${currentDay.to}, ${t(day)}\n`;
      }
    });

    return result;
  }

  return {
    getSchedule,
  };
};
