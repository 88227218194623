import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  filter: string;
}

const initialState: InitialState = {
  filter: 'day',
};

export const dashboardSlice = createSlice({
  initialState,
  name: 'dashboard',
  reducers: {
    resetDashboardSlice: () => initialState,
    setDashboardFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
  },
});

export default dashboardSlice.reducer;

export const { resetDashboardSlice, setDashboardFilter } = dashboardSlice.actions;
