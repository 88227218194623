import DishPlaceholderLogo from '@components/svg/DishPlaceholderLogo';
import { FC } from 'react';

const DishPreview: FC<{ src: string | any }> = ({ src }) => {
  return (
    <div className={'relative flex h-full w-full items-center justify-center overflow-hidden bg-grey-20'}>
      {src ? (
        <img alt="example" src={src} className={'object-center-top absolute left-0 top-0 h-full w-full object-cover'} />
      ) : (
        <DishPlaceholderLogo />
      )}
    </div>
  );
};

export default DishPreview;
