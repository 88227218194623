import { SVGProps } from 'react';
const MBUserLoggedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.3334 15V13.6667C14.3334 12.9594 14.0524 12.2811 13.5523 11.781C13.0522 11.281 12.3739 11 11.6667 11H6.33335C5.62611 11 4.94783 11.281 4.44774 11.781C3.94764 12.2811 3.66669 12.9594 3.66669 13.6667V15"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99998 8.33333C10.4727 8.33333 11.6666 7.13943 11.6666 5.66667C11.6666 4.19391 10.4727 3 8.99998 3C7.52722 3 6.33331 4.19391 6.33331 5.66667C6.33331 7.13943 7.52722 8.33333 8.99998 8.33333Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={1} y={1} width={16} height={16} rx={8} stroke="currentColor" strokeWidth={1.5} />
  </svg>
);
export default MBUserLoggedInIcon;
