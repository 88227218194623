import { setWorkerRegistrationData } from '@base/redux/features/workerSlice';
import extractQueryParameter from '@helpers/extractQueryParameter';
import NoMatchPage from '@pages/NoMatchPage';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

const CheckParamWorker = () => {
  const url = window.location.href;
  const dispatch = useDispatch();
  const { email, role } = extractQueryParameter(url);

  useLayoutEffect(() => {
    if (email && role) {
      dispatch(setWorkerRegistrationData({ email, role }));
    } else {
      dispatch(setWorkerRegistrationData(null));
    }
  }, []);

  if (email && role) {
    return <Outlet />;
  }

  return <NoMatchPage />;
};

export default CheckParamWorker;
