import { useGetQRCodeQuery } from '@base/redux/api/qrCodeApi';
import { useGetSettingsQuery } from '@base/redux/api/restaurantSettingsApi';
import { setSubdomain } from '@base/redux/features/commonSlice';
import {
  setBackgroundColor,
  setForegroundColor,
  setIsQRCodeLoaded,
  setQRCodeId,
} from '@base/redux/features/QRCodeSlice';
import { useAppSelector } from '@base/redux/store';
import useQRBuilderOnboarding from '@components/QRBuilder/QRBuilderOnboarding/useQRBuilderOnboarding';
import SimpleSection from '@components/UI/SimpleSection';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QRColorPicker from './QRColorPicker';
import QRPreviewDownload from './QRPreviewDownload';
import QRPrint from './QRPrint';

const QRBuilder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSuccessReq, setIsSuccessReq] = useState<boolean>(false);
  const restaurantId = useAppSelector((state) => state.userState.restaurantId);
  const {
    data: restaurantData,
    isError: restaurantIsError,
    isLoading: restaurantIsLoading,
    isSuccess: restaurantIsSuccess,
  } = useGetSettingsQuery(restaurantId);
  const {
    data: qrCodeData,
    isError: qrCodeIsError,
    isLoading: qrCodeIsLoading,
    isSuccess: qrCodeIsSuccess,
  } = useGetQRCodeQuery();

  const { isShowTour, renderTour, obStepRef1 } = useQRBuilderOnboarding();

  useEffect(() => {
    if (qrCodeIsSuccess && qrCodeData.id) {
      dispatch(setQRCodeId(qrCodeData.id));
      dispatch(setIsQRCodeLoaded(true));
      dispatch(setForegroundColor(qrCodeData.foreground_color));
      dispatch(setBackgroundColor(qrCodeData.background_color));
    }

    if (qrCodeIsError) {
      dispatch(setQRCodeId(null));
      dispatch(setIsQRCodeLoaded(false));
      dispatch(setForegroundColor('#000000'));
      dispatch(setBackgroundColor('#ffffff'));
    }
  }, [qrCodeIsLoading]);

  useEffect(() => {
    if (restaurantIsSuccess && restaurantData?.domain) {
      dispatch(setSubdomain(restaurantData.domain));
      setIsSuccessReq(true);
    }

    if (restaurantIsError || !restaurantData?.domain) {
      dispatch(setSubdomain(''));
    }
  }, [restaurantIsLoading]);

  return (
    <SimpleSection ref={obStepRef1} title={t('pages.qr_builder')}>
      <Card>
        {restaurantIsLoading || qrCodeIsLoading ? (
          <p>{t('loading')}</p>
        ) : isSuccessReq ? (
          <>
            {isShowTour && renderTour()}

            <div className={'flex w-full flex-wrap justify-between gap-10'}>
              <div className={'flex gap-10'}>
                <div className={'w-full max-w-[248px] shrink-0'}>
                  <QRColorPicker />
                </div>
                <div className={'w-full max-w-[335px] shrink-0'}>
                  <QRPreviewDownload />
                </div>
              </div>
              <div className={'max-w-[457px] shrink-0 grow text-center'}>
                <QRPrint />
              </div>
            </div>
          </>
        ) : (
          <p>{t('create_restaurant_subdomain_first')}</p>
        )}
      </Card>
    </SimpleSection>
  );
};

export default QRBuilder;
