import ifExistVerticalScrollPage from '@helpers/ifExistVerticalScrollPage';
import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';

const MyModal = ({ footer, ...props }: ModalProps) => {
  if (ifExistVerticalScrollPage() && props.open) {
    document.body.classList.add('modal-opened');
  }

  const afterChange = () => {
    if (!props.open) {
      document.body.classList.remove('modal-opened');
    }
  };

  const footerModal = <div className={'w-full text-center'}>{footer as ReactNode}</div>;

  return (
    <Modal afterOpenChange={afterChange} width={590} centered footer={footerModal} {...props}>
      {props.children}
    </Modal>
  );
};

export default MyModal;
