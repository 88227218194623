import { ClientOrder } from '@api/models/Order';
import { useGetGuestOrdersQuery } from '@api/ordersApi';
import orderHeroImg from '@assets/img/bg-header-auth-form.jpg';
import { setGuestOrders } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import { OrderStatusesType } from '@base/types/OrderStatusesType';
import { ROUTES } from '@base/utils/constants/routes';
import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import GuestOrder from './GuestOrder';

const filterAndSortOrders = (orders: ClientOrder[], status: OrderStatusesType): ClientOrder[] => {
  return orders
    ? orders
        .filter((order) => order.status.status === status)
        .sort((a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime())
    : [];
};

const GuestOrders = () => {
  const { t } = useTranslation();
  const restLink = getRestaurantLinkFromCookies();
  const storedOrders = useAppSelector((state) => state.clientSlice.guestOrders);

  const { data, isLoading, isSuccess, isError } = useGetGuestOrdersQuery(
    storedOrders.map((storedOrder) => storedOrder.id)
  );
  const [orders, setOrders] = useState<ClientOrder[] | []>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data && data?.data) {
      const newStoredOrders: Array<{ id: number; order_date: number }> = [];
      const responseData = data.data.filter((order) => {
        const storedOrder = storedOrders.find((item) => item.id === order.id);

        if (storedOrder) {
          if (
            (order.status.status === 'completed' && Date.now() - storedOrder.order_date < 7200000) ||
            order.status.status !== 'completed'
          ) {
            newStoredOrders.push(storedOrder);

            return true;
          }
        }

        return false;
      });

      dispatch(setGuestOrders(newStoredOrders));

      setOrders(responseData);
    }
  }, [isLoading, data]);

  //sorting orders
  const waitingOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'waiting'), [orders]);
  const inProgressOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'in_progress'), [orders]);
  const completedOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'completed'), [orders]);

  const notCompletedOrders: ClientOrder[] = useMemo(() => {
    return [...waitingOrders, ...inProgressOrders];
  }, [waitingOrders, inProgressOrders]);

  return (
    <>
      {isError ? (
        <div>{t('error')}</div>
      ) : isLoading ? (
        <div>{t('loading')}</div>
      ) : (
        isSuccess &&
        orders && (
          <div>
            <img className={'h-[160px] w-full object-cover'} src={orderHeroImg} alt={''} />
            <div className={'mx-auto mt-[-100px] flex max-w-2xl flex-col gap-6 px-4 pb-10'}>
              <div className={'flex flex-col items-center gap-6 rounded bg-white px-4 py-6 shadow-custom'}>
                <span className={'text-xl font-bold leading-[120%] text-indigo-40'}>{t('my_orders')}</span>
                {orders && orders.length > 0 ? (
                  <>
                    {notCompletedOrders?.length > 0 &&
                      notCompletedOrders.map((orderItem) => (
                        <GuestOrder key={`order-${orderItem.id}`} order={orderItem} />
                      ))}
                    {completedOrders?.length > 0 && (
                      <>
                        <span className={'font-bold leading-[120%] text-grey-30'}>{t('previous_orders')}</span>
                        {completedOrders.map((orderItem) => (
                          <GuestOrder key={`order-${orderItem.id}`} order={orderItem} />
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <span className={'leading-[150%] text-grey-40'}>{t('you_have_not_placed_orders_yet')}.</span>
                )}
              </div>

              {restLink && (
                <Button
                  href={`${restLink}${ROUTES.restaurant_menu}`}
                  type="primary"
                  className={'m-auto w-[206px] justify-center no-underline'}
                >
                  {t('go_to_menu')}
                </Button>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default GuestOrders;
