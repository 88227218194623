import api from '@api/api';
import { BestDish } from '@api/models/BestDishe';
import { PopularPromotion } from '@api/models/PopularPromotion';
import RestaurantStatistics from '@api/models/RestaurantStatistics';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getPopularPromotions: builder.query<PopularPromotion[], string>({
      query(filter) {
        return {
          url: addTenantToEndpoint(`/dashboard/popular/promotions`),
          params: {
            filter,
          },
          method: 'GET',
        };
      },
      transformResponse: (response: { data: PopularPromotion[] }) => response.data,
    }),
    getBestDishes: builder.query<BestDish[], string>({
      query(filter) {
        return {
          url: addTenantToEndpoint(`/dashboard/popular/dishes`),
          params: {
            filter,
          },
          method: 'GET',
        };
      },
      transformResponse: (response: { data: BestDish[] }) => response.data,
    }),
    getStatistics: builder.query<RestaurantStatistics, string>({
      query(filter) {
        return {
          url: addTenantToEndpoint(`/dashboard/order/statistics`),
          params: {
            filter,
          },
          method: 'GET',
        };
      },
      transformResponse: (response: { data: RestaurantStatistics }) => response.data,
    }),
  }),
});

export const { useGetPopularPromotionsQuery, useGetBestDishesQuery, useGetStatisticsQuery } = extendedApiSlice;
