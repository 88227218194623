import GoogleCredentials from '@base/types/GoogleCredentials';
import useGoogleRegistrationForm from '@components/AuthForm/RegistrationForm/GoogleRegistrationForm/useGoogleRegistrationForm';
import MBHandsetIcon from '@components/icons/MBHandsetIcon';
import { regExPhone } from '@constants/regexp';
import { Button, Checkbox, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface GoogleFormFieldType {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  agreed: string;
  google_accessor: string;
}

interface GoogleRegistrationFormProps {
  setRegSuccess: Dispatch<SetStateAction<boolean>>;
  googleCredentials: GoogleCredentials;
}

const GoogleRegistrationForm: FC<GoogleRegistrationFormProps> = ({ googleCredentials, setRegSuccess }) => {
  const { t, form, onFinish, isLoading, isSuccess } = useGoogleRegistrationForm();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setRegSuccess(true);
    }
  }, [isLoading, isSuccess]);

  // set initial values from google credentials
  useEffect(() => {
    form.setFieldsValue({
      email: googleCredentials.email,
      first_name: googleCredentials.given_name,
      last_name: googleCredentials.family_name,
      google_accessor: googleCredentials.google_accessor,
    });
  }, []);

  return (
    <>
      <Form
        form={form}
        name="register"
        layout="vertical"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<GoogleFormFieldType> name={'first_name'} hidden>
          <Input />
        </Form.Item>
        <Form.Item<GoogleFormFieldType> name={'last_name'} hidden>
          <Input />
        </Form.Item>
        <Form.Item<GoogleFormFieldType> name={'email'} hidden>
          <Input />
        </Form.Item>
        <Form.Item<GoogleFormFieldType> name={'google_accessor'} hidden>
          <Input />
        </Form.Item>

        <Form.Item<GoogleFormFieldType>
          label={t('phone')}
          name="phone_number"
          rules={[
            {
              required: true,
              pattern: regExPhone,
              message: t('input_errors.phone_only_numbers'),
            },
            {
              max: 15,
              message: t('input_errors.phone_max'),
            },
          ]}
        >
          <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
        </Form.Item>
        <Form.Item<GoogleFormFieldType>
          name="agreed"
          valuePropName="checked"
          className={'mb-0 mt-5 pb-4'}
          required
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
            },
          ]}
        >
          <Checkbox className={'text-xs leading-[1.5]'}>
            <div className={'pb-2 text-xs'}>
              {t('agree_to_the')} <Link to={'#'}>{t('terms_of_use')}</Link> |{' '}
              <Link to={'#'}>{t('privacy_policy')}</Link>
              <span className={'px-1 text-lg font-bold leading-none text-cyan-30'}>*</span>
            </div>
          </Checkbox>
        </Form.Item>

        <Form.Item className={'mb-0'}>
          <Button type="primary" htmlType="submit" className={'w-full justify-center lg:w-[206px]'} loading={isLoading}>
            {t('signup')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GoogleRegistrationForm;
