import { QRCode } from '@api/models/QRCode';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['QRCode'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getQRCode: builder.query<QRCode, void>({
        query: () => addTenantToEndpoint(`/qrcode`),
        transformResponse: (response: { data: QRCode }) => response.data,
        providesTags: ['QRCode'],
      }),

      createQRCode: builder.mutation<QRCode, any>({
        query(data) {
          return {
            url: `/qrcode`,
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: ['QRCode'],
      }),
      updateQRCode: builder.mutation<QRCode, { data: QRCode; id: number }>({
        query({ data, id }) {
          return {
            url: `/qrcode/${id}`,
            method: 'PUT',
            body: data,
          };
        },
        invalidatesTags: ['QRCode'],
      }),
    }),
  });

export const { useGetQRCodeQuery, useCreateQRCodeMutation, useUpdateQRCodeMutation } = extendedApiSlice;
