import { SVGProps } from 'react';
const SuccessfulGuy = (props: SVGProps<SVGSVGElement>) => (
  <svg width={141} height={182} viewBox="0 0 141 182" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1797_12835)">
      <path d="M46.9046 181.5H52.297L52.297 114.23H46.9046L46.9046 181.5Z" fill="#205FAE" />
      <path
        d="M56.1527 111.729H43.0558C43.0558 113.106 44.1664 114.223 45.5355 114.223H53.68C55.0491 114.223 56.1597 113.106 56.1597 111.729H56.1527Z"
        fill="#205FAE"
      />
      <path d="M61.5242 132.694L56.6906 181.5H57.5776L66.1902 132.694H61.5242Z" fill="#DD6B20" />
      <path d="M76.6399 132.694L81.4735 181.5H80.5864L71.9739 132.694H76.6399Z" fill="#DD6B20" />
      <path d="M50.2504 132.694L39.8008 181.5H40.6809L54.9164 132.694H50.2504Z" fill="#DD6B20" />
      <path d="M87.9206 132.694L98.3703 181.5H97.4832L83.2546 132.694H87.9206Z" fill="#DD6B20" />
      <path
        d="M94.8918 128.515C94.9407 133.488 79.9648 136.009 56.7046 136.009C32.0753 136.009 29.1696 116.175 29.1696 100.597C29.1696 87.2037 30.8669 78.4807 37.9218 76.3245C39.0604 75.9593 40.3317 75.7837 41.7776 75.7837C46.5483 75.7837 50.439 79.6887 50.439 84.4927V95.4984C50.439 101.398 57.3402 117.404 63.1937 117.741C82.6051 118.809 91.4901 122.876 94.0745 126.366C94.6264 127.118 94.8988 127.848 94.8988 128.515H94.8918Z"
        fill="#FBD38D"
      />
      <path
        d="M94.0675 126.367C63.2984 136.972 44.0965 131.437 38.7669 119.589C34.3244 109.7 33.9123 84.781 37.9217 76.3318C39.0603 75.9666 40.3316 75.791 41.7775 75.791C46.5483 75.791 50.4389 79.696 50.4389 84.5V95.5057C50.4389 101.405 57.3401 117.412 63.1936 117.749C82.605 118.816 91.49 122.883 94.0745 126.374L94.0675 126.367Z"
        fill="#DD6B20"
      />
      <path d="M91.3154 111.737H109.057V106.884H91.3154V111.737Z" fill="#4286FB" />
      <path
        d="M81.236 95.0772V95.1193C81.0264 98.7645 80.5445 102.726 79.5805 106.877C79.2872 108.225 78.9309 109.595 78.5118 110.985C78.4909 111.091 78.449 111.196 78.407 111.322C78.3861 111.449 78.3442 111.596 78.3023 111.723C77.946 112.839 77.5898 113.935 77.1707 115.052V115.073C77.1497 115.115 77.1288 115.136 77.1078 115.178V115.199H76.7935L70.1717 115.115L69.9621 115.094H68.8096L59.0236 114.946H58.4159L52.3179 114.862H52.297L48.7346 114.799L46.9115 114.778H46.2619C46.2409 114.609 46.22 114.42 46.199 114.23C46.1781 114.104 46.1781 113.956 46.1571 113.83L46.0943 113.387C46.0523 112.839 45.9895 112.292 45.9266 111.723C45.7799 110.185 45.6332 108.541 45.4866 106.877C45.3189 105.043 45.1932 103.126 45.0884 101.188C45.0675 101.04 45.0465 100.914 45.0465 100.766C44.7112 95.33 44.6274 89.6622 45.0675 84.3525L45.1094 83.9311C45.277 82.0137 45.5075 80.1595 45.8428 78.3685C45.9266 77.8839 46.0104 77.4204 46.1152 76.9568C46.3248 76.0087 46.5343 75.0816 46.8067 74.1966C46.8067 74.1334 46.8277 74.0702 46.8486 74.007C47.5821 71.5207 48.525 69.2873 49.7824 67.391C49.8033 67.3067 49.8452 67.2224 49.9081 67.1592C50.2015 66.7167 50.4948 66.3164 50.8092 65.9161C51.0816 65.5579 51.3959 65.1997 51.7312 64.8836C52.0036 64.5886 52.297 64.3147 52.6113 64.0408C52.7999 63.8933 53.0094 63.7458 53.219 63.5983C57.4519 60.501 63.5708 60.6485 68.9144 63.4298C69.4173 63.6826 69.9202 63.9565 70.4231 64.2726C70.8003 64.5043 71.1775 64.7572 71.5338 65.0311C71.8691 65.2629 72.2043 65.4946 72.5187 65.7896C72.6025 65.8318 72.6863 65.895 72.7701 66.0003C75.8505 68.5288 78.3442 72.1318 79.5386 76.5354C79.6015 76.6829 79.6224 76.8093 79.6644 76.9568C80.0206 78.3896 80.3978 80.2016 80.6912 82.3297C80.754 82.8354 80.8378 83.3832 80.9007 83.9311C81.2569 87.1127 81.4455 90.8842 81.236 95.0772Z"
        fill="#39C9DF"
      />
      <path
        d="M46.1642 113.83L46.1013 113.38C62.1599 103.99 66.1903 78.0525 63.8782 66.7308L64.2764 66.6465C66.6094 78.0665 62.3904 104.341 46.1642 113.823V113.83Z"
        fill="#1095A9"
      />
      <path
        d="M45.0675 101.216L45.0396 100.759C54.8675 92.022 58.6115 72.4056 57.9339 64.2866L58.3391 64.2515C58.9188 71.1625 55.8384 91.6427 45.0675 101.216Z"
        fill="#1095A9"
      />
      <path
        d="M58.8699 115.101L58.3949 114.96C68.7048 105.444 74.8167 84.2189 72.344 65.8106L72.7492 65.7544C75.2358 84.2821 69.2706 105.493 58.8699 115.094V115.101Z"
        fill="#1095A9"
      />
      <path
        d="M80.9007 83.9309C80.6283 86.5436 80.1882 89.2406 79.5386 91.9376C79.5177 92.064 79.4758 92.2115 79.4339 92.359C78.5118 96.2781 77.2126 100.197 75.62 103.884C75.5362 104.053 75.4733 104.222 75.3895 104.39C75.0333 105.233 74.6351 106.055 74.237 106.876C73.4197 108.562 72.5187 110.184 71.5757 111.723C71.0728 112.544 70.5698 113.324 70.025 114.082C69.9412 114.23 69.8364 114.356 69.7316 114.504C69.6269 114.672 69.5011 114.841 69.3754 115.009C69.3544 115.052 69.3335 115.073 69.3125 115.094C69.2916 115.136 69.2497 115.178 69.2287 115.22L68.7677 115.157C68.7677 115.157 68.7887 115.115 68.8096 115.094C68.8306 115.073 68.8515 115.052 68.8725 115.009C69.103 114.693 69.3335 114.377 69.564 114.04C69.6688 113.893 69.7526 113.766 69.8574 113.619C70.2765 113.008 70.6746 112.376 71.0728 111.723C71.9948 110.205 72.8749 108.583 73.7131 106.876C74.216 105.865 74.677 104.833 75.1171 103.779C75.2009 103.61 75.2847 103.442 75.3476 103.273C76.8983 99.6072 78.1765 95.7092 79.1195 91.8322C79.1614 91.6848 79.1824 91.5373 79.2243 91.4108C79.9577 88.3135 80.4607 85.2373 80.6912 82.3296C80.754 82.8353 80.8378 83.3831 80.9007 83.9309Z"
        fill="#1095A9"
      />
      <path
        d="M69.9831 115.171C65.2891 101.131 53.9245 89.0582 45.0535 84.3596L45.1024 83.9312C54.0432 88.6649 65.6454 100.893 70.3742 115.038L69.9901 115.171H69.9831Z"
        fill="#1095A9"
      />
      <path
        d="M78.2255 111.393C70.1857 88.707 57.1866 79.5906 46.7649 74.1826L46.9535 73.8174C57.445 79.2605 70.528 88.4331 78.6167 111.252L78.2325 111.393H78.2255Z"
        fill="#1095A9"
      />
      <path
        d="M81.1801 95.5828C72.7003 78.7898 59.2122 69.3504 49.7474 67.5454L49.8243 67.145C59.3868 68.9711 72.7003 78.1858 81.243 95.1052L81.1871 95.5898L81.1801 95.5828Z"
        fill="#1095A9"
      />
      <path
        d="M71.9529 129.527H93.0407C93.0407 129.527 100.801 144.55 99.3831 167.509L102.394 168.331C102.394 168.331 109.714 146.404 111.174 128.164C111.677 121.906 106.662 116.456 99.942 115.789C81.9904 114.019 58.2133 115.965 53.4006 117.306C53.4006 117.306 53.4006 117.327 53.4076 117.362C55.0212 126.177 62.3275 129.527 71.9529 129.527Z"
        fill="#3446C7"
      />
      <path d="M99.3762 167.509L98.978 169.897L101.639 170.347L102.394 168.331L99.3762 167.509Z" fill="#EDC198" />
      <path
        d="M98.9781 169.897C98.1957 171.499 97.0991 174.245 96.4844 175.832C96.219 176.514 96.5892 177.265 97.3296 177.511C99.9071 178.347 105.998 180.159 113.221 181.128C114.275 181.268 114.708 179.913 113.744 179.491C107.004 176.549 102.31 172.089 101.639 170.361L98.9781 169.912V169.897Z"
        fill="#205FAE"
      />
      <path
        d="M62.6908 129.527H77.4292C77.4292 129.527 80.9008 140.266 71.436 162.2L74.0904 164.075C74.0904 164.075 87.0476 147.542 94.8918 130.236C97.581 124.295 92.1467 115.08 85.1267 115.08C67.1612 115.08 59.8269 114.792 46.262 114.792C46.262 114.792 46.4017 115.572 46.6601 116.815C48.3225 124.878 53.8617 129.527 62.6978 129.527H62.6908Z"
        fill="#4286FB"
      />
      <path d="M71.436 162.2L69.8085 164.841L72.2462 166.231L74.0903 164.075L71.436 162.2Z" fill="#EDC198" />
      <path
        d="M69.8085 164.841C68.4813 166.126 66.4417 168.422 65.2822 169.75C64.7793 170.326 64.884 171.19 65.5197 171.688C67.7409 173.423 73.0565 177.356 79.7761 180.875C80.761 181.388 81.6621 180.215 80.8658 179.456C75.3197 174.182 72.2882 168.156 72.2463 166.224L69.8085 164.833V164.841Z"
        fill="#205FAE"
      />
      <path d="M124.082 181.5H129.475V114.23H124.082L124.082 181.5Z" fill="#205FAE" />
      <path
        d="M133.33 111.729H120.234C120.234 113.106 121.344 114.223 122.713 114.223H130.858C132.227 114.223 133.337 113.106 133.337 111.729H133.33Z"
        fill="#205FAE"
      />
      <path d="M22.897 181.5H28.2894L28.2894 114.23H22.897L22.897 181.5Z" fill="#205FAE" />
      <path
        d="M32.1452 111.729H19.0482C19.0482 113.106 20.1588 114.223 21.5279 114.223H29.6725C31.0415 114.223 32.1522 113.106 32.1522 111.729H32.1452Z"
        fill="#205FAE"
      />
      <path d="M133.33 106.876H19.0413V111.73H133.33V106.876Z" fill="#205FAE" />
      <path d="M100.075 181.5H105.467L105.467 114.23H100.075L100.075 181.5Z" fill="#205FAE" />
      <path
        d="M109.323 111.729H96.226C96.226 113.106 97.3366 114.223 98.7056 114.223H106.85C108.219 114.223 109.33 113.106 109.33 111.729H109.323Z"
        fill="#205FAE"
      />
      <path d="M121.337 80.3843L108.869 106.743H128.301L140.769 80.3843H121.337Z" fill="white" />
      <path
        d="M121.26 80.2437L121.197 80.3911L121.135 80.5176L108.792 106.602L108.75 106.687L108.708 106.75L108.645 106.876H128.385L140.895 80.4333L141 80.2437H121.26ZM109.085 106.602L121.428 80.5176H140.56L128.217 106.602H109.085Z"
        fill="#205FAE"
      />
      <path
        d="M128.266 94.0375C127.351 96.9171 125.158 99.2489 123.356 99.2489C121.554 99.2489 120.841 96.9171 121.756 94.0375C122.671 91.1579 124.865 88.8262 126.667 88.8262C128.469 88.8262 129.181 91.1579 128.266 94.0375Z"
        fill="white"
      />
      <path
        d="M128.217 89.4725C127.84 88.9668 127.295 88.6929 126.667 88.6929C124.823 88.6929 122.559 91.0738 121.637 94.0026C121.03 95.8989 121.093 97.6056 121.826 98.5959C122.203 99.1226 122.727 99.3965 123.356 99.3965C125.221 99.3965 127.484 97.0156 128.406 94.0868C128.993 92.1905 128.93 90.4628 128.217 89.4725ZM123.356 99.1226C122.811 99.1226 122.371 98.8698 122.036 98.4273C121.386 97.5423 121.323 95.8567 121.889 94.0868C122.79 91.2634 124.927 88.9668 126.667 88.9668C127.211 88.9668 127.672 89.1986 127.987 89.641C128.657 90.547 128.699 92.2116 128.133 94.0026C127.253 96.826 125.095 99.1226 123.356 99.1226Z"
        fill="#205FAE"
      />
      <path
        d="M120.024 80.3843L108.156 105.472H84.6238C84.6238 106.174 85.1896 106.743 85.8881 106.743H108.869L121.337 80.3843H120.017H120.024Z"
        fill="white"
      />
      <path
        d="M119.94 80.2437L108.079 105.338H84.484V105.465C84.484 106.244 85.1127 106.876 85.888 106.876H108.96L109.022 106.75L109.085 106.602L121.428 80.5176L121.47 80.4333L121.491 80.3911L121.554 80.2437H119.94ZM85.888 106.602C85.3222 106.602 84.8402 106.16 84.7774 105.612H108.247L120.108 80.5176H121.135L108.792 106.602H85.888Z"
        fill="#205FAE"
      />
      <path
        d="M72.854 63.9562C94.5425 54.1726 105.09 42.0994 109.602 34.0225L112.34 35.2024C112.34 35.2024 107.919 57.7335 81.0195 76.0435C80.1184 76.6615 78.9379 76.6896 77.988 76.1488C76.0391 75.0251 72.861 72.6371 71.1147 68.3037C70.4371 66.6181 71.1985 64.7007 72.847 63.9562H72.854Z"
        fill="#1095A9"
      />
      <path
        d="M91.1268 13.7042L87.9835 8.85807C87.9626 8.79486 87.8997 8.75272 87.8368 8.75272C87.753 8.73165 87.6901 8.79486 87.6482 8.837L77.6107 22.4905C77.5479 22.5748 77.5479 22.6801 77.6317 22.7433C77.6736 22.7855 77.7155 22.8065 77.7784 22.8065C77.8203 22.8065 77.8622 22.8065 77.8831 22.7855L91.0639 13.9992C91.1687 13.936 91.1896 13.8096 91.1268 13.7042ZM78.6375 21.7952L87.8159 9.32162L90.6658 13.7674L78.6375 21.7952Z"
        fill="#1095A9"
      />
      <path
        d="M74.2161 2.36802L68.2439 0.51384C68.181 0.49277 68.1181 0.49277 68.0553 0.53491C68.0133 0.577051 67.9714 0.640261 67.9714 0.703472V17.2857C67.9714 17.3911 68.0553 17.4753 68.1391 17.4964H68.181C68.2648 17.4964 68.3486 17.4332 68.3696 17.37L74.3418 2.64193C74.3837 2.57872 74.3627 2.53658 74.3418 2.47337C74.3208 2.43123 74.2789 2.38909 74.2161 2.36802ZM68.3905 16.2322V0.977384L73.8808 2.68407L68.3905 16.2322Z"
        fill="#1095A9"
      />
      <path
        d="M55.1468 1.06205C55.1259 0.998844 55.1049 0.956704 55.063 0.914563C55.0002 0.893493 54.9373 0.872423 54.8954 0.893493L48.3574 2.60018C48.2945 2.62125 48.2526 2.66339 48.2317 2.7266C48.2107 2.76874 48.2107 2.85302 48.2317 2.89516L56.8442 17.9604C56.8861 18.0236 56.949 18.0657 57.0328 18.0657C57.0538 18.0657 57.0747 18.0657 57.0957 18.0446C57.1795 18.0236 57.2424 17.9393 57.2214 17.8339L55.1468 1.06205ZM48.7346 2.9373L54.7697 1.35704L56.6975 16.8647L48.7346 2.9373Z"
        fill="#1095A9"
      />
      <path
        d="M47.1839 21.3524L38.5295 6.87721C38.4876 6.83507 38.4456 6.79293 38.3828 6.79293C38.3199 6.77186 38.257 6.79293 38.2151 6.83507L32.9344 11.0491C32.8925 11.0913 32.8716 11.1545 32.8716 11.2177C32.8716 11.2809 32.8925 11.3441 32.9554 11.3652L46.8906 21.6264C46.9325 21.6474 46.9744 21.6685 47.0163 21.6685C47.0582 21.6685 47.1211 21.6474 47.142 21.6264C47.2258 21.5421 47.2468 21.4367 47.1839 21.3524ZM33.3955 11.1966L38.299 7.29862L46.3038 20.6993L33.3955 11.1966Z"
        fill="#1095A9"
      />
      <path
        d="M40.7716 28.0741L26.9832 18.4028C26.9413 18.3818 26.8784 18.3607 26.8155 18.3818C26.7736 18.4028 26.7108 18.4239 26.6898 18.4871L23.5884 24.4921C23.5465 24.5553 23.5465 24.6186 23.5884 24.6818C23.6094 24.745 23.6513 24.7871 23.7142 24.7871L40.604 28.4533H40.6459C40.7297 28.4533 40.8135 28.4112 40.8345 28.3269C40.8764 28.2426 40.8555 28.1373 40.7716 28.0741ZM24.0704 24.45L26.9413 18.8875L39.7029 27.8423L24.0704 24.45Z"
        fill="#1095A9"
      />
      <path
        d="M70.4231 64.2793C70.4231 66.9341 70.0739 70.4458 68.3765 72.5248C66.4137 70.1017 60.3228 64.8552 50.7952 65.9298C51.0816 65.5576 51.3889 65.2134 51.7172 64.8833C56.2994 60.1355 64.1086 60.3181 70.4231 64.2863V64.2793Z"
        fill="#1095A9"
      />
      <path
        d="M115.742 20.5097C115.777 19.2947 112.836 18.9365 112.417 19.3298C112.417 19.3298 110.922 21.9846 110.804 26.2127C110.434 24.3866 109.539 23.2348 109.295 23.0381C108.631 22.4973 107.996 22.8134 107.996 22.8134C108.059 24.9766 110.783 30.8763 109.609 34.0227L112.347 35.2027C115.547 29.3662 115.693 22.3569 115.742 20.5097Z"
        fill="#EDC198"
      />
      <path
        d="M7.1946 27.6104C7.29239 28.3128 7.29239 28.9519 7.25746 29.57C6.1678 25.9108 4.59616 22.5396 4.205 22.5396C3.56936 22.5466 0.0489041 23.4807 8.78022e-06 24.7589C-0.0488865 26.0372 3.52047 35.1395 5.87443 37.1763L8.35412 35.9964C9.49268 32.7305 8.36809 28.5516 9.05262 25.9459C9.73715 23.3402 6.75454 24.5693 7.1946 27.6175V27.6104Z"
        fill="#EDC198"
      />
      <path
        d="M66.3997 34.2266C66.3229 36.6988 68.5581 42.6125 68.5581 42.6125L66.3788 42.9005C66.3788 42.9005 69.3404 49.6289 63.9969 49.8606C63.1447 53.2459 68.9144 63.4088 68.9144 63.4088C68.9144 63.4088 68.9143 66.3375 68.1181 69.4349C62.8932 65.5018 56.9909 62.9031 53.1631 63.6125C54.6299 58.6891 55.1748 52.9018 55.1748 52.9018L53.1631 36.5021L66.3997 34.2336V34.2266Z"
        fill="#EDC198"
      />
      <path
        d="M66.3719 42.9C62.2716 43.6656 60.8397 41.6358 60.4206 39.7114C60.0015 37.787 60.2809 36.1505 60.2809 36.1505C60.2809 36.1505 63.4521 34.4298 67.9086 34.8301C72.358 35.2305 72.2393 29.7101 70.8772 26.8164C69.5151 23.9228 64.8421 25.173 64.8421 25.173C64.8421 25.173 65.4498 21.1345 60.7768 22.0827C56.1039 23.0238 58.7442 26.2546 58.7442 26.2546C58.7442 26.2546 51.4448 24.0492 52.4437 29.7522C48.0291 26.9499 44.1315 32.9057 47.4913 37.3164C50.8511 41.7271 51.5426 41.8044 50.2085 44.5786C48.8743 47.3458 53.7709 51.9602 55.1679 52.8943C55.7686 49.7197 55.7057 47.3318 57.2005 44.986C57.6755 44.2415 57.5567 43.2723 56.9001 42.6823C55.4612 41.39 55.6708 38.9177 57.6545 38.2856C58.4997 41.994 61.5452 51.7003 69.9342 53.8425C68.2508 50.6187 68.2299 45.3301 67.0704 42.8087L66.3649 42.9H66.3719Z"
        fill="#DD6B20"
      />
      <path d="M67.203 45.0776L64.8351 45.3024L65.338 46.377L67.4405 46.1311L67.203 45.0776Z" fill="#EDC198" />
      <path
        d="M63.7105 38.3844C63.9131 39.2904 64.2484 39.9857 64.4579 39.9436C64.6675 39.8944 64.6745 39.1218 64.4719 38.2158C64.2693 37.3098 63.934 36.6145 63.7245 36.6566C63.5149 36.7058 63.508 37.4783 63.7105 38.3844Z"
        fill="#205FAE"
      />
      <path
        d="M69.9202 32.4914C69.9202 32.4914 69.9272 32.4914 69.9342 32.4844C71.3032 31.473 71.1147 29.2466 70.1367 27.6874C69.2636 26.2897 68.0203 25.7911 66.8957 26.3951C66.8188 26.4442 66.7909 26.5355 66.8328 26.6198C66.8747 26.6971 66.9725 26.7322 67.0494 26.6901C68.1949 26.072 69.2566 26.8937 69.8643 27.863C70.7444 29.2676 70.947 31.3325 69.7456 32.2175C69.6757 32.2737 69.6548 32.372 69.7107 32.4492C69.7596 32.5195 69.8504 32.5335 69.9202 32.4914Z"
        fill="white"
      />
      <path
        d="M54.3855 50.8299C54.3855 50.8299 54.4204 50.8088 54.4344 50.7878C54.4903 50.7175 54.4763 50.6122 54.4064 50.556C52.6672 49.1443 51.2003 46.6931 51.8429 45.5202C51.8848 45.4429 51.8569 45.3446 51.7801 45.2955C51.7032 45.2533 51.6054 45.2814 51.5565 45.3587C50.7323 46.8546 52.5624 49.4814 54.1969 50.8088C54.2528 50.851 54.3226 50.858 54.3785 50.8229L54.3855 50.8299Z"
        fill="white"
      />
      <path
        d="M70.025 23.923C70.025 23.923 70.067 23.8879 70.0809 23.8668C70.1298 23.7895 70.1019 23.6912 70.032 23.642C69.103 23.0591 66.8259 23.5367 65.4149 24.3584C65.3381 24.4076 65.3101 24.5059 65.359 24.5832C65.401 24.6604 65.5057 24.6885 65.5826 24.6464C67.0005 23.8176 69.124 23.4594 69.8574 23.923C69.9133 23.9581 69.9762 23.9581 70.025 23.923Z"
        fill="#205FAE"
      />
      <path
        d="M50.1456 41.7556C50.2154 41.7134 50.2504 41.6221 50.2085 41.5449C49.7684 40.6108 48.9372 39.6767 48.0571 38.6864C46.702 37.1623 45.298 35.5961 45.2491 33.8964C45.2491 33.8051 45.1722 33.7349 45.0814 33.7349C44.9906 33.7349 44.9208 33.8121 44.9208 33.9034C44.9767 35.7225 46.4156 37.3379 47.8126 38.9041C48.6787 39.8733 49.496 40.7864 49.9151 41.6853C49.95 41.7696 50.0478 41.8047 50.1316 41.7626C50.1316 41.7626 50.1386 41.7626 50.1456 41.7626V41.7556Z"
        fill="#205FAE"
      />
      <path
        d="M51.7312 63.2962C43.1605 57.4598 35.0928 62.7625 8.35408 35.9893L5.87439 37.1692C5.87439 37.1692 10.5823 59.4263 44.2292 77.9189C45.1094 78.4036 46.1851 78.5089 47.121 78.1437C49.5518 77.2026 53.9524 74.6671 54.3156 68.5146C54.4344 66.4497 53.4355 64.4621 51.7312 63.2962Z"
        fill="#1095A9"
      />
      <path
        d="M127.121 63.7386C131.58 63.7386 135.195 60.1036 135.195 55.6195C135.195 51.1355 131.58 47.5005 127.121 47.5005C122.661 47.5005 119.046 51.1355 119.046 55.6195C119.046 60.1036 122.661 63.7386 127.121 63.7386Z"
        fill="white"
      />
      <path
        d="M127.128 47.3252C122.566 47.3252 118.871 51.0476 118.871 55.6128C118.871 60.178 122.573 63.9145 127.128 63.9145C131.682 63.9145 135.384 60.1921 135.384 55.6128C135.384 51.0336 131.668 47.3252 127.128 47.3252ZM127.128 63.5563C122.776 63.5563 119.242 59.9884 119.242 55.6058C119.242 51.2232 122.776 47.6764 127.128 47.6764C131.479 47.6764 135.014 51.2302 135.014 55.6058C135.014 59.9814 131.479 63.5563 127.128 63.5563Z"
        fill="#1095A9"
      />
      <path
        d="M126.639 59.1101C126.499 59.1101 126.366 59.0329 126.303 58.9064L123.894 54.4185C123.796 54.2359 123.866 53.9971 124.047 53.8987C124.229 53.8004 124.466 53.8707 124.564 54.0533L126.639 57.9161L129.852 51.9392C129.95 51.7496 130.187 51.6864 130.369 51.7847C130.557 51.883 130.62 52.1148 130.522 52.3044L126.974 58.9064C126.904 59.0329 126.778 59.1101 126.639 59.1101Z"
        fill="#1095A9"
      />
    </g>
    <defs>
      <clipPath id="clip0_1797_12835">
        <rect width={141} height={181} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SuccessfulGuy;
