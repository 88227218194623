import { setNextTime2ShowInventoryWarmingPopup } from '@base/redux/features/commonSlice';
import { useAppDispatch, useAppSelector } from '@base/redux/store';
import InventoryWarningModal from '@components/Inventory/InventoryWarningModal';
import useInventory from '@components/Inventory/useInventory';
import { INVENTORY_WARNING_INTERVAL_TIME } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import useCheckRole from '@hooks/useCheckRole';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PortionsContext = createContext<any | null>(null);

export const PortionsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isWarning } = useInventory();
  const location = useLocation();
  const { isManager, isSupervisor } = useCheckRole();

  const nextTime2Show = useAppSelector((state) => state.commonSlice.nextTime2ShowInventoryWarmingPopup);
  const [isOpened, setIsOpened] = useState(false);

  const isInventoryPage = location.pathname === ROUTES.inventory;

  useEffect(() => {
    if (!isManager && !isSupervisor) {
      return;
    }

    const isShouldShow = isWarning && !isInventoryPage && nextTime2Show < Date.now();

    if (isShouldShow) {
      setIsOpened(true);
      dispatch(setNextTime2ShowInventoryWarmingPopup(Date.now() + Number(INVENTORY_WARNING_INTERVAL_TIME)));
    }
  }, [nextTime2Show, isWarning, location.pathname]);

  return (
    <PortionsContext.Provider value={{}}>
      {children}

      <InventoryWarningModal isOpened={isOpened} setIsOpened={setIsOpened} />
    </PortionsContext.Provider>
  );
};
