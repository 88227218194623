import RestaurantTheme from '@base/hoc/RestaurantTheme';
import { useAppSelector } from '@base/redux/store';
import MobileMenu from '@components/MobileMenu';
import { ROUTES } from '@constants/routes';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const RestaurantMenuPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.home'));

  const navigation = useNavigate();
  const selectedTable = useAppSelector((state) => state.clientSlice.table);

  useEffect(() => {
    if (!selectedTable) {
      navigation(ROUTES.root);
    }
  }, [selectedTable]);

  return (
    <RestaurantTheme>
      <MobileMenu />
    </RestaurantTheme>
  );
};

export default RestaurantMenuPage;
