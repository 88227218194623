import RestaurantTheme from '@base/hoc/RestaurantTheme';
import ClientBindingTable from '@components/MobileMenu/ClientBindingTable';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const ClientBindingTablePage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.binding_table'));

  return (
    <RestaurantTheme>
      <ClientBindingTable />
    </RestaurantTheme>
  );
};

export default ClientBindingTablePage;
