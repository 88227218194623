import { useLazyGetClientAccountInfoQuery, useUpdateClientAccountPreferencesMutation } from '@api/accauntSettingsApi';
import { Client } from '@api/models/Client';
import { useGetAllergiesQuery, useGetDishTypesQuery } from '@api/publicApi';
import { useAppSelector } from '@base/redux/store';
import { COOKIE_CLIENT_PREFERENCES_KEY } from '@base/utils/constants/constants';
import { domain } from '@constants/environments';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useClientAccountPreferences = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm();
  const clientID = useAppSelector((state) => state.userState.user?.id);
  const { data: dishTypeList = [], isLoading: isLoadingTypeList, isError: isErrorTypeList } = useGetDishTypesQuery();
  const {
    data: allergyList = [],
    isLoading: isLoadingAllergyList,
    isError: isErrorAllergyList,
  } = useGetAllergiesQuery();

  const [getClientPreferences, { data: clientPreferences, isLoading: isLoadingClientPreferences }] =
    useLazyGetClientAccountInfoQuery();

  const [
    updatePreferences,
    { data: dataUpdatePreferences, isLoading: isUpdating, isSuccess: isSuccessUpdating, isError: isErrorUpdating },
  ] = useUpdateClientAccountPreferencesMutation();

  const initFormValues = (clientPreferences: Client) => {
    const allergies = clientPreferences.allergies.map((item) => item.id);

    const dish_types = clientPreferences.dish_types.map((item) => item.id);

    form.setFieldsValue({
      allergies,
      dish_types,
    });
  };

  const fetchClientPreferences = async (id: number) => {
    await getClientPreferences(id);
  };

  const onFinish = async (values: any) => {
    if (!clientID) {
      return;
    }

    await updatePreferences({
      id: clientID,
      data: values,
    });
  };

  useEffect(() => {
    if (clientID) {
      fetchClientPreferences(clientID);
    }
  }, [clientID]);

  useEffect(() => {
    if (clientPreferences) {
      initFormValues(clientPreferences);
    }
  }, [clientPreferences]);

  useEffect(() => {
    if (isUpdating) {
      return;
    }

    if (isSuccessUpdating) {
      message.success(t('updated'));

      if (dataUpdatePreferences) {
        // create cookie with new client preferences
        const clientNewAllergies = dataUpdatePreferences.allergies;
        const clientNewDishTypes = dataUpdatePreferences.dish_types;

        Cookies.set(
          COOKIE_CLIENT_PREFERENCES_KEY,
          JSON.stringify({ allergies: clientNewAllergies, dish_types: clientNewDishTypes }),
          {
            expires: 1,
            path: '/',
            domain,
          }
        );
      }
    }

    if (isErrorUpdating) {
      message.error(t('error'));
    }
  }, [isUpdating, isErrorUpdating, isSuccessUpdating, dataUpdatePreferences]);

  return {
    t,
    form,
    dishTypeList,
    isLoadingTypeList: isLoadingTypeList || isLoadingClientPreferences,
    isErrorTypeList,
    allergyList,
    isLoadingAllergyList,
    isErrorAllergyList,
    onFinish,
    isUpdating,
  };
};

export default useClientAccountPreferences;
