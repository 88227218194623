import { Inventory } from '@api/models/Inventory';
import InventoryDishList from '@components/Inventory/InventoryDishList';
import { FC } from 'react';

const InventorySubmenuList: FC<{ submenuList: Inventory[] }> = ({ submenuList }) => {
  return (
    <div className={'rounded-[4px] bg-bg-input px-2 py-4'}>
      {submenuList.map((submenu) => (
        <div key={`inventory-submenu-${submenu.id}`}>
          <div className={'mb-4 text-base font-bold text-indigo-40'}>{submenu.name}</div>
          <InventoryDishList dishList={submenu.dishes} />
        </div>
      ))}
    </div>
  );
};

export default InventorySubmenuList;
