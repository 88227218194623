import { SVGProps } from 'react';
const PasswordCreatedSuccessful = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={91} height={39} fill="none" {...props}>
    <path
      fill="#E6F1FF"
      d="M57.946 6.75H4.851C2.39 6.75.398 7.973.398 9.483v26.534c0 1.51 1.994 2.733 4.453 2.733h53.095c2.459 0 4.452-1.224 4.452-2.733V9.483c0-1.51-1.993-2.733-4.452-2.733Z"
    />
    <path
      fill="#39C9DF"
      d="M67.852 34.125c6.282 0 11.375-5.093 11.375-11.375s-5.093-11.375-11.375-11.375-11.375 5.093-11.375 11.375 5.093 11.375 11.375 11.375Z"
    />
    <path
      stroke="#05505B"
      strokeLinecap="round"
      strokeWidth={2}
      d="m61.352 21.125 5.223 3.917a1.625 1.625 0 0 0 2.257-.302l10.395-13.365"
    />
    <path
      fill="url(#a)"
      d="M18.815 26.748a2.596 2.596 0 0 1-3.634 0l-4.641-4.59a2.522 2.522 0 0 1 0-3.591 2.596 2.596 0 0 1 3.635 0l4.64 4.585a2.527 2.527 0 0 1 0 3.596Z"
      opacity={0.3}
    />
    <path
      fill="url(#b)"
      d="M10.54 26.747a2.523 2.523 0 0 1 0-3.593l4.64-4.587a2.595 2.595 0 0 1 3.635 0 2.523 2.523 0 0 1 0 3.593l-4.64 4.587a2.595 2.595 0 0 1-3.635 0Z"
      opacity={0.3}
    />
    <path
      fill="url(#c)"
      d="M35.594 26.748a2.596 2.596 0 0 1-3.635 0l-4.64-4.59a2.522 2.522 0 0 1 0-3.591 2.596 2.596 0 0 1 3.634 0l4.64 4.585a2.527 2.527 0 0 1 0 3.596Z"
      opacity={0.3}
    />
    <path
      fill="url(#d)"
      d="M27.318 26.747a2.523 2.523 0 0 1 0-3.593l4.641-4.587a2.595 2.595 0 0 1 3.635 0 2.523 2.523 0 0 1 0 3.593l-4.641 4.587a2.595 2.595 0 0 1-3.635 0Z"
      opacity={0.3}
    />
    <path
      fill="url(#e)"
      d="M52.371 26.748a2.596 2.596 0 0 1-3.634 0l-4.64-4.59a2.522 2.522 0 0 1 0-3.591 2.596 2.596 0 0 1 3.634 0l4.64 4.585a2.527 2.527 0 0 1 0 3.596Z"
      opacity={0.3}
    />
    <path
      fill="url(#f)"
      d="M44.096 26.747a2.523 2.523 0 0 1 0-3.593l4.64-4.587a2.595 2.595 0 0 1 3.636 0 2.523 2.523 0 0 1 0 3.593l-4.641 4.587a2.595 2.595 0 0 1-3.635 0Z"
      opacity={0.3}
    />
    <defs>
      <linearGradient id="a" x1={15.526} x2={13.871} y1={18.391} y2={26.931} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient id="b" x1={18.994} x2={10.366} y1={23.492} y2={21.78} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient id="c" x1={32.305} x2={30.649} y1={18.391} y2={26.931} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient id="d" x1={35.772} x2={27.144} y1={23.492} y2={21.78} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient id="e" x1={49.083} x2={47.427} y1={18.391} y2={26.931} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient id="f" x1={52.55} x2={43.922} y1={23.492} y2={21.78} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
    </defs>
  </svg>
);
export default PasswordCreatedSuccessful;
