import apiCentral from '@api/central-api';
import { User } from '@api/models/User';

export const extendedApiSlice = apiCentral.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => '/user-info',
      transformResponse: (response: { data: User }) => response.data,
    }),
  }),
});

export const { useLazyGetUserQuery } = extendedApiSlice;
