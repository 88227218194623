import ResetPasswordForm from '@base/components/AuthForm/ResetPasswordForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';

const WorkerResetPasswordPage = () => {
  const { t } = useTranslation();

  useSetTitlePage(t('pages.new_password'));

  return (
    <AuthForm>
      <ResetPasswordForm type="worker" />
    </AuthForm>
  );
};

export default WorkerResetPasswordPage;
