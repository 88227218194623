import GuestOrders from '@base/components/GuestOrders';
import { PusherProvider } from '@base/contexts/PusherProvider';
import ClientOrders from '@components/Client/ClientOrders';
import useAuth from '@hooks/useAuth';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const ClientOrdersPage = () => {
  const { t } = useTranslation();
  const { isAuth } = useAuth();
  useSetTitlePage(t('pages.orders'));

  return (
    <>
      {isAuth ? (
        <PusherProvider>
          <ClientOrders />
        </PusherProvider>
      ) : (
        <GuestOrders />
      )}
    </>
  );
};

export default ClientOrdersPage;
