import { useGetStatisticsQuery } from '@api/DashboardApi';

const useDashboardStatistics = (filter: string) => {
  const { data, isLoading, isError } = useGetStatisticsQuery(filter, { refetchOnMountOrArgChange: true });

  const statisticsData = data;

  return {
    totalOrders: statisticsData?.total_orders,
    ordersWaiting: statisticsData?.orders_waiting,
    ordersInProgress: statisticsData?.orders_in_progress,
    totalSum: statisticsData?.total_sum,
    isLoading,
    isError,
  };
};

export default useDashboardStatistics;
