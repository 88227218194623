import WhiteBackgroundSelect from '@base/components/UI/WhiteBackgroundSelect';
import { Waiter } from '@base/redux/api/models/Waiter';
import { useGetTakeAwayTableQuery, useUpdateTakeAwayTableMutation } from '@base/redux/api/tableApi';
import { App, Col, Row, Select } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface WaiterTakeAwayTableProps {
  waiters?: Waiter[] | null;
  isWaiters2Tables: boolean;
}

const WaiterTakeAwayTable: FC<WaiterTakeAwayTableProps> = ({ waiters, isWaiters2Tables }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const [
    updateTakeAwayTable,
    { isLoading: isLoadingUpdateTakeAway, isSuccess: isSuccessUpdateTakeAway, isError: isErrorUpdateTakeAway },
  ] = useUpdateTakeAwayTableMutation();

  const {
    data,
    isFetching,
    isError: isErrorGetTakeAwayTable,
  } = useGetTakeAwayTableQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccessUpdateTakeAway) {
      message.success(t('saved'));
    }

    if (isErrorUpdateTakeAway) {
      message.error(t('error'));
    }
  }, [isLoadingUpdateTakeAway]);

  useEffect(() => {
    if (!isFetching && isErrorGetTakeAwayTable) {
      message.error(t('error'));
    }
  }, [isFetching]);

  const handleOnChange = (value: number) => {
    if (data?.id) {
      updateTakeAwayTable({ data: { waiter_id: value }, id: data.id });
    }
  };

  return (
    <>
      {data?.is_active &&
        !isErrorGetTakeAwayTable &&
        (isFetching ? (
          <div
            className={'mb-4 flex w-full max-w-[526px] items-center rounded-[10px] bg-white px-6 py-2 shadow-custom'}
          >
            <p>{t('loading')}</p>
          </div>
        ) : (
          <div
            className={'mb-4 flex w-full max-w-[526px] items-center rounded-[10px] bg-white px-6 py-2 shadow-custom'}
          >
            <Row wrap={false} gutter={[16, 0]} align="middle" className={'w-full py-2'} justify="space-between">
              <Col flex="1 1 247px" className={'max-w-[247px]'}>
                <span className={'block truncate text-sm leading-[150%] text-indigo-40'}>{t('take_away_orders')}</span>
              </Col>
              <Col flex="1 1 215px" className={'max-w-[215px]'}>
                {isWaiters2Tables ? (
                  <WhiteBackgroundSelect
                    className={'w-full text-sm leading-[150%] text-grey-30'}
                    onChange={handleOnChange}
                    defaultValue={+data?.waiter?.id || null}
                  >
                    <Select.Option key={0} value={null}>
                      {t('no_one')}
                    </Select.Option>
                    {waiters &&
                      waiters.map((waiter) => (
                        <Select.Option key={`waiter-id-${waiter.id}`} value={+waiter.id}>
                          {waiter.first_name} {waiter.last_name}
                        </Select.Option>
                      ))}
                  </WhiteBackgroundSelect>
                ) : (
                  <span className="block w-full truncate rounded-[4px] px-2 py-1 text-sm font-normal leading-[150%] text-grey-30 outline outline-1 outline-grey-20">
                    {t('no_one')}
                  </span>
                )}
              </Col>
            </Row>
          </div>
        ))}
    </>
  );
};

export default WaiterTakeAwayTable;
