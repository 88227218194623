import { TreeSelect } from 'antd';
import { useState } from 'react';

const DishThreeSelect = () => {
  const [value, setValue] = useState<string>();

  const data = [
    {
      id: 1,
      name: 'Category 1',
      menu_tree: {
        id: 1,
        menus_id: 1,
        ancestor: 1,
        descendant: 1,
        level: 1,
        position: 1,
        created_at: '2024-02-19T13:07:09.000000Z',
        updated_at: '2024-02-19T13:07:09.000000Z',
        deleted_at: null,
      },
      dishes: [
        {
          id: 1,
          dish_name: 'Doris Bryan',
          price: 622,
          inventory_quantity: 516,
          is_inventory_unlimited: true,
          description: 'Est dolorem voluptat',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
      ],
    },
    {
      id: 2,
      name: 'Category 2',
      menu_tree: {
        id: 2,
        menus_id: 2,
        ancestor: 2,
        descendant: 2,
        level: 1,
        position: 2,
        created_at: '2024-02-21T09:30:20.000000Z',
        updated_at: '2024-02-21T09:30:20.000000Z',
        deleted_at: null,
      },
      dishes: [
        {
          id: 2,
          dish_name: 'Julie Browning',
          price: 801,
          inventory_quantity: 670,
          is_inventory_unlimited: false,
          description: 'In commodi at ut omn',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
      ],
    },
    {
      id: 3,
      name: 'Category 3',
      menu_tree: {
        id: 3,
        menus_id: 3,
        ancestor: 3,
        descendant: 3,
        level: 1,
        position: 3,
        created_at: '2024-02-21T09:30:24.000000Z',
        updated_at: '2024-02-21T09:30:24.000000Z',
        deleted_at: null,
      },
      dishes: [
        {
          id: 5,
          dish_name: 'Cecilia Tillman',
          price: 342,
          inventory_quantity: 373,
          is_inventory_unlimited: true,
          description: 'Quo aliquip quia vel',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
        {
          id: 6,
          dish_name: 'Solomon Watkins',
          price: 134,
          inventory_quantity: 837,
          is_inventory_unlimited: true,
          description: 'Nulla repudiandae ul',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
      ],
    },
    {
      id: 5,
      name: 'Category 4',
      menu_tree: {
        id: 5,
        menus_id: 5,
        ancestor: 5,
        descendant: 5,
        level: 1,
        position: 4,
        created_at: '2024-02-21T09:34:20.000000Z',
        updated_at: '2024-02-21T09:34:20.000000Z',
        deleted_at: null,
      },
      dishes: [],
    },
    {
      id: 4,
      name: 'Sub 1',
      menu_tree: {
        id: 4,
        menus_id: 4,
        ancestor: 2,
        descendant: 4,
        level: 2,
        position: 1,
        created_at: '2024-02-21T09:30:35.000000Z',
        updated_at: '2024-02-21T09:30:35.000000Z',
        deleted_at: null,
      },
      dishes: [
        {
          id: 3,
          dish_name: 'Ulla Dickson',
          price: 514,
          inventory_quantity: 342,
          is_inventory_unlimited: true,
          description: 'Nulla culpa aut inc',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
        {
          id: 4,
          dish_name: 'Nichole Harrell',
          price: 129,
          inventory_quantity: 425,
          is_inventory_unlimited: true,
          description: 'Sint dicta voluptat',
          image: null,
          is_portion_available: true,
          is_take_away: false,
        },
      ],
    },
  ];

  const dishThree = data.map((item) => {
    const categoryTitle = (
      <div className={'overflow-x-hidden text-center'}>
        <div className={'dish-three-title inline-block px-2 text-center font-bold text-indigo-40'}>{item.name}</div>
      </div>
    );
    const subcategoryTitle = (
      <div className={'bg-indigo-15 w-full px-2 text-sm font-bold text-indigo-40'}>{item.name}</div>
    );
    const isCategory = item.menu_tree.level === 1;

    return {
      id: item.id,
      value: item.menu_tree.level === 1 ? item.id : item.id,
      title: isCategory ? categoryTitle : subcategoryTitle,
      children: item.dishes.map((dish) => ({
        value: `dish-${dish.id}`,
        title: dish.dish_name,
      })),
      disabled: true,
      selectable: false,
      pId: item.menu_tree.level === 1 ? 0 : item.menu_tree.ancestor,
    };
  });

  const ids = dishThree.map((item) => item.id);

  const onChangeThreeSelect = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={'min-w-[300px]'}>
      <TreeSelect
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        treeDataSimpleMode={{ id: 'id', pId: 'pId', rootPId: 0 }}
        showSearch
        placeholder="Please select"
        allowClear
        treeDefaultExpandedKeys={ids}
        onChange={onChangeThreeSelect}
        treeData={dishThree}
        treeNodeFilterProp="title"
        switcherIcon={false}
        style={{ width: '100%' }}
        dropdownStyle={{ padding: '16px' }}
        className={'max-h-[400px] w-full overflow-auto'}
        popupClassName={'popup-dish-tree-select'}
        value={value}
      />
    </div>
  );
};

export default DishThreeSelect;
