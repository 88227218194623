import AndroidPayIcon from '@components/icons/AndroidPayIcon';
import IOSPayIcon from '@components/icons/IOSPayIcon';
import { useTranslation } from 'react-i18next';

const ServicesPayment = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className={'mb-4 text-center text-base leading-[150%]'}>{t('additional_payment_options')}</p>
      <div className={'flex items-center justify-center gap-6'}>
        <div className={'flex items-center rounded-[50px] border-[2px] border-solid border-grey-30 px-4 py-[6px]'}>
          <AndroidPayIcon height={20} width={50} />
        </div>
        <div className={'flex items-center rounded-[50px] border-[2px] border-solid border-grey-30 px-4 py-[6px]'}>
          <IOSPayIcon height={20} width={50} />
        </div>
      </div>
    </>
  );
};

export default ServicesPayment;
