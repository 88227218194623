import { useGetDishByIdQuery } from '@api/dishApi';
import UserAccess from '@base/hoc/UserAccess';
import DishInfoCard from '@components/DishInfoCard';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import MobileDishExtra from '@components/MobileMenu/MobileDishSettings/MobileDishExtra';
import MobileDishOptionValue from '@components/MobileMenu/MobileDishSettings/MobileDishOptionValue';
import useMobileDishSettings from '@components/MobileMenu/MobileDishSettings/useMobileDishSettings';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import useCheckRole from '@hooks/useCheckRole';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MobileDishSettingsProps {
  id: number;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

const MobileDishSettings: FC<MobileDishSettingsProps> = ({ isOpened, setIsOpened, id }) => {
  const { t } = useTranslation();
  const {
    data: dish,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useGetDishByIdQuery(id, { refetchOnMountOrArgChange: true });
  const { update, isLoading: isLoadingUpdate } = useMobileDishSettings();
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(isOpened);
  const { isWaiter } = useCheckRole();

  const opts = dish?.options;
  const extras = dish?.extras;

  useEffect(() => {
    if (dish) {
      form.setFieldValue('id', dish.id);
      form.setFieldValue('updated_at', dish.updated_at);
      form.setFieldValue('is_portion_available', dish.is_portion_available);
      form.setFieldValue('is_take_away', dish.is_take_away);

      if (opts) {
        form.setFieldValue(OPTIONS_NAME_FIELD, opts);
      }

      if (extras) {
        form.setFieldValue(EXTRAS_NAME_FIELD, extras);
      }
    }
  }, [dish]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onFinish = async (value: any) => {
    const { options, extras, ...restValue } = value;
    await update(value.id, restValue);
  };

  const handleAfterClose = () => {
    if (!isOpen) {
      setIsOpened(false);
    }
  };

  return (
    <Drawer
      height={'90%'}
      headerStyle={{ border: 'none', paddingBottom: 0 }}
      placement={'bottom'}
      open={isOpen}
      onClose={handleClose}
      className={'dish-settings-modal'}
      afterOpenChange={handleAfterClose}
    >
      {(isLoading || isFetching) && <p>{t('loading')}</p>}
      {isError && <span>{t('error')}</span>}
      {isSuccess && dish && (
        <div className={'dish-settings-modal__content'}>
          <DishInfoCard
            title={dish.dish_name}
            description={dish.description}
            price={dish.price}
            imgUrl={dish.image}
            options={dish.options}
            extras={dish.extras}
          />

          <Form form={form} onFinish={onFinish} className={'dish-settings-modal__form dish-settings'}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="updated_at" hidden>
              <Input />
            </Form.Item>

            <div className={'mb-10'}>
              {isWaiter ? (
                <>
                  {dish.is_portion_available && <div>{t('serving_available')}</div>}
                  {!!dish.is_take_away && <div>{t('suitable_dish_4_take_away')}</div>}
                </>
              ) : (
                <>
                  <Form.Item initialValue name="is_portion_available" valuePropName="checked">
                    <HorizontalSwitch label={t('serving_available')} />
                  </Form.Item>
                  <Form.Item initialValue name="is_take_away" valuePropName="checked" className={'mb-0'}>
                    <HorizontalSwitch label={t('suitable_dish_4_take_away')} />
                  </Form.Item>
                </>
              )}
            </div>

            <div className={'dish-settings__options-extras'}>
              <Form.List name={OPTIONS_NAME_FIELD}>
                {(fields) => (
                  <>
                    {fields.map((field, i) => (
                      <div key={`option-${i}`}>
                        {opts && opts[i].values.length > 0 && (
                          <>
                            <div className={'dish-settings__title'}>
                              {opts[field.key].name}
                              <Form.Item name={[field.name, 'name']} hidden>
                                <Input />
                              </Form.Item>
                            </div>

                            <div className={'dish-settings-group dish-settings__group'}>
                              <Form.Item>
                                <Form.List name={[field.name, 'values']}>
                                  {(subFields) => (
                                    <div className={'dish-settings-group__items'}>
                                      {subFields.map((subField, indexValue) => {
                                        const id = opts[i].values[indexValue].id as number;
                                        const price = opts[i].values[indexValue].additional_charge as number;
                                        const toggleName = opts[i].values[indexValue].value as string;

                                        return (
                                          <MobileDishOptionValue
                                            key={`opt-value-${i}-${indexValue}`}
                                            id={id}
                                            fieldName={subField.name}
                                            toggleName={toggleName}
                                            price={price}
                                            className={'dish-settings-group__item'}
                                            titleClassName={'dish-settings-group__item-title'}
                                            priceClassName={'dish-settings-group__item-title'}
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                </Form.List>
                              </Form.Item>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </Form.List>

              {extras && extras.length > 0 && (
                <div>
                  <div className={'dish-settings__title'}>{t('extras')}</div>

                  <div className={'dish-settings-group dish-settings__group'}>
                    <div className={'dish-settings-group__items'}>
                      <Form.List name={EXTRAS_NAME_FIELD}>
                        {(fields) => (
                          <>
                            {fields.map(({ key, name }, i) => (
                              <MobileDishExtra
                                key={`extra-${name}-${i}`}
                                id={extras[key].id as number}
                                fieldName={name}
                                toggleName={extras[key].name}
                                price={extras[key].price}
                              />
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <UserAccess roles={['restaurant-manager', 'supervisor']}>
              <footer className={'pt-6'}>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  className={'dish-settings-footer__submit-button'}
                  loading={isLoadingUpdate}
                >
                  {t('save')}
                </Button>
              </footer>
            </UserAccess>
          </Form>
        </div>
      )}
    </Drawer>
  );
};

export default MobileDishSettings;
