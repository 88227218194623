import ClientPaymentSuccess from '@components/Client/ClientPaymentSuccess';
import { ROUTES } from '@constants/routes';
import { getClientOrderFromLocalstorage } from '@helpers/getClientOrderFromLocalstorage';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ClientPaymentSuccessPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.payment_success'));

  const navigate = useNavigate();
  useSetTitlePage(t('pages.payment'));

  const localstorageClientOrder = getClientOrderFromLocalstorage();

  useLayoutEffect(() => {
    if (!localstorageClientOrder?.clientOrder.id) {
      navigate(ROUTES.root);
    }
  }, [localstorageClientOrder]);

  return (
    <>
      {localstorageClientOrder && (
        <ClientPaymentSuccess
          orderID={localstorageClientOrder.clientOrder.id as number}
          orderCreatedAt={localstorageClientOrder.clientOrder.order_date as string}
          restName={localstorageClientOrder.clientOrder.restName}
          restLogo={localstorageClientOrder.clientOrder.restLogo}
          order={localstorageClientOrder.clientOrder.order}
          waiter={localstorageClientOrder.clientOrder.waiter}
          tableName={localstorageClientOrder.clientOrder.tableName}
          restaurantLink={localstorageClientOrder.clientOrder.restaurantLink}
        />
      )}
    </>
  );
};

export default ClientPaymentSuccessPage;
