import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundRestaurantPage: FC = () => {
  const { t } = useTranslation();
  useSetTitlePage('404');

  return <div className={'w-full pt-6 text-center text-2xl'}>{t('not_found_restaurant')}</div>;
};

export default NotFoundRestaurantPage;
