import colorsPalette from '@utils/constants/colors';

export const mobileMenuCategoryCollapseTheme = {
  components: {
    Collapse: {
      contentPadding: '8px 0px 16px 0px',
      headerPadding: '8px 8px 8px 16px',
      fontSize: 20,
      colorTextHeading: colorsPalette['indigo40'],
      fontSizeIcon: 20,
      headerBg: 'rgb(255, 255, 255)',
      colorBorder: 'rgba(0, 0, 0, 0)',
      contentBg: 'rgba(255, 255, 255, 0)',
      colorBgContainer: 'rgba(255, 255, 255, 0)',
    },
  },
};
