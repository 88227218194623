import { PromotionDishExtra, PromotionDishOption } from '@api/models/Promotion';
import { useCreatePromotionMutation, useUpdatePromotionMutation } from '@api/promotionApi';
import { CartExtra, CartOption, CartOptionValues } from '@base/types/Cart';
import PromotionModalForm from '@components/Promotions/PromotionModalForm';
import { HandleEditProps } from '@components/Promotions/usePromotions';
import MyModal from '@components/UI/MyModal';
import { Button } from 'antd';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function preparedPromotionRequestData(values: any): any {
  const opts: PromotionDishOption[] = values.options || [];
  const extras: PromotionDishExtra[] = values.extras || [];

  // prepare options and extras for promotions model structure
  const dishOptions: CartOption[] | undefined = opts.map((opt) => {
    let values: CartOptionValues | null = null;

    opt.values.forEach((valueOpt) => {
      if (valueOpt.selected) {
        values = {
          id: valueOpt.id as number,
          value: valueOpt.value,
          additional_charge: `${valueOpt.additional_charge}`,
          is_readily_available: +valueOpt.is_readily_available,
        };
      }
    });

    return {
      id: opt.id as number,
      name: opt.name,
      values,
    };
  });

  const dishExtras: CartExtra[] = extras
    .filter((extra) => extra.checked && extra.is_readily_available)
    .map((extra) => {
      return {
        id: extra.id as number,
        dish_id: values.dish_id,
        name: extra.name,
        price: `${extra.price}`,
        is_readily_available: extra.is_readily_available,
      };
    });

  return {
    ...values,
    dish_id: values.dish_id || null,
    new_price: +values.new_price || 0,
    options: dishOptions,
    extras: dishExtras,
  };
}

interface PushNotificationModalProps {
  isOpen: boolean;
  form: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setFormValues: (data: HandleEditProps) => Promise<void>;
}

const PromotionModal: FC<PushNotificationModalProps> = ({ isOpen, setIsOpen, form, setFormValues }) => {
  const { t } = useTranslation();
  const [create, { isLoading: isCreation, isSuccess: isCreated }] = useCreatePromotionMutation();
  const [update, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdatePromotionMutation();

  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    if ((!isCreation && isCreated) || (!isUpdating && isUpdated)) {
      setIsModalOpen(false);
    }
  }, [isCreation, isUpdated]);

  const onFinish = useCallback(
    async (values: any) => {
      const preparedValues = preparedPromotionRequestData(values);

      if (values.id) {
        await update({ id: values.id, data: preparedValues });
      } else {
        await create(preparedValues);
      }
    },
    [update, create]
  );

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleAfterClose = () => {
    if (!isModalOpen) {
      setIsOpen(false);
    }
  };

  return (
    <MyModal
      open={isModalOpen}
      width={740}
      footer={
        <Button type={'primary'} onClick={form.submit} htmlType={'submit'} loading={isCreation || isUpdating}>
          {t('save')}
        </Button>
      }
      title={t('definitions_promotions_benefits')}
      onCancel={handleClose}
      afterClose={handleAfterClose}
    >
      <PromotionModalForm form={form} onFinish={onFinish} setFormValues={setFormValues} />
    </MyModal>
  );
};

export default PromotionModal;
