import MBCheckCircleIcon from '@components/icons/MBCheckCircleIcon';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { FC } from 'react';

const ApplyIconButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      type={'text'}
      icon={<MBCheckCircleIcon className={'text-cyan-30'} />}
      className={`h-[40px] justify-center ${className}`}
      {...props}
    />
  );
};

export default ApplyIconButton;
