import { SVGProps } from 'react';
const UserProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2431_658)">
      <path
        d="M4.67997 20.4C5.44496 19.1444 6.5201 18.1066 7.80204 17.3864C9.08398 16.6663 10.5296 16.2881 12 16.2881C13.4703 16.2881 14.916 16.6663 16.1979 17.3864C17.4798 18.1066 18.555 19.1444 19.32 20.4M12 13.7143C13.1366 13.7143 14.2267 13.2628 15.0304 12.4591C15.8342 11.6553 16.2857 10.5652 16.2857 9.42861C16.2857 8.29196 15.8342 7.20188 15.0304 6.39815C14.2267 5.59442 13.1366 5.14289 12 5.14289C10.8633 5.14289 9.77324 5.59442 8.96952 6.39815C8.16579 7.20188 7.71426 8.29196 7.71426 9.42861C7.71426 10.5652 8.16579 11.6553 8.96952 12.4591C9.77324 13.2628 10.8633 13.7143 12 13.7143ZM12 23.1429C14.9552 23.1429 17.7895 21.9689 19.8792 19.8792C21.9689 17.7895 23.1428 14.9553 23.1428 12C23.1428 9.04477 21.9689 6.21054 19.8792 4.12085C17.7895 2.03115 14.9552 0.857178 12 0.857178C9.0447 0.857178 6.21048 2.03115 4.12078 4.12085C2.03109 6.21054 0.857117 9.04477 0.857117 12C0.857117 14.9553 2.03109 17.7895 4.12078 19.8792C6.21048 21.9689 9.0447 23.1429 12 23.1429Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2431_658">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default UserProfile;
