import { SVGProps } from 'react';
const MBWaiterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5646_872)">
      <path
        d="M13.2531 4.47854C12.4531 2.0988 10.3419 0.5 7.99958 0.5C5.55742 0.5 3.41779 2.18953 2.67546 4.70406C2.49965 5.29923 2.41046 5.91996 2.41046 6.54923C2.41046 9.88445 4.91773 12.5979 7.99965 12.5979C11.0819 12.5979 13.5895 9.88445 13.5895 6.54923C13.5895 5.8371 13.4762 5.14023 13.2531 4.47854ZM7.99958 11.2526C5.65945 11.2526 3.75556 9.14266 3.75556 6.54917C3.75556 6.1064 3.81119 5.66956 3.92055 5.24798H8.75382L9.39755 3.84167L10.075 5.24798H12.0787C12.1881 5.66855 12.2443 6.10459 12.2443 6.54917C12.2443 9.14266 10.3401 11.2526 7.99958 11.2526Z"
        fill="currentColor"
      />
      <path
        d="M4.34558 12.6602V16.5004L7.97754 15.4219L11.6095 16.5004V12.6602L7.97754 13.7388L4.34558 12.6602Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5646_872">
        <rect width={16} height={16} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default MBWaiterIcon;
