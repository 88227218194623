import {
  useChangeAvailableExtraMutation,
  useCreateDishExtraMutation,
  useRemoveDishExtraMutation,
  useUpdateDishExtraMutation,
} from '@api/dishExtrasApi';
import useCheckRole from '@hooks/useCheckRole';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ExtraValue {
  name: string;
  price: string;
  is_readily_available: boolean;
}

const useDishExtra = () => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const { isManager } = useCheckRole();
  const [isEditing, setIsEditing] = useState(false);

  const inputNameRef = useRef<any>(null);
  const inputPriceRef = useRef<any>(null);
  const inputAvailableRef = useRef<any>(null);

  const previousValues = useRef({
    name: '',
    price: '',
    is_readily_available: false,
  });

  const [crateExtra, { data: createdExtra, isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] =
    useCreateDishExtraMutation();
  const [updateExtra, { isLoading: isLoadingUpdateExtra, isSuccess: isSuccessUpdate }] = useUpdateDishExtraMutation();
  const [removeExtra, { isLoading: isLoadingRemoveExtra }] = useRemoveDishExtraMutation();
  const [updateAvailability] = useChangeAvailableExtraMutation();

  function getExtraValues(): ExtraValue {
    return {
      name: inputNameRef.current && inputNameRef.current.input.value.trim(),
      price: inputPriceRef.current && inputPriceRef.current.input.value.trim(),
      is_readily_available:
        inputAvailableRef.current && inputAvailableRef.current.getAttribute('aria-checked') === 'true',
    };
  }

  const handleEdit = () => {
    // save previous values
    previousValues.current = getExtraValues();

    setIsEditing(true);
    if (inputNameRef.current) {
      inputNameRef.current.focus();
    }
  };

  return {
    t,
    form,
    isManager,
    crateExtra,
    isLoadingCreate,
    isSuccessCreate,
    createdExtra,
    updateExtra,
    isLoadingUpdateExtra,
    isSuccessUpdate,
    removeExtra,
    isLoading: isLoadingCreate || isLoadingUpdateExtra,
    isLoadingRemoveExtra,
    updateAvailability,

    isEditing,
    setIsEditing,
    inputNameRef,
    inputPriceRef,
    inputAvailableRef,
    previousValues,

    getExtraValues,
    handleEdit,
  };
};

export default useDishExtra;
