import ObRestDomain from '@assets/img/ob_domain.jpg';
import ObMobileRestPreview from '@assets/img/ob_mobile-resr-preview.jpg';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const useSteps = () => {
  const { t } = useTranslation();

  const isSmall = useMediaQuery({ maxWidth: 1310 });

  const obStepRef1 = useRef(null);
  const obStepRef2 = useRef(null);
  const obStepRef3 = useRef(null);
  const obStepRef4 = useRef(null);

  const renderContentStep1 = () => (
    <div className={'flex items-start gap-4'}>
      <div>
        <p>{t('onboarding.rest_settings.step_1.desc_1')}.</p>
        <p>{t('onboarding.rest_settings.step_1.desc_2')}.</p>
      </div>
      <div className={'w-[183px] flex-shrink-0'}>
        <img src={ObMobileRestPreview} alt="" />
      </div>
    </div>
  );

  const renderContentStep4 = () => (
    <div className={'flex flex-col gap-4'}>
      <div>
        <p>{t('onboarding.rest_settings.step_4.desc')}.</p>
      </div>
      <div>
        <img src={ObRestDomain} alt="" />
      </div>
    </div>
  );

  const steps = [
    {
      title: t('onboarding.rest_settings.step_1.title'),
      description: renderContentStep1(),
      target: () => obStepRef1.current,
      contentWidth: isSmall ? 400 : 480,
    },
    {
      title: t('onboarding.rest_settings.step_1.title'),
      description: renderContentStep1(),
      target: () => obStepRef2.current,
      contentWidth: 480,
    },
    {
      title: t('onboarding.rest_settings.step_1.title'),
      description: renderContentStep1(),
      target: () => obStepRef3.current,
      contentWidth: 480,
    },
    {
      title: t('onboarding.rest_settings.step_4.title'),
      description: renderContentStep4(),
      target: () => obStepRef4.current,
    },
  ];

  return {
    obStepRef1,
    obStepRef2,
    obStepRef3,
    obStepRef4,
    steps,
  };
};

export default useSteps;
