import api from '@api/api';
import DishOption from '@api/models/DishOption';
import DishOptionValue from '@api/models/DishOptionValue';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createDishOption: builder.mutation<DishOption, { dish_id: number; name: string }>({
      query({ dish_id, name }) {
        return {
          url: `/option`,
          method: 'POST',
          body: {
            dish_id,
            name,
          },
        };
      },
      transformResponse: (response: { data: DishOption }) => response.data,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('saved');
        } catch (error) {
          message.error('error creating option');
        }
      },
    }),

    updateDishOption: builder.mutation<any, { id: number; name: string }>({
      query({ id, name }) {
        return {
          url: `/option/${id}`,
          method: 'PUT',
          body: {
            name,
          },
        };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('updated');
        } catch (error) {
          message.error('error updating option');
        }
      },
    }),

    removeDishOption: builder.mutation<any, number>({
      query(id) {
        return {
          url: `/option/${id}`,
          method: 'DELETE',
        };
      },
    }),

    createDishOptionValue: builder.mutation<any, DishOptionValue>({
      query(data) {
        return {
          url: `/option-value`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: DishOptionValue }) => response.data,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('saved');
        } catch (error) {
          message.error('error creating option value');
        }
      },
    }),

    updateDishOptionValue: builder.mutation<any, { id: number; data: DishOptionValue }>({
      query({ id, data }) {
        return {
          url: `/option-value/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('saved');
        } catch (error) {
          message.error('error updating option value');
        }
      },
    }),

    removeDishOptionValue: builder.mutation<any, number>({
      query(id) {
        return {
          url: `/option-value/${id}`,
          method: 'DELETE',
        };
      },
    }),
    changeAvailableDishOptionValue: builder.mutation<any, { id: number; is_readily_available: boolean }>({
      query({ id, is_readily_available }) {
        return {
          url: addTenantToEndpoint(`/option-value/toggle/${id}`),
          method: 'PATCH',
          body: { is_readily_available },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('updated');
        } catch (error) {
          message.error('error updating availability option value');
        }
      },
    }),
  }),
});

export const {
  useCreateDishOptionMutation,
  useUpdateDishOptionMutation,
  useRemoveDishOptionMutation,
  useCreateDishOptionValueMutation,
  useUpdateDishOptionValueMutation,
  useRemoveDishOptionValueMutation,
  useChangeAvailableDishOptionValueMutation,
} = extendedApiSlice;
