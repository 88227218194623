import { useAppSelector } from '@base/redux/store';
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY, serverUrl } from '@constants/environments';
import { ROUTES } from '@constants/routes';
import useCheckRole from '@hooks/useCheckRole';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

function getChannels(authToken?: string) {
  const client = new Pusher(PUSHER_APP_KEY, {
    cluster: PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: `${serverUrl}/broadcasting/auth`,
    auth: authToken
      ? {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      : undefined,
  });

  return new Echo({
    broadcaster: 'pusher',
    client,
  });
}

type Channels = Echo | undefined;
const PusherContext = createContext<any | undefined>(undefined);
export function usePusher() {
  return useContext(PusherContext);
}

export function usePrivateChannels(channelName: string) {
  const pusherInstance = usePusher();

  return useMemo(() => {
    return pusherInstance && pusherInstance.private(channelName);
  }, [pusherInstance]);
}

export function useGetPusherInstance() {
  const pusherInstance: Echo = usePusher();

  return useMemo(() => {
    return pusherInstance && pusherInstance;
  }, [pusherInstance]);
}

export const PusherProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const token = useAppSelector((state) => state.userState.token);
  const [channelState, setChannelState] = useState<Channels>(undefined);
  const { pathname } = useLocation();
  const { isCallsDrawerOpened } = useAppSelector((state) => state.waiterSlice);
  const { isWaiter } = useCheckRole();

  const isCreateChannel =
    pathname === ROUTES.orders ||
    pathname === ROUTES.client_orders ||
    pathname === ROUTES.dashboard ||
    isCallsDrawerOpened ||
    isWaiter;

  // init for websockets
  useEffect(() => {
    if (!channelState && token && isCreateChannel) {
      const channels = getChannels(token);
      setChannelState(channels);
    }

    if (channelState && !isCreateChannel) {
      channelState.disconnect();
      setChannelState(undefined);
    }
  }, [token, pathname, isCallsDrawerOpened]);

  return <PusherContext.Provider value={channelState}>{children}</PusherContext.Provider>;
};
