import { SVGProps } from 'react';
const SmileFaceRatingThreeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.5 40C31.5117 40 40.4385 31.0457 40.4385 20C40.4385 8.9543 31.5117 0 20.5 0C9.48829 0 0.561523 8.9543 0.561523 20C0.561523 31.0457 9.48829 40 20.5 40Z"
      fill="#FFAF26"
    />
    <path
      d="M27.3609 18.3022C28.9647 18.3022 30.2648 16.9981 30.2648 15.3894C30.2648 13.7807 28.9647 12.4766 27.3609 12.4766C25.7571 12.4766 24.457 13.7807 24.457 15.3894C24.457 16.9981 25.7571 18.3022 27.3609 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M12.8615 18.3022C14.4653 18.3022 15.7654 16.9981 15.7654 15.3894C15.7654 13.7807 14.4653 12.4766 12.8615 12.4766C11.2577 12.4766 9.95764 13.7807 9.95764 15.3894C9.95764 16.9981 11.2577 18.3022 12.8615 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M27.7495 28.1986H13.2501C12.7084 28.1986 12.269 27.7579 12.269 27.2145C12.269 26.6712 12.7084 26.2305 13.2501 26.2305H27.7495C28.2911 26.2305 28.7305 26.6712 28.7305 27.2145C28.7305 27.7579 28.2911 28.1986 27.7495 28.1986Z"
      fill="currentColor"
    />
  </svg>
);
export default SmileFaceRatingThreeIcon;
