import MBBurgerIcon from '@components/icons/MBBurgerIcon';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import DndDish from '@pages/Components/DndMenu/DndDish';
import { CategoryType, DishType, DND_CATEGORY_TYPE, SubcategoryType } from '@pages/Components/DndMenu/DndMenu';
import DndSubcategory from '@pages/Components/DndMenu/DndSubcategory';
import { Collapse, CollapseProps } from 'antd';
import { FC, useMemo } from 'react';

interface DndCategoryProps {
  category: CategoryType;
  subcategories: SubcategoryType[];
  dishes: DishType[];
}

const DndCategory: FC<DndCategoryProps> = ({ category, subcategories, dishes }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: category.dnd_id,
    data: {
      type: DND_CATEGORY_TYPE,
      category,
    },
  });

  const subcategoriesIds = useMemo(() => {
    return subcategories.map((subcategory) => subcategory.dnd_id);
  }, [subcategories]);

  const dishesIds = useMemo(() => {
    return dishes.map((dish) => dish.dnd_id);
  }, [dishes]);

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  const isOverClass = isOver ? 'border border-solid border-grey-40' : '';

  const renderSubcategories = () => {
    return subcategories.map((subcategory) => (
      <DndSubcategory
        key={subcategory.dnd_id}
        subcategory={subcategory}
        dishes={dishes.filter((dish) => dish.menu.dnd_id === subcategory.dnd_id)}
      />
    ));
  };

  const renderDishes = () => {
    return dishes
      .filter((dish) => dish.menu.dnd_id === category.dnd_id)
      .map((dish) => <DndDish key={dish.dnd_id} dish={dish} />);
  };

  const renderChildren = () => {
    return (
      <div className={'min-h-[120px] flex-grow'}>
        <div className={'flex flex-col justify-start gap-2'}>
          <SortableContext items={subcategoriesIds}>
            {subcategories.length > 0 && renderSubcategories()}
          </SortableContext>
          <SortableContext items={dishesIds}>{dishes.length > 0 && renderDishes()}</SortableContext>
        </div>
      </div>
    );
  };

  if (isDragging) {
    return (
      <div ref={setNodeRef} style={style} className={'flex w-full justify-start gap-4'}>
        <div
          className={'mr-[32px] min-h-[50px] w-full border-2 border-solid border-orange-700 bg-grey-20 p-2 opacity-60'}
        ></div>
      </div>
    );
  }

  const items: CollapseProps['items'] = [
    {
      key: category.dnd_id,
      label: category.name,
      children: renderChildren(),
      destroyInactivePanel: true,
    },
  ];

  return (
    <div ref={setNodeRef} style={style}>
      <div className={'flex w-full justify-start gap-4'}>
        <MBBurgerIcon className={'mt-4 flex-shrink-0 grow-0'} {...listeners} {...attributes} />
        <Collapse items={items} className={`flex-grow bg-green-20 ${isOverClass}`} />
      </div>
    </div>
  );
};

export default DndCategory;
