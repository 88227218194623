import { PackageTransaction } from '@base/redux/api/models/PackageTransaction';
import { CURRENCY } from '@base/utils/constants/constants';
import convertServerDateToClient from '@base/utils/helpers/convertServerDateToClient';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../icons/Logo';

const InformationField: FC<{ title: string; text?: string }> = ({ title, text }) => {
  return (
    <div className={'flex w-full flex-col'}>
      <span className={'mb-1 text-base font-bold'}>{title}</span>
      <span className={'text-base text-grey-30'}>{text}</span>
    </div>
  );
};

const TransactionPdf: FC<{ transaction: PackageTransaction }> = ({ transaction }) => {
  const { t } = useTranslation();

  const renderShippingInformation = () => {
    return (
      <div className={'flex basis-[calc(50%-12px)] flex-col gap-5'}>
        <div className={'mb-4 text-xl font-bold text-grey-30'}>{t('shipping_information')}</div>
        <InformationField title={t('name')} text={transaction.data?.fullName || transaction.data?.payer_name} />
        <InformationField title={t('phone')} text={transaction.data?.payerPhone || transaction.data?.phone} />
        <InformationField title={t('email')} text={transaction.data?.email || transaction.data?.payerEmail} />
      </div>
    );
  };

  const renderBillingInformation = () => {
    return (
      <div className={'flex basis-[calc(50%-12px)] flex-col gap-5'}>
        <div className={'mb-4 text-xl font-bold text-grey-30'}>{t('billing_information')}</div>
        <InformationField title={t('company')} text={'MenuBit'} />
        <InformationField title={t('phone')} text={'1234567890'} />
        <InformationField title={t('email')} text={'menubit@gmail.com'} />
      </div>
    );
  };

  return (
    <>
      <div className={'w-full max-w-[794px] bg-white'}>
        <div className={'relative flex min-h-[96px] items-center justify-between bg-[#f2f0fb] px-20 py-5'}>
          <span className={'text-base'}>{convertServerDateToClient(transaction.created_at, 'DD/MM/YYYY')} </span>
          <span
            className={
              'absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] rotate-0 skew-x-0 skew-y-0 scale-100 transform text-2xl font-bold'
            }
          >
            {t('payment_confirmation')}
          </span>
          <Logo />
        </div>
        <div className={'flex w-full flex-col gap-5 px-10 py-5'}>
          <div>
            <div className={'mb-1 text-base font-bold'}>
              {t('transaction_id')}:{' '}
              <span className={'text-base text-grey-30'}>{transaction.data?.transactionId || '-'}</span>
            </div>
            <div className={'mb-1 text-base font-bold'}>
              {t('transaction_code')}:{' '}
              <span className={'text-base text-grey-30'}>{transaction.data?.transactionCode || '-'}</span>
            </div>
          </div>
          <div className={'h-[5px] w-full bg-grey-20'}></div>
          <div className={'mt-5 flex w-full justify-between gap-6'}>
            {renderShippingInformation()}
            {renderBillingInformation()}
          </div>
          <div className={'h-[5px] w-full bg-grey-20'}></div>
          <div className={'grid grid-cols-5 gap-2'}>
            <div></div>
            <div className={'rounded bg-[#d8d6f7] px-2 py-1 text-center text-sm font-bold'}>{t('description')}</div>
            <div className={'rounded bg-[#d8d6f7] px-2 py-1 text-center text-sm font-bold'}>{t('quantity')}</div>
            <div className={'rounded bg-[#d8d6f7] px-2 py-1 text-center text-sm font-bold'}>{t('unit_price')}</div>
            <div className={'rounded bg-[#d8d6f7] px-2 py-1 text-center text-sm font-bold'}>{t('total')}</div>
            <div className={'rounded bg-[#d8d6f7] px-2 py-1 text-center text-sm font-bold'}>{t('product')}</div>
            <div className={'rounded bg-[#f5f4fb] px-2 py-1 text-center text-sm font-bold'}>
              {transaction.package.name}
            </div>
            <div className={'rounded bg-[#f5f4fb] px-2 py-1 text-center text-sm font-bold'}>{1}</div>
            <div className={'rounded bg-[#f5f4fb] px-2 py-1 text-center text-sm font-bold'}>{transaction.sum}</div>
            <div className={'rounded bg-[#f5f4fb] px-2 py-1 text-center text-sm font-bold'}>
              {CURRENCY}
              {transaction.sum}
            </div>
          </div>
          <div className="w-full px-16 text-end text-xl font-bold">
            <span className={'pe-2 font-normal'}>{t('total')}:</span>
            <span>
              {CURRENCY}
              {transaction.sum}
            </span>
          </div>
          <div className={'h-[5px] w-full bg-grey-20'}></div>
        </div>
      </div>
    </>
  );
};

export default TransactionPdf;
