import RestaurantExistence from '@base/hoc/RestaurantExistence';
import Packages from '@components/Packages';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const PackagesPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.packages'));

  return (
    <RestaurantExistence>
      <Packages />
    </RestaurantExistence>
  );
};

export default PackagesPage;
