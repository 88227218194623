import placeholder from '@base/assets/img/logo-restaurant-placeholder.png';
import { FC } from 'react';

const RestaurantLogo: FC<{ src: string | null }> = ({ src }) => {
  return (
    <div
      className={
        'restaurant-card-image-block__img flex h-[inherit] w-[inherit] shrink overflow-hidden rounded-[50%] bg-white shadow-[4px_4px_20px_0_rgba(0,90,178,0.5)]'
      }
    >
      <img className={'m-auto h-full w-full'} src={src || placeholder} alt="Restaurant logo" />
    </div>
  );
};

export default RestaurantLogo;
