import CabinetNav from '@base/types/CabinetNav';
import MBBoxIcon from '@components/icons/MBBoxIcon';
import MBClipBoardIcon from '@components/icons/MBClipBoardIcon';
import MBCreditCardIcon from '@components/icons/MBCreditCardIcon';
import MBGridIcon from '@components/icons/MBGridIcon';
import MBLayoutIcon from '@components/icons/MBLayoutIcon';
import MBLockIcon from '@components/icons/MBLockIcon';
import MBMaximizeIcon from '@components/icons/MBMaximizeIcon';
import MBMealIcon from '@components/icons/MBMealIcon';
import MBMenuIcon from '@components/icons/MBMenuIcon';
import MBMessageSquareIcon from '@components/icons/MBMessageSquareIcon';
import MBSettingsIcon from '@components/icons/MBSettingsIcon';
import MBTableIcon from '@components/icons/MBTableIcon';
import MBUsersIcon from '@components/icons/MBUsersIcon';
import { ROUTES } from '@constants/routes';
import useAvailableFeatures, { AvailableFeatures } from '@hooks/useAvailableFeatures';
import useOriginalRestHost from '@hooks/useOriginalRestHost';
import useSidepanel from '@layout/Sidepanel/useSidepanel';
import { Layout, Menu, MenuProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const { Sider } = Layout;

export interface MenuItemsByRole {
  manager: CabinetNav[];
  supervisor: CabinetNav[];
  waiter: CabinetNav[];
}

type MenuItem = Required<MenuProps>['items'][number];

function getCabinetNavByRole({
  role,
  features,
  t,
  isActiveRestaurant = true,
}: {
  role: string;
  features: AvailableFeatures;
  t: any;
  isActiveRestaurant: boolean | null;
}) {
  const isDisabledRoute = isActiveRestaurant !== null && !isActiveRestaurant;
  const cabinetNav: MenuItemsByRole = {
    manager: [
      {
        link: ROUTES.dashboard,
        label: t('sidebar_menu.dashboard'),
        icon: <MBGridIcon />,
        divider: true,
        disabled: isDisabledRoute,
      },
      {
        link: ROUTES.restaurant,
        label: t('sidebar_menu.restaurant_management'),
        icon: <MBLayoutIcon />,
        disabled: isDisabledRoute,
      },
      { link: ROUTES.menus, label: t('sidebar_menu.menus'), icon: <MBMenuIcon />, disabled: isDisabledRoute },
      {
        link: ROUTES.cabinet_promotions,
        label: t('sidebar_menu.promotions'),
        icon: <MBMessageSquareIcon />,
        disabled: !features.isPushNotifications || isDisabledRoute,
      },
      {
        link: ROUTES.orders,
        label: t('sidebar_menu.orders'),
        icon: <MBMealIcon />,
        disabled: !features.isOrderPlacementPayment || isDisabledRoute,
      },
      {
        link: ROUTES.inventory,
        label: t('sidebar_menu.inventory'),
        icon: <MBClipBoardIcon />,
        disabled: !features.isInventory || isDisabledRoute,
      },
      {
        link: ROUTES.qr_builder,
        label: t('sidebar_menu.qr_builder'),
        icon: <MBMaximizeIcon />,
        disabled: isDisabledRoute,
      },
      {
        link: ROUTES.workers,
        label: t('sidebar_menu.users'),
        icon: <MBUsersIcon />,
        disabled: features.availableWorkers.length === 1 || isDisabledRoute,
      },
      {
        link: ROUTES.tables,
        label: t('sidebar_menu.tables'),
        icon: <MBTableIcon />,
        divider: true,
        disabled: isDisabledRoute,
      },
      { link: ROUTES.account_settings, label: t('sidebar_menu.account_settings'), icon: <MBSettingsIcon /> },
      { link: ROUTES.packages, label: t('sidebar_menu.packages'), icon: <MBBoxIcon /> },
      { link: ROUTES.transactions, label: t('sidebar_menu.transactions'), icon: <MBCreditCardIcon /> },
    ],

    supervisor: [
      {
        link: ROUTES.dashboard,
        label: t('sidebar_menu.dashboard'),
        icon: <MBGridIcon />,
        divider: true,
        disabled: isDisabledRoute,
      },
      { link: ROUTES.menus, label: t('sidebar_menu.menus'), icon: <MBMenuIcon />, disabled: isDisabledRoute },
      {
        link: ROUTES.cabinet_promotions,
        label: t('sidebar_menu.promotions'),
        icon: <MBMessageSquareIcon />,
        disabled: isDisabledRoute,
      },
      { link: ROUTES.orders, label: t('sidebar_menu.orders'), icon: <MBMealIcon />, disabled: isDisabledRoute },
      {
        link: ROUTES.inventory,
        label: t('sidebar_menu.inventory'),
        icon: <MBClipBoardIcon />,
        disabled: isDisabledRoute,
      },
      {
        link: ROUTES.tables,
        label: t('sidebar_menu.tables'),
        icon: <MBTableIcon />,
        divider: true,
        disabled: isDisabledRoute,
      },
      { link: ROUTES.account_settings, label: t('sidebar_menu.account_settings'), icon: <MBSettingsIcon /> },
    ],

    waiter: [
      { link: ROUTES.menus, label: t('sidebar_menu.menus'), icon: <MBMenuIcon />, disabled: isDisabledRoute },
      {
        link: ROUTES.orders,
        label: t('sidebar_menu.orders'),
        icon: <MBMealIcon />,
        divider: true,
        disabled: isDisabledRoute,
      },
      {
        link: ROUTES.account_settings,
        label: t('sidebar_menu.account_settings'),
        icon: <MBSettingsIcon />,
      },
    ],
  };

  return cabinetNav[role as keyof MenuItemsByRole];
}

function getDisableMenuItem(label: string) {
  return (
    <div className={'flex w-full items-center justify-between gap-2'}>
      {label}
      <MBLockIcon className={'text-base'} />
    </div>
  );
}

const Sidepanel: FC = () => {
  const { t } = useTranslation();
  const features = useAvailableFeatures();
  const { getOriginalRestHost } = useOriginalRestHost();

  const { location, isLG, isAuth, collapsed, setCollapsed, role, isActiveRestaurant } = useSidepanel();

  // TODO: move this to hook and make it exec only once or on features changes
  const navForRole = getCabinetNavByRole({ role, features, t, isActiveRestaurant });
  const nav: MenuItem[] = [];

  navForRole.forEach((item) => {
    nav.push({
      label: item.disabled ? (
        getDisableMenuItem(item.label)
      ) : (
        <Link to={getOriginalRestHost(item.link)}>{item.label}</Link>
      ),
      key: item.link,
      icon: item.icon,
    });

    if (item.divider) {
      nav.push({ type: 'divider' });
    }
  });

  return (
    <Sider
      theme={'light'}
      className={'side-panel py-6'}
      collapsible={isLG}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu selectedKeys={[location.pathname]} mode="inline" items={isAuth ? nav : []} />
    </Sider>
  );
};

export default Sidepanel;
