import UserAccess from '@base/hoc/UserAccess';
import { setCollapsedActiveKeys } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';
import MBChevronDownIcon from '@components/icons/MBChevronDownIcon';
import MBListIcon from '@components/icons/MBListIcon';
import DndDraggableDish from '@components/Menus/Dnd/DndDraggableDish';
import DndDraggableSubcategory from '@components/Menus/Dnd/DndDraggableSubcategory';
import { DND_CATEGORY_TYPE, overClasses } from '@components/Menus/Dnd/DndMenuCategories/DndMenuCategories';
import { DndDish, DndMenu } from '@components/Menus/Dnd/DndMenuCategories/useDndMenuCategories';
import MenuCategory from '@components/Menus/MenuCategory';
import useMenusOnboarding from '@components/Menus/MenusOnboarding/useMenusOnboarding';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { Button, Collapse, CollapseProps } from 'antd';
import { FC, ReactNode, useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface CategoryItemProps {
  category: DndMenu;
  subcategories?: DndMenu[];
  categoryDishes?: DndDish[];
  dishes?: DndDish[];
  isFirstCategory?: boolean;
}

export function genExpandBtn(isActive: boolean): ReactNode {
  return (
    <Button
      type={'link'}
      className={'h-[2em] w-[1.8em] justify-center gap-0 bg-grey-5 p-1 text-center hover:bg-grey-5'}
    >
      <MBChevronDownIcon
        className={'m-0 text-2xl text-grey-20'}
        style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
      />
    </Button>
  );
}

const DndDraggableCategory: FC<CategoryItemProps> = ({
  category,
  subcategories,
  categoryDishes,
  dishes,
  isFirstCategory,
}) => {
  const dispatch = useDispatch();

  const { isShowTour, renderTour, obStepRef1, obStepRef2, obStepRef3 } = useMenusOnboarding();

  const { collapsedActiveKeys } = useAppSelector((state) => state.menuSlice);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: category.dnd_id,
    data: {
      type: DND_CATEGORY_TYPE,
      category,
    },
  });

  const subcategoriesDndIds = subcategories
    ? useMemo(() => {
        return subcategories.map((subcategory) => subcategory.dnd_id);
      }, [subcategories])
    : [];

  const categoryDishesDndIds = categoryDishes
    ? useMemo(() => {
        return categoryDishes.map((dish) => dish.dnd_id);
      }, [categoryDishes])
    : [];

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  const isOverClass = isOver ? overClasses : '';

  const handleCollapseChange = (key: string | string[]) => {
    if (Array.isArray(key)) {
      dispatch(setCollapsedActiveKeys(key));
    }
  };

  const renderSubcategories = (subcategories: DndMenu[]) => {
    return subcategories.map((subcategory) => (
      <DndDraggableSubcategory key={subcategory.dnd_id} subcategory={subcategory} dishes={dishes} />
    ));
  };

  const renderDishes = (dishes: DndDish[]) => {
    return dishes.map((dish) => <DndDraggableDish key={dish.dnd_id} dish={dish} />);
  };

  const renderChildren = () => (
    <div className={'mx-auto flex max-w-[90%] flex-col gap-4'}>
      {/* render dish subcategory list in category */}
      <SortableContext items={subcategoriesDndIds}>
        {subcategories && subcategories.length > 0 && renderSubcategories(subcategories)}
      </SortableContext>

      {/* render dish list in category */}
      <SortableContext items={categoryDishesDndIds}>
        {categoryDishes && categoryDishes.length > 0 && renderDishes(categoryDishes)}
      </SortableContext>
    </div>
  );

  const renderHeader = () => (
    <div className={'flex gap-2'}>
      <UserAccess roles={['restaurant-manager']}>
        <Button
          ref={obStepRef3}
          type={'text'}
          className={'h-[2em] w-[1.8em] cursor-move justify-center bg-grey-5 p-1 text-md'}
          {...attributes}
          {...listeners}
        >
          <MBListIcon type={'link'} className={'shrink-0 text-base text-grey-20'} />
        </Button>
      </UserAccess>
      <div className={'flex-grow'}>
        <MenuCategory obStepRef1={obStepRef1} obStepRef2={obStepRef2} item={category} />
      </div>
      {isFirstCategory && isShowTour && renderTour()}
    </div>
  );

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={'min-h-[72px] w-full rounded border-2 border-solid border-grey-20 bg-grey-10 opacity-60'}
      ></div>
    );
  }

  const collapseItems: CollapseProps['items'] = [
    {
      key: category.id,
      label: renderHeader(),
      children: renderChildren(),
      showArrow: true,
      collapsible: 'icon',
      destroyInactivePanel: true,
    },
  ];

  return (
    <div ref={setNodeRef} style={style}>
      <Collapse
        className={`menu-category flex flex-col gap-4 text-md ${isOverClass}`}
        bordered={false}
        expandIconPosition={'end'}
        expandIcon={({ isActive }) => genExpandBtn(isActive as boolean)}
        items={collapseItems}
        activeKey={collapsedActiveKeys}
        onChange={handleCollapseChange}
      />
    </div>
  );
};

export default DndDraggableCategory;
