import { SVGProps } from 'react';
const VegetarianIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#E9F9FB" />
    <path
      d="M10.6824 18.209C13.1726 18.5924 15.8453 20.9323 16.552 23.3682M24.3518 10.3465C24.902 9.3729 25.7242 8.58018 26.6667 7.94122C24.3858 7.12279 21.7698 6.82106 19.3951 7.4429C17.6025 7.9123 16.0195 9.02099 15.1152 10.5741C14.4055 11.7934 14.0912 13.3589 14.7384 14.6633C15.4977 16.1941 17.3223 17.126 19.0809 16.9782C21.0193 16.8152 22.4245 15.4244 23.0845 13.7668C23.1584 13.5813 23.225 13.3935 23.2876 13.2044M19.2222 11.5515C19.2222 11.5515 16.6505 12.7535 16.6505 17.3362V26.6669M5.33334 18.1878C5.33334 18.1878 6.39648 16.6746 8.81206 15.8941C11.1016 15.1544 14.0753 15.6516 14.8132 17.9619C15.4892 20.0786 13.6351 22.2402 11.2882 21.9733C8.11711 21.6127 8.10749 18.55 5.33334 18.1878Z"
      stroke="#1095A9"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#1095A9" />
  </svg>
);
export default VegetarianIcon;
