import { useLogoutUserMutation, useLogoutWorkerMutation } from '@api/authApi';
import { useLogoutClientMutation } from '@api/clientApi';
import { useBeams } from '@base/contexts/PushNotificationsProvider';
import api from '@base/redux/api/api';
import { resetCartSlice } from '@base/redux/features/cartSlice';
import { resetClientSlice } from '@base/redux/features/clientSlice';
import { resetCommonSlice } from '@base/redux/features/commonSlice';
import { resetDashboardSlice } from '@base/redux/features/dashboardSlice';
import { resetMenuSlice } from '@base/redux/features/menuSlice';
import { resetQRSlice } from '@base/redux/features/QRCodeSlice';
import { resetRestaurantSlice } from '@base/redux/features/restaurantSettingsSlice';
import { resetSupMobMenuSetSlice } from '@base/redux/features/supervisorMobileMenuSettingsSlice';
import { resetUserSlice } from '@base/redux/features/userSlice';
import { resetWaiterSlice } from '@base/redux/features/waiterSlice';
import { resetWorkerSlice } from '@base/redux/features/workerSlice';
import { LOGOUT_QUERY_PARAM } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import getQueryParam from '@helpers/getQueryParam';
import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { getRoleFromCookies } from '@helpers/getRoleFromCookies';
import { getUserFromCookies } from '@helpers/getUserFromCookies';
import { removeClientOrderFromCookies } from '@helpers/removeClientOrderFromCookies';
import { removeClientOrderFromLocalstorage } from '@helpers/removeClientOrderFromLocalstorage';
import { removeRoleFromCookies } from '@helpers/removeRoleFromCookies';
import { removeTokenFromCookies } from '@helpers/removeTokenFromCookies';
import { removeUserFromCookies } from '@helpers/removeUserFromCookies';
import useAuth from '@hooks/useAuth';
import useCheckRole from '@hooks/useCheckRole';
import { googleLogout } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const clearAllCookies = async () => {
  await removeClientOrderFromCookies();
  await removeTokenFromCookies();
  await removeUserFromCookies();
};

const useLogout = () => {
  const dispatch = useDispatch();
  const { isClient, isManager } = useCheckRole();
  const createdBeams = useBeams();
  const navigate = useNavigate();
  const { originalHost } = useAuth();

  const [logoutUser] = isManager
    ? useLogoutUserMutation()
    : isClient
    ? useLogoutClientMutation()
    : useLogoutWorkerMutation();

  const clearAllSlices = async () => {
    await dispatch(api.util.resetApiState());
    await dispatch(resetCartSlice());
    await dispatch(resetClientSlice());
    await dispatch(resetCommonSlice());
    await dispatch(resetMenuSlice());
    await dispatch(resetQRSlice());
    await dispatch(resetRestaurantSlice());
    await dispatch(resetSupMobMenuSetSlice());
    await dispatch(resetWorkerSlice());
    await dispatch(resetWaiterSlice());
    await dispatch(resetDashboardSlice());
    await dispatch(resetUserSlice());
  };

  const clearAllData = async () => {
    if (createdBeams) {
      await createdBeams.unsubscribeFromPusher();
    }

    await clearAllCookies();
    await removeClientOrderFromLocalstorage();
    await clearAllSlices();
    await localStorage.removeItem('persist:root');
  };

  const logout = async () => {
    const isUserOnOriginalHost = window.location.origin === originalHost;
    const isLogoutQuery = await getQueryParam('logout');
    const role = getRoleFromCookies();
    const isCookieUser = getUserFromCookies();
    const restLink = getRestaurantLinkFromCookies();

    // show loading
    navigate(ROUTES.loading);

    if (isUserOnOriginalHost) {
      await logoutUser();
    }

    await googleLogout();
    await clearAllData();

    if (role === 'client') {
      // if client logout from subdomain
      // step 1
      if (!isUserOnOriginalHost && !isLogoutQuery) {
        window.location.href = `${originalHost}?${LOGOUT_QUERY_PARAM}`;

        return;
      }
      // step 2
      if (isUserOnOriginalHost && !isCookieUser && restLink) {
        window.location.href = restLink;

        return;
      }

      // if client logout from domain
      // step 1
      if (isUserOnOriginalHost && isCookieUser && restLink) {
        window.location.href = `${restLink}?${LOGOUT_QUERY_PARAM}`;

        return;
      }
      // step 2
      if (!isUserOnOriginalHost && isLogoutQuery && !isCookieUser) {
        navigate(ROUTES.root);

        return;
      }

      return;
    }

    // remove role from cookies if user is not client
    removeRoleFromCookies();

    if (isUserOnOriginalHost) {
      await navigate(ROUTES.root);
    } else if (originalHost) {
      window.location.href = `${originalHost}?${LOGOUT_QUERY_PARAM}`;
    } else {
      await navigate(ROUTES.root);
    }
  };

  return {
    logout,
  };
};

export default useLogout;
