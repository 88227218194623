import { OnboardingKeys } from '@base/redux/features/userSlice';
import useSteps from '@components/Menus/MenusOnboarding/useSteps';
import useTour from '@components/UI/Tour/useTour';

const useMenusOnboarding = () => {
  const onboardingKey: OnboardingKeys = 'cabinet_menus';

  const { steps, obStepRef1, obStepRef2, obStepRef3 } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
    obStepRef2,
    obStepRef3,
  };
};

export default useMenusOnboarding;
