import { useLazyGetWaiterCallsQuery } from '@api/callToWaiterApi';
import { WaiterCallFromClient, WaiterCallsState } from '@api/models/WaiterCalls';
import { useGetPusherInstance } from '@base/contexts/PusherProvider';
import { useAppSelector } from '@base/redux/store';
import { WaterCall } from '@base/types/WaterCall';
import { CHANNELS, CHANNELS_EVENTS } from '@constants/channels';
import useCheckRole from '@hooks/useCheckRole';
import { Channel } from 'laravel-echo';
import { useEffect, useMemo, useState } from 'react';

const useCallToWaiterRequestList = () => {
  const pusherInstance = useGetPusherInstance();
  const waiterID = useAppSelector((state) => state.userState.id);
  const [getCalls, { data, isLoading, isSuccess, isError }] = useLazyGetWaiterCallsQuery();
  const { isWaiter } = useCheckRole();
  const workerID = useAppSelector((state) => state.userState.id);

  const [callsState, setCallsState] = useState<WaiterCallsState[]>([]);

  useEffect(() => {
    if (waiterID) {
      getCalls(waiterID);
    }
  }, [waiterID]);

  useEffect(() => {
    if (!isLoading && data) {
      setCallsState(
        data.map((call) => ({
          waiter: call.waiter,
          table: call.table,
          services: call.services,
          client: call.client,
          created_at: call.created_at,
        })) as WaiterCallsState[]
      );
    }
  }, [isLoading, data]);

  useEffect(() => {
    let newCallChannel: Channel | null = null;

    if (pusherInstance && workerID) {
      if (isWaiter) {
        newCallChannel = pusherInstance.private(`${CHANNELS.newCallToWaiter}.${workerID}`);
        newCallChannel.listen(CHANNELS_EVENTS.new_waiter_call, handleAddCall);
        newCallChannel.listen(CHANNELS_EVENTS.undo_waiter_call, handleRemoveCall);
      }
    }

    return () => {
      if (newCallChannel) {
        newCallChannel.stopListening(CHANNELS_EVENTS.new_waiter_call);
        newCallChannel.stopListening(CHANNELS_EVENTS.undo_waiter_call);
      }
    };
  }, [pusherInstance, callsState, handleAddCall]);

  function handleAddCall(e: any) {
    const newCall: WaiterCallFromClient = e.data;

    if (!newCall) {
      return;
    }

    const newService = {
      id: newCall.service.id,
      name: newCall.service.name,
    };

    const callItemState: WaiterCallsState = {
      waiter: newCall.waiter,
      table: newCall.table,
      services: [newService],
      client: newCall.client,
      created_at: newCall.service.created_at,
    };

    const clientIndex = callsState.findIndex((call) => call.client.id === newCall.client.id);

    setCallsState((prev) => {
      const newCalls = [...prev];
      // if client already has call, then update it
      if (clientIndex !== -1) {
        const clientCall = newCalls[clientIndex];
        newCalls[clientIndex] = { ...clientCall, services: [...clientCall.services, newService] };

        // if client doesn't have call, then add it
      } else {
        newCalls.push(callItemState);
      }

      return newCalls;
    });
  }

  function handleRemoveCall(e: any) {
    const removedCall: WaiterCallFromClient = e.data;

    if (!removedCall) {
      return;
    }

    const clientIndex = callsState.findIndex((call) => call.client.id === removedCall.client.id);

    if (clientIndex !== -1) {
      setCallsState((prev) => {
        const newCalls = [...prev];
        const currentCall = newCalls[clientIndex];
        const updatedServices = currentCall.services.filter((service) => service.id !== removedCall.service.id);

        if (!updatedServices.length) {
          newCalls.splice(clientIndex, 1);
        } else {
          newCalls[clientIndex] = { ...currentCall, services: updatedServices };
        }

        return newCalls;
      });
    }
  }

  const calls: WaterCall[] | undefined = useMemo(
    () =>
      callsState.map((call) => {
        const client_name = `${call.client.first_name} ${call.client.last_name}`;
        const services_slugs = call.services.map((service) => service.name);

        return {
          // id: call.id,
          createdAt: call.created_at,
          client_id: call.client.id,
          client_name,
          table_id: call.table.id,
          table_name: call.table.name,
          services_slugs,
        };
      }),
    [callsState]
  );

  return {
    calls,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useCallToWaiterRequestList;
