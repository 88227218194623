import OrdersMonitoring from '@base/components/OrdersMonitoring';
import RestaurantExistence from '@base/hoc/RestaurantExistence';
import SimpleSection from '@components/UI/SimpleSection';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const OrdersMonitoringPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.orders'));

  return (
    <RestaurantExistence>
      <SimpleSection title={t('pages.orders')}>
        <OrdersMonitoring />
      </SimpleSection>
    </RestaurantExistence>
  );
};

export default OrdersMonitoringPage;
