import { useGetMenusQuery } from '@api/menuApi';
import { Menu } from '@api/models/Menu';
import { setIsClientFilteredMenu } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import MobileCategoryLogo from '@components/icons/MobileCategoryLogo';
import MobileMenuDishList from '@components/MobileMenu/MobileMenuDishList';
import MobileSubCategory from '@components/MobileMenu/MobileSubCategory';
import { Button, Collapse, CollapseProps, ConfigProvider } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mobileMenuCategoryCollapseTheme } from './mobileMenuCategoryCollapse.antd';

const genHeader = (title: string): ReactNode => (
  <div className={'mobile-menu-category__header'}>
    <MobileCategoryLogo />
    <span className={'mobile-menu-category__title'}>{title}</span>
  </div>
);

export const ManuCollapsedContent: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={'flex flex-col gap-2'}>{children}</div>;
};

const MobileSubmenuList: FC<{ subMenuList: Menu[] }> = ({ subMenuList }) => {
  const MobileSubCategoryElements = subMenuList.map((subcategory, i) => (
    <MobileSubCategory key={`${subcategory.id}-${i}`} id={subcategory.id} name={subcategory.name} />
  ));

  return <>{MobileSubCategoryElements}</>;
};

const Categories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFilteredMenu = useAppSelector((state) => state.clientSlice.isFilteredMenu);
  const isTakeAway = !!useAppSelector((state) => state.clientSlice.table.table_take_away_id);
  const { allergies, dishTypes } = useAppSelector((state) => state.clientSlice);
  const { data, isFetching, isError } = isFilteredMenu
    ? useGetMenusQuery({ allergies, dishTypes, isTakeAway })
    : useGetMenusQuery({ isTakeAway });

  let collapseItems: CollapseProps['items'];

  if (data) {
    collapseItems = data
      .filter((category) => category.level === 1)
      .map((item) => {
        const subcategories = data.filter((sub) => sub.level === 2 && item.id === sub.ancestor);

        return {
          key: `mobile-menu-${item.id}`,
          label: genHeader(item.name),
          children: (
            <ManuCollapsedContent>
              {subcategories && <MobileSubmenuList subMenuList={subcategories} />}
              <MobileMenuDishList categoryId={item.id} />
            </ManuCollapsedContent>
          ),
        };
      });
  }

  return (
    <>
      {isError && <span>Error getting data</span>}
      {isFetching && <span>{t('loading')}</span>}
      {!isFetching && data && (
        <ConfigProvider theme={mobileMenuCategoryCollapseTheme}>
          <Collapse className={'mobile-menu-category'} expandIconPosition={'end'} items={collapseItems} />
        </ConfigProvider>
      )}
      {!data ||
        (data?.length === 0 && !isFetching && (
          <div className="mobile-menu__no-dishes">
            {isFilteredMenu && isTakeAway && <p>No results, change your filter or choose no TakeAway table</p>}
            {isFilteredMenu && !isTakeAway && (
              <p>{t('there_no_items_to_display_for_you_based_on_preferences_you_marked')}</p>
            )}
            {!isFilteredMenu && isTakeAway && <p>There are no takeaway dishes</p>}
            {!isFilteredMenu && !isTakeAway && <p>There are no dishes yet</p>}
            {isFilteredMenu && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(setIsClientFilteredMenu(false));
                }}
              >
                {t('whole_menu')}
              </Button>
            )}
          </div>
        ))}
    </>
  );
};

export default Categories;
