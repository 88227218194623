import { useGetRestaurantTablesQuery } from '@api/restaurantInfoApi';
import { setClientTable } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import { Form, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const CartChangingTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { table_id } = useAppSelector((state) => state.clientSlice.table);
  const { data: tables = [], isFetching, isSuccess, isError } = useGetRestaurantTablesQuery();
  const handleChange = (id: number) => {
    const selectedTable = tables?.find((table) => table.id === id);

    if (selectedTable?.waiter) {
      dispatch(
        setClientTable({
          selected: true,
          table_id: id,
          table_take_away_id: null,
          table_name: selectedTable.name,
          waiter: selectedTable.waiter,
        })
      );
    }
  };

  return (
    <>
      {isError ? (
        <span>{t('error_getting_data')}</span>
      ) : isFetching ? (
        <span>{t('loading')}</span>
      ) : (
        isSuccess &&
        tables && (
          <Form.Item name="table" rules={[{ required: true }]} noStyle>
            <Select
              className={'block w-[110px] rounded !border !border-solid !border-grey-20'}
              onChange={handleChange}
              value={table_id}
              placeholder={t('select_table_placeholder')}
            >
              {tables.map((table) => (
                <Select.Option key={`table-id-${table.id}`} value={table.id}>
                  {table.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )
      )}
    </>
  );
};

export default CartChangingTable;
