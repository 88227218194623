import useMobileDishOptionValue from '@components/MobileMenu/MobileDishSettings/MobileDishOptionValue/useMobileDishOptionValue';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import { CURRENCY } from '@constants/constants';
import useCheckRole from '@hooks/useCheckRole';
import { Form } from 'antd';
import { FC } from 'react';

interface MobileDishOptionValueProps {
  id: number;
  fieldName: number;
  toggleName: string;
  price: number;
  className?: string;
  titleClassName?: string;
  priceClassName?: string;
}

const MobileDishOptionValue: FC<MobileDishOptionValueProps> = ({
  id,
  fieldName,
  toggleName,
  price,
  className,
  titleClassName,
  priceClassName,
}) => {
  const { isWaiter } = useCheckRole();
  const { updateAvailability } = useMobileDishOptionValue();

  const handleToggleChange = async (checked: boolean) => {
    await updateAvailability({
      id,
      is_readily_available: checked,
    });
  };

  return (
    <div className={`flex items-center justify-between gap-4 ${className}`}>
      {!isWaiter ? (
        <Form.Item noStyle name={[fieldName, 'is_readily_available']} initialValue valuePropName={'checked'}>
          <HorizontalSwitch label={toggleName} onChange={handleToggleChange} />
        </Form.Item>
      ) : (
        <span className={titleClassName}>{toggleName}</span>
      )}

      <span className={priceClassName}>
        {CURRENCY}
        {price}
      </span>
    </div>
  );
};

export default MobileDishOptionValue;
