import { Waiter } from '@api/models/Waiter';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import React, { FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import MyModal from '../../UI/MyModal';

interface FieldType {
  id?: number;
  name: string;
  user_id: number;
}

interface WaiterTablesModalProps {
  isModalOpen: boolean;
  setIsModalOpened: React.Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  handleCreate: (value: any) => void;
  handleUpdate: (value: any) => void;
  isLoading: boolean;
  waiters?: Waiter[] | null;
  isWaiters2Tables: boolean;
}

const WaiterTablesModal: FC<WaiterTablesModalProps> = ({
  isModalOpen,
  setIsModalOpened,
  form,
  handleCreate,
  handleUpdate,
  isLoading,
  waiters,
  isWaiters2Tables,
}) => {
  const { t } = useTranslation();

  const tableID = form.getFieldValue('id');

  const onFinish = (value: any) => {
    if (tableID) {
      handleUpdate(value);
    } else {
      handleCreate(value);
    }
  };
  const TableForm = () => (
    <Form form={form} name={'tables-form'} layout={'vertical'} onFinish={onFinish} className={'pb-10 pt-6'}>
      <Form.Item<FieldType> name="id" hidden>
        <Input />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item<FieldType>
            label={t('there_are_new_table')}
            name="name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('table_name_placeholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item<FieldType> label={t('waiter')} name={'user_id'}>
            <Select defaultValue={null}>
              <Select.Option key={0} value={null}>
                {t('no_one')}
              </Select.Option>
              {isWaiters2Tables &&
                waiters &&
                waiters
                  .filter((waiter) => waiter.is_active)
                  .map((waiter) => (
                    <Select.Option key={`waiter-id-${waiter.id}`} value={+waiter.id}>
                      {waiter.first_name} {waiter.last_name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <MyModal
      getContainer={false}
      title={t('table_details')}
      open={isModalOpen}
      onCancel={() => setIsModalOpened(false)}
      footer={
        <Button type={'primary'} loading={isLoading} onClick={() => form.submit()}>
          {t('save')}
        </Button>
      }
    >
      <TableForm />
    </MyModal>
  );
};

export default WaiterTablesModal;
