import { useAppSelector } from '@base/redux/store';

export interface AvailableFeatures {
  availableWorkers: string[];
  isBuildingMenu: boolean;
  isPushNotifications: boolean;
  isCall2Waiter: boolean;
  isClientFeedback: boolean;
  isSupport: boolean;
  isTips: boolean;
  isInventory: boolean;
  isReceiptTransactions: boolean;
  isOrderPlacementPayment: boolean;
  countMenuTemplates: number;
  isWaiters2Tables: boolean;
  isPersonalizedClientMenu: boolean;
  isRestaurantManagement: boolean;
}

const useAvailableFeatures = (): AvailableFeatures => {
  const features = useAppSelector(
    (state) =>
      state.restaurantSettingsState.package_payment?.package.availability_of_features ||
      state.restaurantState.package_payment?.package.availability_of_features
  );

  return {
    availableWorkers: features?.user_accounts || ['manager'],
    isBuildingMenu: features?.is_building_menu || false,
    isPushNotifications: features?.is_push_messages || false,
    isCall2Waiter: features?.is_call_to_waiter || false,
    isClientFeedback: features?.is_client_feedback || false,
    isSupport: features?.is_customer_support || false,
    isTips: features?.is_tipping_on_checkout || false,
    isInventory: features?.is_inventory_management || false,
    isReceiptTransactions: features?.is_bons_and_transactions || false,
    isOrderPlacementPayment: features?.is_placing_order_and_payment || false,
    countMenuTemplates: features?.count_designed_menu_templates || 1,
    isWaiters2Tables: features?.is_assigning_waiters_to_tables || false,
    isPersonalizedClientMenu: features?.is_personalized_menu_based_on_client || false,
    isRestaurantManagement: features?.is_restaurant_management_and_dashboard || false,
  };
};

export default useAvailableFeatures;
