import RestaurantExistence from '@base/hoc/RestaurantExistence';
import Promotions from '@components/Promotions';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const PromotionsPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.restaurant_promotions'));

  return (
    <RestaurantExistence>
      <Promotions />
    </RestaurantExistence>
  );
};

export default PromotionsPage;
