import { OnboardingKeys } from '@base/redux/features/userSlice';
import useTour from '@components/UI/Tour/useTour';
import useSteps from './useSteps';

const useWaiterCallsOnboarding = () => {
  const onboardingKey: OnboardingKeys = 'waiter_call_2_water';

  const { steps, obStepRef1 } = useSteps();
  const { isOnboard, isFinished, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
    isTourFinished: isFinished,
  };
};

export default useWaiterCallsOnboarding;
