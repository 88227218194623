import { SVGProps } from 'react';
const PackagePremiumLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={65} height={61} viewBox="0 0 65 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.7}
      d="M59.5823 56.3235C53.0254 62.5713 42.3774 62.5713 35.7925 56.3235L5.41769 27.3541C-1.13923 21.1063 -1.13923 10.9603 5.41769 4.68584C11.9746 -1.56195 22.6226 -1.56195 29.2075 4.68584L59.5823 33.6286C66.1392 39.903 66.1392 50.049 59.5823 56.3235Z"
      fill="url(#paint0_linear_9021_67727)"
    />
    <path
      opacity={0.7}
      d="M5.41769 56.3215C-1.13923 50.0709 -1.13923 39.9205 5.41769 33.6433L35.7925 4.68789C42.3494 -1.56263 52.9974 -1.56263 59.5823 4.68789C66.1392 10.9384 66.1392 21.0888 59.5823 27.366L29.2075 56.3215C22.6226 62.572 11.9746 62.572 5.41769 56.3215Z"
      fill="url(#paint1_linear_9021_67727)"
    />
    <path
      d="M32.8458 11.2229L33.7833 13.2101C33.8109 13.2692 33.852 13.3201 33.9029 13.3587C33.9539 13.3973 34.0128 13.4227 34.0749 13.432L36.1709 13.7483C36.242 13.7595 36.3083 13.7913 36.363 13.8402C36.4177 13.8886 36.4583 13.9521 36.4808 14.0235C36.5032 14.0949 36.506 14.1711 36.4892 14.2445C36.4724 14.3173 36.4364 14.3843 36.3854 14.4371L34.8708 15.9833C34.8259 16.0292 34.7923 16.0859 34.7727 16.1485C34.7535 16.2111 34.7488 16.2776 34.7596 16.3421L35.1176 18.5258C35.1297 18.6006 35.1218 18.6773 35.0947 18.7472C35.0675 18.8176 35.0217 18.8782 34.9633 18.9227C34.9049 18.9672 34.8353 18.9936 34.7633 18.999C34.6909 19.0043 34.6189 18.9887 34.5549 18.953L32.6804 17.9196C32.6248 17.8893 32.5626 17.8732 32.5 17.8732C32.4374 17.8732 32.3752 17.8893 32.3196 17.9196L30.4451 18.953C30.3811 18.9882 30.3091 19.0039 30.2367 18.9985C30.1647 18.9931 30.0956 18.9662 30.0371 18.9217C29.9787 18.8772 29.9334 18.8166 29.9058 18.7462C29.8787 18.6763 29.8708 18.5996 29.8829 18.5248L30.2404 16.3411C30.2512 16.2761 30.2465 16.2096 30.2273 16.1475C30.2077 16.085 30.1741 16.0283 30.1292 15.9823L28.6146 14.4366C28.5636 14.3838 28.5276 14.3168 28.5108 14.244C28.494 14.1711 28.4968 14.0944 28.5192 14.023C28.5417 13.9516 28.5823 13.8881 28.637 13.8397C28.6917 13.7913 28.7585 13.759 28.8291 13.7478L30.9251 13.4296C30.9872 13.4203 31.0461 13.3949 31.0971 13.3562C31.148 13.3176 31.1891 13.2668 31.2167 13.2076L32.1546 11.221C32.1874 11.1545 32.2369 11.0983 32.2981 11.0596C32.3589 11.02 32.4294 11 32.5009 11C32.5724 11 32.6425 11.021 32.7038 11.0606C32.7645 11.0997 32.8141 11.1559 32.8463 11.2229H32.8458Z"
      fill="white"
    />
    <path
      d="M21.7594 17.1672L22.4625 18.6576C22.4832 18.7019 22.514 18.74 22.5522 18.769C22.5904 18.798 22.6346 18.817 22.6812 18.824L24.2532 19.0612C24.3065 19.0696 24.3562 19.0935 24.3973 19.1301C24.4383 19.1664 24.4688 19.2141 24.4856 19.2676C24.5024 19.3212 24.5045 19.3784 24.4919 19.4333C24.4793 19.488 24.4523 19.5382 24.4141 19.5778L23.2781 20.7375C23.2445 20.7719 23.2192 20.8145 23.2045 20.8614C23.1901 20.9083 23.1866 20.9582 23.1947 21.0066L23.4632 22.6443C23.4723 22.7004 23.4663 22.758 23.446 22.8104C23.4257 22.8632 23.3913 22.9087 23.3475 22.942C23.3037 22.9754 23.2515 22.9952 23.1975 22.9992C23.1432 23.0033 23.0892 22.9915 23.0412 22.9648L21.6353 22.1897C21.5936 22.167 21.547 22.1549 21.5 22.1549C21.453 22.1549 21.4064 22.167 21.3647 22.1897L19.9588 22.9648C19.9108 22.9912 19.8568 23.0029 19.8025 22.9989C19.7485 22.9948 19.6967 22.9747 19.6529 22.9413C19.609 22.9079 19.575 22.8625 19.5544 22.8097C19.534 22.7572 19.5281 22.6997 19.5372 22.6436L19.8053 21.0058C19.8134 20.9571 19.8099 20.9072 19.7955 20.8607C19.7808 20.8137 19.7555 20.7712 19.7219 20.7367L18.5859 19.5774C18.5477 19.5378 18.5207 19.4876 18.5081 19.433C18.4955 19.3784 18.4976 19.3208 18.5144 19.2673C18.5312 19.2137 18.5617 19.1661 18.6027 19.1298C18.6438 19.0935 18.6939 19.0693 18.7468 19.0608L20.3188 18.8222C20.3654 18.8152 20.4096 18.7961 20.4478 18.7672C20.486 18.7382 20.5168 18.7001 20.5375 18.6557L21.241 17.1657C21.2655 17.1159 21.3027 17.0737 21.3486 17.0447C21.3941 17.015 21.4471 17 21.5007 17C21.5543 17 21.6069 17.0158 21.6528 17.0455C21.6984 17.0748 21.7355 17.117 21.7597 17.1672H21.7594Z"
      fill="white"
    />
    <path
      d="M43.7594 17.1672L44.4625 18.6576C44.4832 18.7019 44.514 18.74 44.5522 18.769C44.5904 18.798 44.6346 18.817 44.6812 18.824L46.2532 19.0612C46.3065 19.0696 46.3562 19.0935 46.3973 19.1301C46.4383 19.1664 46.4688 19.2141 46.4856 19.2676C46.5024 19.3212 46.5045 19.3784 46.4919 19.4333C46.4793 19.488 46.4523 19.5382 46.4141 19.5778L45.2781 20.7375C45.2445 20.7719 45.2192 20.8145 45.2045 20.8614C45.1901 20.9083 45.1866 20.9582 45.1947 21.0066L45.4632 22.6443C45.4723 22.7004 45.4663 22.758 45.446 22.8104C45.4257 22.8632 45.3913 22.9087 45.3475 22.942C45.3037 22.9754 45.2515 22.9952 45.1975 22.9992C45.1432 23.0033 45.0892 22.9915 45.0412 22.9648L43.6353 22.1897C43.5936 22.167 43.547 22.1549 43.5 22.1549C43.453 22.1549 43.4064 22.167 43.3647 22.1897L41.9588 22.9648C41.9108 22.9912 41.8568 23.0029 41.8025 22.9989C41.7485 22.9948 41.6967 22.9747 41.6529 22.9413C41.609 22.9079 41.575 22.8625 41.5544 22.8097C41.534 22.7572 41.5281 22.6997 41.5372 22.6436L41.8053 21.0058C41.8134 20.9571 41.8099 20.9072 41.7955 20.8607C41.7808 20.8137 41.7555 20.7712 41.7219 20.7367L40.5859 19.5774C40.5477 19.5378 40.5207 19.4876 40.5081 19.433C40.4955 19.3784 40.4976 19.3208 40.5144 19.2673C40.5312 19.2137 40.5617 19.1661 40.6027 19.1298C40.6438 19.0935 40.6939 19.0693 40.7468 19.0608L42.3188 18.8222C42.3654 18.8152 42.4096 18.7961 42.4478 18.7672C42.486 18.7382 42.5168 18.7001 42.5375 18.6557L43.241 17.1657C43.2655 17.1159 43.3027 17.0737 43.3486 17.0447C43.3941 17.015 43.4471 17 43.5007 17C43.5543 17 43.6069 17.0158 43.6528 17.0455C43.6984 17.0748 43.7355 17.117 43.7597 17.1672H43.7594Z"
      fill="white"
    />
    <g clipPath="url(#clip0_9021_67727)">
      <path
        d="M26.6805 40.9141H28.1403L29.1573 44.1904L30.1612 40.9141H31.5782L29.9005 45.4664H28.3871L26.6805 40.9141Z"
        fill="white"
      />
      <path d="M32.09 40.9141H33.4892V45.4664H32.09V40.9141Z" fill="white" />
      <path
        d="M34.4848 40.9145H36.8061C37.3118 40.9145 37.6903 41.0356 37.9418 41.2776C38.1937 41.5197 38.3194 41.8645 38.3194 42.3116C38.3194 42.7587 38.182 43.1302 37.9078 43.3892C37.6335 43.6481 37.2149 43.7771 36.6519 43.7771H35.8873V45.4664H34.4848V40.9141V40.9145ZM35.8873 42.8553H36.2296C36.4987 42.8553 36.6878 42.8084 36.7967 42.7141C36.9057 42.6198 36.9602 42.4992 36.9602 42.3524C36.9602 42.2056 36.9127 42.0883 36.8186 41.9888C36.7241 41.8894 36.5462 41.8396 36.2855 41.8396H35.8878V42.8553H35.8873Z"
        fill="white"
      />
      <path
        d="M32.4997 29.8408C33.3844 29.8408 34.1015 29.1183 34.1015 28.2271C34.1015 27.3358 33.3844 26.6133 32.4997 26.6133C31.6151 26.6133 30.8979 27.3358 30.8979 28.2271C30.8979 29.1183 31.6151 29.8408 32.4997 29.8408Z"
        fill="white"
      />
      <path d="M32.4997 36.1035L38.9569 37.1741L38.5159 38.3956H26.484L26.0431 37.1741L32.4997 36.1035Z" fill="white" />
      <path
        d="M43.2799 26.4473L42.879 27.56L39.0942 36.1665L32.5319 35.0786L32.4997 35.0735L25.9057 36.1665L22.1209 27.56L21.72 26.4473C21.7335 26.4698 21.9663 26.8629 22.3677 27.4249C24.0026 29.7147 28.4281 34.8056 32.1337 29.0068C32.2571 28.8145 32.3791 28.6099 32.5002 28.3927C32.6217 28.6099 32.7437 28.8145 32.8667 29.0068C36.5723 34.8056 40.9978 29.7147 42.6327 27.4249C43.0336 26.8629 43.2669 26.4698 43.2804 26.4473H43.2799Z"
        fill="white"
      />
      <path
        d="M44.2928 27.8853C44.9184 27.255 44.9184 26.2332 44.2928 25.603C43.6673 24.9728 42.6531 24.9728 42.0275 25.603C41.4019 26.2332 41.4019 27.255 42.0275 27.8853C42.6531 28.5155 43.6673 28.5155 44.2928 27.8853Z"
        fill="white"
      />
      <path
        d="M22.2082 28.3155C23.0692 28.1107 23.6024 27.2416 23.3992 26.3741C23.196 25.5067 22.3333 24.9695 21.4723 25.1742C20.6113 25.379 20.078 26.2481 20.2812 27.1155C20.4844 27.983 21.3472 28.5202 22.2082 28.3155Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9021_67727"
        x1={38.0547}
        y1={3.57715}
        x2={27.9535}
        y2={57.6168}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9021_67727"
        x1={60.7479}
        y1={35.7762}
        x2={4.43738}
        y2={24.1881}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <clipPath id="clip0_9021_67727">
        <rect width={24.5234} height={20.3364} fill="white" transform="translate(20.2383 25.1309)" />
      </clipPath>
    </defs>
  </svg>
);
export default PackagePremiumLogo;
