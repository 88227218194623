import ForgotPasswordForm from '@base/components/AuthForm/ForgotPasswordForm';
// import { useAppSelector } from '@base/redux/store';
// import AuthRestaurantInfo from '@components/AuthRestaurantInfo';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  // const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);

  useSetTitlePage(t('pages.forgot_password'));

  // TODO: decide what to do with AuthRestaurantInfo - remove AuthRestaurantInfo
  return (
    // <AuthForm sideBarContent={isSubdomain && <AuthRestaurantInfo />}>
    //   <ForgotPasswordForm />
    // </AuthForm>
    <AuthForm>
      <ForgotPasswordForm type="manager" />
    </AuthForm>
  );
};

export default ForgotPasswordPage;
