import themeImage1 from '@assets/img/layout-preview-1.jpg';
import themeImage2 from '@assets/img/layout-preview-2.jpg';
import themeImage3 from '@assets/img/layout-preview-3.jpg';
import useAvailableFeatures from '@hooks/useAvailableFeatures';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const RestaurantLayoutChooser = () => {
  const { t } = useTranslation();
  const { countMenuTemplates } = useAvailableFeatures();

  return (
    <div className={'flex flex-wrap justify-center gap-x-14 gap-y-6'}>
      <h5 className={'mb-0 basis-full text-center text-base font-bold leading-[120%]'}>
        {t('choose_restaurant_menu_template')}
      </h5>
      <div className={'relative flex h-[359px] w-[204px] items-end justify-center'}>
        <Radio value={1}>
          <img src={themeImage1} className={'absolute left-0 top-0 h-[327px] w-[204px] object-cover'} alt={t('classic')} />
          {t('classic')}
        </Radio>
      </div>

      {countMenuTemplates > 1 && (
        <>
          <div className={'relative flex h-[359px] w-[204px] items-end justify-center'}>
            <Radio value={2}>
              <img src={themeImage2} className={'absolute left-0 top-0 h-[327px] w-[204px] object-cover'} alt={t('mediterranean')} />
              {t('mediterranean')}
            </Radio>
          </div>
          <div className={'relative flex h-[359px] w-[204px] items-end justify-center'}>
            <Radio value={3}>
              <img src={themeImage3} className={'absolute left-0 top-0 h-[327px] w-[204px] object-cover'} alt={t('lilly')} />
              {t('lilly')}
            </Radio>
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantLayoutChooser;
