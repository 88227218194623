import { useChangePositionDishMutation, useGetAllDishesQuery } from '@api/dishApi';
import { useChangePositionMenuMutation, useGetMenusQuery } from '@api/menuApi';
import { Menu, MenuDish } from '@api/models/Menu';
import { useAppSelector } from '@base/redux/store';
import {
  DND_CATEGORY_TYPE,
  DND_DISH_TYPE,
  DND_SUBCATEGORY_TYPE,
} from '@components/Menus/Dnd/DndMenuCategories/DndMenuCategories';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DndMenu extends Menu {
  dnd_id: string;
  dnd_ancestor_id?: string;
}

export interface DndDish extends MenuDish {
  dnd_id: string;
  dnd_ancestor_id?: string;
}

const useDndMenuCategories = () => {
  const { t } = useTranslation();
  const restID = useAppSelector((state) => state.restaurantSettingsState.id);

  const [categories, setCategories] = useState<DndMenu[] | []>([]);
  const [activeCategory, setActiveCategory] = useState<DndMenu | null>(null);

  const [subcategories, setSubcategories] = useState<DndMenu[] | []>([]);
  const [activeSubcategory, setActiveSubcategory] = useState<DndMenu | null>(null);

  const [dishes, setDishes] = useState<DndDish[]>([]);
  const [activeDish, setActiveDish] = useState<DndDish | null>(null);

  const [activeIndexElement, setActiveIndexElement] = useState<number | null>(null);

  const { data = [], isLoading, isFetching, isError } = useGetMenusQuery({});

  const {
    data: allDishes = [],
    isLoading: isLoadingDishes,
    isFetching: isFetchingDishes,
  } = useGetAllDishesQuery(restID, { skip: !restID });

  const [changePosition] = useChangePositionMenuMutation();
  const [changeDishPosition] = useChangePositionDishMutation();

  function handleDragStart(event: DragStartEvent) {
    setActiveIndexElement(null);
    setActiveCategory(null);
    setActiveSubcategory(null);
    setActiveDish(null);

    const active = event?.active?.data?.current;

    if (active) {
      setActiveIndexElement(active?.sortable.index);
    }

    if (event?.active?.data?.current?.type === DND_CATEGORY_TYPE) {
      setActiveCategory(event.active.data.current.category);

      return;
    }

    if (event.active.data.current?.type === DND_SUBCATEGORY_TYPE) {
      setActiveSubcategory(event.active.data.current.subcategory);

      return;
    }

    if (event.active.data.current?.type === DND_DISH_TYPE) {
      setActiveDish(event.active.data.current.dish);

      return;
    }
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    const prevIndexElement = activeIndexElement;
    const newIndexElement = active.data.current?.sortable?.index;

    // if position did not change
    if (activeId === overId && prevIndexElement === newIndexElement) {
      return;
    }

    const isActiveACategory = active.data.current?.type === DND_CATEGORY_TYPE;
    const isOverACategory = over.data.current?.type === DND_CATEGORY_TYPE;

    const isActiveASubcategory = active.data.current?.type === DND_SUBCATEGORY_TYPE;
    const isOverASubcategory = over.data.current?.type === DND_SUBCATEGORY_TYPE;

    const isActiveADish = active.data.current?.type === DND_DISH_TYPE;
    const isOverADish = over.data.current?.type === DND_DISH_TYPE;

    if (!isActiveADish && !isActiveASubcategory && !isActiveACategory) {
      return;
    }

    // Im dropping a Dish over a Subcategory
    if (isActiveADish && isOverASubcategory) {
      const overSubcategory = over.data.current?.subcategory;
      const countDishInOverSubcategory = dishes.filter(
        (dish) => dish.dnd_ancestor_id === overSubcategory.dnd_id
      ).length;

      const newIndex = countDishInOverSubcategory + 1;

      await setDishes((dishes) => {
        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);

        dishes[activeIndex].dnd_ancestor_id = overId as string; // dnd_id of the subcategory
        dishes[activeIndex].menu = {
          ...dishes[activeIndex].menu,
          id: overSubcategory.id,
          name: overSubcategory.name,
        };

        return arrayMove(dishes, activeIndex, newIndex);
      });

      updateDishPosition(newIndex);
    }

    // If sorting a Dish over another Dish
    if (isActiveADish && isOverADish) {
      updateDishPosition();
    }

    // Im dropping a Dish over a Category
    if (isActiveADish && isOverACategory) {
      const overCategory = over.data.current?.category;
      const countDishInOverCategory = dishes.filter((dish) => dish.dnd_ancestor_id === overCategory.dnd_id).length;

      const newIndex = countDishInOverCategory + 1;

      await setDishes((dishes) => {
        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);

        dishes[activeIndex].dnd_ancestor_id = overId as string;
        dishes[activeIndex].menu = {
          ...dishes[activeIndex].menu,
          id: overCategory.ancestor,
          name: overCategory.name,
        };

        return arrayMove(dishes, activeIndex, newIndex);
      });

      updateDishPosition(newIndex);
    }

    // If sorting the Subcategory over the Subcategory
    if (isActiveASubcategory && isOverASubcategory) {
      updateSubcategoryPosition();
    }

    // Im dropping a Subcategory over a Category
    if (isActiveASubcategory && isOverACategory) {
      const overCategory = over.data.current?.category;
      const countSubInOverCategory = subcategories.filter(
        (subcategories) => subcategories.dnd_ancestor_id === overCategory.dnd_id
      ).length;

      const newIndex = countSubInOverCategory + 1;

      await setSubcategories((subcategories) => {
        const activeIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === activeId);

        subcategories[activeIndex].dnd_ancestor_id = overId as string;
        subcategories[activeIndex].ancestor = overCategory.ancestor;

        return arrayMove(subcategories, activeIndex, newIndex);
      });

      updateSubcategoryPosition(newIndex);
    }

    // If sorting Categories
    if (isActiveACategory) {
      updateCategoryPositions();
    }
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) {
      return;
    }

    const isActiveACategory = active.data.current?.type === DND_CATEGORY_TYPE;

    const isActiveASubcategory = active.data.current?.type === DND_SUBCATEGORY_TYPE;
    const isOverASubcategory = over.data.current?.type === DND_SUBCATEGORY_TYPE;

    const isActiveADish = active.data.current?.type === DND_DISH_TYPE;
    const isOverADish = over.data.current?.type === DND_DISH_TYPE;

    // Im sorting a Dish over another Dish
    if (isActiveADish && isOverADish) {
      setDishes((dishes) => {
        const overCategory = over.data.current?.category;

        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);
        const overIndex = dishes.findIndex((dish) => dish.dnd_id === overId);

        // sorting in another category
        if (dishes[activeIndex].dnd_ancestor_id !== dishes[overIndex].dnd_ancestor_id) {
          dishes[activeIndex].dnd_ancestor_id = dishes[overIndex].dnd_ancestor_id;
          dishes[activeIndex].menu = {
            ...dishes[activeIndex].menu,
            id: overCategory.ancestor,
            name: overCategory.name,
          };

          return arrayMove(dishes, activeIndex, overIndex - 1);
        }

        dishes[activeIndex].menu = {
          ...dishes[activeIndex].menu,
          id: dishes[overIndex].menu.id,
          name: dishes[overIndex].menu.name,
        };

        return arrayMove(dishes, activeIndex, overIndex);
      });
    }

    // Im sorting a Subcategory over another Subcategory
    if (isActiveASubcategory && isOverASubcategory) {
      setSubcategories((subcategories) => {
        const activeIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === activeId);
        const overIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === overId);

        // sorting in another category
        if (subcategories[activeIndex].dnd_ancestor_id !== subcategories[overIndex].dnd_ancestor_id) {
          subcategories[activeIndex].dnd_ancestor_id = subcategories[overIndex].dnd_ancestor_id;

          subcategories[activeIndex].ancestor = subcategories[overIndex].ancestor;

          return arrayMove(subcategories, activeIndex, overIndex - 1);
        }

        return arrayMove(subcategories, activeIndex, overIndex);
      });
    }

    // Im sorting Categories
    if (isActiveACategory) {
      setCategories((categories) => {
        const activeColumnIndex = categories.findIndex((category) => category.dnd_id === activeId);

        const overColumnIndex = categories.findIndex((category) => category.dnd_id === overId);

        return arrayMove(categories, activeColumnIndex, overColumnIndex);
      });
    }
  }

  function updateCategoryPositions() {
    if (activeCategory) {
      const indexDroppedCategory = categories.findIndex((category) => category.id === activeCategory.id);

      if (indexDroppedCategory === 0 || indexDroppedCategory > 0) {
        changePosition({
          id: activeCategory.id,
          data: {
            level: activeCategory.level,
            ancestor: activeCategory.ancestor,
            position: indexDroppedCategory + 1,
          },
        });
      }
    }
  }

  function updateSubcategoryPosition(position?: number) {
    if (activeSubcategory) {
      const droppedSubcategory = activeSubcategory;
      const allSiblings = subcategories.filter((subcategory) => subcategory.ancestor === activeSubcategory.ancestor);
      const newIndexDroppedSubcategory = allSiblings.findIndex(
        (subcategory) => subcategory.id === activeSubcategory.id
      );

      const newPosition = position !== undefined ? position : newIndexDroppedSubcategory + 1;

      if (newIndexDroppedSubcategory === 0 || newIndexDroppedSubcategory > 0) {
        changePosition({
          id: droppedSubcategory.id,
          data: {
            level: droppedSubcategory.level,
            ancestor: droppedSubcategory.ancestor,
            position: newPosition,
          },
        });
      }
    }
  }

  function updateDishPosition(position?: number) {
    if (activeDish) {
      const droppedDish = activeDish;
      const allSiblings = dishes.filter((dish) => dish.menu.id === activeDish.menu.id);

      const newIndexDroppedDish = allSiblings.findIndex((dish) => dish.id === activeDish.id);

      const newPosition = position !== undefined ? position : newIndexDroppedDish + 1;

      if (newIndexDroppedDish === 0 || newIndexDroppedDish > 0) {
        changeDishPosition({
          id: droppedDish.id,
          data: {
            menu_id: droppedDish.menu.id,
            position: newPosition,
          },
        });
      }
    }
  }

  // init dnd categories and subcategories
  useLayoutEffect(() => {
    if (!isFetching && data.length > 0) {
      const categories = data.filter((category) => category.level === 1);
      const subcategories = data.filter((category) => category.level === 2);

      const dndCategories: DndMenu[] = categories.map((category) => ({
        ...category,
        dnd_id: `${DND_CATEGORY_TYPE}_${category.id}`,
      }));

      const dndSubcategories: DndMenu[] = subcategories.map((subcategory) => ({
        ...subcategory,
        dnd_id: `${DND_SUBCATEGORY_TYPE}_${subcategory.id}`,
        dnd_ancestor_id: `${DND_CATEGORY_TYPE}_${subcategory.ancestor}`,
      }));

      setCategories(dndCategories);
      setSubcategories(dndSubcategories);
    } else if (!isFetching && categories.length) {
      setCategories([]);
    }
  }, [isFetching, data]);

  // init dnd dishes
  useLayoutEffect(() => {
    if (!isFetchingDishes && categories && allDishes.length > 0) {
      const dndDishes = allDishes.map((dish) => {
        const isAncestorCategory = !!categories.find((category) => category.id === dish.menu.id);

        const dnd_ancestor_id = isAncestorCategory
          ? `${DND_CATEGORY_TYPE}_${dish.menu.id}`
          : `${DND_SUBCATEGORY_TYPE}_${dish.menu.id}`;

        return {
          ...dish,
          dnd_id: `${DND_DISH_TYPE}_${dish.id}`,
          dnd_ancestor_id,
        };
      });

      // sort dishes by position and menu_id
      dndDishes.sort((a, b) => {
        if (a.menu.id === b.menu.id) {
          return a.position - b.position;
        }

        return a.menu.id - b.menu.id;
      });

      setDishes(dndDishes);
    }
  }, [isFetchingDishes, categories, allDishes]);

  const categoriesDndIDs = categories.map((category) => category.dnd_id);

  return {
    t,
    categories,
    categoriesDndIDs,
    activeCategory,

    subcategories,
    activeSubcategory,

    dishes,
    activeDish,

    isLoading: isLoading || isLoadingDishes,
    isError,

    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};

export default useDndMenuCategories;
