import ForgotPasswordForm from '@base/components/AuthForm/ForgotPasswordForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';

const ClientForgotPasswordPage = () => {
  const { t } = useTranslation();

  useSetTitlePage(t('pages.forgot_password'));

  return (
    <AuthForm>
      <ForgotPasswordForm type="client" />
    </AuthForm>
  );
};

export default ClientForgotPasswordPage;
