import { apiCentral } from '@api/central-api';
import { Restaurant } from '@api/models/Restaurant';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';

export const extendedApiSlice = apiCentral
  .enhanceEndpoints({
    addTagTypes: ['RestaurantSettings'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSettings: builder.query<Restaurant, number | string | null>({
        query: (id) => addTenantToEndpoint(`/restaurant/${id}`),
        transformResponse: (response: { data: Restaurant }) => {
          const modifiedData: Restaurant = {
            ...response.data,
            work_hours: Object.fromEntries(
              Object.entries(response.data.work_hours).map(([day, { to, from, day_off }]) => [
                day,
                {
                  to: to || '00:00',
                  from: from || '00:00',
                  day_off,
                },
              ])
            ),
          };

          return modifiedData;
        },
        providesTags: () => ['RestaurantSettings'],
      }),
      createRestaurant: builder.mutation<Restaurant, any>({
        query(data) {
          const formData = new FormData();

          if (data.profile_picture) {
            formData.append('profile_picture', data.profile_picture);
          }

          if (data.background_picture) {
            formData.append('background_picture', data.background_picture);
          }

          const { profile_picture, background_picture, hero, logo, ...restData } = data;
          formData.append('data', JSON.stringify(restData));

          return {
            url: '/restaurant',
            method: 'POST',
            body: formData,
            formData: true,
          };
        },
        transformResponse: (response: { data: Restaurant }) => response.data,
        invalidatesTags: ['RestaurantSettings'],
      }),
      updateRestaurant: builder.mutation<Restaurant, { id: number | null; data: any }>({
        query({ id, data }) {
          return {
            url: `/restaurant/${id}`,
            method: 'PUT',
            body: data,
          };
        },
        transformResponse: (response: { data: Restaurant }) => response.data,
        invalidatesTags: ['RestaurantSettings'],
      }),

      updateImagesRestaurant: builder.mutation<any, { id: number; data: any }>({
        query({ id, data }) {
          const formData = new FormData();

          if (data.profile_picture) {
            formData.append('profile_picture', data.profile_picture);
          }

          if (data.background_picture) {
            formData.append('background_picture', data.background_picture);
          }

          return {
            url: `/restaurant/image/update/${id}`,
            method: 'POST',
            body: formData,
          };
        },
        invalidatesTags: ['RestaurantSettings'],
      }),
    }),
  });

export const {
  useGetSettingsQuery,
  useCreateRestaurantMutation,
  useUpdateRestaurantMutation,
  useUpdateImagesRestaurantMutation,
} = extendedApiSlice;
