import { useAppSelector } from '@base/redux/store';
import DecodedGoogleCredentials from '@base/types/DecodedGoogleCredentials';
import GoogleCredentials from '@base/types/GoogleCredentials';
import GoogleRegistrationForm from '@components/AuthForm/RegistrationForm/GoogleRegistrationForm';
import SimpleRegistrationForm from '@components/AuthForm/RegistrationForm/SimpleRegistrationForm';
import GoogleAuthButton from '@components/UI/GoogleAuthButton';
import { ROUTES } from '@constants/routes';
import { CredentialResponse } from '@react-oauth/google';
import { Button, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SuccessfulRegistration from '../SuccessfulRegistration';

const RegistrationForm = () => {
  const { t } = useTranslation();
  const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);

  const [regSuccess, setRegSuccess] = useState(false);
  const [googleCredentials, setGoogleCredentials] = useState<GoogleCredentials | null>(null);

  const onSuccess = async (response: CredentialResponse) => {
    const decodedCredentials: DecodedGoogleCredentials | null = response.credential
      ? jwtDecode(response.credential)
      : null;

    if (!decodedCredentials) {
      return;
    }

    setGoogleCredentials({
      email: decodedCredentials.email,
      family_name: decodedCredentials.family_name,
      given_name: decodedCredentials.given_name,
      google_accessor: decodedCredentials.sub,
    });
  };

  const onError = () => {
    console.error('Login Failed');
  };

  const handleBackStep = () => {
    setGoogleCredentials(null);
  };

  return (
    <>
      {regSuccess ? (
        <SuccessfulRegistration />
      ) : (
        <div className={`flex flex-col gap-4 p-6 lg:w-full lg:px-14 lg:py-10 ${googleCredentials ? 'm-auto' : ''}`}>
          <header>
            <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('signup')}</div>
            <p className={'mb-0'}>{t('signup_info')}</p>
          </header>

          {googleCredentials ? (
            <GoogleRegistrationForm setRegSuccess={setRegSuccess} googleCredentials={googleCredentials} />
          ) : (
            <SimpleRegistrationForm setRegSuccess={setRegSuccess} />
          )}

          {!googleCredentials && (
            <div className={'flex flex-col items-center gap-3 border-0 border-t border-solid border-grey-15 pt-3'}>
              <span>{t('or')}</span>
              <GoogleAuthButton onSuccess={onSuccess} onError={onError} />
            </div>
          )}

          {googleCredentials && (
            <Button type={'text'} className={'justify-center'} onClick={handleBackStep}>
              {t('reset')}
            </Button>
          )}

          <footer className={'py-4 text-center'}>
            <Space wrap size={'middle'}>
              <span className={'text-grey-40'}>{t('have_you_registered_yet')}</span>
              <Link to={isSubdomain ? ROUTES.worker_login : ROUTES.login} className={'underline underline-offset-4'}>
                {t('login')}
              </Link>
            </Space>
          </footer>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
