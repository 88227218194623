import MBBurgerIcon from '@components/icons/MBBurgerIcon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DishType, DND_DISH_TYPE } from '@pages/Components/DndMenu/DndMenu';
import { FC } from 'react';

interface DishCardProps {
  dish: DishType;
}

const DndDish: FC<DishCardProps> = ({ dish }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: dish.dnd_id,
    data: {
      type: DND_DISH_TYPE,
      dish,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (isDragging) {
    return (
      <div ref={setNodeRef} style={style} className={'flex items-center justify-center'}>
        <div className={'mr-[24px] h-[40px] w-full border-2 border-solid border-grey-40 bg-red-20 p-2 opacity-60'} />
      </div>
    );
  }

  return (
    <div ref={setNodeRef} style={style} className={'flex items-center gap-4'}>
      <MBBurgerIcon {...attributes} {...listeners} className={'flex-shrink-0 grow-0'} />
      <div className={'h-[40px] w-full bg-red-20 p-2'}>{dish.name}</div>
    </div>
  );
};

export default DndDish;
