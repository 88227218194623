import DishOption from '@api/models/DishOption';
import UserAccess from '@base/hoc/UserAccess';
import useOptionCategory from '@components/Menus/DishForm/DishFormOptions/OptionCategory/useOptionCategory';
import { OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import ApplyIconButton from '@components/UI/ApplyIconButton';
import EditIconButton from '@components/UI/EditIconButton';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import useCheckRole from '@hooks/useCheckRole';
import { Form, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OptionCategoryProps {
  temp_id?: string;
  id: number;
  fieldName: number;
  isEditMode: boolean;
  updateOptionsState: () => void;
  remove: (name: number) => void;
}

const OptionCategory: FC<OptionCategoryProps> = ({
  temp_id,
  id,
  fieldName,
  isEditMode,
  updateOptionsState,
  remove,
}) => {
  const { t } = useTranslation();
  const { isManager } = useCheckRole();
  const form = useFormInstance();
  const {
    crateOption,
    createdOption,
    isLoadingCreate,
    isSuccessCreate,
    updateOption,
    isLoadingUpdateOption,
    isSuccessUpdate,
    removeOption,
    isLoading,
    isLoadingRemoveOption,
  } = useOptionCategory();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setIsEditing(isEditMode);
  }, [isEditMode]);

  // update DishOption form input value;
  useEffect(() => {
    if (isSuccessCreate && createdOption) {
      const option = form.getFieldValue(OPTIONS_NAME_FIELD);

      const newOpts = option.map((opt: DishOption) => {
        if (opt.temp_id === temp_id) {
          return {
            ...opt,
            id: createdOption.id,
          };
        }

        return opt;
      });

      form.setFieldValue(OPTIONS_NAME_FIELD, newOpts);
      updateOptionsState();
      setIsEditing(false);
    }
  }, [isLoadingCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      updateOptionsState();
      setIsEditing(false);
    }
  }, [isLoadingUpdateOption]);

  const handleApply = async () => {
    const name = inputRef.current && inputRef.current.input.value;

    if (name) {
      const dish_id: number = form.getFieldValue('id');

      // work on API
      if (dish_id) {
        if (id) {
          await updateOption({ id, name });
        } else {
          await crateOption({ name, dish_id });
        }
      } else {
        await updateOptionsState();
      }

      await setIsEditing(false);
    }
  };

  const handleRemove = async () => {
    const dish_id: number = form.getFieldValue('id');

    if (dish_id) {
      await removeOption(id);
      await remove(fieldName);
      updateOptionsState();
    } else {
      await remove(fieldName);
      updateOptionsState();
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <div className={'flex w-full gap-4'}>
        <UserAccess roles={['restaurant-manager']}>
          <RemoveIconButton
            className={'mt-7 self-start text-lg'}
            onClick={handleRemove}
            loading={isLoadingRemoveOption}
          />

          {isEditing ? (
            <ApplyIconButton className={'mt-7 self-start text-lg'} onClick={handleApply} loading={isLoading} />
          ) : (
            <EditIconButton className={'mt-7 self-start text-lg'} onClick={handleEdit} />
          )}
        </UserAccess>

        <Form.Item name={[fieldName, 'temp_id']} initialValue={temp_id} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label={`${t('option')} ${fieldName + 1}`}
          name={[fieldName, 'name']}
          className={'grow'}
          rules={[{ required: true, message: '' }]}
        >
          <Input
            ref={inputRef}
            placeholder={t('option_name_placeholder')}
            disabled={!isManager}
            readOnly={!isEditing}
            autoFocus
            bordered={isEditing}
            className={'!bg-bg-input'}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default OptionCategory;
