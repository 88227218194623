import { PromotionDishSelectedExtras } from '@api/models/Promotion';
import { CURRENCY } from '@constants/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ClientPromotionsExtras: FC<{ extras: PromotionDishSelectedExtras[] }> = ({ extras }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={'dish-settings__title'}>{t('extras')}</div>
      <div className={'flex flex-col gap-6'}>
        {extras.map((extra) => (
          <div
            key={extra.id}
            className={'dish-settings-promotion__item flex justify-between gap-2 rounded bg-grey-5 p-4'}
          >
            <span>{extra.name}</span>
            <span>
              {CURRENCY}
              {extra.price}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientPromotionsExtras;
