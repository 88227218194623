import { useRegisterWorkerMutation } from '@api/authApi';
import { WorkerRegistration } from '@api/models/Worker';
import { useAppSelector } from '@base/redux/store';
import useAuth from '@hooks/useAuth';
import { regExPassword } from '@utils/constants/regexp';
import { ROUTES } from '@utils/constants/routes';
import { App, Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import SuccessfulRegistration from '../SuccessfulRegistration';

interface FieldType extends WorkerRegistration {
  agreed: string;
}

const WorkerRegistrationForm = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [form] = useForm();
  const { isAuth } = useAuth();
  const [regSuccess, setRegSuccess] = useState(false);
  const [registerWorker, { isLoading, isSuccess, isError }] = useRegisterWorkerMutation();
  const { registrationEmail, registrationRole } = useAppSelector((state) => state.workerSlice);

  useLayoutEffect(() => {
    if (isAuth) {
      navigate(ROUTES.dashboard);
    }
  }, []);

  useEffect(() => {
    form.setFieldValue('email', registrationEmail);
    form.setFieldValue('role', registrationRole);
  }, [registrationEmail, registrationRole]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setRegSuccess(true);
    }

    if (!isLoading && isError) {
      message.error(t('server_errors.server_error'));
    }
  }, [isLoading]);

  const onFinish = (values: any) => {
    registerWorker(values);
  };

  return (
    <>
      {regSuccess ? (
        <SuccessfulRegistration loginLink={ROUTES.worker_login} />
      ) : (
        <>
          <div className={' p-6 lg:mx-auto lg:box-content lg:max-w-[400px] lg:px-10 lg:py-14'}>
            <div className={'flex flex-col gap-10'}>
              <header>
                <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('enrollment')}</div>
                <div className={'bg-bg-input p-2'}>
                  <div className={'m-0 mb-1 flex gap-1'}>
                    <div className={'text-grey-30'}>{t('email')}:</div>
                    <div>{registrationEmail}</div>
                  </div>
                  <div className={'m-0 flex gap-1'}>
                    <div className={'text-grey-30'}>{t('name_of_position')}:</div>
                    <div>{registrationRole}</div>
                  </div>
                </div>
              </header>
              <Form
                form={form}
                name="register-supervisor"
                layout="vertical"
                initialValues={{ remember: false }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item<FieldType> name={'email'} hidden>
                  <Input />
                </Form.Item>
                <Form.Item<FieldType> name={'role'} hidden>
                  <Input />
                </Form.Item>
                <div className={'mb-2 text-lg'}>{t('you_need_create_pass')}</div>

                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item<FieldType>
                      label={t('password')}
                      name="password"
                      rules={[
                        { required: true, pattern: regExPassword, message: t('input_errors.password_validation') },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <Form.Item<FieldType>
                      label={t('password_verify')}
                      name="password_confirmation"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: t('required_field'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(new Error(t('input_errors.password_not_match')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item<FieldType>
                  name="agreed"
                  valuePropName="checked"
                  className={'mb-0 mt-5 pb-4'}
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
                    },
                  ]}
                >
                  <Checkbox className={'leading-[1.5]'}>
                    <div className={'pb-2 text-xs'}>
                      {t('agree_to_the')} <Link to={'#'}>{t('terms_of_use')}</Link> |{' '}
                      <Link to={'#'}>{t('privacy_policy')}</Link>
                      <span className={'px-1 text-lg font-bold leading-none text-cyan-30'}>*</span>
                    </div>
                  </Checkbox>
                </Form.Item>

                <Form.Item className={'mb-0'}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={'w-full justify-center lg:w-[206px]'}
                    loading={isLoading}
                  >
                    {t('signup')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <footer className={'py-4 text-center lg:mt-10'}>
              <Space wrap size={'middle'}>
                <span className={'text-grey-40'}>{t('have_you_registered_yet')}</span>
                <Link to={ROUTES.worker_login} className={'underline underline-offset-4'}>
                  {t('login')}
                </Link>
              </Space>
            </footer>
          </div>
        </>
      )}
    </>
  );
};

export default WorkerRegistrationForm;
