import { useAppSelector } from '@base/redux/store';
import GoogleCredentials from '@base/types/GoogleCredentials';
import getQueryParam from '@helpers/getQueryParam';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useClientRegistrationForm = () => {
  const { t } = useTranslation();
  const paymentUrlParam = getQueryParam('payment');
  const restaurantDomain = useAppSelector((state) => state.commonSlice.restaurantDomain4Client);
  const [isClientRegistered, setIsClientRegistered] = useState(false);
  const [googleCredentials, setGoogleCredentials] = useState<GoogleCredentials | null>(null);

  return {
    t,
    paymentUrlParam,
    restaurantDomain,
    isClientRegistered,
    setIsClientRegistered,
    googleCredentials,
    setGoogleCredentials,
  };
};

export default useClientRegistrationForm;
