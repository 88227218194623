import { ClientDishExtra } from '@api/models/DishExtra';
import { ClientDishOption } from '@api/models/DishOption';
import DishOptionValue from '@api/models/DishOptionValue';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import { addProduct } from '@base/redux/features/cartSlice';
import { useAppSelector } from '@base/redux/store';
import { CartExtra, CartOption, CartOptionValues, CartProduct } from '@base/types/Cart';
import DishInfoCard from '@components/DishInfoCard';
import MBMinusIcon from '@components/icons/MBMinusIcon';
import MBPlusIcon from '@components/icons/MBPlusIcon';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import useMobileClientDishSettings from '@components/MobileMenu/MobileClientDishSettings/useMobileClientDishSettings';
import Heading from '@components/UI/Heading';
import { CURRENCY, zIndexes } from '@constants/constants';
import roundPrice from '@helpers/roundPrice';
import { Button, Checkbox, Drawer, Form, Input, message, Radio } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const QUANTITY_NAME_FIELD = 'quantity';

interface MobileDishSettingsProps {
  id: number;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

const MobileClientDishSettings: FC<MobileDishSettingsProps> = ({ isOpened, setIsOpened, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dish, isLoading, isSuccess, isError, form, changedFields, onCalculateTotal, totalDishPrice } =
    useMobileClientDishSettings(id);

  const [isOpen, setIsOpen] = useState(isOpened);
  const [quantity, setQuantity] = useState(1);
  const { isOrderPlacementPayment } = useAvailableFeatures();
  const isPaymentMethod = useAppSelector((state) => state.restaurantState.payment_method);

  const isMaximumQuantity = !dish?.is_inventory_unlimited && quantity === dish?.inventory_quantity;

  const handleIncrement = async () => {
    if (isMaximumQuantity) {
      return;
    }

    const newValue = quantity + 1;
    setQuantity(newValue);

    await form.setFieldValue(QUANTITY_NAME_FIELD, newValue);
    await onCalculateTotal();
  };

  const handleDecrement = async () => {
    if (quantity > 1) {
      const newValue = quantity - 1;
      setQuantity(newValue);

      await form.setFieldValue(QUANTITY_NAME_FIELD, newValue);

      await onCalculateTotal();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAfterClose = () => {
    if (!isOpen) {
      setIsOpened(false);
    }
  };

  const addToCart = async (dishID: number) => {
    if (dish) {
      const opts: ClientDishOption[] = await form.getFieldValue(OPTIONS_NAME_FIELD);
      const extras: ClientDishExtra[] = await form.getFieldValue(EXTRAS_NAME_FIELD);

      // prepare options and extras for cart model structure
      const productOptions: CartOption[] | undefined = opts.map((opt) => {
        let values: CartOptionValues | null = null;

        opt.values.forEach((valueOpt) => {
          if (valueOpt.selected) {
            values = {
              id: valueOpt.id as number,
              value: valueOpt.value,
              additional_charge: `${valueOpt.additional_charge}`,
              is_readily_available: +valueOpt.is_readily_available,
            };
          }
        });

        return {
          id: opt.id as number,
          name: opt.name,
          values,
        };
      });

      const productExtras: CartExtra[] = extras
        .filter((extra) => extra.checked && extra.is_readily_available)
        .map((extra) => {
          return {
            id: extra.id as number,
            dish_id: dishID,
            name: extra.name,
            price: `${extra.price}`,
            is_readily_available: extra.is_readily_available,
          };
        });

      const dish_type = dish.dish_type
        ? {
            id: dish.dish_type.id,
            name: dish.dish_type.name,
          }
        : null;

      const product: CartProduct = {
        card_id: Date.now(),
        id: dishID,
        dish_name: dish.dish_name,
        price_one_item: +dish.price,
        product_total_price: totalDishPrice,
        quantity,
        description: dish.description,
        options: productOptions || [],
        extras: productExtras || [],
        inventory_quantity: dish.inventory_quantity,
        is_inventory_unlimited: dish.is_inventory_unlimited,
        menu: {
          id: dish.menu.id,
          name: dish.menu.name,
        },
        dish_type,
      };

      dispatch(addProduct(product));
      handleClose();
      message.success(t('product_added_to_card'));
    }
  };

  return (
    <Drawer
      height={'90%'}
      headerStyle={{ border: 'none', paddingBottom: 0 }}
      placement={'bottom'}
      open={isOpen}
      onClose={handleClose}
      className={'dish-settings-modal'}
      afterOpenChange={handleAfterClose}
      zIndex={zIndexes.dishSettingModal}
    >
      {isLoading && <p>{t('loading')}</p>}
      {isError && <span>{t('error')}</span>}
      {isSuccess && dish && (
        <div className={'dish-settings-modal__content'}>
          <DishInfoCard
            title={dish.dish_name}
            description={dish.description}
            price={dish.price}
            imgUrl={dish.image}
            options={dish.options}
            extras={dish.extras}
          />

          <Form
            form={form}
            onFieldsChange={changedFields}
            onValuesChange={onCalculateTotal}
            className={'dish-settings-modal__form dish-settings'}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="updated_at" hidden>
              <Input />
            </Form.Item>

            <div className={'dish-settings__options-extras'}>
              {dish.options && dish.options?.length > 0 && (
                <Form.List name={OPTIONS_NAME_FIELD}>
                  {(fields) => (
                    <>
                      {fields.map((field, i) => {
                        const opts = form.getFieldValue(OPTIONS_NAME_FIELD);

                        return (
                          <div key={`fields-${i}`}>
                            {opts[i].values.find((value: DishOptionValue) => value.is_readily_available) && (
                              <>
                                <div className={'dish-settings__title'}>
                                  {opts && opts[field.key].name}
                                  <Form.Item name={[field.name, 'name']} hidden>
                                    <Input />
                                  </Form.Item>
                                </div>
                                <div className={'dish-settings-group dish-settings__group'}>
                                  <Form.Item className={'client-dish-option'}>
                                    <Form.List name={[field.name, 'values']}>
                                      {(subFields) => (
                                        <div className={'dish-settings-group__items'}>
                                          {subFields.map((subField, indexValueOpt) => {
                                            const optValue = opts[field.key].values[indexValueOpt];

                                            // if is_readily_available is true
                                            if (optValue.is_readily_available) {
                                              const id = opts[field.key].values[subField.name].id;
                                              const toggleName = opts[field.key].values[subField.name].value;
                                              const price = opts[field.key].values[subField.name].additional_charge;
                                              const selected = opts[field.key].values[subField.name].selected;

                                              return (
                                                <Form.Item
                                                  key={`opt-value-${toggleName}-${i}`}
                                                  noStyle
                                                  name={[subField.name, 'selected']}
                                                  valuePropName={'checked'}
                                                >
                                                  <Radio value={id}>
                                                    <div className={'dish-settings-group__item'}>
                                                      <span className={'dish-settings-group__item-title'}>
                                                        {toggleName}
                                                      </span>
                                                      <span
                                                        className={
                                                          selected ? 'dish-settings-group__item-title--selected' : ''
                                                        }
                                                      >
                                                        {CURRENCY}
                                                        {price}
                                                      </span>
                                                    </div>
                                                  </Radio>
                                                </Form.Item>
                                              );
                                            }
                                          })}
                                        </div>
                                      )}
                                    </Form.List>
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form.List>
              )}

              {dish.extras && dish.extras.length > 0 && dish.extras.find((extra) => extra.is_readily_available) && (
                <div>
                  <div className={'dish-settings__title'}>{t('extras')}</div>

                  <div className={'dish-settings-group dish-settings__group'}>
                    <div className={'dish-settings-group__items'}>
                      <Form.List name={EXTRAS_NAME_FIELD}>
                        {(fields) => (
                          <>
                            {fields.map(({ key, name }, i) => {
                              const extras = form.getFieldValue(EXTRAS_NAME_FIELD);

                              if (extras[i].is_readily_available) {
                                const toggleName = extras && (extras[key].name as string);
                                const price = extras && extras[key].price;
                                const checked = extras && extras[key].is_readily_available;

                                return (
                                  <div key={`extra-${name}-${i}`} className={'dish-settings-group__item'}>
                                    <Form.Item noStyle name={[name, 'checked']} initialValue valuePropName={'checked'}>
                                      <Checkbox className={'dish-settings-group__item-title'}>{toggleName}</Checkbox>
                                    </Form.Item>
                                    <span className={checked ? 'dish-settings-group__item-title--selected' : ''}>
                                      {CURRENCY}
                                      {price}
                                    </span>
                                  </div>
                                );
                              }
                            })}
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <footer className={'dish-settings-footer'}>
              <Button.Group className={'dish-settings-footer__count-button-group count-button-group mb-4'}>
                <Button
                  size={'small'}
                  className={'dish-settings-footer__count-button count-button-group__button'}
                  onClick={handleIncrement}
                  disabled={isMaximumQuantity}
                >
                  <MBPlusIcon className={'text-2xl'} />
                </Button>
                <div>{quantity}</div>
                <Form.Item name="quantity" hidden>
                  <Input />
                </Form.Item>
                <Button
                  size={'small'}
                  className={'dish-settings-footer__count-button count-button-group__button'}
                  onClick={handleDecrement}
                >
                  <MBMinusIcon className={'text-2xl'} />
                </Button>
              </Button.Group>

              <div className={'dish-settings-footer__price-block'}>
                <div>{t('to_be_paid')}:</div>
                <Heading className={'dish-settings-footer__price'} component={'span'}>
                  {CURRENCY}
                  {roundPrice(totalDishPrice)}
                </Heading>
              </div>
              {isOrderPlacementPayment && isPaymentMethod && (
                <Button
                  htmlType={'submit'}
                  type={'primary'}
                  className={'dish-settings-footer__submit-button'}
                  onClick={() => addToCart(dish.id)}
                >
                  {t('add_to_order')}
                </Button>
              )}
            </footer>
          </Form>
        </div>
      )}
    </Drawer>
  );
};

export default MobileClientDishSettings;
