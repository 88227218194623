import api from './api';
import { PackageTransaction, PackageTransactionsList } from './models/PackageTransaction';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<PackageTransactionsList, number>({
      query: (page) => ({
        url: `recurring/payments/history`,
        method: 'GET',
        params: {
          page,
        },
      }),
    }),
    getTransactionById: builder.query<PackageTransaction, number>({
      query: (id) => ({
        url: `recurring/payment/${id}/info`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTransactionsQuery, useGetTransactionByIdQuery, useLazyGetTransactionByIdQuery } = extendedApiSlice;
