import { DishExtra as DishExtraType } from '@api/models/DishExtra';
import UserAccess from '@base/hoc/UserAccess';
import { EXTRAS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import { Button, Form } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MBPlusSquareIcon from '../../../icons/MBPlusSquareIcon';
import DishExtra from './/DishExtra';
const DishFormExtras = () => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const extrasValue = form.getFieldValue(EXTRAS_NAME_FIELD);
  const [extras, setExtras] = useState<DishExtraType[] | null>(null);

  useEffect(() => {
    setExtras(extrasValue);
  }, [extrasValue]);

  const updateExtrasState = async () => {
    const extras = await form.getFieldValue(EXTRAS_NAME_FIELD);
    setExtras(extras);
  };

  return (
    <>
      <Form.List name={EXTRAS_NAME_FIELD}>
        {(fields, { add, remove }) => (
          <div className={'flex gap-4'}>
            <div className={'flex grow flex-col gap-4'}>
              {fields.map(({ key, name, ...restField }, index) => {
                const extras = form.getFieldValue(EXTRAS_NAME_FIELD);
                const id = extras && extras[index]?.id;

                return (
                  <DishExtra
                    temp_id={`temp-extra-id-${index}`}
                    key={`extra-${key}`}
                    id={id}
                    fieldName={name}
                    restField={restField}
                    remove={remove}
                    isEditMode={!id}
                    updateExtrasState={updateExtrasState}
                  />
                );
              })}
            </div>

            <UserAccess roles={['restaurant-manager']}>
              <Button onClick={() => add()} className={'shrink-0 justify-center'}>
                {t('add_extra')}
                <MBPlusSquareIcon className={'shrink-0'} />
              </Button>
            </UserAccess>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default DishFormExtras;
