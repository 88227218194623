import CallToWaiterRequestList from '@components/CallToWaiterRequests/CallToWaiterRequestList';
import useCallToWaiterRequests from '@components/CallToWaiterRequests/useCallToWaiterRequests';
import { Badge, Button, Drawer } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MBCallWaiterIcon from '../icons/MBCallWaiterIcon';
import MBCloseIcon from '../icons/MBCloseIcon';
import useCallsCounter from './CallsCounter/useCallsCounter';

const CallToWaiterRequests = () => {
  const { t } = useTranslation();
  const { isOpened, openDrawer, showCallID } = useCallToWaiterRequests();
  const { newCallsCounter } = useCallsCounter();
  const [isAfterOpened, setIsAfterOpened] = useState(false);

  const handleOpenDrawer = () => {
    openDrawer(true);
  };

  const handleCloseDrawer = () => {
    openDrawer(false);
  };

  const handleAfterOpenChange = () => {
    if (isOpened) {
      setIsAfterOpened(true);
    } else {
      setIsAfterOpened(false);
    }
  };

  return (
    <>
      <Badge count={newCallsCounter} color={'#DD6B20'} offset={[0, 10]}>
        <Button
          type="primary"
          icon={<MBCallWaiterIcon />}
          className={'relative min-w-[56px] justify-center rounded-[100px] text-2xl'}
          onClick={handleOpenDrawer}
        />
      </Badge>
      <Drawer
        id={'call-to-waiter-drawer'}
        placement={'top'}
        open={isOpened}
        onClose={handleCloseDrawer}
        afterOpenChange={handleAfterOpenChange}
        width={'100%'}
        height={'80dvh'}
        title={t('customer_requests')}
        className={'customer-requests-modal'}
        closeIcon={<MBCloseIcon width={24} height={24} />}
      >
        {isOpened && <CallToWaiterRequestList showCallID={showCallID} isAfterOpened={isAfterOpened} />}
      </Drawer>
    </>
  );
};

export default CallToWaiterRequests;
