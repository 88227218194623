// should be the same as in the CSS styles

interface ColorsPalette {
  white: string;
  blackCustom: string;

  grey5: string;
  grey10: string;
  grey15: string;
  grey20: string;
  grey30: string;
  grey40: string;

  red: string;
  red10: string;
  red20: string;
  red30: string;
  red40: string;

  green10: string;
  green20: string;
  green30: string;
  green40: string;

  orange10: string;
  orange20: string;
  orange30: string;
  orange40: string;

  cyan10: string;
  cyan20: string;
  cyan30: string;
  cyan40: string;

  indigo10: string;
  indigo15: string;
  indigo20: string;
  indigo30: string;
  indigo40: string;

  colorBgInput: string;
}

const colors: ColorsPalette = {
  white: '#ffffff',
  blackCustom: '#0F1322',

  grey5: '#F3F4F6',
  grey10: '#E9F9FB',
  grey15: '#D6D8E1',
  grey20: '#B9BCCB',
  grey30: '#646B87',
  grey40: '#252F54',

  red: '#F56565',
  red10: '#FFF5F5',
  red20: '#FED7D7',
  red30: '#F56565',
  red40: '#9B2C2C',

  green10: '#F0FFF4',
  green20: '#C6F6D5',
  green30: '#48BB78',
  green40: '#276749',

  orange10: '#FEEBCB',
  orange20: '#FBD38D',
  orange30: '#DD6B20',
  orange40: '#7B341E',

  cyan10: '#E9F9FB',
  cyan20: '#39C9DF',
  cyan30: '#1095A9',
  cyan40: '#05505B',

  indigo10: '#E6F0FF',
  indigo15: '#EEF2FF',
  indigo20: '#90C0FF',
  indigo30: '#4286FB',
  indigo40: '#3446C7',

  colorBgInput: '#F9F9FB',
};

export default colors;
