import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function convertServerDateToClient(serverDate: string, format = '') {
  const clientDateTime = dayjs();
  const serverDateTime = dayjs(serverDate); // Время с сервера
  const serverOffset = serverDateTime.utcOffset();
  const clientOffset = clientDateTime.utcOffset();
  const timeDifference = clientOffset - serverOffset;

  const clientConvertedDateTime = serverDateTime.add(timeDifference, 'minute');

  if (!format) {
    return clientConvertedDateTime.toISOString();
  }

  return clientConvertedDateTime.format(format);
}

export default convertServerDateToClient;
