import SubscriptionPackage from '@api/models/SubscriptionPackage';
import { useSetFreeSubscriptionPackageMutation } from '@base/redux/api/subscriptionApi';
import { useAppSelector } from '@base/redux/store';
import { SubscriptionPackagePlansType } from '@base/types/SubscriptionPackagePlansType';
import MBCheckIcon from '@components/icons/MBCheckIcon';
import PackagesModal from '@components/Packages/PackagesModal';
import usePackages from '@components/Packages/usePackages';
import PackageBasicLogo from '@components/svg/PackageBasicLogo';
import PackageFreeLogo from '@components/svg/PackageFreeLogo';
import PackagePremiumLogo from '@components/svg/PackagePremiumLogo';
import Heading from '@components/UI/Heading';
import SimpleSection from '@components/UI/SimpleSection';
import { CURRENCY } from '@constants/constants';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { Button, Card, message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';

function getTitlePackage({ title, logo }: { title: ReactNode; logo: ReactNode }) {
  return (
    <div className={'mb-3 flex flex-col items-center gap-2 pb-4'}>
      <div className={'mb-3'}>{title}</div>
      {logo}
      <GradientLine />
    </div>
  );
}

function getPackageBtn({
  t,
  packageType,
  price,
  period,
  button,
  selected,
  onClick,
}: {
  t: any;
  packageType: SubscriptionPackagePlansType;
  price: ReactNode;
  period: ReactNode;
  button?: ReactNode;
  selected: boolean;
  onClick?: () => void;
}) {
  const btnClasses =
    packageType === 'Premium'
      ? 'border-0 bg-[linear-gradient(281deg,#2EB5FE_11.68%,#500EF6_88.35%)] hover:bg-[linear-gradient(281deg,#500EF6_0%,#500EF6_100%)]'
      : '';

  return (
    <div className={'relative mt-5 flex flex-col items-center gap-2'}>
      <div className={'whitespace-nowrap'}>
        {price}/<span className={'text-grey-30'}>{period}</span>
      </div>
      {selected ? (
        <span className={'flex h-10 items-center text-base'}>{t('selected')}</span>
      ) : (
        <>
          {button || (
            <Button type={'primary'} onClick={onClick} className={`${btnClasses}`}>
              {t('to_be_paid')}
            </Button>
          )}
        </>
      )}
    </div>
  );
}

interface PackageTableType {
  key: number | string;
  properties: ReactNode;
  package0: ReactNode;
  package1: ReactNode;
  package2: ReactNode;
  package3: ReactNode;
}

interface GenerateColumnsProps {
  t: any;
  packages: SubscriptionPackage[];
  isMobile: boolean;
}

const GradientLine = ({ align = 'center' }: { align?: 'left' | 'center' | 'right' }) => {
  const style: CSSProperties = {
    left: align === 'left' ? 0 : align === 'center' ? '50%' : 'auto',
    right: align === 'right' ? 0 : 'auto',
    transform: align === 'center' ? 'translateX(-50%)' : 'none',
  };

  return (
    <div
      style={style}
      className={`absolute bottom-[12px] h-[2px] w-[100px] rounded-[2px] bg-[linear-gradient(90deg,rgba(80,14,246,1)_50%,rgba(46,181,254,1)_100%)]`}
    ></div>
  );
};

function generateColumns({ t, packages, isMobile }: GenerateColumnsProps): ColumnsType<PackageTableType> {
  const packageLogos = {
    ['Trial']: <PackagePremiumLogo />,
    ['Free']: <PackageFreeLogo />,
    ['Basic']: <PackageBasicLogo />,
    ['Premium']: <PackagePremiumLogo />,
  };

  const columns: ColumnsType<PackageTableType> = [
    {
      key: 'properties',
      dataIndex: 'properties',
      title: (
        <>
          <Heading>{t('properties')}</Heading>
          <GradientLine align={'right'} />
        </>
      ),
      className: '!bg-white relevant',
      fixed: !isMobile ? 'left' : false,
    },
  ];

  packages.forEach((packageItem, i) => {
    columns.push({
      key: `package${i}`,
      dataIndex: `package${i}`,
      title: getTitlePackage({
        title: <Heading>{packageItem.name}</Heading>,
        logo: packageLogos[packageItem.name],
      }),
      align: 'center',
      width: 220,
      className: '!bg-transparent relevant',
    });
  });

  return columns;
}

function getPackageFeature(packageFeatures: { [key: string]: any }, key: string) {
  if (typeof packageFeatures[key] !== 'boolean') {
    return packageFeatures[key];
  }

  return packageFeatures[key] ? (
    <MBCheckIcon className="text-cyan-30" />
  ) : (
    <code className={'text-xl text-grey-15'}>&#8212;</code>
  );
}

function generateDataSource({ t, packages }: { t: any; packages: SubscriptionPackage[] }): PackageTableType[] {
  const { user_accounts, ...features } = packages[0].availability_of_features;
  const properties: PackageTableType[] = [];

  for (const [key] of Object.entries(features)) {
    properties.push({
      key,
      properties: <span className={'font-medium'}>{t(`package_features.${key}`)}</span>,
      package0: getPackageFeature(packages[0].availability_of_features, key),
      package1: getPackageFeature(packages[1].availability_of_features, key),
      package2: getPackageFeature(packages[2].availability_of_features, key),
      package3: getPackageFeature(packages[3].availability_of_features, key),
    });
  }

  return properties;
}

const Packages = () => {
  const { t, packages = [], isLoading, isError } = usePackages();
  const { isMobile } = useGetMediaQuery();
  const [isPackageModalOpened, setIsPackageModalOpened] = useState(false);
  const [packageID, setPackageID] = useState<number | null>(null);
  const restPackage = useAppSelector(
    (state) => state.restaurantSettingsState.package_payment?.package || state.restaurantState.package_payment?.package
  );

  // TODO: move this to other file/hook
  // Temporary (setFreePackage)
  const [
    setFreePackage,
    {
      isError: isErrorSetFreePackage,
      isLoading: isLoadingSetFreePackage,
      isSuccess: isSuccessSetFreePackage,
      error: errorSetFreePackage,
    },
  ] = useSetFreeSubscriptionPackageMutation();
  const user = useAppSelector((state) => state.userState.user);
  const restaurantId = useAppSelector((state) => state.restaurantSettingsState.id || state.restaurantState.id);

  useEffect(() => {
    if (isLoadingSetFreePackage) {
      return;
    }

    if (isSuccessSetFreePackage) {
      message.success(t('updated'));

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    if (isErrorSetFreePackage && errorSetFreePackage) {
      const reqError = errorSetFreePackage as any;
      const errorMessage = reqError?.data?.data?.error_message;

      message.error(errorMessage || t('error'));
    }
  }, [isLoadingSetFreePackage]);
  ////////////////

  const columns = useMemo(() => generateColumns({ t, packages, isMobile }), [packages]);
  const dataSource: PackageTableType[] = [];

  const packagePrices = useMemo(
    () => ({
      key: 'price',
      properties: '',
      package0: getPackageBtn({
        t,
        packageType: 'Trial',
        price: t('trial', { n: 12 }),
        period: t('month'),
        selected: restPackage?.name === 'Trial' || false,
        button: <span className={'h-10 text-base opacity-0'}>empty</span>,
        onClick: () => onChoosePackage(packages[0].id),
      }),
      package1: getPackageBtn({
        t,
        packageType: 'Free',
        price: t('free_to', { n: 12 }),
        period: t('month'),
        selected: restPackage?.name === 'Free' || false,
        onClick: () => handleSetFreePackage(),
      }),
      package2: getPackageBtn({
        t,
        packageType: 'Basic',
        price: packages[2]?.price ? `${CURRENCY}${packages[2].price}` : '',
        period: t('per_month'),
        selected: restPackage?.name === 'Basic' || false,
        onClick: () => onChoosePackage(packages[2].id),
      }),
      package3: getPackageBtn({
        t,
        packageType: 'Premium',
        price: packages[3]?.price ? `${CURRENCY}${packages[3].price}` : '',
        period: t('per_month'),
        onClick: () => onChoosePackage(packages[3].id),
        selected: restPackage?.name === 'Premium' || false,
      }),
    }),
    [packages]
  );

  const data = useMemo(() => (packages.length > 0 ? generateDataSource({ t, packages }) : null), [packages]);

  if (data) {
    dataSource.push(...data);
    dataSource.push(packagePrices);
  }

  async function onChoosePackage(id: number) {
    await setPackageID(id);
    setIsPackageModalOpened(true);
  }

  // TODO: move this to other file/hook
  // temporary (setFreePackage)
  const handleSetFreePackage = async () => {
    if (user && restaurantId) {
      await setFreePackage({ managerId: user?.id, restId: restaurantId });
    }
  };
  /////////////////

  return (
    <>
      <SimpleSection title={t('club_packages')}>
        <Card>
          {isError ? (
            <p>{t('error')}</p>
          ) : isLoading ? (
            <p>{t('loading')}</p>
          ) : (
            packages.length > 0 && (
              <Table
                rowClassName={'!text-lg'}
                className={'packages-table'}
                size={'small'}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 1000 }}
              />
            )
          )}
        </Card>
      </SimpleSection>

      {packages.length > 0 && packageID && (
        <PackagesModal
          packages={packages}
          selected_package_id={packageID}
          isOpened={isPackageModalOpened}
          setIsOpened={setIsPackageModalOpened}
        />
      )}
    </>
  );
};

export default Packages;
