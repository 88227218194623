import DashboardBestDish from '@components/Dashboard/DashboardBestDish';
import DashboardInventory from '@components/Dashboard/DashboardInventory';
import DashboardOrdersMonitoring from '@components/Dashboard/DashboardOrdersMonitoring';
import DashboardPromotions from '@components/Dashboard/DashboardPromotions';
import DashboardStatistics from '@components/Dashboard/DashboardStatistics';
import useDashboard from '@components/Dashboard/useDashboard';
import SecondarySelect from '@components/UI/SecondarySelect';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  const { filterOptions, filter, defaultFilterValue, setFilter } = useDashboard();

  return (
    <div>
      <section>
        <div className={'mb-6 flex items-center gap-6'}>
          <h2 className={'mb-0 text-[26px] font-bold text-indigo-40'}>{t('pages.dashboard')}</h2>
          <SecondarySelect
            options={filterOptions}
            defaultValue={defaultFilterValue}
            onChange={(value) => setFilter(value)}
          />
        </div>

        <Row gutter={[24, 40]}>
          <Col xs={24} md={10} xl={8}>
            <DashboardStatistics filter={filter} />
          </Col>
          <Col xs={24} md={14} xl={16}>
            <DashboardPromotions filter={filter} />
          </Col>
          <Col xs={24} md={12}>
            <DashboardBestDish filter={filter} />
          </Col>
          <Col xs={24} md={12}>
            <DashboardInventory />
          </Col>
          <Col xs={24}>
            <DashboardOrdersMonitoring />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Dashboard;
