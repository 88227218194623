import { DEFAULT_COLLAPSED_CART_HEIGHT, EXTRA_PROMOTION_CART_HEIGHT } from '@constants/constants';
import { Alert, Button } from 'antd';
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface CartFooterProps {
  totalCartPriceFooter: ReactNode;
  cartErrorMessages: string;
  isLoading: boolean;
  handleClick: () => void;
  setRecompressedHeight: Dispatch<SetStateAction<number>>;
  isPromotion: boolean;
}

const CartFooter: FC<CartFooterProps> = ({
  totalCartPriceFooter,
  handleClick,
  isLoading,
  cartErrorMessages,
  setRecompressedHeight,
  isPromotion,
}) => {
  const { t } = useTranslation();
  const cartFooterMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const messageSection = cartFooterMessageRef.current;
    if (messageSection) {
      let height = DEFAULT_COLLAPSED_CART_HEIGHT + messageSection.scrollHeight;

      if (isPromotion) {
        height += EXTRA_PROMOTION_CART_HEIGHT;
      }

      setRecompressedHeight(height);
    }
  }, [cartErrorMessages]);

  return (
    <>
      {totalCartPriceFooter}

      <Button
        type={'primary'}
        className={'mobile-cart-modal__submit-button h-[52px]'}
        onClick={handleClick}
        loading={isLoading}
      >
        {t('make_order')}
      </Button>
      <div className={'flex flex-col gap-2 pb-2'} ref={cartFooterMessageRef}>
        {cartErrorMessages && <Alert type={'error'} showIcon message={cartErrorMessages} className={'p-2 text-xs'} />}
      </div>
    </>
  );
};

export default CartFooter;
