import DishCardThemeTest from '@base/components/DishCardThemeTest/DishCardTheme';
import RestaurantTheme from '@base/hoc/RestaurantTheme';
import { useRestaurantLayout } from '@base/hooks/useRestaurantLayout';
import { Dish } from '@base/redux/api/models/Dish';
import { useAppSelector } from '@base/redux/store';
import Pagination from '@components/UI/Pagination';
import SecondaryButton from '@components/UI/SecondaryButton';
import SecondarySelect from '@components/UI/SecondarySelect';
import StatisticsCard from '@components/UI/StatisticsCard';
import DishThreeSelect from '@pages/Components/DishThreeSelect';
import DndMenu from '@pages/Components/DndMenu';
import { Button, Card, Checkbox, Form, Input, Radio, RadioChangeEvent, Select, Space, Switch } from 'antd';
import { useState } from 'react';
import MBDownloadIcon from '../../components/icons/MBDownloadIcon';
import FullWidthButton from '../../components/UI/FullWidthButton';
import MyModal from '../../components/UI/MyModal';
import '../../styles/index.css';

const Components = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setRestaurantLayout } = useRestaurantLayout();
  const restLayout = useAppSelector((state) => state.restaurantSettingsState.layout);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleThemeChange = (e: RadioChangeEvent) => {
    setRestaurantLayout({ id: 0, name: e.target.value });
  };

  const DISH_CARD_DUMMY_DATA: Dish = {
    id: 1,
    dish_name: 'dish_name',
    amount: 1,
    image:
      'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
    price: 1,
    description: 'description',
    is_portion_available: true,
    is_take_away: false,
    allergies: [
      {
        id: 1,
        name: '\u05e7\u05e4\u05d0\u05d9\u05df',
      },
      {
        id: 2,
        name: '\u05e6\u05d1\u05e2 \u05de\u05d0\u05db\u05dc',
      },
      {
        id: 3,
        name: '\u05db\u05d9\u05e0\u05d9\u05df',
      },
    ],
    menu: {
      id: 1,
      restaurant_id: 1,
      name: 'meat',
    },
    dish_type: {
      id: 1,
      name: 'vegan',
    },
    updated_at: '2023-12-07T09:04:26.000000Z',
    options: [
      {
        id: 1,
        name: 'portion size',
        values: [
          {
            id: 1,
            value: 'little',
            additional_charge: 0.0,
            is_readily_available: true,
          },
          {
            id: 2,
            value: 'medium',
            additional_charge: 1.0,
            is_readily_available: true,
          },
          {
            id: 3,
            value: 'big',
            additional_charge: 2.0,
            is_readily_available: true,
          },
        ],
      },
      {
        id: 2,
        name: 'gluten',
        values: [
          {
            id: 4,
            value: 'Regular',
            additional_charge: 0.0,
            is_readily_available: true,
          },
          {
            id: 5,
            value: 'lacking',
            additional_charge: 2.0,
            is_readily_available: true,
          },
        ],
      },
    ],
    extras: [
      {
        id: 1,
        dish_id: 1,
        name: 'French fries',
        price: 1.0,
        is_readily_available: true,
      },
      {
        id: 2,
        dish_id: 1,
        name: 'drinking',
        price: 5.0,
        is_readily_available: true,
      },
    ],
    inventory_quantity: 3,
    is_inventory_unlimited: false,
  };

  const options = [
    { value: 'jack', label: 'Jack' },
    { value: 'lucy', label: 'Lucy' },
    { value: 'Yiminghe', label: 'yiminghe' },
    { value: 'disabled', label: 'Disabled', disabled: true },
  ];

  return (
    <div className={'p-6'}>
      <h1>Components</h1>
      <div className={'m-[100px]'}>
        <DndMenu />
      </div>

      <Space wrap direction={'horizontal'} align={'start'}>
        {/* <Card title={'Kitchen receipt'}>
          <KitchenReceipt />
        </Card>*/}
        <Card title={'Dish three select'}>
          <DishThreeSelect />
        </Card>
        <Card title={'Pagination'}>
          <Pagination defaultCurrent={1} total={50} />
        </Card>
        <div>
          <StatisticsCard title={'Title'} value={2033} color={'#F67D1C'} />
        </div>

        <Card title={'Secondary select'}>
          <SecondarySelect options={options} defaultValue={'lucy'} />
        </Card>

        <Card title={'Full width button'}>
          <FullWidthButton>Button</FullWidthButton>
        </Card>

        <Card title={'Buttons'}>
          <Space wrap>
            <SecondaryButton>Secondary</SecondaryButton>
            <Button type="primary">Button</Button>
            <Button type="primary" disabled>
              Button
            </Button>
            <Button type="primary" icon={<MBDownloadIcon />}>
              Download
            </Button>
          </Space>
          <br />
          <br />
          <Space wrap>
            <Button>Ghost</Button>
            <Button disabled>Ghost</Button>
          </Space>
        </Card>

        <Card title={'Select with checkbox list'} className={'w-screen max-w-[500px]'}>
          <Select
            mode="multiple"
            allowClear
            placeholder={'Does not include allergenic ingredients'}
            className={'select-checkbox w-full'}
            popupClassName={'select-checkbox-list'}
          >
            <Select.Option value={'Opt 1'}>Caffeine</Select.Option>
            <Select.Option value={'Opt 2'}>Food coloring</Select.Option>
            <Select.Option value={'Opt 3'}>quinine</Select.Option>
            <Select.Option value={'Opt 4'}>Taurine</Select.Option>
            <Select.Option value={'Opt 5'}>sourdough</Select.Option>
            <Select.Option value={'Opt 6'}>A sweetener and source of phenylalanine</Select.Option>
            <Select.Option value={'Opt 8'}>Preservatives</Select.Option>
            <Select.Option value={'Opt 9'}>Lactose</Select.Option>
            <Select.Option value={'Opt 10'}>Sulfites</Select.Option>
          </Select>
        </Card>

        <Card title={'Switchers'}>
          <Space direction={'vertical'}>
            <Space direction={'horizontal'}>
              <Switch />
              <span>Item</span>
            </Space>
            <Space direction={'horizontal'}>
              <span>Item</span>
              <Switch />
            </Space>
            <Space direction={'horizontal'}>
              <span>Item disabled</span>
              <Switch disabled />
            </Space>
          </Space>
        </Card>

        <Card title={'Checkbox'}>
          <Space wrap direction={'vertical'}>
            <Checkbox>Item</Checkbox>
            <Checkbox>Item</Checkbox>
          </Space>
        </Card>

        <Card title={'Radio'}>
          <Radio.Group>
            <Space wrap direction={'vertical'}>
              <Radio value={'1'}>Radio 1</Radio>
              <Radio value={'2'} checked>
                Radio 2
              </Radio>
            </Space>
          </Radio.Group>
        </Card>

        <Card title={'Input'}>
          <Space wrap>
            <Form name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
              </Form.Item>
            </Form>
          </Space>
        </Card>

        <Card title={'Modal'}>
          <>
            <Button type="primary" onClick={showModal}>
              Open My Modal
            </Button>
            <MyModal
              title="Basic Modal"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={<Button type={'primary'}>Save</Button>}
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </MyModal>
          </>
        </Card>
        <RestaurantTheme>
          <div className={'flex w-[100vw] flex-col items-center gap-5'}>
            <Card title={'Choose theme'} className={'max-w-max'}>
              <Radio.Group defaultValue={restLayout?.name || 'theme_1'} onChange={handleThemeChange}>
                <Space wrap direction={'vertical'}>
                  <Radio value={'theme_1'}>Theme 1</Radio>
                  <Radio value={'theme_2'}>Theme 2</Radio>
                </Space>
              </Radio.Group>
            </Card>
            <div className="m-auto w-full max-w-[343px]">
              <DishCardThemeTest dish={DISH_CARD_DUMMY_DATA} />
            </div>
          </div>
        </RestaurantTheme>
      </Space>
    </div>
  );
};

export default Components;
