import MailSentSuccesful from '@base/components/icons/MailSentSuccesful';
import { useTranslation } from 'react-i18next';

const SuccessfulForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <div className={'lg: mx-auto flex h-full self-center p-6 text-center lg:px-14 lg:pb-10 lg:pt-20'}>
      <div className={'m-auto max-w-[448px] p-4'}>
        <div className={'mb-1 text-[26px] font-bold text-indigo-40'}>{t('thanks')}!</div>
        <p className={'mb-0 text-grey-30'}>{t('sent_link_to_set_new_password')}.</p>
        <MailSentSuccesful className={'mt-10 lg:mt-6'} />
      </div>
    </div>
  );
};

export default SuccessfulForgotPassword;
