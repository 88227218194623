import useAccountInfo from '@components/AccountSettings/AccountInfo/useAccountInfo';
import MBHandsetIcon from '@components/icons/MBHandsetIcon';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import SimpleSection from '@components/UI/SimpleSection';
import { regExPhone } from '@constants/regexp';
import { Button, Card, Col, Form, Input, Row } from 'antd';

interface AccountInfoFrom {
  first_name: string;
  last_name: string;
  phone_number: string;
}

const AccountInfo = () => {
  const { t, form, userInfo, isError, onFinish, isUpdating, isGoogleAccount } = useAccountInfo();

  return (
    <SimpleSection title={t('personal_information')}>
      {isError ? (
        <p>{t('error')}</p>
      ) : (
        <Card>
          <Form
            form={form}
            name="account_info"
            layout="vertical"
            initialValues={{ remember: false }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={[24, 0]}>
              <Col xs={24} md={12}>
                <Form.Item<AccountInfoFrom>
                  label={t('first_name')}
                  name="first_name"
                  rules={[{ required: true, message: t('required_field') }]}
                >
                  <Input
                    placeholder={t('first_name_placeholder')}
                    disabled={isGoogleAccount}
                    readOnly={isGoogleAccount}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item<AccountInfoFrom>
                  label={t('last_name')}
                  name="last_name"
                  rules={[{ required: true, message: t('required_field') }]}
                >
                  <Input
                    placeholder={t('last_name_placeholder')}
                    disabled={isGoogleAccount}
                    readOnly={isGoogleAccount}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t('email')}>
                  <Input
                    disabled
                    readOnly
                    suffix={<MBLetterIcon className={'text-grey-20'} />}
                    value={userInfo?.email}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item<AccountInfoFrom>
                  label={t('phone')}
                  name="phone_number"
                  rules={[
                    {
                      required: true,
                      pattern: regExPhone,
                      message: t('input_errors.phone_only_numbers'),
                    },
                    {
                      max: 15,
                      message: t('input_errors.phone_max'),
                    },
                  ]}
                >
                  <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button type={'primary'} size={'small'} htmlType={'submit'} loading={isUpdating}>
                  {t('save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </SimpleSection>
  );
};

export default AccountInfo;
