import { Order, OrderStatus } from '@api/models/Order';
import { Pagination } from '@api/models/Pagination';
import { Waiter } from '@api/models/Waiter';
import useCheckRole from '@base/hooks/useCheckRole';
import { ROUTES } from '@base/utils/constants/routes';
import MobileOrdersMonitoringList from '@components/OrdersMonitoring/MobileOrdersMonitoringList';
import OrdersMonitoringTable from '@components/OrdersMonitoring/OrdersMonitoringTable';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { Card } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface OrdersMonitoringViewProps {
  orders: Order[];
  waiterOrders: Order[] | null;
  pagination: Pagination | false;
  orderStatuses: OrderStatus[];
  waiters: Waiter[];
  isLoading: boolean;
  isError: boolean;
  setPagePagination?: Dispatch<SetStateAction<number>>;
  isDashboard?: boolean;
  isWaiters2Tables: boolean;
  isPaymentMethod?: boolean;
}

const OrdersMonitoringView: FC<OrdersMonitoringViewProps> = ({
  orders,
  waiterOrders,
  pagination,
  orderStatuses,
  waiters,
  isLoading,
  isError,
  setPagePagination,
  isDashboard,
  isWaiters2Tables,
  isPaymentMethod,
}) => {
  const { t } = useTranslation();
  const { isMD } = useGetMediaQuery();
  const { isManager } = useCheckRole();

  return (
    <>
      {isError ? (
        <span>{t('error')}</span>
      ) : isLoading ? (
        <div>{t('loading')}</div>
      ) : !isPaymentMethod ? (
        <Card className={'text-center'}>
          {t('no_information')}. {t('you_must_add_clearing_system')}{' '}
          {isManager && (
            <Link
              className={'text-indigo-30 underline underline-offset-4 hover:text-indigo-30'}
              to={ROUTES.account_settings}
            >
              {t('in_account_settings')}
            </Link>
          )}
        </Card>
      ) : orders.length > 0 ? (
        <>
          {isMD ? (
            <OrdersMonitoringTable
              orders={waiterOrders || orders}
              waiters={waiters}
              orderStatuses={orderStatuses}
              pagination={pagination}
              setPagePagination={setPagePagination}
              isDashboard={isDashboard}
              isWaiters2Tables={isWaiters2Tables}
            />
          ) : (
            <MobileOrdersMonitoringList
              orders={waiterOrders || orders}
              waiters={waiters}
              statuses={orderStatuses}
              isWaiters2Tables={isWaiters2Tables}
            />
          )}
        </>
      ) : (
        <p>{t('no_orders_yet')}</p>
      )}
    </>
  );
};

export default OrdersMonitoringView;
