import GoogleLogo from '@components/svg/GoogleLogo';
import { GoogleLogin } from '@react-oauth/google';
import { Button } from 'antd';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface GoogleAuthButtonProps {
  onSuccess: (tokenResponse: any) => void;
  onError: () => void;
}

const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({ onSuccess, onError }) => {
  const { t } = useTranslation();
  const btnRef = useRef<any | null>(null);

  const handleHover = () => {
    if (btnRef.current) {
      btnRef.current.classList.add('!bg-grey-5');
    }
  };

  const handleLeave = () => {
    if (btnRef.current) {
      btnRef.current.classList.remove('!bg-grey-5');
    }
  };

  return (
    <div className={'relative overflow-hidden'} onMouseOver={handleHover} onMouseLeave={handleLeave}>
      <div className={'absolute left-0 top-0 z-20 h-full w-full opacity-[0.001]'}>
        <GoogleLogin onSuccess={onSuccess} onError={onError} />
      </div>
      <Button ref={btnRef} className={'gap-4'}>
        <span>{t('continue_with_google')}</span>
        <GoogleLogo />
      </Button>
    </div>
  );
};

export default GoogleAuthButton;
