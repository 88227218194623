import MBChevronDownIcon from '@base/components/icons/MBChevronDownIcon';
import { ConfigProvider, Select, SelectProps } from 'antd';
import { FC, ReactNode } from 'react';
import { whiteBackgroundSelect } from './whiteBackgroundSelectTheme.antd';

interface WhiteBackgroundSelect extends SelectProps {
  children: ReactNode;
}

const WhiteBackgroundSelect: FC<WhiteBackgroundSelect> = ({ children, ...props }) => {
  return (
    <ConfigProvider theme={whiteBackgroundSelect}>
      <Select {...props} suffixIcon={<MBChevronDownIcon />}>
        {children}
      </Select>
    </ConfigProvider>
  );
};

export default WhiteBackgroundSelect;
