import footerBgImg from '@assets/img/bg-header-auth-form.jpg';
import { useAppSelector } from '@base/redux/store';
import FacebookIcon from '@components/icons/FacebookIcon';
import InstagramIcon from '@components/icons/InstagramIcon';
import Logo from '@components/icons/Logo';
import OmnisLogo from '@components/icons/OmnisLogo';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import RoundedIcon from '../../UI/RoundedIcon';
import RestaurantContacts from '../RestaurantContacts';

const styles = {
  background: `linear-gradient(180deg, rgba(54, 41, 204, 0.35) 0%, rgba(54, 41, 204, 0.00) 100%), #1095A9`,
};

const RestaurantFooter = () => {
  const { t } = useTranslation();
  const restInfoByDomain = useAppSelector((state) => state.restaurantState);
  const restInfoById = useAppSelector((state) => state.restaurantSettingsState);
  const restInfo = restInfoByDomain.id ? restInfoByDomain : restInfoById;

  const { work_hours, description_schedule, address, phone_number, instagram_link, facebook_link } = restInfo;

  return (
    <>
      <footer className={'restaurant-footer relative px-4 py-6 text-center text-indigo-10'} style={styles}>
        <img
          src={footerBgImg}
          alt="footer background image"
          className={'absolute left-0 top-0 z-[0] h-full w-full object-cover opacity-[10%]'}
        />
        <div className={'restaurant-footer__content relative z-[2]'}>
          <RestaurantContacts
            work_hours={work_hours}
            description_schedule={description_schedule}
            phone_number={phone_number}
            address={address}
          />
          <div className={'restaurant-footer-socials flex w-full flex-col items-center gap-4'}>
            <Space wrap size={'middle'} className={'footer-socials-group restaurant-footer-socials__group'}>
              {instagram_link && (
                <a href={instagram_link} target={'_blank'} rel="noreferrer">
                  <span className={'footer-socials-group__icon inline-block h-9 w-9'}>
                    <RoundedIcon>
                      <InstagramIcon />
                    </RoundedIcon>
                  </span>
                </a>
              )}

              {facebook_link && (
                <a href={facebook_link} target={'_blank'} rel="noreferrer">
                  <span className={'footer-socials-group__icon inline-block h-9 w-9'}>
                    <RoundedIcon>
                      <FacebookIcon />
                    </RoundedIcon>
                  </span>
                </a>
              )}
            </Space>
            <div className={'restaurant-footer__copyright'}>
              {t('copyright')} Menu Bit {new Date().getFullYear()} <br /> {t('all_rights_reserved')}
            </div>
            <Space align={'center'} content={'center'} className={'restaurant-footer-omnis'}>
              <span className={'restaurant-footer-omnis__title'}>{t('created_by')}</span>
              <OmnisLogo className={'text-white'} />
            </Space>
          </div>
        </div>
      </footer>

      <div className={'restaurant-footer-logo flex items-center justify-center gap-2 bg-white p-4'}>
        <Logo className={'h-[21px] w-auto'} />
        <span className={'restaurant-footer-logo__text text-grey-30'}>Created by</span>
      </div>
    </>
  );
};

export default RestaurantFooter;
