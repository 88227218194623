import { SVGProps } from 'react';
const MBCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m.5 8.01 6 9 11.999-16-1-1L7 11.01l-5.5-4-1 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MBCheckIcon;
