import PasswordCreatedSuccessful from '@base/components/icons/PasswordCreatedSuccessful';
import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface SuccessfulPasswordResetProps {
  loginLink?: string | null;
  additionalText?: string | null;
}

const SuccessfulPasswordReset: FC<SuccessfulPasswordResetProps> = ({ loginLink, additionalText }) => {
  const { t } = useTranslation();

  return (
    <div className={'lg: mx-auto flex h-full self-center p-6 text-center lg:px-14 lg:pb-10 lg:pt-20'}>
      <div className={'m-auto max-w-[448px] p-4'}>
        <div className={'mb-1 text-[26px] font-bold text-indigo-40'}>{t('excellent')}!</div>
        <p className={'mb-0 text-grey-30'}>{t('password_changed_successfully')}!</p>
        {additionalText && <p className={'mb-0 text-grey-30'}>{additionalText}!</p>}
        <PasswordCreatedSuccessful className={'mt-10 lg:mt-6'} />
        {loginLink && (
          <Link to={loginLink} className={'block'}>
            <Button type="primary" className={'mt-10 w-[206px] justify-center lg:mt-6'}>
              {t('system_login')}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SuccessfulPasswordReset;
