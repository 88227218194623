import { SVGProps } from 'react';
const SmileFaceRatingFourIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.9384 40C30.9501 40 39.8769 31.0457 39.8769 20C39.8769 8.9543 30.9501 0 19.9384 0C8.9267 0 -6.10352e-05 8.9543 -6.10352e-05 20C-6.10352e-05 31.0457 8.9267 40 19.9384 40Z"
      fill="#5DC974"
    />
    <path
      d="M26.7991 18.3022C28.4029 18.3022 29.703 16.9981 29.703 15.3894C29.703 13.7807 28.4029 12.4766 26.7991 12.4766C25.1954 12.4766 23.8953 13.7807 23.8953 15.3894C23.8953 16.9981 25.1954 18.3022 26.7991 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M12.2998 18.3022C13.9036 18.3022 15.2037 16.9981 15.2037 15.3894C15.2037 13.7807 13.9036 12.4766 12.2998 12.4766C10.696 12.4766 9.39594 13.7807 9.39594 15.3894C9.39594 16.9981 10.696 18.3022 12.2998 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M27.187 24.4297C27.499 24.4297 27.8052 24.5781 27.9956 24.8556C28.3031 25.3028 28.1905 25.9158 27.7447 26.2242C27.6643 26.2797 25.7447 27.5916 22.8768 28.2524C19.0447 29.1358 15.329 28.4343 12.1324 26.2242C11.6866 25.9158 11.574 25.3034 11.8815 24.8556C12.189 24.4084 12.7995 24.2955 13.246 24.6039C14.7725 25.659 16.4213 26.3152 18.1473 26.554C19.5323 26.7456 20.9759 26.6721 22.4381 26.3346C24.945 25.757 26.6157 24.6155 26.6318 24.6039C26.8016 24.4865 26.9959 24.4303 27.1876 24.4303L27.187 24.4297Z"
      fill="currentColor"
    />
  </svg>
);
export default SmileFaceRatingFourIcon;
