import { domain } from '../constants/environments';

const addSubdomainToUrl = (subdomain: string | null) => {
  if (subdomain) {
    return `https://${subdomain}.${domain}`;
  }

  return `https://${domain}`;
};

export default addSubdomainToUrl;
