import WorkerLoginForm from '@components/AuthForm/WorkerLoginForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';
// import AuthRestaurantInfo from '../../components/AuthRestaurantInfo';

const WorkerLoginPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.login'));

  // TODO: decide what to do with AuthRestaurantInfo - remove AuthRestaurantInfo
  return (
    // <AuthForm sideBarContent={<AuthRestaurantInfo />}>
    //   <WorkerLoginForm />
    // </AuthForm>
    <AuthForm>
      <WorkerLoginForm />
    </AuthForm>
  );
};

export default WorkerLoginPage;
