import ResetPasswordForm from '@base/components/AuthForm/ResetPasswordForm';
// import { useAppSelector } from '@base/redux/store';
// import AuthRestaurantInfo from '@components/AuthRestaurantInfo';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';

const ResetPasswordPage = () => {
  const { t } = useTranslation();

  // const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);

  useSetTitlePage(t('pages.new_password'));

  // TODO: decide what to do with AuthRestaurantInfo - remove AuthRestaurantInfo
  return (
    // <AuthForm sideBarContent={isSubdomain && <AuthRestaurantInfo />}>
    //   <ResetPasswordForm />
    // </AuthForm>
    <AuthForm>
      <ResetPasswordForm type="manager" />
    </AuthForm>
  );
};

export default ResetPasswordPage;
