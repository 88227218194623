import { useAppSelector } from '@base/redux/store';
import useAuth from '@hooks/useAuth';
import useCheckRole from '@hooks/useCheckRole';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { MenuItemsByRole } from '@layout/Sidepanel/Sidepanel';
import { MenuProps } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

const useSidepanel = () => {
  const location = useLocation();
  const { isLG } = useGetMediaQuery();
  const { isAuth } = useAuth();
  const { isManager, isSupervisor } = useCheckRole();
  const isActiveRestaurant = useAppSelector((state) => state.restaurantSettingsState.isActive);
  const [collapsed, setCollapsed] = useState(false);
  const role: keyof MenuItemsByRole = isManager ? 'manager' : isSupervisor ? 'supervisor' : 'waiter';
  // TODO: decide to remove this or not
  //const [nav, setNav] = useState<MenuItem[]>([]);

  return {
    location,
    isLG,
    collapsed,
    setCollapsed,
    isAuth,
    role,
    isActiveRestaurant,
    // TODO: decide to remove this or not
    // nav,
    // setNav,
  };
};

export default useSidepanel;
