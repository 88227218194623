import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  id?: number | null;
  foregroundColor: string;
  backgroundColor: string;
  isQRCodeLoaded: boolean;
}

const initialState: InitialState = {
  foregroundColor: '#ffffff',
  backgroundColor: '#000000',
  isQRCodeLoaded: false,
};

export const QRCodeSlice = createSlice({
  initialState,
  name: 'QRCode',
  reducers: {
    resetQRSlice: () => initialState,
    setQRCodeId: (state, action: PayloadAction<number | null>) => {
      state.id = action.payload;
    },
    setForegroundColor: (state, action: PayloadAction<string>) => {
      state.foregroundColor = action.payload;
    },
    setBackgroundColor: (state, action: PayloadAction<string>) => {
      state.backgroundColor = action.payload;
    },
    setIsQRCodeLoaded: (state, action: PayloadAction<boolean>) => {
      state.isQRCodeLoaded = action.payload;
    },
  },
});

export default QRCodeSlice.reducer;

export const { resetQRSlice, setQRCodeId, setForegroundColor, setBackgroundColor, setIsQRCodeLoaded } =
  QRCodeSlice.actions;
