import { ClientOrdersMonitoring, Order, OrdersMonitoring, OrderStatus } from '@api/models/Order';
import { generateQueryStringFromArrayObjects } from '@base/utils/helpers/generateQueryStringFromArrayObjects';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';
import { t } from 'i18next';
import api from './api';
import { ClientOrdersRestaurant } from './models/Restaurant';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<OrdersMonitoring, number>({
      query: (page) => ({
        url: `orders`,
        params: {
          page,
        },
      }),
    }),
    getWaiterOrders: builder.query<OrdersMonitoring, number>({
      query: (page) => ({
        url: addTenantToEndpoint(`/waiter/orders`),
        params: {
          page,
        },
      }),
    }),
    getSupervisorOrders: builder.query<OrdersMonitoring, number>({
      query: (page) => ({
        url: addTenantToEndpoint(`/supervisor/orders`),
        params: {
          page,
        },
      }),
    }),
    getClientOrders: builder.query<ClientOrdersMonitoring, { page: number; restaurants: number[] | [] }>({
      query: ({ page, restaurants }) => {
        const restaurantsStringQuery = generateQueryStringFromArrayObjects(restaurants as [], 'restaurants');
        const queryParamsString = restaurantsStringQuery ? `?page=${page}&${restaurantsStringQuery}` : `?page=${page}`;

        return addTenantToEndpoint(`/client/orders${queryParamsString}`);
      },
    }),
    getClientOrdersRestaurants: builder.query<ClientOrdersRestaurant[], void>({
      query: () => ({
        url: addTenantToEndpoint(`/client/orders/restaurants`),
      }),
      transformResponse: (response: { data: ClientOrdersRestaurant[] }) => response.data,
    }),
    getGuestOrders: builder.query<ClientOrdersMonitoring, number[] | []>({
      query: (order_ids) => ({
        url: addTenantToEndpoint(`/client/guest/orders`),
        params: {
          order_ids: `[${order_ids}]`,
        },
      }),
    }),
    getOrderStatuses: builder.query<OrderStatus[], void>({
      query: (page) => ({
        url: addTenantToEndpoint(`/orders-statuses`),
        params: {
          page,
        },
      }),
      transformResponse: (response: { data: OrderStatus[] }) => response.data,
    }),

    changeOrderStatus: builder.mutation<Order, { order_id: number; order_status_id: number }>({
      query: ({ order_id, order_status_id }) => ({
        url: `/orders/change/status/${order_id}`,
        method: 'PATCH',
        body: {
          order_status_id,
        },
      }),
      transformResponse: (response: { data: Order }) => response.data,

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success(t('changed'));
        } catch (error) {
          message.error(t('error_changing_status'));
        }
      },
    }),

    changeOrderStatusBySupervisor: builder.mutation<Order, { order_id: number; order_status_id: number }>({
      query: ({ order_id, order_status_id }) => ({
        url: addTenantToEndpoint(`/supervisor/orders/change/status/${order_id}`),
        method: 'PATCH',
        body: {
          order_status_id,
        },
      }),
      transformResponse: (response: { data: Order }) => response.data,

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success(t('changed'));
        } catch (error) {
          message.error(t('error_changing_status'));
        }
      },
    }),

    changeOrderStatusByWaiter: builder.mutation<Order, { order_id: number; order_status_id: number }>({
      query: ({ order_id, order_status_id }) => ({
        url: addTenantToEndpoint(`/waiter/orders/change/status/${order_id}`),
        method: 'PATCH',
        body: {
          order_status_id,
        },
      }),
      transformResponse: (response: { data: Order }) => response.data,

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success(t('changed'));
        } catch (error) {
          message.error(t('error_changing_status'));
        }
      },
    }),

    changeOrderWaiter: builder.mutation<any, { order_id: number; order_waiter_id: number }>({
      query: ({ order_id, order_waiter_id }) => ({
        url: `/orders/change/waiter/${order_id}`,
        method: 'PATCH',
        body: {
          waiter_id: order_waiter_id,
        },
      }),

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success(t('changed'));
        } catch (error) {
          message.error(t('error_changing_waiter'));
        }
      },
    }),

    changeOrderWaiterBySupervisor: builder.mutation<any, { order_id: number; order_waiter_id: number }>({
      query: ({ order_id, order_waiter_id }) => ({
        url: addTenantToEndpoint(`/supervisor/orders/change/waiter/${order_id}`),
        method: 'PATCH',
        body: {
          waiter_id: order_waiter_id,
        },
      }),

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success(t('changed'));
        } catch (error) {
          message.error(t('error_changing_waiter'));
        }
      },
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useChangeOrderStatusMutation,
  useChangeOrderWaiterMutation,
  useGetWaiterOrdersQuery,
  useChangeOrderStatusByWaiterMutation,
  useGetOrderStatusesQuery,
  useGetSupervisorOrdersQuery,
  useChangeOrderStatusBySupervisorMutation,
  useChangeOrderWaiterBySupervisorMutation,
  useGetClientOrdersQuery,
  useGetClientOrdersRestaurantsQuery,
  useGetGuestOrdersQuery,
} = extendedApiSlice;
