import RestaurantExistence from '@base/hoc/RestaurantExistence';
import Inventory from '@components/Inventory';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const InventoryPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.inventory'));

  return (
    <RestaurantExistence>
      <Inventory />
    </RestaurantExistence>
  );
};

export default InventoryPage;
