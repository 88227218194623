import { Restaurant } from '@api/models/Restaurant';
import { RestaurantInfo } from '@api/models/RestaurantInfo';
import { DishAvailability, DishNotAvailable } from '@base/types/ClientOrder';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import getBaseUrlApi from '@helpers/getBaseUrlApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const publicTenantApi = createApi({
  reducerPath: 'publicTenantApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrlApi(),
  }),
  endpoints: (builder) => ({
    getRestaurantByDomain: builder.query<RestaurantInfo, string | null>({
      query: (domain) => `/restaurant-by-domain/${domain}`,
      transformResponse: (response: { data: RestaurantInfo }) => response.data,
    }),
    getRestaurantById: builder.query<Restaurant, number | null>({
      query: (id) => addTenantToEndpoint(`/restaurant-info/${id}`),
      transformResponse: (response: { data: Restaurant }) => response.data,
    }),
    checkDishQuantityAvailability: builder.query<DishNotAvailable[], DishAvailability[]>({
      query(order) {
        return {
          url: addTenantToEndpoint('/dishes/availability'),
          method: 'POST',
          body: order,
        };
      },
      transformResponse: (response: { data: DishNotAvailable[] }) => response.data,
    }),
  }),
});

export default publicTenantApi;

export const {
  useLazyGetRestaurantByIdQuery,
  useLazyGetRestaurantByDomainQuery,
  useLazyCheckDishQuantityAvailabilityQuery,
} = publicTenantApi;
