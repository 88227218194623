import { useChangeAvailableDishOptionValueMutation } from '@api/dishOptionsApi';

const useMobileDishOptionValue = () => {
  const [updateAvailability] = useChangeAvailableDishOptionValueMutation();

  return {
    updateAvailability,
  };
};

export default useMobileDishOptionValue;
