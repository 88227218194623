import MBBurgerIcon from '@components/icons/MBBurgerIcon';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import DndDish from '@pages/Components/DndMenu/DndDish';
import { DishType, DND_SUBCATEGORY_TYPE, SubcategoryType } from '@pages/Components/DndMenu/DndMenu';
import { Collapse, CollapseProps } from 'antd';
import { FC, useMemo } from 'react';

const DndSubcategory: FC<{ subcategory: SubcategoryType; dishes?: DishType[] }> = ({ subcategory, dishes = [] }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: subcategory.dnd_id,
    data: {
      type: DND_SUBCATEGORY_TYPE,
      subcategory,
    },
  });

  const dishesIds = useMemo(() => {
    return dishes.map((dish) => dish.dnd_id);
  }, [dishes]);

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  const isOverClass = isOver ? 'border border-solid border-grey-40' : '';

  if (isDragging) {
    return (
      <div ref={setNodeRef} style={style} className={`flex`}>
        <div
          className={
            'mr-[24px] h-[40px] min-w-[236px] flex-shrink-0 flex-grow border-2 border-solid border-grey-40 bg-blue-400 p-2 opacity-60'
          }
        />
      </div>
    );
  }

  const renderChildren = () => {
    return (
      <div className={'min-h-[80px] flex-shrink-0 flex-grow'}>
        <div className={'flex flex-col gap-2'}>
          <SortableContext items={dishesIds}>
            {dishes.length > 0 && dishes.map((dish) => <DndDish key={dish.dnd_id} dish={dish} />)}
          </SortableContext>
        </div>
      </div>
    );
  };

  const items: CollapseProps['items'] = [
    {
      key: subcategory.dnd_id,
      label: subcategory.name,
      children: renderChildren(),
      destroyInactivePanel: true,
    },
  ];

  return (
    <div ref={setNodeRef} style={style}>
      <div className={'flex w-full justify-start gap-4'}>
        <MBBurgerIcon className={'mt-3 flex-shrink-0 grow-0'} {...listeners} {...attributes} />
        <Collapse size={'small'} className={`flex-grow bg-blue-300 ${isOverClass}`} items={items} />
      </div>
    </div>
  );
};

export default DndSubcategory;
