import { ClientOrderData } from '@base/types/ClientOrder';
import { LOCALSTORAGE_CLIENT_ORDER_KEY } from '@constants/constants';

export const getClientOrderFromLocalstorage = (): { clientOrder: ClientOrderData; restId: number } | null => {
  const data = localStorage.getItem(LOCALSTORAGE_CLIENT_ORDER_KEY);

  if (data) {
    return JSON.parse(data);
  }

  return null;
};
