import {
  useUpdateOnboardingClientMutation,
  useUpdateOnboardingManagerMutation,
  useUpdateOnboardingWorkerMutation,
} from '@api/onboardignApi';
import {
  OnboardingKeys,
  setOnboardingSaved,
  setOnboardingStep,
  setOnboardingStepsFinish,
} from '@base/redux/features/userSlice';
import { useAppSelector } from '@base/redux/store';
import MBArrowLeftIcon from '@components/icons/MBArrowLeftIcon';
import Tour from '@components/UI/Tour/index';
import { TOUR_DEFAULT_CONTENT_WIDTH } from '@constants/constants';
import useCheckRole from '@hooks/useCheckRole';
import { CheckboxProps, TourStepProps } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface TourStep extends Omit<TourStepProps, 'key'> {
  contentWidth?: number;
}

interface UseTourProps {
  onboardingKey: OnboardingKeys;
  steps: TourStep[];
}

const useTour = ({ onboardingKey, steps }: UseTourProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSupervisor, isWaiter, isClient } = useCheckRole();
  const isWorker = isSupervisor || isWaiter;

  const [isDOMReady, setIsDOMReady] = useState(false);
  const [isTourOpened, setIsTourOpened] = useState(true);
  const [isNotShowAgainState, setIsNotShowAgainState] = useState(false);

  const { id, onboarding } = useAppSelector((state) => state.userState);
  const onboardingData = onboarding[onboardingKey];
  const { currentStep, isFinished, isNotShowAgain, isSaved } = onboardingData;

  const isOnboard = onboardingData && !isNotShowAgain && isDOMReady;

  const onboardingSteps = steps?.length ? reformatTourSteps(steps) : [];

  const [updateOnboarding, { isLoading, isSuccess }] = isWorker
    ? useUpdateOnboardingWorkerMutation()
    : isClient
    ? useUpdateOnboardingClientMutation()
    : useUpdateOnboardingManagerMutation();

  const initTourPopupWidth = (width?: number) => {
    document.documentElement.style.setProperty('--tour-content-width', `${width || TOUR_DEFAULT_CONTENT_WIDTH}px`);
  };

  function reformatTourSteps(steps: TourStep[]): TourStep[] {
    if (!steps) {
      return [];
    }

    const onFinishTour = () => {
      dispatch(setOnboardingStepsFinish({ onboardingKey, isNotShowAgain: isNotShowAgainState }));
      setIsTourOpened(false);
    };

    return steps.map(({ title, description, target, ...props }, index) => {
      const nextBtnText = steps.length === index + 1 ? t('finish') : t('next');

      return {
        title,
        description: <div className={'tour-desc-wrap'}>{description}</div>,
        target,
        prevButtonProps: {
          children: (
            <div className={'flex items-center gap-1'}>
              {t('back')}
              <MBArrowLeftIcon className={'text-base'} />
            </div>
          ),
        },
        nextButtonProps: {
          children: nextBtnText,
        },
        onFinish: () => index === steps.length - 1 && onFinishTour(),
        ...props,
      };
    });
  }

  const onChangeOnboardingStep = (onboardingKey: OnboardingKeys, step: number) => {
    dispatch(setOnboardingStep({ onboardingKey, step }));
  };

  const onShowAgain: CheckboxProps['onChange'] = (e) => {
    setIsNotShowAgainState(e.target.checked);
  };

  const renderTour = () => (
    <Tour
      open={isTourOpened}
      onChange={(currentStep) => onChangeOnboardingStep(onboardingKey, currentStep)}
      onClose={() => setIsTourOpened(false)}
      steps={onboardingSteps}
      current={currentStep}
      onCheckboxChange={onShowAgain}
    />
  );

  useLayoutEffect(() => {
    const st = setTimeout(() => {
      setIsDOMReady(true);
    }, 200);

    return () => {
      clearTimeout(st);
    };
  }, []);

  useEffect(() => {
    if (steps.length && currentStep !== undefined) {
      initTourPopupWidth(steps[currentStep].contentWidth);
    }
  }, [currentStep]);

  useEffect(() => {
    if (id && isFinished && isNotShowAgain && !isSaved) {
      updateOnboarding({ id, data: onboarding });
    }
  }, [isFinished, isNotShowAgain]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setOnboardingSaved({ onboardingKey }));
    }
  }, [isLoading, isSuccess]);

  return {
    isOnboard,
    isTourOpened,
    isFinished,
    setIsTourOpened,
    initTourPopupWidth,
    onboardingSteps,
    renderTour,
  };
};

export default useTour;
