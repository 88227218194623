import { secondaryButton } from '@components/UI/SecondaryButton/secondaryButton.antd';
import { Button, ConfigProvider } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { FC } from 'react';

const SecondaryButton: FC<ButtonProps> = ({ className = '', children, ...props }) => {
  return (
    <ConfigProvider theme={secondaryButton}>
      <Button size={'small'} className={`secondary-button ${className}`} {...props}>
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default SecondaryButton;
