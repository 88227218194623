import { useTranslation } from 'react-i18next';

const PackageSuccessPaymentPage = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex h-full w-full'}>
      <p className={'m-auto text-2xl font-bold text-green-500'}>{t('successful_payment')}</p>
    </div>
  );
};

export default PackageSuccessPaymentPage;
