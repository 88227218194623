import { useSetTitlePage } from '@hooks/useSetTitlePage';

const UnauthorizedPage = () => {
  useSetTitlePage('Unauthorized');

  return (
    <div className={'pt-10 text-center'}>
      <h1>Unauthorized Page</h1>
    </div>
  );
};

export default UnauthorizedPage;
