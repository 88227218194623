import AccountSettingsInfo, {
  AccountSettingsClientPreferences,
  AccountSettingsPassword,
} from '@api/models/AccaountSettings';
import { Client } from '@api/models/Client';
import { User } from '@api/models/User';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['AccountSettings'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getManagerAccountInfo: builder.query<User, number>({
        query: (id) => `/account/${id}`,
        transformResponse: (response: { data: User }) => response.data,
        providesTags: ['AccountSettings'],
      }),
      getWorkerAccountInfo: builder.query<User, number>({
        query: (id) => addTenantToEndpoint(`/worker/account/${id}`),
        transformResponse: (response: { data: User }) => response.data,
        providesTags: ['AccountSettings'],
      }),
      getClientAccountInfo: builder.query<Client, number>({
        query: (id) => `/client/account/${id}`,
        transformResponse: (response: { data: Client }) => response.data,
        providesTags: ['AccountSettings'],
      }),
      updateManagerAccountInfo: builder.mutation<any, { id: number; data: AccountSettingsInfo }>({
        query: ({ id, data }) => ({
          url: `/account/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['AccountSettings'],
      }),
      updateManagerPassword: builder.mutation<any, { id: number; data: AccountSettingsPassword }>({
        query: ({ id, data }) => ({
          url: `/account/${id}`,
          method: 'PUT',
          body: data,
        }),
      }),
      deleteAccountByManager: builder.mutation<any, number>({
        query: (id) => ({
          url: `/account/${id}`,
          method: 'DELETE',
        }),
      }),
      updateWorkerAccountInfo: builder.mutation<any, { id: number; data: AccountSettingsInfo }>({
        query: ({ id, data }) => ({
          url: addTenantToEndpoint(`/worker/account/${id}`),
          method: 'PUT',
          body: data,
        }),

        transformResponse: (response: { data: User }) => response.data,
        invalidatesTags: ['AccountSettings'],
      }),
      updateWorkerAccountPassword: builder.mutation<any, { id: number; data: AccountSettingsPassword }>({
        query: ({ id, data }) => ({
          url: addTenantToEndpoint(`/worker/account/${id}`),
          method: 'PUT',
          body: data,
        }),
      }),
      updateClientAccountInfo: builder.mutation<any, { id: number; data: AccountSettingsInfo }>({
        query: ({ id, data }) => ({
          url: addTenantToEndpoint(`/client/account/${id}`),
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['AccountSettings'],
      }),
      updateClientAccountPassword: builder.mutation<any, { id: number; data: AccountSettingsPassword }>({
        query: ({ id, data }) => ({
          url: addTenantToEndpoint(`/client/account/${id}`),
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['AccountSettings'],
      }),
      updateClientAccountPreferences: builder.mutation<Client, { id: number; data: AccountSettingsClientPreferences }>({
        query: ({ id, data }) => ({
          url: addTenantToEndpoint(`/client/account/${id}`),
          method: 'PUT',
          body: data,
        }),
        transformResponse: (response: { data: Client }) => response.data,
        invalidatesTags: ['AccountSettings'],
      }),
    }),
  });

export const {
  useLazyGetManagerAccountInfoQuery,
  useUpdateManagerAccountInfoMutation,
  useLazyGetWorkerAccountInfoQuery,
  useUpdateWorkerAccountInfoMutation,
  useLazyGetClientAccountInfoQuery,
  useUpdateClientAccountInfoMutation,
  useUpdateManagerPasswordMutation,
  useUpdateWorkerAccountPasswordMutation,
  useUpdateClientAccountPasswordMutation,
  useUpdateClientAccountPreferencesMutation,
  useDeleteAccountByManagerMutation,
} = extendedApiSlice;
