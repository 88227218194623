import { Order } from '@api/models/Order';
import {
  useChangeOrderStatusBySupervisorMutation,
  useChangeOrderStatusByWaiterMutation,
  useChangeOrderStatusMutation,
} from '@api/ordersApi';
import useCheckRole from '@hooks/useCheckRole';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

const useMobileOrderItemHeader = () => {
  const { t } = useTranslation();
  const { isSupervisor, isWaiter } = useCheckRole();

  const [changedOrder, setChangedOrder] = useState<Order | null>(null);

  const [changeOrderStatus, { isLoading, isSuccess, data }] = isWaiter
    ? useChangeOrderStatusByWaiterMutation()
    : isSupervisor
    ? useChangeOrderStatusBySupervisorMutation()
    : useChangeOrderStatusMutation();

  const handleChangeStatus = (order_id: number, order_status_id: number) => {
    changeOrderStatus({ order_id, order_status_id });
  };

  const printReceipt = useReactToPrint({
    content: () => document.getElementById('kitchenReceipt'),
    copyStyles: true,
    bodyClass: document.documentElement.getAttribute('dir') === 'rtl' ? 'printable-qrcode-rtl-direction' : '',
  });

  useEffect(() => {
    const isPrint = !isLoading && isSuccess && data && data.status.status === 'in_progress';

    // open print dialog if order status is 'in_pending'
    if (isPrint) {
      setChangedOrder(data);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    const content = document.getElementById('kitchenReceipt');

    if (!content) {
      return;
    }

    printReceipt();
  }, [changedOrder]);

  return {
    t,
    handleChangeStatus,
    changedOrder,
  };
};

export default useMobileOrderItemHeader;
