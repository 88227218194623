import { Language } from '@utils/translation/i18n';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguageSwitcher = () => {
  const {
    i18n: { language, dir, changeLanguage: changeLng },
  } = useTranslation();

  const languages = [
    { value: Language.en, label: 'English (US)' },
    { value: Language.he, label: 'עִברִית' },
  ];

  const changeLanguage = useCallback(async (value: string) => {
    await changeLng(value);
  }, []);

  function setDir() {
    if (language === 'he') {
      document.dir = 'rtl';
      dir('ar');
    }

    if (language === 'en-US') {
      document.dir = 'ltr';
      dir('en');
    }
  }

  useEffect(() => {
    setDir();
  }, [language]);

  return {
    language,
    languages,
    changeLanguage,
  };
};
