import { SVGProps } from 'react';
const MBLockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.6667 7.33398H3.33333C2.59695 7.33398 2 7.93094 2 8.66732V13.334C2 14.0704 2.59695 14.6673 3.33333 14.6673H12.6667C13.403 14.6673 14 14.0704 14 13.334V8.66732C14 7.93094 13.403 7.33398 12.6667 7.33398Z"
      fill="#D6D8E1"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66669 7.33398V4.66732C4.66669 3.78326 5.01788 2.93542 5.643 2.3103C6.26812 1.68517 7.11597 1.33398 8.00002 1.33398C8.88408 1.33398 9.73192 1.68517 10.357 2.3103C10.9822 2.93542 11.3334 3.78326 11.3334 4.66732V7.33398"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MBLockIcon;
