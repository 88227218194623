import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';
import ClientRegistrationForm from '../../components/AuthForm/RegistrationForm';

const RegistrationPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.registration'));

  return (
    <AuthForm>
      <ClientRegistrationForm />
    </AuthForm>
  );
};

export default RegistrationPage;
