import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import useDashboardPromotions from '@components/Dashboard/DashboardPromotions/useDashboardPromotions';
import StatisticsBoard from '@components/UI/StatisticsBoard';
import StatisticsList from '@components/UI/StatisticsList';
import { ROUTES } from '@constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DashboardPromotions: FC<{ filter: string }> = ({ filter }) => {
  const { t } = useTranslation();
  const { promotions, isLoading, isError } = useDashboardPromotions(filter);
  const { isPushNotifications } = useAvailableFeatures();

  return (
    <StatisticsBoard
      title={t('the_best_orders_promotions')}
      linkMore={ROUTES.cabinet_promotions}
      className={'h-full min-h-[341px]'}
      isUnavailable={!isPushNotifications}
    >
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : promotions.length > 0 ? (
        <StatisticsList data={promotions} limit={8} isUnavailable={!isPushNotifications} />
      ) : !isPushNotifications ? (
        <BluredUnavailableFeature isWithoutBlure={true} />
      ) : (
        <p>{t('list_is_empty')}</p>
      )}
    </StatisticsBoard>
  );
};

export default DashboardPromotions;
