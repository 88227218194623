import { ClientOrder } from '@base/types/ClientOrder';
import { ROUTES } from '@constants/routes';
import { Button, Card } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ClientPaymentAuthSection: FC<{ order: ClientOrder }> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <p className={'mb-4 text-center text-base leading-[150%]'}>{t('registered_users_can_view_order_history')}</p>
      <Link to={`${ROUTES.client_login}?restaurant=${order.restaurant_domain}&payment=true`}>
        <Button type={'primary'} className={'mb-4 h-[52px] w-full justify-center'}>
          {t('payment_as_registered_user')}
        </Button>
      </Link>
      <div className={'flex items-center justify-center gap-4 px-8 py-2 opacity-[0.23]'}>
        <span>{t('you_have_not_registered_yet')}?</span>
        <Link
          className={'underline underline-offset-4'}
          to={`${ROUTES.client_registration}?restaurant=${order.restaurant_domain}&payment=true`}
        >
          {t('enrollment')}
        </Link>
      </div>
    </Card>
  );
};

export default ClientPaymentAuthSection;
