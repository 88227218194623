import { SVGProps } from 'react';
const MBLetterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.16667 2.6665H13.8333C14.5667 2.6665 15.1667 3.2665 15.1667 3.99984V11.9998C15.1667 12.7332 14.5667 13.3332 13.8333 13.3332H3.16667C2.43333 13.3332 1.83333 12.7332 1.83333 11.9998V3.99984C1.83333 3.2665 2.43333 2.6665 3.16667 2.6665Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1667 4L8.5 8.66667L1.83333 4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MBLetterIcon;
