import ClientPayment from '@components/Client/ClientPayment';
import { ROUTES } from '@constants/routes';
import { getClientOrderFromCookies } from '@helpers/getClientOrderFromCookies';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ClientPaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useSetTitlePage(t('pages.payment'));

  const cookieClientOrder = getClientOrderFromCookies();

  useLayoutEffect(() => {
    if (!cookieClientOrder) {
      navigate(ROUTES.root);
    }
  }, [cookieClientOrder]);

  return <ClientPayment />;
};

export default ClientPaymentPage;
