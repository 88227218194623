import { SVGProps } from 'react';
const MenuBitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 57 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2020_17758)">
      <path
        d="M2.26217 38.7254H0.000610352V28.9785H3.04819L5.8337 36.1381L8.59159 28.9785H11.6805V38.7254H9.41899V31.7575L6.70236 38.7254H4.96491L2.26217 31.7575V38.7254Z"
        fill="white"
      />
      <path
        d="M17.0647 31.4434C19.3262 31.4434 20.8707 32.9766 20.8707 35.1943C20.8758 35.4 20.8667 35.6058 20.8432 35.8103L15.3134 35.7966C15.4926 36.7822 16.1684 37.4256 17.1061 37.4256C17.8784 37.4256 18.5265 36.9876 18.747 36.3715L20.6086 36.6316C20.2639 38.0006 18.7746 38.9999 17.0509 38.9999C14.7756 38.9999 13.176 37.4256 13.176 35.1669C13.176 32.9766 14.7894 31.4434 17.0647 31.4434ZM18.7746 34.5646C18.6505 33.6747 17.961 33.0176 17.0233 33.0176C16.1132 33.0176 15.4789 33.6337 15.2996 34.5646H18.7746Z"
        fill="white"
      />
      <path
        d="M24.104 31.7172V32.6617C24.3461 32.2882 24.6787 31.9809 25.0714 31.7681C25.4641 31.5552 25.9043 31.4436 26.3517 31.4434C27.9375 31.4434 29.0131 32.6617 29.0131 34.3455V38.7261H26.9447V34.7014C26.9447 33.8527 26.4482 33.2503 25.6347 33.2503C24.8212 33.2503 24.1592 33.8937 24.1592 34.7836V38.7261H22.0906V31.7172H24.104Z"
        fill="white"
      />
      <path
        d="M35.305 38.7258V37.7675C35.0659 38.1448 34.7342 38.4557 34.3412 38.6712C33.9481 38.8867 33.5063 38.9996 33.0573 38.9996C31.4714 38.9996 30.3958 37.7812 30.3958 36.0974V31.7168H32.4643V35.7416C32.4643 36.5903 32.9608 37.1926 33.7743 37.1926C34.5878 37.1926 35.2499 36.5492 35.2499 35.6594V31.7168H37.3182V38.7258H35.305Z"
        fill="white"
      />
      <path
        d="M39.1284 38.7254V28.9785H43.8858C45.9267 28.9785 47.3609 30.1011 47.3609 31.6206C47.3609 32.6199 46.7818 33.4276 45.9406 33.6603C46.9472 33.9067 47.6366 34.8513 47.6366 36.0148C47.6366 37.6439 46.1611 38.7254 44.0513 38.7254H39.1284ZM41.4451 30.7171V32.8937H43.4723C44.3548 32.8937 44.989 32.4556 44.989 31.8122C44.989 31.1688 44.3548 30.7171 43.4723 30.7171L41.4451 30.7171ZM43.5825 34.4543H41.4451V36.9868H43.5825C44.5616 36.9868 45.2648 36.4666 45.2648 35.7411C45.2648 34.9882 44.5616 34.4543 43.5825 34.4543Z"
        fill="white"
      />
      <path
        d="M49.0083 29.6226C49.0083 28.9518 49.5048 28.4727 50.208 28.4727C50.9113 28.4727 51.4078 28.9518 51.4078 29.6226C51.4078 30.3071 50.9113 30.7863 50.208 30.7863C49.5048 30.7863 49.0083 30.3071 49.0083 29.6226ZM51.2422 31.7171V38.7261H49.1738V31.7171H51.2422Z"
        fill="white"
      />
      <path
        d="M53.2637 33.2777H52.2708V31.7171H53.3602L53.5946 29.9512H55.3321V31.7171H56.9318V33.2777H55.3321V36.2484C55.3321 36.837 55.6906 37.2339 56.2699 37.2339C56.5171 37.2281 56.7627 37.1913 57.0006 37.1245V38.7808C56.5403 38.9214 56.062 38.9951 55.5804 38.9999C54.1737 38.9999 53.2637 37.9458 53.2637 36.4536L53.2637 33.2777Z"
        fill="white"
      />
      <path
        opacity={0.78}
        d="M17.3501 2.01148L17.3501 2.0115C14.6465 4.69538 14.6465 9.04681 17.3501 11.7307L29.8607 24.1504C32.5643 26.8342 36.9476 26.8342 39.6511 24.1504L39.6512 24.1503C42.3547 21.4665 42.3547 17.115 39.6512 14.4312L27.1405 2.01148C24.437 -0.672396 20.0536 -0.672396 17.3501 2.01148Z"
        fill="white"
      />
      <path
        opacity={0.78}
        d="M29.8607 2.01175L17.3501 14.4314C14.6465 17.1153 14.6465 21.4667 17.3501 24.1506L17.3501 24.1506C20.0536 26.8345 24.437 26.8345 27.1405 24.1506L39.6512 11.731C42.3547 9.04707 42.3547 4.69565 39.6512 2.01177L39.6511 2.01175C36.9476 -0.67213 32.5643 -0.672132 29.8607 2.01175Z"
        fill="white"
      />
      <path d="M32.4736 8.96484H24.5277V10.5741H32.4736V8.96484Z" fill="#1A1A1A" />
      <path d="M32.4736 12.2773H24.5277V13.8866H32.4736V12.2773Z" fill="#1A1A1A" />
      <path d="M32.4735 15.5898H24.5276V17.1991H32.4735V15.5898Z" fill="#1A1A1A" />
    </g>
    <defs>
      <clipPath id="clip0_2020_17758">
        <rect width={57} height={39} fill="white" transform="translate(0.000610352)" />
      </clipPath>
    </defs>
  </svg>
);
export default MenuBitIcon;
