import placeholder from '@base/assets/img/hero-restaurant-placeholder.jpg';
import { FC } from 'react';

interface HeroProps {
  url?: string | null | undefined;
  name?: string | null | undefined;
}

const Hero: FC<HeroProps> = ({ url, name }) => {
  return (
    <div className={'relative flex h-[206px] text-center mobile-menu__restaurant-hero'}>
      <div className={'mobile-menu__restaurant-preview-hero restaurant-preview-hero absolute left-0 top-0 z-0 h-[206px] w-full'}>
        <img className={'h-full w-full object-cover'} src={url || placeholder} alt="background restaurant image" />
      </div>
      <div className={'relative z-[1] mx-auto mt-9 line-clamp-1 px-4 text-2xl font-bold text-white mobile-menu__title'}>{name}</div>
    </div>
  );
};

export default Hero;
