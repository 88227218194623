import { FC, ReactNode } from 'react';

interface OrderSectionProps {
  children: ReactNode;
  isWhiteBg?: boolean;
}

const OrderSection: FC<OrderSectionProps> = ({ children, isWhiteBg }) => {
  return (
    <div className={`flex flex-col gap-2 rounded p-4 leading-[1.5] ${isWhiteBg ? 'bg-white' : 'bg-grey-5'} order-section`}>
      {children}
    </div>
  );
};

export default OrderSection;
