import useAccountPassword from '@components/AccountSettings/AccountPassword/useAccountPassword';
import SimpleSection from '@components/UI/SimpleSection';
import { regExPassword } from '@constants/regexp';
import { Button, Card, Col, Form, Input, Row } from 'antd';

interface AccountPasswordFrom {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const AccountPassword = () => {
  const { t, form, onFinish, isUpdating } = useAccountPassword();

  return (
    <SimpleSection title={t('password')}>
      <Card>
        <Form
          form={form}
          name="account_password"
          layout="vertical"
          initialValues={{ remember: false }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} lg={8}>
              <Form.Item<AccountPasswordFrom>
                label={t('current_password')}
                name="old_password"
                rules={[{ required: true, message: t('input_errors.password_validation') }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item<AccountPasswordFrom>
                label={t('password')}
                name="password"
                rules={[{ required: true, pattern: regExPassword, message: t('input_errors.password_validation') }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item<AccountPasswordFrom>
                label={t('password_verify')}
                name="password_confirmation"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('required_field'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(t('input_errors.password_not_match')));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Button type={'primary'} size={'small'} htmlType={'submit'} loading={isUpdating}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </SimpleSection>
  );
};

export default AccountPassword;
