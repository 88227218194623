import { useModalWindowSize } from '@hooks/useModalWindowSize';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ShowModalConfirmParam {
  modal: any;
  title: ReactNode;
  content: ReactNode;
  okCallback: () => void;
}

const useModalConfirm = () => {
  const { t } = useTranslation();
  const showModalConfirm = ({ modal, title, content, okCallback }: ShowModalConfirmParam) => {
    const { addBodyClassBeforeModalOpen, removeBodyClassBeforeModalOpen } = useModalWindowSize();

    addBodyClassBeforeModalOpen();

    modal.confirm({
      title,
      icon: null,
      closeIcon: true,
      content,
      okText: t('okay'),
      okType: 'danger',
      cancelText: t('no_cancel'),
      centered: true,
      okButtonProps: {
        type: 'primary',
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: async () => {
        await okCallback();
        await removeBodyClassBeforeModalOpen();
      },
      width: 590,
      afterClose: () => removeBodyClassBeforeModalOpen(),
    });
  };

  return {
    showModalConfirm,
  };
};

export default useModalConfirm;
