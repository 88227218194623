import { FC, ReactNode } from 'react';
import { Scrollbar, ScrollbarProps } from 'react-scrollbars-custom';

interface CustomScrollbarProps extends ScrollbarProps {
  children: ReactNode;
  isShowScrollbar?: boolean;
}

const CustomScrollbar: FC<CustomScrollbarProps> = ({ children, isShowScrollbar = true, ref, ...props }) => {
  return <>{isShowScrollbar ? <Scrollbar {...props}>{children}</Scrollbar> : <>{children}</>}</>;
};

export default CustomScrollbar;
