import DishExtra from '@api/models/DishExtra';
import DishOption from '@api/models/DishOption';
import { PromotionDishSelectedExtras, PromotionDishSelectedOptions } from '@api/models/Promotion';
import { useLazyGetPromotionsQuery } from '@api/promotionApi';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import {
  preparePromotionExtras,
  preparePromotionOpts,
} from '@components/Promotions/PromotionModalForm/usePromotionModalForm';
import { Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

export interface HandleEditProps {
  id?: number;
  name?: string;
  description?: string;
  old_price?: number;
  new_price?: number;
  dish_id?: number;
  promotion_type_id?: number;
  dish_price?: number;
  options: DishOption[] | null;
  extras: DishExtra[] | null;
  selected_options?: PromotionDishSelectedOptions[] | null;
  selected_extras?: PromotionDishSelectedExtras[] | null;
}

export const OLD_PROMOTION_PRICE_NAME_FIELD = 'old_price';
export const DISH_PRICE_NAME_FIELD = 'dish_price';

const usePromotions = () => {
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const [getDataByPage, { data, isLoading, isFetching, isError }] = useLazyGetPromotionsQuery();

  const cards = data?.data || [];
  const pagination = data?.meta;
  const isShowPagination = !isLoading && pagination && pagination.total > pagination.per_page;

  const setFormValues = async (data: HandleEditProps) => {
    const options = data.options;
    const selectedOptions = data.selected_options;
    const preparedOpts = options ? preparePromotionOpts(options, selectedOptions) : null;

    const extras = data.extras;
    const selectedExtras = data.selected_extras;
    const preparedExtras = extras ? preparePromotionExtras(extras, selectedExtras) : null;

    form.setFieldValue('id', data.id);

    if (data.name) {
      form.setFieldValue('name', data.name);
    }

    if (data.description) {
      form.setFieldValue('description', data.description);
    }

    form.setFieldValue('dish_id', data.dish_id);
    form.setFieldValue('promotion_type_id', data.promotion_type_id || 1); // TODO: there will several types of promotions, need to change this
    form.setFieldValue('new_price', data.new_price);
    form.setFieldValue(OLD_PROMOTION_PRICE_NAME_FIELD, data.old_price);
    form.setFieldValue(DISH_PRICE_NAME_FIELD, data.dish_price);
    form.setFieldValue(OPTIONS_NAME_FIELD, preparedOpts);
    form.setFieldValue(EXTRAS_NAME_FIELD, preparedExtras);
  };

  const handleChangePage = async (page: number) => {
    await getDataByPage(page);
  };

  useEffect(() => {
    getDataByPage(1);
  }, []);

  // reset page to 1 when data is empty on the another pages
  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (!cards.length && pagination && pagination.current_page !== 1) {
        getDataByPage(1);
      }
    }
  }, [cards, isLoading, isFetching]);

  return {
    form,
    isOpen,
    modal,
    contextHolder,
    setIsOpen,
    isGetting: isLoading,
    cards,
    isShowPagination,
    pagination,
    isError,
    setFormValues,
    handleChangePage,
  };
};

export default usePromotions;
