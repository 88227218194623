import CallToWaiterRequests from '@base/components/CallToWaiterRequests';
import MBCallWaiterIcon from '@base/components/icons/MBCallWaiterIcon';
import MBFilterIcon from '@base/components/icons/MBFilterIcon';
import MobileCart from '@components/MobileMenu/MobileCart';
import { centralDomain } from '@constants/environments';
import useHeader from '@layout/Header/useHeader';
import MobileNav from '@layout/MobileNav';
import { ROUTES } from '@utils/constants/routes';
import { Button, Space } from 'antd';
import { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderProfile from '../../components/HeaderProfile';
import Logo from '../../components/icons/Logo';
import MBUserLoggedInIcon from '../../components/icons/MBUserLoggedInIcon';
import HeaderCallToWaiterModal from './HeaderCallToWaiterModal';
import HeaderMenuFilterModal from './HeaderMenuFilterModal';
import HeaderRegisterModal from './HeaderRegisterModal';

const Header = () => {
  const {
    t,
    dispatch,
    isLG,
    isAuth,
    isSubdomain,
    subdomain,
    isTableSelected,
    isClient,
    isWaiter,
    isModalFilterOpen,
    isModalCallWaiterOpen,
    location,
    restaurantParam,
    ref,
    isShowFilter,
    isShowCallWaiter,
    isWaiterHeader,
    isClientHeader,
    isOrderPlacementPayment,
    isPaymentMethod,
    setIsModalFilterOpen,
    setIsModalCallWaiterOpen,
    handleCallWaiterClick,
    handleFilterClick,
  } = useHeader();

  useLayoutEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${ref.current.offsetHeight}px`);
  }, []);

  const renderFilterModal = () => (
    <HeaderRegisterModal
      key={'header-register-filter'}
      centralDomain={centralDomain}
      title={`${t('what_to_show')}?`}
      text={t('option_to_filter_available_to_registered_users')}
      restaurant={subdomain || restaurantParam}
      setIsOpened={setIsModalFilterOpen}
    />
  );

  const renderCallWaiterModal = () => (
    <HeaderRegisterModal
      key={'header-register-call-waiter'}
      centralDomain={centralDomain}
      title={`${t('call_the_waiter')}?`}
      text={t('option_to_call_waiter_available_to_registered_users')}
      restaurant={subdomain || restaurantParam}
      setIsOpened={setIsModalCallWaiterOpen}
    />
  );

  return (
    <header
      className={'shadow-common site-header relative flex items-center gap-1 bg-white px-4 py-2 lg:px-10 lg:py-4'}
      ref={ref}
    >
      <div className={`flex items-center gap-4 ltr:mr-auto rtl:ml-auto ${isWaiter && 'w-[50%] justify-between'}`}>
        {isClientHeader && (
          <>
            {isShowCallWaiter && (
              <Button
                id={'client-call-waiter'}
                type="primary"
                icon={<MBCallWaiterIcon />}
                className={'site-header__client-button w-max min-w-[56px] justify-center rounded-[100px] text-2xl'}
                onClick={handleCallWaiterClick}
                disabled={!isTableSelected || location.pathname === ROUTES.root}
              />
            )}

            {isShowFilter && (
              <Button
                id={'client-filter-menu'}
                type="primary"
                icon={<MBFilterIcon />}
                className={'site-header__client-button w-max min-w-[56px] justify-center rounded-[100px] text-2xl'}
                onClick={handleFilterClick}
                disabled={!isTableSelected || location.pathname === ROUTES.root}
              />
            )}

            {isTableSelected && isAuth ? (
              <>
                {isModalFilterOpen && location.pathname !== ROUTES.root && (
                  <HeaderMenuFilterModal setIsOpened={setIsModalFilterOpen} />
                )}
                {isModalCallWaiterOpen && location.pathname !== ROUTES.root && (
                  <HeaderCallToWaiterModal setIsOpened={setIsModalCallWaiterOpen} />
                )}
              </>
            ) : (
              <>
                {isModalCallWaiterOpen && location.pathname !== ROUTES.root && renderCallWaiterModal()}
                {isModalFilterOpen && location.pathname !== ROUTES.root && renderFilterModal()}
              </>
            )}
          </>
        )}

        {isWaiterHeader && (
          <>
            <CallToWaiterRequests />
            <Logo
              height={isLG ? 30 : 24}
              className={
                isWaiter ? 'relative -translate-x-1/2 translate-y-0 rotate-0 skew-x-0 skew-y-0 scale-100 transform' : ''
              }
            />
          </>
        )}
      </div>

      <div>
        {isLG ? (
          <div className={'hidden lg:block'}>
            {isAuth ? (
              <HeaderProfile />
            ) : (
              <Space>
                {isSubdomain || restaurantParam ? (
                  <div className={'flex gap-4'}>
                    {isSubdomain && (
                      <Link to={ROUTES.worker_login}>
                        <Button>Worker login</Button>
                      </Link>
                    )}

                    <Link to={`${centralDomain}${ROUTES.client_login}?restaurant=${subdomain || restaurantParam}`}>
                      <Button>Client login</Button>
                    </Link>
                    <Link
                      to={`${centralDomain}${ROUTES.client_registration}?restaurant=${subdomain || restaurantParam}`}
                    >
                      <Button>Client register</Button>
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link to={ROUTES.registration}>
                      <Button type={'primary'}>{t('signup')}</Button>
                    </Link>
                    <Link to={ROUTES.login}>
                      <Button>
                        {t("companie's")}
                        <MBUserLoggedInIcon />
                      </Button>
                    </Link>
                  </>
                )}
              </Space>
            )}
          </div>
        ) : (
          <MobileNav />
        )}

        {isClient && isOrderPlacementPayment && isPaymentMethod && <MobileCart />}
      </div>
    </header>
  );
};

export default Header;
