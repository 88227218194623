import { useRegisterMangerByGoogleMutation } from '@api/authApi';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

const useGoogleRegistrationForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [register, { isLoading, isSuccess }] = useRegisterMangerByGoogleMutation();
  const onFinish = async (values: any) => {
    await register(values);
  };

  return {
    t,
    form,
    onFinish,
    isLoading,
    isSuccess,
  };
};

export default useGoogleRegistrationForm;
