import api from '@api/api';
import { Menu, PositionMenu } from '@api/models/Menu';
import { generateQueryStringFromArrayObjects } from '@base/utils/helpers/generateQueryStringFromArrayObjects';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { Allergy, DishType } from './models/Dish';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Menu'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMenus: builder.query<
        Menu[],
        { allergies?: Allergy[] | []; dishTypes?: DishType[] | []; isTakeAway?: boolean }
      >({
        query: ({ allergies, dishTypes, isTakeAway }) => {
          // TODO: make research about passing arrays as params in RTK
          const queryParams = [];
          const allergiesStringQuery = generateQueryStringFromArrayObjects(allergies as [], 'allergies', 'code');
          const dishTypesStringQuery = generateQueryStringFromArrayObjects(dishTypes as [], 'dish_type', 'code');

          if (allergiesStringQuery) {
            queryParams.push(allergiesStringQuery);
          }

          if (dishTypesStringQuery) {
            queryParams.push(dishTypesStringQuery);
          }

          if (isTakeAway) {
            queryParams.push('is_take_away=true');
          }

          const queryParamsString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

          return addTenantToEndpoint(`/menu${queryParamsString}`);
        },
        transformResponse: (response: { data: Menu[] }) => response.data,
        providesTags: ['Menu'],
      }),
      createMenu: builder.mutation<any, any>({
        query(data) {
          return {
            url: `/menu`,
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: ['Menu'],
      }),
      updateMenu: builder.mutation<any, any>({
        query({ id, data }) {
          return {
            url: `/menu/${id}`,
            method: 'PUT',
            body: data,
            headers: {
              accept: 'application/json',
            },
          };
        },
        invalidatesTags: ['Menu'],
      }),
      removeMenu: builder.mutation<any, any>({
        query(id) {
          return {
            url: `/menu/${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['Menu'],
      }),
      changePositionMenu: builder.mutation<any, { id: number; data: PositionMenu }>({
        query({ id, data }) {
          return {
            url: `/menu/change-position/${id}`,
            method: 'PATCH',
            body: data,
          };
        },
        invalidatesTags: ['Menu'],
      }),
    }),
  });

export const {
  useGetMenusQuery,
  useCreateMenuMutation,
  useUpdateMenuMutation,
  useRemoveMenuMutation,
  useChangePositionMenuMutation,
} = extendedApiSlice;
