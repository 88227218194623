import { useLoginWorkerMutation } from '@api/authApi';
import { setRestId } from '@base/redux/features/restaurantSettingsSlice';
import { setOnboarding, setRestaurantId, setRole, setToken, setUser, setUserId } from '@base/redux/features/userSlice';
import { useAppDispatch, useAppSelector } from '@base/redux/store';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import setRoleToCookies from '@helpers/setRoleToCookies';
import setUserToCookie from '@helpers/setUserToCookie';
import useAuth from '@hooks/useAuth';
import { ROUTES } from '@utils/constants/routes';
import { Button, Col, Form, Input, message, Row, Space } from 'antd';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface FieldType {
  email?: string;
  password?: string;
}

const WorkerLoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth, restaurantId } = useAuth();
  const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);
  const [loginWorker, { isLoading, isError, isSuccess, data }] = useLoginWorkerMutation();

  useLayoutEffect(() => {
    if (isAuth) {
      const from =
        ((location.state as any)?.from.pathname as string) || restaurantId ? ROUTES.dashboard : ROUTES.restaurant;

      navigate(from);
    }
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && isSuccess && data) {
      const { remember_token, id, role, restaurant_id, onboarding } = data;

      dispatch(setToken(remember_token));
      dispatch(setUserId(id));
      dispatch(setRole(role));
      dispatch(setUser(data));
      dispatch(setRestaurantId(restaurant_id));
      dispatch(setRestId(restaurant_id));
      dispatch(setOnboarding(onboarding));

      setUserToCookie({
        id: data.id,
        role: data.role,
        token: data.remember_token,
        restaurant_id: data.restaurant_id,
      });

      setRoleToCookies('worker');

      navigate(role === 'supervisor' ? ROUTES.dashboard : ROUTES.root);
    }

    if (isError) {
      // TODO: handler errors from server by status code
      message.error(t('server_errors.bed_credentials'));
    }
  }, [isLoading]);

  const onFinish = (values: any) => {
    loginWorker(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={' p-6 lg:mx-auto lg:box-content lg:max-w-[400px] lg:px-10 lg:py-14'}>
      <div className={'flex flex-col gap-10 lg:py-[100px]'}>
        <header>
          <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('entrance')}</div>
        </header>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item<FieldType>
                label={t('email')}
                name="email"
                rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
              >
                <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item<FieldType>
                label={t('password')}
                name="password"
                rules={[{ required: true, message: t('required_field') }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Link to={ROUTES.worker_forgot_password}>{t('forget_password')}</Link>

          <Form.Item className={'mb-0'}>
            <Button
              type="primary"
              htmlType="submit"
              className={'mt-6 w-full justify-center lg:w-[206px]'}
              loading={isLoading}
            >
              {t('login')}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {!isSubdomain && (
        <footer className={'mt-10 py-4 text-center'}>
          <Space wrap size={'middle'}>
            <span className={'text-grey-40'}>{t('first_time')}</span>
            <Link to={ROUTES.registration} className={'underline underline-offset-4'}>
              {t('register')}
            </Link>
          </Space>
        </footer>
      )}
    </div>
  );
};

export default WorkerLoginForm;
