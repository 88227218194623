import { useRemoveAllServicesRequestsMutation } from '@api/callToWaiterApi';
import { resetCalledServicesIds } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import useCheckRole from '@hooks/useCheckRole';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useClientBindingTable = () => {
  const dispatch = useDispatch();
  const [removeAllServicesRequests, { isLoading, isSuccess }] = useRemoveAllServicesRequestsMutation();
  const { isClient } = useCheckRole();
  const client_id = useAppSelector((state) => state.userState.id);
  const table = useAppSelector((state) => state.clientSlice.table);
  const waiter_id = table.waiter?.id;
  const table_id = table?.table_id || table?.table_take_away_id;

  const removeAllServices = async () => {
    if (!isClient || !table_id || !client_id) {
      return;
    }

    await removeAllServicesRequests({ table_id, waiter_id, client_id });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(resetCalledServicesIds());
    }
  }, [isLoading, isSuccess]);

  return { removeAllServices };
};

export default useClientBindingTable;
