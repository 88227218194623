import InventoryWarning from '@components/svg/InventoryWarning';
import MyModal from '@components/UI/MyModal';
import { ROUTES } from '@constants/routes';
import { Button } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface InventoryWarningModalProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

const InventoryWarningModal: FC<InventoryWarningModalProps> = ({ isOpened, setIsOpened }) => {
  const { t } = useTranslation();

  return (
    <MyModal
      centered
      open={isOpened}
      width={365}
      onCancel={() => setIsOpened(false)}
      footer={
        <Link to={ROUTES.inventory}>
          <Button type={'primary'} onClick={() => setIsOpened(false)}>
            {t('go_to_inventory')}
          </Button>
        </Link>
      }
    >
      <div className={'pt-6 text-center'}>
        <InventoryWarning className={'mb-6'} />
        <div className={'text-2xl font-bold text-red-40'}>{t('portion_running_out')}</div>
      </div>
    </MyModal>
  );
};

export default InventoryWarningModal;
