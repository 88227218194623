import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.96384 18V9.78936H9.85853L10.2919 6.5895H6.96376V4.54653C6.96376 3.6201 7.23394 2.98879 8.62938 2.98879L10.4091 2.98799V0.126072C10.1013 0.0871459 9.04474 0 7.81576 0C5.24974 0 3.493 1.49118 3.493 4.22972V6.5895H0.590881V9.78936H3.493V17.9999H6.96384V18Z"
      fill="currentColor"
    />
  </svg>
);
export default FacebookIcon;
