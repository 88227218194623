import { useMediaQuery } from 'react-responsive';

const useGetMediaQuery = () => {
  return {
    isMobile: useMediaQuery({ maxWidth: 575 }),
    isSM: useMediaQuery({ minWidth: 576 }),
    isMD: useMediaQuery({ minWidth: 768 }),
    isLG: useMediaQuery({ minWidth: 992 }),
    isXL: useMediaQuery({ minWidth: 1200 }),
    is2XL: useMediaQuery({ minWidth: 1600 }),
  };
};

export default useGetMediaQuery;
