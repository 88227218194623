import { useAppSelector } from '@base/redux/store';
import { QR_SCAN_GET_PARAMETER } from '@constants/constants';
import addSubdomainToUrl from '@helpers/addSubdomainToUrl';
import { Button, QRCode } from 'antd';
import { useTranslation } from 'react-i18next';
import MBDownloadIcon from '../../icons/MBDownloadIcon';

const QRPreviewDownload = () => {
  const { t } = useTranslation();

  const { foregroundColor, backgroundColor } = useAppSelector((state) => state.qrCodeSlice);
  const subdomain = useAppSelector((state) => state.commonSlice.subdomain);

  const subdomainUrl = `${addSubdomainToUrl(subdomain)}?${QR_SCAN_GET_PARAMETER}=true`;

  const handleDownloadClick = () => {
    const canvas = document.getElementById('qrbuilder-qrcode')?.querySelector<HTMLCanvasElement>('canvas');

    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
      <h5 className={'mb-4 text-center text-base font-bold leading-[1.2]'}>{t('qr_code')}</h5>
      <div
        id="qrbuilder-qrcode"
        className={'mb-4 flex h-[390px] flex-col items-center justify-start gap-y-6 rounded bg-grey-5 px-10 py-10'}
      >
        <QRCode value={subdomainUrl} errorLevel="H" size={255} color={foregroundColor} bgColor={backgroundColor} />
      </div>
      <div className={'flex justify-center'}>
        <Button icon={<MBDownloadIcon />} onClick={handleDownloadClick}>
          {t('download_image')}
        </Button>
      </div>
    </>
  );
};

export default QRPreviewDownload;
