import { SVGProps } from 'react';
const MenubitRegisterLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 103 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M73.5081 65.7569C67.7805 71.4163 58.4793 71.4163 52.7272 65.7569L26.1941 39.516C20.4665 33.8567 20.4665 24.6663 26.1941 18.9828C31.9218 13.3234 41.223 13.3234 46.9751 18.9828L73.5081 45.1995C79.2357 50.883 79.2357 60.0734 73.5081 65.7569Z"
      fill="url(#paint0_linear_4428_21674)"
    />
    <path
      opacity={0.3}
      d="M26.1941 65.7551C20.4665 60.0933 20.4665 50.8989 26.1941 45.2129L52.7272 18.9846C58.4548 13.3228 67.756 13.3228 73.5081 18.9846C79.2357 24.6465 79.2357 33.8408 73.5081 39.5268L46.9751 65.7551C41.223 71.4169 31.9218 71.4169 26.1941 65.7551Z"
      fill="url(#paint1_linear_4428_21674)"
    />
    <path
      d="M43.6858 49.5883C44.3301 48.4916 45.2357 47.5852 46.3154 46.9562C47.3952 46.3273 48.6128 45.9969 49.8512 45.9969C51.0896 45.9969 52.3072 46.3273 53.387 46.9562C54.4667 47.5852 55.3723 48.4916 56.0166 49.5883M49.8512 43.749C50.8085 43.749 51.7267 43.3546 52.4036 42.6527C53.0806 41.9507 53.4609 40.9986 53.4609 40.0058C53.4609 39.0131 53.0806 38.061 52.4036 37.359C51.7267 36.6571 50.8085 36.2627 49.8512 36.2627C48.8938 36.2627 47.9757 36.6571 47.2987 37.359C46.6218 38.061 46.2415 39.0131 46.2415 40.0058C46.2415 40.9986 46.6218 41.9507 47.2987 42.6527C47.9757 43.3546 48.8938 43.749 49.8512 43.749ZM49.8512 51.9839C52.3403 51.9839 54.7275 50.9586 56.4876 49.1334C58.2476 47.3083 59.2364 44.8329 59.2364 42.2517C59.2364 39.6706 58.2476 37.1952 56.4876 35.37C54.7275 33.5449 52.3403 32.5195 49.8512 32.5195C47.3621 32.5195 44.9749 33.5449 43.2148 35.37C41.4547 37.1952 40.4659 39.6706 40.4659 42.2517C40.4659 44.8329 41.4547 47.3083 43.2148 49.1334C44.9749 50.9586 47.3621 51.9839 49.8512 51.9839Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.3}
      d="M101.167 40.8101C99.8943 42.0678 97.8273 42.0678 96.5491 40.8101L90.6528 34.9788C89.38 33.7212 89.38 31.6789 90.6528 30.4159C91.9256 29.1582 93.9926 29.1582 95.2708 30.4159L101.167 36.2418C102.44 37.5048 102.44 39.5471 101.167 40.8101Z"
      fill="url(#paint2_linear_4428_21674)"
    />
    <path
      opacity={0.3}
      d="M90.6528 40.8097C89.38 39.5515 89.38 37.5083 90.6528 36.2448L96.5491 30.4163C97.8219 29.1581 99.8888 29.1581 101.167 30.4163C102.44 31.6745 102.44 33.7177 101.167 34.9812L95.2708 40.8097C93.9926 42.0679 91.9256 42.0679 90.6528 40.8097Z"
      fill="url(#paint3_linear_4428_21674)"
    />
    <g opacity={0.5}>
      <path
        opacity={0.3}
        d="M13.6049 69.2458C12.5866 70.2519 10.9331 70.2519 9.91047 69.2458L5.19349 64.5807C4.17525 63.5746 4.17525 61.9408 5.19349 60.9304C6.21173 59.9243 7.86529 59.9243 8.88788 60.9304L13.6049 65.5911C14.6231 66.6015 14.6231 68.2354 13.6049 69.2458Z"
        fill="url(#paint4_linear_4428_21674)"
      />
      <path
        opacity={0.3}
        d="M5.19349 69.2454C4.17525 68.2389 4.17525 66.6043 5.19349 65.5935L9.91047 60.9307C10.9287 59.9241 12.5823 59.9241 13.6049 60.9307C14.6231 61.9372 14.6231 63.5718 13.6049 64.5826L8.88788 69.2454C7.86529 70.252 6.21173 70.252 5.19349 69.2454Z"
        fill="url(#paint5_linear_4428_21674)"
      />
    </g>
    <g opacity={0.5} clipPath="url(#clip0_4428_21674)">
      <path
        opacity={0.3}
        d="M80.5978 17.7461C81.2395 16.6538 82.1414 15.751 83.2168 15.1246C84.2922 14.4982 85.5049 14.1692 86.7383 14.1692C87.9717 14.1692 89.1844 14.4982 90.2598 15.1246C91.3352 15.751 92.2371 16.6538 92.8788 17.7461M86.7383 11.9303C87.6918 11.9303 88.6062 11.5375 89.2805 10.8384C89.9547 10.1392 90.3335 9.19098 90.3335 8.20223C90.3335 7.21349 89.9547 6.26524 89.2805 5.56609C88.6062 4.86694 87.6918 4.47416 86.7383 4.47416C85.7848 4.47416 84.8704 4.86694 84.1961 5.56609C83.5219 6.26524 83.1431 7.21349 83.1431 8.20223C83.1431 9.19098 83.5219 10.1392 84.1961 10.8384C84.8704 11.5375 85.7848 11.9303 86.7383 11.9303ZM86.7383 20.1321C89.2174 20.1321 91.5949 19.1108 93.3479 17.2931C95.1009 15.4753 96.0857 13.0098 96.0857 10.4391C96.0857 7.86834 95.1009 5.40289 93.3479 3.5851C91.5949 1.76732 89.2174 0.746094 86.7383 0.746094C84.2592 0.746094 81.8816 1.76732 80.1287 3.5851C78.3757 5.40289 77.3909 7.86834 77.3909 10.4391C77.3909 13.0098 78.3757 15.4753 80.1287 17.2931C81.8816 19.1108 84.2592 20.1321 86.7383 20.1321Z"
        stroke="url(#paint6_linear_4428_21674)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_4428_21674)">
      <path
        opacity={0.3}
        d="M3.87908 40.5859C4.36981 39.7506 5.0595 39.0603 5.88186 38.5813C6.70421 38.1023 7.63157 37.8507 8.57479 37.8507C9.518 37.8507 10.4454 38.1023 11.2677 38.5813C12.0901 39.0603 12.7798 39.7506 13.2705 40.5859M8.57479 36.1386C9.30393 36.1386 10.0032 35.8382 10.5188 35.3036C11.0344 34.7689 11.324 34.0438 11.324 33.2877C11.324 32.5316 11.0344 31.8065 10.5188 31.2718C10.0032 30.7372 9.30393 30.4368 8.57479 30.4368C7.84564 30.4368 7.14636 30.7372 6.63078 31.2718C6.1152 31.8065 5.82554 32.5316 5.82554 33.2877C5.82554 34.0438 6.1152 34.7689 6.63078 35.3036C7.14636 35.8382 7.84564 36.1386 8.57479 36.1386ZM8.57479 42.4105C10.4706 42.4105 12.2887 41.6296 13.6292 40.2395C14.9697 38.8494 15.7228 36.9641 15.7228 34.9982C15.7228 33.0324 14.9697 31.147 13.6292 29.7569C12.2887 28.3669 10.4706 27.5859 8.57479 27.5859C6.67901 27.5859 4.86088 28.3669 3.52037 29.7569C2.17985 31.147 1.42676 33.0324 1.42676 34.9982C1.42676 36.9641 2.17985 38.8494 3.52037 40.2395C4.86088 41.6296 6.67901 42.4105 8.57479 42.4105Z"
        stroke="url(#paint7_linear_4428_21674)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4428_21674"
        x1={54.7033}
        y1={17.9785}
        x2={45.2392}
        y2={66.8043}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4428_21674"
        x1={74.5262}
        y1={47.1449}
        x2={25.1975}
        y2={37.3555}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4428_21674"
        x1={96.9882}
        y1={30.1927}
        x2={94.8851}
        y2={41.0429}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4428_21674"
        x1={101.393}
        y1={36.6741}
        x2={90.4314}
        y2={34.4987}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4428_21674"
        x1={10.2618}
        y1={60.7518}
        x2={8.57929}
        y2={69.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4428_21674"
        x1={13.7859}
        y1={65.937}
        x2={5.0163}
        y2={64.1966}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4428_21674"
        x1={94.9897}
        y1={12.1141}
        x2={78.4368}
        y2={8.98267}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4428_21674"
        x1={14.8847}
        y1={36.2791}
        x2={2.2266}
        y2={33.8845}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2EB5FE" />
        <stop offset={1} stopColor="#500EF6" />
      </linearGradient>
      <clipPath id="clip0_4428_21674">
        <rect width={20.1329} height={20.8772} fill="white" transform="translate(76.6719)" />
      </clipPath>
      <clipPath id="clip1_4428_21674">
        <rect width={15.3958} height={15.9649} fill="white" transform="translate(0.876953 27.0156)" />
      </clipPath>
    </defs>
  </svg>
);
export default MenubitRegisterLogo;
