import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import useCheckRole from '@base/hooks/useCheckRole';
import { useAppSelector } from '@base/redux/store';
import { ROUTES } from '@base/utils/constants/routes';
import useDashboardBestDish from '@components/Dashboard/DashboardBestDish/useDashboardBestDish';
import StatisticsBoard from '@components/UI/StatisticsBoard';
import StatisticsList from '@components/UI/StatisticsList';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const DashboardBestDish: FC<{ filter: string }> = ({ filter }) => {
  const { t } = useTranslation();
  const { dishes, isLoading, isError } = useDashboardBestDish(filter);
  const isPaymentMethod = useAppSelector((state) => state.restaurantSettingsState.payment_method);
  const { isManager } = useCheckRole();

  return (
    <StatisticsBoard title={t('best_selling_dishes')} className={'min-h-[341px]'}>
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : // TODO: (TRANSACTION) discuss with Anastasia is it necessary to check isOrderPlacementPayment?
      !isPaymentMethod ? (
        <BluredUnavailableFeature isWithoutBlure={true}>
          <div className="z-10 text-center">
            {t('no_information')}. {t('you_must_add_clearing_system')}{' '}
            {isManager && (
              <Link
                className={'text-indigo-30 underline underline-offset-4 hover:text-indigo-30'}
                to={ROUTES.account_settings}
              >
                {t('in_account_settings')}
              </Link>
            )}
          </div>
        </BluredUnavailableFeature>
      ) : dishes.length > 0 ? (
        <StatisticsList data={dishes} limit={5} />
      ) : (
        <p>{t('list_is_empty')}</p>
      )}
    </StatisticsBoard>
  );
};

export default DashboardBestDish;
