import MBArrowLeftIcon from '@components/icons/MBArrowLeftIcon';
import MBArrowRightIcon from '@components/icons/MBArrowRightIcon';
import { Pagination as AntdPagination } from 'antd';
import { PaginationProps } from 'antd/es/pagination/Pagination';
import { FC, SVGProps } from 'react';

const Pagination: FC<PaginationProps> = ({ className, ...props }) => {
  const iconProps: SVGProps<SVGSVGElement> = {
    width: '24px',
    height: '24px',
    className: 'text-indigo-30',
  };

  const config = {
    nextIcon: <MBArrowLeftIcon {...iconProps} />,
    prevIcon: <MBArrowRightIcon {...iconProps} />,
  };

  return <AntdPagination {...config} {...props} className={`items-center ${className}`} />;
};

export default Pagination;
