import SubscriptionPackage from '@api/models/SubscriptionPackage';
import PackageBasicLogo from '@components/svg/PackageBasicLogo';
import Heading from '@components/UI/Heading';
import { CURRENCY } from '@constants/constants';
import { Card } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const PackageCard: FC<{ packageItem: SubscriptionPackage }> = ({ packageItem }) => {
  const { t } = useTranslation();
  const { name } = packageItem;

  // TODO: temporary solution when backend will return prices
  const tempPrices = {
    ['Trial']: `${t('free_to', { n: 12 })}/${t('month')}`,
    ['Free']: `${t('free_to', { n: 12 })}/${t('month')}`,
    ['Basic']: `${CURRENCY}200/${t('per_month')}`,
    ['Premium']: `${CURRENCY}400/${t('per_month')}`,
  };

  const renderFeatureList = () => {
    const { user_accounts, ...features } = packageItem.availability_of_features;

    return (
      <ul className="m-0 list-none p-0">
        {Object.entries(features).map(
          ([key, value], i) =>
            value && (
              <li key={i} className="border-0 border-t border-solid border-indigo-10 py-2">
                {t(`package_features.${key}`)}
              </li>
            )
        )}
      </ul>
    );
  };

  return (
    <Card>
      <div className={'px-5 py-6'}>
        <header className={'mb-6 flex gap-2 border-0 border-b-[1px] border-solid border-indigo-40 py-2'}>
          <PackageBasicLogo className={'h-11 w-11'} />
          <div>
            <Heading>{name}</Heading>
            <span className={'text-indigo-40'}>{tempPrices[name]}</span>
          </div>
        </header>

        {renderFeatureList()}
      </div>
    </Card>
  );
};

export default PackageCard;
