import placeholder from '@assets/img/hero-restaurant-placeholder.jpg';
import { setRestHeroSrc } from '@base/redux/features/restaurantSettingsSlice';
import { useAppSelector } from '@base/redux/store';
import MBUploadIcon from '@components/icons/MBUploadIcon';
import { heroRestaurantFormats } from '@constants/constants';
import getBase64 from '@helpers/getBase64';
import { Button, Form, Input, message, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MBInfoIcon from '../../icons/MBInfoIcon';

const RestaurantHeroUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { heroSrc } = useAppSelector((state) => state.restaurantSettingsState);
  const [previewImage, setPreviewImage] = useState(heroSrc || placeholder);

  useEffect(() => {
    if (heroSrc) {
      setPreviewImage(heroSrc);
    }
  }, [heroSrc]);

  const beforeUpload = (file: RcFile) => {
    const isTrueFormat = heroRestaurantFormats.includes(file.type);

    if (!isTrueFormat) {
      message.error(`${file.name} ${t('error_background_format')}`);
    }

    return isTrueFormat || Upload.LIST_IGNORE;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    const src = file.url || (file.preview as string);
    setPreviewImage(src);
    dispatch(setRestHeroSrc(src));
  };

  const handleChange: UploadProps['onChange'] = ({ file }) => {
    handlePreview(file);
  };

  return (
    <>
      <h5 className={'mb-3 flex items-center justify-center gap-2 text-base font-bold leading-[1.2]'}>
        {t('restaurant_background')}{' '}
        <Tooltip title={t('you_must_add_large_image_that_will_fit_a4')}>
          <MBInfoIcon />
        </Tooltip>
      </h5>
      <div className={'mb-4 flex h-[230px] items-center justify-center rounded bg-grey-5 px-6 py-12'}>
        <img className={'h-[135px] w-full rounded-[6px] object-cover'} src={previewImage} alt="" loading={'lazy'} />
      </div>
      <Form.Item name={'hero'} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="background_picture" valuePropName="background_picture">
        <Upload
          accept={heroRestaurantFormats.join(',')}
          listType={'picture'}
          onChange={handleChange}
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}
          name={'background_picture'}
          customRequest={() => {}}
        >
          <Button icon={<MBUploadIcon />}>{t('upload_image')}</Button>
        </Upload>
      </Form.Item>
    </>
  );
};

export default RestaurantHeroUpload;
