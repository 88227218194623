import { setRestLayout } from '@base/redux/features/restaurantSettingsSlice';
import { useAppSelector } from '@base/redux/store';
import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
const RestaurantTheme: FC<{ children: ReactNode }> = ({ children }) => {
  const restLayout = useAppSelector((state) => state.restaurantSettingsState.layout || state.restaurantState.layout);
  const dispatch = useDispatch();

  useEffect(() => {
    let currentTheme = document.querySelector('link[data-type="layout"]');

    // create link tag with theme styles if there are no link in head
    if (!currentTheme) {
      currentTheme = document.createElement('link');
      currentTheme.setAttribute('data-type', 'layout');
      currentTheme.setAttribute('rel', 'stylesheet');
      document.head.appendChild(currentTheme);
    }

    // if there are no (theme || theme name), then set default (theme_1)
    if (!restLayout || !restLayout.name) {
      currentTheme?.setAttribute('data-theme-name', 'theme_1');
      currentTheme?.setAttribute('href', '/styles/theme_1.css');
      dispatch(setRestLayout(null));
    } else {
      currentTheme?.setAttribute('data-theme-name', restLayout.name);
      currentTheme?.setAttribute('href', `/styles/${restLayout.name}.css`);
    }

    // cleanup function (remove <link> tag with custom theme)
    return () => {
      currentTheme?.remove();
    };
  }, [restLayout]);

  return <>{children}</>;
};

export default RestaurantTheme;
