import RestaurantFooter from '@components/MobileMenu/RestaurantFooter';
import checkIsSubdomain from '@helpers/checkIsSubdomain';
import useCheckRole from '@hooks/useCheckRole';
import { Space } from 'antd';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../../components/icons/FacebookIcon';
import InstagramIcon from '../../components/icons/InstagramIcon';
import OmnisLogo from '../../components/icons/OmnisLogo';
import TwitterIcon from '../../components/icons/TwitterIcon';
import RoundedIcon from '../../components/UI/RoundedIcon';

const Footer = () => {
  const { t } = useTranslation();
  const { isWaiter, isSupervisor } = useCheckRole();
  const isSubdomain = checkIsSubdomain();
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    if (!isSubdomain || (isSubdomain && (isSupervisor || isWaiter))) {
      document.documentElement.style.setProperty('--footer-height', `${ref.current.offsetHeight}px`);
    }
  }, []);

  return (
    <>
      {!isSubdomain || (isSubdomain && (isSupervisor || isWaiter)) ? (
        <footer
          dir={'rtl'}
          className={'bg-white p-6 text-center text-grey-30 shadow-custom lg:py-4 lg:text-left'}
          ref={ref}
        >
          <div className={'flex w-full flex-col items-center gap-4 lg:flex-row lg:justify-start'}>
            <Space wrap size={'middle'}>
              <a href="#" target={'_blank'}>
                <span className={'inline-block h-9 w-9'}>
                  <RoundedIcon>
                    <InstagramIcon />
                  </RoundedIcon>
                </span>
              </a>
              <a href="#" target={'_blank'}>
                <span className={'inline-block h-9 w-9'}>
                  <RoundedIcon>
                    <TwitterIcon />
                  </RoundedIcon>
                </span>
              </a>
              <a href="#" target={'_blank'}>
                <span className={'inline-block h-9 w-9'}>
                  <RoundedIcon>
                    <FacebookIcon />
                  </RoundedIcon>
                </span>
              </a>
            </Space>
            <div>
              {t('copyright')} Menu Bit 2023 <br className={'lg:hidden'} /> {t('all_rights_reserved')}
            </div>
            <Space align={'center'} className={'lg:mr-auto'}>
              <span>{t('created_by')}</span>
              <OmnisLogo className={'text-grey-30'} />
            </Space>
          </div>
        </footer>
      ) : (
        <RestaurantFooter />
      )}
    </>
  );
};

export default Footer;
