import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { Layout as AntdLayout } from 'antd';
import { Content } from 'antd/es/layout/layout';

import CustomScrollbar from '@base/hoc/CustomScrollbar';
import { Outlet } from 'react-router-dom';
import Sidepanel from '../Sidepanel';

const RestaurantLayout = () => {
  const { isLG } = useGetMediaQuery();

  return (
    <AntdLayout className={'h-full'}>
      {isLG && <Sidepanel />}

      <AntdLayout className={'main-content'}>
        <CustomScrollbar isShowScrollbar={isLG} rtl={true} noScrollX={true} className={'custom-scrollbar'}>
          <Content className={'m-4 max-w-[1168px] lg:m-10 lg:min-h-max'}>
            <Outlet />
          </Content>
        </CustomScrollbar>
      </AntdLayout>
    </AntdLayout>
  );
};

export default RestaurantLayout;
