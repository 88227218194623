import UserAccess from '@base/hoc/UserAccess';
import { setDishImage } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';
import DishPreview from '@components/DishPreview';
import MBUploadIcon from '@components/icons/MBUploadIcon';
import { heroRestaurantFormats } from '@constants/constants';
import getBase64 from '@helpers/getBase64';
import { Button, Form, message, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const DishHeroUpload: FC<{ url: string | undefined }> = ({ url }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dishImage = useAppSelector((state) => state.menuSlice.dishImage);

  const beforeUpload = (file: RcFile) => {
    const isTrueFormat = heroRestaurantFormats.includes(file.type);

    if (!isTrueFormat) {
      message.error(`${file.name} ${t('error_background_format')}`);
    }

    return isTrueFormat || Upload.LIST_IGNORE;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    const src = file.url || (file.preview as string);

    dispatch(setDishImage(src));
  };

  const handleChange: UploadProps['onChange'] = async ({ file }) => {
    await handlePreview(file);
  };

  return (
    <>
      <div className={'mb-4 rounded bg-grey-5 p-2'}>
        <div className={'h-[120px] overflow-hidden rounded-[6px]'}>
          <DishPreview src={dishImage || url} />
        </div>
      </div>
      <Form.Item name="image" valuePropName="image">
        <Upload
          action={'#'}
          accept={heroRestaurantFormats.join(',')}
          listType={'picture'}
          onChange={handleChange}
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={() => {}}
          name={'image'}
        >
          <UserAccess roles={['restaurant-manager']}>
            <Button icon={<MBUploadIcon />}>{t('upload_image')}</Button>
          </UserAccess>
        </Upload>
      </Form.Item>
    </>
  );
};

export default DishHeroUpload;
