import { WorkHours } from '@base/types/WorkHours';
import { weekDaysArray } from '@constants/constants';
import { Button, Form, Input, Modal, Space } from 'antd';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantDayHours from '../RestaurantDayHours';

interface RestaurantHoursModalProps {
  isOpened: boolean;
  setIsOpened: React.Dispatch<SetStateAction<boolean>>;
}

const RestaurantHoursModal: FC<RestaurantHoursModalProps> = ({ isOpened, setIsOpened }) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const workHours = form.getFieldValue('work_hours');
  const scheduleDescription = form.getFieldValue('description_schedule');

  const [daysHours, setDaysHours] = useState<WorkHours | null>(null);
  const [scheduleText, setScheduleText] = useState('');

  if (isOpened) {
    document.body.classList.add('modal-opened');
  }

  const afterChange = () => {
    if (!isOpened) {
      document.body.classList.remove('modal-opened');
    }
  };

  useEffect(() => {
    if (workHours) {
      setDaysHours({ ...workHours });
    }
  }, [workHours]);

  useEffect(() => {
    if (scheduleDescription) {
      setScheduleText(scheduleDescription);
    }
  }, [scheduleDescription]);

  const handleChangeScheduleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleText(e.target.value);
  };

  const handleSaveHoursSettings = () => {
    form.setFieldValue('work_hours', daysHours);
    form.setFieldValue('description_schedule', scheduleText);
    setIsOpened(false);
  };

  const footerModal = (
    <div className={'w-full text-center'}>
      <Button type={'primary'} onClick={handleSaveHoursSettings}>
        {t('save')}
      </Button>
    </div>
  );

  return (
    <Modal
      title={t('opening_hours')}
      centered
      open={isOpened}
      onOk={() => setIsOpened(false)}
      onCancel={() => setIsOpened(false)}
      afterOpenChange={afterChange}
      footer={footerModal}
      width={590}
    >
      {daysHours && (
        <Space direction={'vertical'} className={'mb-4 w-full'}>
          {weekDaysArray.map((day) => (
            <RestaurantDayHours
              key={day}
              dayName={t(day)}
              dayKey={day}
              daysHours={daysHours}
              setDaysHours={setDaysHours}
            />
          ))}
        </Space>
      )}

      <div>
        <Input
          placeholder={t('extra_schedule_text_placeholder')}
          value={scheduleText}
          onChange={handleChangeScheduleText}
        />
      </div>
    </Modal>
  );
};

export default RestaurantHoursModal;
