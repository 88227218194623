import { useUpdateDishBySupervisorMutation, useUpdateMobileDishMutation } from '@api/dishApi';
import UserAccess from '@base/hoc/UserAccess';
import { DishKeysTabs, setActiveTabDishModal, setDishInfo, setIsModalDishOpen } from '@base/redux/features/menuSlice';
import ConfirmModalContent from '@components/ConfirmModalContent';
import { DndDish } from '@components/Menus/Dnd/DndMenuCategories/useDndMenuCategories';
import useDndMenuDish from '@components/Menus/Dnd/DndMenuDish/useDndMenuDish';
import DishPlaceholderLogo from '@components/svg/DishPlaceholderLogo';
import EditIconButton from '@components/UI/EditIconButton';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import useCheckRole from '@hooks/useCheckRole';
import useModalConfirm from '@hooks/useModalConfirm';
import { message, Modal, Space, Switch, Tooltip } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const DndMenuDish: FC<{ dish: DndDish }> = ({ dish }) => {
  const { t } = useTranslation();
  const { isManager } = useCheckRole();
  const { getDish, dishInfo, isSuccess, isError, error, isFetching, remove } = useDndMenuDish();
  const dispatch = useDispatch();
  const { showModalConfirm } = useModalConfirm();
  const [modal, contextHolder] = Modal.useModal();
  const query = isManager ? useUpdateMobileDishMutation() : useUpdateDishBySupervisorMutation();
  const [updateDish, { isLoading: isLoadingUpdateDish, isSuccess: isSuccessUpdateDish, isError: isErrorUpdateDish }] =
    query;

  useEffect(() => {
    if (!isFetching) {
      if (isSuccess && dishInfo) {
        dispatch(setDishInfo(dishInfo));
        dispatch(setIsModalDishOpen(true));
      }

      if (isError && error) {
        message.error(`${t('error_data_out_of_date')}. ${t('refresh_page')}`);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isLoadingUpdateDish) {
      if (isSuccessUpdateDish) {
        message.success(t('saved'));
      }

      if (isErrorUpdateDish) {
        message.error(t('server_error'));
      }
    }
  }, [isLoadingUpdateDish]);

  const handleEdit = async (dishId: number, keyTab: DishKeysTabs) => {
    await getDish(dishId);
    await dispatch(setActiveTabDishModal(keyTab));
  };

  const handleRemove = (id: number) => {
    showModalConfirm({
      modal,
      title: t('deleting'),
      content: <ConfirmModalContent title={t('do_you_down_remove_dish', { n: dish.dish_name })} />,
      okCallback: () => remove(id),
    });
  };

  const handleChangeAvailability = async (data: any) => {
    await updateDish(data);
  };

  return (
    <>
      <div className={'flex items-center gap-1'}>
        <div className={'flex grow items-center justify-between gap-4 rounded bg-grey-5 px-3 py-2'}>
          <div className={'flex gap-2'}>
            {/* actions btn */}
            <UserAccess roles={['restaurant-manager']}>
              <div className={'flex items-center'}>
                <Space className={'gap-1'}>
                  <RemoveIconButton onClick={() => handleRemove(dish.id)} />
                  <EditIconButton onClick={() => handleEdit(dish.id, 'dish-tab-1')} />
                </Space>
              </div>
            </UserAccess>

            {/* title */}
            <div className={'flex items-center gap-4'}>
              <div className={'relative flex h-10 w-10 items-center justify-center overflow-hidden rounded bg-grey-20'}>
                {dish.image ? (
                  <img className={'h-full w-full object-cover'} src={dish.image} alt="img" />
                ) : (
                  <DishPlaceholderLogo width={30} height={30} />
                )}
              </div>
              <div className={'text-sm'}>{dish.dish_name}</div>
            </div>
          </div>

          {/* change availability action + display amount of the dishes */}
          <div className={'flex items-center gap-4'}>
            <div className={'flex gap-2'}>
              <span className={'text-xs leading-[1.5]'}>{t('availability')}</span>
              <Switch
                checked={dish.is_portion_available}
                loading={isLoadingUpdateDish}
                onChange={() => {
                  handleChangeAvailability({
                    id: dish.id,
                    data: { is_portion_available: !dish.is_portion_available },
                  });
                }}
              />
            </div>
            <Tooltip
              placement={'topRight'}
              title={
                dish.is_inventory_unlimited
                  ? `${t('unlimited')} ${t('dishes')}`
                  : `${dish.inventory_quantity} ${t('dishes')}`
              }
            >
              <div
                className={
                  'w-[75px] truncate border-0 border-r-[1px] border-solid border-grey-20 py-1 pr-3 text-sm leading-[1.5] text-grey-30'
                }
              >
                {dish.is_inventory_unlimited ? (
                  `${t('unlimited')} ${t('dishes')}`
                ) : (
                  <>
                    <span className={'me-2 text-grey-40'}>{dish.inventory_quantity}</span>
                    {t('dishes')}
                  </>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default DndMenuDish;
