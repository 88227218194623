import { SVGProps } from 'react';
const SuprisedLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5338_70671)">
      <path
        d="M13.5 26.9892C1.51689 26.9772 -4.51073 12.4243 3.95427 3.94333C8.9792 -1.29322 18.0211 -1.29407 23.0459 3.94354C31.5108 12.4253 25.4825 26.9779 13.5 26.9892ZM13.5 1.14948C6.69586 1.14948 1.16025 6.68509 1.16025 13.4893C1.8381 29.8597 25.1645 29.8549 25.8398 13.4892C25.8398 6.68509 20.3042 1.14948 13.5 1.14948Z"
        fill="black"
      />
      <path
        d="M20.2433 16.2891H6.75781C6.43745 16.2891 6.17773 16.5488 6.17773 16.8691C6.17773 20.3798 9.4627 23.2358 13.5006 23.2358C17.5384 23.2358 20.8234 20.3798 20.8234 16.8691C20.8234 16.5488 20.5637 16.2891 20.2433 16.2891ZM10.4658 21.4042C11.34 20.7068 12.3578 20.354 13.5006 20.354C14.6797 20.354 15.6828 20.6975 16.5474 21.3985C14.6838 22.2587 12.3314 22.2608 10.4658 21.4042ZM17.6077 20.7726C15.372 18.6681 11.655 18.669 9.40106 20.7784C8.36768 20.0144 7.60947 18.9049 7.37596 17.4492H19.6253C19.3928 18.9009 18.6376 20.0086 17.6077 20.7726Z"
        fill="black"
      />
      <path
        d="M10.6884 13.5344C10.596 13.5344 10.5034 13.5124 10.4185 13.4678L8.63758 12.5315L6.85669 13.4678C6.66126 13.5705 6.42443 13.5535 6.24582 13.4236C6.0672 13.2939 5.97777 13.0739 6.01505 12.8563L6.35519 10.8732L4.91443 9.46882C4.75633 9.31468 4.69943 9.08418 4.76767 8.87419C4.83586 8.66426 5.01742 8.51117 5.2359 8.47942L7.22699 8.19012L8.11741 6.38587C8.21513 6.18785 8.41678 6.0625 8.63758 6.0625C8.85838 6.0625 9.06004 6.18785 9.15775 6.38587L10.0482 8.19007L12.0393 8.47937C12.2579 8.51117 12.4394 8.6642 12.5075 8.87414C12.5758 9.08413 12.5189 9.31468 12.3608 9.46877L10.92 10.8732L11.2601 12.8562C11.3282 13.1992 11.0354 13.5434 10.6884 13.5344ZM8.63758 11.2961C8.73029 11.2961 8.82305 11.3183 8.90753 11.3628L9.91803 11.894C9.64613 10.1477 9.46588 10.7099 10.7094 9.45849L9.57963 9.29433C9.39068 9.26685 9.22736 9.1482 9.14288 8.97697L8.63764 7.95324L8.13239 8.97697C8.04785 9.1482 7.88454 9.26685 7.69564 9.29433L6.56586 9.45849C7.81224 10.7115 7.62772 10.1507 7.35719 11.894L8.36769 11.3628C8.45212 11.3183 8.54488 11.2961 8.63758 11.2961Z"
        fill="#33CCCC"
      />
      <path
        d="M16.3129 13.5344C15.9659 13.5432 15.6731 13.1993 15.7411 12.8563L16.0813 10.8732L14.6405 9.46882C14.4824 9.31468 14.4255 9.08418 14.4938 8.87419C14.5619 8.66426 14.7435 8.51117 14.962 8.47942L16.9531 8.19012L17.8435 6.38587C17.9413 6.18785 18.1429 6.0625 18.3637 6.0625C18.5845 6.0625 18.7862 6.18785 18.8839 6.38587L19.7743 8.19012L21.7654 8.47942C21.9839 8.51122 22.1654 8.66426 22.2336 8.87419C22.3019 9.08418 22.245 9.31473 22.0869 9.46882L20.6461 10.8732L20.9863 12.8563C21.0236 13.0739 20.9341 13.2939 20.7555 13.4236C20.5768 13.5535 20.34 13.5706 20.1446 13.4678L18.3637 12.5315L16.5828 13.4678C16.4979 13.5123 16.4052 13.5344 16.3129 13.5344ZM16.2919 9.45844C17.5383 10.7114 17.3539 10.1506 17.0833 11.8939C18.6594 11.0959 18.0704 11.0981 19.6441 11.8939C19.3722 10.1477 19.192 10.7098 20.4354 9.45844L19.3057 9.29427C19.1167 9.2668 18.9534 9.14815 18.8689 8.97692L18.3637 7.95319L17.8584 8.97692C17.7739 9.14815 17.6106 9.2668 17.4217 9.29427L16.2919 9.45844Z"
        fill="#33CCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_5338_70671">
        <rect width={27} height={27} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SuprisedLogo;
