import { useCreateServiceRequestMutation, useRemoveServiceRequestMutation } from '@api/callToWaiterApi';
import { removeCalledServicesIds, setCalledServicesIds } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useCallToWaiterOption = (is_requested: boolean) => {
  const dispatch = useDispatch();
  const [createServiceRequest] = useCreateServiceRequestMutation();
  const [removeServiceRequest] = useRemoveServiceRequestMutation();

  const [isRequested, setIsRequested] = useState(false);

  const { table_id, waiter } = useAppSelector((state) => state.clientSlice.table);
  const client_id = useAppSelector((state) => state.userState.id);
  const waiter_id = waiter?.id;

  useEffect(() => {
    setIsRequested(is_requested);
  }, [is_requested]);

  const createRequest = async (service_id: number) => {
    if (!table_id || !waiter_id || !client_id) {
      return;
    }

    await dispatch(setCalledServicesIds(service_id));
    setIsRequested((prev) => !prev);

    await createServiceRequest({
      table_id,
      client_id,
      waiter_id,
      service_id,
    });
  };

  const removeRequest = async (service_id: number) => {
    if (!table_id || !waiter_id || !client_id) {
      return;
    }
    await dispatch(removeCalledServicesIds(service_id));
    setIsRequested((prev) => !prev);

    await removeServiceRequest({
      table_id,
      client_id,
      waiter_id,
      service_id,
    });
  };

  return {
    createServiceRequest,
    isRequested,
    setIsRequested,
    createRequest,
    removeRequest,
    client_id,
    table_id,
    waiter_id,
  };
};

export default useCallToWaiterOption;
