import DecodedGoogleCredentials from '@base/types/DecodedGoogleCredentials';
import GoogleClientRegistrationForm from '@components/AuthForm/ClientRegistrationForm/GoogleClientRegistrationForm';
import SimpleClientRegistrationForm from '@components/AuthForm/ClientRegistrationForm/SimpleClientRegistrationForm/SimpleClientRegistrationForm';
import useClientRegistrationForm from '@components/AuthForm/ClientRegistrationForm/useClientRegistrationForm';
import GoogleAuthButton from '@components/UI/GoogleAuthButton';
import { ROUTES } from '@constants/routes';
import { CredentialResponse } from '@react-oauth/google';
import { Button, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import SuccessfulRegistration from '../SuccessfulRegistration';

export interface RegClientFieldType {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  agreed?: string;
  restaurant: string;
  allergies: number[];
}

const ClientRegistrationForm = () => {
  const {
    t,
    paymentUrlParam,
    restaurantDomain,
    isClientRegistered,
    setIsClientRegistered,
    googleCredentials,
    setGoogleCredentials,
  } = useClientRegistrationForm();

  const onGoogleSuccess = async (response: CredentialResponse) => {
    const decodedCredentials: DecodedGoogleCredentials | null = response.credential
      ? jwtDecode(response.credential)
      : null;

    if (!decodedCredentials) {
      return;
    }

    setGoogleCredentials({
      email: decodedCredentials.email,
      family_name: decodedCredentials.family_name,
      given_name: decodedCredentials.given_name,
      google_accessor: decodedCredentials.sub,
    });
  };

  const onGoogleError = () => {
    console.error('Login Failed');
  };

  const handleBackStep = () => {
    setGoogleCredentials(null);
  };

  return (
    <>
      {isClientRegistered ? (
        <SuccessfulRegistration
          loginLink={`${ROUTES.client_login}?restaurant=${restaurantDomain}${
            paymentUrlParam && paymentUrlParam === 'true' ? '&payment=true' : ''
          }`}
        />
      ) : (
        <div className={'flex flex-col gap-4 p-6 lg:w-full lg:px-14 lg:py-10'}>
          <header>
            <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('signup')}</div>
            <p className={'mb-0'}>{t('signup_info')}</p>
          </header>

          {googleCredentials ? (
            <GoogleClientRegistrationForm
              setIsClientRegistered={setIsClientRegistered}
              googleCredentials={googleCredentials}
            />
          ) : (
            <SimpleClientRegistrationForm setIsClientRegistered={setIsClientRegistered} />
          )}

          {!googleCredentials && (
            <div className={'flex flex-col items-center gap-3 border-0 border-t border-solid border-grey-15 pt-3'}>
              <span>{t('or')}</span>
              <GoogleAuthButton onSuccess={onGoogleSuccess} onError={onGoogleError} />
            </div>
          )}

          {googleCredentials && (
            <Button type={'text'} className={'justify-center'} onClick={handleBackStep}>
              {t('reset')}
            </Button>
          )}

          <footer className={'py-4 text-center'}>
            <Space wrap size={'middle'}>
              <span className={'text-grey-40'}>{t('have_you_registered_yet')}</span>
              <Link
                to={`${ROUTES.client_login}?restaurant=${restaurantDomain}`}
                className={'underline underline-offset-4'}
              >
                {t('login')}
              </Link>
            </Space>
          </footer>
        </div>
      )}
    </>
  );
};

export default ClientRegistrationForm;
