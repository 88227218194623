import { useChangeAvailableExtraMutation } from '@api/dishExtrasApi';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import { CURRENCY } from '@constants/constants';
import useCheckRole from '@hooks/useCheckRole';
import { Form } from 'antd';
import { FC } from 'react';

interface MobileDishExtraProps {
  id: number;
  fieldName: number;
  toggleName: string;
  price: number;
}

const MobileDishExtra: FC<MobileDishExtraProps> = ({ id, fieldName, toggleName, price }) => {
  const { isWaiter } = useCheckRole();
  const [updateAvailability] = useChangeAvailableExtraMutation();

  const handleToggleChange = async (checked: boolean) => {
    await updateAvailability({
      id,
      is_readily_available: checked,
    });
  };

  return (
    <div className={'flex items-center justify-between gap-4'}>
      {!isWaiter ? (
        <Form.Item noStyle name={[fieldName, 'is_readily_available']} initialValue valuePropName={'checked'}>
          <HorizontalSwitch label={toggleName} onChange={handleToggleChange} />
        </Form.Item>
      ) : (
        <span>{toggleName}</span>
      )}
      <span>
        {CURRENCY}
        {price}
      </span>
    </div>
  );
};

export default MobileDishExtra;
