import { setDashboardFilter } from '@base/redux/features/dashboardSlice';
import { useAppSelector } from '@base/redux/store';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface FilterOption {
  value: 'day' | 'week' | 'month' | 'year';
  label: string;
}

const useDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filterOptions: FilterOption[] = [
    { value: 'day', label: t('today') },
    { value: 'week', label: t('current_week') },
    { value: 'month', label: t('current_month') },
    { value: 'year', label: t('current_year') },
  ];

  const filter = useAppSelector((state) => state.dashboardState.filter);
  const setFilter = (filter: string) => dispatch(setDashboardFilter(filter));

  return {
    filterOptions,
    defaultFilterValue: filter,
    filter,
    setFilter,
  };
};

export default useDashboard;
