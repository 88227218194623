import { SVGProps } from 'react';
const MBMealIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M10.08.5v6.26A3.81 3.81 0 0 1 8 10.18a3.81 3.81 0 0 1-3.42 0 3.81 3.81 0 0 1-2.16-3.42V.5M6.25.5v23M21.58 7.21a3.83 3.83 0 1 1-7.66 0c0-2.12 1.71-5.75 3.83-5.75s3.83 3.63 3.83 5.75ZM17.75 23.5V11.04"
    />
  </svg>
);
export default MBMealIcon;
