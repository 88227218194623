import { Order } from '@api/models/Order';
import { Pagination } from '@api/models/Pagination';
import {
  useGetOrdersQuery,
  useGetOrderStatusesQuery,
  useGetSupervisorOrdersQuery,
  useGetWaiterOrdersQuery,
} from '@api/ordersApi';
import { useGetWaitersQuery } from '@api/waiterApi';
import { useGetPusherInstance } from '@base/contexts/PusherProvider';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import { useAppSelector } from '@base/redux/store';
import { CHANNELS, CHANNELS_EVENTS } from '@constants/channels';
import useCheckRole from '@hooks/useCheckRole';
import { Channel } from 'laravel-echo';
import { useCallback, useEffect, useState } from 'react';

const useOrdersMonitoring = () => {
  const { isWaiter, isSupervisor } = useCheckRole();
  const pusherInstance = useGetPusherInstance();
  const [pagePagination, setPagePagination] = useState(1);
  const isPaymentMethod = useAppSelector(
    (state) => state.restaurantSettingsState.payment_method || state.restaurantState.payment_method
  );

  const [ordersState, setOrdersState] = useState<{ orders: Order[]; pagination: Pagination | false }>({
    orders: [],
    pagination: false,
  });

  const [isWarningNewOrder, setIsWarningNewOrder] = useState(false);

  const restID = useAppSelector((state) => state.restaurantSettingsState.id);
  const workerID = useAppSelector((state) => state.userState.id);

  const { isWaiters2Tables, isOrderPlacementPayment } = useAvailableFeatures();

  const {
    data: statuses = [],
    isLoading: isLoadingStatuses,
    isSuccess: isSuccessStatuses,
    isError: isErrorStatuses,
  } = useGetOrderStatusesQuery();

  const {
    data: waiters = [],
    isLoading: isLoadingWaiters,
    isSuccess: isSuccessWaiters,
    isError: isErrorWaiters,
  } = useGetWaitersQuery(undefined, { skip: isWaiter });

  const query = isSupervisor
    ? useGetSupervisorOrdersQuery(pagePagination)
    : isWaiter
    ? useGetWaiterOrdersQuery(pagePagination)
    : useGetOrdersQuery(pagePagination);

  const { data: orders, isLoading, isSuccess, isError, refetch: refetchOrders } = query;

  const activeWaiters = waiters.filter((waiter) => waiter.is_active);

  function updateOrderStateWithPagination(newOrder: any) {
    setOrdersState((prev) => {
      if (prev.orders && prev.pagination) {
        return {
          orders: prev.orders.length >= 10 ? [newOrder, ...prev.orders.slice(0, -1)] : [newOrder, ...prev.orders],
          pagination: { ...prev.pagination, total: prev.pagination.total + 1 },
        };
      }

      return prev;
    });
  }

  const handleAddOrder = useCallback((e: any) => {
    const newOrder = e.order;

    const isFirstPaginationPage = pagePagination === 1;

    if (!isFirstPaginationPage && newOrder.waiter.id === workerID) {
      setIsWarningNewOrder(true);

      return;
    }

    // update orders list only if it's first page of pagination
    updateOrderStateWithPagination(newOrder);
  }, []);

  const handleChangeOrder = useCallback((e: any) => {
    const changedOrder = e.order;

    setOrdersState((prev) => {
      return {
        ...prev,
        orders: prev.orders.map((order) => (order.id === changedOrder.id ? changedOrder : order)),
      };
    });
  }, []);

  const waiterOrders =
    ordersState.orders && isWaiter ? ordersState.orders.filter((order) => order.waiter.id === workerID) : null;

  useEffect(() => {
    // reset warning message
    const isFirstPaginationPage = pagePagination === 1;

    if (isFirstPaginationPage) {
      setIsWarningNewOrder(false);
    }

    refetchOrders();
  }, [pagePagination]);

  useEffect(() => {
    if (!isLoading && orders && orders.meta && orders.data) {
      setOrdersState({ orders: orders.data, pagination: orders.meta });
    }
  }, [isLoading, orders]);

  useEffect(() => {
    let newOrderChannel: Channel | null = null;
    let ordersChannel: Channel[] | null = null;
    let waiterChannel: Channel | null = null;

    if (pusherInstance) {
      newOrderChannel = pusherInstance.private(`${CHANNELS.addOrder}.${restID}`);
      newOrderChannel.listen(CHANNELS_EVENTS.new_order, handleAddOrder);

      // for order status and waiter changing
      if (ordersState.orders?.length > 0) {
        ordersChannel = ordersState.orders.map((order) => {
          const channel = pusherInstance.private(`${CHANNELS.updateOrder}.${order.id}`);
          channel.listen(CHANNELS_EVENTS.order_update, handleChangeOrder);

          return channel;
        });
      }

      // for assign waiter to another order
      if (isWaiter) {
        waiterChannel = pusherInstance.private(`${CHANNELS.assignWaiter}.${workerID}`);
        waiterChannel.listen(CHANNELS_EVENTS.order_waiter_change, handleAddOrder);
      }
    }

    return () => {
      if (newOrderChannel) {
        newOrderChannel.stopListening(CHANNELS_EVENTS.new_order);
      }

      if (ordersChannel) {
        ordersChannel.forEach((channel) => {
          channel.stopListening(CHANNELS_EVENTS.order_update);
        });
      }

      if (waiterChannel) {
        waiterChannel.stopListening(CHANNELS_EVENTS.order_waiter_change);
      }
    };
  }, [pusherInstance, ordersState, handleAddOrder, handleChangeOrder]);

  return {
    orders: ordersState.orders || [],
    pagination: ordersState.pagination,
    orderStatuses: statuses,
    waiters: activeWaiters,
    isSuccess: isWaiter ? isSuccess && isSuccessStatuses : isSuccess && isSuccessStatuses && isSuccessWaiters,
    isLoading: isLoading || isLoadingStatuses || isLoadingWaiters,
    isError: isError || isErrorStatuses || isErrorWaiters,
    waiterOrders,
    error: isError
      ? 'Error getting orders'
      : isErrorStatuses
      ? 'Error getting statuses'
      : isErrorWaiters
      ? 'Error getting waiters'
      : null,
    setPagePagination,
    isWarningNewOrder,
    isWaiters2Tables,
    isOrderPlacementPayment,
    isPaymentMethod,
  };
};

export default useOrdersMonitoring;
