import { useGetDishByIdQuery } from '@api/dishApi';
import DishExtra, { ClientDishExtra } from '@api/models/DishExtra';
import DishOption, { ClientDishOption } from '@api/models/DishOption';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import { QUANTITY_NAME_FIELD } from '@components/MobileMenu/MobileClientDishSettings/MobileClientDishSettings';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useRef, useState } from 'react';

export function calculateTotalOptionPrice(opts: ClientDishOption[]): number {
  return opts.reduce(
    (accumOpt, currentOpt) =>
      currentOpt.values.reduce(
        (accumValue, currentValue) =>
          currentValue.is_readily_available && currentValue.selected
            ? accumValue + Number(currentValue.additional_charge)
            : accumValue,
        0
      ) + accumOpt,
    0
  );
}

export function calculateTotalExtrasPrice(extras: ClientDishExtra[]): number {
  return extras.reduce(
    (accumulator, currentValue) =>
      currentValue.is_readily_available && currentValue.checked ? accumulator + +currentValue.price : accumulator,
    0
  );
}

function filterOptionsWithAvailableValues(options: DishOption[]): DishOption[] {
  return options.filter((option) => option.values.some((value) => value.is_readily_available));
}

const prepareExtras = (extras: DishExtra[]): ClientDishExtra[] => {
  return extras.map((item) => ({
    ...item,
    checked: false,
  }));
};

const prepareOpts = (opts: DishOption[]): ClientDishOption[] => {
  const availableOpts = filterOptionsWithAvailableValues(opts);

  return availableOpts.map((opt) => ({
    ...opt,
    values: opt.values.map((item, i) => ({
      ...item,
      selected: i === 0,
    })),
  }));
};

const useMobileClientDishSettings = (dishID: number) => {
  const {
    data: dish,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useGetDishByIdQuery(dishID, { refetchOnMountOrArgChange: true });

  const [form] = useForm();
  const [totalDishPrice, setTotalDishPrice] = useState<number>(0);
  const originalPrice = useRef<number | null>(null);
  const defaultQuantity = 1;

  useEffect(() => {
    if (dish) {
      const options = dish?.options;
      const preparedOpts = options && prepareOpts(options);

      const extras = dish?.extras;
      const preparedExtras = extras && prepareExtras(extras);

      const totalOptionsPrice = preparedOpts ? calculateTotalOptionPrice(preparedOpts) : 0;
      const totalExtrasPrice = preparedExtras ? calculateTotalExtrasPrice(preparedExtras) : 0;

      form.setFieldValue('id', dish.id);
      form.setFieldValue('updated_at', dish.updated_at);
      form.setFieldValue('is_portion_available', dish.is_portion_available);
      form.setFieldValue('is_take_away', dish.is_take_away);
      form.setFieldValue(QUANTITY_NAME_FIELD, defaultQuantity);

      if (preparedOpts) {
        form.setFieldValue(OPTIONS_NAME_FIELD, preparedOpts);
      }

      if (extras) {
        form.setFieldValue(EXTRAS_NAME_FIELD, preparedExtras);
      }

      originalPrice.current = +dish.price;
      setTotalDishPrice((originalPrice.current + totalOptionsPrice + totalExtrasPrice) * defaultQuantity);
    }
  }, [dish]);

  const changedFields = (changedFields: any) => {
    const opts: ClientDishOption[] = form.getFieldValue(OPTIONS_NAME_FIELD);
    const fieldData = changedFields[0].name;

    if (fieldData[0] === OPTIONS_NAME_FIELD) {
      const indexOpt = fieldData[1];
      const indexValue = +fieldData[3];
      const optList = opts[indexOpt].values;

      // change selected value
      optList.forEach((item, i) => (item.selected = i === indexValue));

      const changedOpts = opts.map((opt, i) => {
        if (indexOpt === i) {
          return {
            ...opt,
            values: optList,
          };
        } else {
          return opt;
        }
      });

      form.setFieldValue(OPTIONS_NAME_FIELD, changedOpts);
    }
  };

  const onCalculateTotal = async () => {
    const opts: ClientDishOption[] = await form.getFieldValue(OPTIONS_NAME_FIELD);
    const extras: ClientDishExtra[] = await form.getFieldValue(EXTRAS_NAME_FIELD);
    const quantity = await form.getFieldValue(QUANTITY_NAME_FIELD);

    const optionsPrice = opts ? calculateTotalOptionPrice(opts) : 0;
    const extrasPrice = extras ? calculateTotalExtrasPrice(extras) : 0;

    const total = ((originalPrice.current as number) + extrasPrice + optionsPrice) * quantity;

    setTotalDishPrice(total);
  };

  return {
    dish,
    isLoading: isLoading || isFetching,
    isSuccess,
    isError,
    form,
    changedFields,
    onCalculateTotal,
    originalPrice,
    totalDishPrice,
  };
};

export default useMobileClientDishSettings;
