import apiCentral from '@api/central-api';
import { Allergy, DishType } from '@api/models/Dish';

export const extendedApiSlice = apiCentral.injectEndpoints({
  endpoints: (builder) => ({
    getDishTypes: builder.query<DishType[], void>({
      query: () => '/dish-types',
      transformResponse: (response: { data: DishType[] }) => response.data,
    }),

    getAllergies: builder.query<Allergy[], void>({
      query: () => '/allergies',
      transformResponse: (response: { data: Allergy[] }) => response.data,
    }),
  }),
});

export const { useGetDishTypesQuery, useGetAllergiesQuery } = extendedApiSlice;
