import { useSetTitlePage } from '@base/hooks/useSetTitlePage';
import ClientOrders from '@components/Client/ClientOrders';
import { useTranslation } from 'react-i18next';

const ClientOrdersMonitoringPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.my_orders'));

  return <ClientOrders />;
};

export default ClientOrdersMonitoringPage;
