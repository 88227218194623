import { PromotionDishSelectedOptions } from '@api/models/Promotion';
import { CURRENCY } from '@constants/constants';
import { FC } from 'react';

const ClientPromotionsOptions: FC<{ options: PromotionDishSelectedOptions[] }> = ({ options }) => {
  return (
    <div className={'flex flex-col gap-4'}>
      {options.map((option) => (
        <div key={option.id}>
          <div className={'dish-settings__title'}>{option.name}</div>
          <div className={'dish-settings-promotion__item flex justify-between gap-2 rounded bg-grey-5 p-4'}>
            <span>{option.values.value}</span>
            <span>
              {CURRENCY}
              {option.values.additional_charge}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientPromotionsOptions;
