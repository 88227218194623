import { DayHours, WorkHours } from '@base/types/WorkHours';
import { Dayjs } from 'dayjs';
import React, { SetStateAction, useState } from 'react';

export const format = 'HH:mm';

interface useRestaurantDayHours {
  dayKey: string;
  daysHours: WorkHours;
  setDaysHours: React.Dispatch<SetStateAction<WorkHours | null>>;
}

const useRestaurantDayHours = ({ dayKey, daysHours, setDaysHours }: useRestaurantDayHours) => {
  const currenDay = daysHours[dayKey];
  const { day_off } = currenDay;

  const [startTime, setStartTime] = useState<string | null>(currenDay.to);
  const [endTime, setEndTime] = useState<string | null>(currenDay.from);
  const [isOpened, setIsOpen] = useState(day_off);

  function updateDaysHours(newData: Partial<DayHours>) {
    setDaysHours({
      ...daysHours,
      [dayKey]: {
        ...daysHours[dayKey],
        ...newData,
      },
    });
  }

  const onChangeStartTime = (time: Dayjs | null) => {
    const hours = time ? time.format(format).toString() : '';
    updateDaysHours({ from: hours });
    setStartTime(hours);
  };

  const onChangeEndTime = (time: Dayjs | null) => {
    const hours = time ? time.format(format).toString() : '';
    updateDaysHours({ to: hours });
    setEndTime(hours);
  };

  const handleChangeDayAvailable = (checked: boolean) => {
    updateDaysHours({ day_off: checked });
    setIsOpen(checked);
  };

  return {
    startTime,
    endTime,
    isOpened,
    onChangeStartTime,
    onChangeEndTime,
    handleChangeDayAvailable,
  };
};

export default useRestaurantDayHours;
