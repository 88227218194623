import { useRegisterClientMutation } from '@api/clientApi';
import { useAppSelector } from '@base/redux/store';
import { message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useSimpleClientRegistrationForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [registerClient, { isSuccess, isError, isLoading, error }] = useRegisterClientMutation();
  const [current, setCurrent] = useState(1);
  const restaurantDomain = useAppSelector((state) => state.commonSlice.restaurantDomain4Client);

  const steps = 2;

  useEffect(() => {
    form.setFieldValue('restaurant', restaurantDomain);
    form.setFieldValue('allergies', []);
    form.setFieldValue('dish_types', null);
  }, [restaurantDomain]);

  useEffect(() => {
    const errorObj = error as any;

    if (isError && errorObj?.status === 422) {
      prev();

      // set error state for the email field
      form.setFields([
        {
          name: 'email',
          errors: [t('server_errors.email_has_already_been_taken')],
        },
      ]);
    }
  }, [isLoading]);

  useLayoutEffect(() => {
    scrollTo(0, 0);
  }, []);

  const next = async () => {
    try {
      await form.validateFields();
      setCurrent((prev) => prev + 1);
    } catch (errorInfo) {
      console.error(errorInfo);
    }
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const onFinish = async (values: any) => {
    if (!values.restaurant) {
      message.error(t('value_of_restaurant_is_empty'));

      return;
    }

    await registerClient(values);
  };

  return {
    t,
    form,
    current,
    steps,
    next,
    prev,
    onFinish,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useSimpleClientRegistrationForm;
