import useAuth from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';

const useCheckRole = () => {
  const { t } = useTranslation();
  const { role, isGoogleAccount } = useAuth();

  return {
    t,
    isManager: role === 'restaurant-manager',
    isSupervisor: role === 'supervisor',
    isWaiter: role === 'waiter',
    isClient: !role || role === 'client',
    isGoogleAccount,
  };
};

export default useCheckRole;
