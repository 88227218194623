import { setOriginalHost, setRestaurantId, setRole, setToken, setUserId } from '@base/redux/features/userSlice';
import { useAppSelector } from '@base/redux/store';
import { getUserFromCookies } from '@helpers/getUserFromCookies';
import useLogout from '@hooks/useLogout';
import { FC, ReactNode, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

const Auth: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  const { logout } = useLogout();
  const isUnauthorized = useAppSelector((state) => state.userState.isUnauthorized);

  const initUserState = async (user: any) => {
    await dispatch(setUserId(user.id));
    await dispatch(setToken(user.token));
    await dispatch(setRole(user.role));
    await dispatch(setRestaurantId(user.restaurant_id));
    await dispatch(setOriginalHost(user.original_host));
  };

  const resetUserState = async () => {
    await dispatch(setUserId(null));
    await dispatch(setToken(null));
    await dispatch(setRole(null));
    await dispatch(setRestaurantId(null));
    await dispatch(setOriginalHost(null));
  };

  useLayoutEffect(() => {
    if (user) {
      const dataUser = JSON.parse(user);
      initUserState(dataUser);

      return;
    }

    resetUserState();
  }, [user]);

  // when server returns 401 status code
  useEffect(() => {
    if (isUnauthorized) {
      logout();
    }
  }, [isUnauthorized]);

  return <>{children}</>;
};

export default Auth;
