import placeholder from '@assets/img/logo-restaurant-placeholder.png';
import { setRestLogoSrc } from '@base/redux/features/restaurantSettingsSlice';
import { useAppSelector } from '@base/redux/store';
import MBUploadIcon from '@components/icons/MBUploadIcon';
import { logoRestaurantFormats } from '@constants/constants';
import getBase64 from '@helpers/getBase64';
import { Button, Form, Input, message, Upload, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface UploadImgProps {
  onChange: (info: UploadChangeParam<UploadFile<any>>) => void;
  beforeUpload: (file: RcFile) => string | boolean;
}

const UploadImg: FC<UploadImgProps> = ({ onChange, beforeUpload, ...props }) => {
  const { t } = useTranslation();

  return (
    <ImgCrop rotationSlider cropShape={'round'}>
      <Upload
        accept={logoRestaurantFormats.join(',')}
        listType="picture"
        onChange={onChange}
        maxCount={1}
        showUploadList={false}
        beforeUpload={beforeUpload}
        name={'profile_picture'}
        customRequest={() => {}}
        {...props}
      >
        <Button icon={<MBUploadIcon />}>{t('upload_image')}</Button>
      </Upload>
    </ImgCrop>
  );
};

const RestaurantLogoUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logoSrc } = useAppSelector((state) => state.restaurantSettingsState);
  const [previewImage, setPreviewImage] = useState(logoSrc || placeholder);

  useEffect(() => {
    if (logoSrc) {
      setPreviewImage(logoSrc);
    }
  }, [logoSrc]);

  const beforeUpload = (file: RcFile) => {
    const isTrueFormat = logoRestaurantFormats.includes(file.type);

    if (!isTrueFormat) {
      message.error(`${file.name} ${t('error_background_format')}`);
    }

    return isTrueFormat || Upload.LIST_IGNORE;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    const src = file.url || (file.preview as string);
    setPreviewImage(src);
    dispatch(setRestLogoSrc(src));
  };

  const onChange: UploadProps['onChange'] = ({ file }) => {
    handlePreview(file);
  };

  return (
    <>
      <h5 className={'mb-3 text-base font-bold leading-[1.2]'}>{t('restaurant_logo')}</h5>
      <div className={'mb-4 flex h-[230px] items-center justify-center rounded bg-grey-5 px-6 py-12'}>
        <div className={'h-[8rem] w-[8rem]'}>
          <div
            className={
              'flex h-[inherit] w-[inherit] shrink overflow-hidden rounded-[50%] bg-white shadow-[4px_4px_20px_0_rgba(0,90,178,0.5)]'
            }
          >
            <img
              className={'m-auto h-full w-full rounded-[50%] object-cover'}
              src={previewImage}
              alt="Restaurant logo"
              loading={'lazy'}
            />
          </div>
        </div>
      </div>

      <Form.Item name={'logo'} hidden>
        <Input />
      </Form.Item>

      <Form.Item name="profile_picture" valuePropName="profile_picture">
        <UploadImg onChange={onChange} beforeUpload={beforeUpload} />
      </Form.Item>
    </>
  );
};

export default RestaurantLogoUpload;
