import { useAppSelector } from '@base/redux/store';
import checkIsSubdomain from '@helpers/checkIsSubdomain';
import getQueryParam from '@helpers/getQueryParam';
import useAuth from '@hooks/useAuth';
import useAvailableFeatures from '@hooks/useAvailableFeatures';
import useCheckRole from '@hooks/useCheckRole';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isCall2Waiter } = useAvailableFeatures();
  const { isLG } = useGetMediaQuery();
  const { isAuth } = useAuth();
  const { subdomain } = useAppSelector((state) => state.commonSlice);
  const isSubdomain = checkIsSubdomain();
  const {
    table_id,
    selected: isTableSelected,
    waiter: tableWaiter,
  } = useAppSelector((state) => state.clientSlice.table);
  const { isClient, isWaiter } = useCheckRole();
  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  const [isModalCallWaiterOpen, setIsModalCallWaiterOpen] = useState(false);
  const location = useLocation();
  const restaurantParam = getQueryParam('restaurant');
  const ref = useRef<any>(null);
  const { isOrderPlacementPayment } = useAvailableFeatures();
  const isPaymentMethod = useAppSelector((state) => state.restaurantState.payment_method);

  const isShowCallWaiter =
    (isAuth && isCall2Waiter && isClient && isSubdomain && table_id && tableWaiter) ||
    (!isAuth && isCall2Waiter && isClient && isSubdomain);
  const isShowFilter = isClient && isSubdomain;

  const isWaiterHeader = isWaiter && isSubdomain;
  const isClientHeader = isClient && isSubdomain;

  const handleCallWaiterClick = () => {
    setIsModalCallWaiterOpen(true);
    setIsModalFilterOpen(false);
  };

  const handleFilterClick = () => {
    setIsModalCallWaiterOpen(false);
    setIsModalFilterOpen(true);
  };

  return {
    t,
    dispatch,
    isLG,
    isAuth,
    isSubdomain,
    subdomain,
    isTableSelected,
    isClient,
    isWaiter,
    isModalFilterOpen,
    isModalCallWaiterOpen,
    location,
    restaurantParam,
    ref,
    isShowFilter,
    isShowCallWaiter,
    isWaiterHeader,
    isClientHeader,
    isOrderPlacementPayment,
    isPaymentMethod,
    setIsModalFilterOpen,
    setIsModalCallWaiterOpen,
    handleCallWaiterClick,
    handleFilterClick,
  };
};

export default useHeader;
