import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const LoadingPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('loading'));

  return (
    <div className={'pt-10 text-center'}>
      <h1>{t('loading')}</h1>
    </div>
  );
};

export default LoadingPage;
