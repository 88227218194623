import colorsPalette from '@utils/constants/colors';

export const menuCollapseTheme = {
  components: {
    Collapse: {
      contentPadding: '16px 32px 16px 32px',
      headerPadding: '16px 24px',
      fontSize: 20,
      colorTextHeading: colorsPalette['indigo40'],
      fontSizeIcon: 20,
      headerBg: 'rgb(255, 255, 255)',
      colorBorder: 'rgba(255, 255, 255, 0)',
      contentBg: 'rgba(255, 255, 255, 0)',
      colorBgContainer: 'rgba(255, 255, 255, 0)',
    },
  },
};

export const subMenuCollapseTheme = {
  components: {
    Collapse: {
      contentPadding: '0 20px 16px 20px',
      headerPadding: '0 0 0 0',
      fontSize: 16,
      colorTextHeading: colorsPalette['indigo40'],
      fontSizeIcon: 20,

      colorBorder: 'rgba(255, 255, 255, 0)',
      contentBg: 'rgba(255, 255, 255, 0)',
      colorBgContainer: 'rgba(255, 255, 255, 0)',
    },
  },
};
