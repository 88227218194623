import MBLockIcon from '@base/components/icons/MBLockIcon';
import Heading from '@components/UI/Heading';
import SecondaryButton from '@components/UI/SecondaryButton';
import { Card } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const headerStyles = {
  borderBottom: '1px solid var(--grey-15)',
};

interface StatisticsBoardProps {
  title: string;
  linkMore?: string;
  children?: ReactNode;
  className?: string;
  isUnavailable?: boolean;
}

const StatisticsBoard: FC<StatisticsBoardProps> = ({ title, children, linkMore, className, isUnavailable = false }) => {
  const { t } = useTranslation();

  return (
    <Card className={`${className}`}>
      <header className={'mb-4 flex items-center gap-2 pb-2'} style={headerStyles}>
        <Heading className={'flex flex-grow items-center gap-4'}>
          {title} {isUnavailable && <MBLockIcon className={'min-w-[16px] text-base text-grey-30'} />}
        </Heading>
        {linkMore && !isUnavailable && (
          <Link to={linkMore}>
            <SecondaryButton className={'min-w-[60px] justify-center'}>{t('more')}</SecondaryButton>
          </Link>
        )}
      </header>
      {children}
    </Card>
  );
};

export default StatisticsBoard;
