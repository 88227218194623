import GoogleCredentials from '@base/types/GoogleCredentials';
import IndividualDataStep from '@components/AuthForm/ClientRegistrationForm/IndividualDataStep';
import MBHandsetIcon from '@components/icons/MBHandsetIcon';
import { regExPhone } from '@constants/regexp';
import { Button, Checkbox, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useGoogleClientRegistrationForm from './useGoogleClientRegistrationForm';

const NUMBER_OF_STEPS = 2;

interface ClientGoogleFormFieldType {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  agreed: string;
  google_accessor: string;
  restaurant: string;
}

interface GoogleClientRegistrationFormProps {
  setIsClientRegistered: Dispatch<SetStateAction<boolean>>;
  googleCredentials: GoogleCredentials;
}

const GoogleClientRegistrationForm: FC<GoogleClientRegistrationFormProps> = ({
  setIsClientRegistered,
  googleCredentials,
}) => {
  const { t, form, onFinish, currentStep, prev, next, restaurantDomain, isLoading, isSuccess } =
    useGoogleClientRegistrationForm();

  const isNextButton = currentStep < NUMBER_OF_STEPS;
  const isPhoneStep = currentStep === 1;
  const isIndividualSettingsStep = currentStep === 2;
  const isLastStep = currentStep === NUMBER_OF_STEPS;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsClientRegistered(true);
    }
  }, [isLoading, isSuccess]);

  return (
    <Form
      form={form}
      name="client-google-register"
      layout="vertical"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item<ClientGoogleFormFieldType> name={'first_name'} initialValue={googleCredentials.given_name} hidden>
        <Input />
      </Form.Item>
      <Form.Item<ClientGoogleFormFieldType> name={'last_name'} initialValue={googleCredentials.family_name} hidden>
        <Input />
      </Form.Item>
      <Form.Item<ClientGoogleFormFieldType> name={'email'} initialValue={googleCredentials.email} hidden>
        <Input />
      </Form.Item>
      <Form.Item<ClientGoogleFormFieldType>
        name={'google_accessor'}
        initialValue={googleCredentials.google_accessor}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item<ClientGoogleFormFieldType> name={'restaurant'} initialValue={restaurantDomain} hidden>
        <Input />
      </Form.Item>

      {/* show phone */}
      <>
        <Form.Item<ClientGoogleFormFieldType>
          label={t('phone')}
          name="phone_number"
          rules={[
            {
              required: true,
              pattern: regExPhone,
              message: t('input_errors.phone_only_numbers'),
            },
            {
              max: 15,
              message: t('input_errors.phone_max'),
            },
          ]}
          hidden={!isPhoneStep}
        >
          <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
        </Form.Item>
        <Form.Item<ClientGoogleFormFieldType>
          name="agreed"
          valuePropName="checked"
          className={'mb-0 mt-5 pb-4'}
          required
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
            },
          ]}
          hidden={!isPhoneStep}
        >
          <Checkbox className={'text-xs leading-[1.5]'}>
            <div className={'pb-2 text-xs'}>
              {t('agree_to_the')} <Link to={'#'}>{t('terms_of_use')}</Link> |{' '}
              <Link to={'#'}>{t('privacy_policy')}</Link>
              <span className={'px-1 text-lg font-bold leading-none text-cyan-30'}>*</span>
            </div>
          </Checkbox>
        </Form.Item>
      </>

      {/* show preferences */}
      {isIndividualSettingsStep && <IndividualDataStep />}

      <div className={'flex items-center gap-2'}>
        {isNextButton && (
          <Button type="primary" className={'w-full justify-center lg:w-[206px]'} onClick={next}>
            {t('next')}
          </Button>
        )}

        {isLastStep && (
          <>
            <Button
              type="primary"
              htmlType="submit"
              className={'w-full justify-center lg:w-[206px]'}
              loading={isLoading}
            >
              {t('signup')}
            </Button>

            <Button type={'text'} onClick={prev}>
              {t('back')}
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

export default GoogleClientRegistrationForm;
