import { DishExtra as DishExtraType } from '@api/models/DishExtra';
import UserAccess from '@base/hoc/UserAccess';
import useDishExtra from '@components/Menus/DishForm/DishFormExtras/DishExtra/useDishExtra';
import { EXTRAS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import ApplyIconButton from '@components/UI/ApplyIconButton';
import EditIconButton from '@components/UI/EditIconButton';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { CURRENCY } from '@constants/constants';
import { regExPrice } from '@constants/regexp';
import { Form, Input, Space } from 'antd';
import { FC, useEffect } from 'react';

interface DIshExtraProps {
  temp_id?: string;
  id?: number | null;
  fieldName: number;
  restField: any;
  remove: (name: number) => void;
  isEditMode: boolean;
  updateExtrasState: () => void;
}

const DishExtra: FC<DIshExtraProps> = ({
  temp_id,
  id,
  fieldName,
  restField,
  isEditMode,
  remove,
  updateExtrasState,
}) => {
  const {
    t,
    form,
    isManager,
    crateExtra,
    isLoadingCreate,
    isSuccessCreate,
    createdExtra,
    updateExtra,
    isLoadingUpdateExtra,
    isSuccessUpdate,
    removeExtra,
    isLoading,
    isLoadingRemoveExtra,
    updateAvailability,

    isEditing,
    setIsEditing,
    inputNameRef,
    inputPriceRef,
    inputAvailableRef,
    previousValues,

    getExtraValues,
    handleEdit,
  } = useDishExtra();

  const handleApply = async () => {
    const { name, price, is_readily_available } = getExtraValues();

    // if values changed
    if (
      name !== previousValues.current.name ||
      price !== previousValues.current.price ||
      is_readily_available !== previousValues.current.is_readily_available
    ) {
      if (name && price !== '') {
        const dish_id: number = form.getFieldValue('id');

        // work on API
        if (dish_id) {
          const data = {
            dish_id,
            name,
            price: +price,
            is_readily_available,
          };

          if (id) {
            await updateExtra({
              id,
              data,
            });
          } else {
            await crateExtra(data);
          }
        } else {
          setIsEditing(false);
        }
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleRemove = async () => {
    const dish_id: number = form.getFieldValue('id');

    if (dish_id && id) {
      await removeExtra(id);
      remove(fieldName);
    } else {
      remove(fieldName);
    }
  };

  const handleToggleChange = async (checked: boolean) => {
    if (!isEditing) {
      await updateAvailability({
        id: id as number,
        is_readily_available: checked,
      });
    }
  };

  useEffect(() => {
    setIsEditing(isEditMode);
  }, [isEditMode]);

  // update DishExtra input value of form;
  useEffect(() => {
    if (isSuccessCreate && createdExtra) {
      const extras: DishExtraType[] = form.getFieldValue(EXTRAS_NAME_FIELD);

      const newExtras = extras.map((value) => {
        if (value.temp_id === temp_id) {
          return {
            ...value,
            id: createdExtra.id,
          };
        }

        return value;
      });
      form.setFieldValue(EXTRAS_NAME_FIELD, newExtras);
      updateExtrasState();
    }
  }, [isLoadingCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      updateExtrasState();
      setIsEditing(false);
    }
  }, [isLoadingUpdateExtra]);

  return (
    <Space align="center" size={16}>
      <UserAccess roles={['restaurant-manager']}>
        <RemoveIconButton className={'text-lg'} onClick={handleRemove} loading={isLoadingRemoveExtra} />

        {isEditing ? (
          <ApplyIconButton className={'text-lg'} onClick={handleApply} loading={isLoading} />
        ) : (
          <EditIconButton className={'text-lg'} onClick={handleEdit} />
        )}
      </UserAccess>

      <Form.Item name={[fieldName, 'temp_id']} initialValue={temp_id} hidden>
        <Input />
      </Form.Item>

      <Form.Item {...restField} noStyle name={[fieldName, 'name']} rules={[{ required: true, message: '' }]}>
        <Input
          ref={inputNameRef}
          placeholder={t('extra_name_placeholder')}
          className={'max-w-[180px] !bg-bg-input'}
          disabled={!isManager}
          readOnly={!isEditing}
          bordered={isEditing}
          autoFocus
        />
      </Form.Item>
      <Form.Item
        {...restField}
        noStyle
        name={[fieldName, 'price']}
        rules={[
          {
            pattern: regExPrice,
            message: '',
          },
        ]}
      >
        <Input
          ref={inputPriceRef}
          placeholder="0"
          suffix={CURRENCY}
          className={'max-w-[150px] !bg-bg-input'}
          disabled={!isManager}
          readOnly={!isEditing}
          bordered={isEditing}
        />
      </Form.Item>
      <Form.Item noStyle name={[fieldName, 'is_readily_available']} initialValue valuePropName={'checked'}>
        <HorizontalSwitch label={t('available')} ref={inputAvailableRef} onChange={handleToggleChange} />
      </Form.Item>
    </Space>
  );
};

export default DishExtra;
