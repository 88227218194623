import ClientPromotionDrawer from '@components/Client/ClientPromotions/ClientPromotionDrawer';
import useClientPromotionItem from '@components/Client/ClientPromotions/ClientPromotionItem/useClientPromotionItem';
import MBPlusIcon from '@components/icons/MBPlusIcon';
import Heading from '@components/UI/Heading';
import { App, Button } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RestaurantPromotionCardProps {
  promotion_id: number;
  title: string;
  description?: string;
  hash: string;
  old_price: number;
  new_price: number;
  isImmediateDisplay: boolean;
  setIsOpenedPromotionModal: Dispatch<SetStateAction<boolean>>;
  isOrderPlacementPayment: boolean;
}

const ClientPromotionItem: FC<RestaurantPromotionCardProps> = ({
  promotion_id,
  title,
  description,
  hash,
  old_price,
  new_price,
  isImmediateDisplay,
  setIsOpenedPromotionModal,
  isOrderPlacementPayment,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { getPromotionDetails, promotionDetails, isFetching, isError } = useClientPromotionItem();

  const [isOpened, setIsOpened] = useState(false);

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleAfterClose = () => {
    if (!isOpened) {
      setIsOpened(false);
    }
  };

  const handleGet = async (promotion_id: number) => {
    await getPromotionDetails({ id: promotion_id, hash });
    await setIsOpenedPromotionModal(false);
    setIsOpened(true);
  };

  useEffect(() => {
    setIsOpened(isImmediateDisplay);
  }, [isImmediateDisplay]);

  useEffect(() => {
    if (isError) {
      message.error(t('error'));
    }
  }, [isFetching, isError]);

  return (
    <>
      <div className={'client-promotion-modal__item client-promotion-item flex items-center justify-between gap-2'}>
        <div>
          <Heading className={'client-promotion-item__title'}>{title}</Heading>
          {description && <p className={'mb-0 line-clamp-1 client-promotion-item__description'}>{description}</p>}
        </div>
        <Button
          type={'primary'}
          className={'h-[40px] min-w-[48px] justify-center p-2 client-promotion-item__add-button'}
          loading={isFetching}
          icon={<MBPlusIcon />}
          onClick={() => handleGet(promotion_id)}
        />
      </div>

      {promotionDetails && (
        <ClientPromotionDrawer
          isOpened={isOpened}
          promotion_id={promotion_id}
          title={title}
          description={description}
          old_price={old_price}
          new_price={new_price}
          selectedOptions={promotionDetails.selected_options}
          selectedExtras={promotionDetails.selected_extras}
          dish={promotionDetails.dish}
          handleAfterClose={handleAfterClose}
          handleClose={handleClose}
          isOrderPlacementPayment={isOrderPlacementPayment}
        />
      )}
    </>
  );
};

export default ClientPromotionItem;
