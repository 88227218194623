import { message } from 'antd';
import { t } from 'i18next';
import api from './api';
import { Worker } from './models/Worker';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Worker'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWorkers: builder.query<{ data: Worker[] }, void>({
        query: () => '/worker',
        providesTags: ['Worker'],
      }),
      createWorker: builder.mutation<any, any>({
        query(data) {
          return {
            url: `/worker`,
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: ['Worker'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('error_creating_workers'));
          }
        },
      }),

      updateWorker: builder.mutation<any, any>({
        query({ id, data }) {
          return {
            url: `/worker/${id}`,
            method: 'PUT',
            body: data,
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
            message.success(t('saved'));
          } catch (error) {
            message.error(t('error_updating_workers'));
          }
        },
        invalidatesTags: ['Worker'],
      }),
      removeWorker: builder.mutation<any, any>({
        query(id) {
          return {
            url: `/worker/${id}`,
            method: 'DELETE',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error: any) {
            if (error.error.status === 422) {
              message.error(t('server_errors.waiter_attached_to_table'));

              return;
            }

            message.error(t('error_removing_workers'));
          }
        },

        invalidatesTags: ['Worker'],
      }),
      toggleWorker: builder.mutation<any, { id: number; is_active: boolean }>({
        query({ id, is_active }) {
          return {
            url: `/worker/activation/${id}`,
            method: 'PATCH',
            body: {
              is_active,
            },
          };
        },
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;

            dispatch(
              extendedApiSlice.util.updateQueryData('getWorkers', undefined, (draftWorkers) => {
                const updatedWorkers = draftWorkers.data.map((worker) => {
                  if (+worker.id === args.id) {
                    return {
                      ...worker,
                      is_active: args.is_active,
                    };
                  }

                  return worker;
                });

                return { data: updatedWorkers };
              })
            );

            message.success(t('saved'));
          } catch (error: any) {
            if (error.error.status === 422) {
              message.error(t('server_errors.waiter_attached_to_table'));

              return;
            }

            message.error('server_errors.changing_worker_availability');
          }
        },
      }),
    }),
  });

export const {
  useGetWorkersQuery,
  useCreateWorkerMutation,
  useUpdateWorkerMutation,
  useRemoveWorkerMutation,
  useToggleWorkerMutation,
} = extendedApiSlice;
