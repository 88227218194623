import useForgotPasswordForm from '@components/AuthForm/ForgotPasswordForm/useForgotPasswordForm';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';
import SuccessfulForgotPassword from '../SuccessfulForgotPassword';

interface FieldType {
  email?: string;
}

interface ForgotPasswordFormProps {
  type: 'worker' | 'manager' | 'client';
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ type }) => {
  const { t, form, isSuccessReq, isLoading, onFinish } = useForgotPasswordForm(type);

  return (
    <>
      {isSuccessReq ? (
        <SuccessfulForgotPassword />
      ) : (
        <div className={' w-full p-6 lg:mx-auto lg:box-content lg:max-w-[400px] lg:self-center lg:px-10 lg:py-14'}>
          <div className={'flex flex-col gap-10 lg:py-[100px]'}>
            <header>
              <div className={'mb-2 text-center text-[24px] font-bold text-indigo-40 lg:text-start lg:text-[26px]'}>
                {t('forgot_password')}
              </div>
              <p className={'mb-0 text-center text-base leading-[150%] text-grey-30 lg:text-start'}>
                {t('nothing_happened')} {t('will_send_link_to_set_new_password')}.
              </p>
            </header>
            <Form
              form={form}
              name="forgot-password-form"
              layout="vertical"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item<FieldType>
                    label={t('email')}
                    name="email"
                    rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
                  >
                    <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className={'mb-0'}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={'mt-6 w-full justify-center lg:w-[206px]'}
                  loading={isLoading}
                >
                  {t('email_me_the_link')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordForm;
