import MBCloseIcon from '@components/icons/MBCloseIcon';
import SmilingEmojiLogo from '@components/svg/SmilingEmojiLogo';
import { zIndexes } from '@constants/constants';
import { CSSProperties, Dispatch, FC, SetStateAction } from 'react';

interface PromotionFixedButtonProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

const PromotionFixedButton: FC<PromotionFixedButtonProps> = ({ isOpened, setIsOpened, className = '' }) => {
  const icon = isOpened ? <MBCloseIcon className={'text-2xl'} /> : <SmilingEmojiLogo />;
  const zIndex = isOpened ? zIndexes.promotionFixedBtnActive : zIndexes.promotionFixedBtn;

  const styles: CSSProperties = {
    background: 'var(--Gradient-1, linear-gradient(35deg, #2EB5FE -25.76%, #500EF6 87.25%, #500EF6 87.25%))',
    boxShadow: '0px 0px 20px 0px rgba(0, 90, 178, 0.10)',
    zIndex,
  };

  return (
    <div
      onClick={() => setIsOpened((prev) => !prev)}
      role={'button'}
      style={styles}
      className={`promotion-button fixed bottom-4 right-11 flex h-[56px] w-[56px] items-center justify-center rounded-full text-white
      ${isOpened ? 'promotion-button--active' : ''}
      ${className}`}
    >
      {icon}
    </div>
  );
};

export default PromotionFixedButton;
