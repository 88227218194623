const workHoursEmptyStructure = {
  monday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  tuesday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  wednesday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  thursday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  friday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  saturday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
  sunday: {
    from: '00:00',
    to: '00:00',
    day_off: false,
  },
};

export default workHoursEmptyStructure;
