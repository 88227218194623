import { COOKIE_ROLE_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const removeRoleFromCookies = () => {
  Cookies.remove(COOKIE_ROLE_KEY, {
    path: '/',
    domain,
  });
};
