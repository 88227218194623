import MBEditIcon from '@components/icons/MBEditIcon';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { FC } from 'react';

const EditIconButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      type={'text'}
      icon={<MBEditIcon className={'text-indigo-30'} />}
      className={`h-[40px] justify-center ${className}`}
      {...props}
    />
  );
};

export default EditIconButton;
