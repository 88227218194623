import { ROUTES } from '@base/utils/constants/routes';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SecondaryButton from '../SecondaryButton';

interface BluredUnavailableFeatureProps {
  isWithoutBlure?: boolean;
  children?: ReactNode;
}

const BluredUnavailableFeature: FC<BluredUnavailableFeatureProps> = ({ isWithoutBlure = false, children }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        !isWithoutBlure ? 'blured-content' : ''
      } absolute left-[50%] top-[50%] flex h-full w-full -translate-x-1/2 -translate-y-1/2 rotate-0 skew-x-0 skew-y-0 scale-100 items-center justify-center`}
    >
      {children ? (
        children
      ) : (
        <div className={'z-10 flex flex-col items-center justify-center gap-4'}>
          <p className={'mb-0 text-center text-sm leading-[1.5] text-grey-30'}>
            {t('your_package_not_include_this_information')}
          </p>
          <Link to={ROUTES.packages}>
            <SecondaryButton className={'min-w-[60px] justify-center'}>{t('to_upgrade_package')}</SecondaryButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default BluredUnavailableFeature;
