import { ClientOrder } from '@api/models/Order';
import { useGetClientOrdersQuery, useGetClientOrdersRestaurantsQuery } from '@api/ordersApi';
import orderHeroImg from '@assets/img/bg-header-auth-form.jpg';
import { useGetPusherInstance } from '@base/contexts/PusherProvider';
import { OrderStatusesType } from '@base/types/OrderStatusesType';
import { ROUTES } from '@base/utils/constants/routes';
import ClientOrdersMonitoringList from '@components/Client/ClientOrders/ClientOrdersMonitoringList';
import { CHANNELS, CHANNELS_EVENTS } from '@constants/channels';
import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientOrdersRestaurantFilter from './ClientOrdersRestaurantFilter';

const filterAndSortOrders = (orders: ClientOrder[], status: OrderStatusesType): ClientOrder[] => {
  return orders
    ? orders
        .filter((order) => order.status.status === status)
        .sort((a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime())
    : [];
};

const ClientOrders = () => {
  const { t } = useTranslation();
  const restLink = getRestaurantLinkFromCookies();
  const pusherInstance = useGetPusherInstance();
  const [page, setPage] = useState(1);
  const [filteredRestaurants, setFilteredRestaurants] = useState<number[]>([]);

  // TODO: is isLoading needed or isFetching will be enough? (for rendering "loading" message when fetching data from API (+each time a restaurant filter is used))
  const { data, isFetching, isLoading, isSuccess, isError } = useGetClientOrdersQuery(
    {
      page,
      restaurants: filteredRestaurants,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: dataRestaurants,
    isLoading: isLoadingRestaurants,
    isError: isErrorRestaurants,
  } = useGetClientOrdersRestaurantsQuery();
  const [orders, setOrders] = useState<ClientOrder[] | []>([]);

  useEffect(() => {
    if (!isFetching && data && data?.data) {
      setOrders(data.data);
    }
  }, [isFetching, data]);

  const handleChangeFilter = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setFilteredRestaurants((prev) => [...prev, e.target.value]);
    } else {
      setFilteredRestaurants((prev) => prev.filter((restaurant) => restaurant !== e.target.value));
    }
  };

  const handleClickClearBtn = () => {
    setFilteredRestaurants([]);
  };

  const handleChangeOrder = useCallback((e: any) => {
    const changedOrder = e.order;

    setOrders((prev) =>
      prev.map((order) =>
        order.id === changedOrder.id
          ? {
              ...order,
              status: changedOrder.status,
              waiter: changedOrder.waiter,
            }
          : order
      )
    );
  }, []);

  useEffect(() => {
    if (pusherInstance && orders.length > 0) {
      const newChannels = orders.map((order) => {
        const channel = pusherInstance.private(`${CHANNELS.updateOrder}.${order.id}`);
        channel.listen(CHANNELS_EVENTS.order_update, handleChangeOrder);

        return channel;
      });

      return () => {
        console.log('unsubscribing from client event');

        newChannels.forEach((channel) => {
          channel.stopListening(CHANNELS_EVENTS.order_update);
        });
      };
    }
  }, [pusherInstance, orders, handleChangeOrder]);

  const waitingOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'waiting'), [orders]);
  const inProgressOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'in_progress'), [orders]);
  const completedOrders: ClientOrder[] = useMemo(() => filterAndSortOrders(orders, 'completed'), [orders]);

  const notCompletedOrders: ClientOrder[] = useMemo(() => {
    return [...waitingOrders, ...inProgressOrders];
  }, [waitingOrders, inProgressOrders]);

  return (
    <>
      {isError || isErrorRestaurants ? (
        <div>{t('error')}</div>
      ) : isLoading || isLoadingRestaurants ? (
        <div>{t('loading')}</div>
      ) : (
        isSuccess &&
        orders && (
          <div>
            <img className={'h-[160px] w-full object-cover'} src={orderHeroImg} alt={''} />
            <div className={'mx-auto mt-[-100px] flex max-w-2xl flex-col gap-6 px-4 pb-10'}>
              <div className={'flex flex-col items-center gap-6 rounded bg-white px-4 py-6 shadow-custom'}>
                <div className={'flex w-full flex-wrap items-center justify-between gap-4'}>
                  <span className={'text-xl font-bold leading-[120%] text-indigo-40'}>{t('my_orders')}</span>
                  <ClientOrdersRestaurantFilter
                    filteredRestaurants={filteredRestaurants}
                    restaurants={dataRestaurants}
                    onChangeFilter={handleChangeFilter}
                    onClickClearBtn={handleClickClearBtn}
                  />
                </div>
                {orders.length > 0 ? (
                  <>
                    {isFetching ? (
                      <div>{t('loading')}</div>
                    ) : (
                      <>
                        {notCompletedOrders.length > 0 && (
                          <ClientOrdersMonitoringList orders={notCompletedOrders} isNotCompletedOrders={true} />
                        )}
                        {completedOrders.length > 0 && <ClientOrdersMonitoringList orders={completedOrders} />}
                      </>
                    )}
                  </>
                ) : (
                  <span className={'leading-[150%] text-grey-40'}>{t('you_have_not_placed_orders_yet')}.</span>
                )}
              </div>

              {restLink && (
                <Button
                  href={`${restLink}${ROUTES.restaurant_menu}`}
                  type="primary"
                  className={'m-auto w-[206px] justify-center no-underline'}
                >
                  {t('go_to_menu')}
                </Button>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ClientOrders;
