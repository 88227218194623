import api from '@api/api';
import DishExtra from '@api/models/DishExtra';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createDishExtra: builder.mutation<DishExtra, DishExtra>({
      query(data) {
        return {
          url: `/extra`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: DishExtra }) => response.data,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('saved');
        } catch (error) {
          message.error('error creating extra');
        }
      },
    }),

    updateDishExtra: builder.mutation<any, { id: number; data: DishExtra }>({
      query({ id, data }) {
        return {
          url: `/extra/${id}`,
          method: 'PUT',
          body: data,
        };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('updated');
        } catch (error) {
          message.error('error updating Extra');
        }
      },
    }),

    removeDishExtra: builder.mutation<any, number>({
      query(id) {
        return {
          url: `/extra/${id}`,
          method: 'DELETE',
        };
      },
    }),

    changeAvailableExtra: builder.mutation<any, { id: number; is_readily_available: boolean }>({
      query({ id, is_readily_available }) {
        return {
          url: addTenantToEndpoint(`/extra/toggle/${id}`),
          method: 'PATCH',
          body: { is_readily_available },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          message.success('updated');
        } catch (error) {
          message.error('error updating availability extra');
        }
      },
    }),
  }),
});

export const {
  useCreateDishExtraMutation,
  useUpdateDishExtraMutation,
  useRemoveDishExtraMutation,
  useChangeAvailableExtraMutation,
} = extendedApiSlice;
