import { QRCode } from '@api/models/QRCode';
import { useCreateQRCodeMutation, useUpdateQRCodeMutation } from '@api/qrCodeApi';
import { setBackgroundColor, setForegroundColor } from '@base/redux/features/QRCodeSlice';
import { useAppSelector } from '@base/redux/store';
import { Button, ColorPicker, ColorPickerProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const QRColorPicker = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { foregroundColor, backgroundColor, isQRCodeLoaded, id } = useAppSelector((state) => state.qrCodeSlice);

  const [createQRCode, { isLoading: createQRCodeIsLoading }] = useCreateQRCodeMutation();

  const [updateQRCode, { isLoading: updateQRCodeIsLoading }] = useUpdateQRCodeMutation();

  const handleForegroundChange: ColorPickerProps['onChangeComplete'] = (color) => {
    dispatch(setForegroundColor(color.toHexString()));
  };

  const handleBackgroundChange: ColorPickerProps['onChangeComplete'] = (color) => {
    dispatch(setBackgroundColor(color.toHexString()));
  };

  const handleSaveClick = () => {
    if (isQRCodeLoaded && id) {
      const qrCodeDataObj: { data: QRCode; id: number } = {
        data: {
          background_color: backgroundColor,
          foreground_color: foregroundColor,
        },
        id,
      };

      updateQRCode(qrCodeDataObj);
    } else {
      const qrCodeDataObj = {
        background_color: backgroundColor,
        foreground_color: foregroundColor,
      };
      createQRCode(qrCodeDataObj);
    }
  };

  return (
    <>
      <h5 className={'mb-4 text-center text-base font-bold leading-[1.2]'}>{t('qr_code_design')}</h5>
      <div className={'mb-4 flex h-[390px] flex-col items-center justify-start gap-y-6 rounded bg-grey-5 px-10 py-10'}>
        <Space direction="vertical">
          <h6 className={'mb-0 text-sm font-bold leading-[1.2]'}>{t('foreground_color')}</h6>
          <ColorPicker
            size="large"
            showText
            value={foregroundColor}
            defaultValue="#000000"
            disabledAlpha
            onChangeComplete={handleForegroundChange}
            defaultFormat="hex"
            format="hex"
          />
        </Space>
        <Space direction="vertical">
          <h6 className={'mb-0 text-sm font-bold leading-[1.2]'}>{t('background_color')}</h6>
          <ColorPicker
            size="large"
            showText
            value={backgroundColor}
            defaultValue="#ffffff"
            disabledAlpha
            onChangeComplete={handleBackgroundChange}
            defaultFormat="hex"
            format="hex"
          />
        </Space>
      </div>
      <div className={'flex justify-center'}>
        <Button type="primary" onClick={handleSaveClick} loading={createQRCodeIsLoading || updateQRCodeIsLoading}>
          {t('save')}
        </Button>
      </div>
    </>
  );
};

export default QRColorPicker;
