import { useLazyCheckDishQuantityAvailabilityQuery } from '@api/publicTenantApi';
import happyEmoji from '@assets/img/happy-emoji-animated.gif';
import { resetWarningProductQuantity, setWarningProductQuantity } from '@base/redux/features/cartSlice';
import { useAppSelector } from '@base/redux/store';
import { CartProduct, CartPromotionProduct } from '@base/types/Cart';
import { ClientOrder, ClientOrderData } from '@base/types/ClientOrder';
import CartDishItem from '@components/CartDishItem';
import MBChevronDownIcon from '@components/icons/MBChevronDownIcon';
import MBChevronUpIcon from '@components/icons/MBChevronUpIcon';
import CartChangingTable from '@components/MobileMenu/CartChangingTable';
import CartFooter from '@components/MobileMenu/MobileCart/CartFooter';
import OrderSection from '@components/UI/OrderSection';
import { COOKIE_ORDER_KEY, CURRENCY, zIndexes } from '@constants/constants';
import { centralDomain, domain } from '@constants/environments';
import { ROUTES } from '@constants/routes';
import generateRestaurantLink from '@helpers/generateRestaurantLink';
import roundPrice from '@helpers/roundPrice';
import { setClientOrderToLocalstorage } from '@helpers/setClientOrderToLocalstorage';
import { Button, Drawer, Form } from 'antd';
import Cookies from 'js-cookie';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const bodyDrawerStyle: CSSProperties = {
  padding: '0 24px',
  margin: '0',
};

const compressedBodyDrawerStyle: CSSProperties = {
  height: 0,
  maxHeight: 0,
  padding: 0,
  lineHeight: 0,
};

const footerDrawerStyle: CSSProperties = {
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: 0,
  marginTop: 'auto',
  border: 'none',
};

const headerDrawerStyle: CSSProperties = {
  border: 'none',
  paddingBottom: 0,
};

const renderTotalCartPriceFooter = (totalCartPrice: number, isPromotion: boolean) => {
  const { t } = useTranslation();

  const roundedPrice = roundPrice(totalCartPrice);

  if (isPromotion) {
    return (
      <div className={`relative mb-2 flex h-[50px] flex-col justify-center`}>
        <div className={'mobile-cart-modal__total-price'}>
          {t('total_payment')}{' '}
          <span>
            {CURRENCY}
            {roundedPrice}
          </span>
        </div>
        <div className={'mobile-cart-modal__promotion-image absolute'}>
          <img className={'h-10 w-10'} src={happyEmoji} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className={'mobile-cart-modal__total-price'}>
      {t('total_payment')}{' '}
      <span>
        {CURRENCY}
        {roundedPrice}
      </span>
    </div>
  );
};

const MobileCart = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [checkAvailableProducts, { isLoading, isFetching, isSuccess, data: notAvailableProducts = [] }] =
    useLazyCheckDishQuantityAvailabilityQuery();

  const restDomain = useAppSelector((state) => state.restaurantState.domain);
  const restName = useAppSelector((state) => state.restaurantState.name);
  const restLogo = useAppSelector((state) => state.restaurantState.profile_picture);
  const restId = useAppSelector((state) => state.restaurantState.id);

  const {
    table_id,
    table_take_away_id: table_ta_id,
    table_name,
    waiter,
  } = useAppSelector((state) => state.clientSlice.table);

  const { products } = useAppSelector((state) => state.cartSlice);
  const { id: clientID } = useAppSelector((state) => state.userState);

  const restaurantLink = generateRestaurantLink(restDomain as string);
  const [isCompressed, setIsCompressed] = useState(true);

  const idModal = 'mobileCartModal';
  const modalElement = document.getElementById(idModal);
  const root = document.getElementById('root');

  const [compressedCartHeight, setRecompressedCartHeight] = useState(0);

  const totalCartPrice: number = (products as CartProduct[]).reduce(
    (acc: number, product: CartProduct) => acc + product.product_total_price,
    0
  );

  const [cartErrorMessage, setCartMessages] = useState('');

  const isPromotion = products.some((product) => product.isPromotion);

  const productsWithoutPromotions = products.filter((product) => !product.isPromotion);
  const productsPromotion = products.filter((product) => product.isPromotion);

  const totalCartPriceFooter = renderTotalCartPriceFooter(totalCartPrice, isPromotion);

  function prepareOrder() {
    if (!restDomain || !restId) {
      return;
    }

    const table = table_ta_id ? { take_away_table_id: table_ta_id } : { table_id };

    const order: ClientOrder = {
      client_id: clientID,
      cart_total_price: totalCartPrice,
      waiter_id: waiter?.id,
      restaurant_domain: restDomain as string,
      ...table,
      all_products: products,
      products: productsWithoutPromotions || [],
      promotions: (productsPromotion as CartPromotionProduct[]) || [],
    };

    const orderData: ClientOrderData = {
      restName: restName as string,
      restLogo,
      order,
      waiter,
      tableName: table_name,
      restaurantLink,
    };

    setClientOrderToLocalstorage(orderData, restId);

    // set cookies for main domain than understand that order is created
    Cookies.set(COOKIE_ORDER_KEY, 'true', {
      expires: 1,
      path: '/',
      domain,
    });
  }

  const makeOrder = async () => {
    await dispatch(resetWarningProductQuantity());
    await prepareOrder();

    // redirect to main domain to checkout
    window.location.href = `${centralDomain}${ROUTES.client_payment}`;
  };

  const handleMakeOrder = async () => {
    // check quantity of products
    const preparedProducts = products.map((product) => ({
      dish_id: product.id,
      quantity: product.quantity,
    }));

    await checkAvailableProducts(preparedProducts);
  };

  const handleClick = async () => {
    setCartMessages('');

    // if chose take away
    if (table_ta_id) {
      await handleMakeOrder();

      return;
    }

    // if chose table, firstly to check input value table
    form.submit();
  };

  const onFinishFailed = () => {
    setCartMessages(t('table_not_selected'));
  };

  useEffect(() => {
    if (!products.length) {
      setCartMessages('');
      setIsCompressed(true);
    }

    form.setFieldValue('table', table_id);

    // TODO: review benefit of this code below: (remove if unnecessary)
    // set padding bottom for root element when cart is collapsed
    // if (root && modalElement) {
    //   const heightCollapsedCart = modalElement.offsetHeight;

    //   if (products.length) {
    //     root.style.paddingBottom = `${heightCollapsedCart}px`;
    //   } else {
    //     root.style.paddingBottom = '0';
    //   }
    // }
  }, [products]);

  useEffect(() => {
    if (!isLoading && !isFetching && isSuccess) {
      const isError = notAvailableProducts.length;

      if (isError) {
        dispatch(setWarningProductQuantity({ notAvailableProducts }));
        setCartMessages(t('error_product_quantity_available'));

        return;
      }

      makeOrder();
    }
  }, [isLoading, isFetching, isSuccess, notAvailableProducts]);

  const cartFooter = (
    <CartFooter
      handleClick={handleClick}
      cartErrorMessages={cartErrorMessage}
      totalCartPriceFooter={totalCartPriceFooter}
      setRecompressedHeight={setRecompressedCartHeight}
      isLoading={isLoading}
      isPromotion={isPromotion}
    />
  );

  return (
    <Drawer
      height={isCompressed ? compressedCartHeight : '90%'}
      headerStyle={headerDrawerStyle}
      bodyStyle={isCompressed ? compressedBodyDrawerStyle : bodyDrawerStyle}
      footerStyle={footerDrawerStyle}
      placement={'bottom'}
      open={!!products.length}
      id={idModal}
      className={'mobile-cart-modal'}
      onClose={() => setIsCompressed((prev) => !prev)}
      mask={!isCompressed}
      closeIcon={
        isCompressed ? <MBChevronUpIcon className={'text-2xl'} /> : <MBChevronDownIcon className={'text-2xl'} />
      }
      footer={cartFooter}
      zIndex={zIndexes.cartModal}
    >
      <div className={'mobile-cart-modal__content'}>
        <header className={'mobile-cart-modal__header mobile-cart-header'}>
          <div className={'mobile-cart-header__title'}>{t('my_order')}</div>
          <Button onClick={() => setIsCompressed(true)} className={'mobile-cart-header__button'}>
            {t('go_to_menu')}
          </Button>
        </header>

        {products.map((product, i) => (
          <CartDishItem
            key={`cart-item-${i}`}
            cartProductID={product.card_id}
            name={product.dish_name}
            description={product.description}
            price={product.product_total_price}
            options={product.options}
            extras={product.extras}
            amount={product.quantity}
            inventoryQuantity={product.inventory_quantity}
            isInventoryUnlimited={product.is_inventory_unlimited}
            removable={true}
            counter={true}
            errors={product.errors}
          />
        ))}

        <OrderSection>
          <div className={'order-section__table-select-block table-select-block'}>
            <span className={'table-select-block__title'}>
              {t('table')}: {1}
            </span>

            {table_ta_id ? (
              <div>{table_name}</div>
            ) : (
              <Form name="table-cart-order" form={form} onFinish={handleMakeOrder} onFinishFailed={onFinishFailed}>
                <CartChangingTable />
              </Form>
            )}
          </div>
        </OrderSection>
      </div>
    </Drawer>
  );
};

export default MobileCart;
