import { useUpdateInventoryDishMutation } from '@api/inventoryApi';
import MyModal from '@components/UI/MyModal';
import { regExNumber } from '@constants/regexp';
import { Button, Checkbox, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface InventoryModalProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  id: number;
  name: string;
  count: number;
  isUnlimited: boolean;
  form: any;
}

const InventoryModal: FC<InventoryModalProps> = ({ isOpened, setIsOpened, id, name, count, isUnlimited, form }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(isOpened);
  const [isChecked, setIsChecked] = useState(true);

  const [updateInventoryDish, { isLoading: isUpdating, isSuccess }] = useUpdateInventoryDishMutation();

  useEffect(() => {
    form.setFieldValue('inventory_quantity', count);
    form.setFieldValue('is_inventory_unlimited', isUnlimited);

    setIsChecked(isUnlimited);
  }, [id]);

  useEffect(() => {
    if (!isUpdating && isSuccess) {
      handleClose();
    }
  }, [isUpdating]);

  const handleClose = async () => {
    setIsOpen(false);
  };

  const handleAfterClose = () => {
    if (!isOpen) {
      setIsOpened(false);
    }
  };

  const onFinish = async (values: any) => {
    await updateInventoryDish({ id, ...values });
  };

  return (
    <MyModal
      open={isOpen}
      width={365}
      onCancel={handleClose}
      afterClose={handleAfterClose}
      footer={
        <Button type={'primary'} onClick={form.submit} loading={isUpdating}>
          {t('save')}
        </Button>
      }
      title={t('update_amount_packets')}
    >
      <Form layout={'vertical'} form={form} onFinish={onFinish}>
        <Form.Item label={t('dish')}>
          <Input value={name} readOnly />
        </Form.Item>

        <div className={'flex items-center gap-4'}>
          <Form.Item
            name={'inventory_quantity'}
            rules={[
              { required: !isChecked, message: t('required_field') },
              {
                pattern: regExNumber,
                message: t('input_errors.invalid_number_format'),
              },
            ]}
            className={'max-w-[120px]'}
            label={t('amount')}
          >
            <Input disabled={isChecked} />
          </Form.Item>
          <Form.Item name="is_inventory_unlimited" valuePropName="checked" label={' '}>
            <Checkbox onChange={() => setIsChecked((prev) => !prev)}>{t('unlimited')}</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </MyModal>
  );
};

export default InventoryModal;
