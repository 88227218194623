import { useGetTableServicesQuery, useLazyGetRequestedServicesQuery } from '@api/callToWaiterApi';
import { useAppSelector } from '@base/redux/store';
import { ClientTableService } from '@base/types/ClientTableService';
import { useEffect, useState } from 'react';

const useHeaderCallToWaiterModal = () => {
  const client_id = useAppSelector((state) => state.userState.id);
  const [services, setServices] = useState<ClientTableService[]>([]);

  const { data: tableServices, isLoading, isError } = useGetTableServicesQuery();
  const [
    getRequestedServices,
    { data: requestedServices, isLoading: isLoadingRequestedServices, isError: isErrorRequestedServices },
  ] = useLazyGetRequestedServicesQuery();

  function getRequestedServicesIds() {
    if (!requestedServices?.length) {
      return [];
    }

    return requestedServices[0].services.map((service) => service.id);
  }

  useEffect(() => {
    if (client_id) {
      getRequestedServices(client_id);
    }
  }, [client_id]);

  useEffect(() => {
    if (!isLoadingRequestedServices && tableServices) {
      const requestedServicesIds = getRequestedServicesIds();

      const services: ClientTableService[] = tableServices.map((service) => {
        const isRequested = requestedServicesIds.includes(service.id);

        return {
          ...service,
          isRequested,
        };
      });
      setServices(services);
    }
  }, [isLoadingRequestedServices, isErrorRequestedServices, requestedServices, tableServices]);

  return {
    isLoading,
    isError,
    services,
  };
};

export default useHeaderCallToWaiterModal;
