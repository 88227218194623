import { secondarySelect } from '@components/UI/SecondarySelect/secondarySelect.antd';
import { ConfigProvider, Select } from 'antd';

import { SelectProps } from 'antd/es/select';
import { FC } from 'react';

/*
 *  Styles for SecondarySelect component are defined in secondarySelect.antd.ts and custom.antd.css
 * */

interface SecondarySelectProps extends SelectProps {
  color?: 'cyan';
}

const SecondarySelect: FC<SecondarySelectProps> = ({ color, className, ...props }) => {
  return (
    <ConfigProvider theme={secondarySelect}>
      <Select
        className={`secondary-select min-w-[150px] ${className} ${color ? `secondary-select--${color}` : ''}`}
        popupClassName={`secondary-select-dropdown ${color ? `secondary-select-dropdown--${color}` : ''}`}
        size={'middle'}
        {...props}
      />
    </ConfigProvider>
  );
};

export default SecondarySelect;
