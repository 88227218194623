import { Dish } from '@api/models/Dish';
import UserAccess from '@base/hoc/UserAccess';
import Heading from '@components//UI/Heading';
import DishPreview from '@components/DishPreview';
import MBMoreVerticalIcon from '@components/icons/MBMoreVerticalIcon';
import VegetarianIcon from '@components/icons/VegetarianIcon';
import MobileClientDishSettings from '@components/MobileMenu/MobileClientDishSettings';
import MobileDishSettings from '@components/MobileMenu/MobileDishSettings';
import { CURRENCY } from '@constants/constants';
import useCheckIsWorkerRestConnected from '@hooks/useCheckIsWorkerRestConnected';
import useCheckRole from '@hooks/useCheckRole';
import { Button, Card, Space, Tooltip } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MBPlusIcon from '../icons/MBPlusIcon';
import MBWarningHexagonIcon from '../icons/MBWarningHexagonIcon';
import VaganIcon from '../icons/VaganIcon';

const genImg = (src?: string): ReactNode => (
  <div className={`dish-card__image-wrapper dish-card-image-wrapper `}>
    <div className={'dish-card-image-wrapper__image'}>
      <DishPreview src={src} />
    </div>
  </div>
);

const DishCardThemeTest: FC<{ dish: Dish }> = ({ dish }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const isPreview = pathname.endsWith('preview');
  const { isClient, isManager } = useCheckRole();
  const isWorkerRestConnected = useCheckIsWorkerRestConnected();

  const isOptions = dish.options?.some((option) => option.values.some((value) => value.is_readily_available));
  const isExtras = dish.extras?.some((extra) => extra.is_readily_available);
  const isDishAvailable = (dish.inventory_quantity > 0 || dish.is_inventory_unlimited) && dish.is_portion_available;

  const handleClick = () => {
    setIsOpened(true);
  };

  return (
    <>
      <Card cover={genImg(dish.image)} className={`dish-card ${!isDishAvailable ? 'dish-card--disabled' : ''}`}>
        <div className={`dish-card__content`}>
          <Heading component={'h4'} className="dish-card__title">
            {dish.dish_name}
          </Heading>

          {dish.dish_type && (
            <Space className={'dish-card__dish-type-icon'}>
              {/* TODO: need img url of types or slugs types to choosing Icon. Change in the future */}
              <Tooltip title={dish.dish_type.name}>
                {dish.dish_type.id === 1 && <VaganIcon />}
                {dish.dish_type.id === 2 && <VegetarianIcon />}
              </Tooltip>
            </Space>
          )}

          <div className={'dish-card__text-content dish-card-text-content'}>
            <p className={'dish-card-text-content__description'}>{dish.description}</p>
            {dish.allergies.length > 0 && (
              <p className={'dish-card-text-content__allergies'}>
                <MBWarningHexagonIcon />
                {dish.allergies && dish.allergies.map((allergy) => allergy.name).join(', ')}
              </p>
            )}

            {(isOptions || isExtras) && <p className={'dish-card-text-content__options-extras'}>{t('customizable')}</p>}

            {Boolean(!dish.is_take_away) && (
              <p className={'dish-card-text-content__take-away'}>{t('dish_take_away')}</p>
            )}
          </div>
        </div>
        <footer className={'dish-card__footer'}>
          <div className={'dish-card__price dish-card-price'}>
            <div className={'dish-card-price__title'}>{t('price_of_dish')}:</div>
            <Heading className={'dish-card-price__price'}>
              {CURRENCY}
              {dish.price}
            </Heading>
          </div>

          <UserAccess roles={['restaurant-manager', 'supervisor', 'waiter']}>
            {!isPreview && !isManager && isWorkerRestConnected && (
              <Button
                type={'text'}
                shape="circle"
                icon={<MBMoreVerticalIcon />}
                className={'dish-card__edit-button'}
                onClick={handleClick}
              />
            )}
          </UserAccess>

          {isClient && isDishAvailable && (
            <Button
              type={'primary'}
              shape="circle"
              onClick={handleClick}
              icon={<MBPlusIcon />}
              className={'dish-card__open-button'}
            ></Button>
          )}
        </footer>
      </Card>

      {isOpened &&
        (isClient ? (
          <MobileClientDishSettings isOpened={isOpened} setIsOpened={setIsOpened} id={dish.id} />
        ) : (
          <MobileDishSettings isOpened={isOpened} setIsOpened={setIsOpened} id={dish.id} />
        ))}
    </>
  );
};

export default DishCardThemeTest;
