import { COOKIE_CLIENT_IS_ORDER_SUCCESS_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const removeClientIsOrderSuccessFromCookies = () => {
  Cookies.remove(COOKIE_CLIENT_IS_ORDER_SUCCESS_KEY, {
    path: '/',
    domain,
  });
};
