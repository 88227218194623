import SubscriptionPackage from '@api/models/SubscriptionPackage';
import { useCreatePackagePaymentMutation } from '@api/subscriptionApi';
import { useAppSelector } from '@base/redux/store';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PackagesFormTypeInputs {
  package_id: number;
  agreed: boolean;
}

const usePackagesModal = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { message } = App.useApp();
  // TODO: remove the restId if it will not be used in the future
  // const restId = useAppSelector((state) => state.restaurantSettingsState.id);
  const user = useAppSelector((state) => state.userState.user);
  const restaurantId = useAppSelector((state) => state.restaurantSettingsState.id || state.restaurantState.id);
  const [selectedPackage, setSelectedPackage] = useState<SubscriptionPackage | undefined>(undefined);
  const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(false);
  const [createPayment, { isLoading, isSuccess, isError, data }] = useCreatePackagePaymentMutation();

  useEffect(() => {
    function receiveMessage(event: any) {
      if (event.origin !== 'https://meshulam.co.il' && event.origin !== 'https://sandbox.meshulam.co.il') {
        return;
      }

      const data = event.data;

      if (data.status === 1) {
        setIsSuccessfulPayment(true);
      }

      window.removeEventListener('message', receiveMessage);
    }

    if (!isLoading && isSuccess && data) {
      window.addEventListener('message', receiveMessage, false);
    }

    if (!isLoading && isError) {
      message.error(t('error'));
    }
  }, [isLoading, isSuccess, isError]);

  const onFinish = async (values: PackagesFormTypeInputs) => {
    // TODO: change condition to be more elegant
    if (!values.package_id || !selectedPackage || !user || !restaurantId) {
      return;
    }

    const managerFullName = `${user.first_name} ${user.last_name}`;

    const requestBody = {
      chargeType: '1',
      sum: selectedPackage.price,
      pageField: { fullName: managerFullName, phone: user.phone_number, email: user.email },
      description: selectedPackage?.name === 'Basic' ? 'רכישת תכנית בסיסית' : 'רכישת תכנית פרמיום', // TODO: add this to translations
      cField1: `package_id=${values.package_id}`,
      cField2: `package_name=${selectedPackage?.name}`,
    };

    await createPayment({
      requestBody,
      packageId: values.package_id,
      managerId: user.id,
      restId: restaurantId,
    });
  };

  return {
    t,
    form,
    selectedPackage,
    setSelectedPackage,
    onFinish,
    iframeUrl: data?.data.url,
    isSuccessfulPayment,
  };
};

export default usePackagesModal;
