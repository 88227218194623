import {
  useForgotPasswordClientMutation,
  useForgotPasswordMutation,
  useForgotPasswordWorkerMutation,
} from '@api/authApi';
import { SERVER_GOOGLE_AUTH_ERROR_PASS_RECOVER } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import useAuth from '@hooks/useAuth';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const useForgotPasswordForm = (type: 'worker' | 'manager' | 'client') => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = useForm();
  const { isAuth, restaurantId } = useAuth();

  const [isSuccessReq, setIsSuccessReq] = useState(false);

  // API usage depending on prop 'type' (client | manager | worker)
  const forgotPasswordHook =
    type === 'worker'
      ? useForgotPasswordWorkerMutation
      : type === 'client'
      ? useForgotPasswordClientMutation
      : useForgotPasswordMutation;

  const [sendForgotPassword, { isLoading, isError, error, isSuccess }] = forgotPasswordHook();

  const onFinish = async (values: any) => {
    await sendForgotPassword(values);
  };

  useLayoutEffect(() => {
    if (isAuth) {
      const from =
        ((location.state as any)?.from.pathname as string) || restaurantId ? ROUTES.dashboard : ROUTES.restaurant;

      navigate(from);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessReq(true);
    }

    if (!isLoading && isError && error) {
      const errorMessage = (error as any)?.data?.data?.error_message;

      if (errorMessage && errorMessage === SERVER_GOOGLE_AUTH_ERROR_PASS_RECOVER) {
        message.error(t('server_errors.account_uses_google_auth'));

        return;
      }

      message.error(t('error_cant_send_or_not_correct_email'));
    }
  }, [isLoading]);

  return {
    t,
    form,
    isSuccessReq,
    isLoading,
    onFinish,
  };
};

export default useForgotPasswordForm;
