import InstagramIcon from '@components/icons/InstagramIcon';
import useRestaurantSettingsForm from '@components/RestaurantSettings/RestaurantSettingsForm/useRestaurantSettingsForm';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import { domain } from '@constants/environments';
import { regExDomain, regExPhone } from '@constants/regexp';
import { Button, Card, Col, Form, Input, Radio, Row } from 'antd';
import { FC, ReactNode } from 'react';
import FacebookIcon from '../../icons/FacebookIcon';
import MBHandsetIcon from '../../icons/MBHandsetIcon';
import RoundedIcon from '../../UI/RoundedIcon';
import SimpleSection from '../../UI/SimpleSection';
import RestaurantDesign from '../RestaurantDesign';
import RestaurantHoursUpdate from '../RestaurantHoursUpdate';
import RestaurantLayoutChooser from '../RestaurantLayoutChooser';
import useRestaurantSettingsOnboarding from '../RestaurantSettingsOnboarding/useRestaurantSettingsOnboarding';

export interface RestaurantSettingsFieldType {
  id: number;
  name: string;
  secondary_title: string;
  description: string;
  google_account_link: string;
  domain: string;
  phone_number: string;
  work_hours: any;
  description_schedule: string;
  layout_id: number;
  address: string;
  facebook_link: string;
  instagram_link: string;
  restaurant_call_waiter: string;
  restaurant_digital_order_menu: string;
  is_take_away: string;
  restaurant_design: string;
  restaurant_menu_templates: string;
}

const InputWithSocialIcon: FC<{ icon: ReactNode }> = ({ icon, ...props }) => (
  <div className={'flex gap-[10px]'}>
    <div className={'h-10 w-10 text-cyan-30'}>
      <RoundedIcon>{icon}</RoundedIcon>
    </div>
    <Input {...props} />
  </div>
);

const RestaurantSettingsForm = () => {
  const {
    t,
    restaurantId,
    isLoading,
    isError,
    form,
    isLoadingBtn,

    handleChangeName,
    handleChangeSubtitle,
    handleChangeDescription,
    onFinish,
    handleChangeFrom,
  } = useRestaurantSettingsForm();

  const { isShowTour, renderTour, obStepRef1, obStepRef2, obStepRef3, obStepRef4 } = useRestaurantSettingsOnboarding();

  return (
    <>
      {isError && <span>{t('error')}</span>}
      {isLoading && <span>{t('loading')}</span>}
      {((restaurantId && !isLoading) || !restaurantId) && (
        <>
          <Form
            form={form}
            name="restaurant-settings"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            onFieldsChange={handleChangeFrom}
            scrollToFirstError
          >
            <Row gutter={[40, 40]}>
              <Col xs={24}>
                <SimpleSection title={t('restaurant_details')}>
                  <Row gutter={[40, 32]}>
                    <Col xs={24} lg={12}>
                      <Card size="default" ref={obStepRef1}>
                        <Form.Item<RestaurantSettingsFieldType> name={'id'} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('restaurant_name')}
                          name="name"
                          rules={[{ required: true, message: t('required_field') }]}
                        >
                          <Input placeholder={t('restaurant_name_placeholder')} onChange={handleChangeName} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default" ref={obStepRef2}>
                        <Form.Item<RestaurantSettingsFieldType> label={t('restaurant_subtitle')} name="secondary_title">
                          <Input placeholder={t('restaurant_subtitle_placeholder')} onChange={handleChangeSubtitle} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default" ref={obStepRef3}>
                        <Form.Item<RestaurantSettingsFieldType> label={t('restaurant_description')} name="description">
                          <Input maxLength={255} placeholder={'תיאור'} onChange={handleChangeDescription} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default">
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('link_to_google_my_business')}
                          name="google_account_link"
                          rules={[{ type: 'url', message: t('input_errors.invalid_url_format') }]}
                          className={'restaurant-business-link-input'}
                        >
                          <Input placeholder={t('link_to_google_my_business')} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default" ref={obStepRef4}>
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('restaurant_domain')}
                          name="domain"
                          rules={[
                            {
                              required: true,
                              pattern: regExDomain,
                              message: t('input_errors.domain_pattern'),
                            },
                            {
                              max: 255,
                              message: t('input_errors.max_value', { n: 255 }),
                            },
                          ]}
                        >
                          <Input
                            className={'input-restaurant-domain'}
                            placeholder={t('restaurant_domain_placeholder')}
                            prefix={<span className={'font-semibold'}>{domain}.</span>}
                          />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default">
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('restaurant_phone')}
                          name="phone_number"
                          rules={[
                            {
                              pattern: regExPhone,
                              message: t('input_errors.phone_only_numbers'),
                            },
                            {
                              max: 15,
                              message: t('input_errors.phone_max'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('phone_placeholder')}
                            suffix={<MBHandsetIcon className={'text-grey-20'} />}
                          />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default" className={'h-full'}>
                        <RestaurantHoursUpdate />
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="default">
                        <Form.Item<RestaurantSettingsFieldType> label={t('restaurant_address')} name="address">
                          <Input.TextArea maxLength={255} rows={6} placeholder={'תיאור'} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size={'default'}>
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('restaurant_facebook')}
                          name="facebook_link"
                          rules={[{ type: 'url', message: t('input_errors.invalid_url_format') }]}
                        >
                          <InputWithSocialIcon icon={<FacebookIcon />} />
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size={'default'}>
                        <Form.Item<RestaurantSettingsFieldType>
                          label={t('restaurant_instagram')}
                          name="instagram_link"
                          rules={[{ type: 'url', message: t('input_errors.invalid_url_format') }]}
                        >
                          <InputWithSocialIcon icon={<InstagramIcon />} />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </SimpleSection>
              </Col>
              <Col xs={24}>
                <SimpleSection title={t('additional_features')}>
                  <Row gutter={[40, 32]}>
                    <Col xs={24} lg={12}>
                      <Card size="default">
                        <Form.Item<RestaurantSettingsFieldType>
                          name="restaurant_call_waiter"
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <HorizontalSwitch label={t('restaurant_call_waiter')} disabled />
                        </Form.Item>
                        <Form.Item<RestaurantSettingsFieldType>
                          name="restaurant_digital_order_menu"
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <HorizontalSwitch label={t('restaurant_digital_menu')} disabled />
                        </Form.Item>
                        <Form.Item<RestaurantSettingsFieldType>
                          initialValue
                          name="is_take_away"
                          valuePropName="checked"
                        >
                          <HorizontalSwitch label={t('restaurant_take_away')} />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </SimpleSection>
              </Col>
              <Col xs={24}>
                <SimpleSection title={t('restaurant_design')}>
                  <RestaurantDesign />
                </SimpleSection>
              </Col>
              <Col xs={24}>
                <SimpleSection title={t('restaurant_menu_templates')}>
                  <Card size={'default'} className="flex justify-center">
                    <Form.Item<RestaurantSettingsFieldType> name="layout_id" initialValue={1}>
                      <Radio.Group className={'restaurant-settings-layout-chooser'}>
                        <RestaurantLayoutChooser />
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                </SimpleSection>
              </Col>
              <Col xs={24} className={'text-center'}>
                <Button type={'primary'} htmlType="submit" loading={isLoadingBtn}>
                  {t('save_changes')}
                </Button>
              </Col>
            </Row>
          </Form>

          {isShowTour && renderTour()}
        </>
      )}
    </>
  );
};

export default RestaurantSettingsForm;
