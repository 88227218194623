import { User } from '@api/models/User';
import useSimpleLoginForm from '@components/AuthForm/LoginForm/SimpleLoginForm/useSimpleLoginForm';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import { SERVER_GOOGLE_AUTH_ERROR } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface RegistrationFormFieldType {
  email: string;
  password: string;
}

interface SimpleLoginFormProps {
  initLoggedUser: (data: User) => void;
}

const SimpleLoginForm: FC<SimpleLoginFormProps> = ({ initLoggedUser }) => {
  const { t, message, navigate, form, restaurantId, onFinish, isLoading, isError, error, isSuccess, data } =
    useSimpleLoginForm();

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      initLoggedUser(data);

      navigate(ROUTES.root);

      return;
    }

    if (!isLoading && isError && error) {
      const errorMessage = (error as any)?.data?.data?.error_message;

      if (errorMessage && errorMessage === SERVER_GOOGLE_AUTH_ERROR) {
        message.error(t('server_errors.account_uses_google_auth'));

        return;
      }

      message.error(t('server_errors.bed_credentials'));
    }
  }, [isLoading, isSuccess, isError, error]);

  return (
    <Form
      form={form}
      name="registration-form"
      layout="vertical"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item<RegistrationFormFieldType>
            label={t('email')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
          >
            <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item<RegistrationFormFieldType>
            label={t('password')}
            name="password"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Link to={ROUTES.forgot_password}>{t('forget_password')}</Link>

      <Form.Item className={'mb-0'}>
        <Button
          type="primary"
          htmlType="submit"
          className={'mt-6 w-full justify-center lg:w-[206px]'}
          loading={isLoading}
        >
          {t('login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SimpleLoginForm;
