import IndividualDataStep from '@components/AuthForm/ClientRegistrationForm/IndividualDataStep';
import RegistrationStep from '@components/AuthForm/ClientRegistrationForm/RegistrationStep';
import useSimpleClientRegistrationForm from '@components/AuthForm/ClientRegistrationForm/SimpleClientRegistrationForm/useSimpleClientRegistrationForm';
import { Button, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';

interface SimpleClientRegistrationFormProps {
  setIsClientRegistered: Dispatch<SetStateAction<boolean>>;
}

const SimpleClientRegistrationForm: FC<SimpleClientRegistrationFormProps> = ({ setIsClientRegistered }) => {
  const { t, form, current, steps, next, prev, onFinish, isLoading, isSuccess, isError } =
    useSimpleClientRegistrationForm();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsClientRegistered(true);
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Form
      form={form}
      name="client-register"
      layout="vertical"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item name={'restaurant'} hidden>
        <Input />
      </Form.Item>
      <div className={`${current === 1 ? 'block' : 'hidden'}`}>
        <RegistrationStep />
      </div>
      <div className={`${current === 2 ? 'block' : 'hidden'}`}>
        <IndividualDataStep />
      </div>
      <div>
        {current < steps && (
          <Button type="primary" className={'w-full justify-center lg:w-[206px]'} onClick={next}>
            {t('next')}
          </Button>
        )}
        {current === steps && (
          <div className={'flex items-center gap-2'}>
            <Button type="primary" htmlType={'submit'} className={'w-full justify-center lg:w-[206px]'}>
              {t('register')}
            </Button>
            <Button type={'text'} onClick={prev}>
              {t('back')}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default SimpleClientRegistrationForm;
