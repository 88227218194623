import SubscriptionPackage from '@api/models/SubscriptionPackage';
import SuccessfulPaymentGuy from '@components/icons/SuccessfulPaymentGuy';
import PackageCard from '@components/Packages/PackageCard';
import usePackagesModal, { PackagesFormTypeInputs } from '@components/Packages/PackagesModal/usePackagesModal';
import Heading from '@components/UI/Heading';
import MyModal from '@components/UI/MyModal';
import { Button, Checkbox, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';

interface PackagesModalProps {
  packages: SubscriptionPackage[];
  selected_package_id: number;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}
const PackagesModal: FC<PackagesModalProps> = ({ packages, selected_package_id, isOpened, setIsOpened }) => {
  const { t, form, selectedPackage, setSelectedPackage, onFinish, iframeUrl, isSuccessfulPayment } = usePackagesModal();

  const options = packages.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  {
    /* TODO: check if need to remove it completely */
  }
  // const onChangePackage = (value: any) => {
  //   const selectedPackage = packages.find((item) => item.id === value);
  //   if (selectedPackage) {
  //     form.setFieldsValue({ package_id: selectedPackage.id });
  //     setSelectedPackage(selectedPackage);
  //   }
  // };

  const handleCancelModal = () => {
    if (isSuccessfulPayment) {
      window.location.reload();
    }

    setIsOpened(false);
  };

  useEffect(() => {
    const selectedPackage = packages.find((item) => item.id === selected_package_id);

    if (selectedPackage) {
      form.setFieldsValue({ package_id: selectedPackage.id });
      setSelectedPackage(selectedPackage);
    }
  }, [selected_package_id]);

  const renderPaymentForm = () => (
    <div className={'w-full max-w-[500px] flex-auto p-6'}>
      <Heading className={'!text-grey-40'}>{t('payment_by_credit_card')}</Heading>
      <p>{t('payment_secured_by_PC_standard')}</p>
      <Form form={form} onFinish={onFinish} className={'mt-6'}>
        {iframeUrl && <iframe src={iframeUrl} width={450} height={350} frameBorder={0} />}

        <Form.Item<PackagesFormTypeInputs>
          name="agreed"
          valuePropName="checked"
          className={'pb-2'}
          required
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
            },
          ]}
        >
          <Checkbox className={'text-xs leading-[1.5]'}>
            <div className={'text-sm'}>
              <a href="#">{t('confirm_reading_and_agreeing_to_terms')}</a>
            </div>
          </Checkbox>
        </Form.Item>
        <Form.Item<PackagesFormTypeInputs> rules={[{ required: true }]} name={'package_id'} hidden>
          <Input />
        </Form.Item>
        <Button type={'primary'} className={'block w-full'} htmlType={'submit'}>
          {t('greed')}
        </Button>
      </Form>
    </div>
  );

  const renderSuccess = () => (
    <div className={'flex grow text-center'}>
      <div className={'m-auto'}>
        <Heading className={'text-[26px]'}>{t('payment_went_successfully')}</Heading>
        <p>{t('sent_you_email_confirming_payment_for_package')}</p>
        <SuccessfulPaymentGuy className={'my-10 mt-10'} />
        <Button type={'primary'} className={'leading-0 mx-auto block h-11 w-max px-6'} onClick={handleCancelModal}>
          {t('back_to_system')}
        </Button>
      </div>
    </div>
  );

  return (
    <MyModal open={isOpened} onCancel={handleCancelModal} title={t('payment_for_package')} width={1080}>
      <div className={'flex flex-wrap-reverse justify-center gap-10 lg:justify-between'}>
        {isSuccessfulPayment ? renderSuccess() : renderPaymentForm()}

        <div className={'w-full max-w-[350px] flex-auto'}>
          {selectedPackage && <PackageCard packageItem={selectedPackage} />}

          {/* TODO: check if need to remove it completely */}
          {/* <div className={'mt-4 px-6'}>
            <SecondarySelect
              options={options}
              value={selectedPackage?.id}
              color={'cyan'}
              className={'w-full'}
              onChange={onChangePackage}
            />
          </div> */}
        </div>
      </div>
    </MyModal>
  );
};

export default PackagesModal;
