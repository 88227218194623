import { useLazyGetInventoryQuery } from '@api/inventoryApi';
import { useAppSelector } from '@base/redux/store';
import checkIsSubdomain from '@helpers/checkIsSubdomain';
import useAuth from '@hooks/useAuth';
import useCheckRole from '@hooks/useCheckRole';
import { useEffect, useMemo } from 'react';

const useInventory = () => {
  const { isAuth } = useAuth();
  const restID = useAppSelector((state) => state.restaurantSettingsState.id);
  const isSubdomain = checkIsSubdomain();
  const { isClient, isWaiter, isManager, isSupervisor } = useCheckRole();
  const isPaymentMethod = useAppSelector(
    (state) => state.restaurantSettingsState.payment_method || state.restaurantState.payment_method
  );

  const skip =
    !isAuth || !restID || isClient || isWaiter || (isManager && isSubdomain) || (isSupervisor && !isSubdomain);

  const [getInventory, { data, isLoading, isError }] = useLazyGetInventoryQuery();

  const inventoryBoardList = data?.data || [];
  const isWarning = data?.meta.is_inventory_that_has_ended || false;

  const categoryList = inventoryBoardList.filter((inventory) => inventory.menu_tree.level === 1);
  const subCategoryList = inventoryBoardList.filter((inventory) => inventory.menu_tree.level === 2);

  const isUnlimitedWarning = useMemo(
    () => inventoryBoardList.some((inventory) => inventory.dishes.some((dish) => dish.is_inventory_unlimited === true)),
    [data]
  );

  const isDishes = categoryList.some((category) => category.dishes.length > 0);

  useEffect(() => {
    if (!skip) {
      getInventory();
    }
  }, [skip]);

  return {
    categoryList,
    subCategoryList,
    isLoading,
    isError,
    isWarning,
    isUnlimitedWarning,
    isDishes,
    isPaymentMethod,
  };
};

export default useInventory;
