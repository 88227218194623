import { ROUTES } from '@base/utils/constants/routes';
import ClientPromotionItem from '@components/Client/ClientPromotions/ClientPromotionItem';
import useClientPromotions from '@components/Client/ClientPromotions/useClientPromotions';
import Heading from '@components/UI/Heading';
import MyModal from '@components/UI/MyModal';
import PromotionFixedButton from '@components/UI/PromotionFixedButton';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ClientPromotions = () => {
  const { t } = useTranslation();
  const {
    isOpened,
    setIsOpened,
    promotions,
    isLoading,
    isSuccess,
    isError,
    promotionID,
    isOrderPlacementPayment,
    isPushNotifications,
  } = useClientPromotions();
  const location = useLocation();

  const renderPromotionsItems = () =>
    promotions.map((promotion) => (
      <ClientPromotionItem
        key={`promotion-id-${promotion.id}`}
        promotion_id={promotion.id}
        title={promotion.name}
        description={promotion.description}
        hash={promotion.hash}
        old_price={promotion.old_price}
        new_price={promotion.new_price}
        isImmediateDisplay={promotionID === promotion.id}
        setIsOpenedPromotionModal={setIsOpened}
        isOrderPlacementPayment={isOrderPlacementPayment}
      />
    ));

  return (
    <>
      {location.pathname !== ROUTES.root && isPushNotifications && (
        <>
          <MyModal
            open={isOpened}
            onCancel={() => setIsOpened(false)}
            centered={false}
            footer={false}
            closeIcon={false}
            forceRender={true}
            wrapClassName={'client-promotion-modal-wrapper'}
            className={'client-promotion-modal max-w-[560px]'}
            width={'90%'}
            style={{ top: 0, padding: 0 }}
            bodyStyle={{ padding: '4px 0' }}
            maskStyle={{ pointerEvents: 'auto' }}
          >
            <Heading className={'client-promotion-modal__title mb-8 text-center !text-grey-40'}>
              {t('my_benefits')}
            </Heading>

            <div className={'client-promotion-modal__items flex max-h-[195px] flex-col gap-6 overflow-y-auto'}>
              {isError ? (
                <p>{t('error')}</p>
              ) : isLoading ? (
                <p>{t('loading')}</p>
              ) : isSuccess && promotions.length > 0 ? (
                renderPromotionsItems()
              ) : (
                <p>{t('there_are_not_promotions')}</p>
              )}
            </div>
          </MyModal>

          {promotions.length > 0 && <PromotionFixedButton isOpened={isOpened} setIsOpened={setIsOpened} />}
        </>
      )}
    </>
  );
};

export default ClientPromotions;
