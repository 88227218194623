import MBFolderPlusIcon from '@components/icons/MBFolderPlusIcon';
import MenuCreateCategoryForm from '@components/Menus/MenuCreateCategoryForm';
import FullWidthButton from '@components/UI/FullWidthButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MenuCreateCategory = () => {
  const { t } = useTranslation();
  const [isShowForm, setIsShowFrom] = useState(false);

  return (
    <>
      {isShowForm && (
        <div className={'pad mb-4 rounded bg-white px-6 py-4'}>
          <MenuCreateCategoryForm setIsShowed={setIsShowFrom} />
        </div>
      )}
      <FullWidthButton onClick={() => setIsShowFrom(true)}>
        {t('add_category')} <MBFolderPlusIcon />
      </FullWidthButton>
    </>
  );
};

export default MenuCreateCategory;
