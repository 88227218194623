import { useAppSelector } from '@base/redux/store';
import { centralDomain } from '@constants/environments';
import generateRestaurantLink from '@helpers/generateRestaurantLink';

function getOriginalRestHost(route: string): string {
  const role = useAppSelector((state) => state.userState.role);
  const isManager = role === 'restaurant-manager';
  const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);
  const restSettingDomain = useAppSelector((state) => state.restaurantSettingsState.domain);

  return isSubdomain && restSettingDomain && !isManager
    ? `${generateRestaurantLink(restSettingDomain)}${route}`
    : `${centralDomain}${route}`;
}

export default getOriginalRestHost;
