import {
  useChangeAvailableDishOptionValueMutation,
  useCreateDishOptionValueMutation,
  useRemoveDishOptionValueMutation,
  useUpdateDishOptionValueMutation,
} from '@api/dishOptionsApi';

const useOptionValue = () => {
  const [crateValue, { data: createdValue, isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] =
    useCreateDishOptionValueMutation();
  const [updateValue, { isLoading: isLoadingUpdateValue, isSuccess: isSuccessUpdate }] =
    useUpdateDishOptionValueMutation();
  const [removeValue, { isLoading: isLoadingRemoveValue }] = useRemoveDishOptionValueMutation();
  const [updateAvailability] = useChangeAvailableDishOptionValueMutation();

  return {
    crateValue,
    isLoadingCreate,
    isSuccessCreate,
    createdValue,
    updateValue,
    isLoadingUpdateValue,
    isSuccessUpdate,
    removeValue,
    updateAvailability,
    isLoading: isLoadingCreate || isLoadingUpdateValue,
    isLoadingRemoveValue,
  };
};

export default useOptionValue;
