import { useLazyGetTransactionByIdQuery } from '@base/redux/api/transactionApi';
import { message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

const useTransactionsTable = () => {
  const { t } = useTranslation();

  const [
    getTransactionById,
    {
      data: dataTransaction,
      isError: isErrorTransaction,
      isSuccess: isSuccessTransaction,
      isFetching: isFetchingTransaction,
    },
  ] = useLazyGetTransactionByIdQuery();

  const printTransaction = useReactToPrint({
    content: () => document.getElementById('packageTransaction'),
    copyStyles: true,
    bodyClass: document.documentElement.getAttribute('dir') === 'rtl' ? 'printable-qrcode-rtl-direction' : '',
  });

  useEffect(() => {
    const isPrint =
      !isFetchingTransaction && isSuccessTransaction && dataTransaction && dataTransaction.payment_method !== 'no_type';

    // open print dialog
    if (isPrint) {
      const content = document.getElementById('packageTransaction');

      if (!content) {
        return;
      }

      printTransaction();
    }

    if (!isFetchingTransaction && isErrorTransaction) {
      message.error(t('error'));
    }
  }, [isFetchingTransaction, isSuccessTransaction]);

  return {
    dataTransaction,
    getTransactionById,
  };
};

export default useTransactionsTable;
