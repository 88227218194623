import { Dish } from '@api/models/Dish';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DishKeysTabs = 'dish-tab-1' | 'dish-tab-2' | 'dish-tab-3';

interface MenuState {
  isModalDishOpen: boolean;
  dishParentCategoryId: number | null;
  dishInfo: Dish | null;
  dishImage: string | null;
  collapsedActiveKeys: string[] | [];
  activeTabDishModal: DishKeysTabs | null;
}

const initialState: MenuState = {
  isModalDishOpen: false,
  dishParentCategoryId: null,
  dishInfo: null,
  dishImage: null,
  collapsedActiveKeys: [],
  activeTabDishModal: 'dish-tab-1',
};

export const menuSlice = createSlice({
  initialState,
  name: 'menu',
  reducers: {
    resetMenuSlice: () => initialState,
    setIsModalDishOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalDishOpen = action.payload;
    },
    setDishInfo: (state, action: PayloadAction<Dish | null>) => {
      state.dishInfo = action.payload ? { ...action.payload } : null;
    },
    setDishParentCategoryId: (state, action: PayloadAction<number | null>) => {
      state.dishParentCategoryId = action.payload;
    },
    setCollapsedActiveKeys: (state, action: PayloadAction<string[] | []>) => {
      state.collapsedActiveKeys = action.payload;
    },
    setActiveTabDishModal: (state, action: PayloadAction<DishKeysTabs | null>) => {
      state.activeTabDishModal = action.payload;
    },
    setDishImage: (state, action: PayloadAction<string | null>) => {
      state.dishImage = action.payload;
    },
  },
});

export default menuSlice.reducer;

export const {
  resetMenuSlice,
  setIsModalDishOpen,
  setDishInfo,
  setDishParentCategoryId,
  setCollapsedActiveKeys,
  setActiveTabDishModal,
  setDishImage,
} = menuSlice.actions;
