import { SVGProps } from 'react';
const MBPhoneFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.49958 16.9201V19.9201C2.49845 20.1986 2.5555 20.4743 2.66707 20.7294C2.77864 20.9846 2.94228 21.2137 3.1475 21.402C3.35272 21.5902 3.59501 21.7336 3.85883 21.8228C4.12265 21.912 4.40221 21.9452 4.67958 21.9201C7.75674 21.5857 10.7126 20.5342 13.3096 18.8501C15.7258 17.3148 17.7742 15.2663 19.3096 12.8501C20.9996 10.2413 22.0513 7.27109 22.3796 4.1801C22.4046 3.90356 22.3717 3.62486 22.2831 3.36172C22.1945 3.09859 22.052 2.85679 21.8648 2.65172C21.6776 2.44665 21.4498 2.28281 21.1958 2.17062C20.9418 2.05843 20.6672 2.00036 20.3896 2.0001H17.3896C16.9043 1.99532 16.4338 2.16718 16.0658 2.48363C15.6978 2.80008 15.4575 3.23954 15.3896 3.7201C15.263 4.68016 15.0281 5.62282 14.6896 6.5301C14.555 6.88802 14.5259 7.27701 14.6057 7.65098C14.6854 8.02494 14.8707 8.36821 15.1396 8.6401L16.4096 9.9101C14.986 12.4136 12.9131 14.4865 10.4096 15.9101L9.13958 14.6401C8.86769 14.3712 8.52442 14.1859 8.15046 14.1062C7.77649 14.0264 7.3875 14.0556 7.02958 14.1901C6.12231 14.5286 5.17964 14.7635 4.21958 14.8901C3.73381 14.9586 3.29018 15.2033 2.97305 15.5776C2.65591 15.9519 2.48741 16.4297 2.49958 16.9201Z"
      fill="currentColor"
    />
  </svg>
);
export default MBPhoneFilledIcon;
