import { DishPromotion } from '@api/models/Promotion';
import { addProduct, setIsPromotion } from '@base/redux/features/cartSlice';
import { useAppSelector } from '@base/redux/store';
import { CartPromotionProduct } from '@base/types/Cart';
import ClientPromotionsExtras from '@components/Client/ClientPromotions/ClientPromotionsExtras';
import ClientPromotionsOptions from '@components/Client/ClientPromotions/ClientPromotionsOptions';
import DishInfoCard from '@components/DishInfoCard';
import Heading from '@components/UI/Heading';
import { CURRENCY, zIndexes } from '@constants/constants';
import { App, Button, Drawer } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface ClientPromotionDrawerProps {
  promotion_id: number;
  isOpened: boolean;
  title: string;
  description?: string;
  old_price: number;
  new_price: number;
  selectedOptions: any;
  selectedExtras: any;
  dish: DishPromotion;
  handleClose: () => void;
  handleAfterClose?: () => void;
  isOrderPlacementPayment?: boolean;
}

const ClientPromotionDrawer: FC<ClientPromotionDrawerProps> = ({
  promotion_id,
  isOpened,
  handleClose,
  handleAfterClose,
  title,
  description,
  old_price,
  new_price,
  selectedOptions,
  selectedExtras,
  dish,
  isOrderPlacementPayment = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const isPaymentMethod = useAppSelector((state) => state.restaurantState.payment_method);

  const addToCart = () => {
    const product: CartPromotionProduct = {
      card_id: Date.now(),
      id: promotion_id,
      dish_name: dish.dish_name,
      price_one_item: +dish.price,
      product_total_price: new_price,
      quantity: 1,
      description: dish.description,
      options: [],
      extras: [],
      inventory_quantity: dish.inventory_quantity,
      is_inventory_unlimited: dish.is_inventory_unlimited,
      isPromotion: true,
      product_id: dish.id,
      promotion_id,
      promotion_name: title,
    };

    dispatch(setIsPromotion(true));
    dispatch(addProduct(product));
    handleClose();
    message.success(t('product_added_to_card'));
  };

  return (
    <Drawer
      height={'75%'}
      headerStyle={{ border: 'none', paddingBottom: 0 }}
      placement={'bottom'}
      open={isOpened}
      onClose={handleClose}
      className={'dish-settings-modal'}
      afterOpenChange={handleAfterClose}
      zIndex={zIndexes.promotionModal}
    >
      <div className="dish-settings-modal__content dish-settings-promotion">
        <DishInfoCard
          title={title}
          description={description || ''}
          imgUrl={dish.image}
          cardClassName={'dish-info-card--borderless-variant'}
          price={dish.price}
        />

        <div className={'flex flex-col gap-6 py-4'}>
          {selectedOptions && <ClientPromotionsOptions options={selectedOptions} />}
          {selectedExtras && <ClientPromotionsExtras extras={selectedExtras} />}
        </div>

        <footer className={'dish-settings-promotion__footer dish-settings-footer'}>
          <div>{t('to_be_paid')}:</div>
          <div className={'dish-settings-promotion-price'}>
            <div className={'dish-settings-promotion-price__old-price'}>
              {CURRENCY}
              {old_price}
            </div>
            <Heading className={'dish-settings-promotion-price__new-price'}>
              {CURRENCY}
              {new_price}
            </Heading>
          </div>
        </footer>
        {isOrderPlacementPayment && isPaymentMethod && (
          <Button
            htmlType={'submit'}
            type={'primary'}
            className={'dish-settings-footer__submit-button dish-settings-footer__submit-button--big-h'}
            onClick={addToCart}
          >
            {t('add_to_order')}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

export default ClientPromotionDrawer;
