import colors from '@constants/colors';

export const secondaryButton = {
  components: {
    Button: {
      defaultBorderColor: colors.indigo30,
      colorText: colors.indigo30,

      colorBorder: colors.indigo30,
      defaultGhostBorderColor: colors.indigo30,
      colorPrimaryBorder: colors.indigo30,

      controlHeightSM: 33,
      fontSize: 14,
    },
  },
};
