import { useGetAllergiesQuery, useGetDishTypesQuery } from '@api/publicApi';
import { useAppSelector } from '@base/redux/store';
import { DishFormFieldType } from '@components/Menus/DishForm/DishForm';
import DishHeroUpload from '@components/Menus/DishForm/DishHeroUpload';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import { CURRENCY } from '@constants/constants';
import { regExNumber, regExPrice } from '@constants/regexp';
import useCheckRole from '@hooks/useCheckRole';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DishFormDetails = () => {
  const { t } = useTranslation();
  const restIsTakeAway = useAppSelector((state) => state.restaurantSettingsState.isTakeAway);
  const { data: dishTypeList } = useGetDishTypesQuery();
  const { data: allergyList } = useGetAllergiesQuery();
  const { dishInfo } = useAppSelector((state) => state.menuSlice);
  const { isManager } = useCheckRole();
  const form = useFormInstance();
  const [isUnlimitedChecked, setIsUnlimitedChecked] = useState<boolean>(false);

  const unlimitedDefaultValue = form.getFieldValue('is_inventory_unlimited');

  useEffect(() => {
    if (unlimitedDefaultValue !== undefined) {
      setIsUnlimitedChecked(unlimitedDefaultValue);
    }
  }, [unlimitedDefaultValue]);

  return (
    <>
      <Row gutter={[40, 0]}>
        <Col xs={18}>
          <Form.Item<DishFormFieldType> name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item<DishFormFieldType> name="menu_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item<DishFormFieldType> name="updated_at" hidden>
            <Input />
          </Form.Item>
          <Form.Item<DishFormFieldType>
            label={t('dish_name')}
            name="dish_name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('dish_name_placeholder')} disabled={!isManager} />
          </Form.Item>
          <Form.Item<DishFormFieldType> label={t('description')} name="description">
            <Input.TextArea maxLength={255} rows={2} placeholder={t('dish_des_placeholder')} disabled={!isManager} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <DishHeroUpload url={dishInfo?.image} />
        </Col>
        <Col xs={13}>
          <Row gutter={[24, 24]}>
            <Col xs={7}>
              <Form.Item<DishFormFieldType>
                label={t('price')}
                name="price"
                rules={[
                  { required: true, message: t('required_field') },
                  {
                    pattern: regExPrice,
                    message: t('input_errors.invalid_price_format'),
                  },
                ]}
              >
                <Input placeholder={'2'} suffix={CURRENCY} disabled={!isManager} />
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Form.Item<DishFormFieldType>
                label={t('amount')}
                name="inventory_quantity"
                rules={[
                  { required: !isUnlimitedChecked, message: t('required_field') },
                  {
                    pattern: regExNumber,
                    message: t('input_errors.invalid_number_format'),
                  },
                ]}
              >
                <Input
                  placeholder={'2'}
                  suffix={<span className={'text-sm'}>{t('dishes')}</span>}
                  disabled={!isManager || isUnlimitedChecked}
                />
              </Form.Item>
            </Col>
            <Col xs={10}>
              <Form.Item<DishFormFieldType>
                name="is_inventory_unlimited"
                valuePropName="checked"
                label={' '}
                className={'pt-1'}
              >
                <Checkbox onChange={() => setIsUnlimitedChecked((prev) => !prev)}>{t('unlimited')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={12}>
          <Form.Item<DishFormFieldType> label={t('type')} name={'dish_type_id'}>
            <Select placeholder={t('no_type')} disabled={!isManager}>
              {dishTypeList &&
                dishTypeList.map((dishType, i) => (
                  <Select.Option key={`dish-type-${dishType.id}-${i}`} value={dishType.id}>
                    {dishType.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item<DishFormFieldType> label={t('allergies')} name={'allergies'}>
            <Select
              direction={'ltr'}
              mode="multiple"
              allowClear
              placeholder={t('allergies_placeholder')}
              className={'select-checkbox w-full'}
              popupClassName={'select-checkbox-list'}
              disabled={!isManager}
            >
              {allergyList &&
                allergyList.map((allergy, i) => (
                  <Select.Option key={`allergy-${i}`} value={allergy.id}>
                    {allergy.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item<DishFormFieldType> initialValue name="is_portion_available" valuePropName="checked">
        <HorizontalSwitch label={t('serving_available')} />
      </Form.Item>
      <Form.Item<DishFormFieldType> initialValue={false} name="is_take_away" valuePropName="checked" className={'mb-0'}>
        <HorizontalSwitch label={t('suitable_dish_4_take_away')} disabled={!restIsTakeAway} />
      </Form.Item>
    </>
  );
};

export default DishFormDetails;
