import { OnboardingKeys } from '@base/redux/features/userSlice';
import useTour from '@components/UI/Tour/useTour';
import useSteps from './useSteps';

const useMobileOrdersOnboarding = () => {
  const onboardingKey: OnboardingKeys = 'waiter_orders';

  const { steps, obStepRef1 } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
  };
};

export default useMobileOrdersOnboarding;
