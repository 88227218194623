import { removeProduct, updateProductQuantity } from '@base/redux/features/cartSlice';
import { CartExtra, CartOption, CartProductErrors } from '@base/types/Cart';
import SuprisedLogo from '@components/svg/SuprisedLogo';
import AmountCounter from '@components/UI/AmountCounter';
import OrderSection from '@components/UI/OrderSection';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { CURRENCY } from '@constants/constants';
import roundPrice from '@helpers/roundPrice';
import { Alert, List } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface CartDishItemProps {
  cartProductID: number;
  name: string;
  description?: string;
  options?: CartOption[];
  extras?: CartExtra[];
  amount: number;
  price: number;
  counter?: boolean;
  inventoryQuantity?: number;
  isInventoryUnlimited?: boolean;
  removable?: boolean;
  isPromotion?: boolean;
  isWithoutBEMClasses?: boolean;
  errors?: CartProductErrors;
}

const CartDishItem: FC<CartDishItemProps> = ({
  cartProductID,
  name,
  description,
  options,
  extras,
  amount,
  price,
  inventoryQuantity,
  isInventoryUnlimited,
  counter = false,
  removable = false,
  isPromotion = false,
  isWithoutBEMClasses = false,
  errors,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const warningMessage = errors?.inventoryQuantity
    ? `${t('maximum_available')}: ${inventoryQuantity}`
    : errors?.notAvailable
    ? t('dish_not_available')
    : '';

  const handleRemove = (id: number) => {
    dispatch(removeProduct(id));
  };

  const handleUpdateProductAmount = (amount: number) => {
    dispatch(updateProductQuantity({ cartProductId: cartProductID, quantity: amount }));
  };

  return (
    <OrderSection>
      {isPromotion && <SuprisedLogo />}
      <div className={isWithoutBEMClasses ? 'font-bold text-indigo-40' : 'order-section__title'}>{name}</div>
      <p className={isWithoutBEMClasses ? 'mb-0' : 'order-section__description'}>{description}</p>
      {((options && options?.length > 0) || (extras && extras?.length > 0)) && (
        <List>
          {options?.map((option) => <li key={`opt-${option.name}`}>{option.values?.value}</li>)}
          {extras?.map((extra) => (extra.is_readily_available ? <li key={`ext-${extra.name}`}>{extra.name}</li> : ''))}
        </List>
      )}
      <div className={'flex items-center justify-between gap-4'}>
        <div>{t('amount')}:</div>
        <div className={'flex items-center gap-4'}>
          {counter ? (
            <AmountCounter
              key={`amount-counter-key-${cartProductID}`}
              amount={amount}
              bgColorBtnClass={'bg-white'}
              handleUpdateProductAmount={handleUpdateProductAmount}
              inventoryQuantity={inventoryQuantity as number}
              isInventoryUnlimited={isInventoryUnlimited as boolean}
            />
          ) : (
            amount
          )}
          {removable && <RemoveIconButton onClick={() => handleRemove(cartProductID)} />}
        </div>
      </div>
      <div className={isWithoutBEMClasses ? 'flex items-center justify-between gap-4' : 'order-section__price-block'}>
        <div>{t('to_be_paid')}:</div>
        <div>
          {CURRENCY}
          {roundPrice(price)}
        </div>
      </div>
      {warningMessage && <Alert type={'warning'} showIcon message={warningMessage} className={'p-2 text-xs'} />}
    </OrderSection>
  );
};

export default CartDishItem;
