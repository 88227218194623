import placeholderRestLogo from '@assets/img/logo-restaurant-placeholder.png';
import { CartProduct } from '@base/types/Cart';
import CartDishItem from '@components/CartDishItem';
import Heading from '@components/UI/Heading';
import OrderSection from '@components/UI/OrderSection';
import { CURRENCY } from '@constants/constants';
import { Card, Image } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ClientPaymentSummeryProps {
  restLogo: string | null;
  restName: string;
  products: CartProduct[];
  cart_total_price: number;
}

const ClientPaymentSummery: FC<ClientPaymentSummeryProps> = ({ restLogo, restName, products, cart_total_price }) => {
  const { t } = useTranslation();

  const renderProducts = useMemo(
    () =>
      products.map((product) => (
        <CartDishItem
          key={`cart-item-${product.id}`}
          cartProductID={product.card_id}
          name={product.dish_name}
          description={product.description}
          price={product.product_total_price}
          options={product.options}
          extras={product.extras}
          amount={product.quantity}
          removable={false}
          counter={false}
          isPromotion={product.isPromotion}
          isWithoutBEMClasses={true}
          inventoryQuantity={product.inventory_quantity}
        />
      )),
    [products]
  );

  return (
    <Card>
      <Heading className={'mb-4 text-center'}>{t('order_payment')}</Heading>
      <div
        className={'mb-4 flex items-center justify-center gap-4 pb-1'}
        style={{ borderBottom: '1px solid var(--grey-15)' }}
      >
        <Image
          preview={false}
          width={40}
          height={40}
          src={restLogo || placeholderRestLogo}
          alt={`Logo of ${restName} restaurant.`}
        />
        <div className={'font-bold'}>{restName}</div>
      </div>
      <div className={'flex flex-col gap-4'}>
        {renderProducts}

        <OrderSection>
          <div className={'flex items-center justify-between gap-4'}>
            <div>{t('cost')}:</div>
            <div className={'font-bold'}>
              {CURRENCY}
              {cart_total_price}
            </div>
          </div>
        </OrderSection>
      </div>
    </Card>
  );
};

export default ClientPaymentSummery;
