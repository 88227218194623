import colors from '@constants/colors';

export const secondarySelect = {
  components: {
    Select: {
      colorBorder: colors.indigo30,
      colorText: colors.grey40,
      colorIcon: colors.indigo30,
      selectorBg: colors.white,
      colorPrimary: colors.indigo30,
      colorPrimaryHover: colors.indigo30,
      colorTextPlaceholder: colors.indigo30,
      multipleItemBorderColor: colors.indigo30,
      optionSelectedBg: colors.indigo15,
      optionSelectedColor: colors.indigo40,

      controlHeightSM: 33,
      optionPadding: '0px 8px',
      optionHeight: 26,
      borderRadius: 10,
      borderRadiusLG: 10,
      borderRadiusSM: 10,
      borderRadiusXS: 10,
    },
  },
};
