import { PackageTransaction } from '@base/redux/api/models/PackageTransaction';
import { Pagination } from '@base/redux/api/models/Pagination';
import { useGetTransactionsQuery } from '@base/redux/api/transactionApi';
import { useEffect, useState } from 'react';

const useTransactions = () => {
  const [pagePagination, setPagePagination] = useState(1);

  const [transactionsState, setTransactionsState] = useState<{
    transactions: PackageTransaction[];
    pagination: Pagination | false;
  }>({
    transactions: [],
    pagination: false,
  });

  const {
    data: dataTransactions,
    isError: isErrorTransactions,
    isLoading: isLoadingTransactions,
  } = useGetTransactionsQuery(pagePagination);

  useEffect(() => {
    if (!isLoadingTransactions && dataTransactions && dataTransactions.meta && dataTransactions.data) {
      setTransactionsState({ transactions: dataTransactions.data, pagination: dataTransactions.meta });
    }
  }, [isLoadingTransactions]);

  return {
    transactions: transactionsState.transactions,
    pagination: transactionsState.pagination,
    isLoadingTransactions,
    isErrorTransactions,
    setPagePagination,
  };
};

export default useTransactions;
