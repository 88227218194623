// const NGROK_DOMAIN = 'f482-2-202-3-12'; // ngrok domain

function checkIsSubdomain(): string | null {
  const host = window.location.host;
  const arr = host.split('.');
  let subdomain = null;

  const isDevMode = process.env.NODE_ENV === 'development';

  // TODO: change it when production will be on original domain of project
  // TODO: Now a subdomain is defined by dropping the domain into points.
  // if localhost and main domain - localhost.com:3000
  // if localhost and submain domain - mydomain.localhost.com:3000
  // if use ngrok domain:
  // for domain - just return null
  // for subdomain - return the subdomain of the restaurant you created

  // localhost or production
  if ((isDevMode && arr.length === 3) || (!isDevMode && arr.length === 4)) {
    subdomain = arr[0].replace(/^(http|https):\/\//, '');
  }

  // TODO: if use ngrok for https and use restaurant subdomain
  /* if (isDevMode && subdomain && subdomain === NGROK_DOMAIN) {
    subdomain = 'mydomain';
  }*/

  return subdomain; // TODO: return null if need to be on main domain with ngrok
}

export default checkIsSubdomain;
