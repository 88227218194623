import { useLazyGetRestaurantByDomainQuery, useLazyGetRestaurantByIdQuery } from '@api/publicTenantApi';
import { setIsSubdomain, setSubdomain } from '@base/redux/features/commonSlice';
import { setRestSettings } from '@base/redux/features/restaurantSettingsSlice';
import { setRestaurantInfo } from '@base/redux/features/restaurantSlice';
import { useAppSelector } from '@base/redux/store';
import { COOKIE_RESTAURANT_LINK } from '@constants/constants';
import { domain } from '@constants/environments';
import checkIsSubdomain from '@helpers/checkIsSubdomain';
import generateRestaurantLink from '@helpers/generateRestaurantLink';
import getQueryParam from '@helpers/getQueryParam';
import useAuth from '@hooks/useAuth';
import useLogout from '@hooks/useLogout';
import NotFoundRestaurantPage from '@pages/NotFoundRestaurantPage';
import Cookies from 'js-cookie';
import { createContext, FC, ReactNode, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

const RestaurantContext = createContext({});

export const RestaurantInitProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  const subdomain = checkIsSubdomain();
  const { restaurantId } = useAuth();
  const { logout } = useLogout();

  const restaurantStateId = useAppSelector((state) => state.restaurantSettingsState.id || state.restaurantState.id);
  const useID = useAppSelector((state) => state.userState.id);

  const [
    getRestaurantBySubdomain,
    {
      isLoading: isLoadingBySubdomain,
      isFetching: isFetchingBySubdomain,
      isError: isErrorBySubdomain,
      data: dataBySubdomain,
    },
  ] = useLazyGetRestaurantByDomainQuery();

  const [
    getRestaurantById,
    { isLoading: isLoadingById, isFetching: isFetchingById, isError: isErrorById, data: dataById },
  ] = useLazyGetRestaurantByIdQuery();

  const isRestaurant = restaurantId || subdomain;
  const isError = isErrorById || isErrorBySubdomain;
  const isLoading = isLoadingById || isLoadingBySubdomain;
  const data = dataById || dataBySubdomain;

  const initRestaurant = async () => {
    if (subdomain) {
      // init by rest domain
      await getRestaurantBySubdomain(subdomain);
      // init by rest id
    } else if (restaurantId) {
      await getRestaurantById(restaurantId);
    }
  };

  useLayoutEffect(() => {
    initRestaurant();
  }, [restaurantId, subdomain, useID]);

  useLayoutEffect(() => {
    const isLogout = getQueryParam('logout');

    if (isLogout) {
      const res = logout();
      res.then(() => {
        initRestaurant();
      });
    }
  }, []);

  // init by subdomain
  useEffect(() => {
    if (!isLoadingBySubdomain && !isFetchingBySubdomain && dataBySubdomain) {
      const restLink = generateRestaurantLink(dataBySubdomain.domain);

      // for return from central domain to subdomain
      Cookies.set(COOKIE_RESTAURANT_LINK, restLink, {
        expires: 1,
        path: '/',
        domain,
      });
      dispatch(setIsSubdomain(true));
      dispatch(setSubdomain(subdomain));
      dispatch(setRestaurantInfo(dataBySubdomain));

      if (restaurantId === dataBySubdomain.id) {
        dispatch(setRestSettings(dataBySubdomain));
      } else if (restaurantId) {
        getRestaurantById(restaurantId);
      }

      return;
    }
  }, [dataBySubdomain, isLoadingBySubdomain, isFetchingBySubdomain]);

  // init by id
  useEffect(() => {
    if (!isFetchingById && dataById) {
      dispatch(setRestSettings(dataById));
    }
  }, [dataById, isFetchingById, isLoadingById]);

  useEffect(() => {
    if (isErrorBySubdomain) {
      dispatch(setIsSubdomain(false));
      dispatch(setSubdomain(null));
    }
  }, [isErrorBySubdomain]);

  // after creation restaurant to update restaurant settings
  useEffect(() => {
    if (restaurantId && !restaurantStateId) {
      getRestaurantById(restaurantId);
    }
  }, [restaurantId]);

  return (
    <RestaurantContext.Provider value={{}}>
      {!isRestaurant ? (
        <>{children}</>
      ) : isError ? (
        <NotFoundRestaurantPage />
      ) : isLoading ? (
        // TODO: add pretty spinner instead
        'Init restaurant...'
      ) : (
        data && <>{children}</>
      )}
    </RestaurantContext.Provider>
  );
};
