import { OnboardingKeys } from '@base/redux/features/userSlice';
import useSteps from '@components/QRBuilder/QRBuilderOnboarding/useSteps';
import useTour from '@components/UI/Tour/useTour';
import useGetMediaQuery from '@hooks/useGetMediaQuery';

const useQRBuilderOnboarding = () => {
  const { isXL } = useGetMediaQuery();
  const onboardingKey: OnboardingKeys = 'qr_builder';

  const { steps, obStepRef1 } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard && isXL;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
  };
};

export default useQRBuilderOnboarding;
