import OrderSection from '@base/components/UI/OrderSection';
import { Order } from '@base/redux/api/models/Order';
import { CURRENCY } from '@constants/constants';
import { List } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderDishListProps {
  order: Order;
}

const OrderDishList: FC<OrderDishListProps> = ({ order }) => {
  const { t } = useTranslation();

  const renderOrders = () => (
    <div className={'flex flex-col gap-4'}>
      {order.order_items.map((orderItem) => {
        const orderName = orderItem?.dish?.dish_name || orderItem?.promotion?.dish.dish_name;
        const orderDescription = orderItem?.dish?.description || orderItem?.promotion?.dish.description;

        return (
          <OrderSection key={`order-key-${orderItem.id}-${orderItem.order_id}`}>
            <div>
              <span className={'font-bold text-indigo-40'}>{orderName}</span>
              <p className={'mb-0'}>{orderDescription}</p>
            </div>
            {((orderItem.options && orderItem.options?.length > 0) ||
              (orderItem.extras && orderItem.extras?.length > 0)) && (
              <List>
                {orderItem.options?.map((option) => <li key={`opt-${option.name}`}>{option.values?.value}</li>)}
                {orderItem.extras?.map((extra) =>
                  extra.is_readily_available ? <li key={`ext-${extra.name}`}>{extra.name}</li> : ''
                )}
              </List>
            )}
            <div className={'flex items-center justify-between gap-4'}>
              <div>{t('amount')}:</div>
              <div className={'flex items-center gap-4'}>{orderItem.quantity}</div>
            </div>
            <div className={'flex items-center justify-between gap-4'}>
              <div>{t('cost')}:</div>
              <div>
                {CURRENCY}
                {orderItem.price}
              </div>
            </div>
          </OrderSection>
        );
      })}
    </div>
  );

  return <>{renderOrders()}</>;
};

export default OrderDishList;
