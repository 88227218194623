import { PackagePlansType } from '@base/types/PackagePlansType';
import { SubscriptionPackagePlansType } from '@base/types/SubscriptionPackagePlansType';

export const weekDaysArray = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const logoRestaurantFormats = ['image/png', 'image/jpeg'];
export const heroRestaurantFormats = ['image/png', 'image/jpeg'];
export const workers = ['supervisor', 'waiter'];
export const COOKIE_TOKEN_KEY = 'token';

export const COOKIE_USER_KEY = 'user';
export const COOKIE_ORDER_KEY = 'clientOrder';
export const COOKIE_RESTAURANT_LINK = 'restaurantLink';
export const COOKIE_CLIENT_PREFERENCES_KEY = 'clientPreferences';
export const COOKIE_CLIENT_IS_ORDER_SUCCESS_KEY = 'isOrderSuccess';

export const COOKIE_ROLE_KEY = 'role';

export const LOCALSTORAGE_CLIENT_ORDER_KEY = 'clientOrder';

export const INVENTORY_WARNING_INTERVAL_TIME = 1000 * 60 * 5; // 5 minutes
export const INVENTORY_LIMIT_LOW = 5;
export const INVENTORY_LIMIT_SUFFICIENT = 10;

export const DEFAULT_COLLAPSED_CART_HEIGHT = 130;
export const EXTRA_PROMOTION_CART_HEIGHT = 20;

export const MENU_FILTER_ALL_TYPE = 'all';
export const MENU_FILTER_SPECIFIC_TYPE = 'specific';

export const CURRENCY = '₪';

export const QR_SCAN_GET_PARAMETER = 'qr-scanned';

export const PACKAGE_PLANS: PackagePlansType[] = ['free', 'basic', 'premium'];
export const DEFAULT_PACKAGE_PLAN: PackagePlansType = 'free';
export const PACKAGE_PLAN_GET_PARAMETER = 'package';

export const DEFAULT_SUBSCRIPTION_PACKAGE_PLAN: SubscriptionPackagePlansType = 'Free';
export const TRIAL_SUBSCRIPTION_PACKAGE_PLAN: SubscriptionPackagePlansType = 'Trial';

export const DATE_FORMAT = 'DD/MM/YYYY ,HH:mm';

export const TOUR_DEFAULT_CONTENT_WIDTH = 400;

export const SERVER_GOOGLE_AUTH_ERROR = 'use_google_login';
export const SERVER_GOOGLE_AUTH_ERROR_PASS_RECOVER = 'user_register_in_system_by_google.';

export const LOGOUT_QUERY_PARAM = 'logout=true';

export const zIndexes = {
  cartModal: 100,
  promotionFixedBtn: 200,
  promotionModal: 300,
  dishSettingModal: 300,
  promotionFixedBtnActive: 1002,
};
