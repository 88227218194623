import { useTogglePromotionMutation } from '@api/promotionApi';
import { Switch } from 'antd';
import { FC, useState } from 'react';

const PromotionCardSwitcher: FC<{ id: number; is_active: boolean; disabled?: boolean }> = ({
  id,
  is_active,
  disabled = false,
}) => {
  const [isActive, setIsActive] = useState(is_active);
  const [toggle] = useTogglePromotionMutation();

  const handleSwitch = (value: boolean) => {
    toggle({ id, is_active: value });
    setIsActive(value);
  };

  return <Switch checked={isActive} onChange={handleSwitch} disabled={disabled} />;
};

export default PromotionCardSwitcher;
