import sidebarImage from '@assets/img/bg-header-auth-form.jpg';
import MBMenuIcon from '@base/components/icons/MBMenuIcon';
import MenubitRegisterLogo from '@base/components/svg/MenubitRegisterLogo';
import CabinetNav from '@base/types/CabinetNav';
import { centralDomain } from '@base/utils/constants/environments';
import MBMealIcon from '@components/icons/MBMealIcon';
import MBSettingsIcon from '@components/icons/MBSettingsIcon';
import generateRestaurantLink from '@helpers/generateRestaurantLink';
import getUrlWithCentralDomain from '@helpers/getUrlWithCentralDomain';
import navigationGeneration from '@helpers/navigationGeneration';
import useAuth from '@hooks/useAuth';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { ROUTES } from '@utils/constants/routes';
import { Button, Layout, Menu } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

const ClientSidepanel: FC<{ restaurant: string | null }> = ({ restaurant }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isLG } = useGetMediaQuery();
  const [collapsed, setCollapsed] = useState(false);
  const { isAuth } = useAuth();

  const clientNav: CabinetNav[] = [
    {
      link: getUrlWithCentralDomain(ROUTES.client_orders),
      isUseCleaLink: true,
      label: t('sidebar_menu.my_order'),
      icon: <MBMealIcon />,
    },
    {
      link: restaurant ? `${generateRestaurantLink(restaurant)}${ROUTES.restaurant_menu}` : '#',
      isUseCleaLink: true,
      label: t('sidebar_menu.menus'),
      icon: <MBMenuIcon />,
      disabled: !restaurant,
    },
    { link: ROUTES.account_settings, label: t('sidebar_menu.account_settings'), icon: <MBSettingsIcon /> },
  ];

  const nav = navigationGeneration(clientNav);

  return (
    <>
      {!isAuth && (
        <div
          className={
            'relative mb-4 flex h-[108px] w-full items-center px-10 py-[10px] text-xl font-bold leading-[120%] text-white'
          }
        >
          <img
            className={'absolute left-0 top-0 z-0 h-full w-full object-cover'}
            src={sidebarImage}
            alt="Hello guest"
          />
          <span className={'z-[1]'}>{t('hi_guest')}!</span>
        </div>
      )}

      <div className={'flex flex-col items-center gap-[100px]'}>
        <Sider theme={'light'} collapsible={isLG} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <div>
            <Menu selectedKeys={[location.pathname]} mode="inline" items={nav} />
          </div>
        </Sider>
        {!isAuth && restaurant && (
          <div className={'flex w-[213px] flex-col items-center gap-4'}>
            <span className={'text-center text-base font-bold leading-[140%] text-grey-30'}>
              {t('registered_users_have_more_options')}!
            </span>
            <MenubitRegisterLogo width={'101px'} height={'80px'} />
            <Link
              className={'sidebar-client-register-button'}
              to={`${centralDomain}${ROUTES.client_registration}?restaurant=${restaurant}`}
            >
              <Button type={'primary'}>{t('to_register')}</Button>
            </Link>
            <div className={'flex gap-4 py-2'}>
              {t('already_have_account')}?
              <Link
                className={'underline underline-offset-4'}
                to={`${centralDomain}${ROUTES.client_login}?restaurant=${restaurant}`}
              >
                {t('entrance')}
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientSidepanel;
