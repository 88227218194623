import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <div className={'grow pb-12 lg:flex lg:p-5'}>
      <Outlet />
    </div>
  );
};

export default AuthLayout;
