import { useAppSelector } from '@base/redux/store';
import { Roles } from '@base/types/Roles';
import { FC, ReactNode } from 'react';

interface UserAccessProps {
  roles: Roles[];
  children: ReactNode;
}
const UserAccess: FC<UserAccessProps> = ({ roles, children }) => {
  const role = useAppSelector((state) => state.userState.role);

  if (role && roles.includes(role)) {
    return <>{children}</>;
  }

  return null;
};

export default UserAccess;
