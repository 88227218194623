import Dashboard from '@components/Dashboard';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const DashboardPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.dashboard'));

  return <Dashboard />;
};

export default DashboardPage;
