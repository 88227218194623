import StatisticsLogo from '@components/svg/StatisticsLogo';
import Heading from '@components/UI/Heading';
import { Card } from 'antd';

interface StatisticsCardProps {
  title: string;
  value: string | number;
  color?: string;
}

const StatisticsCard = ({ title, value, color = '#000' }: StatisticsCardProps) => {
  return (
    <Card bodyStyle={{ padding: '8px 24px' }} className={'w-full'}>
      <div className={`flex items-center gap-6`}>
        <StatisticsLogo className={`flex-shrink-0`} style={{ color }} />
        <div className={'h-[85.5px] w-[1px] bg-grey-15'}></div>
        <div className={'flex-grow font-bold'}>{title}</div>
        <Heading className={`flex-shrink-0 font-bold`} style={{ color }}>
          {value}
        </Heading>
      </div>
    </Card>
  );
};

export default StatisticsCard;
