import useDashboardStatistics from '@components/Dashboard/DashboardStatistics/useDashboardStatistics';
import StatisticsCard from '@components/UI/StatisticsCard';
import colors from '@constants/colors';
import { CURRENCY } from '@constants/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const statisticsColors = {
  totalOrders: colors.blackCustom,
  waiting: colors.indigo40,
  inProgress: colors.red40,
  totalSum: colors.cyan40,
};

const DashboardStatistics: FC<{ filter: string }> = ({ filter }) => {
  const { t } = useTranslation();
  const { ordersWaiting, ordersInProgress, totalOrders, totalSum, isLoading, isError } = useDashboardStatistics(filter);
  const isDataExist = (totalOrders ?? ordersWaiting ?? ordersInProgress ?? totalSum) !== undefined;

  return (
    <>
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : isDataExist ? (
        <div className={'flex flex-col gap-4'}>
          <StatisticsCard
            title={t('order_quantity')}
            value={totalOrders as number}
            color={statisticsColors.totalOrders}
          />
          <StatisticsCard
            title={t('order_are_pending')}
            value={ordersWaiting as number}
            color={statisticsColors.waiting}
          />
          <StatisticsCard
            title={t('order_in_progress')}
            value={ordersInProgress as number}
            color={statisticsColors.inProgress}
          />
          <StatisticsCard title={t('income')} value={`${totalSum} ${CURRENCY}`} color={statisticsColors.totalSum} />
        </div>
      ) : (
        <p>{t('list_is_empty')}</p>
      )}
    </>
  );
};

export default DashboardStatistics;
