import { SVGProps } from 'react';
const MBDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99967 9.33398C2.36786 9.33398 2.66634 9.63246 2.66634 10.0007V12.6673C2.66634 12.8441 2.73658 13.0137 2.8616 13.1387C2.98663 13.2637 3.1562 13.334 3.33301 13.334H12.6663C12.8432 13.334 13.0127 13.2637 13.1377 13.1387C13.2628 13.0137 13.333 12.8441 13.333 12.6673V10.0007C13.333 9.63246 13.6315 9.33398 13.9997 9.33398C14.3679 9.33398 14.6663 9.63246 14.6663 10.0007V12.6673C14.6663 13.1978 14.4556 13.7065 14.0806 14.0815C13.7055 14.4566 13.1968 14.6673 12.6663 14.6673H3.33301C2.80257 14.6673 2.29387 14.4566 1.91879 14.0815C1.54372 13.7065 1.33301 13.1978 1.33301 12.6673V10.0007C1.33301 9.63246 1.63148 9.33398 1.99967 9.33398Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21106 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 1.33398C8.36786 1.33398 8.66634 1.63246 8.66634 2.00065V10.0007C8.66634 10.3688 8.36786 10.6673 7.99967 10.6673C7.63148 10.6673 7.33301 10.3688 7.33301 10.0007V2.00065C7.33301 1.63246 7.63148 1.33398 7.99967 1.33398Z"
      fill="currentColor"
    />
  </svg>
);
export default MBDownloadIcon;
