import { RoleTypeCookies } from '@base/types/Roles';
import { COOKIE_ROLE_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const setRoleToCookies = (role: RoleTypeCookies) => {
  Cookies.set(COOKIE_ROLE_KEY, role, {
    expires: 1,
    path: '/',
    domain,
  });
};

export default setRoleToCookies;
