import { useAppSelector } from '@base/redux/store';
import { useTranslation } from 'react-i18next';
import Logo from '../icons/Logo';
import MBBurgerIcon from '../icons/MBBurgerIcon';
import RestaurantHero from '../RestaurantHero';
import RestaurantLogo from '../RestaurantLogo';

const RestaurantPreview = () => {
  const { t } = useTranslation();
  const { name, secondaryTitle, description, logoSrc } = useAppSelector((state) => state.restaurantSettingsState);

  return (
    <div className={'relative h-[292px] w-[280px] overflow-hidden rounded bg-grey-5 px-4'}>
      <RestaurantHero />
      <div className={'relative z-[1]'}>
        <header className={'mb-8 flex items-center justify-between gap-4 pt-3 text-white'}>
          <div>
            <Logo className={'flex h-[23px]'} />
          </div>
          <div>
            <MBBurgerIcon />
          </div>
        </header>
        <div className={'mb-5 line-clamp-1 px-1 text-2xl font-bold text-white'}>{name || t('restaurant_name')}</div>
        <div className={'mt-[65px] rounded bg-white px-2 pb-2'}>
          <div className={'m-auto mb-4 mt-[-45px] inline-block h-[90px] w-[90px]'}>
            <RestaurantLogo src={logoSrc} />
          </div>
          <div className={'text-lg font-bold leading-[1.2] text-indigo-40'}>
            {secondaryTitle || t('restaurant_subtitle')}
          </div>
          <div className={'leading-[1.5] text-grey-30'}>
            <p>{description || t('restaurant_description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantPreview;
