import paymentHeroImg from '@assets/img/bg-header-auth-form.jpg';
import MBHandsetIcon from '@base/components/icons/MBHandsetIcon';
import MBLetterIcon from '@base/components/icons/MBLetterIcon';
import { ClientOrder } from '@base/types/ClientOrder';
import { regExPhone } from '@base/utils/constants/regexp';
import ClientPaymentAuthSection from '@components/Client/ClientPayment/ClientPaymentAuthSection';
import ClientPaymentSummery from '@components/Client/ClientPayment/ClientPaymentSummery';
import useClientPayment from '@components/Client/ClientPayment/useClientPayment';
import MessageErrorDishQuantity from '@components/MessageErrorDishQuantity';
import ServicesPayment from '@components/ServicesPayment';
import { Button, Card, Col, Form, Input, Row } from 'antd';

const ClientPayment = () => {
  const {
    t,
    isAuth,
    iframeOrdersLink,
    restLogo,
    restName,
    orderData,
    order,
    cart_total_price,
    all_products,
    isLoading,
    isIframeOrdersLoading,
    isIframeOrdersError,
    onIframeOrdersLoad,
    onFinish,
    isPaymentCreditCard,
    isOrderQuantityError,
    iframePaymentUrl,
  } = useClientPayment();

  const renderCreditCardForm = () => (
    <>
      <div className={'text-xl font-bold leading-[120%]'}>{t('payment_by_credit_card')}</div>
      <p>{t('payment_on_website_secured_by_pci')}</p>
      <div className={'mx-auto'}>
        {/* TODO: change height of this iframe to 100% + make sure it will fit the container */}
        {iframePaymentUrl && <iframe src={iframePaymentUrl} width={'100%'} height={'445px'} frameBorder={0} />}
      </div>
      {/* TODO: remove this if it will be unnecessary */}
      {/* <Form
        name="guest-payment"
        layout="vertical"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="agreed"
          valuePropName="checked"
          className={'mb-0 mt-5 pb-4'}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
            },
          ]}
        >
          <Checkbox className={'text-xs leading-[1.5]'}>
            <div className={'text-sm'}>{t('confirm_reading_and_agreeing_to_terms')}</div>
          </Checkbox>
        </Form.Item>

        <Button htmlType="submit" type={'primary'} className={'h-[52px] w-full justify-center'} loading={isLoading}>
          {t('pay')}
        </Button>
      </Form> */}
    </>
  );

  const renderPaymentSection = () => (
    <div className={'flex flex-col gap-4'}>
      {!isAuth && !isPaymentCreditCard && (
        <>
          <ClientPaymentAuthSection order={order as ClientOrder} />
          <div className={'text-center font-bold'}>{t('or')}</div>
        </>
      )}

      {isPaymentCreditCard ? (
        <>
          <Card>{renderCreditCardForm()}</Card>
          <Card>
            <ServicesPayment />
          </Card>
        </>
      ) : (
        <Card>
          <Form
            name="guest-payment"
            layout="vertical"
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
            className={'mb-4'}
          >
            {!isAuth && (
              <Row gutter={16} className={'mb-3'}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('first_name')}
                    name="first_name"
                    rules={[{ required: true, message: t('required_field') }]}
                  >
                    <Input placeholder={t('first_name_placeholder')} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('last_name')}
                    name="last_name"
                    rules={[{ required: true, message: t('required_field') }]}
                  >
                    <Input placeholder={t('last_name_placeholder')} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('email')}
                    name="email"
                    rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
                  >
                    <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('phone')}
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        pattern: regExPhone,
                        message: t('input_errors.phone_only_numbers'),
                      },
                      {
                        max: 10,
                        min: 10,
                        message: t('input_errors.only_n_symbols', { n: 10 }),
                      },
                    ]}
                  >
                    <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Button htmlType="submit" type={'primary'} className={'h-[52px] w-full justify-center'} loading={isLoading}>
              {t('pay')}
            </Button>
          </Form>

          <ServicesPayment />
        </Card>
      )}
    </div>
  );

  return (
    <>
      {isIframeOrdersError ? (
        <p className={'m-2 text-center text-red'}>{t('error')}</p>
      ) : isIframeOrdersLoading ? (
        <p className={'m-2 text-center'}>{t('loading')}</p>
      ) : (
        orderData && (
          <div>
            <img className={'h-[160px] w-full object-cover'} src={paymentHeroImg} alt={''} />
            <div className={'mx-auto mt-[-100px] flex max-w-2xl flex-col gap-4 px-4 pb-10'}>
              <ClientPaymentSummery
                restLogo={restLogo}
                restName={restName}
                products={all_products}
                cart_total_price={cart_total_price}
              />

              {isOrderQuantityError ? <MessageErrorDishQuantity /> : renderPaymentSection()}
            </div>
          </div>
        )
      )}

      {!orderData && (
        <iframe
          src={iframeOrdersLink}
          width="0"
          height="0"
          className={'absolute left-[-1000px] top-[-1000px] opacity-0'}
          onLoad={onIframeOrdersLoad}
        />
      )}
    </>
  );
};

export default ClientPayment;
