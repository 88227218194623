import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import useCheckRole from '@base/hooks/useCheckRole';
import { useAppSelector } from '@base/redux/store';
import { ROUTES } from '@base/utils/constants/routes';
import useDashboardInventory from '@components/Dashboard/DashboardInventory/useDashboardInventory';
import InventoryDishList from '@components/Inventory/InventoryDishList';
import StatisticsBoard from '@components/UI/StatisticsBoard';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const DashboardInventory = () => {
  const { t } = useTranslation();
  const { dishList, isLoading, isError } = useDashboardInventory();
  const { isInventory } = useAvailableFeatures();
  const isPaymentMethod = useAppSelector((state) => state.restaurantSettingsState.payment_method);
  const { isManager } = useCheckRole();

  return (
    <StatisticsBoard
      title={t('dishes_that_will_run_out_soon')}
      className={'min-h-[341px]'}
      isUnavailable={!isInventory}
    >
      {isError ? (
        <p>{t('error')}</p>
      ) : isLoading ? (
        <p>{t('loading')}</p>
      ) : !isPaymentMethod && isInventory ? (
        <BluredUnavailableFeature isWithoutBlure={true}>
          <div className="z-10 text-center">
            {t('no_information')}. {t('you_must_add_clearing_system')}{' '}
            {isManager && (
              <Link
                className={'text-indigo-30 underline underline-offset-4 hover:text-indigo-30'}
                to={ROUTES.account_settings}
              >
                {t('in_account_settings')}
              </Link>
            )}
          </div>
        </BluredUnavailableFeature>
      ) : dishList.length > 0 ? (
        <InventoryDishList dishList={dishList} limit={5} isDashboard isUnavailable={!isInventory} />
      ) : !isInventory ? (
        <BluredUnavailableFeature isWithoutBlure={true} />
      ) : (
        <p>{t('list_is_empty')}</p>
      )}
    </StatisticsBoard>
  );
};

export default memo(DashboardInventory);
