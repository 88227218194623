import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { FC } from 'react';

const NoMatchPage: FC = () => {
  useSetTitlePage('404');

  return <div className={'w-full pt-6 text-center text-2xl'}>Error 404</div>;
};

export default NoMatchPage;
