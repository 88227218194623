import { useGetSubscriptionPackagesQuery } from '@api/subscriptionApi';
import { useTranslation } from 'react-i18next';

const usePackages = () => {
  const { t } = useTranslation();
  const { data: packages, isLoading, isError } = useGetSubscriptionPackagesQuery();

  return {
    t,
    packages,
    isLoading,
    isError,
  };
};

export default usePackages;
