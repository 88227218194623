import { getRestaurantLinkFromCookies } from '@helpers/getRestaurantLinkFromCookies';
import { Alert, Button, Card } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MessageErrorDishQuantity: FC = () => {
  const { t } = useTranslation();
  const restLink = getRestaurantLinkFromCookies();

  return (
    <Card>
      <Alert message={t('server_errors.dish_quantity_changed')} type="error" className={'mb-4 p-2 text-sm'} />
      <Link to={restLink as string}>
        <Button type={'primary'} className={'mb-4 h-[52px] w-full justify-center'}>
          {t('check_my_cart')}
        </Button>
      </Link>
    </Card>
  );
};

export default MessageErrorDishQuantity;
