import { Allergy, DishType } from '@api/models/Dish';
import { StoreClientTable } from '@base/types/ClientTable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GuestOrder {
  id: number;
  order_date: number;
}

interface InitialState {
  table: StoreClientTable;
  guestOrders: GuestOrder[] | [];
  allergies: Allergy[] | [];
  dishTypes: DishType[] | [];
  isFilteredMenu: boolean;
  calledServicesIds: number[];
}

const initialState: InitialState = {
  table: {
    selected: false,
    table_id: null,
    table_take_away_id: null,
    table_name: '',
    waiter: {
      id: 0,
      first_name: '',
      last_name: '',
      restaurant_id: 0,
    },
  },
  guestOrders: [],
  allergies: [],
  dishTypes: [],
  isFilteredMenu: false,
  calledServicesIds: [],
};

export const cartSlice = createSlice({
  initialState,
  name: 'client',
  reducers: {
    resetClientSlice: () => initialState,
    resetClientTable: (state) => {
      state.table = initialState.table;
    },
    resetGuestOrders: (state) => {
      state.guestOrders = initialState.guestOrders;
    },
    setClientTable: (state, action: PayloadAction<StoreClientTable>) => {
      state.table = action.payload;
    },
    setGuestOrders: (state, action: PayloadAction<GuestOrder[] | []>) => {
      state.guestOrders = action.payload;
    },
    setClientAllergies: (state, action: PayloadAction<Allergy[] | []>) => {
      state.allergies = action.payload;
    },
    setClientDishTypes: (state, action: PayloadAction<DishType[] | []>) => {
      state.dishTypes = action.payload;
    },
    setIsClientFilteredMenu: (state, action: PayloadAction<boolean>) => {
      state.isFilteredMenu = action.payload;
    },
    setCalledServicesIds: (state, action: PayloadAction<number>) => {
      state.calledServicesIds = [...state.calledServicesIds, action.payload];
    },
    removeCalledServicesIds: (state, action: PayloadAction<number>) => {
      state.calledServicesIds = state.calledServicesIds.filter((id) => id !== action.payload);
    },
    resetCalledServicesIds: (state) => {
      state.calledServicesIds = [];
    },
  },
});

export default cartSlice.reducer;

export const {
  resetClientSlice,
  resetClientTable,
  setClientTable,
  setGuestOrders,
  setClientAllergies,
  setClientDishTypes,
  setIsClientFilteredMenu,
  setCalledServicesIds,
  removeCalledServicesIds,
  resetCalledServicesIds,
} = cartSlice.actions;
