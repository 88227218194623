import { Select } from 'antd';
import { useLanguageSwitcher } from './useLanguageSwitcher';

const LanguageSwitcher = (): JSX.Element => {
  const { language, languages, changeLanguage } = useLanguageSwitcher();

  return <Select defaultValue={language} style={{ width: '100%' }} onChange={changeLanguage} options={languages} />;
};

export default LanguageSwitcher;
