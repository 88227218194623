import useAccountRemoving from '@components/AccountSettings/AccountRemoving/useAccountRemoving';
import ConfirmModalContent from '@components/ConfirmModalContent';
import { Button } from 'antd';

const AccountRemoving = () => {
  const { t, modal, contextHolder, showModalConfirm, onRemoveAccount } = useAccountRemoving();

  const handleRemoveAccount = () => {
    showModalConfirm({
      modal,
      title: t('deleting'),
      content: <ConfirmModalContent subtitle={t('removing_account_massage')} />,
      okCallback: () => onRemoveAccount(),
    });
  };

  return (
    <>
      <Button type={'link'} className={'w-max text-red underline underline-offset-2'} onClick={handleRemoveAccount}>
        Remove account
      </Button>
      {contextHolder}
    </>
  );
};

export default AccountRemoving;
