import { Layout } from '@base/redux/api/models/Restaurant';
import { setRestLayout } from '@base/redux/features/restaurantSettingsSlice';
import { useDispatch } from 'react-redux';

export const useRestaurantLayout = () => {
  const dispatch = useDispatch();

  const setRestaurantLayout = (theme: Layout | null) => {
    let currentTheme = document.querySelector('link[data-type="layout"]');
    
    //create link tag with theme styles if there are no link in head
    if (!currentTheme) {
      currentTheme = document.createElement('link');
      currentTheme.setAttribute('data-type', 'layout');
      currentTheme.setAttribute('rel', 'stylesheet');
      document.head.appendChild(currentTheme);
    }

    //if there are no theme || theme name then set default (theme_1)
    if (!theme || (theme.name && theme.name.trim().length < 0)) {
      currentTheme?.setAttribute('data-theme-name', 'theme_1');
      currentTheme?.setAttribute('href', './styles/theme_1.css');
      dispatch(setRestLayout(null));
    } else {
      currentTheme?.setAttribute('data-theme-name', theme.name);
      currentTheme?.setAttribute('href', `./styles/${theme.name}.css`);
      dispatch(setRestLayout(theme));
    }
  };

  return { setRestaurantLayout };
};
