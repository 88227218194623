import { useRegisterUserMutation } from '@api/authApi';
import { ROUTES } from '@constants/routes';
import useAuth from '@hooks/useAuth';
import { useForm } from 'antd/es/form/Form';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useSimpleRegistrationForm = () => {
  const { t } = useTranslation();
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const [form] = useForm();
  const [registerUser, { isLoading, isSuccess }] = useRegisterUserMutation();

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const onFinish = async (values: any) => {
    await registerUser(values);
  };

  // TODO: move this functional to Routers (protecting and redirecting)
  useLayoutEffect(() => {
    if (isAuth) {
      navigate(ROUTES.dashboard);
    }
  }, []);

  // TODO: remove this if it will be unnecessary
  // useEffect(() => {
  //   const packagePlan: PackagePlansType | null = getQueryParam(PACKAGE_PLAN_GET_PARAMETER) as PackagePlansType | null;

  //   if (packagePlan && PACKAGE_PLANS.includes(packagePlan)) {
  //     form.setFieldValue('package_plan', packagePlan);
  //   } else {
  //     form.setFieldValue('package_plan', DEFAULT_PACKAGE_PLAN);
  //   }
  // }, []);

  return {
    t,
    form,
    onFinish,
    onFinishFailed,
    isLoading,
    isSuccess,
  };
};

export default useSimpleRegistrationForm;
