import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';
import { Waiter } from './models/Waiter';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getWaiters: builder.query<Waiter[], void>({
      query: () => addTenantToEndpoint(`/restaurant-waiters`),
      transformResponse: (response: { data: Waiter[] }) => response.data,
    }),
  }),
});

export const { useGetWaitersQuery } = extendedApiSlice;
