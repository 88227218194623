import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

interface SimpleSectionProps {
  title?: string;
  children: ReactNode;
}

const SimpleSection: ForwardRefRenderFunction<HTMLDivElement, SimpleSectionProps> = ({ title, children }, ref) => {
  return (
    <section>
      {title && (
        <div>
          <h2 ref={ref} className={'inline-block text-[26px] font-bold text-indigo-40'}>
            {title}
          </h2>
        </div>
      )}
      {children}
    </section>
  );
};

export default forwardRef(SimpleSection);
