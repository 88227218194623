import {
  useCreateDishMutation,
  useUpdateDishBySupervisorMutation,
  useUpdateDishImageMutation,
  useUpdateDishMutation,
} from '@api/dishApi';
import { setCollapsedActiveKeys } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';
import useCheckRole from '@hooks/useCheckRole';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useMenuDishModal = (isOpen: boolean) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(isOpen);

  const { dishParentCategoryId, collapsedActiveKeys } = useAppSelector((state) => state.menuSlice);
  const [createDish, { isSuccess: isSuccessCreate, isLoading: isLoadingCreate }] = useCreateDishMutation();
  const [updateDish, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] =
    useUpdateDishMutation();
  const [updateDishImage, { isSuccess: isSuccessUpdateImage, isError: isErrorUpdateImage }] =
    useUpdateDishImageMutation();

  const [
    updateDishSupervisor,
    {
      isLoading: isLoadingUpdateSupervisor,
      isSuccess: isSuccessUpdateSupervisor,
      isError: isErrorUpdateSupervisor,
      error: errorUpdateSupervisor,
    },
  ] = useUpdateDishBySupervisorMutation();
  const { isManager, isSupervisor } = useCheckRole();

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate || isSuccessUpdateSupervisor) {
      message.success(t('updated'));

      if (dishParentCategoryId) {
        dispatch(setCollapsedActiveKeys([...collapsedActiveKeys, dishParentCategoryId.toString()]));
      }

      setIsOpenModal(false);
    }
  }, [isSuccessCreate, isSuccessUpdate, isSuccessUpdateImage, isSuccessUpdateSupervisor]);

  useEffect(() => {
    if (isErrorUpdate || isErrorUpdateSupervisor) {
      if (errorUpdateSupervisor && 'status' in errorUpdateSupervisor && errorUpdateSupervisor.status === 403) {
        message.error(`${t('server_errors.error_data_not_curren')}. ${t('refresh_page')}`);
      } else {
        message.error(t('error_saving_data'));
      }
    }

    if (isErrorUpdateImage) {
      message.error(t('error_uploading_image_try_again'));
    }
  }, [isErrorUpdate, isErrorUpdateSupervisor, isErrorUpdateImage]);

  const update = async (id: number, data: any, image: any) => {
    if (isManager) {
      updateDish({ id, data });

      if (image) {
        updateDishImage({ id, image });
      }
    }

    if (isSupervisor) {
      updateDishSupervisor({ id, data });
    }
  };

  const create = async (data: any) => {
    await createDish(data);
  };

  return {
    isOpenModal,
    setIsOpenModal,
    update,
    create,
    isLoading: isLoadingCreate || isLoadingUpdate || isLoadingUpdateSupervisor,
  };
};

export default useMenuDishModal;
