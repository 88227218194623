import api from '@api/api';
import { authApi } from '@api/authApi';
import { authInterceptor } from '@api/authInterceptor';
import apiCentral from '@api/central-api';
import { publicTenantApi } from '@api/publicTenantApi';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './features/cartSlice';
import clientReducer from './features/clientSlice';
import commonReducer from './features/commonSlice';
import dashboardReducer from './features/dashboardSlice';
import menuReducer from './features/menuSlice';
import qrCodeReducer from './features/QRCodeSlice';
import restaurantSettingsReducer from './features/restaurantSettingsSlice';
import restaurantReducer from './features/restaurantSlice';
import supervisorMobileMenuSettingsReducer from './features/supervisorMobileMenuSettingsSlice';
import userReducer from './features/userSlice';
import waiterReducer from './features/waiterSlice';
import workerReducer from './features/workerSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [api.reducerPath, apiCentral.reducerPath, authApi.reducerPath, publicTenantApi.reducerPath],
};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [apiCentral.reducerPath]: apiCentral.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [publicTenantApi.reducerPath]: publicTenantApi.reducer,
  userState: userReducer,
  restaurantSettingsState: restaurantSettingsReducer,
  supervisorMobileMenuSettingsSlice: supervisorMobileMenuSettingsReducer,
  menuSlice: menuReducer,
  workerSlice: workerReducer,
  commonSlice: commonReducer,
  qrCodeSlice: qrCodeReducer,
  cartSlice: cartReducer,
  clientSlice: clientReducer,
  waiterSlice: waiterReducer,
  restaurantState: restaurantReducer,
  dashboardState: dashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([api.middleware, apiCentral.middleware, authApi.middleware, publicTenantApi.middleware, authInterceptor]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
