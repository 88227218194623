import { FC, ReactNode } from 'react';

interface RoundedIconProps {
  children: ReactNode;
}

const RoundedIcon: FC<RoundedIconProps> = ({ children }) => {
  return (
    <div className={'flex h-[inherit] w-[inherit] items-center justify-center rounded-lg bg-indigo-10'}>{children}</div>
  );
};

export default RoundedIcon;
