import SimpleSection from '@components/UI/SimpleSection';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import TransactionsTable from './TransactionsTable';
import useTransactions from './useTransactions';

const Transactions = () => {
  const { t } = useTranslation();
  const { transactions, isErrorTransactions, isLoadingTransactions, pagination, setPagePagination } = useTransactions();

  return (
    <>
      {isErrorTransactions ? (
        <span>{t('error')}</span>
      ) : isLoadingTransactions ? (
        <div>{t('loading')}</div>
      ) : (
        <SimpleSection title={t('transactions')}>
          <Card>
            <TransactionsTable
              transactions={transactions}
              pagination={pagination}
              setPagePagination={setPagePagination}
            />
          </Card>
        </SimpleSection>
      )}
    </>
  );
};

export default Transactions;
