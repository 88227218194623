import RestaurantExistence from '@base/hoc/RestaurantExistence';
import Workers from '@components/Workers';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const WorkersPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.users'));

  return (
    <RestaurantExistence>
      <Workers />
    </RestaurantExistence>
  );
};

export default WorkersPage;
