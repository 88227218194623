import { SVGProps } from 'react';
const SuccessfulPaymentGuy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={250} height={200} fill="none" {...props}>
    <path
      fill="#205FAE"
      d="M112.904 199.25h5.393v-67.27h-5.393v67.27ZM122.153 129.48h-13.097a2.487 2.487 0 0 0 2.479 2.494h8.145a2.488 2.488 0 0 0 2.48-2.494h-.007Z"
    />
    <path
      fill="#DD6B20"
      d="m127.524 150.445-4.834 48.806h.888l8.612-48.806h-4.666ZM142.64 150.445l4.833 48.806h-.887l-8.612-48.806h4.666ZM116.25 150.445l-10.449 48.806h.88l14.235-48.806h-4.666ZM153.921 150.445l10.45 48.806h-.888l-14.228-48.806h4.666Z"
    />
    <path
      fill="#FBD38D"
      d="M160.892 146.265c.049 4.972-14.927 7.494-38.187 7.494-24.63 0-27.535-19.834-27.535-35.412 0-13.394 1.697-22.117 8.752-24.273 1.139-.365 2.41-.54 3.856-.54 4.771 0 8.661 3.904 8.661 8.708v11.006c0 5.9 6.902 21.906 12.755 22.243 19.411 1.068 28.296 5.134 30.881 8.625.552.751.824 1.482.824 2.149h-.007Z"
    />
    <path
      fill="#DD6B20"
      d="M160.067 144.117c-30.769 10.605-49.971 5.07-55.3-6.778-4.443-9.889-4.855-34.808-.845-43.257 1.138-.365 2.409-.541 3.855-.541 4.771 0 8.662 3.905 8.662 8.709v11.006c0 5.899 6.901 21.906 12.754 22.243 19.412 1.067 28.297 5.134 30.881 8.625l-.007-.007Z"
    />
    <path fill="#4286FB" d="M157.316 129.486h17.742v-4.853h-17.742v4.853Z" />
    <path
      fill="#39C9DF"
      d="M147.236 112.826v.042c-.21 3.646-.692 7.607-1.656 11.758a53.931 53.931 0 0 1-1.068 4.108c-.021.106-.063.211-.105.337a2.823 2.823 0 0 1-.105.401 69.46 69.46 0 0 1-1.131 3.329v.021c-.021.042-.042.063-.063.105v.021h-.315l-6.621-.084-.21-.021h-1.153l-9.786-.148h-.607l-6.098-.084h-.021l-3.563-.063-1.823-.021h-.649a42.254 42.254 0 0 1-.063-.548c-.021-.126-.021-.274-.042-.4l-.063-.443c-.042-.548-.105-1.095-.168-1.664a758.782 758.782 0 0 1-.44-4.846 156.86 156.86 0 0 1-.398-5.689c-.021-.148-.042-.274-.042-.422-.335-5.436-.419-11.104.021-16.414l.042-.421c.168-1.917.398-3.772.734-5.562.083-.485.167-.949.272-1.412.21-.948.419-1.875.692-2.76 0-.064.021-.127.042-.19.733-2.486 1.676-4.72 2.933-6.616a.503.503 0 0 1 .126-.232c.293-.442.587-.843.901-1.243.272-.358.587-.716.922-1.032a11.2 11.2 0 0 1 .88-.843c.189-.148.398-.295.608-.443 4.233-3.097 10.352-2.95 15.695-.168.503.253 1.006.526 1.509.843.377.231.754.484 1.111.758.335.232.67.464.985.759a.75.75 0 0 1 .251.21c3.08 2.529 5.574 6.132 6.769 10.535.062.148.083.274.125.422a51.423 51.423 0 0 1 1.027 5.373c.063.505.147 1.053.21 1.601a69.45 69.45 0 0 1 .335 11.146Z"
    />
    <path
      fill="#1095A9"
      d="m112.164 131.58-.062-.45c16.058-9.39 20.089-35.328 17.776-46.65l.399-.084c2.333 11.42-1.886 37.695-18.113 47.177v.007ZM111.067 118.966l-.028-.456c9.828-8.738 13.572-28.354 12.894-36.473l.406-.035c.579 6.911-2.501 27.391-13.272 36.964ZM124.87 132.85l-.475-.14c10.309-9.517 16.421-30.742 13.949-49.15l.405-.056c2.486 18.528-3.479 39.738-13.879 49.339v.007ZM146.901 101.681a61.72 61.72 0 0 1-1.362 8.007 4.035 4.035 0 0 1-.105.421 69.403 69.403 0 0 1-3.814 11.526c-.084.168-.147.337-.231.506a53.806 53.806 0 0 1-1.152 2.486 56.256 56.256 0 0 1-2.661 4.846 38.152 38.152 0 0 1-1.551 2.36c-.084.147-.189.274-.293.421-.105.169-.231.337-.357.506a.29.29 0 0 1-.063.084c-.021.042-.062.085-.083.127l-.461-.064s.021-.042.041-.063a.29.29 0 0 0 .063-.084c.231-.316.461-.632.692-.969.105-.148.188-.274.293-.422.419-.611.817-1.243 1.216-1.896a61.662 61.662 0 0 0 2.64-4.846 51.882 51.882 0 0 0 1.404-3.097c.084-.169.168-.338.23-.506a75.296 75.296 0 0 0 3.772-11.441c.042-.148.063-.295.105-.422.734-3.097 1.237-6.173 1.467-9.081.063.506.147 1.054.21 1.601Z"
    />
    <path
      fill="#1095A9"
      d="M135.983 132.922c-4.694-14.04-16.058-26.113-24.929-30.812l.049-.428c8.94 4.733 20.543 16.961 25.271 31.106l-.384.134h-.007ZM144.225 129.142c-8.04-22.686-21.039-31.802-31.46-37.21l.188-.366c10.492 5.444 23.575 14.616 31.663 37.435l-.384.141h-.007ZM147.18 113.332c-8.48-16.793-21.968-26.232-31.433-28.037l.077-.4c9.562 1.826 22.876 11.04 31.419 27.96l-.056.484-.007-.007Z"
    />
    <path
      fill="#3446C7"
      d="M137.953 147.278h21.087s7.761 15.023 6.343 37.982l3.01.822s7.321-21.927 8.781-40.167c.503-6.258-4.513-11.708-11.232-12.375-17.952-1.77-41.729.176-46.542 1.517 0 0 0 .021.007.056 1.614 8.815 8.92 12.165 18.546 12.165Z"
    />
    <path fill="#EDC198" d="m165.376 185.26-.398 2.388 2.661.449.754-2.015-3.017-.822Z" />
    <path
      fill="#205FAE"
      d="M164.978 187.646c-.783 1.602-1.879 4.348-2.494 5.935-.265.682.105 1.433.845 1.679 2.578.836 8.669 2.648 15.891 3.617 1.055.14 1.488-1.215.524-1.637-6.74-2.942-11.434-7.402-12.105-9.13l-2.661-.449v-.015Z"
    />
    <path
      fill="#4286FB"
      d="M128.691 147.276h14.738s3.472 10.739-5.993 32.673l2.654 1.875s12.957-16.533 20.802-33.839c2.689-5.941-2.746-15.156-9.766-15.156-17.965 0-25.299-.288-38.864-.288 0 0 .139.78.398 2.023 1.662 8.063 7.201 12.712 16.038 12.712h-.007Z"
    />
    <path fill="#EDC198" d="m137.436 179.949-1.627 2.641 2.437 1.391 1.844-2.157-2.654-1.875Z" />
    <path
      fill="#205FAE"
      d="M135.808 182.591c-1.327 1.285-3.367 3.582-4.526 4.909-.503.576-.398 1.44.237 1.939 2.221 1.735 7.537 5.668 14.257 9.186.985.513 1.886-.66 1.089-1.418-5.546-5.275-8.577-11.301-8.619-13.232l-2.438-1.391v.007ZM190.082 199.25h5.393v-67.27h-5.393v67.27ZM199.33 129.48h-13.097a2.488 2.488 0 0 0 2.48 2.494h8.145a2.487 2.487 0 0 0 2.479-2.494h-.007ZM88.897 199.25h5.392v-67.27h-5.392v67.27ZM98.145 129.48H85.048a2.487 2.487 0 0 0 2.48 2.494h8.144c1.37 0 2.48-1.117 2.48-2.494h-.007ZM199.33 124.627H85.041v4.853H199.33v-4.853ZM166.074 199.25h5.393v-67.27h-5.393v67.27ZM175.323 129.48h-13.097a2.487 2.487 0 0 0 2.479 2.494h8.145a2.488 2.488 0 0 0 2.48-2.494h-.007Z"
    />
    <path fill="#fff" d="m187.337 98.135-12.468 26.359h19.433l12.468-26.36h-19.433Z" />
    <path
      fill="#205FAE"
      d="m187.26 97.994-.062.148-.063.126-12.343 26.085-.042.084-.042.063-.062.127h19.739l12.51-26.443.105-.19h-19.74Zm-12.174 26.359 12.342-26.085h19.132l-12.342 26.085h-19.132Z"
    />
    <path
      fill="#fff"
      d="M194.266 111.788c-.915 2.879-3.108 5.211-4.91 5.211s-2.515-2.332-1.6-5.211c.915-2.88 3.108-5.212 4.911-5.212 1.802 0 2.514 2.332 1.599 5.212Z"
    />
    <path
      fill="#205FAE"
      d="M194.218 107.223a1.885 1.885 0 0 0-1.551-.78c-1.844 0-4.107 2.381-5.029 5.31-.608 1.896-.545 3.603.188 4.593.377.527.901.801 1.53.801 1.865 0 4.128-2.381 5.05-5.31.587-1.896.524-3.624-.188-4.614Zm-4.862 9.65c-.545 0-.985-.253-1.32-.695-.65-.885-.713-2.571-.147-4.341.901-2.823 3.039-5.12 4.778-5.12a1.56 1.56 0 0 1 1.32.675c.671.906.713 2.57.147 4.361-.88 2.823-3.039 5.12-4.778 5.12Z"
    />
    <path
      fill="#fff"
      d="m186.024 98.135-11.867 25.087h-23.533c0 .703.566 1.272 1.264 1.272h22.981l12.468-26.36h-1.32.007Z"
    />
    <path
      fill="#205FAE"
      d="m185.94 97.994-11.86 25.095h-23.596v.126c0 .78.629 1.412 1.404 1.412h23.072l.063-.127.063-.147 12.342-26.085.042-.084.021-.042.063-.148h-1.614Zm-34.052 26.359a1.132 1.132 0 0 1-1.11-.99h23.469l11.861-25.095h1.027l-12.343 26.085h-22.904Z"
    />
    <path
      fill="#1095A9"
      d="M136.423 82.03c0 2.654-.349 6.166-2.047 8.245-1.963-2.423-8.053-7.67-17.581-6.595.286-.372.594-.717.922-1.047 4.582-4.748 12.391-4.565 18.706-.597v-.007Z"
    />
    <path
      fill="#EDC198"
      d="M132.4 51.977c-.077 2.472 2.158 8.386 2.158 8.386l-2.179.288s2.961 6.728-2.382 6.96c-.852 3.385 4.917 13.548 4.917 13.548s0 2.928-.796 6.026c-5.225-3.933-11.127-6.532-14.955-5.823 1.467-4.923 2.012-10.71 2.012-10.71l-2.012-16.4 13.237-2.268v-.007Z"
    />
    <path
      fill="#DD6B20"
      d="M132.372 60.651c-4.1.765-5.532-1.264-5.952-3.189-.419-1.924-.139-3.56-.139-3.56s3.171-1.721 7.627-1.32c4.45.4 4.331-5.12 2.969-8.015-1.362-2.893-6.035-1.643-6.035-1.643s.608-4.038-4.065-3.09c-4.673.94-2.033 4.171-2.033 4.171s-7.299-2.205-6.3 3.498c-4.415-2.802-8.313 3.154-4.953 7.564 3.36 4.411 4.051 4.488 2.717 7.263-1.334 2.767 3.563 7.381 4.96 8.315.6-3.174.538-5.562 2.032-7.908a1.789 1.789 0 0 0-.3-2.304c-1.439-1.292-1.229-3.764.754-4.396.846 3.708 3.891 13.414 12.28 15.556-1.683-3.223-1.704-8.512-2.864-11.033l-.705.091h.007Z"
    />
    <path fill="#EDC198" d="m133.203 62.828-2.368.225.503 1.075 2.102-.246-.237-1.054Z" />
    <path
      fill="#205FAE"
      d="M129.71 56.134c.203.906.538 1.601.748 1.56.209-.05.216-.823.014-1.729-.203-.906-.538-1.601-.748-1.559-.209.05-.216.822-.014 1.728Z"
    />
    <path
      fill="#fff"
      d="M135.92 50.241s.007 0 .014-.007c1.369-1.011 1.181-3.237.203-4.797-.873-1.397-2.117-1.896-3.241-1.292a.17.17 0 0 0-.063.225c.042.077.14.112.216.07 1.146-.618 2.208.204 2.815 1.173.881 1.405 1.083 3.47-.118 4.355a.167.167 0 0 0-.035.231c.049.07.139.084.209.042ZM120.386 68.58s.035-.022.049-.043a.168.168 0 0 0-.028-.232c-1.74-1.411-3.206-3.862-2.564-5.035a.171.171 0 0 0-.063-.225.169.169 0 0 0-.223.063c-.824 1.496 1.006 4.123 2.64 5.45.056.042.126.05.182.014l.007.007Z"
    />
    <path
      fill="#205FAE"
      d="M136.025 41.673s.042-.035.056-.056a.167.167 0 0 0-.049-.224c-.929-.583-3.206-.106-4.617.716-.077.05-.105.147-.056.225a.169.169 0 0 0 .224.063c1.418-.829 3.541-1.187 4.275-.724.056.036.118.036.167 0ZM116.146 59.505a.16.16 0 0 0 .063-.21c-.44-.935-1.272-1.869-2.152-2.86-1.355-1.523-2.759-3.09-2.808-4.79a.164.164 0 0 0-.167-.16.165.165 0 0 0-.161.168c.056 1.819 1.495 3.434 2.892 5 .866.97 1.683 1.883 2.102 2.782.035.084.133.12.217.077h.014v-.007Z"
    />
    <path
      fill="#1095A9"
      d="M138.922 81.965c21.688-9.784 32.236-21.857 36.748-29.934l2.738 1.18s-4.421 22.531-31.321 40.841c-.901.618-2.081.646-3.031.106-1.949-1.124-5.127-3.512-6.873-7.846a3.379 3.379 0 0 1 1.732-4.347h.007Z"
    />
    <path
      fill="#EDC198"
      d="M181.811 38.518c.035-1.215-2.906-1.573-3.325-1.18 0 0-1.495 2.655-1.614 6.883-.37-1.826-1.264-2.978-1.508-3.175-.664-.54-1.3-.224-1.3-.224.063 2.163 2.787 8.063 1.614 11.209l2.738 1.18c3.199-5.837 3.346-12.846 3.395-14.693ZM70.166 46.352c.098.703.098 1.342.063 1.96-1.09-3.66-2.662-7.03-3.053-7.03-.635.006-4.156.94-4.205 2.219-.049 1.278 3.52 10.38 5.875 12.417l2.48-1.18c1.138-3.266.013-7.445.698-10.05.684-2.606-2.298-1.377-1.858 1.671v-.007Z"
    />
    <path
      fill="#1095A9"
      d="M114.702 82.037c-8.57-5.836-16.638-.533-43.377-27.307l-2.48 1.18s4.709 22.258 38.356 40.75c.88.485 1.955.59 2.891.225 2.431-.941 6.832-3.477 7.195-9.63.119-2.064-.88-4.052-2.585-5.218Z"
    />
    <path
      fill="#39C9DF"
      d="M207.175 22.694c-2.464 2.434-6.464 2.434-8.938 0l-11.413-11.287a6.202 6.202 0 0 1 0-8.831c2.464-2.435 6.464-2.435 8.938 0l11.413 11.276a6.214 6.214 0 0 1 0 8.842Z"
      opacity={0.3}
    />
    <path
      fill="#39C9DF"
      d="M186.824 22.693a6.206 6.206 0 0 1 0-8.835l11.413-11.282c2.463-2.435 6.464-2.435 8.938 0a6.206 6.206 0 0 1 0 8.836l-11.413 11.281c-2.474 2.435-6.474 2.435-8.938 0Z"
      opacity={0.3}
    />
    <g fill="#39C9DF" opacity={0.5}>
      <path
        d="M40.795 81.215c-3.42 3.38-8.975 3.38-12.41 0l-15.843-15.67a8.609 8.609 0 0 1 0-12.26c3.42-3.38 8.974-3.38 12.409 0L40.795 68.94a8.626 8.626 0 0 1 0 12.275Z"
        opacity={0.3}
      />
      <path
        d="M12.542 81.214a8.615 8.615 0 0 1 0-12.266l15.844-15.662c3.42-3.381 8.974-3.381 12.409 0a8.615 8.615 0 0 1 0 12.266L24.95 81.214c-3.435 3.381-8.99 3.381-12.41 0Z"
        opacity={0.3}
      />
    </g>
    <g fill="#39C9DF" opacity={0.5}>
      <path
        d="M240.93 76.532c-1.659 1.64-4.354 1.64-6.021 0l-7.688-7.603a4.178 4.178 0 0 1 0-5.95c1.66-1.639 4.355-1.639 6.021 0l7.688 7.597a4.185 4.185 0 0 1 0 5.956Z"
        opacity={0.3}
      />
      <path
        d="M227.221 76.532a4.181 4.181 0 0 1 0-5.952l7.688-7.6c1.66-1.64 4.355-1.64 6.021 0a4.18 4.18 0 0 1 0 5.952l-7.688 7.6c-1.666 1.64-4.361 1.64-6.021 0Z"
        opacity={0.3}
      />
    </g>
  </svg>
);
export default SuccessfulPaymentGuy;
