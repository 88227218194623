import { SVGProps } from 'react';
const MBWarningHexagonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3695_6877)">
      <path
        d="M5.24 1.33301H10.76L14.6667 5.23967V10.7597L10.76 14.6663H5.24L1.33333 10.7597V5.23967L5.24 1.33301Z"
        fill="#FED7D7"
        stroke="#F56565"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 10.667H8.00667" stroke="#F56565" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 5.33301V7.99967" stroke="#F56565" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_3695_6877">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MBWarningHexagonIcon;
