import DishOption from '@api/models/DishOption';
import DishOptionValue from '@api/models/DishOptionValue';
import UserAccess from '@base/hoc/UserAccess';
import useOptionValue from '@components/Menus/DishForm/DishFormOptions/OptionValue/useOptionValue';
import { OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import ApplyIconButton from '@components/UI/ApplyIconButton';
import EditIconButton from '@components/UI/EditIconButton';
import HorizontalSwitch from '@components/UI/HorizontalSwitch';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { CURRENCY } from '@constants/constants';
import { regExPrice } from '@constants/regexp';
import useCheckRole from '@hooks/useCheckRole';
import { Form, Input, Space } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OptionCategoryProps {
  temp_id?: string;
  id?: number;
  option_id?: number;
  fieldName: number;
  isEditMode: boolean;
  updateOptionsState: () => void;
  remove: (name: number) => void;
}

const OptionValue: FC<OptionCategoryProps> = ({
  temp_id,
  id,
  option_id,
  fieldName,
  isEditMode,
  updateOptionsState,
  remove,
}) => {
  const { t } = useTranslation();
  const { isManager } = useCheckRole();
  const form = useFormInstance();

  const {
    crateValue,
    createdValue,
    isLoadingCreate,
    isSuccessCreate,
    isLoading,
    updateValue,
    isLoadingUpdateValue,
    isSuccessUpdate,
    removeValue,
    isLoadingRemoveValue,
    updateAvailability,
  } = useOptionValue();

  const [isEditing, setIsEditing] = useState(false);

  const inputNameRef = useRef<any>(null);
  const inputPriceRef = useRef<any>(null);
  const inputAvailableRef = useRef<any>(null);

  const previousValues = useRef({
    value: '',
    additional_charge: 0,
    is_readily_available: false,
  });

  useEffect(() => {
    setIsEditing(isEditMode);
  }, [isEditMode]);

  // update DishOptionValue input value of form;
  useEffect(() => {
    if (isSuccessCreate && createdValue) {
      const option = form.getFieldValue(OPTIONS_NAME_FIELD);
      const optIndex = option.findIndex((opt: DishOption) => opt.id === option_id);

      const valueList = option[optIndex].values;

      option[optIndex].values = valueList.map((value: DishOptionValue) => {
        if (value.temp_id === temp_id) {
          return {
            ...value,
            id: createdValue.id,
          };
        }

        return value;
      });

      form.setFieldValue(OPTIONS_NAME_FIELD, option);
      updateOptionsState();
    }
  }, [isLoadingCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      setIsEditing(false);
    }
  }, [isLoadingUpdateValue]);

  function getOptionValue(): DishOptionValue {
    return {
      value: inputNameRef.current && inputNameRef.current.input.value.trim(),
      additional_charge: inputPriceRef.current && +inputPriceRef.current.input.value.trim(),
      is_readily_available:
        inputAvailableRef.current && inputAvailableRef.current.getAttribute('aria-checked') === 'true',
    };
  }

  const handleApply = async () => {
    const { value, additional_charge, is_readily_available } = getOptionValue();

    if (
      value !== previousValues.current.value ||
      additional_charge !== previousValues.current.additional_charge ||
      is_readily_available !== previousValues.current.is_readily_available
    ) {
      if (value && (additional_charge === 0 || additional_charge > 0)) {
        const dish_id: number = form.getFieldValue('id');

        const data = {
          option_id,
          value,
          additional_charge,
          is_readily_available,
        };

        // work on API
        if (dish_id) {
          if (id) {
            await updateValue({
              id,
              data,
            });
          } else {
            await crateValue(data);
          }
        } else {
          setIsEditing(false);
        }
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleRemove = async () => {
    const dish_id: number = form.getFieldValue('id');

    if (dish_id && id) {
      await removeValue(id);
      remove(fieldName);
    } else {
      remove(fieldName);
    }
  };

  const handleEdit = () => {
    previousValues.current = getOptionValue();

    setIsEditing(true);
    if (inputNameRef.current) {
      inputNameRef.current.focus();
    }
  };

  const handleToggleChange = async (checked: boolean) => {
    if (!isEditing && id) {
      await updateAvailability({
        id,
        is_readily_available: checked,
      });
    }
  };

  return (
    <Space direction={'horizontal'} size={16}>
      <UserAccess roles={['restaurant-manager']}>
        <RemoveIconButton className={'text-lg'} onClick={handleRemove} loading={isLoadingRemoveValue} />

        {isEditing ? (
          <ApplyIconButton className={'text-lg'} onClick={handleApply} loading={isLoading} />
        ) : (
          <EditIconButton className={'text-lg'} onClick={handleEdit} />
        )}
      </UserAccess>

      <Form.Item name={[fieldName, 'temp_id']} initialValue={temp_id} hidden>
        <Input />
      </Form.Item>

      <Form.Item
        noStyle
        name={[fieldName, 'value']}
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
          },
        ]}
      >
        <Input
          ref={inputNameRef}
          placeholder={t('option_value_placeholder')}
          className={'max-w-[180px] !bg-bg-input'}
          disabled={!isManager}
          readOnly={!isEditing}
          bordered={isEditing}
        />
      </Form.Item>

      <Form.Item
        noStyle
        name={[fieldName, 'additional_charge']}
        rules={[
          {
            validator: (_, additional_charge) => {
              return regExPrice.test(additional_charge) ? Promise.resolve() : Promise.reject();
            },
            message: '',
          },
        ]}
      >
        <Input
          ref={inputPriceRef}
          placeholder="0"
          suffix={CURRENCY}
          className={'max-w-[150px] !bg-bg-input'}
          disabled={!isManager}
          readOnly={!isEditing}
          bordered={isEditing}
        />
      </Form.Item>
      <Form.Item noStyle name={[fieldName, 'is_readily_available']} initialValue valuePropName={'checked'}>
        <HorizontalSwitch label={t('available')} ref={inputAvailableRef} onChange={handleToggleChange} />
      </Form.Item>
    </Space>
  );
};

export default OptionValue;
