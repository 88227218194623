import { useAppSelector } from '@base/redux/store';
import { DEFAULT_SUBSCRIPTION_PACKAGE_PLAN, TRIAL_SUBSCRIPTION_PACKAGE_PLAN } from '@base/utils/constants/constants';
import convertServerDateToClient from '@base/utils/helpers/convertServerDateToClient';
import SubscriptionMassage from '@components/UI/AmountCounter/SubscriptionMassage';
import useCheckRole from '@hooks/useCheckRole';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

const SubscriptionContext = createContext<any | null>(null);

export const SubscriptionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // TODO: Add logic here about when to show the subscription message
  const { isManager } = useCheckRole();
  const [isShow, setIsShow] = useState(false);
  const [packagePlan, setPackagePlan] = useState(DEFAULT_SUBSCRIPTION_PACKAGE_PLAN);
  const [remainingDays, setRemainingDays] = useState(0);
  const userPackagePayment = useAppSelector((state) => state.userState.user?.package_payment);

  // TODO: change calculation of remaining days (start use ended_time from PackagePayment model)
  useEffect(() => {
    if (userPackagePayment && isManager) {
      const { package: userPackage, created_at } = userPackagePayment;

      const { name: userPackageName } = userPackage;
      const userPackageCreatedAt = new Date(convertServerDateToClient(created_at)).getTime();

      //get date difference between today & date of package creation
      const dateDifference = Date.now() - userPackageCreatedAt;

      if (userPackageName === TRIAL_SUBSCRIPTION_PACKAGE_PLAN && dateDifference < 2592000000) {
        setPackagePlan(TRIAL_SUBSCRIPTION_PACKAGE_PLAN);
        setRemainingDays(Math.ceil(30 - dateDifference / (24 * 60 * 60 * 1000)));
        setIsShow(true);
      } else if (userPackageName === DEFAULT_SUBSCRIPTION_PACKAGE_PLAN) {
        setIsShow(true);
      }
    } else {
      setIsShow(false);
    }
  }, [userPackagePayment]);

  return (
    <SubscriptionContext.Provider value={{}}>
      {children}

      {isShow && (
        <SubscriptionMassage
          className={'absolute left-1/2 top-0'}
          styles={{ transform: 'translate(-50%, 0)' }}
          setIsShow={setIsShow}
          packagePlan={packagePlan}
          remainingDays={remainingDays}
        />
      )}
    </SubscriptionContext.Provider>
  );
};
