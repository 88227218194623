import { Menu } from '@api/models/Menu';
import UserAccess from '@base/hoc/UserAccess';
import { setDishParentCategoryId, setIsModalDishOpen } from '@base/redux/features/menuSlice';
import ConfirmModalContent from '@components/ConfirmModalContent';
import MBFolderPlusIcon from '@components/icons/MBFolderPlusIcon';
import MBPlusSquareIcon from '@components/icons/MBPlusSquareIcon';
import useMenuCategory from '@components/Menus/MenuCategory/useMenuCategory';
import MenuCreateCategoryForm from '@components/Menus/MenuCreateCategoryForm';
import ApplyIconButton from '@components/UI/ApplyIconButton';
import EditIconButton from '@components/UI/EditIconButton';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { Button, Form, Input, Space } from 'antd';
import { FC, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface MenuCategoryFieldType {
  id: string;
  name: string;
  is_sub_category: string;
  level: string;
  ancestor: string;
  position: string;
}

interface MenuNameProps {
  item: Menu;
  obStepRef1?: any;
  obStepRef2?: any;
}

const MenuCategory: FC<MenuNameProps> = ({ item, obStepRef1, obStepRef2 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    edit,
    update,
    remove,
    showModalConfirm,
    form,
    modal,
    contextHolder,
    isSubcategory,
    isEdit,
    title,
    setIsEdit,
  } = useMenuCategory(item);
  const [isShowForm, setIsShowFrom] = useState(false);
  const obActionsRef = useRef<any>(null);
  const obCreationsRef = useRef<any>(null);

  useImperativeHandle(obStepRef1, () => obActionsRef.current);
  useImperativeHandle(obStepRef2, () => obCreationsRef.current);

  const onFinish = async (value: any) => {
    await update(value);
  };

  const handleEdit = () => {
    edit();
  };

  const handleBlurInput = () => {
    const tm = setTimeout(() => {
      setIsEdit(false);
      clearTimeout(tm);
    }, 100);
  };

  const handleRemove = (id: number) => {
    showModalConfirm({
      modal,
      title: t('deleting'),
      content: (
        <ConfirmModalContent
          title={t('do_you_want_delete_category')}
          subtitle={t('all_child_elements_will_be_removed')}
        />
      ),
      okCallback: () => remove(id),
    });
  };

  const handleCreateDish = async (id: number) => {
    await dispatch(setDishParentCategoryId(id));
    await dispatch(setIsModalDishOpen(true));
  };

  return (
    <>
      <div className={`flex items-center ${isSubcategory ? 'mx-auto gap-1' : 'gap-6'}`}>
        {/* header */}
        <div
          className={`menu-category-header flex min-h-[40px] grow items-center justify-between gap-2 rounded ${
            isSubcategory ? 'mr-1 bg-grey-5 px-2' : ''
          }`}
        >
          <div className={'flex items-center'}>
            {/* buttons actions */}
            <UserAccess roles={['restaurant-manager']}>
              <div ref={obActionsRef} className={'flex items-center gap-[1.2em]'}>
                <Space className={'gap-1'}>
                  <RemoveIconButton onClick={() => handleRemove(item.id)} />

                  {isEdit ? <ApplyIconButton onClick={form.submit} /> : <EditIconButton onClick={handleEdit} />}
                </Space>
              </div>
            </UserAccess>

            {/* title */}
            {isEdit ? (
              <Form form={form} onFinish={onFinish}>
                <Form.Item<MenuCategoryFieldType> noStyle name={'name'} rules={[{ required: true }]}>
                  <Input size={isSubcategory ? 'small' : 'middle'} autoFocus onBlur={handleBlurInput} />
                </Form.Item>
              </Form>
            ) : (
              <div className={'px-4 text-[1em] font-bold'}>{title}</div>
            )}
          </div>

          {/* create actions */}
          <UserAccess roles={['restaurant-manager']}>
            <div className={'flex items-center gap-4 px-3'}>
              <Button
                ref={obCreationsRef}
                type={'link'}
                className={'h-auto p-0 text-sm font-normal text-grey-40 hover:text-indigo-40'}
                onClick={() => handleCreateDish(item.id)}
              >
                {t('new_dish')}
                <MBPlusSquareIcon className={'text-base text-indigo-30'} />
              </Button>

              {!isSubcategory && (
                <Button
                  type={'link'}
                  className={'h-auto p-0 text-sm font-normal text-grey-40 hover:text-indigo-40'}
                  onClick={() => setIsShowFrom(true)}
                >
                  {t('new_subcategory')}
                  <MBFolderPlusIcon className={'text-base text-indigo-30'} />
                </Button>
              )}
            </div>
          </UserAccess>
        </div>
      </div>

      {isShowForm && (
        <div className={'mb-4 pt-4'}>
          <MenuCreateCategoryForm setIsShowed={setIsShowFrom} parentCategory={item.id} />
        </div>
      )}
      {contextHolder}
    </>
  );
};

export default MenuCategory;
