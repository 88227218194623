import { SVGProps } from 'react';
const AndroidPayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 52 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4430_63076)">
      <path
        d="M30.4757 9.3575C30.1933 7.55568 29.2096 6.25728 27.5004 5.58084C26.0971 5.02552 24.7146 5.14489 23.4137 5.94245C23.1756 6.08864 22.9627 6.26945 22.7307 6.47964C22.7307 6.14833 22.7272 5.85246 22.7325 5.55667C22.7342 5.46411 22.7073 5.43381 22.6125 5.43473C22.2432 5.43903 21.8748 5.43646 21.5055 5.43646C21.3344 5.43646 21.3344 5.43646 21.3344 5.60948V15.9655C21.7845 16.3495 22.2511 16.7154 22.7334 17.0606C22.7273 15.9689 22.7299 14.8755 22.7316 13.7838C22.7316 13.7372 22.7177 13.6887 22.749 13.6342C22.9123 13.7752 23.0653 13.9222 23.2408 14.039C24.8354 15.0961 26.4881 15.1324 28.1374 14.2198C29.8622 13.2657 30.7833 11.315 30.4757 9.3575ZM26.3873 13.4335C25.05 13.725 23.5884 13.1498 22.8455 12.0408C22.7681 11.9266 22.729 11.8073 22.7299 11.6689C22.7316 10.5893 22.7307 9.50974 22.7299 8.43022C22.7299 8.2875 22.7716 8.16291 22.855 8.04701C23.4894 7.17073 24.3531 6.70448 25.428 6.61367C27.1389 6.47004 28.6891 7.63954 29.0515 9.3445C29.101 9.57895 29.128 9.81596 29.1201 10.0079C29.121 11.693 27.9957 13.0832 26.3873 13.4335Z"
        fill="black"
      />
      <path
        d="M40.542 5.44063C40.1597 5.44759 39.7774 5.44759 39.395 5.44063C39.289 5.43889 39.2707 5.4778 39.2725 5.57127C39.2786 5.82302 39.2751 6.07559 39.2733 6.32817C39.2733 6.36973 39.2899 6.41551 39.2507 6.46311C39.0943 6.32469 38.9413 6.18545 38.7728 6.06607C37.24 4.97613 35.0363 4.98995 33.4974 6.09894C31.8612 7.27796 31.1243 9.27706 31.6074 11.2199C32.1244 13.2985 33.9187 14.7587 35.9964 14.8339C36.9975 14.8703 37.9116 14.622 38.7363 14.0519C38.9179 13.9256 39.0839 13.7803 39.2742 13.609C39.2742 13.9412 39.2785 14.237 39.2724 14.532C39.2707 14.6271 39.2906 14.6635 39.3958 14.6617C39.7782 14.6557 40.1605 14.6557 40.5429 14.6617C40.6489 14.6635 40.6654 14.6254 40.6654 14.532C40.6628 11.5442 40.6628 8.55733 40.6654 5.57044C40.6654 5.47606 40.6463 5.43889 40.542 5.44063ZM39.2742 10.0321C39.2742 10.546 39.2734 11.0589 39.2751 11.5728C39.2759 11.7509 39.229 11.9084 39.1213 12.0546C38.4974 12.8945 37.6667 13.3746 36.6213 13.4758C34.8512 13.6462 33.235 12.4205 32.9256 10.6758C32.5928 8.80046 33.745 7.04095 35.6159 6.67165C36.9619 6.40698 38.1298 6.78498 39.0239 7.86706C39.2029 8.0833 39.2898 8.32296 39.2794 8.61188C39.2621 9.08409 39.2742 9.55811 39.2742 10.0321Z"
        fill="black"
      />
      <path
        d="M46.1496 12.6935C46.365 12.181 48.3564 7.43659 49.143 5.55166C49.1836 5.45447 49.2304 5.42028 49.3371 5.42193C49.7682 5.42872 50.1996 5.42715 50.6307 5.42301C50.7312 5.4221 50.7651 5.43054 50.7165 5.54222C49.2798 8.84692 47.8487 12.1542 46.4099 15.458C46.0222 16.3483 45.4037 17.0135 44.4561 17.3321C43.9806 17.492 43.4884 17.5027 42.9941 17.498C42.9321 17.4974 42.9082 17.4783 42.9085 17.4138C42.9107 17.0022 42.9113 16.5907 42.9083 16.1792C42.9078 16.0972 42.9541 16.1006 43.0109 16.0998C43.2724 16.0956 43.5355 16.1202 43.7943 16.0593C44.3358 15.932 44.7216 15.6071 44.9709 15.1213C45.096 14.8777 45.1954 14.6212 45.312 14.3732C45.3421 14.3092 45.3148 14.2632 45.2925 14.2116C44.6201 12.6574 42.1324 6.91198 41.5613 5.59338C41.4957 5.4418 41.4962 5.44139 41.6673 5.4413C42.0985 5.44122 42.5298 5.4452 42.9608 5.43857C43.0701 5.43692 43.1236 5.46987 43.1659 5.57153C44.1376 7.90118 46.1204 12.6299 46.1496 12.6935Z"
        fill="black"
      />
      <path
        d="M11.5884 2.30637L12.4708 0.691346C12.5093 0.620804 12.4831 0.53261 12.4122 0.494254C12.3414 0.455897 12.2528 0.481977 12.2143 0.552519L11.329 2.17266C10.6296 1.83647 9.84501 1.64787 9.01619 1.64787C8.18738 1.64787 7.40289 1.83647 6.70334 2.17266L5.81819 0.552519C5.77966 0.481977 5.69107 0.455897 5.6202 0.494254C5.54934 0.53261 5.52314 0.620804 5.56167 0.691346L6.44402 2.30637C4.94238 3.13224 3.88378 4.65854 3.71326 6.44272H14.3191C14.1487 4.65854 13.0901 3.13224 11.5884 2.30637Z"
        fill="#6BCA3E"
      />
      <path
        d="M3.68884 14.7773C3.68884 15.4433 4.23128 15.9834 4.90043 15.9834H5.78098V18.5987C5.78098 19.2674 6.32555 19.8095 6.9973 19.8095C7.66906 19.8095 8.21363 19.2674 8.21363 18.5987V15.9834H9.81913V18.5987C9.81913 19.2674 10.3637 19.8095 11.0355 19.8095C11.7072 19.8095 12.2518 19.2674 12.2518 18.5987V15.9834H13.1323C13.8014 15.9834 14.3438 15.4434 14.3438 14.7773V7.02344H3.68884V14.7773Z"
        fill="#6BCA3E"
      />
      <path
        d="M16.0224 6.78125C15.3506 6.78125 14.806 7.32335 14.806 7.99207V12.8837C14.806 13.5524 15.3506 14.0945 16.0224 14.0945C16.6941 14.0945 17.2387 13.5524 17.2387 12.8837V7.99207C17.2387 7.32335 16.6941 6.78125 16.0224 6.78125Z"
        fill="#6BCA3E"
      />
      <path
        d="M2.01064 6.78125C1.33888 6.78125 0.794312 7.32335 0.794312 7.99207V12.8837C0.794312 13.5524 1.33888 14.0945 2.01064 14.0945C2.68239 14.0945 3.22696 13.5524 3.22696 12.8837V7.99207C3.22696 7.32335 2.68239 6.78125 2.01064 6.78125Z"
        fill="#6BCA3E"
      />
      <path
        d="M6.53381 4.60155C6.80254 4.60155 7.02038 4.38469 7.02038 4.11718C7.02038 3.84967 6.80254 3.63281 6.53381 3.63281C6.26509 3.63281 6.04724 3.84967 6.04724 4.11718C6.04724 4.38469 6.26509 4.60155 6.53381 4.60155Z"
        fill="white"
      />
      <path
        d="M11.4996 4.60155C11.7684 4.60155 11.9862 4.38469 11.9862 4.11718C11.9862 3.84967 11.7684 3.63281 11.4996 3.63281C11.2309 3.63281 11.0131 3.84967 11.0131 4.11718C11.0131 4.38469 11.2309 4.60155 11.4996 4.60155Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4430_63076">
        <rect width={50.8333} height={20} fill="white" transform="translate(0.583374)" />
      </clipPath>
    </defs>
  </svg>
);
export default AndroidPayIcon;
