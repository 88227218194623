import { Order, OrderStatus } from '@api/models/Order';
import KitchenReceipt from '@components/KitchenReceipt';
import useMobileOrderItemHeader from '@components/OrdersMonitoring/MobileOrdersMonitoringList/MobileOrderItemHeader/useMobileOrderItemHeader';
import ChangeOrderStatusSelect from '@components/UI/ChangeOrderStatusSelect';
import { CURRENCY, DATE_FORMAT } from '@constants/constants';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import { Tooltip } from 'antd';
import { FC } from 'react';

interface MobileOrderItemHeaderProps {
  order: Order;
  statuses: OrderStatus[];
  stepRef?: any;
}

const MobileOrderItemHeader: FC<MobileOrderItemHeaderProps> = ({ order, statuses, stepRef }) => {
  const { t, changedOrder, handleChangeStatus } = useMobileOrderItemHeader();
  const orderDate = convertServerDateToClient(order.order_date, DATE_FORMAT);
  const tableName = order.table?.name || t('take_away');

  return (
    <>
      <div className={'flex w-full flex-col gap-2'}>
        <div className={'flex flex-wrap items-center justify-between gap-2 pl-8'}>
          <span className={'w-max rounded bg-indigo-40 px-4 py-2 text-sm leading-normal text-white'}>{tableName}</span>
          <div ref={stepRef}>
            <ChangeOrderStatusSelect
              defaultStatus={order.status}
              statuses={statuses}
              onChange={(value) => handleChangeStatus(order.id, value)}
            />
            <span></span>
          </div>
        </div>
        <div className={'flex flex-wrap items-center justify-between gap-x-4 gap-y-2'}>
          <div className={'text-sm leading-[150%]'}>
            <div className={'whitespace-normal text-grey-30'}>
              {t('client_name')}:{' '}
              <Tooltip
                placement={'topRight'}
                title={order.client ? `${order.client?.first_name} ${order.client?.last_name}` : t('guest')}
              >
                <span className={'max-w-[200px] truncate whitespace-normal text-grey-40'}>
                  {order.client ? `${order.client?.first_name} ${order.client?.last_name}` : t('guest')}
                </span>
              </Tooltip>
            </div>
          </div>
          <span className={'text-xs text-grey-30'}>{orderDate}</span>
        </div>
        <div className={'flex items-center justify-between gap-4 text-sm leading-[150%]'}>
          <span>{t('sum')}:</span>
          <span>
            {CURRENCY}
            {order.total}
          </span>
        </div>
      </div>

      <div className={'absolute top-[-9999px]'}>
        {changedOrder && (
          <div id={'kitchenReceipt'}>
            <KitchenReceipt order={changedOrder} />
          </div>
        )}
      </div>
    </>
  );
};

export default MobileOrderItemHeader;
