import ClientRegistrationForm from '@components/AuthForm/ClientRegistrationForm';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';
import AuthForm from '../../components/AuthForm';

const ClientRegistrationPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.registration'));

  return (
    <AuthForm>
      <ClientRegistrationForm />
    </AuthForm>
  );
};

export default ClientRegistrationPage;
