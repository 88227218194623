import { Space, Switch } from 'antd';
import { forwardRef, ForwardRefRenderFunction } from 'react';

interface HorizontalSwitchProps {
  label: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

// TODO: change any to correct type "HTMLInputElement | HTMLButtonElement" (there is 'any' because of a strange bug while deploying on the stage)
const HorizontalSwitch: ForwardRefRenderFunction<any, HorizontalSwitchProps> = (
  { label, disabled = false, onChange, ...props },
  ref
) => {
  return (
    <Space direction={'horizontal'}>
      <Switch ref={ref} disabled={disabled} {...props} onChange={onChange} />
      <span>{label}</span>
    </Space>
  );
};

export default forwardRef(HorizontalSwitch);
