import { User } from '@api/models/User';
import Onboarding from '@base/types/Onboarding';
import { Roles } from '@base/types/Roles';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  id: number | null;
  user: User | null;
  role: Roles | null;
  token: string | null;
  restaurantId: number | null;
  isUnauthorized: boolean;
  originalHost: string | null;
  onboarding: Onboarding;
}

const initOnboardingState = {
  restaurant_settings: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  cabinet_menus: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  inventory: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  qr_builder: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  cabinet_tables: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  waiter_orders: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  waiter_call_2_water: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
  client_restaurant_menu: {
    currentStep: 0,
    isFinished: false,
    isNotShowAgain: false,
    isSaved: false,
  },
};

export type OnboardingKeys = keyof typeof initOnboardingState;

const initialState: InitialState = {
  id: null,
  user: null,
  role: null,
  token: null,
  restaurantId: null,
  isUnauthorized: false,
  originalHost: null,
  onboarding: initOnboardingState,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    resetUserSlice: () => initialState,
    setUserId: (state, action: PayloadAction<number | null>) => {
      state.id = action.payload;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setRestaurantId: (state, action: PayloadAction<number | null>) => {
      state.restaurantId = action.payload;
    },
    setRole: (state, action: PayloadAction<Roles | null>) => {
      state.role = action.payload;
    },
    setOriginalHost: (state, action: PayloadAction<string | null>) => {
      state.originalHost = action.payload;
    },
    setIsUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    setOnboarding: (state, action: PayloadAction<Onboarding | null>) => {
      state.onboarding = action.payload || initOnboardingState;
    },
    // TODO: check if it will be needed in future (resetOnboarding)
    resetOnboarding: (state) => {
      state.onboarding = initOnboardingState;
    },
    setOnboardingStep: (state, action: PayloadAction<{ onboardingKey: OnboardingKeys; step: number }>) => {
      const route = state.onboarding[action.payload.onboardingKey];

      if (route) {
        route.currentStep = action.payload.step;
      }
      state.onboarding = { ...state.onboarding, route };
    },
    setOnboardingStepsFinish: (
      state,
      action: PayloadAction<{ onboardingKey: OnboardingKeys; isNotShowAgain: boolean }>
    ) => {
      const route = state.onboarding[action.payload.onboardingKey];

      if (route) {
        route.isFinished = true;
        route.currentStep = 0;
        route.isNotShowAgain = action.payload.isNotShowAgain;
      }

      state.onboarding = { ...state.onboarding, route };
    },
    setOnboardingSaved: (state, action: PayloadAction<{ onboardingKey: OnboardingKeys }>) => {
      const route = state.onboarding[action.payload.onboardingKey];

      if (route) {
        route.isSaved = true;
      }
      state.onboarding = { ...state.onboarding, route };
    },
  },
});

export default userSlice.reducer;

export const {
  resetUserSlice,
  setUserId,
  setUser,
  setToken,
  setRestaurantId,
  setRole,
  setIsUnauthorized,
  setOriginalHost,
  setOnboarding,
  resetOnboarding,
  setOnboardingStep,
  setOnboardingStepsFinish,
  setOnboardingSaved,
} = userSlice.actions;
