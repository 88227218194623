import api from './api';
import { PaymentCredential } from './models/PaymentCredential';
import { PaymentProvider } from './models/PaymentProvider';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['PaymentCredential'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPaymentCredential: builder.query<PaymentCredential, number>({
        query: (restId) => ({
          url: `restaurant/${restId}/payment/credential`,
          method: 'GET',
          params: {
            restId,
          },
        }),
        providesTags: () => ['PaymentCredential'],
        transformResponse: (response: { data: PaymentCredential }) => response.data,
      }),
      getPaymentProviders: builder.query<PaymentProvider[], void>({
        query: () => ({
          url: `payment/providers`,
          method: 'GET',
        }),
        transformResponse: (response: { data: PaymentProvider[] }) => response.data,
      }),
      updatePaymentCredential: builder.mutation<
        PaymentCredential,
        { restId: number; meshulam_user_id: string; payment_provider_id: number }
      >({
        query: (data) => ({
          url: `restaurant/${data.restId}/payment/credential`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: ['PaymentCredential'],
      }),
    }),
  });

export const {
  useGetPaymentCredentialQuery,
  useGetPaymentProvidersQuery,
  useUpdatePaymentCredentialMutation,
  useLazyGetPaymentCredentialQuery,
} = extendedApiSlice;
