import { serverUrl } from '@constants/environments';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../store';

export const apiCentral = createApi({
  reducerPath: 'centralApi',
  baseQuery: fetchBaseQuery({
    baseUrl: serverUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userState.token;

      headers.set('accept', `application/json`);
      headers.set('contentType', `application/json`);
      // headers.set('mode', 'cors');

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default apiCentral;
