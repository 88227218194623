import CabinetNav from '@base/types/CabinetNav';
import getOriginalRestHost from '@helpers/getOriginalRestHost';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

function navigationGeneration(navList: CabinetNav[]): MenuItem[] {
  const nav: MenuItem[] = [];

  navList.forEach((item) => {
    const link = item.isUseCleaLink ? item.link : getOriginalRestHost(item.link);

    nav.push({
      label: <Link to={link}>{item.label}</Link>,
      key: item.link,
      icon: item.icon,
      disabled: item.disabled,
    });

    if (item.divider) {
      nav.push({ type: 'divider' });
    }
  });

  return nav;
}

export default navigationGeneration;
