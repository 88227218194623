import MobileMenuDishList from '@components/MobileMenu/MobileMenuDishList';
import { Collapse, ConfigProvider } from 'antd';
import { FC } from 'react';
import { ManuCollapsedContent } from '../Categories/Categories';
import { mobileMenuSubCategoryCollapse } from './mobileMenuSubCategoryCollapse.antd';

interface SubCategoryProps {
  id: number;
  name: string;
}

const MobileSubCategory: FC<SubCategoryProps> = ({ id, name }) => {
  const subCategoriesList = [
    {
      key: `mobile-submenu-${id}`,
      label: <span className={'mobile-menu-subcategory__title'}>{name}</span>,
      children: (
        <ManuCollapsedContent>
          <MobileMenuDishList categoryId={id} />
        </ManuCollapsedContent>
      ),
    },
  ];

  return (
    <ConfigProvider theme={mobileMenuSubCategoryCollapse}>
      <Collapse className={'mobile-menu-subcategory'} expandIconPosition={'end'} items={subCategoriesList} />
    </ConfigProvider>
  );
};

export default MobileSubCategory;
