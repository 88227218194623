import { Cart, CartProduct } from '@base/types/Cart';
import { DishNotAvailable } from '@base/types/ClientOrder';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends Cart {
  isPromotion: boolean;
}

const initialState: InitialState = {
  cart_total_price: null,
  table_id: null,
  waiter_id: null,
  restaurant: null,
  products: [],
  isPromotion: false,
};

export const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    resetCartSlice: () => initialState,
    addProduct: (state, action: PayloadAction<CartProduct>) => {
      state.products = [...state.products, action.payload];
    },
    removeProduct: (state, action: PayloadAction<number>) => {
      state.products = state.products.filter((product) => product.card_id !== action.payload);
    },
    updateProductQuantity: (state, action: PayloadAction<{ cartProductId: number; quantity: number }>) => {
      state.products = state.products.map((product) =>
        product.card_id === action.payload.cartProductId
          ? {
              ...product,
              quantity: action.payload.quantity,
              product_total_price: (product.product_total_price / product.quantity) * action.payload.quantity,
            }
          : product
      );
    },
    setTotalCartPrice: (state, action: PayloadAction<number>) => {
      state.cart_total_price = action.payload;
    },
    setIsPromotion: (state, action: PayloadAction<boolean>) => {
      state.isPromotion = action.payload;
    },

    setWarningProductQuantity: (state, action: PayloadAction<{ notAvailableProducts: DishNotAvailable[] }>) => {
      const notAvailableProductsIds = action.payload.notAvailableProducts.map((product) => product.dish_id);

      state.products = state.products.map((product) => {
        if (notAvailableProductsIds.includes(product.id)) {
          const notAvailableProduct = action.payload.notAvailableProducts.find(
            (notAvailableProduct) => notAvailableProduct.dish_id === product.id
          )!;

          const inventoryQuantity = !notAvailableProduct.dish_is_unavailable;
          const notAvailable = !!notAvailableProduct.dish_is_unavailable;

          return {
            ...product,
            errors: {
              inventoryQuantity,
              notAvailable,
            },
            inventory_quantity: notAvailableProduct.available_quantity,
          };
        }

        return {
          ...product,
          errors: {
            inventoryQuantity: false,
            notAvailable: false,
          },
        };
      });
    },
    resetWarningProductQuantity: (state) => {
      state.products = state.products.map((product) => ({
        ...product,
        error: null,
      }));
    },
  },
});

export default cartSlice.reducer;

export const {
  resetCartSlice,
  addProduct,
  removeProduct,
  updateProductQuantity,
  setTotalCartPrice,
  setIsPromotion,
  setWarningProductQuantity,
  resetWarningProductQuantity,
} = cartSlice.actions;
