import MBAlertCircleIcon from '@base/components/icons/MBAlertCircleIcon';
import MBArrowLeftIcon from '@base/components/icons/MBArrowLeftIcon';
import MBArrowRightIcon from '@base/components/icons/MBArrowRightIcon';
import MBEyeIcon from '@base/components/icons/MBEyeIcon';
import TransactionPdf from '@base/components/TransactionPdf';
import { PackageTransaction } from '@base/redux/api/models/PackageTransaction';
import { Pagination } from '@base/redux/api/models/Pagination';
import colors from '@base/utils/constants/colors';
import convertServerDateToClient from '@base/utils/helpers/convertServerDateToClient';
import { Button, PaginationProps, Table, TableProps, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useTransactionsTable from './useTransactionsTable';

interface TransactionsTableProps {
  transactions: PackageTransaction[];
  pagination: Pagination | false;
  setPagePagination: Dispatch<SetStateAction<number>>;
}

interface TransactionTableType extends PackageTransaction {
  key: string;
}

function generateColumns({
  t,
  getTransactionById,
}: {
  t: any;
  getTransactionById: (id: number) => void;
}): ColumnsType<TransactionTableType> {
  const columns: TableProps<TransactionTableType>['columns'] = [
    {
      title: t('date'),
      key: 'created_at',
      className: '!bg-transparent',
      width: 150,
      render: (_, { created_at }) => convertServerDateToClient(created_at, 'DD/MM/YYYY'),
    },
    {
      title: t('status'),
      key: 'status',
      className: '!bg-transparent',
      render: (_, { data }) => {
        if (!data?.status && !data?.error_message) {
          return t('success');
        }

        return data?.status || data?.error_message || '-';
      },
    },
    {
      title: t('transaction_id'),
      key: 'transactionId',
      className: '!bg-transparent',
      render: (_, { data }) => data?.transactionId || '-',
    },
    {
      title: t('transaction_code'),
      key: 'transactionCode',
      className: '!bg-transparent',
      render: (_, { data }) => data?.transactionCode || '-',
    },
    {
      title: t('package'),
      key: 'packageName',
      className: '!bg-transparent',
      render: (_, { package: transactionPackage }) => transactionPackage.name,
    },
    {
      title: t('sum'),
      key: 'sum',
      className: 'text-grey-40 !bg-transparent',
      render: (_, { data }) => data?.sum || data?.paymentSum || '-',
    },
    {
      title: t('action'),
      key: 'action',
      align: 'center',
      className: '!bg-transparent',
      width: 120,
      render: (_, { id, data, payment_method }) => (
        <>
          {data &&
            payment_method !== 'no_type' &&
            (data.error_message ? (
              <Tooltip title={t('payment_error')}>
                <MBAlertCircleIcon fontSize={18} color={colors.orange40} />
              </Tooltip>
            ) : (
              <Button
                onClick={() => {
                  getTransactionById(id);
                }}
                type={'text'}
                className={'mx-auto flex items-center justify-center rounded border border-solid border-grey-15'}
                icon={<MBEyeIcon />}
              />
            ))}
        </>
      ),
    },
  ];

  return columns;
}

const TransactionsTable: FC<TransactionsTableProps> = ({ pagination, transactions, setPagePagination }) => {
  const { t } = useTranslation();
  const { dataTransaction, getTransactionById } = useTransactionsTable();

  const handleChangePagination: PaginationProps['onChange'] = (page) => {
    if (setPagePagination) {
      setPagePagination(page);
    }
  };

  const paginationConfig: TablePaginationConfig | false = pagination
    ? {
        showSizeChanger: false,
        hideOnSinglePage: true,
        pageSize: pagination.per_page,
        current: pagination.current_page,
        total: pagination.total,
        position: ['bottomCenter'],
        nextIcon: <MBArrowLeftIcon height="24px" width="24px" />,
        prevIcon: <MBArrowRightIcon height="24px" width="24px" />,
        onChange: handleChangePagination,
      }
    : false;

  const dataSource: TransactionTableType[] = transactions.map((item, index) => ({
    ...item,
    key: `transaction-key-${index}`,
  }));

  const columns = generateColumns({ t, getTransactionById });

  return (
    <>
      <Table
        rowClassName={'text-grey-30'}
        className={'transactions-table'}
        size={'small'}
        dataSource={dataSource}
        columns={columns}
        pagination={paginationConfig}
        scroll={{ x: 1 }}
      />
      <div className={'absolute top-[-9999px] w-full'}>
        {dataTransaction && (
          <div id={'packageTransaction'}>
            <TransactionPdf transaction={dataTransaction} />
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionsTable;
