import { OnboardingKeys } from '@base/redux/features/userSlice';
import useTour from '@components/UI/Tour/useTour';
import useSteps from './useSteps';

const useClientMenuOnboarding = () => {
  const onboardingKey: OnboardingKeys = 'client_restaurant_menu';

  const { steps } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard;

  return {
    isShowTour,
    renderTour,
  };
};

export default useClientMenuOnboarding;
