import { useAppSelector } from '@base/redux/store';
import useCheckRole from '@hooks/useCheckRole';
import { FC } from 'react';
import RestaurantLogo from '../../RestaurantLogo';

interface RestaurantCardProps {
  children: React.ReactNode;
}

const RestaurantCard: FC<RestaurantCardProps> = ({ children }) => {
  const { isClient } = useCheckRole();
  const restInfo = useAppSelector((state) => state.restaurantState);
  const restSettings = useAppSelector((state) => state.restaurantSettingsState);

  const logoSrc = isClient ? restInfo.profile_picture : restSettings.logoSrc;

  return (
    <div className={'restaurant-card__bg mx-auto mt-[45px] w-full rounded bg-white px-2 pb-6 text-center'}>
      <div
        className={
          'restaurant-card__image-block restaurant-card-image-block m-auto mb-4 mt-[-45px] inline-block h-[90px] w-[90px]'
        }
      >
        <RestaurantLogo src={logoSrc} />
      </div>
      <>{children}</>
    </div>
  );
};

export default RestaurantCard;
