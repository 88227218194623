import ConfirmModalContent from '@base/components/ConfirmModalContent';
import { Table } from '@base/redux/api/models/Table';
import { Button, Col, Row, Space } from 'antd';
import { HookAPI } from 'antd/es/modal/useModal';
import { t } from 'i18next';
import { FC } from 'react';
import MBEditIcon from '../../icons/MBEditIcon';
import RemoveIconButton from '../../UI/RemoveIconButton';

interface WaiterTableProps {
  handleEditTable: (table: Table) => void;
  handleRemove: (id: number) => void;
  showModalConfirm: (data: any) => void;
  table: Table;
  modal: HookAPI;
}

const WaiterTable: FC<WaiterTableProps> = ({ handleEditTable, handleRemove, showModalConfirm, modal, table }) => {
  const handleDeleteConfirm = (id: number) => {
    showModalConfirm({
      modal,
      title: t('deleting_table'),
      content: <ConfirmModalContent title={t('do_you_want_to_remove_table')} />,
      okCallback: () => handleRemove(id),
    });
  };

  return (
    <>
      <Row
        wrap={false}
        gutter={[16, 0]}
        align="middle"
        className={'w-full border-0 border-b border-solid border-grey-5 py-2'}
      >
        <Col flex="0 0 88px">
          <Space className={'gap-0'} align="center">
            <RemoveIconButton className={'text-2xl'} onClick={() => handleDeleteConfirm(table.id)} />
            <Button type={'text'} className={'w-8 justify-center px-5 text-2xl'} onClick={() => handleEditTable(table)}>
              <MBEditIcon type={'link'} className={'shrink-0 text-indigo-30'} />
            </Button>
          </Space>
        </Col>
        <Col flex="1 1 50%">
          <span className={'block truncate px-4 py-2 text-sm leading-[150%] text-indigo-40'}>{table.name}</span>
        </Col>
        <Col flex="1 1 50%">
          <span
            className={
              'block w-full truncate rounded-[4px] px-2 py-1 text-sm font-normal leading-[150%] text-grey-30 outline outline-1 outline-grey-20'
            }
          >
            {table.waiter ? `${table.waiter.first_name} ${table.waiter.last_name}` : t('no_one')}
          </span>
        </Col>
      </Row>
    </>
  );
};

export default WaiterTable;
