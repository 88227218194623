import { useAppSelector } from '@base/redux/store';
import useLogout from '@hooks/useLogout';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import MBChevronDownIcon from '../icons/MBChevronDownIcon';
import MBLogoutIcon from '../icons/MBLogoutIcon';
import UserProfile from '../icons/UserProfile';

const HeaderProfile = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const role = useAppSelector((state) => state.userState.role);
  const { logout } = useLogout();

  const onClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'logout') {
      await logout();
    }
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div className={'flex w-full gap-[10px] text-sm'}>
          <MBLogoutIcon className={'flex text-2xl text-grey-20'} />
          {t('logout')}
        </div>
      ),
      key: 'logout',
    },
  ];

  return (
    <>
      <div className={'flex items-center gap-2'}>
        <div>
          <span className={'px-2 text-sm font-bold text-green-500'}>{role}</span>
          <span>{t('hi')}</span> <span>{user?.first_name}</span>
        </div>
        <div
          className={
            ' h-[36px] w-[44px] rounded-[50px_50px_4px_4px] bg-[linear-gradient(281deg,rgba(46,181,254,0.50)_11.68%,rgba(80,14,246,0.50)88.35%)] px-[10px] pb-1 pt-2 text-center'
          }
        >
          <UserProfile />
        </div>
        <Dropdown menu={{ items, onClick }} trigger={['click']} placement={'bottomLeft'} className={'dropdown-profile'}>
          <Button
            type="link"
            size={'small'}
            icon={<MBChevronDownIcon className={'mt-1 flex border-none text-2xl text-grey-20 hover:text-grey-30'} />}
          />
        </Dropdown>
      </div>
    </>
  );
};

export default HeaderProfile;
