import TourCounter from '@components/UI/TourCounter';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import { Checkbox, Tour as TourAntd, TourProps as AntTourPops } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface TourProps extends AntTourPops {
  onCheckboxChange?: (e: CheckboxChangeEvent) => void;
}

const Tour: FC<TourProps> = ({ onCheckboxChange, steps = [], current = 0, open, ...props }) => {
  const { t } = useTranslation();
  const { isXL } = useGetMediaQuery();
  const [isRendered, setIsRendered] = useState(false);
  const isShowCheckbox = steps.length === current + 1;
  const renderCheckbox = () => (
    <div className={'tour-checkbox'}>
      <Checkbox onChange={(e) => onCheckboxChange && onCheckboxChange(e)}>{t('not_introduce_again')}</Checkbox>
    </div>
  );

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div>
      <TourAntd
        indicatorsRender={(current: number, total: number) => <TourCounter current={current + 1} total={total} />}
        scrollIntoViewOptions={false}
        placement={isXL ? 'leftTop' : 'bottom'}
        steps={steps}
        current={current}
        open={open}
        {...props}
      />
      {isShowCheckbox &&
        isRendered &&
        open &&
        createPortal(renderCheckbox(), document.querySelector('.ant-tour-footer') as HTMLElement)}
    </div>
  );
};

export default Tour;
