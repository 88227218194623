import { useGetRestaurantTablesQuery, useGetRestaurantTakeAwayTableQuery } from '@api/restaurantInfoApi';
import tableNumberQRImg from '@assets/img/table_number_QR.png';
import { resetCartSlice } from '@base/redux/features/cartSlice';
import { setClientTable } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import { QR_SCAN_GET_PARAMETER } from '@base/utils/constants/constants';
import getQueryParam from '@base/utils/helpers/getQueryParam';
import useClientBindingTable from '@components/MobileMenu/ClientBindingTable/useClientBindingTable';
import Hero from '@components/MobileMenu/Hero';
import RestaurantCard from '@components/MobileMenu/RestaurantCard';
import { ROUTES } from '@constants/routes';
import { Alert, Button, Image, Select } from 'antd';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const ClientBindingTable = () => {
  const { t } = useTranslation();
  const { removeAllServices } = useClientBindingTable();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: tables, isFetching: isFetchingTables, isError } = useGetRestaurantTablesQuery();
  const { data: tableTakeAway, isFetching } = useGetRestaurantTakeAwayTableQuery();
  const { name, background_picture, secondary_title, description } = useAppSelector((state) => state.restaurantState);
  const table = useAppSelector((state) => state.clientSlice.table);
  const cartProducts = useAppSelector((state) => state.cartSlice.products);
  const { selected: isTableSelected, table_take_away_id, table_id } = table;
  const selectedTableID = table_take_away_id ? 0 : table_id;
  const isTakeAwayTableSelected = isTableSelected && table_take_away_id;
  const isRegularTableSelected = isTableSelected && table_id;

  const calledServicesIds = useAppSelector((state) => state.clientSlice.calledServicesIds);

  const isCalledService = calledServicesIds.length > 0;

  useLayoutEffect(() => {
    const isQrCodeScanned = getQueryParam(QR_SCAN_GET_PARAMETER);

    if (isQrCodeScanned) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(QR_SCAN_GET_PARAMETER);
      const newSearchString = searchParams.toString();

      navigate(`${location.pathname}${newSearchString ? `?${newSearchString}` : ''}`);

      // TODO: send req to the server (scanned qr code)
    }
  }, []);

  const handleChange = async (value: number) => {
    if (isCalledService) {
      await removeAllServices();
    }

    if (tables) {
      if (value === 0 && tableTakeAway?.id) {
        dispatch(
          setClientTable({
            selected: true,
            table_id: null,
            table_take_away_id: tableTakeAway.id,
            table_name: tableTakeAway.name.name,
            waiter: tableTakeAway.waiter,
          })
        );
      } else {
        const selectedTable = tables?.find((table) => table.id === value);

        if (selectedTable) {
          dispatch(
            setClientTable({
              selected: true,
              table_id: value,
              table_take_away_id: null,
              table_name: selectedTable.name,
              waiter: selectedTable.waiter,
            })
          );
        }
      }
    }
  };

  const handleMoveToMenu = () => {
    navigate(ROUTES.restaurant_menu);
  };

  return (
    <>
      {isError && <span>{t('error')}</span>}
      {isFetchingTables || (isFetching && <span>{t('loading')}</span>)}
      {tables && !isFetching && (
        <div className={'mobile-menu'}>
          <Hero url={background_picture} name={name} />

          <div className={'restaurant-card mobile-menu__restaurant-card restaurant-card-table'}>
            <RestaurantCard>
              <div className={'restaurant-card__title restaurant-card-table__restaurant-title'}>{secondary_title}</div>
              <div className={'restaurant-card__description restaurant-card-table__restaurant-description'}>
                {description}
              </div>
              <div className={'restaurant-card__choose-table'}>
                <div className={'restaurant-card-table__title'}>{t('choose_table')}</div>

                {tables.length > 0 || tableTakeAway ? (
                  <Select
                    className={'restaurant-card-table__select'}
                    onChange={handleChange}
                    placeholder={t('select_table_placeholder')}
                    defaultValue={selectedTableID}
                  >
                    {tableTakeAway && (
                      <Select.Option disabled={cartProducts.length > 0 && isRegularTableSelected} value={0}>
                        {tableTakeAway.name.name}
                      </Select.Option>
                    )}

                    {tables.map((table) => (
                      <Select.Option
                        disabled={cartProducts.length > 0 && isTakeAwayTableSelected}
                        key={`table-id-${table.id}`}
                        value={table.id}
                      >
                        {table.name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <p>{t('tables_not_exist')}</p>
                )}

                {isCalledService && (
                  <Alert className={'mt-5'} type={'warning'} message={t('all_calls_will_be_canceled')} />
                )}

                {cartProducts.length > 0 && (
                  <>
                    <Alert
                      className={'mt-5'}
                      type={'warning'}
                      message={'in order to choose another type of table, you need to clear the cart'} // TODO: add translation
                    />
                    <Button
                      className={'restaurant-card-table__clear-cart-btn'}
                      type={'primary'}
                      onClick={() => {
                        dispatch(resetCartSlice());
                      }}
                    >
                      Clear the cart
                    </Button>
                  </>
                )}

                <div className={'restaurant-card-table__qrcode'}>
                  <span className={'restaurant-card-table__qrcode-title'}>{t('table_number_appears_QR_code')}:</span>
                  <div className={'restaurant-card-table__image'}>
                    <Image preview={false} src={tableNumberQRImg} />
                  </div>
                </div>

                <Button
                  type={'primary'}
                  className={'restaurant-card-table__btn'}
                  onClick={handleMoveToMenu}
                  disabled={!isTableSelected}
                >
                  {t('go_to_menu')}
                </Button>
              </div>
            </RestaurantCard>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientBindingTable;
