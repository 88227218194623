// import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import { FC, ReactNode } from 'react';
// import { useLocation } from 'react-router-dom';

interface AuthFormProps {
  children: ReactNode;
  sideBarContent?: ReactNode;
}

const AuthForm: FC<AuthFormProps> = ({ children, sideBarContent }) => {
  // TODO: remove commented code
  // const { heroSrc } = useAppSelector((state) => state.restaurantSettingsState);
  // const location = useLocation();
  // const [restaurantHero, setRestaurantHero] = useState(false);
  // const [restaurantTitle, setRestaurantTitle] = useState('');
  // const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);

  // useLayoutEffect(() => {
  //   if (isSubdomain) {
  //     setRestaurantHero(true);
  //     // TODO: get from server
  //     setRestaurantTitle('My Italian Grandma');
  //   }
  // }, [location]);

  return (
    <div
      className={
        'lg:shadow-common m-auto flex-row-reverse items-stretch rounded lg:flex lg:min-h-[713px] lg:w-full lg:max-w-[1026px] lg:overflow-hidden lg:border lg:border-solid lg:border-cyan-20 lg:bg-white'
      }
    >
      {/* <div
        className={`${
          restaurantTitle ? 'h-[206px]' : 'h-[10rem]'
        } relative flex-shrink-0 bg-[url(../assets/img/bg-header-auth-form.jpg)] bg-cover bg-left-top bg-no-repeat lg:flex lg:h-auto lg:w-[21rem] lg:bg-[url(../assets/img/bg-header-auth-form-desktop.jpg)]`}
      >
        {restaurantTitle && (
          <p className={'relative z-10 line-clamp-1 px-4 pt-8 text-center text-[26px] font-bold text-white lg:hidden'}>
            {restaurantTitle}
          </p>
        )}

        {restaurantHero && (
          <div className={'restaurant-preview-hero absolute left-0 top-0 z-0 h-full w-full'}>
            <img
              className={'h-full w-full object-cover object-center'}
              src={heroSrc || placeholder}
              alt="background restaurant image"
            />
          </div>
        )}

        <div className={'m-auto hidden text-center lg:block'}>{sideBarContent}</div>
      </div> */}

      <div
        className={`relative h-[10rem] flex-shrink-0 bg-[url(../assets/img/bg-header-auth-form.jpg)] bg-cover bg-left-top bg-no-repeat lg:flex lg:h-auto lg:w-[21rem] lg:bg-[url(../assets/img/bg-header-auth-form-desktop.jpg)]`}
      >
        <div className={'m-auto hidden text-center lg:block'}>{sideBarContent}</div>
      </div>
      <div className={'w-full px-4 lg:px-0'}>
        <div
          className={
            'shadow-common relative z-10 m-auto mt-[-100px] max-w-[500px] rounded bg-white lg:mt-0  lg:flex lg:h-full lg:max-w-full lg:items-end lg:shadow-none'
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
