import { Pagination } from '@api/models/Pagination';
import MBArrowLeftIcon from '@base/components/icons/MBArrowLeftIcon';
import MBArrowRightIcon from '@base/components/icons/MBArrowRightIcon';
import MBChevronDownIcon from '@base/components/icons/MBChevronDownIcon';
import MBChevronUpIcon from '@base/components/icons/MBChevronUpIcon';
import ChangeOrderStatusSelect from '@base/components/UI/ChangeOrderStatusSelect';
import WhiteBackgroundSelect from '@base/components/UI/WhiteBackgroundSelect';
import { Order, OrderStatus } from '@base/redux/api/models/Order';
import { Waiter } from '@base/redux/api/models/Waiter';
import KitchenReceipt from '@components/KitchenReceipt';
import useOrdersMonitoringTable from '@components/OrdersMonitoring/OrdersMonitoringTable/useOrdersMonitoringTable';
import { CURRENCY, DATE_FORMAT } from '@constants/constants';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import { Col, PaginationProps, Select, Table, TablePaginationConfig, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface DataType extends Order {
  key: string;
}

interface Role {
  isManager: boolean;
  isSupervisor: boolean;
  isWaiter: boolean;
  isClient: boolean;
}

interface ExpandedOrderProps {
  record: Order;
}

interface GetColumnsProps {
  role: Role;
  waiters: Waiter[];
  statuses: OrderStatus[];
  isWaiters2Tables: boolean;
  changeOrderStatus: ({ order_id, order_status_id }: { order_id: number; order_status_id: number }) => void;
  changeOrderWaiter: ({ order_id, order_waiter_id }: { order_id: number; order_waiter_id: number }) => void;
}

function getColumns({
  role,
  waiters,
  isWaiters2Tables,
  statuses,
  changeOrderWaiter,
  changeOrderStatus,
}: GetColumnsProps) {
  const { isManager, isSupervisor, isWaiter } = role;

  const handleChangeStatus = (order_id: number, order_status_id: number) => {
    changeOrderStatus({ order_id, order_status_id });
  };

  const handleChangeWaiter = (order_id: number, order_waiter_id: number) => {
    changeOrderWaiter({ order_id, order_waiter_id });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t('order_number'),
      key: 'order',
      render: (_, { id }) => `#${id}`,
      width: '100px',
    },
    {
      title: t('table'),
      key: 'table',
      dataIndex: 'table',
      ellipsis: {
        showTitle: false,
      },
      render: (_, { table }) => (
        <Tooltip placement="topRight" title={table?.name || 'TakeAway'}>
          {table?.name || 'TakeAway'}
        </Tooltip>
      ),
      width: '100px',
    },
    {
      title: t('waiter'),
      key: 'waiter',
      // enable ellipsis if user is waiter
      ellipsis: isWaiter,
      // Render select with all waiters if user is manager or supervisor or render simple text with tooltip
      render: (_, { id, waiter }) =>
        isWaiters2Tables && (isManager || isSupervisor) ? (
          <WhiteBackgroundSelect
            key={`waiter-select-${id}-${waiter?.id || null}`}
            defaultValue={waiter?.id || null}
            onChange={(value) => handleChangeWaiter(id, value)}
          >
            <Select.Option key={0} id={0} value={null}>
              {t('no_one')}
            </Select.Option>
            {waiters &&
              waiters.map((waiter) => (
                <Select.Option key={`waiter-key-${waiter.id}`} id={waiter.id} value={+waiter.id}>
                  {waiter.first_name} {waiter.last_name}
                </Select.Option>
              ))}
          </WhiteBackgroundSelect>
        ) : waiter ? (
          <Tooltip placement="topRight" title={`${waiter.first_name} ${waiter.last_name}`}>
            {waiter.first_name} {waiter.last_name}
          </Tooltip>
        ) : (
          <Tooltip placement="topRight" title={t('no_one')}>
            {t('no_one')}
          </Tooltip>
        ),
      width: '152.75px',
      className: isManager || isSupervisor ? 'order-monitoring-select-waiter-cell' : '',
    },
    {
      title: t('inviting_name'),
      key: 'client',
      dataIndex: 'client',
      ellipsis: {
        showTitle: false,
      },
      render: (_, { client }) => (
        <Tooltip placement="topRight" title={client ? `${client?.first_name} ${client?.last_name}` : t('guest')}>
          {client ? `${client?.first_name} ${client?.last_name}` : t('guest')}
        </Tooltip>
      ),
      width: '152.75px',
    },
    {
      title: t('order_status'),
      key: 'status',
      // custom select component for 'monitoring orders' table
      render: (_, { id, status }) => (
        <ChangeOrderStatusSelect
          defaultStatus={status}
          statuses={statuses}
          onChange={(value) => handleChangeStatus(id, value)}
        />
      ),
      width: '152.75px',
    },
    {
      title: t('order_time'),
      dataIndex: 'order_date',
      key: 'order_date',
      ellipsis: {
        showTitle: false,
      },
      render: (_, { order_date }) => (
        <Tooltip placement="topRight" title={order_date}>
          {convertServerDateToClient(order_date, DATE_FORMAT)}
        </Tooltip>
      ),
      width: '200px',
      sorter: (a, b) => (a.order_date as any) - (b.order_date as any),
      sortDirections: ['descend'],
    },
    {
      // needed to set visualy min width of previous column (order_date)
      colSpan: 1,
    },
    {
      title: t('sum'),
      dataIndex: 'total',
      key: 'total',
      ellipsis: {
        showTitle: false,
      },
      render: (_, { total }) => (
        <Tooltip placement="topRight" title={`${total} ${CURRENCY}`}>
          {total} {CURRENCY}
        </Tooltip>
      ),
      width: '81px',
      className: 'order-monitoring-table-price-cell',
    },
    // render expandIcon as last column
    Table.EXPAND_COLUMN,
  ];

  return columns;
}

interface OrdersMonitoringTableProps {
  orders: Order[];
  pagination: Pagination | false;
  waiters: Waiter[];
  orderStatuses: OrderStatus[];
  setPagePagination?: Dispatch<SetStateAction<number>>;
  isDashboard?: boolean;
  isWaiters2Tables: boolean;
}

interface ExpandedOrderProps {
  record: Order;
}

const ExpandedOrder: FC<ExpandedOrderProps> = ({ record }) => {
  const { t } = useTranslation();
  const orders = record?.order_items || [];

  // TODO: add calculation of order price instead of output from api
  return (
    <div className={'flex flex-col gap-y-[10px]'}>
      {orders.map((order) => {
        const orderName = order?.dish?.dish_name || order?.promotion?.dish.dish_name;
        const orderDescription = order?.dish?.description || order?.promotion?.dish.description;

        return (
          <div key={`order-dish-key-${order.id}`} className={'flex items-center gap-x-2 rounded bg-grey-5 px-4 py-2'}>
            <Col flex="1 1 50%">
              <span className={'text-sm font-bold leading-[120%] text-indigo-40'}>{orderName}</span>
              <p className={'m-0 text-sm leading-[150%] text-grey-40'}>{orderDescription}</p>
            </Col>
            <Col flex="1 1 50%" className={'flex h-max flex-wrap items-center gap-2 px-8'}>
              {order.options &&
                order.options.map((option) => (
                  <span key={`opt-${option.name}`} className={'h-[21px] text-sm leading-[150%] text-grey-40'}>
                    {option.values?.value}
                  </span>
                ))}
              {order.extras &&
                order.extras.map((extra) => (
                  <span key={`ext-${extra.name}`} className={'h-[21px] text-sm leading-[150%] text-grey-40'}>
                    {extra.is_readily_available ? extra.name : null}
                  </span>
                ))}
            </Col>
            <Col flex="0 0 171px" className={'flex flex-wrap items-center gap-x-6 text-sm leading-[150%] text-grey-40'}>
              <span>
                {t('amount')}: {order.quantity}
              </span>
              <span>
                {order?.price} {CURRENCY}
              </span>
            </Col>
          </div>
        );
      })}
    </div>
  );
};

const OrdersMonitoringTable: FC<OrdersMonitoringTableProps> = ({
  orders,
  pagination,
  waiters,
  orderStatuses,
  setPagePagination,
  isDashboard,
  isWaiters2Tables,
}) => {
  const { role, changeOrderStatus, changeOrderWaiter, setExpandedKeys, expandedKeys, onRow, onExpand, changedOrder } =
    useOrdersMonitoringTable();

  const memoizedData: DataType[] = useMemo(
    () => orders.map((item, index) => ({ ...item, key: `order-key-${index + 1}` })),
    [orders]
  );

  const memoizedColumns = useMemo(
    () =>
      getColumns({ role, waiters, isWaiters2Tables, statuses: orderStatuses, changeOrderStatus, changeOrderWaiter }),
    [changeOrderStatus, changeOrderWaiter]
  );

  const handleChangePagination: PaginationProps['onChange'] = (page) => {
    if (setPagePagination) {
      setPagePagination(page);
      setExpandedKeys([]);
    }
  };

  const paginationConfig: TablePaginationConfig | false = pagination
    ? {
        showSizeChanger: false,
        hideOnSinglePage: true,
        pageSize: pagination.per_page,
        current: pagination.current_page,
        total: pagination.total,
        position: ['bottomCenter'],
        nextIcon: <MBArrowLeftIcon height="24px" width="24px" />,
        prevIcon: <MBArrowRightIcon height="24px" width="24px" />,
        onChange: handleChangePagination,
      }
    : false;

  const scrollY = isDashboard ? 'auto' : '55vh'; // 55vh - height of table container, also set in style of table
  const modificatorClass = isDashboard ? 'order-monitoring-table--dashboard' : '';

  const memoizedTable = useMemo(() => {
    return (
      <Table
        className={`order-monitoring-table ${modificatorClass}`}
        rowClassName={(record, index) => (index % 2 !== 0 ? 'table-row-grey' : '')}
        dataSource={memoizedData}
        columns={memoizedColumns}
        onRow={onRow}
        pagination={paginationConfig}
        scroll={{ x: 1, y: scrollY }}
        expandable={{
          expandedRowRender: (record) => <ExpandedOrder record={record} key={`key-record-${record.id}`} />,
          expandedRowClassName: () => 'order-monitoring-expanded-row',
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <div className={'cursor-pointer'} onClick={(e) => onExpand(record, e)}>
                <MBChevronUpIcon className={'text-indigo-40'} />
              </div>
            ) : (
              <div className={'cursor-pointer'} onClick={(e) => onExpand(record, e)}>
                <MBChevronDownIcon className={'text-grey-20'} />
              </div>
            ),
          onExpand,
          expandedRowKeys: expandedKeys,
        }}
      />
    );
  }, [orders, expandedKeys]);

  return (
    <>
      {memoizedTable}
      <div className={'absolute top-[-9999px]'}>
        {changedOrder && (
          <div id={'kitchenReceipt'}>
            <KitchenReceipt order={changedOrder} />
          </div>
        )}
      </div>
    </>
  );
};
export default OrdersMonitoringTable;
