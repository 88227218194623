import { RestaurantSettingsFieldType } from '@components/RestaurantSettings/RestaurantSettingsForm/RestaurantSettingsForm';
import { Button, Form, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MBClockIcon from '../../icons/MBClockIcon';
import RestaurantHoursModal from '../RestaurantHoursModal';
import { useRestaurantHoursUpdate } from './useRestaurantHoursUpdate';

const RestaurantHoursUpdate: FC = () => {
  const { t } = useTranslation();
  const { getSchedule } = useRestaurantHoursUpdate();
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const form = useFormInstance();
  const workHours = form.getFieldValue('work_hours');
  const scheduleDescription = form.getFieldValue('description_schedule');

  return (
    <>
      <Form.Item label={t('restaurant_work_hours')}>
        <Input.TextArea
          className={'hover: focus: !border-none !shadow-none'}
          readOnly
          maxLength={255}
          rows={4}
          placeholder={'תיאור'}
          value={`${workHours ? getSchedule(workHours) : ''}${scheduleDescription || ''}`}
        />
      </Form.Item>
      <Form.Item<RestaurantSettingsFieldType> name="work_hours" hidden>
        <Input />
      </Form.Item>
      <Form.Item<RestaurantSettingsFieldType> name="description_schedule" hidden>
        <Input />
      </Form.Item>
      <Button size={'small'} onClick={() => setModalIsOpened(true)}>
        {t('update_hours')}
        <MBClockIcon />
      </Button>

      <RestaurantHoursModal isOpened={modalIsOpened} setIsOpened={setModalIsOpened} />
    </>
  );
};

export default RestaurantHoursUpdate;
