import SimpleLoginForm from '@components/AuthForm/LoginForm/SimpleLoginForm';
import useLoginForm from '@components/AuthForm/LoginForm/useLoginForm';
import GoogleAuthButton from '@components/UI/GoogleAuthButton';
import { ROUTES } from '@utils/constants/routes';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const { t, isSubdomain, initLoggedUser, onGoogleSuccess, onGoogleError } = useLoginForm();

  return (
    <div className={' p-6 lg:mx-auto lg:box-content lg:max-w-[400px] lg:px-10 lg:py-10'}>
      <header className={'mb-10'}>
        <div className={'mb-2 text-[24px] font-bold text-indigo-40 lg:text-[26px]'}>{t('entrance')}</div>
      </header>

      <SimpleLoginForm initLoggedUser={initLoggedUser} />

      <div className={'mt-4 flex flex-col items-center gap-3 border-0 border-t border-solid border-grey-15 pt-3'}>
        <span>{t('or')}</span>
        <GoogleAuthButton onSuccess={onGoogleSuccess} onError={onGoogleError} />
      </div>

      {!isSubdomain && (
        <footer className={'mt-10 py-4 text-center'}>
          <Space wrap size={'middle'}>
            <span className={'text-grey-40'}>{t('first_time')}</span>
            <Link to={ROUTES.registration} className={'underline underline-offset-4'}>
              {t('register')}
            </Link>
          </Space>
        </footer>
      )}
    </div>
  );
};

export default LoginForm;
