import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.548505 0L8.22964 9.92807L0.5 18H2.23963L9.00692 10.9329L14.4747 18H20.3947L12.2814 7.5135L19.4761 0H17.7365L11.5041 6.50866L6.46854 0H0.548505ZM3.10676 1.2387H5.82644L17.8361 16.7611H15.1164L3.10676 1.2387Z"
      fill="currentColor"
    />
  </svg>
);
export default TwitterIcon;
