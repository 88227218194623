import { useAppSelector } from '@base/redux/store';
import { ROUTES } from '@constants/routes';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const RestaurantExistence: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const restID = useAppSelector((state) => state.restaurantSettingsState.id);

  if (restID) {
    return <>{children}</>;
  }

  return (
    <div className={'text-center'}>
      <p className={'text-xl'}>{t('first_you_should_create_restaurant')}</p>

      <Link to={ROUTES.restaurant}>{t('create_restaurant')}</Link>
    </div>
  );
};

export default RestaurantExistence;
