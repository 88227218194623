import { SVGProps } from 'react';
const CutleryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4157_38657)">
      <path
        d="M29.05 28.2444C29.6065 28.8009 29.6065 29.6705 29.05 30.227C28.7717 30.4705 28.4239 30.6096 28.0761 30.6096C27.7283 30.6096 27.3804 30.4705 27.1022 30.1922L16.0413 19.1313L5.11957 30.1922C4.8413 30.4705 4.49348 30.6096 4.14565 30.6096C3.79783 30.6096 3.45 30.4705 3.17174 30.1922C2.61522 29.6357 2.61522 28.7661 3.17174 28.2096L14.0935 17.1487L12.2152 15.3052L11.937 15.5835C11.0674 16.4531 9.88478 16.94 8.63261 16.94C7.38043 16.94 6.19783 16.4531 5.32826 15.5835L0.667391 11.027C0.11087 10.4705 0.11087 9.6009 0.667391 9.04438C1.22391 8.48786 2.09348 8.48786 2.65 9.04438L7.27609 13.6357C7.62391 13.9835 8.11087 14.1922 8.63261 14.1922C9.15435 14.1922 9.60652 13.9835 9.98913 13.6357L10.3022 13.3226L4.28478 7.4096C3.72826 6.85307 3.72826 5.98351 4.28478 5.42699C4.8413 4.87047 5.71087 4.87047 6.26739 5.42699L12.2152 11.3748L12.563 11.027C13.2935 10.2966 13.2935 9.07916 12.563 8.31394L7.93696 3.75742C7.38043 3.2009 7.38043 2.33133 7.93696 1.77481C8.49348 1.21829 9.36304 1.21829 9.91957 1.77481L14.5109 6.36612C16.3543 8.2096 16.3543 11.1661 14.5109 13.0096L14.163 13.3574L16.0065 15.2009L28.2848 2.74873C28.5283 2.47047 28.9109 2.33133 29.2587 2.33133C29.6065 2.33133 29.9891 2.47047 30.2326 2.74873C33.3283 5.84438 32.8065 10.1922 28.9109 14.0879L24.2152 18.7835C23.6587 19.34 22.7891 19.34 22.2326 18.7835C21.6761 18.227 21.6761 17.3574 22.2326 16.8009L26.9283 12.1052C29.7457 9.28786 29.6761 7.16612 29.0848 5.87916L17.9891 17.1487L29.05 28.2444Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4157_38657">
        <rect width={32} height={32} fill="currentColor" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
);
export default CutleryIcon;
