import UserAccess from '@base/hoc/UserAccess';
import { setCollapsedActiveKeys } from '@base/redux/features/menuSlice';
import { useAppSelector } from '@base/redux/store';
import MBChevronDownIcon from '@components/icons/MBChevronDownIcon';
import MBListIcon from '@components/icons/MBListIcon';
import DndDraggableDish from '@components/Menus/Dnd/DndDraggableDish';
import { DND_SUBCATEGORY_TYPE, overClasses } from '@components/Menus/Dnd/DndMenuCategories/DndMenuCategories';
import { DndDish, DndMenu } from '@components/Menus/Dnd/DndMenuCategories/useDndMenuCategories';
import MenuCategory from '@components/Menus/MenuCategory';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { subMenuCollapseTheme } from '@pages/MenusPage/menuCollapse.antd';
import { Button, Collapse, CollapseProps, ConfigProvider } from 'antd';
import { FC, ReactNode, useMemo } from 'react';
import { useDispatch } from 'react-redux';

function genExpandBtn(isActive: boolean): ReactNode {
  return (
    <Button
      type={'link'}
      className={'h-[40px] w-[1.8em] justify-center gap-0 bg-grey-5 p-1 text-center hover:bg-grey-5'}
    >
      <MBChevronDownIcon
        className={'m-0 text-2xl text-grey-20'}
        style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
      />
    </Button>
  );
}

const DndDraggableSubcategory: FC<{ subcategory: DndMenu; dishes?: DndDish[] }> = ({ subcategory, dishes }) => {
  const dispatch = useDispatch();
  const { collapsedActiveKeys } = useAppSelector((state) => state.menuSlice);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({
    id: subcategory.dnd_id,
    data: {
      type: DND_SUBCATEGORY_TYPE,
      subcategory,
    },
  });

  const subcategoryDishesDndIds = dishes
    ? useMemo(() => {
        return dishes.map((dish) => dish.dnd_id);
      }, [dishes])
    : [];

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  const isOverClass = isOver ? overClasses : '';

  const renderHeader = () => (
    <div className={'flex items-center gap-1'}>
      <UserAccess roles={['restaurant-manager']}>
        <Button
          type={'text'}
          className={'h-[2em] w-[1.8em] cursor-move justify-center bg-grey-5 p-1 text-md'}
          {...attributes}
          {...listeners}
        >
          <MBListIcon type={'link'} className={'shrink-0 text-base text-grey-20'} />
        </Button>
      </UserAccess>
      <div className={'flex-grow'}>
        <MenuCategory item={subcategory} />
      </div>
    </div>
  );

  const renderDishes = (dishes: DndDish[]) => {
    return dishes
      .filter((dish) => dish.menu.id === subcategory.id)
      .map((dish) => <DndDraggableDish key={dish.dnd_id} dish={dish} />);
  };

  const renderChildren = (dishes: DndDish[]) => (
    <div className={'mx-auto mt-3 flex max-w-[90%] flex-col gap-4'}>
      <SortableContext items={subcategoryDishesDndIds}>
        {dishes && dishes.length > 0 && renderDishes(dishes)}
      </SortableContext>
    </div>
  );

  const collapseItems: CollapseProps['items'] = [
    {
      key: subcategory.id,
      label: renderHeader(),
      children: dishes && renderChildren(dishes),
      showArrow: true,
      collapsible: 'icon',
      destroyInactivePanel: true,
    },
  ];

  // remember opened collapse key
  const handleCollapseChange = (key: string | string[]) => {
    if (Array.isArray(key)) {
      dispatch(setCollapsedActiveKeys(key));
    }
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={'h-[40px] w-full rounded border-2 border-solid border-grey-20 bg-grey-10 opacity-60'}
      ></div>
    );
  }

  return (
    <div ref={setNodeRef} style={style}>
      <ConfigProvider theme={subMenuCollapseTheme}>
        <Collapse
          className={`menu-subcategory text-[16px] ${isOverClass}`}
          bordered={false}
          expandIconPosition={'end'}
          expandIcon={({ isActive }) => genExpandBtn(isActive as boolean)}
          items={collapseItems}
          activeKey={collapsedActiveKeys}
          onChange={handleCollapseChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default DndDraggableSubcategory;
