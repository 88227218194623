import colorsPalette from '@utils/constants/colors';

export const inventoryCollapseTheme = {
  components: {
    Collapse: {
      contentPadding: '16px 24px 16px 24px',
      headerPadding: '24px 24px',
      fontSize: 20,
      colorTextHeading: colorsPalette['indigo40'],
      fontSizeIcon: 20,
      headerBg: 'rgb(255, 255, 255)',
      colorBorder: 'rgba(255, 255, 255, 0)',
      contentBg: 'rgba(255, 255, 255, 0)',
      colorBgContainer: 'rgba(255, 255, 255, 0)',
    },
  },
};
