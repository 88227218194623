import placeholder from '@base/assets/img/hero-restaurant-placeholder.jpg';
import { useAppSelector } from '@base/redux/store';

const RestaurantHero = () => {
  const { heroSrc } = useAppSelector((state) => state.restaurantSettingsState);

  return (
    <div className={'restaurant-preview-hero absolute left-0 top-0 z-0 h-[206px] w-full'}>
      <img className={'h-full w-full object-cover'} src={heroSrc || placeholder} alt="background restaurant image" />
    </div>
  );
};

export default RestaurantHero;
