import { useTranslation } from 'react-i18next';

const PackageErrorPaymentPage = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex h-full w-full'}>
      <p className={'m-auto text-2xl font-bold text-red-30'}>{t('payment_failed')}</p>
    </div>
  );
};

export default PackageErrorPaymentPage;
