import { useGetPromotionByIdQuery } from '@api/promotionApi';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import ClientPromotionDrawer from '@components/Client/ClientPromotions/ClientPromotionDrawer';
import ClientPromotionModal from '@components/Client/ClientPromotions/ClientPromotionModal';
import getQueryParam from '@helpers/getQueryParam';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const ClientNotification = () => {
  const action = getQueryParam('action');
  const notification_id = getQueryParam('notification_id');
  const hash = getQueryParam('hash');

  const { isOrderPlacementPayment } = useAvailableFeatures();

  const [isPromotionDrawerOpened, setIsPromotionDrawerOpened] = useState(false);

  const { data: promotion } = useGetPromotionByIdQuery(
    {
      id: Number(notification_id),
      hash: hash as string,
    },
    {
      skip: !(action === 'notification' && notification_id && hash),
    }
  );

  const handleClose = () => {
    setIsPromotionDrawerOpened(false);
  };

  return (
    <>
      <Outlet />

      {promotion && (
        <>
          <ClientPromotionModal
            isOpened={true}
            title={promotion.name}
            content={promotion.description}
            setIsPromotionDrawerOpened={setIsPromotionDrawerOpened}
          />

          <ClientPromotionDrawer
            isOpened={isPromotionDrawerOpened}
            promotion_id={promotion.id}
            title={promotion.name}
            old_price={promotion.old_price}
            new_price={promotion.new_price}
            selectedOptions={promotion.selected_options}
            selectedExtras={promotion.selected_extras}
            dish={promotion.dish}
            handleClose={handleClose}
            isOrderPlacementPayment={isOrderPlacementPayment}
          />
        </>
      )}
    </>
  );
};

export default ClientNotification;
