import { OnboardingKeys } from '@base/redux/features/userSlice';
import useTour from '@components/UI/Tour/useTour';
import useGetMediaQuery from '@hooks/useGetMediaQuery';
import useSteps from './useSteps';

const useRestaurantSettingsOnboarding = () => {
  const { isXL } = useGetMediaQuery();
  const onboardingKey: OnboardingKeys = 'restaurant_settings';

  const { obStepRef1, obStepRef2, obStepRef3, obStepRef4, steps } = useSteps();
  const { isOnboard, renderTour } = useTour({
    onboardingKey,
    steps,
  });

  const isShowTour = isOnboard && isXL;

  return {
    isShowTour,
    renderTour,
    obStepRef1,
    obStepRef2,
    obStepRef3,
    obStepRef4,
  };
};

export default useRestaurantSettingsOnboarding;
