import MBCloseIcon from '@base/components/icons/MBCloseIcon';
import MenubitRegisterLogo from '@base/components/svg/MenubitRegisterLogo';
import { ROUTES } from '@base/utils/constants/routes';
import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface HeaderRegisterModalProps {
  title: string;
  text: string;
  restaurant: string | null;
  centralDomain: string;
  setIsOpened: (value: boolean) => void;
}

const HeaderRegisterModal: FC<HeaderRegisterModalProps> = ({ title, text, restaurant, centralDomain, setIsOpened }) => {
  const { t } = useTranslation();

  return (
    <div className={'header-modal site-header__modal'}>
      <div
        className={'header-modal__close'}
        onClick={() => {
          setIsOpened(false);
        }}
      >
        <MBCloseIcon />
      </div>
      <div className={'header-modal__header'}>
        <span className="header-modal__title">{title}</span>
      </div>
      <div className={'modal-register'}>
        <span className={'modal-register__text'}>{text}</span>
        <MenubitRegisterLogo width={'101px'} height={'80px'} />
        <Link
          className={'modal-register__button'}
          to={`${centralDomain}${ROUTES.client_registration}?restaurant=${restaurant}`}
        >
          <Button className={'w-full'} type={'primary'}>
            {t('to_register')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default HeaderRegisterModal;
