import { ClientOrder } from '@api/models/Order';
import waitingStatusGif from '@assets/img/cook-hat-animated.gif';
import inProgressStatusGif from '@assets/img/knife-animated.gif';
import { useAppSelector } from '@base/redux/store';
import MBChevronDownIcon from '@components/icons/MBChevronDownIcon';
import MBChevronUpIcon from '@components/icons/MBChevronUpIcon';
import MBEditIcon from '@components/icons/MBEditIcon';
import DishPlaceholderLogo from '@components/svg/DishPlaceholderLogo';
import SecondaryButton from '@components/UI/SecondaryButton';
import { CURRENCY, DATE_FORMAT, DEFAULT_SUBSCRIPTION_PACKAGE_PLAN } from '@constants/constants';
import { ROUTES } from '@constants/routes';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import { Collapse, CollapseProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClientOrderDishList from './ClientOrderDishList';

interface ClientOrdersMonitoringListProps {
  orders: ClientOrder[];
  isNotCompletedOrders?: boolean;
}

const OrderItemHeader: FC<{ order: ClientOrder }> = ({ order }) => {
  const { t } = useTranslation();
  const orderDate = convertServerDateToClient(order.order_date, DATE_FORMAT);

  return (
    <div className={'flex w-full flex-col gap-2'}>
      <div
        className={'flex items-center justify-between gap-9 border-0 border-b-[1px] border-solid border-grey-15 pb-1'}
      >
        <div className={'flex items-center gap-2'}>
          <div
            className={
              'relative flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-full bg-grey-20'
            }
          >
            {order.restaurant?.profile_picture ? (
              <img
                className={'h-full w-full object-cover'}
                src={order.restaurant.profile_picture}
                alt={`${order.restaurant?.name} logo.`}
              />
            ) : (
              <DishPlaceholderLogo width={25} height={25} />
            )}
          </div>
          <span className={'block w-full max-w-[300px] break-all text-base font-bold leading-[120%] text-black-custom'}>
            {order.restaurant?.name}
          </span>
        </div>
        <span></span>
      </div>
      <div className={'flex flex-wrap items-center justify-between gap-x-4 gap-y-2'}>
        <div className={'text-sm leading-[150%] text-black-custom'}>
          <span>
            {t('order_number')}: <span>#{order.id}</span>
          </span>
        </div>
        <span className={'text-xs text-grey-30'}>{orderDate}</span>
      </div>
      {order.status.status === 'completed' ? (
        <div
          className={
            'client-collapse-item-total-cost flex items-center justify-between gap-4 rounded-md bg-white p-4 leading-[150%] text-black-custom'
          }
        >
          <span className={'text-sm'}>{t('total_cost')}:</span>
          <span className={'font-bold leading-[120%]'}>
            {CURRENCY}
            {order.total}
          </span>
        </div>
      ) : (
        <>
          {order.status.status === 'waiting' && (
            <>
              <img className={'m-auto h-[52px] w-[52px] object-cover'} src={waitingStatusGif} alt="order accepted" />
              <span className={'m-auto w-full bg-cyan-10 px-4 py-2 text-center font-bold leading-[120%] text-cyan-40'}>
                {t('order_accepted')}
              </span>
            </>
          )}
          {order.status.status === 'in_progress' && (
            <>
              <img
                className={'m-auto h-[52px] w-[52px] object-cover'}
                src={inProgressStatusGif}
                alt="order in progress"
              />
              <span
                className={'m-auto w-full bg-[#C7DFFF] px-4 py-2 text-center font-bold leading-[120%] text-indigo-40'}
              >
                {t('order_in_preparation')}
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
};

const ClientOrdersMonitoringList: FC<ClientOrdersMonitoringListProps> = ({ orders, isNotCompletedOrders }) => {
  const { t } = useTranslation();
  const clientId = useAppSelector((state) => state.userState.id);
  let collapseItems: CollapseProps['items'];

  if (orders.length > 0) {
    collapseItems = orders.map((order) => {
      return {
        key: `order-item-${order.id}`,
        label: <OrderItemHeader order={order} />,
        children: (
          <>
            <ClientOrderDishList order={order} />
            {/* Hide 'send feedback' button if this is not completed order && restaurant active package !== Free (DEFAULT_SUBSCRIPTION_PACKAGE_PLAN) */}
            {!isNotCompletedOrders && order.restaurant.package_payment.name !== DEFAULT_SUBSCRIPTION_PACKAGE_PLAN && (
              <Link
                to={`${ROUTES.client_order_feedback}?restaurant-id=${order.restaurant.id}&client-id=${clientId}&order-id=${order.id}`}
                className={'mx-auto mt-2 block w-max'}
              >
                <SecondaryButton className={'h-[33px] p-2 text-sm'} type="default" icon={<MBEditIcon fontSize={16} />}>
                  {t('sending_feedback')}
                </SecondaryButton>
              </Link>
            )}
          </>
        ),
      };
    });
  }

  return (
    <>
      {orders && (
        <div className={'flex w-full flex-col gap-4'}>
          {isNotCompletedOrders ? (
            <span className="w-max text-center font-bold leading-[120%] text-grey-30">{t('orders_from_today')}</span>
          ) : (
            <div className={'relative flex items-center justify-between'}>
              <span className={'font-bold leading-[120%] text-grey-30'}>{t('orders_from_previous_days')}</span>
            </div>
          )}
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <MBChevronUpIcon width={'16px'} height={'16px'} />
              ) : (
                <MBChevronDownIcon width={'16px'} height={'16px'} />
              )
            }
            className={`client-order-monitoring-collapse ${isNotCompletedOrders ? 'not-completed' : ''}`}
            items={collapseItems}
          />
        </div>
      )}
    </>
  );
};

export default ClientOrdersMonitoringList;
