import ifExistVerticalScrollPage from '../utils/helpers/ifExistVerticalScrollPage';

export const useModalWindowSize = () => {
  function addBodyClassBeforeModalOpen() {
    if (ifExistVerticalScrollPage()) {
      document.body.classList.add('modal-opened');
    }
  }

  function removeBodyClassBeforeModalOpen() {
    if (ifExistVerticalScrollPage()) {
      document.body.classList.remove('modal-opened');
    }
  }

  return {
    addBodyClassBeforeModalOpen,
    removeBodyClassBeforeModalOpen,
  };
};
