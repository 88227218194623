import { useGetPusherInstance } from '@base/contexts/PusherProvider';
import { decreaseCallsCounter, increaseCallsCounter } from '@base/redux/features/waiterSlice';
import { useAppSelector } from '@base/redux/store';
import { CHANNELS, CHANNELS_EVENTS } from '@constants/channels';
import useCheckRole from '@hooks/useCheckRole';
import { Channel } from 'laravel-echo';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useCallsCounter = () => {
  const dispatch = useDispatch();
  const pusherInstance = useGetPusherInstance();
  const newCallsCounter = useAppSelector((state) => state.waiterSlice.newCallsCounter);
  const { isWaiter } = useCheckRole();
  const workerID = useAppSelector((state) => state.userState.id);

  useEffect(() => {
    let newCallChannel: Channel | null = null;

    if (pusherInstance && workerID && isWaiter) {
      newCallChannel = pusherInstance.private(`${CHANNELS.newCallToWaiter}.${workerID}`);
      newCallChannel.listen(CHANNELS_EVENTS.new_waiter_call, increaseCounter);
      newCallChannel.listen(CHANNELS_EVENTS.undo_waiter_call, decreaseCounter);
    }

    return () => {
      if (newCallChannel) {
        newCallChannel.stopListening(CHANNELS_EVENTS.new_waiter_call);
        newCallChannel.stopListening(CHANNELS_EVENTS.undo_waiter_call);
      }
    };
  }, [pusherInstance, increaseCounter]);

  function increaseCounter() {
    dispatch(increaseCallsCounter());
  }

  function decreaseCounter() {
    dispatch(decreaseCallsCounter());
  }

  return {
    newCallsCounter,
  };
};

export default useCallsCounter;
