import useCheckRole from '@base/hooks/useCheckRole';
import { useGetRestaurantTablesQuery } from '@base/redux/api/restaurantInfoApi';
import { setClientTable } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import useClientMenuOnboarding from '@components/MobileMenu/ClientMenuOnboarding/useClientMenuOnboarding';
import { ROUTES } from '@constants/routes';
import useAuth from '@hooks/useAuth';
import { Button } from 'antd';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Categories from './Categories';
import Hero from './Hero';
import RestaurantCard from './RestaurantCard';

const MobileMenu = () => {
  const { t } = useTranslation();
  const { isClient } = useCheckRole();
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const dispatch = useDispatch();

  const restInfo = useAppSelector((state) => state.restaurantState);
  const restSettings = useAppSelector((state) => state.restaurantSettingsState);

  const heroSrc = isClient ? restInfo.background_picture : restSettings.heroSrc;
  const name = isClient ? restInfo.name : restSettings.name;
  const secondary_title = isClient ? restInfo.secondary_title : restSettings.secondaryTitle;
  const description = isClient ? restInfo.description : restSettings.description;

  const { table_name: selectedTableName, table_id: selectedTableId } = useAppSelector(
    (state) => state.clientSlice.table
  );

  // TODO: call with Ivan and discuss this solution (can we make another req to get table info (for the optimization)???)
  const restTablesGetReq = selectedTableId && isClient ? useGetRestaurantTablesQuery() : null;

  const { isShowTour, renderTour } = useClientMenuOnboarding();

  useLayoutEffect(() => {
    if (isClient && !selectedTableName) {
      navigate(ROUTES.root);
    }
  }, []);

  useEffect(() => {
    const restTables = restTablesGetReq?.data;

    if (!restTables || !selectedTableId) {
      return;
    }

    const selectedTable = restTables.find((table) => table.id === selectedTableId);

    if (!restTablesGetReq?.isLoading && restTablesGetReq?.isSuccess && selectedTable) {
      dispatch(
        setClientTable({
          selected: true,
          table_id: selectedTable.id,
          table_take_away_id: null,
          table_name: selectedTable.name,
          waiter: selectedTable.waiter,
        })
      );
    }
  }, [restTablesGetReq?.data]);

  return (
    <div className={'mobile-menu'}>
      <Hero url={heroSrc} name={name} />

      <div className={'restaurant-card mobile-menu__restaurant-card'}>
        <RestaurantCard>
          <div className={'restaurant-card__title text-lg font-bold leading-[1.2] text-indigo-40'}>
            {secondary_title}
          </div>
          <div className={'restaurant-card__description leading-[1.5] text-grey-30'}>
            <p>{description}</p>
          </div>
        </RestaurantCard>
      </div>

      {isClient && selectedTableName && (
        <div className={'mobile-menu__table-block table-changer-block'}>
          <div className="table-changer-block__title">
            {t('table')}: {selectedTableName}
          </div>
          <Link id={'onboarding-table'} to={ROUTES.root} className="table-changer-block__button">
            <Button>{t('change')}</Button>
          </Link>
        </div>
      )}

      <main className={`${selectedTableName ? 'mb-10 mt-2' : 'my-10'} mobile-menu__categories px-4`}>
        <Categories />
      </main>

      {isClient && isAuth && isShowTour && renderTour()}
    </div>
  );
};

export default MobileMenu;
