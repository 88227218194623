import { WorkHours } from '@base/types/WorkHours';
import { Space, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useRestaurantDayHours, { format } from './useRestaurantDayHours';

interface RestaurantDayHoursProps {
  dayName: string;
  dayKey: string;
  daysHours: WorkHours;
  setDaysHours: React.Dispatch<SetStateAction<WorkHours | null>>;
}

const RestaurantDayHours: FC<RestaurantDayHoursProps> = ({ dayName, dayKey, daysHours, setDaysHours }) => {
  const { t } = useTranslation();
  const { isOpened, startTime, endTime, handleChangeDayAvailable, onChangeStartTime, onChangeEndTime } =
    useRestaurantDayHours({ dayKey, daysHours, setDaysHours });

  return (
    <div className={'flex h-8 items-center justify-start gap-x-10'}>
      <span className={'w-20 text-sm font-bold'}>{dayName}</span>
      <Space direction={'horizontal'}>
        <Switch checked={isOpened} onChange={handleChangeDayAvailable} />
        <span>{t('open')}</span>
      </Space>

      <Space direction={'horizontal'}>
        <span>{t('from')}</span>
        <TimePicker
          disabled={!isOpened}
          value={dayjs(startTime || '00:00', format)}
          onChange={onChangeStartTime}
          defaultValue={dayjs('00:00', format)}
          format={format}
          placeholder={'00:00'}
          className={'max-w-[105px]'}
        />
      </Space>
      <Space direction={'horizontal'}>
        <span>{t('until')}</span>
        <TimePicker
          disabled={!isOpened}
          value={dayjs(endTime || '00:00', format)}
          onChange={onChangeEndTime}
          defaultValue={dayjs('00:00', format)}
          format={format}
          placeholder={'00:00'}
          className={'max-w-[105px]'}
        />
      </Space>
    </div>
  );
};

export default RestaurantDayHours;
