import MBCloseIcon from '@base/components/icons/MBCloseIcon';
import { setIsClientFilteredMenu } from '@base/redux/features/clientSlice';
import { useAppSelector } from '@base/redux/store';
import { MENU_FILTER_ALL_TYPE, MENU_FILTER_SPECIFIC_TYPE } from '@base/utils/constants/constants';
import { Radio, RadioChangeEvent } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface HeaderMenuFilterModalProps {
  setIsOpened: (value: boolean) => void;
}

const HeaderMenuFilterModal: FC<HeaderMenuFilterModalProps> = ({ setIsOpened }) => {
  const { t } = useTranslation();
  const isFilteredMenu = useAppSelector((state) => state.clientSlice.isFilteredMenu);
  const dispatch = useDispatch();

  const handleFilterChange = (e: RadioChangeEvent) => {
    if (e.target.value === MENU_FILTER_SPECIFIC_TYPE) {
      dispatch(setIsClientFilteredMenu(true));
    } else {
      dispatch(setIsClientFilteredMenu(false));
    }
  };

  return (
    <div className={'header-modal header-modal--sm-gap site-header__modal'}>
      <div
        className={'header-modal__close'}
        onClick={() => {
          setIsOpened(false);
        }}
      >
        <MBCloseIcon />
      </div>
      <div className={'header-modal__header'}>
        <span className="header-modal__title">{t('what_to_show')}?</span>
      </div>
      <div className={'modal-menu-filter'}>
        <Radio.Group
          defaultValue={isFilteredMenu ? MENU_FILTER_SPECIFIC_TYPE : MENU_FILTER_ALL_TYPE}
          value={isFilteredMenu ? MENU_FILTER_SPECIFIC_TYPE : MENU_FILTER_ALL_TYPE}
          className={'modal-menu-filter__options'}
          onChange={handleFilterChange}
        >
          <Radio className="modal-menu-filter__option" value={MENU_FILTER_SPECIFIC_TYPE}>
            {t('only_what_suits_me')}
          </Radio>
          <Radio className="modal-menu-filter__option" value={MENU_FILTER_ALL_TYPE}>
            {t('whole_menu')}
          </Radio>
        </Radio.Group>
      </div>
      {/* TODO: show this button when the personal settings page is ready */}
      {/* <Button type="default" icon={<MBUserLoggedInIcon />}>
        {t('personal_settings')}
      </Button> */}
    </div>
  );
};

export default HeaderMenuFilterModal;
