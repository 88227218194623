import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.home'));

  return (
    <div className={'pt-10 text-center'}>
      <h1>Welcome to MenuBit</h1>
    </div>
  );
};

export default HomePage;
