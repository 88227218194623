import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import api from './api';
import { Table } from './models/Table';
import { TableTakeAway } from './models/TableTakeAway';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['WaiterTables', 'TakeAwayTable'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTables: builder.query<Table[], void>({
        query: () => addTenantToEndpoint(`/tables-to-waiters`),
        transformResponse: (response: { data: Table[] }) => response.data,
        providesTags: ['WaiterTables'],
      }),
      getTableById: builder.query<Table, number>({
        query: (id) => addTenantToEndpoint(`/tables-to-waiters/${id}`),
        transformResponse: (response: { data: Table }) => response.data,
      }),
      getTakeAwayTable: builder.query<TableTakeAway, void>({
        query: () => addTenantToEndpoint(`/take-away-tables-to-waiters`),
        transformResponse: (response: { data: TableTakeAway }) => response.data,
        providesTags: ['TakeAwayTable'],
      }),
      createTable: builder.mutation<Table, any>({
        query(data) {
          return {
            url: addTenantToEndpoint(`/tables-to-waiters`),
            method: 'POST',
            body: data,
          };
        },
        transformResponse: (response: { data: Table }) => response.data,
        invalidatesTags: ['WaiterTables'],
      }),
      updateTable: builder.mutation<Table, { data: any; id: number }>({
        query({ data, id }) {
          return {
            url: addTenantToEndpoint(`/tables-to-waiters/${id}`),
            method: 'PUT',
            body: data,
          };
        },
        transformResponse: (response: { data: Table }) => response.data,
        invalidatesTags: ['WaiterTables'],
      }),
      removeTable: builder.mutation<any, number>({
        query(id) {
          return {
            url: addTenantToEndpoint(`/tables-to-waiters/${id}`),
            method: 'DELETE',
          };
        },
        invalidatesTags: ['WaiterTables'],
      }),
      updateTakeAwayTable: builder.mutation<Table, { data: any; id: number }>({
        query({ data, id }) {
          return {
            url: addTenantToEndpoint(`/take-away-tables-to-waiters/${id}`),
            method: 'PATCH',
            body: data,
          };
        },
        transformResponse: (response: { data: Table }) => response.data,
        invalidatesTags: ['TakeAwayTable'],
      }),
    }),
  });

export const {
  useGetTablesQuery,
  useGetTableByIdQuery,
  useGetTakeAwayTableQuery,
  useCreateTableMutation,
  useUpdateTableMutation,
  useRemoveTableMutation,
  useUpdateTakeAwayTableMutation,
} = extendedApiSlice;
