import QRBuilder from '@base/components/QRBuilder';
import RestaurantExistence from '@base/hoc/RestaurantExistence';
import { useSetTitlePage } from '@hooks/useSetTitlePage';
import { useTranslation } from 'react-i18next';

const QRBuilderPage = () => {
  const { t } = useTranslation();
  useSetTitlePage(t('pages.qr_builder'));

  return (
    <RestaurantExistence>
      <QRBuilder />
    </RestaurantExistence>
  );
};

export default QRBuilderPage;
