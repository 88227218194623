import OrdersMonitoringView from '@components/OrdersMonitoring/OrdersMonitoringView';
import { Alert } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useOrdersMonitoring from './useOrdersMonitoring';

const OrdersMonitoring = () => {
  const { t } = useTranslation();
  const {
    orders,
    waiterOrders,
    pagination,
    orderStatuses,
    waiters,
    isLoading,
    isError,
    setPagePagination,
    isWarningNewOrder,
    isWaiters2Tables,
    isPaymentMethod,
  } = useOrdersMonitoring();

  return (
    <>
      {isWarningNewOrder && <Alert message={t('new_order_go_to_first_page')} type="warning" className={'mb-6'} />}

      <OrdersMonitoringView
        orders={orders}
        waiterOrders={waiterOrders}
        pagination={pagination}
        orderStatuses={orderStatuses}
        waiters={waiters}
        isLoading={isLoading}
        isError={isError}
        setPagePagination={setPagePagination}
        isWaiters2Tables={isWaiters2Tables}
        isPaymentMethod={!!isPaymentMethod}
      />
    </>
  );
};

export default memo(OrdersMonitoring);
