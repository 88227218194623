import { OrderItem } from '@api/models/Order';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const KitchenReceiptOrderDetails: FC<{ orderItems: OrderItem[] }> = ({ orderItems }) => {
  const { t } = useTranslation();

  const renderOrderItems = () => {
    return orderItems.map((orderItem) => {
      const options = orderItem.options;
      const extras = orderItem.extras;

      return (
        <tr key={orderItem.id}>
          <td>
            <div className={'mb-1 font-bold'}>{orderItem.dish?.dish_name}</div>

            {options && options.length > 0 && (
              <ul className={'m-0 list-none p-0 text-[8px]'}>
                {orderItem.options?.map((option) => <li key={option.id}>{option.name}</li>)}
              </ul>
            )}

            {extras && extras.length > 0 && (
              <ul className={'m-0 mt-1 list-none p-0'}>
                {orderItem.extras?.map((extra) => <li key={extra.id}>{extra.name} +</li>)}
              </ul>
            )}
          </td>
          <td>{orderItem.quantity}</td>
        </tr>
      );
    });
  };

  return (
    <>
      {t('order_details')}:
      <table className={'table-kitchen-receipt-order-details mt-1 w-full'}>
        <thead>
          <tr>
            <th className={'text-right'}>{t('name')}</th>
            <th>{t('amt')}</th>
          </tr>
        </thead>
        <tbody>{renderOrderItems()}</tbody>
      </table>
    </>
  );
};

export default KitchenReceiptOrderDetails;
