import { useAppSelector } from '@base/redux/store';
import { centralDomain } from '@constants/environments';
import generateRestaurantLink from '@helpers/generateRestaurantLink';

const useOriginalRestHost = () => {
  const role = useAppSelector((state) => state.userState.role);
  const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);
  const restSettingDomain = useAppSelector(
    (state) => state.restaurantSettingsState.domain || state.restaurantState.domain
  );

  const isManager = role === 'restaurant-manager';

  function getOriginalRestHost(route: string): string {
    return isSubdomain && restSettingDomain && !isManager
      ? `${generateRestaurantLink(restSettingDomain)}${route}`
      : `${centralDomain}${route}`;
  }

  return {
    getOriginalRestHost,
  };
};

export default useOriginalRestHost;
