import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isOpened: boolean;
}

const initialState: InitialState = {
  isOpened: false,
};

export const supervisorMobileMenuSettingsSlice = createSlice({
  initialState,
  name: 'supervisorMobileMenuSettings',
  reducers: {
    resetSupMobMenuSetSlice: () => initialState,
    setIsOpened: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
  },
});

export default supervisorMobileMenuSettingsSlice.reducer;

export const { resetSupMobMenuSetSlice, setIsOpened } = supervisorMobileMenuSettingsSlice.actions;
