import { useLazyGetUserQuery } from '@api/userApi';
import { resetCartSlice } from '@base/redux/features/cartSlice';
import { setClientAllergies, setClientDishTypes } from '@base/redux/features/clientSlice';
import { setOnboarding, setRole, setToken, setUser, setUserId } from '@base/redux/features/userSlice';
import { getClientIsOrderSuccessFromCookies } from '@base/utils/helpers/getClientIsOrderSuccessFromCookies';
import { getClientPreferencesFromCookies } from '@base/utils/helpers/getClientPreferencesFromCookies';
import { removeClientIsOrderSuccessFromCookies } from '@base/utils/helpers/removeClientIsOrderSuccessFromCookies';
import { removeClientPreferencesFromCookies } from '@base/utils/helpers/removeClientPreferencesFromCookies';
import checkIsSubdomain from '@helpers/checkIsSubdomain';
import { getTokenFromCookies } from '@helpers/getTokenFromCookies';
import { removeClientOrderFromLocalstorage } from '@helpers/removeClientOrderFromLocalstorage';
import { removeTokenFromCookies } from '@helpers/removeTokenFromCookies';
import { App } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useRouters = () => {
  const { message } = App.useApp();
  const isSubdomain = checkIsSubdomain();
  const cookieToken = getTokenFromCookies();
  const cookieClientPreferences = getClientPreferencesFromCookies();
  const cookieClientIsOrderSuccess = getClientIsOrderSuccessFromCookies();
  const dispatch = useDispatch();
  const [getUserInfo, { data: userInfo, isLoading: isLoadingUserInfo, isError: isErrorUserInfo }] =
    useLazyGetUserQuery();

  // when client is redirected to restaurant domain after login on central domain
  useEffect(() => {
    if (isSubdomain && cookieToken) {
      removeTokenFromCookies();
      dispatch(setToken(cookieToken));
      // TODO: check this endpoint for the client
      //getUserInfo();
    }
  }, [cookieToken, dispatch, getUserInfo]);

  // when there are cookies needed to process some info when the user goes to a subdomain from the central domain
  useEffect(() => {
    if (isSubdomain) {
      if (cookieClientPreferences) {
        const { allergies, dish_types } = JSON.parse(cookieClientPreferences);
        dispatch(setClientAllergies(allergies));
        dispatch(setClientDishTypes(dish_types));
        removeClientPreferencesFromCookies();
      }

      if (cookieClientIsOrderSuccess) {
        removeClientOrderFromLocalstorage();
        removeClientIsOrderSuccessFromCookies();
        dispatch(resetCartSlice());
      }
    }
  }, [cookieClientIsOrderSuccess, cookieClientPreferences]);

  // User info
  useEffect(() => {
    if (!isLoadingUserInfo && !isErrorUserInfo && userInfo) {
      dispatch(setUser(userInfo));
      dispatch(setOnboarding(userInfo.onboarding));
      dispatch(setUserId(userInfo.id));
      dispatch(setRole(userInfo.role));
    }

    if (!isLoadingUserInfo && isErrorUserInfo) {
      dispatch(setRole(null));
      dispatch(setUser(null));
      message.error('error getting user info');
    }
  }, [isLoadingUserInfo, isErrorUserInfo]);

  return {
    isSubdomain,
  };
};

export default useRouters;
