import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WorkerState {
  registrationEmail: string | null;
  registrationRole: string | null;
}

const initialState: WorkerState = {
  registrationEmail: null,
  registrationRole: null,
};

export const workerSlice = createSlice({
  initialState,
  name: 'worker',
  reducers: {
    resetWorkerSlice: () => initialState,
    setWorkerRegistrationData: (state, action: PayloadAction<{ email: string; role: string } | null>) => {
      if (action.payload) {
        state.registrationEmail = action.payload.email;
        state.registrationRole = action.payload.role;
      }
    },
  },
});

export default workerSlice.reducer;

export const { resetWorkerSlice, setWorkerRegistrationData } = workerSlice.actions;
