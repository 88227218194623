import { SVGProps } from 'react';
const GoogleBusinessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.15088 12.0312H29.8492V31.9147H2.15088V12.0312Z" fill="#7584F2" />
    <path d="M16 12.0312H29.8491V31.9147H16V12.0312Z" fill="#606AEA" />
    <path
      d="M22.4544 29.2892C19.3666 29.2892 16.8546 26.7772 16.8546 23.6895C16.8546 20.6018 19.3666 18.0898 22.4544 18.0898C23.5764 18.0898 24.6594 18.4208 25.5863 19.0469L24.0977 21.2503C23.6121 20.9222 23.0439 20.7488 22.4545 20.7488C20.8329 20.7488 19.5137 22.068 19.5137 23.6895C19.5137 25.311 20.833 26.6303 22.4545 26.6303C23.5979 26.6303 24.591 25.9744 25.0771 25.0191H23.0946V22.3601H28.0542V23.6895C28.0542 26.7772 25.5421 29.2892 22.4544 29.2892Z"
      fill="white"
    />
    <path
      d="M15.9999 12.0314L12.0064 12.7645L8.0127 12.0314L8.82316 0.537109H16.6244L15.9999 12.0314Z"
      fill="#4855B7"
    />
    <path
      d="M12.0064 17.1212C9.80072 17.1212 8.01282 15.3332 8.01282 13.1277V12.0312H15.9999V13.1277C15.9999 15.3332 14.2119 17.1212 12.0064 17.1212Z"
      fill="#344293"
    />
    <path
      d="M8.01273 12.0314L4.13954 12.6422L0.0255127 12.0314L3.17495 0.537109H9.32983L8.01273 12.0314Z"
      fill="#7584F2"
    />
    <path
      d="M4.0192 17.1212C1.81354 17.1212 0.0256348 15.3332 0.0256348 13.1277V12.0312H8.01285V13.1277C8.01276 15.3332 6.22476 17.1212 4.0192 17.1212Z"
      fill="#4B60C6"
    />
    <path d="M16 12.0314L19.9936 12.6422L23.9872 12.0314L23.2003 0.537109H16V12.0314Z" fill="#606AEA" />
    <path
      d="M19.9936 17.1212C22.1992 17.1212 23.9871 15.3332 23.9871 13.1277V12.0312H16V13.1277C16 15.3332 17.788 17.1212 19.9936 17.1212Z"
      fill="#4855B7"
    />
    <path d="M23.9871 12.0314L28.2684 12.6422L31.9744 12.0314L28.8249 0.537109H22.67L23.9871 12.0314Z" fill="#27488F" />
    <path
      d="M27.9807 17.1212C30.1864 17.1212 31.9743 15.3332 31.9743 13.1277V12.0312H23.9871V13.1277C23.9872 15.3332 25.7752 17.1212 27.9807 17.1212Z"
      fill="#12254C"
    />
  </svg>
);
export default GoogleBusinessIcon;
