import api from '@api/api';
import MenuDishList from '@api/models/MenuDishList';
import { Pagination } from '@api/models/Pagination';
import { Promotion, PromotionByIdWithDish } from '@api/models/Promotion';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';
import { t } from 'i18next';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Promotion'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPromotions: builder.query<{ data: Promotion[]; meta: Pagination }, number>({
        query: (page) => ({
          url: addTenantToEndpoint('/promotion'),
          params: {
            page,
          },
        }),

        transformResponse: (response: { data: Promotion[]; meta: Pagination }) => ({
          data: response.data,
          meta: response.meta,
        }),
        providesTags: ['Promotion'],
      }),
      createPromotion: builder.mutation<Promotion, void>({
        query(data) {
          return {
            url: addTenantToEndpoint(`/promotion`),
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: ['Promotion'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('server_errors.creating_promotion'));
          }
        },
      }),
      updatePromotion: builder.mutation<any, { id: number; data: Promotion }>({
        query({ id, data }) {
          return {
            url: addTenantToEndpoint(`/promotion/${id}`),
            method: 'PUT',
            body: data,
          };
        },
        invalidatesTags: ['Promotion'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('server_errors.updating_promotion'));
          }
        },
      }),
      removePromotion: builder.mutation<any, number>({
        query(id) {
          return {
            url: addTenantToEndpoint(`/promotion/${id}`),
            method: 'DELETE',
          };
        },
        invalidatesTags: ['Promotion'],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('server_errors.removing_promotion'));
          }
        },
      }),

      getDishesList: builder.query<MenuDishList[], void>({
        query: () => addTenantToEndpoint('/menu-dishes'),
        transformResponse: (response: { data: MenuDishList[] }) => response.data,
      }),

      getPromotionByIdWithDish: builder.query<PromotionByIdWithDish, number>({
        query: (id) => {
          return {
            url: addTenantToEndpoint(`/promotion/${id}`),
            method: 'GET',
          };
        },
        transformResponse: (response: { data: PromotionByIdWithDish }) => response.data,
      }),

      getPromotionById: builder.query<PromotionByIdWithDish, { id: number; hash: string }>({
        query: ({ id, hash }) => {
          return {
            url: addTenantToEndpoint(`/client/push-notification/${id}`),
            method: 'GET',
            params: {
              hash,
            },
          };
        },
        transformResponse: (response: { data: PromotionByIdWithDish }) => response.data,
      }),

      getRestaurantPromotions: builder.query<Promotion[], void>({
        query: () => addTenantToEndpoint('/promotions'),
        transformResponse: (response: { data: Promotion[] }) => response.data,
      }),

      togglePromotion: builder.mutation<any, { id: number; is_active: boolean }>({
        query({ id, is_active }) {
          return {
            url: addTenantToEndpoint(`/promotion/activation/${id}`),
            method: 'PATCH',
            body: { is_active },
          };
        },
        invalidatesTags: ['Promotion'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
            message.success(t('saved'));
          } catch (error) {
            message.error(t('server_errors.toggle_promotion'));
          }
        },
      }),

      sendPromotion: builder.mutation<any, number>({
        query(id) {
          return {
            url: addTenantToEndpoint(`/promotion/send/${id}`),
            method: 'POST',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
            message.success(t('promotion_has_been_sent'));
          } catch (error) {
            message.error(t('server_errors.sending_promotion'));
          }
        },
      }),
    }),
  });

export const {
  useGetDishesListQuery,
  useLazyGetPromotionsQuery,
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
  useRemovePromotionMutation,
  useGetPromotionByIdQuery,
  useLazyGetPromotionByIdQuery,
  useGetRestaurantPromotionsQuery,
  useTogglePromotionMutation,
  useSendPromotionMutation,
  useLazyGetPromotionByIdWithDishQuery,
} = extendedApiSlice;
