import ManagerByGoogleLogin from '@api/models/ManagerByGoogleLogin';
import ManagerByGoogleRegistration from '@api/models/ManagerByGoogleRegistration';
import { User } from '@api/models/User';
import { WorkerRegistration } from '@api/models/Worker';
import getBaseUrlApi from '@helpers/getBaseUrlApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { message } from 'antd';
import { t } from 'i18next';
import { RootState } from '../store';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrlApi(),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userState.token;

      headers.set('accept', 'application/json');
      headers.set('content-type', 'application/json');

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/register',
          method: 'POST',
          body: data,
        };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (errorData: any) {
          if (errorData.error.status === 422) {
            message.warning(t('server_errors.email_has_already_been_taken'));
          } else {
            message.error(t('server_errors.server_error'));
          }
        }
      },
    }),
    registerMangerByGoogle: builder.mutation<any, ManagerByGoogleRegistration>({
      query(data) {
        return {
          url: '/by-gmail/register',
          method: 'POST',
          body: data,
        };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (errorData: any) {
          if (errorData.error.status === 422) {
            message.warning(t('server_errors.email_has_already_been_taken'));
          } else {
            message.error(t('server_errors.server_error'));
          }
        }
      },
    }),
    loginUser: builder.mutation<User, any>({
      query(data) {
        return {
          url: '/login',
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: User }) => response.data,
    }),
    loginManagerByGoogle: builder.mutation<User, ManagerByGoogleLogin>({
      query(data) {
        return {
          url: '/by-gmail/login',
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: User }) => response.data,
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: '/logout',
          method: 'POST',
        };
      },
    }),
    registerWorker: builder.mutation<WorkerRegistration, void>({
      query(data) {
        return {
          url: `/tenant/worker/register`,
          method: 'POST',
          body: data,
        };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (errorData: any) {
          if (errorData.error.status === 422) {
            message.warning(t('server_errors.email_has_already_been_taken'));
          } else {
            message.error(t('server_errors.server_error'));
          }
        }
      },
    }),
    loginWorker: builder.mutation<User, void>({
      query(data) {
        return {
          url: `/tenant/worker/login`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: User }) => response.data,
    }),
    logoutWorker: builder.mutation<void, void>({
      query() {
        return {
          url: '/tenant/worker/logout',
          method: 'POST',
        };
      },
    }),
    forgotPassword: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/forgot-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
    resetPassword: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/reset-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
    forgotPasswordWorker: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/tenant/worker/forgot-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
    resetPasswordWorker: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/tenant/worker/reset-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),

    // TODO: move this to clientApi
    forgotPasswordClient: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/client/forgot-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
    resetPasswordClient: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/client/reset-password',
          method: 'POST',
          body: data,
          mode: 'cors',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
          },
        };
      },
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useRegisterWorkerMutation,
  useLoginWorkerMutation,
  useLogoutWorkerMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useForgotPasswordWorkerMutation,
  useResetPasswordWorkerMutation,
  useForgotPasswordClientMutation,
  useResetPasswordClientMutation,
  useRegisterMangerByGoogleMutation,
  useLoginManagerByGoogleMutation,
} = authApi;
