import { useUpdateDishBySupervisorMutation, useUpdateMobileDishMutation } from '@api/dishApi';
import useCheckRole from '@hooks/useCheckRole';
import { message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useMobileDishSettings = () => {
  const { t } = useTranslation();
  const { isManager } = useCheckRole();
  const query = isManager ? useUpdateMobileDishMutation() : useUpdateDishBySupervisorMutation();
  const [updateDish, { isLoading, isSuccess, isError, error }] = query;

  useEffect(() => {
    if (isSuccess) {
      message.success(t('updated'));
    }

    if (isError) {
      if (error && 'status' in error && error.status === 403) {
        message.error(`${t('server_errors.error_data_not_curren')}. ${t('refresh_page')}`);
      } else {
        message.error(t('error_saving_data'));
      }
    }
  }, [isSuccess, isError]);

  const update = async (id: number, data: any) => {
    await updateDish({ id, data });
  };

  return {
    update,
    isLoading,
  };
};

export default useMobileDishSettings;
