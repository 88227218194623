import { SVGProps } from 'react';
const SmileFaceRatingTwoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.623 40C31.6347 40 40.5615 31.0457 40.5615 20C40.5615 8.9543 31.6347 0 20.623 0C9.61127 0 0.684509 8.9543 0.684509 20C0.684509 31.0457 9.61127 40 20.623 40Z"
      fill="#FF7940"
    />
    <path
      d="M27.873 18.3022C29.4767 18.3022 30.7768 16.9981 30.7768 15.3894C30.7768 13.7807 29.4767 12.4766 27.873 12.4766C26.2692 12.4766 24.9691 13.7807 24.9691 15.3894C24.9691 16.9981 26.2692 18.3022 27.873 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M13.3735 18.3022C14.9773 18.3022 16.2774 16.9981 16.2774 15.3894C16.2774 13.7807 14.9773 12.4766 13.3735 12.4766C11.7698 12.4766 10.4697 13.7807 10.4697 15.3894C10.4697 16.9981 11.7698 18.3022 13.3735 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M13.3744 28.6468C13.0624 28.6468 12.7561 28.4984 12.5657 28.2209C12.2582 27.7737 12.3708 27.1607 12.8166 26.8522C12.897 26.7967 14.8166 25.4849 17.6845 24.8241C21.5166 23.9407 25.2323 24.6421 28.4289 26.8522C28.8747 27.1607 28.9873 27.7731 28.6798 28.2209C28.3723 28.6681 27.7618 28.781 27.3154 28.4725C25.7888 27.4175 24.14 26.7612 22.414 26.5225C21.029 26.3308 19.5854 26.4044 18.1232 26.7419C15.6163 27.3194 13.9456 28.4609 13.9295 28.4725C13.7597 28.59 13.5654 28.6461 13.3737 28.6461L13.3744 28.6468Z"
      fill="currentColor"
    />
  </svg>
);
export default SmileFaceRatingTwoIcon;
