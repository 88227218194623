export const CHANNELS = {
  addOrder: 'orders',
  updateOrder: 'update-order',
  assignWaiter: 'waiter-order',
  newCallToWaiter: 'waiter-call',
};

export const CHANNELS_EVENTS = {
  new_order: 'OrderAdd',
  order_update: 'OrderUpdate',
  order_waiter_change: 'OrderWaiterChange',
  new_waiter_call: 'WaiterCall',
  undo_waiter_call: 'WaiterCallUndo',
};
