import { SVGProps } from 'react';
const SmileFaceRatingFiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.2539 40C31.2656 40 40.1924 31.0457 40.1924 20C40.1924 8.9543 31.2656 0 20.2539 0C9.24219 0 0.31543 8.9543 0.31543 20C0.31543 31.0457 9.24219 40 20.2539 40Z"
      fill="#19B5A8"
    />
    <path
      d="M27.2374 25.2067C24.5845 27.6342 18.7658 31.3975 12.6814 25.1673C12.1237 24.5962 12.5309 23.6328 13.3273 23.6328H26.6314C27.4561 23.6328 27.846 24.6491 27.2368 25.2067H27.2374Z"
      fill="currentColor"
    />
    <path
      d="M27.1145 18.3022C28.7183 18.3022 30.0184 16.9981 30.0184 15.3894C30.0184 13.7807 28.7183 12.4766 27.1145 12.4766C25.5108 12.4766 24.2107 13.7807 24.2107 15.3894C24.2107 16.9981 25.5108 18.3022 27.1145 18.3022Z"
      fill="currentColor"
    />
    <path
      d="M12.6151 18.3022C14.2188 18.3022 15.5189 16.9981 15.5189 15.3894C15.5189 13.7807 14.2188 12.4766 12.6151 12.4766C11.0113 12.4766 9.71118 13.7807 9.71118 15.3894C9.71118 16.9981 11.0113 18.3022 12.6151 18.3022Z"
      fill="currentColor"
    />
  </svg>
);
export default SmileFaceRatingFiveIcon;
