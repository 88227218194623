import { useGetAllergiesQuery, useGetDishTypesQuery } from '@api/publicApi';
import { DishFormFieldType } from '@components/Menus/DishForm/DishForm';
import { Checkbox, Form, Input, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useTranslation } from 'react-i18next';

const IndividualDataStep = () => {
  const { t } = useTranslation();
  const { data: dishTypeList, isLoading: isLoadingTypeList, isError: isErrorTypeList } = useGetDishTypesQuery();
  const { data: allergyList, isLoading: isLoadingAllergyList, isError: isErrorAllergyList } = useGetAllergiesQuery();
  const form = useFormInstance();

  let dishTypes: number[] = [];

  const onChange = (e: CheckboxChangeEvent) => {
    const value = e.target.value as number;

    if (e.target.checked) {
      dishTypes.push(value);
    } else {
      dishTypes = dishTypes.filter((item) => item !== value);
    }

    if (dishTypes.length === 0) {
      form.setFieldValue('dish_types', null);
    } else {
      form.setFieldValue('dish_types', dishTypes);
    }
  };

  return (
    <>
      {/* TODO: translate */}
      {(isLoadingTypeList || isLoadingAllergyList) && <span>{t('loading')}</span>}
      {isErrorTypeList && <span>Error getting data type list</span>}
      {isErrorAllergyList && <span>Error getting data allergy list</span>}
      {dishTypeList && allergyList && (
        <>
          <Form.Item<DishFormFieldType> label={t('allergies')} name={'allergies'}>
            <Select
              mode="multiple"
              allowClear
              placeholder={t('allergies_placeholder')}
              className={'select-checkbox w-full'}
              popupClassName={'select-checkbox-list'}
            >
              {allergyList &&
                allergyList.map((allergy, i) => (
                  <Select.Option key={`allergy-${i}`} value={allergy.id}>
                    {allergy.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item name={'dish_types'} hidden>
            <Input />
          </Form.Item>

          <div className={'mt-6 flex flex-col'}>
            {dishTypeList.map((dishType, i) => (
              <>
                {dishType.code !== 'no_type' && (
                  <div key={`dish-type-${i}`}>
                    <Form.Item name={dishType.name} valuePropName="checked">
                      <Checkbox value={dishType.id} onChange={onChange}>
                        {t(dishType.name)}
                      </Checkbox>
                    </Form.Item>
                  </div>
                )}
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default IndividualDataStep;
