import { COOKIE_USER_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const removeUserFromCookies = () => {
  Cookies.remove(COOKIE_USER_KEY, {
    path: '/',
    domain,
  });
};
