import MBMessageSquareIcon from '@components/icons/MBMessageSquareIcon';
import PromotionCard from '@components/Promotions/PromotionCard';
import PromotionModal from '@components/Promotions/PromotionModal';
import usePromotions, { HandleEditProps } from '@components/Promotions/usePromotions';
import FullWidthButton from '@components/UI/FullWidthButton';
import Pagination from '@components/UI/Pagination';
import SimpleSection from '@components/UI/SimpleSection';
import { Col, Row } from 'antd';
import { PaginationProps } from 'antd/es/pagination/Pagination';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const DISH_ID_NAME_FIELD = 'dish_id';

const Promotions = () => {
  const { t } = useTranslation();

  const {
    cards,
    isGetting,
    form,
    isError,
    isOpen,
    modal,
    contextHolder,
    setIsOpen,
    setFormValues,
    isShowPagination,
    pagination,
    handleChangePage,
  } = usePromotions();

  const paginationConfig: PaginationProps = pagination
    ? {
        showSizeChanger: false,
        pageSize: pagination.per_page,
        current: pagination.current_page,
        total: pagination.total,
        onChange: handleChangePage,
      }
    : {};

  const handleCreatePromotion = () => {
    form.resetFields();
    setIsOpen(true);
  };

  const memoSetValues = useCallback((data: HandleEditProps) => {
    setFormValues(data);
  }, []);

  const memoSetIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const renderPromotionCards = () => {
    if (!cards || !cards.length) {
      return <p>{t('there_are_no_promotions')}</p>;
    }

    return (
      <Row className={'mb-4'} gutter={[16, 16]}>
        {cards.map((card, i) => {
          let isPromotionAvailable = false;

          // check if promotion is available
          if (card.dish) {
            isPromotionAvailable =
              card.dish.is_inventory_unlimited || (card.dish.is_portion_available && card.dish.inventory_quantity > 0);
          }

          return (
            <Col key={`push-card-${i}`} xs={24} sm={12} xl={8}>
              <PromotionCard
                form={form}
                id={card.id}
                title={card.name}
                description={card.description}
                modal={modal}
                is_active={card.is_active}
                isPromotionAvailable={isPromotionAvailable}
                setIsOpen={memoSetIsOpen}
                setFormValues={memoSetValues}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <SimpleSection title={t('definitions_promotions_benefits')}>
        {isError ? <div>{t('error')}</div> : isGetting ? <div>{t('loading')}</div> : renderPromotionCards()}
      </SimpleSection>

      <FullWidthButton onClick={handleCreatePromotion}>
        {t('add_new_message')}
        <MBMessageSquareIcon />
      </FullWidthButton>

      {isShowPagination && (
        <div className={'mt-6 text-center'}>
          <Pagination {...paginationConfig} />
        </div>
      )}

      {isOpen && <PromotionModal isOpen={isOpen} form={form} setIsOpen={setIsOpen} setFormValues={setFormValues} />}

      {contextHolder}
    </>
  );
};

export default Promotions;
