import MBUserAddIcon from '@components/icons/MBUserAddIcon';
import FullWidthButton from '@components/UI/FullWidthButton';
import SimpleSection from '@components/UI/SimpleSection';
import useWorkers from '@components/Workers/useWorkers';
import WorkerModal from '@components/Workers/WorkerModal';
import WorkersTable from '@components/Workers/WorkersTable';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

const Workers = () => {
  const { t } = useTranslation();
  const {
    form,
    isModalOpened,
    setIsModalOpened,
    workers,
    handleAddUser,
    handleCreate,
    handleUpdate,
    handleRemove,
    isLoading,
    isGetting,
    isGettingError,
  } = useWorkers();

  return (
    <>
      <SimpleSection title={t('pages.users')}>
        {isGetting ? (
          <p>{t('loading')}</p>
        ) : isGettingError ? (
          <p>{t('error')}</p>
        ) : (
          workers && (
            <Card className={'mb-6'}>
              <WorkersTable
                setIsModalOpened={setIsModalOpened}
                form={form}
                workers={workers}
                handleRemove={handleRemove}
              />
            </Card>
          )
        )}

        <FullWidthButton onClick={handleAddUser}>
          {t('add_user')} <MBUserAddIcon />
        </FullWidthButton>
      </SimpleSection>

      <WorkerModal
        isModalOpen={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        form={form}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
      />
    </>
  );
};

export default Workers;
