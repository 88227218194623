import { useLoginManagerByGoogleMutation } from '@api/authApi';
import { PackagePayment } from '@api/models/SubscriptionPackage';
import { User } from '@api/models/User';
import { setRestId, setRestPackage } from '@base/redux/features/restaurantSettingsSlice';
import { setOnboarding, setRestaurantId, setRole, setToken, setUser } from '@base/redux/features/userSlice';
import { useAppDispatch, useAppSelector } from '@base/redux/store';
import DecodedGoogleCredentials from '@base/types/DecodedGoogleCredentials';
import { ROUTES } from '@constants/routes';
import encodeBase64 from '@helpers/encodeBase64';
import { setRoleToCookies } from '@helpers/setRoleToCookies';
import setUserToCookie from '@helpers/setUserToCookie';
import useAuth from '@hooks/useAuth';
import { CredentialResponse } from '@react-oauth/google';
import { App } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const useLoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = App.useApp();
  const { isAuth, restaurantId } = useAuth();
  const isSubdomain = useAppSelector((state) => state.commonSlice.isSubdomain);

  const [loginByGoogle, { isLoading, isSuccess, isError, data }] = useLoginManagerByGoogleMutation();

  async function initLoggedUser(data: User) {
    const { remember_token, role, restaurant_id, onboarding, package_payment: subscription_pack } = data;

    await dispatch(setToken(remember_token));
    await dispatch(setRole(role));
    await dispatch(setUser(data));
    await dispatch(setRestaurantId(restaurant_id));
    await dispatch(setRestId(restaurant_id));
    await dispatch(setOnboarding(onboarding));
    await dispatch(setRestPackage(subscription_pack as PackagePayment));

    await setUserToCookie({
      id: data.id,
      role: data.role,
      token: data.remember_token,
      restaurant_id: data.restaurant_id,
    });

    await setRoleToCookies('manager');
  }

  const onGoogleSuccess = async (response: CredentialResponse) => {
    const decodedCredentials: DecodedGoogleCredentials | null = response.credential
      ? jwtDecode(response.credential)
      : null;

    if (!decodedCredentials) {
      return;
    }

    await loginByGoogle({
      email: decodedCredentials.email,
      google_accessor: encodeBase64(decodedCredentials.sub),
    });
  };

  const onGoogleError = () => {
    message.error(t('error'));
  };

  // TODO: remove it after remaking routes
  useLayoutEffect(() => {
    if (isAuth) {
      const from =
        ((location.state as any)?.from.pathname as string) || restaurantId ? ROUTES.dashboard : ROUTES.restaurant;

      navigate(from);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      const result = initLoggedUser(data);

      result.then(() => {
        const from = restaurantId ? ROUTES.dashboard : ROUTES.restaurant;

        navigate(from);
      });
    }

    if (!isLoading && isError) {
      message.error(t('error'));
    }
  }, [isLoading, isSuccess, isError]);

  return {
    t,
    isSubdomain,
    initLoggedUser,
    onGoogleSuccess,
    onGoogleError,
  };
};

export default useLoginForm;
