import RestaurantTheme from '@base/hoc/RestaurantTheme';
import ClientPromotions from '@components/Client/ClientPromotions';
import { Outlet } from 'react-router-dom';

const ClientNotificationWrapper = () => {
  return (
    <>
      <Outlet />
      <RestaurantTheme>
        <ClientPromotions />
      </RestaurantTheme>
    </>
  );
};

export default ClientNotificationWrapper;
