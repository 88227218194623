import { useLoginUserMutation } from '@api/authApi';
import useAuth from '@hooks/useAuth';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useSimpleLoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { restaurantId } = useAuth();
  const { message } = App.useApp();
  const [loginUser, { isLoading, isError, error, isSuccess, data }] = useLoginUserMutation();
  const onFinish = async (values: any) => {
    await loginUser(values);
  };

  return {
    t,
    message,
    navigate,
    form,
    restaurantId,
    onFinish,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  };
};

export default useSimpleLoginForm;
