import { useLoginClientByGoogleMutation } from '@api/clientApi';
import { Client } from '@api/models/Client';
import { User } from '@api/models/User';
import { setClientAllergies, setClientDishTypes } from '@base/redux/features/clientSlice';
import { setOnboarding, setRole, setToken, setUser, setUserId } from '@base/redux/features/userSlice';
import { useAppDispatch, useAppSelector } from '@base/redux/store';
import DecodedGoogleCredentials from '@base/types/DecodedGoogleCredentials';
import { COOKIE_CLIENT_PREFERENCES_KEY, COOKIE_TOKEN_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import { ROUTES } from '@constants/routes';
import encodeBase64 from '@helpers/encodeBase64';
import generateRestaurantLink from '@helpers/generateRestaurantLink';
import getQueryParam from '@helpers/getQueryParam';
import { setRoleToCookies } from '@helpers/setRoleToCookies';
import setUserToCookie from '@helpers/setUserToCookie';
import { CredentialResponse } from '@react-oauth/google';
import { App } from 'antd';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useClientLoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const paymentUrlParam = getQueryParam('payment');
  const restaurantDomain = useAppSelector((state) => state.commonSlice.restaurantDomain4Client);
  const [loginByGoogle, { isLoading, isSuccess, isError, data }] = useLoginClientByGoogleMutation();
  const redirectRestaurantLink = generateRestaurantLink(restaurantDomain);

  async function initLoggedClient(data: Client) {
    const { id, remember_token, role, allergies, dish_types, onboarding } = data;

    const userData: User = {
      ...data,
      restaurant_id: null,
      restaurant_domain: '',
      package_payment: null,
    };
    const clientPreferences = { allergies, dish_types };

    await dispatch(setClientAllergies(data.allergies));
    await dispatch(setClientDishTypes(data.dish_types));
    await dispatch(setUserId(id));
    await dispatch(setUser(userData));
    await dispatch(setRole(role));
    await dispatch(setToken(remember_token));
    await dispatch(setOnboarding(onboarding));

    await setUserToCookie({
      id: data.id,
      role: data.role,
      token: data.remember_token,
    });

    await Cookies.set(COOKIE_TOKEN_KEY, remember_token, {
      expires: 1,
      path: '/',
      domain,
    });

    await setRoleToCookies('client');

    await Cookies.set(COOKIE_CLIENT_PREFERENCES_KEY, JSON.stringify(clientPreferences), {
      expires: 1,
      path: '/',
      domain,
    });
  }

  function redirectAfterLogin() {
    navigate(ROUTES.loading);

    setTimeout(() => {
      if (paymentUrlParam && paymentUrlParam === 'true') {
        window.location.href = ROUTES.client_payment;
      } else {
        window.location.href = redirectRestaurantLink;
      }
    }, 200);
  }

  const onGoogleSuccess = async (response: CredentialResponse) => {
    const decodedCredentials: DecodedGoogleCredentials | null = response.credential
      ? jwtDecode(response.credential)
      : null;

    if (!decodedCredentials) {
      return;
    }

    await loginByGoogle({
      email: decodedCredentials.email,
      google_accessor: encodeBase64(decodedCredentials.sub),
    });
  };

  const onGoogleError = () => {
    message.error(t('error'));
  };

  useLayoutEffect(() => {
    if (!isLoading && isSuccess && data) {
      const result = initLoggedClient(data);

      result.then(() => {
        redirectAfterLogin();
      });
    }

    if (!isLoading && isError) {
      message.error(t('server_errors.bed_credentials'));
    }
  }, [isLoading]);

  return {
    t,
    initLoggedClient,
    redirectAfterLogin,
    restaurantDomain,
    onGoogleSuccess,
    onGoogleError,
  };
};

export default useClientLoginForm;
