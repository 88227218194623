import CheckParamRestaurant from '@base/hoc/CheckParamRestaurant';
import CheckParamWorker from '@base/hoc/CheckParamWorker';
import ClientNotification from '@base/hoc/ClientNotification';
import ClientPromotionsWrapper from '@base/hoc/ClientPromotionsWrapper';
import useAvailableFeatures from '@base/hooks/useAvailableFeatures';
import ClientForgotPasswordPage from '@base/pages/ClientForgotPasswordPage';
import ClientOrderFeedbackPage from '@base/pages/ClientOrderFeedbackPage';
import ClientOrdersMonitoringPage from '@base/pages/ClientOrdersMonitoringPage';
import ClientResetPasswordPage from '@base/pages/ClientResetPasswordPage';
import ForgotPasswordPage from '@base/pages/ForgotPosswordPage';
import OrdersMonitoringPage from '@base/pages/OrdersMonitoringPage';
import QRBuilderPage from '@base/pages/QRBuilderPage';
import ResetPasswordPage from '@base/pages/ResetPasswordPage';
import WaiterTablesPage from '@base/pages/WaiterTablesPage/WaiterTablesPage';
import WorkerForgotPasswordPage from '@base/pages/WorkerForgotPasswordPage';
import WorkerResetPasswordPage from '@base/pages/WorkerResetPasswordPage';
import { useAppSelector } from '@base/redux/store';
import useRouters from '@base/Routers/useRouters';
import { ROUTES } from '@constants/routes';
import useAuth from '@hooks/useAuth';
import RestaurantLayout from '@layout/RestaurantLayout';
import AccountSettingsPage from '@pages/AccountSettingsPage';
import ClientBindingTablePage from '@pages/ClientBindingTablePage';
import ClientLoginPage from '@pages/ClientLoginPage';
import ClientOrdersPage from '@pages/ClientOrdersPage';
import ClientPaymentPage from '@pages/ClientPaymentPage';
import ClientPaymentSuccessPage from '@pages/ClientPaymentSuccessPage';
import ClientRegistrationPage from '@pages/ClientRegistrationPage';
import GetOrderClientPage from '@pages/GetOrderClientPage';
import HomePage from '@pages/HomePage';
import InventoryPage from '@pages/InventoryPage';
import LoadingPage from '@pages/LoadingPage';
import PackageErrorPaymentPage from '@pages/PackageErrorPaymentPage';
import PackagesPage from '@pages/PackagesPage';
import PackageSuccessPaymentPage from '@pages/PackageSuccessPaymentPage';
import PromotionsPage from '@pages/PromotionsPage';
import RegistrationPage from '@pages/RegistrationPage';
import RestaurantMenuPage from '@pages/RestaurantMenuPage';
import TransactionsPage from '@pages/TransactionsPage';
import WorkerLoginPage from '@pages/WorkerLoginPage';
import WorkerRegistrationPage from '@pages/WorkerRegistrationPage';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout';
import AuthLayout from '../layout/AuthLayout';
import Components from '../pages/Components';
import DashboardPage from '../pages/DashboardPage';
import LoginPage from '../pages/LoginPage';
import MenusPage from '../pages/MenusPage';
import MobileMenuPreviewPage from '../pages/MobileMenuPreviewPage';
import NoMatchPage from '../pages/NoMatchPage';
import RestaurantSettingsPage from '../pages/RestaurantSettingsPage';
import UnauthorizedPage from '../pages/UnauthorizedPage';
import WorkersPage from '../pages/WorkersPage';

const Routers = () => {
  const { isSubdomain } = useRouters();
  const { isAuth, role } = useAuth();
  // TODO: Check if it can be used here (this causes an extra rerender)
  const features = useAvailableFeatures();
  const isActiveRestaurant = useAppSelector((state) => state.restaurantSettingsState.isActive);

  return (
    <Routes>
      <Route path={ROUTES.package_payment_success} element={<PackageSuccessPaymentPage />} />
      <Route path={ROUTES.package_payment_error} element={<PackageErrorPaymentPage />} />

      <Route element={<Layout />}>
        <Route path={ROUTES.components} element={<Components />} />
        <Route path={ROUTES.client_payment} element={<ClientPaymentPage />} />
        <Route path={ROUTES.client_payment_success} element={<ClientPaymentSuccessPage />} />

        {/* TENANT DOMAIN */}
        {isSubdomain ? (
          <>
            {!isAuth ? (
              <>
                <Route index path={ROUTES.root} element={<ClientBindingTablePage />} />
                <Route path={ROUTES.restaurant_menu} element={<RestaurantMenuPage />} />
                <Route path={ROUTES.restaurant_menu} element={<RestaurantMenuPage />} />
                <Route path={ROUTES.get_order} element={<GetOrderClientPage />} />

                <Route element={<AuthLayout />}>
                  <Route path={ROUTES.worker_login} element={<WorkerLoginPage />} />
                  <Route path={ROUTES.worker_forgot_password} element={<WorkerForgotPasswordPage />} />
                  <Route path={ROUTES.reset_password} element={<WorkerResetPasswordPage />} />

                  <Route element={<CheckParamWorker />}>
                    <Route path={ROUTES.worker_registration} element={<WorkerRegistrationPage />} />
                  </Route>
                </Route>
              </>
            ) : (
              <>
                {role === 'restaurant-manager' && <Route path={ROUTES.root} element={<RestaurantMenuPage />} />}

                {role === 'client' && (
                  <Route element={<ClientNotification />}>
                    <Route element={<ClientPromotionsWrapper />}>
                      <Route index path={ROUTES.root} element={<ClientBindingTablePage />} />
                      <Route path={ROUTES.restaurant_menu} element={<RestaurantMenuPage />} />
                      <Route path={ROUTES.get_order} element={<GetOrderClientPage />} />
                    </Route>
                  </Route>
                )}

                {role === 'supervisor' && (
                  <Route element={<RestaurantLayout />}>
                    {features.isPushNotifications && (
                      <Route path={ROUTES.cabinet_promotions} element={<PromotionsPage />} />
                    )}
                    {features.isInventory && <Route path={ROUTES.inventory} element={<InventoryPage />} />}
                  </Route>
                )}

                {role === 'supervisor' || role === 'waiter' ? (
                  <Route element={<RestaurantLayout />}>
                    {role === 'supervisor' && <Route path={ROUTES.root} element={<DashboardPage />} />}
                    {role === 'supervisor' && <Route path={ROUTES.dashboard} element={<DashboardPage />} />}
                    {role === 'supervisor' && <Route path={ROUTES.tables} element={<WaiterTablesPage />} />}
                    {role === 'waiter' && <Route path={ROUTES.root} element={<MenusPage />} />}
                    <Route path={ROUTES.account_settings} element={<AccountSettingsPage />} />

                    <Route path={ROUTES.menus} element={<MenusPage />} />
                    {features.isOrderPlacementPayment && (
                      <Route path={ROUTES.orders} element={<OrdersMonitoringPage />} />
                    )}
                    <Route path={ROUTES.client_orders} element={<ClientOrdersMonitoringPage />} />
                  </Route>
                ) : (
                  // While the role is initializing
                  <Route path={ROUTES.root} element={<p>Loading...</p>} />
                )}
              </>
            )}
          </>
        ) : (
          /* CENTRAL DOMAIN */
          <>
            {!isAuth ? (
              <>
                <Route index path={ROUTES.root} element={<HomePage />} />

                <Route element={<AuthLayout />}>
                  <Route path={ROUTES.login} element={<LoginPage />} />
                  <Route path={ROUTES.registration} element={<RegistrationPage />} />
                  <Route path={ROUTES.forgot_password} element={<ForgotPasswordPage />} />
                  <Route path={ROUTES.reset_password} element={<ResetPasswordPage />} />

                  <Route element={<CheckParamRestaurant />}>
                    <Route path={ROUTES.client_registration} element={<ClientRegistrationPage />} />
                    <Route path={ROUTES.client_login} element={<ClientLoginPage />} />
                  </Route>
                  <Route path={ROUTES.client_forgot_password} element={<ClientForgotPasswordPage />} />
                  <Route path={ROUTES.client_reset_password} element={<ClientResetPasswordPage />} />
                </Route>
                <Route path={ROUTES.client_orders} element={<ClientOrdersPage />} />
              </>
            ) : (
              <>
                {(role === 'waiter' || role === 'supervisor') && <Route path={ROUTES.root} element={<HomePage />} />}

                {role === 'restaurant-manager' ? (
                  <>
                    <Route element={<RestaurantLayout />}>
                      {isActiveRestaurant || isActiveRestaurant === null ? (
                        <>
                          <Route index path={ROUTES.root} element={<RestaurantSettingsPage />} />
                          <Route path={ROUTES.restaurant} element={<RestaurantSettingsPage />} />
                          <Route path={ROUTES.dashboard} element={<DashboardPage />} />
                          <Route path={ROUTES.menus} element={<MenusPage />} />
                          {features.availableWorkers.length > 1 && (
                            <Route path={ROUTES.workers} element={<WorkersPage />} />
                          )}
                          <Route path={ROUTES.qr_builder} element={<QRBuilderPage />} />
                          <Route path={ROUTES.menu_preview} element={<MobileMenuPreviewPage />} />
                          <Route path={ROUTES.tables} element={<WaiterTablesPage />} />
                          {features.isOrderPlacementPayment && (
                            <Route path={ROUTES.orders} element={<OrdersMonitoringPage />} />
                          )}
                          {features.isPushNotifications && (
                            <Route path={ROUTES.cabinet_promotions} element={<PromotionsPage />} />
                          )}
                          {features.isInventory && <Route path={ROUTES.inventory} element={<InventoryPage />} />}
                        </>
                      ) : (
                        <Route path={ROUTES.root} element={<PackagesPage />} />
                      )}

                      <Route path={ROUTES.account_settings} element={<AccountSettingsPage />} />
                      <Route path={ROUTES.packages} element={<PackagesPage />} />
                      <Route path={ROUTES.transactions} element={<TransactionsPage />} />
                    </Route>
                  </>
                ) : role === 'client' ? (
                  <>
                    <Route index path={ROUTES.root} element={<HomePage />} />
                    <Route path={ROUTES.client_orders} element={<ClientOrdersPage />} />
                    <Route path={ROUTES.client_order_feedback} element={<ClientOrderFeedbackPage />} />
                    <Route path={ROUTES.account_settings} element={<AccountSettingsPage />} />
                  </>
                ) : (
                  // While there is a redirect to restaurant domain
                  <Route path={ROUTES.client_login} element={<p>Loading...</p>} />
                )}
              </>
            )}
          </>
        )}

        <Route path={ROUTES.unauthorized} element={<UnauthorizedPage />} />
        <Route path={ROUTES.loading} element={<LoadingPage />} />
        <Route path={'*'} element={<NoMatchPage />} />
      </Route>
    </Routes>
  );
};

export default Routers;
