import { useToggleInventoryDishMutation } from '@api/inventoryApi';
import { InventoryDish } from '@api/models/Inventory';
import BluredUnavailableFeature from '@base/components/UI/BluredUnavailableFeature/BluredUnavailableFeature';
import InventoryModal from '@components/Inventory/InventoryModal';
import EditIconButton from '@components/UI/EditIconButton';
import { INVENTORY_LIMIT_LOW, INVENTORY_LIMIT_SUFFICIENT } from '@constants/constants';
import { Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function getInventoryStatusBgColorClass(count: number): string {
  let statusColor = '';

  if (count <= INVENTORY_LIMIT_LOW) {
    statusColor = 'bg-red-20';
  } else if (count <= INVENTORY_LIMIT_SUFFICIENT) {
    statusColor = 'bg-orange-20';
  } else if (count > INVENTORY_LIMIT_SUFFICIENT) {
    statusColor = 'bg-green-20';
  }

  return statusColor;
}

interface InventoryDishProps {
  id: number;
  count: number;
  isUnlimited: boolean;
  name: string;
  isAvailable: boolean;
  isEditable?: boolean;
  isSwitchable?: boolean;
}

interface InventoryDishListProps {
  dishList: InventoryDish[];
  limit?: number;
  isDashboard?: boolean;
  isUnavailable?: boolean;
}

const InventoryDishList = ({ dishList, limit, isDashboard, isUnavailable = false }: InventoryDishListProps) => {
  const { t } = useTranslation();

  const sortedDishList = [...dishList].sort((a, b) => {
    if (a.is_inventory_unlimited && !b.is_inventory_unlimited) {
      return 1;
    }
    if (!a.is_inventory_unlimited && b.is_inventory_unlimited) {
      return -1;
    }

    return a.inventory_quantity - b.inventory_quantity;
  });

  if (limit) {
    sortedDishList.splice(limit);
  }

  return (
    <div className={isUnavailable ? 'relative min-h-[257px]' : ''}>
      <header className={'mb-2 flex text-sm font-bold text-grey-40'}>
        <div className={'flex-grow'}>{t('dish')}</div>
        <div className={'w-[60px]'}>{t('amount')}</div>
      </header>
      <div className={'flex flex-col gap-1'}>
        {sortedDishList.map((dish) => (
          <InventoryDishList.Item
            key={`inventory-dish-${dish.id}`}
            id={dish.id}
            name={dish.dish_name}
            count={dish.inventory_quantity}
            isUnlimited={dish.is_inventory_unlimited}
            isAvailable={dish.is_portion_available}
            isEditable={!isDashboard}
            isSwitchable={!isDashboard}
          />
        ))}
      </div>
      {isUnavailable && <BluredUnavailableFeature />}
    </div>
  );
};

InventoryDishList.Item = function InventoryDishListItem({
  id,
  name,
  count,
  isUnlimited,
  isAvailable,
  isEditable,
  isSwitchable,
}: InventoryDishProps) {
  const [form] = useForm();
  const [isOpened, setIsOpened] = useState(false);

  const statusBgColor = !isUnlimited ? getInventoryStatusBgColorClass(count) : 'bg-grey-15';
  const notAvailableClass = isAvailable ? '' : 'opacity-50';

  const [toggleInventoryDish] = useToggleInventoryDishMutation();

  const handleAvailable = async (checked: boolean) => {
    await toggleInventoryDish({ id, is_portion_available: checked });
  };

  return (
    <>
      <div className={'flex items-center gap-1'}>
        <div className={`flex h-[40px] flex-grow items-center bg-grey-5 p-2 text-sm ${notAvailableClass}`}>{name}</div>
        <div
          className={`flex h-[40px] w-[60px] flex-shrink-0 items-center justify-center p-2 text-sm ${statusBgColor} ${notAvailableClass}`}
        >
          {isUnlimited ? String.fromCharCode(8734) : count}
        </div>
        {isEditable && <EditIconButton className={'flex-shrink-0'} onClick={() => setIsOpened(true)} />}
        {isSwitchable && <Switch size={'small'} defaultChecked={isAvailable} onChange={handleAvailable} />}
      </div>

      {isOpened && (
        <InventoryModal
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          id={id}
          name={name}
          count={count}
          isUnlimited={isUnlimited}
          form={form}
        />
      )}
    </>
  );
};

export default InventoryDishList;
