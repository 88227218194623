import DishPreview from '@components/DishPreview';
import { CURRENCY } from '@constants/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../UI/Heading';

interface DishInfoCardProps {
  title: string;
  description?: string;
  price?: number;
  imgUrl: string | null;
  options?: any[] | null;
  extras?: any[] | null;
  cardClassName?: string;
}

const DishInfoCard: FC<DishInfoCardProps> = ({ title, description, price, imgUrl, options, extras, cardClassName }) => {
  const { t } = useTranslation();

  return (
    <div className={`dish-info-card ${cardClassName ? cardClassName : ''}`}>
      <div className={'dish-info-card__container'}>
        <div className={'dish-info-card__text-content'}>
          <Heading className={'dish-info-card__title'}>{title}</Heading>
          {description && <p className={'dish-info-card__description'}>{description}</p>}

          {(options || extras) && <span className={'dish-info-card__options-extras'}>{t('customizable')}</span>}
          {price && (
            <Heading className={'dish-info-card__price'}>
              {CURRENCY}
              {price}
            </Heading>
          )}
        </div>
        <div className={'dish-info-card__image-block'}>
          <div className={'dish-info-card__image'}>
            <DishPreview src={imgUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DishInfoCard;
