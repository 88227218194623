import { useEditFeedbackMutation, useGetFeedbackByOrderIdQuery, useSendFeedbackMutation } from '@api/feedbackApi';
import { useGetRestaurantInfoByIdQuery } from '@api/restaurantInfoApi';
import orderFeedbackHeroImg from '@assets/img/bg-header-auth-form.jpg';
import { ROUTES } from '@constants/routes';
import getQueryParam from '@helpers/getQueryParam';
import { Button, ButtonProps, Form, FormInstance, Input, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import GoogleBusinessIcon from '../../icons/GoogleBusinessIcon';
import OrderFeedbackRatingRadio from './OrderFeedbackRatingRadio';
import SuccessfulOrderFeedback from './SuccessfulOrderFeedback';

interface SubmitButtonProps extends ButtonProps {
  formInstance: FormInstance<any>;
}

const SubmitButton: FC<SubmitButtonProps> = ({ formInstance, ...props }) => {
  const [submittable, setSubmittable] = useState(false);
  const { t } = useTranslation();

  // Watch all form values
  const values = useWatch([], formInstance);

  useEffect(() => {
    formInstance.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  return (
    <Button
      className={'h-[44px] w-full justify-center'}
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      {...props}
    >
      {t('sending')}
    </Button>
  );
};

const ClientOrderFeedback = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const restaurantId = getQueryParam('restaurant-id') ? Number(getQueryParam('restaurant-id')) : NaN;
  const clientId = getQueryParam('client-id') ? Number(getQueryParam('client-id')) : NaN;
  const orderId = getQueryParam('order-id') ? Number(getQueryParam('order-id')) : NaN;

  const isValidGetParameters = !!(restaurantId && clientId && orderId);
  const {
    data: dataRest,
    isLoading: isLoadingRest,
    isError: isErrorRest,
  } = useGetRestaurantInfoByIdQuery(restaurantId, {
    skip: !isValidGetParameters,
  });
  const {
    data: dataFeedback,
    isLoading: isLoadingFeedback,
    isSuccess: isSuccessFeedback,
  } = useGetFeedbackByOrderIdQuery(orderId, { skip: !isValidGetParameters });
  const [
    sendFeedbackReq,
    { isLoading: isLoadingSendFeedback, isError: isErrorSendFeedback, isSuccess: isSuccessSendFeedback },
  ] = useSendFeedbackMutation();
  const [
    editFeedbackReq,
    { isLoading: isLoadingEditFeedback, isError: isErrorEditFeedback, isSuccess: isSuccessEditFeedback },
  ] = useEditFeedbackMutation();

  useLayoutEffect(() => {
    // navigate back to the orders page if get parameters are not valid (0 or NaN)
    if (!isValidGetParameters) {
      navigate(ROUTES.client_orders);
    }

    scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isLoadingFeedback && isSuccessFeedback && dataFeedback) {
      form.setFieldsValue({
        rating_assortment: dataFeedback.rating_assortment,
        rating_order_process: dataFeedback.rating_order_process,
        rating_using_menu: dataFeedback.rating_using_menu,
        comments: dataFeedback.comments,
      });
    }

    if (!isLoadingRest && !isLoadingFeedback && isErrorRest) {
      message.error(t('server_errors.getting_restaurant_data'));
    }
  }, [isLoadingFeedback, isLoadingRest]);

  useEffect(() => {
    if (!isLoadingEditFeedback && !isLoadingSendFeedback) {
      if (isSuccessSendFeedback || isSuccessEditFeedback) {
        setIsSubmitted(true);
      }

      if (isErrorEditFeedback || isErrorSendFeedback) {
        message.error(t('server_errors.sending_feedback'));
      }
    }
  }, [isLoadingSendFeedback, isLoadingEditFeedback]);

  const onFinish = (values: any) => {
    // send edit req if feedback is already exist || send new feedback
    if (dataFeedback) {
      editFeedbackReq({ id: dataFeedback.id, data: values });
    } else {
      sendFeedbackReq({
        data: values,
        restaurantId,
        clientId,
        orderId,
      });
    }
  };

  return (
    <>
      {isLoadingFeedback && isLoadingRest ? (
        <div>{t('loading')}</div>
      ) : (
        <div>
          <img className={'h-[160px] w-full object-cover'} src={orderFeedbackHeroImg} alt={'page hero'} />
          <div className={'mx-auto mt-[-100px] flex max-w-2xl flex-col gap-6 px-4 pb-10'}>
            {isSubmitted ? (
              <SuccessfulOrderFeedback />
            ) : (
              <>
                {dataRest?.google_account_link && !isErrorRest && (
                  <div className={'flex flex-col items-center rounded bg-white px-4 py-6 text-center shadow-custom'}>
                    <span className={'mb-2 text-xl font-bold leading-[1.2] text-indigo-40'}>
                      {t('leave_us_review')}
                    </span>
                    <p className={'mb-6 text-base text-grey-30'}>{t('tell_other_diners_about_your_experience')}</p>
                    <Link to={dataRest?.google_account_link} target={'_blank'}>
                      <Button className={'h-14'} type="default" icon={<GoogleBusinessIcon fontSize={32} />}>
                        {t('we_were_ranked_on_google')}
                      </Button>
                    </Link>
                  </div>
                )}
                <div className={'flex flex-col items-center rounded bg-white px-4 py-6 shadow-custom'}>
                  <span className={'mb-2 text-xl font-bold leading-[1.2] text-indigo-40'}>{t('fill_in_feedback')}</span>
                  <p className={'mb-6 text-base text-grey-30'}>
                    {t('leave_request_and_review_for_restaurant_manager')}
                  </p>
                  <Form
                    className={'w-full max-w-[311px]'}
                    form={form}
                    name="order-feedback"
                    autoComplete="off"
                    layout={'vertical'}
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <div className={'flex flex-col items-center gap-6'}>
                      <OrderFeedbackRatingRadio
                        name={'rating_assortment'}
                        rules={[{ required: true }]}
                        title={`${t('how_was_food_and_drink_in_restaurant')}?`}
                      />
                      <OrderFeedbackRatingRadio
                        name={'rating_order_process'}
                        rules={[{ required: true }]}
                        title={`${t('how_convenient_is_ordering_process_over_phone')}?`}
                      />
                      <OrderFeedbackRatingRadio
                        name={'rating_using_menu'}
                        rules={[{ required: true }]}
                        title={`${t('how_convenient_is_our_digital_menu_to_use')}?`}
                      />
                      <Form.Item
                        label={
                          <span className={'pb-1 text-base font-normal text-grey-40'}>
                            {t('anything_else_you_would_like_tell_us')}?
                          </span>
                        }
                        className={'m-0 w-full'}
                        name={'comments'}
                      >
                        <Input.TextArea maxLength={255} rows={4} placeholder={t('message')} />
                      </Form.Item>
                      <SubmitButton formInstance={form} loading={isLoadingEditFeedback || isLoadingSendFeedback} />
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientOrderFeedback;
