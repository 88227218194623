import apiCentral from '@api/central-api';
import ClientByGoogleLogin from '@api/models/ClientByGoogleLogin';
import ClientByGoogleRegistration from '@api/models/ClientByGoogleRegistration';
import { CheckoutOrder } from '@api/models/Order';
import { CheckoutClientOrder } from '@base/types/ClientOrder';
import { message } from 'antd';
import { t } from 'i18next';
import { Client } from './models/Client';

export const extendedApiSlice = apiCentral.injectEndpoints({
  endpoints: (builder) => ({
    registerClient: builder.mutation<any, any>({
      query(data) {
        return {
          url: `/client/register`,
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (errorData: any) {
          if (errorData.error.status === 422) {
            message.warning(t('server_errors.email_has_already_been_taken'));
          } else {
            message.error(t('server_errors.server_error'));
          }
        }
      },
    }),

    registerClientByGoogle: builder.mutation<any, ClientByGoogleRegistration>({
      query(data) {
        return {
          url: `/client/by-gmail/register`,
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (errorData: any) {
          if (errorData.error.status === 422) {
            message.warning(t('server_errors.email_has_already_been_taken'));
          } else {
            message.error(t('server_errors.server_error'));
          }
        }
      },
    }),

    loginClient: builder.mutation<Client, void>({
      query(data) {
        return {
          url: '/client/login',
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: Client }) => response.data,
    }),

    loginClientByGoogle: builder.mutation<Client, ClientByGoogleLogin>({
      query(data) {
        return {
          url: '/client/by-gmail/login',
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: { data: Client }) => response.data,
    }),

    logoutClient: builder.mutation<void, void>({
      query() {
        return {
          url: '/client/logout',
          method: 'POST',
        };
      },
    }),

    createOrderByClient: builder.mutation<CheckoutOrder, { clientOrder: CheckoutClientOrder; restId: number }>({
      query({ clientOrder, restId }) {
        return {
          url: `/restaurant/${restId}/check-out`,
          method: 'POST',
          body: clientOrder,
        };
      },
      transformResponse: (response: { data: CheckoutOrder }) => response.data,

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          message.error(t('server_errors.creating_order'));
        }
      },
    }),
  }),
});

export const {
  useRegisterClientMutation,
  useLoginClientMutation,
  useLogoutClientMutation,
  useCreateOrderByClientMutation,
  useRegisterClientByGoogleMutation,
  useLoginClientByGoogleMutation,
} = extendedApiSlice;
