import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isCallsDrawerOpened: boolean;
  newCallsCounter: number;
}

const initialState: InitialState = {
  isCallsDrawerOpened: false,
  newCallsCounter: 0,
};

export const waiterSlice = createSlice({
  initialState,
  name: 'waiter',
  reducers: {
    resetWaiterSlice: () => initialState,
    setCallsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isCallsDrawerOpened = action.payload;
    },
    increaseCallsCounter: (state) => {
      state.newCallsCounter += 1;
    },
    decreaseCallsCounter: (state) => {
      if (state.newCallsCounter > 0) {
        state.newCallsCounter -= 1;
      }
    },
    resetCallsCounter: (state) => {
      state.newCallsCounter = 0;
    },
  },
});

export default waiterSlice.reducer;

export const { resetWaiterSlice, setCallsDrawerOpen, increaseCallsCounter, decreaseCallsCounter, resetCallsCounter } =
  waiterSlice.actions;
