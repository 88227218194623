import UserAccess from '@base/hoc/UserAccess';
import OptionCategory from '@components/Menus/DishForm/DishFormOptions/OptionCategory';
import OptionValue from '@components/Menus/DishForm/DishFormOptions/OptionValue';
import { OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import { Button, Card, Form } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MBPlusSquareIcon from '../../../icons/MBPlusSquareIcon';

const DishFormOptions = () => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const optionInputValue = Form.useWatch(OPTIONS_NAME_FIELD, form);
  const [options, setOptions] = useState<any>(null);

  useEffect(() => {
    if (!options && optionInputValue) {
      setOptions(optionInputValue);
    }
  }, [optionInputValue]);

  const updateOptionsState = async () => {
    const options = await form.getFieldValue(OPTIONS_NAME_FIELD);
    setOptions(options);
  };

  return (
    <Form.List name={OPTIONS_NAME_FIELD}>
      {(fields, { add, remove }) => (
        <div className={'flex flex-col gap-4'}>
          {fields.map((field, index) => {
            const optionValue = !options || (options && !options[index]) || (options && !options[index].name);

            return (
              <Card size="small" key={`option-${field.key}-${index}`} className={'shadow-none'}>
                <OptionCategory
                  temp_id={`temp-option-id-${index}`}
                  id={options && options[index]?.id}
                  fieldName={field.name}
                  isEditMode={optionValue}
                  updateOptionsState={updateOptionsState}
                  remove={remove}
                />

                <Form.Item>
                  <Form.List name={[field.name, 'values']}>
                    {(subFields, subOpt) => {
                      return (
                        <div className={'flex justify-between gap-4'}>
                          <div className={'flex max-w-[90%] grow flex-col gap-4 px-14'}>
                            {subFields.map((subField, indexSubField) => {
                              const opts = form.getFieldValue(OPTIONS_NAME_FIELD);
                              const id = opts[index]?.values[indexSubField]?.id;
                              const option_id = opts[index]?.id;

                              return (
                                <div key={`option-value-${index}-${indexSubField}`}>
                                  <OptionValue
                                    id={id}
                                    temp_id={`temp-value-id-${indexSubField}`}
                                    option_id={option_id}
                                    fieldName={subField.name}
                                    isEditMode={!id}
                                    updateOptionsState={updateOptionsState}
                                    remove={subOpt.remove}
                                  />
                                </div>
                              );
                            })}
                          </div>

                          {options && options[index]?.name && (
                            <UserAccess roles={['restaurant-manager']}>
                              <Button
                                onClick={() => subOpt.add()}
                                block
                                className={'max-w-[140px] justify-center self-end'}
                              >
                                {t('add_value')}
                                <MBPlusSquareIcon className={'shrink-0'} />
                              </Button>
                            </UserAccess>
                          )}
                        </div>
                      );
                    }}
                  </Form.List>
                </Form.Item>
              </Card>
            );
          })}

          <UserAccess roles={['restaurant-manager']}>
            <Button onClick={() => add()} block className={'justify-center'}>
              {t('add_option')}
              <MBPlusSquareIcon />
            </Button>
          </UserAccess>
        </div>
      )}
    </Form.List>
  );
};

export default DishFormOptions;
