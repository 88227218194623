import { Restaurant } from '@api/models/Restaurant';
import {
  useCreateRestaurantMutation,
  useGetSettingsQuery,
  useUpdateImagesRestaurantMutation,
  useUpdateRestaurantMutation,
} from '@api/restaurantSettingsApi';
import {
  setRestDescription,
  setRestGoogleAccountLink,
  setRestHeroSrc,
  setRestId,
  setRestLayout,
  setRestLogoSrc,
  setRestName,
  setRestSecondaryTitle,
  setRestTakeAway,
} from '@base/redux/features/restaurantSettingsSlice';
import { setRestaurantId } from '@base/redux/features/userSlice';
import { useAppSelector } from '@base/redux/store';
import workHoursEmptyStructure from '@constants/workHoursEmptyStructure';
import { FormInstance, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function setFieldsValue(form: FormInstance<any>, values: Array<{ name: string; value: any }>): void {
  values.forEach((item) => {
    form.setFieldValue(item.name, item.value);
  });
}

const useRestaurantSettingsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const restaurantId = useAppSelector((state) => state.restaurantSettingsState.id);
  const {
    data: restaurantSettings,
    isLoading,
    isSuccess,
    isError,
  } = useGetSettingsQuery(restaurantId, { skip: !restaurantId });

  const [form] = useForm();
  const [formData, setFormData] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [
    createRestaurant,
    { isLoading: isLoadingCreate, isError: isErrorCreate, isSuccess: isSuccessCreate, data: responseCreate },
  ] = useCreateRestaurantMutation();

  const [
    updateRestaurant,
    { data: dataUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateRestaurantMutation();

  const [updateImages, { isError: isErrorUpdateImages }] = useUpdateImagesRestaurantMutation();

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRestName(e.target.value));
  };

  const handleChangeSubtitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRestSecondaryTitle(e.target.value));
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRestDescription(e.target.value));
  };

  const update = async (id: number, values: any) => {
    const { profile_picture, background_picture, ...restValues } = values;

    await updateRestaurant({ id, data: restValues });

    if (profile_picture || background_picture) {
      await updateImages({ id, data: values });
    }
  };

  // TODO: remove this because of RestaurantInitProvider, trigger by useEffect to update Rest. info in RestaurantInitProvider
  // TODO: update all important states in storage
  // update storage after form successful update
  const updateStorage = async (data: Restaurant) => {
    await dispatch(setRestaurantId(data.id));
    await dispatch(setRestId(data.id));
    // update layout id (for now)
    await dispatch(setRestLayout(data.layout ? data.layout : { id: 1, name: 'theme_1' }));
    await dispatch(setRestTakeAway(data.is_take_away));
  };

  const onFinish = async (values: any) => {
    if (isFormChanged) {
      if (values.profile_picture) {
        values.profile_picture = values.profile_picture.file.originFileObj;
      }

      if (values.background_picture) {
        values.background_picture = values.background_picture.file.originFileObj;
      }

      if (values.id) {
        await update(values.id, values);
      } else {
        await createRestaurant(values);
      }
    }
  };

  const handleChangeFrom = () => {
    const currentValue_JSON = JSON.stringify(form.getFieldsValue());
    const formData_JSON = JSON.stringify(formData);

    if (currentValue_JSON !== formData_JSON) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  };

  useEffect(() => {
    if (isSuccessCreate && responseCreate) {
      updateStorage(responseCreate);
      message.success(t('created'));
    }

    if (isSuccessUpdate && dataUpdate) {
      updateStorage(dataUpdate);
      message.success(t('saved'));
    }
  }, [isSuccessCreate, isSuccessUpdate]);

  useEffect(() => {
    if (isErrorCreate) {
      message.error(t('error_creating_restaurant'));
    }

    if (isErrorUpdate) {
      message.error(t('error_updating_restaurant'));
    }

    if (isErrorUpdateImages) {
      message.error(t('error_uploading_image_try_again'));
    }
  }, [isErrorCreate, isErrorUpdate, isErrorUpdateImages]);

  useEffect(() => {
    const settingsFields = [
      { name: 'id', value: restaurantSettings?.id ? restaurantSettings.id : '' },
      { name: 'name', value: restaurantSettings?.name ? restaurantSettings.name : '' },
      { name: 'secondary_title', value: restaurantSettings?.secondary_title ? restaurantSettings.secondary_title : '' },
      { name: 'description', value: restaurantSettings?.description ? restaurantSettings.description : '' },
      {
        name: 'google_account_link',
        value: restaurantSettings?.google_account_link ? restaurantSettings.google_account_link : '',
      },
      { name: 'domain', value: restaurantSettings?.domain ? restaurantSettings.domain : '' },
      { name: 'phone_number', value: restaurantSettings?.phone_number ? restaurantSettings.phone_number : '' },
      {
        name: 'work_hours',
        value: restaurantSettings?.work_hours ? restaurantSettings.work_hours : workHoursEmptyStructure,
      },
      {
        name: 'description_schedule',
        value: restaurantSettings?.description_schedule ? restaurantSettings.description_schedule : '',
      },
      { name: 'layout_id', value: restaurantSettings?.layout?.id ? restaurantSettings.layout.id : 1 },
      { name: 'address', value: restaurantSettings?.address ? restaurantSettings.address : '' },
      { name: 'facebook_link', value: restaurantSettings?.facebook_link ? restaurantSettings.facebook_link : '' },
      { name: 'instagram_link', value: restaurantSettings?.instagram_link ? restaurantSettings.instagram_link : '' },
      { name: 'is_take_away', value: restaurantSettings?.is_take_away ? restaurantSettings.is_take_away : false },
      {
        name: 'hero',
        value: restaurantSettings?.background_picture ? restaurantSettings.background_picture : '',
      },
      {
        name: 'logo',
        value: restaurantSettings?.profile_picture ? restaurantSettings.profile_picture : '',
      },
    ];

    setFieldsValue(form, settingsFields);
    if (restaurantSettings) {
      // for preview
      dispatch(setRestName(restaurantSettings?.name || ''));
      dispatch(setRestSecondaryTitle(restaurantSettings?.secondary_title || ''));
      dispatch(setRestDescription(restaurantSettings?.description || ''));
      dispatch(setRestGoogleAccountLink(restaurantSettings?.google_account_link || ''));
      dispatch(setRestLogoSrc(restaurantSettings?.profile_picture || ''));
      dispatch(setRestHeroSrc(restaurantSettings?.background_picture || ''));
      dispatch(setRestLayout(restaurantSettings?.layout || ''));
    }
    setFormData(form.getFieldsValue());
  }, [isSuccess]);

  return {
    t,
    restaurantId,
    isLoading,
    isError,
    form,
    isLoadingBtn: isLoadingCreate || isLoadingUpdate,

    handleChangeName,
    handleChangeSubtitle,
    handleChangeDescription,
    onFinish,
    handleChangeFrom,
  };
};

export default useRestaurantSettingsForm;
