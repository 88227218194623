export const ROUTES: Record<string, string> = {
  root: '/',
  loading: '/loading',
  components: '/components',
  registration: '/registration',
  login: '/login',
  client_login: '/client/login',
  client_registration: '/client/register',
  client_forgot_password: '/client/forgot-password',
  client_reset_password: '/client/reset-password',
  client_orders: '/client-orders',
  client_payment: '/payment',
  client_payment_success: '/payment-success',
  client_order_feedback: '/order-feedback',

  worker_login: '/worker/login',
  worker_registration: '/worker/register',
  worker_forgot_password: '/worker/forgot-password',

  forgot_password: '/forgot-password',
  reset_password: '/reset-password',

  unauthorized: '/unauthorized',
  dashboard: '/dashboard',
  restaurant: '/restaurant-settings',
  workers: '/workers',
  menus: '/menus',
  menu_preview: '/menus/preview',
  qr_builder: '/qr',
  tables: '/tables',
  orders: '/orders',
  cabinet_promotions: '/restaurant-promotions',
  account_settings: '/account-settings',
  packages: '/packages',
  transactions: '/transactions',

  // restaurant
  restaurant_menu: '/menu',
  inventory: '/inventory',
  get_order: '/get-order-from-subdomain',
  package_payment_success: '/package-payment-success',
  package_payment_error: '/package-payment-error',
};
