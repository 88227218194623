import { TableServiceName } from '@api/models/TableService';
import BigJarIcon from '@base/components/icons/BigJarIcon';
import CutleryIcon from '@base/components/icons/CutleryIcon';
import MBCallWaiterIcon from '@base/components/icons/MBCallWaiterIcon';
import NapkinHolderIcon from '@base/components/icons/NapkinHolderIcon';
import useCallToWaiterRequest from '@components/CallToWaiterRequests/CallToWaiterRequest/useCallToWaiterRequest';
import RemoveIconButton from '@components/UI/RemoveIconButton';
import { DATE_FORMAT } from '@constants/constants';
import convertServerDateToClient from '@helpers/convertServerDateToClient';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
const EXPIRED_HOURS = 24;

function checkIsCallExpired(serverDate: string): boolean {
  const serverDateTime = dayjs(serverDate);
  const clientDateTime = dayjs();

  return clientDateTime.diff(serverDateTime, 'hours') > EXPIRED_HOURS;
}

const requestedServicesIcons = {
  cutlery: <CutleryIcon />,
  water: <BigJarIcon />,
  napkins: <NapkinHolderIcon />,
  service: <MBCallWaiterIcon />,
};

interface CallToWaiterRequestProps {
  createdAt: string;
  client_id: number;
  client_name: string;
  table_id: number;
  table_name: string;
  services: string[];
  isHighlighted?: boolean;
}

const CallToWaiterRequest: ForwardRefRenderFunction<HTMLDivElement, CallToWaiterRequestProps> = (
  { createdAt, client_id, client_name, table_id, table_name, services, isHighlighted },
  ref
) => {
  const { t } = useTranslation();
  const { removeWaiterCall } = useCallToWaiterRequest();
  const callDate = convertServerDateToClient(createdAt, DATE_FORMAT);
  const isRemovable = checkIsCallExpired(createdAt);
  const isHighlightedClass = isHighlighted ? 'highlighted-shadow-animation' : '';

  const handleRemove = async () => {
    await removeWaiterCall(client_id, table_id);
  };

  const requestedServicesElements = services.map((service_slug) => {
    const icon = requestedServicesIcons[service_slug as TableServiceName];

    return (
      <span key={service_slug} className={'flex h-10 w-10 items-center justify-center rounded bg-cyan-10'}>
        {icon}
      </span>
    );
  });

  return (
    <div
      className={`flex w-full max-w-[600px] flex-col gap-2 rounded px-4 py-4 pt-6 shadow-custom ${isHighlightedClass}`}
    >
      <div className={'flex flex-wrap items-center justify-between gap-x-4 gap-y-2'}>
        <div className={'text-sm leading-[150%]'}>
          <div className={'whitespace-normal text-grey-30'}>
            {t('client_name')}: <span className={'max-w-[200px] whitespace-normal text-grey-40'}>{client_name}</span>
          </div>
        </div>
        <span className={'text-xs text-grey-30'}>{callDate}</span>
      </div>
      <div className={'flex flex-wrap items-center justify-between gap-x-6 gap-y-2'}>
        <div className={'flex items-center gap-2'}>
          {isRemovable && <RemoveIconButton onClick={handleRemove} />}
          <span className={'w-max rounded bg-indigo-40 px-4 py-2 text-sm leading-normal text-white'}>{table_name}</span>
        </div>
        <div ref={ref} id={'onboarding-calls'} className={'flex items-center gap-[10px] text-2xl text-cyan-30'}>
          {requestedServicesElements}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(CallToWaiterRequest);
