import { SVGProps } from 'react';
const InventoryWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width={153} height={67} viewBox="0 0 153 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.3}
      d="M128.668 26.8111C127.395 28.0687 125.328 28.0687 124.05 26.8111L118.154 20.9798C116.881 19.7222 116.881 17.6799 118.154 16.4169C119.426 15.1592 121.493 15.1592 122.772 16.4169L128.668 22.2428C129.941 23.5058 129.941 25.5481 128.668 26.8111Z"
      fill="#F56565"
    />
    <path
      opacity={0.3}
      d="M118.154 26.8107C116.881 25.5525 116.881 23.5093 118.154 22.2458L124.05 16.4173C125.323 15.1591 127.39 15.1591 128.668 16.4173C129.941 17.6754 129.941 19.7186 128.668 20.9822L122.772 26.8107C121.493 28.0689 119.426 28.0689 118.154 26.8107Z"
      fill="#F56565"
    />
    <g opacity={0.5}>
      <path
        opacity={0.3}
        d="M41.1053 40.2458C40.0871 41.2519 38.4335 41.2519 37.4109 40.2458L32.6939 35.5807C31.6757 34.5746 31.6757 32.9408 32.6939 31.9304C33.7122 30.9243 35.3657 30.9243 36.3883 31.9304L41.1053 36.5911C42.1235 37.6015 42.1235 39.2354 41.1053 40.2458Z"
        fill="#F56565"
      />
      <path
        opacity={0.3}
        d="M32.6939 40.2454C31.6757 39.2389 31.6757 37.6043 32.6939 36.5935L37.4109 31.9307C38.4291 30.9241 40.0827 30.9241 41.1053 31.9307C42.1235 32.9372 42.1235 34.5718 41.1053 35.5826L36.3883 40.2454C35.3657 41.252 33.7122 41.252 32.6939 40.2454Z"
        fill="#F56565"
      />
    </g>
    <g opacity={0.5}>
      <path
        opacity={0.3}
        d="M89.2259 8.36492C88.3685 9.21211 86.9761 9.21211 86.1151 8.36492L82.1431 4.43671C81.2857 3.58952 81.2857 2.21374 82.1431 1.36293C83.0005 0.515742 84.3929 0.515742 85.254 1.36293L89.2259 5.28752C90.0833 6.13833 90.0833 7.51411 89.2259 8.36492Z"
        fill="#F56565"
      />
      <path
        opacity={0.3}
        d="M82.1431 8.36464C81.2857 7.51708 81.2857 6.1407 82.1431 5.28951L86.1151 1.36321C86.9725 0.515649 88.3648 0.515649 89.2259 1.36321C90.0833 2.21077 90.0833 3.58715 89.2259 4.43833L85.254 8.36464C84.3929 9.2122 83.0005 9.2122 82.1431 8.36464Z"
        fill="#F56565"
      />
    </g>
    <g clipPath="url(#clip0_3442_44078)">
      <path
        d="M0.892883 52.4997C0.892883 53.8542 1.43096 55.1532 2.38873 56.111C3.34651 57.0688 4.64553 57.6069 6.00003 57.6069C7.35452 57.6069 8.65355 57.0688 9.61132 56.111C10.5691 55.1532 11.1072 53.8542 11.1072 52.4997C11.1072 51.1452 10.5691 49.8462 9.61132 48.8884C8.65355 47.9307 7.35452 47.3926 6.00003 47.3926C4.64553 47.3926 3.34651 47.9307 2.38873 48.8884C1.43096 49.8462 0.892883 51.1452 0.892883 52.4997Z"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42859 52.5001C4.42859 52.9169 4.59415 53.3166 4.88885 53.6113C5.18355 53.906 5.58325 54.0716 6.00002 54.0716C6.41679 54.0716 6.81649 53.906 7.11119 53.6113C7.40589 53.3166 7.57145 52.9169 7.57145 52.5001C7.57145 52.0834 7.40589 51.6837 7.11119 51.389C6.81649 51.0943 6.41679 50.9287 6.00002 50.9287C5.58325 50.9287 5.18355 51.0943 4.88885 51.389C4.59415 51.6837 4.42859 52.0834 4.42859 52.5001Z"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.53574 52.1064L9.14288 52.8922" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.85718 54.0713L3.64289 54.857" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.78577 48.9639L7.57148 49.7496" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.25002 50.7317C3.19792 50.7317 3.14796 50.711 3.11112 50.6742C3.07428 50.6374 3.05359 50.5874 3.05359 50.5353C3.05359 50.4832 3.07428 50.4332 3.11112 50.3964C3.14796 50.3596 3.19792 50.3389 3.25002 50.3389"
        stroke="#FED7D7"
      />
      <path
        d="M3.25 50.7317C3.3021 50.7317 3.35206 50.711 3.3889 50.6742C3.42573 50.6374 3.44643 50.5874 3.44643 50.5353C3.44643 50.4832 3.42573 50.4332 3.3889 50.3964C3.35206 50.3596 3.3021 50.3389 3.25 50.3389"
        stroke="#FED7D7"
      />
      <path
        d="M7.17861 55.8391C7.12651 55.8391 7.07655 55.8185 7.03971 55.7816C7.00287 55.7448 6.98218 55.6948 6.98218 55.6427C6.98218 55.5906 7.00287 55.5407 7.03971 55.5038C7.07655 55.467 7.12651 55.4463 7.17861 55.4463"
        stroke="#FED7D7"
      />
      <path
        d="M7.17859 55.8391C7.23069 55.8391 7.28065 55.8185 7.31749 55.7816C7.35432 55.7448 7.37502 55.6948 7.37502 55.6427C7.37502 55.5906 7.35432 55.5407 7.31749 55.5038C7.28065 55.467 7.23069 55.4463 7.17859 55.4463"
        stroke="#FED7D7"
      />
    </g>
    <g clipPath="url(#clip1_3442_44078)">
      <path
        d="M142.214 37.4287H149.5V41.7144C149.5 42.1691 149.319 42.6051 148.998 42.9266C148.676 43.2481 148.24 43.4287 147.786 43.4287H143.5C143.045 43.4287 142.609 43.2481 142.288 42.9266C141.966 42.6051 141.786 42.1691 141.786 41.7144V37.8573C141.786 37.7436 141.831 37.6346 141.911 37.5542C141.992 37.4739 142.101 37.4287 142.214 37.4287Z"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M140.929 45.5713H152.071" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M149.5 37.4287H150.357C150.812 37.4287 151.248 37.6093 151.569 37.9308C151.891 38.2523 152.071 38.6883 152.071 39.143C152.071 39.5977 151.891 40.0337 151.569 40.3552C151.248 40.6767 150.812 40.8573 150.357 40.8573H149.5"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M143.071 34.4287V35.2859" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M147.357 34.4287V35.2859" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M145.214 34.4287V35.2859" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <g clipPath="url(#clip2_3442_44078)">
      <path
        d="M18.5 14.5723C19.9777 14.5723 21.3948 15.1593 22.4396 16.2041C23.4845 17.2489 24.0714 18.6661 24.0714 20.1437C24.0714 20.371 23.9811 20.589 23.8204 20.7498C23.6596 20.9105 23.4416 21.0008 23.2143 21.0008H13.7857C13.5584 21.0008 13.3404 20.9105 13.1796 20.7498C13.0189 20.589 12.9286 20.371 12.9286 20.1437C12.9286 18.6661 13.5156 17.2489 14.5604 16.2041C15.6053 15.1593 17.0224 14.5723 18.5 14.5723Z"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.5 14.5718V13.2861" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9286 22.7148H24.0714" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <path
      opacity={0.3}
      d="M97.8118 63.3133C92.8941 68.2289 84.9081 68.2289 79.9694 63.3133L57.1883 40.5212C52.2706 35.6057 52.2706 27.6232 57.1883 22.6867C62.106 17.7711 70.092 17.7711 75.0307 22.6867L97.8118 45.4578C102.729 50.3943 102.729 58.3768 97.8118 63.3133Z"
      fill="#F56565"
    />
    <path
      opacity={0.3}
      d="M57.1883 63.3117C52.2706 58.394 52.2706 50.408 57.1883 45.4693L79.9694 22.6883C84.8871 17.7706 92.8731 17.7706 97.8118 22.6883C102.729 27.606 102.729 35.5919 97.8118 40.5306L75.0307 63.3117C70.092 68.2294 62.106 68.2294 57.1883 63.3117Z"
      fill="#F56565"
    />
    <path
      d="M76.42 34.696V38.6804H75.7V34.7204C75.7 34.5217 75.5388 34.3604 75.34 34.3604C75.1413 34.3604 74.98 34.5217 74.98 34.7204V38.6804H74.26V34.7075C74.26 34.2442 73.54 34.26 73.54 34.6956V38.6804H72.82V34.724C72.82 34.2334 72.1 34.2514 72.1 34.7046V39.9145C72.1 40.6658 72.82 41.1612 73.54 41.1612V50.5604C73.54 52.0004 75.7 52.0004 75.7 50.5604V41.1616C76.42 41.1616 77.14 40.5632 77.14 39.9998V34.7075C77.14 34.2485 76.42 34.2661 76.42 34.696ZM79.66 35.8004V44.8004H80.38V50.5604C80.38 52.0004 82.9 52.0004 82.9 50.5604V35.8004C82.9 34.0004 79.66 34.0004 79.66 35.8004Z"
      fill="white"
    />
    <g clipPath="url(#clip3_3442_44078)">
      <path d="M110.743 47.0859V48.5364" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M115.306 47.0859V48.5364" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M113.025 46.0059V47.4563" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M120.009 49.2735C120.009 49.5087 120.11 49.7343 120.289 49.9006C120.468 50.0669 120.711 50.1603 120.964 50.1603C121.217 50.1603 121.46 50.0669 121.639 49.9006C121.818 49.7343 121.919 49.5087 121.919 49.2735C121.919 49.0383 121.818 48.8128 121.639 48.6465C121.46 48.4801 121.217 48.3867 120.964 48.3867C120.711 48.3867 120.468 48.4801 120.289 48.6465C120.11 48.8128 120.009 49.0383 120.009 49.2735Z"
        stroke="#FED7D7"
        strokeLinecap="round"
      />
      <path
        d="M119.341 53.0572L119.553 53.1436C121.033 53.7936 121.442 54.6349 121.442 55.79V56.0667C121.442 56.313 121.337 56.5492 121.149 56.7233C120.962 56.8975 120.707 56.9953 120.442 56.9953H110.14C109.813 56.9953 109.506 56.8458 109.38 56.5654C109.103 55.9441 108.781 54.6961 109.618 53.1436C110.828 50.9001 113.805 50.4071 115.712 51.3356L115.802 51.3784"
        stroke="#FED7D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.658 54.2919C119.586 53.3466 119.634 51.9844 118.766 51.2509C117.898 50.5173 116.443 50.6891 115.516 51.6344C114.673 52.4933 114.556 53.6949 115.191 54.4572C115.255 54.5352 115.328 54.6086 115.408 54.6754"
        stroke="#FED7D7"
        strokeLinecap="round"
      />
      <path d="M120.306 49.918L118.82 51.2969" stroke="#FED7D7" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_3442_44078">
        <rect width={11} height={11} fill="white" transform="translate(0.5 47)" />
      </clipPath>
      <clipPath id="clip1_3442_44078">
        <rect width={12} height={12} fill="white" transform="translate(140.5 34)" />
      </clipPath>
      <clipPath id="clip2_3442_44078">
        <rect width={12} height={12} fill="white" transform="translate(12.5 12)" />
      </clipPath>
      <clipPath id="clip3_3442_44078">
        <rect width={14} height={13} fill="white" transform="translate(108.5 45)" />
      </clipPath>
    </defs>
  </svg>
);
export default InventoryWarning;
