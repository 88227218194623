import UserAccess from '@base/hoc/UserAccess';
import MBListIcon from '@components/icons/MBListIcon';
import { DND_DISH_TYPE } from '@components/Menus/Dnd/DndMenuCategories/DndMenuCategories';
import { DndDish } from '@components/Menus/Dnd/DndMenuCategories/useDndMenuCategories';
import DndMenuDish from '@components/Menus/Dnd/DndMenuDish';
import { useSortable } from '@dnd-kit/sortable';
import { Button } from 'antd';
import { FC } from 'react';

export const DndDraggableDish: FC<{ dish: DndDish }> = ({ dish }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: dish.dnd_id,
    data: {
      type: DND_DISH_TYPE,
      dish,
    },
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={'h-[56px] w-full rounded border-2 border-solid border-grey-20 bg-grey-10 opacity-60'}
      />
    );
  }

  return (
    <div ref={setNodeRef} className={'flex items-center gap-2'} style={style}>
      <UserAccess roles={['restaurant-manager']}>
        <Button
          type={'text'}
          className={'h-[2em] w-[1.8em] cursor-move justify-center !bg-transparent p-1'}
          {...attributes}
          {...listeners}
        >
          <MBListIcon type={'link'} className={'shrink-0 text-base text-grey-20'} />
        </Button>
      </UserAccess>
      <div className={'flex-grow'}>
        <DndMenuDish dish={dish} />
      </div>
    </div>
  );
};

export default DndDraggableDish;
