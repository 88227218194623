import obInventoryStep1 from '@assets/img/ob_inventory_step_1.png';
import obInventoryStep2 from '@assets/img/ob_inventory_step_2.png';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useSteps = () => {
  const { t } = useTranslation();

  const obStepRef1 = useRef(null);

  const renderContentStep1 = () => (
    <div className={'flex flex-col items-center justify-center gap-4'}>
      <div>
        <p>{t('onboarding.inventory.step_1.desc')}.</p>
      </div>
      <div>
        <img src={obInventoryStep1} alt="" />
      </div>
    </div>
  );

  const renderContentStep2 = () => (
    <div className={'flex flex-col items-center gap-4'}>
      <div>
        <p>{t('onboarding.inventory.step_2.desc')}.</p>
      </div>
      <div>
        <img src={obInventoryStep2} alt="" />
      </div>
    </div>
  );

  const steps = [
    {
      title: t('onboarding.inventory.step_1.title'),
      description: renderContentStep1(),
      target: () => obStepRef1.current,
      contentWidth: 580,
    },
    {
      title: t('onboarding.inventory.step_2.title'),
      description: renderContentStep2(),
      target: () => obStepRef1.current,
      contentWidth: 385,
    },
  ];

  return {
    obStepRef1,
    steps,
  };
};

export default useSteps;
