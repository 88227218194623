import { COOKIE_CLIENT_PREFERENCES_KEY } from '@constants/constants';
import { domain } from '@constants/environments';
import Cookies from 'js-cookie';

export const removeClientPreferencesFromCookies = () => {
  Cookies.remove(COOKIE_CLIENT_PREFERENCES_KEY, {
    path: '/',
    domain,
  });
};
