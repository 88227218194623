import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useSteps = () => {
  const { t } = useTranslation();

  const obStepRef1 = useRef(null);

  const steps = [
    {
      title: t('onboarding.qr_builder.step_1.title'),
      description: t('onboarding.qr_builder.step_1.desc'),
      target: () => obStepRef1.current,
    },
  ];

  return {
    obStepRef1,
    steps,
  };
};

export default useSteps;
