import { SubscriptionPackagePlansType } from '@base/types/SubscriptionPackagePlansType';
import { DEFAULT_PACKAGE_PLAN, DEFAULT_SUBSCRIPTION_PACKAGE_PLAN } from '@base/utils/constants/constants';
import MBCloseCircleIcon from '@components/icons/MBCloseCircleIcon';
import PackagePremiumLogo from '@components/svg/PackagePremiumLogo';
import Heading from '@components/UI/Heading';
import { ROUTES } from '@constants/routes';
import { Button } from 'antd';
import { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface SubscriptionMassageProps {
  setIsShow: Dispatch<SetStateAction<boolean>>;
  remainingDays: number;
  packagePlan?: SubscriptionPackagePlansType;
  className?: string;
  styles?: CSSProperties;
}

const SubscriptionMassage: FC<SubscriptionMassageProps> = ({
  setIsShow,
  packagePlan,
  remainingDays,
  className,
  styles,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex w-full max-w-max items-center gap-4 rounded-[0px_0px_20px_20px] bg-[linear-gradient(281deg,#774AF7_11.68%,#2A6BEF_88.35%)] px-8 py-2 text-white shadow-[0px_4px_8px_0px_rgba(0,0,0,0.25)] ${className}`}
      style={styles}
    >
      <Button
        type={'text'}
        className={'flex items-center justify-center text-center text-white'}
        shape={'circle'}
        icon={<MBCloseCircleIcon className={'text-2xl text-white'} onClick={() => setIsShow(false)} />}
      />
      {packagePlan === 'Trial' && (
        <span>
          {t('the_trial_period_ends_in')} {remainingDays} {t('days')}.{' '}
          {t('after_that_some_functions_will_not_be_available')}.
        </span>
      )}
      {packagePlan === 'Free' && (
        <span>
          {t('trial_period_ended_without_choosing_package_so_you_tranferred_to_package')} {DEFAULT_SUBSCRIPTION_PACKAGE_PLAN}.
        </span>
      )}

      <Link to={ROUTES.packages} className={'flex items-center gap-3 rounded bg-white p-2 pl-4 no-underline'}>
        <PackagePremiumLogo className={'h-8 w-8 flex-shrink-0'} />
        <Heading component={'span'} className={'whitespace-nowrap text-base'}>
          {t('to_select_package')}
        </Heading>
      </Link>
    </div>
  );
};

export default SubscriptionMassage;
