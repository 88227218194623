function extractQueryParameter(url: string): Record<string, string> {
  const parsedUrl = new URL(url);
  const query = parsedUrl.search.slice(1);

  const parameters = query.split('/?');

  const result: Record<string, string> = {};

  for (const parameter of parameters) {
    const [paramKey, paramValue] = parameter.split('=');

    result[paramKey] = decodeURIComponent(paramValue);
  }

  return result;
}

export default extractQueryParameter;
