import AccountInfo from '@components/AccountSettings/AccountInfo';
import AccountPassword from '@components/AccountSettings/AccountPassword';
import AccountRemoving from '@components/AccountSettings/AccountRemoving';
import ClientAccountPreferences from '@components/AccountSettings/ClientAccountPreferences';
import useCheckRole from '@hooks/useCheckRole';
import { Alert } from 'antd';
import RestaurantPaymentSystem from './RestaurantPaymentSystem';

const AccountSettings = () => {
  const { t, isManager, isClient, isGoogleAccount } = useCheckRole();

  return (
    <>
      {isGoogleAccount && <Alert type={'warning'} message={t('limiting_changes_personal_info_due_google_account')} />}
      <div className={`flex flex-col gap-4 ${isClient ? 'm-4' : ''}`}>
        <AccountInfo />
        {isManager && <RestaurantPaymentSystem />}
        {!isGoogleAccount && <AccountPassword />}

        {isManager && (
          <>
            {/* TODO: will be in the future */}
            {/* <SimpleSection title={t('tariffs_and_payment')}>
            <div className={'flex flex-wrap gap-6'}>
              <div className={'max-w-[550px] flex-[1_1_400px]'}>
                <AccountPackage />
              </div>
            </div>
          </SimpleSection>*/}
            <AccountRemoving />
          </>
        )}

        {isClient && <ClientAccountPreferences />}
      </div>
    </>
  );
};

export default AccountSettings;
