import api from '@api/api';
import { InventoryData } from '@api/models/Inventory';
import addTenantToEndpoint from '@helpers/addTenantToEndpoint';
import { message } from 'antd';
import { t } from 'i18next';

export const extendedApiSlice = api
  .enhanceEndpoints({
    addTagTypes: ['Inventory'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInventory: builder.query<InventoryData, void>({
        query: () => addTenantToEndpoint('/menu-dishes?type=inventory'),
        providesTags: ['Inventory'],
      }),

      updateInventoryDish: builder.mutation<
        any,
        { id: number; inventory_quantity: number; is_inventory_unlimited: boolean }
      >({
        query({ id, inventory_quantity, is_inventory_unlimited }) {
          return {
            url: addTenantToEndpoint(`/inventory/${id}`),
            method: 'PATCH',
            body: {
              inventory_quantity,
              is_inventory_unlimited,
            },
          };
        },
        invalidatesTags: ['Inventory'],

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            message.error(t('server_errors.updating_inventory'));
          }
        },
      }),
      toggleInventoryDish: builder.mutation<any, { id: number; is_portion_available: boolean }>({
        query({ id, is_portion_available }) {
          return {
            url: addTenantToEndpoint(`/inventory/${id}`),
            method: 'PATCH',
            body: {
              is_portion_available,
            },
          };
        },
        invalidatesTags: ['Inventory'],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;

            message.success(t('saved'));
          } catch (error) {
            message.error(t('server_errors.changing_dish_availability'));
          }
        },
      }),
    }),
  });

export const { useLazyGetInventoryQuery, useUpdateInventoryDishMutation, useToggleInventoryDishMutation } =
  extendedApiSlice;
