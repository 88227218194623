import { useGetBestDishesQuery } from '@api/DashboardApi';

const useDashboardBestDish = (filter: string) => {
  const { data = [], isLoading, isError } = useGetBestDishesQuery(filter, { refetchOnMountOrArgChange: true });

  const dishes = data.map((dish) => ({
    id: dish.dish_id,
    name: dish.dish.dish_name,
    value: +dish.total_quantity,
  }));

  return {
    dishes,
    isLoading,
    isError,
  };
};

export default useDashboardBestDish;
