import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DndCategory from '@pages/Components/DndMenu/DndCategory';
import DndDish from '@pages/Components/DndMenu/DndDish/DndDish';
import DndSubcategory from '@pages/Components/DndMenu/DndSubcategory';
import { Card } from 'antd';
import { FC, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

export const DND_CATEGORY_TYPE = 'Category';
export const DND_SUBCATEGORY_TYPE = 'Subcategory';
export const DND_DISH_TYPE = 'Dish';

export interface DishType {
  id: number;
  dnd_id: string;
  name: string;
  menu: {
    id: number;
    dnd_id: string;
  };
}

export interface CategoryType {
  id: number;
  dnd_id: string;
  name: string;
  position: number;
}

export interface SubcategoryType extends CategoryType {
  menu: {
    id: number;
    dnd_id: string;
  };
}

const defaultCategories: CategoryType[] = [
  { id: 1, dnd_id: 'category_1', name: 'Category 1', position: 1 },
  { id: 2, dnd_id: 'category_2', name: 'Category 2', position: 2 },
  { id: 3, dnd_id: 'category_3', name: 'Category 3', position: 3 },
];

const defaultSubcategories: SubcategoryType[] = [
  {
    id: 4,
    dnd_id: 'subcategory_1',
    name: 'Subcategory 1',
    position: 1,
    menu: {
      id: 2,
      dnd_id: 'category_2',
    },
  },
  {
    id: 5,
    dnd_id: 'subcategory_2',
    name: 'Subcategory 2',
    position: 2,
    menu: {
      id: 3,
      dnd_id: 'category_3',
    },
  },
  {
    id: 6,
    dnd_id: 'subcategory_3',
    name: 'Subcategory 3',
    position: 3,
    menu: {
      id: 3,
      dnd_id: 'category_3',
    },
  },
];

const defaultDishes: DishType[] = [
  {
    id: 1,
    dnd_id: 'dish_1',
    name: 'Dish 1',
    menu: {
      id: 1,
      dnd_id: 'category_1',
    },
  },
  {
    id: 2,
    dnd_id: 'dish_2',
    name: 'Dish 2',
    menu: {
      id: 1,
      dnd_id: 'category_1',
    },
  },
  {
    id: 3,
    dnd_id: 'dish_3',
    name: 'Dish 3',
    menu: {
      id: 1,
      dnd_id: 'category_1',
    },
  },
  {
    id: 4,
    dnd_id: 'dish_4',
    name: 'Dish 4',
    menu: {
      id: 4,
      dnd_id: 'subcategory_1',
    },
  },
  {
    id: 5,
    dnd_id: 'dish_5',
    name: 'Dish 5',
    menu: {
      id: 4,
      dnd_id: 'subcategory_1',
    },
  },
];

const DndMenu: FC = () => {
  const [categories, setCategories] = useState<CategoryType[]>(defaultCategories);
  const categoriesID = useMemo(() => categories.map((category) => category.dnd_id), [categories]);

  const [subcategories, setSubcategories] = useState<SubcategoryType[]>(defaultSubcategories);
  const subcategoriesID = useMemo(() => subcategories.map((subcategory) => subcategory.dnd_id), [subcategories]);

  const [dishes, setDishes] = useState<DishType[]>(defaultDishes);

  const [activeCategory, setActiveCategory] = useState<CategoryType | null>(null);
  const [activeSubcategory, setActiveSubcategory] = useState<SubcategoryType | null>(null);

  const [activeDish, setActiveDish] = useState<DishType | null>(null);

  /* const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );*/

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={onDragOver}>
      <Card className={'w-[500px]'} title={'Drag&drop'}>
        <div className={'flex flex-col gap-4'}>
          <SortableContext items={categoriesID} strategy={verticalListSortingStrategy}>
            {categories.map((category) => (
              <DndCategory
                key={category.dnd_id}
                category={category}
                subcategories={subcategories.filter((subcategory) => subcategory.menu.dnd_id === category.dnd_id)}
                dishes={dishes}
              />
            ))}
          </SortableContext>
        </div>
      </Card>
      {createPortal(
        <div className={'portal'}>
          <DragOverlay>
            {activeCategory && (
              <DndCategory
                category={activeCategory}
                subcategories={subcategories.filter((subcategory) => subcategory.menu.dnd_id === activeCategory.dnd_id)}
                dishes={dishes}
              />
            )}
            {activeDish && <DndDish dish={activeDish} />}
            {activeSubcategory && <DndSubcategory subcategory={activeSubcategory} />}
          </DragOverlay>
        </div>,
        document.body
      )}
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === DND_CATEGORY_TYPE) {
      setActiveCategory(event.active.data.current.category);

      return;
    }

    if (event.active.data.current?.type === DND_SUBCATEGORY_TYPE) {
      setActiveSubcategory(event.active.data.current.subcategory);

      return;
    }

    if (event.active.data.current?.type === DND_DISH_TYPE) {
      setActiveDish(event.active.data.current.dish);

      return;
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    // console.log('DRAG END');
    setActiveCategory(null);
    setActiveSubcategory(null);
    setActiveDish(null);

    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) {
      return;
    }

    const isActiveAColumn = active.data.current?.type === DND_CATEGORY_TYPE;

    const isActiveASubcategory = active.data.current?.type === DND_SUBCATEGORY_TYPE;
    const isOverASubcategory = over.data.current?.type === DND_SUBCATEGORY_TYPE;

    const isActiveADish = active.data.current?.type === DND_DISH_TYPE;
    const isOverACategory = over.data.current?.type === DND_CATEGORY_TYPE;

    // Im dropping a Dish over a Subcategory
    if (isActiveADish && isOverASubcategory) {
      setDishes((dishes) => {
        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);

        dishes[activeIndex].menu.dnd_id = overId as string;
        // console.log('DROPPING DISH OVER SUBCATEGORY', { activeIndex });

        return arrayMove(dishes, activeIndex, activeIndex);
      });
    }

    // Im dropping a Subcategory over a Category
    if (isActiveASubcategory && isOverACategory) {
      setSubcategories((subcategories) => {
        const activeIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === activeId);

        subcategories[activeIndex].menu.dnd_id = overId as string;
        console.log('DROPPING SUBCATEGORY OVER CATEGORY ', { activeIndex });

        return arrayMove(subcategories, activeIndex, activeIndex);
      });
    }

    // Im dropping a Dish over a Category
    if (isActiveADish && isOverACategory) {
      setDishes((dishes) => {
        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);

        dishes[activeIndex].menu.dnd_id = overId as string;
        // console.log('DROPPING DISH OVER CATEGORY', { activeIndex });

        return arrayMove(dishes, activeIndex, activeIndex);
      });
    }

    // Im sorting the Category
    if (isActiveAColumn) {
      setCategories((categories) => {
        const activeColumnIndex = categories.findIndex((category) => category.dnd_id === activeId);

        const overColumnIndex = categories.findIndex((category) => category.dnd_id === overId);

        return arrayMove(categories, activeColumnIndex, overColumnIndex);
      });
    }
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) {
      return;
    }

    const isActiveASubcategory = active.data.current?.type === DND_SUBCATEGORY_TYPE;
    const isOverASubcategory = over.data.current?.type === DND_SUBCATEGORY_TYPE;

    const isActiveADish = active.data.current?.type === DND_DISH_TYPE;
    const isOverADish = over.data.current?.type === DND_DISH_TYPE;

    if (!isActiveADish && !isActiveASubcategory) {
      return;
    }

    // Im dropping a Subcategory over another Subcategory
    if (isActiveASubcategory && isOverASubcategory) {
      setSubcategories((subcategories) => {
        const activeIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === activeId);
        const overIndex = subcategories.findIndex((subcategory) => subcategory.dnd_id === overId);
        // console.log('DROPPING SUBCATEGORY OVER SUBCATEGORY', { activeIndex });
        if (subcategories[activeIndex].menu.dnd_id !== subcategories[overIndex].menu.dnd_id) {
          subcategories[activeIndex].menu.dnd_id = subcategories[overIndex].menu.dnd_id;

          return arrayMove(subcategories, activeIndex, overIndex - 1);
        }

        return arrayMove(subcategories, activeIndex, overIndex);
      });
    }

    // Im dropping a Dish over another Dish
    if (isActiveADish && isOverADish) {
      setDishes((dishes) => {
        const activeIndex = dishes.findIndex((dish) => dish.dnd_id === activeId);
        const overIndex = dishes.findIndex((dish) => dish.dnd_id === overId);
        // console.log('DROPPING DISH OVER DISH', { activeIndex });

        if (dishes[activeIndex].menu.dnd_id !== dishes[overIndex].menu.dnd_id) {
          dishes[activeIndex].menu.dnd_id = dishes[overIndex].menu.dnd_id;

          return arrayMove(dishes, activeIndex, overIndex - 1);
        }

        return arrayMove(dishes, activeIndex, overIndex);
      });
    }
  }
};

export default DndMenu;
