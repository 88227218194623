import { useAppSelector } from '@base/redux/store';
import { PUSHER_APP_INSTANCE_ID } from '@constants/environments';
import GetBaseUrlApi from '@helpers/getBaseUrlApi';
import useCheckRole from '@hooks/useCheckRole';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

const BEAMS_AUTH_URL = `${GetBaseUrlApi()}/tenant/worker/pusher/beams-auth`;

function getBeamsClient(): PusherPushNotifications.Client {
  return new PusherPushNotifications.Client({
    instanceId: PUSHER_APP_INSTANCE_ID,
  });
}

function getBeamsTokenProvider(token: string): PusherPushNotifications.TokenProvider {
  return new PusherPushNotifications.TokenProvider({
    url: BEAMS_AUTH_URL,
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
}

type Beams = PusherPushNotifications.Client | undefined;
const PushNotificationsContext = createContext<any | undefined>(undefined);
export function useBeams() {
  return useContext(PushNotificationsContext);
}

export function useGetBeamsInstance() {
  const beamsInstance: Beams = useBeams();

  return useMemo(() => {
    return beamsInstance && beamsInstance;
  }, [beamsInstance]);
}

export const PushNotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isClient, isWaiter } = useCheckRole();

  const token = useAppSelector((state) => state.userState.token);
  const userID = useAppSelector((state) => state.userState.id);
  const restID = useAppSelector((state) => state.restaurantSettingsState.id || state.restaurantState.id);

  const [beamsClientState, setBeamsClientState] = useState<Beams>(undefined);

  const isHTTPS = window.location.protocol === 'https:';

  async function unsubscribeFromPusher() {
    if (beamsClientState) {
      await beamsClientState
        .stop()
        .then(() => console.log('Beams SDK has been stopped'))
        .catch((e) => console.error('Could not stop Beams SDK', e));
    }
  }

  const values = {
    beamsClientState,
    unsubscribeFromPusher,
  };

  useEffect(() => {
    if (!isHTTPS || !restID || !token) {
      console.log('Not subscribed to Pusher');

      return;
    }

    if (beamsClientState) {
      return;
    }

    const initializeBeamsClient = async () => {
      try {
        const beamsClient = getBeamsClient();
        await beamsClient.start();

        // for client notifications
        if (isClient) {
          await beamsClient.addDeviceInterest(`promotions${restID}`);
          console.log('Client - Successfully registered and subscribed!');
        }

        // for waiter notifications
        if (isWaiter && userID && token) {
          const beamsTokenProvider = getBeamsTokenProvider(token);
          await beamsClient.setUserId(`user-${userID}`, beamsTokenProvider);
          console.log('Waiter - Successfully registered and subscribed!');
        }

        setBeamsClientState(beamsClient);
      } catch (error) {
        console.error('Error registering device:', error);
      }
    };

    initializeBeamsClient();
  }, [token, restID, isClient, isWaiter, userID]);

  return <PushNotificationsContext.Provider value={values}>{children}</PushNotificationsContext.Provider>;
};
