import { useLazyGetDishByIdQuery } from '@api/dishApi';
import DishExtra, { ClientDishExtra } from '@api/models/DishExtra';
import DishOption, { ClientDishOption } from '@api/models/DishOption';
import { PromotionDishSelectedExtras, PromotionDishSelectedOptions } from '@api/models/Promotion';
import { useGetDishesListQuery } from '@api/promotionApi';
import { EXTRAS_NAME_FIELD, OPTIONS_NAME_FIELD } from '@components/Menus/MenuDishModal/MenuDishModal';
import {
  calculateTotalExtrasPrice,
  calculateTotalOptionPrice,
} from '@components/MobileMenu/MobileClientDishSettings/useMobileClientDishSettings';
import { DISH_ID_NAME_FIELD } from '@components/Promotions/Promotions';
import { DISH_PRICE_NAME_FIELD, OLD_PROMOTION_PRICE_NAME_FIELD } from '@components/Promotions/usePromotions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function filterOptionsWithAvailableValues(options: DishOption[]): DishOption[] {
  return options.filter((option) => option.values.some((value) => value.is_readily_available));
}

export const preparePromotionExtras = (
  extras: DishExtra[],
  selectedExtras?: PromotionDishSelectedExtras[] | null
): ClientDishExtra[] => {
  return extras.map((item) => {
    const isChecked = !selectedExtras ? false : selectedExtras?.some((selectedExtra) => selectedExtra.id === item.id);

    return {
      ...item,
      checked: isChecked,
    };
  });
};

export const preparePromotionOpts = (
  opts: DishOption[],
  selectedOpts?: PromotionDishSelectedOptions[] | null
): ClientDishOption[] => {
  const availableOpts = filterOptionsWithAvailableValues(opts);

  return availableOpts.map((opt) => {
    const selectedOpt = selectedOpts?.find((selectedOpt) => selectedOpt.id === opt.id);

    return {
      ...opt,
      values: opt.values.map((item, i) => {
        const isSelected = (!selectedOpt && i === 0) || selectedOpt?.values.id === item.id;

        return {
          ...item,
          selected: isSelected,
        };
      }),
    };
  });
};

const usePromotionModalForm = (form: any) => {
  const { t } = useTranslation();
  const [totalDishPrice, setTotalDishPrice] = useState<number>(0);
  const {
    data: menuDishList = [],
    isFetching,
    isError,
  } = useGetDishesListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [isDish, setIsDish] = useState<boolean>(false);

  const [getDishById, { data: dish, isFetching: isFetchingDish, isError: isErrorDish }] = useLazyGetDishByIdQuery();

  const changedFields = (changedFields: any) => {
    const opts: ClientDishOption[] = form.getFieldValue(OPTIONS_NAME_FIELD);
    const fieldData = changedFields[0].name;

    if (fieldData[0] === OPTIONS_NAME_FIELD) {
      const indexOpt = fieldData[1];
      const indexValue = +fieldData[3];
      const optList = opts[indexOpt].values;

      // change selected value
      optList.forEach((item, i) => (item.selected = i === indexValue));

      const changedOpts = opts.map((opt, i) => {
        if (indexOpt === i) {
          return {
            ...opt,
            values: optList,
          };
        } else {
          return opt;
        }
      });

      form.setFieldValue(OPTIONS_NAME_FIELD, changedOpts);
    }
  };

  const onCalculateTotal = async () => {
    const dish_price = await form.getFieldValue(DISH_PRICE_NAME_FIELD);
    const opts: ClientDishOption[] = await form.getFieldValue(OPTIONS_NAME_FIELD);
    const extras: ClientDishExtra[] = await form.getFieldValue(EXTRAS_NAME_FIELD);

    const optionsPrice = opts ? calculateTotalOptionPrice(opts) : 0;
    const extrasPrice = extras ? calculateTotalExtrasPrice(extras) : 0;

    const total = dish_price ? +dish_price + extrasPrice + optionsPrice : 0;
    setTotalDishPrice(total);
  };

  useEffect(() => {
    form.setFieldValue(OLD_PROMOTION_PRICE_NAME_FIELD, totalDishPrice);
  }, [totalDishPrice]);

  useEffect(() => {
    const dishID = form.getFieldValue(DISH_ID_NAME_FIELD);
    if (dishID) {
      setIsDish(true);
    }

    onCalculateTotal();
  }, []);

  useEffect(() => {
    if (dish) {
      setIsDish(true);
    }
  }, [dish, isFetchingDish]);

  return {
    t,
    menuDishList,
    isFetching,
    isError,
    getDishById,
    dish,
    isDish,
    setIsDish,
    isFetchingDish,
    isErrorDish,
    changedFields,
    onCalculateTotal,
    totalDishPrice,
  };
};

export default usePromotionModalForm;
