import {
  useCreateDishOptionMutation,
  useRemoveDishOptionMutation,
  useUpdateDishOptionMutation,
} from '@api/dishOptionsApi';

const useOptionCategory = () => {
  const [
    crateOption,
    { data: createdOption, isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isLoading: isLoadingCreateOption },
  ] = useCreateDishOptionMutation();
  const [updateOption, { isLoading: isLoadingUpdateOption, isSuccess: isSuccessUpdate }] =
    useUpdateDishOptionMutation();
  const [removeOption, { isLoading: isLoadingRemoveOption }] = useRemoveDishOptionMutation();

  return {
    crateOption,
    isLoadingCreate,
    isSuccessCreate,
    createdOption,
    updateOption,
    isLoadingUpdateOption,
    isSuccessUpdate,
    removeOption,
    isLoading: isLoadingCreateOption || isLoadingUpdateOption,
    isLoadingRemoveOption,
  };
};

export default useOptionCategory;
