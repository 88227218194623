import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialState {
  isSubdomain: boolean;
  subdomain: string | null;
  restaurantDomain4Client: string;
  nextTime2ShowInventoryWarmingPopup: number;
}

const initialState: initialState = {
  isSubdomain: false,
  subdomain: null,
  restaurantDomain4Client: '',
  nextTime2ShowInventoryWarmingPopup: 0,
};

export const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    resetCommonSlice: () => initialState,
    setIsSubdomain: (state, action: PayloadAction<boolean>) => {
      state.isSubdomain = action.payload;
    },
    setSubdomain: (state, action: PayloadAction<string | null>) => {
      state.subdomain = action.payload;
    },
    setRestaurantDomain4Client: (state, action: PayloadAction<string>) => {
      state.restaurantDomain4Client = action.payload;
    },
    setNextTime2ShowInventoryWarmingPopup: (state, action: PayloadAction<number>) => {
      state.nextTime2ShowInventoryWarmingPopup = action.payload;
    },
  },
});

export default commonSlice.reducer;

export const {
  resetCommonSlice,
  setIsSubdomain,
  setSubdomain,
  setRestaurantDomain4Client,
  setNextTime2ShowInventoryWarmingPopup,
} = commonSlice.actions;
