import MBHandsetIcon from '@components/icons/MBHandsetIcon';
import MBLetterIcon from '@components/icons/MBLetterIcon';
import { regExPassword, regExPhone } from '@constants/regexp';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSimpleRegistrationForm from './useSimpleRegistrationForm';

interface SimpleFormFieldType {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  // TODO: remove this if it will be unnecessary
  // package_plan?: PackagePlansType;
  agreed?: string;
}

interface SimpleRegistrationFormProps {
  setRegSuccess: Dispatch<SetStateAction<boolean>>;
}

const SimpleRegistrationForm: FC<SimpleRegistrationFormProps> = ({ setRegSuccess }) => {
  const { t, form, onFinish, onFinishFailed, isLoading, isSuccess } = useSimpleRegistrationForm();

  useEffect(() => {
    if (isSuccess) {
      setRegSuccess(true);
    }
  }, [isLoading]);

  return (
    <Form
      form={form}
      name="register"
      layout="vertical"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('first_name')}
            name="first_name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('first_name_placeholder')} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('last_name')}
            name="last_name"
            rules={[{ required: true, message: t('required_field') }]}
          >
            <Input placeholder={t('last_name_placeholder')} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('email')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('input_errors.email_invalid_format') }]}
          >
            <Input placeholder={'mail@simmmple.com'} suffix={<MBLetterIcon className={'text-grey-20'} />} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('phone')}
            name="phone_number"
            rules={[
              {
                required: true,
                pattern: regExPhone,
                message: t('input_errors.phone_only_numbers'),
              },
              {
                max: 15,
                message: t('input_errors.phone_max'),
              },
            ]}
          >
            <Input placeholder={t('phone_placeholder')} suffix={<MBHandsetIcon className={'text-grey-20'} />} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('password')}
            name="password"
            rules={[{ required: true, pattern: regExPassword, message: t('input_errors.password_validation') }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('password_verify')}
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('required_field'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(t('input_errors.password_not_match')));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>

        {/* TODO: remove this if it will be unnecessary */}
        {/* <Col xs={24} lg={12}>
          <Form.Item<SimpleFormFieldType>
            label={t('package')}
            name={'package_plan'}
            rules={[
              {
                required: true,
                message: t('required_field'),
              },
            ]}
          >
            <Select placeholder={t('select_package')}>
              {PACKAGE_PLANS.map((packagePlan, i) => (
                <Select.Option key={`key-package-plan-${i}`} value={packagePlan}>
                  {t(packagePlan)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
      </Row>

      <Form.Item<SimpleFormFieldType>
        name="agreed"
        valuePropName="checked"
        className={'mb-0 mt-5 pb-4'}
        required
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error(t('input_errors.terms_agree'))),
          },
        ]}
      >
        <Checkbox className={'text-xs leading-[1.5]'}>
          <div className={'pb-2 text-xs'}>
            {t('agree_to_the')} <Link to={'#'}>{t('terms_of_use')}</Link> | <Link to={'#'}>{t('privacy_policy')}</Link>
            <span className={'px-1 text-lg font-bold leading-none text-cyan-30'}>*</span>
          </div>
        </Checkbox>
      </Form.Item>

      <Form.Item className={'mb-0'}>
        <Button type="primary" htmlType="submit" className={'w-full justify-center lg:w-[206px]'} loading={isLoading}>
          {t('signup')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SimpleRegistrationForm;
