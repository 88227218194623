import { useRegisterClientByGoogleMutation } from '@api/clientApi';
import { useAppSelector } from '@base/redux/store';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useGoogleClientRegistrationForm = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const restaurantDomain = useAppSelector((state) => state.commonSlice.restaurantDomain4Client);

  const [registerClient, { isLoading, isSuccess }] = useRegisterClientByGoogleMutation();

  const next = async () => {
    try {
      await form.validateFields();
      setCurrentStep((prev) => prev + 1);
    } catch (errorInfo) {
      console.error(errorInfo);
    }
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const onFinish = async (values: any) => {
    await registerClient(values);
  };

  return {
    t,
    form,
    onFinish,
    currentStep,
    next,
    prev,
    restaurantDomain,
    isLoading,
    isSuccess,
  };
};

export default useGoogleClientRegistrationForm;
