import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Footer from './Footer';
import Header from './Header';

const Layout = () => {
  const { t } = useTranslation();
  const currentLng = i18n.language;

  return (
    <div id={'wrapper'}>
      <div
        style={{
          position: 'fixed',
          width: '205px',
          zIndex: '20',
          bottom: '10px',
          left: '20px',
          padding: '8px',
          backgroundColor: 'rgb(16, 149, 169)',
          borderRadius: '5px',
          opacity: 0,
          pointerEvents: 'none',
        }}
      >
        <LanguageSwitcher />
        <p style={{ color: 'white', textAlign: 'center', margin: '6px 0' }}>
          {t('pages.current_language')}: {currentLng}
        </p>
      </div>
      <div className={'relative flex min-h-screen flex-col overflow-hidden'}>
        <Header />

        <main className={'flex h-full grow flex-col bg-grey-5 main'}>
          <Outlet />
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
