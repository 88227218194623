import {
  useLazyGetClientAccountInfoQuery,
  useLazyGetManagerAccountInfoQuery,
  useLazyGetWorkerAccountInfoQuery,
  useUpdateClientAccountInfoMutation,
  useUpdateManagerAccountInfoMutation,
  useUpdateWorkerAccountInfoMutation,
} from '@api/accauntSettingsApi';
import { useAppSelector } from '@base/redux/store';
import useCheckRole from '@hooks/useCheckRole';
import { App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useAccountInfo = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { message } = App.useApp();
  const { isSupervisor, isWaiter, isClient } = useCheckRole();
  const userID = useAppSelector((state) => state.userState.user?.id);

  const isWorker = isSupervisor || isWaiter;

  const [getAccountInfo, { data: userInfo, isLoading, isError }] = isWorker
    ? useLazyGetWorkerAccountInfoQuery()
    : isClient
    ? useLazyGetClientAccountInfoQuery()
    : useLazyGetManagerAccountInfoQuery();

  const [updateAccountInfo, { isLoading: isUpdating, isSuccess: isSuccessUpdating, isError: isErrorUpdating }] =
    isWorker
      ? useUpdateWorkerAccountInfoMutation()
      : isClient
      ? useUpdateClientAccountInfoMutation()
      : useUpdateManagerAccountInfoMutation();
  const fetchUserInfo = async (id: number) => {
    await getAccountInfo(id);
  };

  const initFormValues = () => {
    if (!userInfo) {
      return;
    }

    form.setFieldsValue({
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      phone_number: userInfo.phone_number,
    });
  };

  const onFinish = async (values: any) => {
    const currentData = {
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      phone_number: userInfo?.phone_number,
    };

    if (!userID || JSON.stringify(currentData) === JSON.stringify(values)) {
      return;
    }

    await updateAccountInfo({
      id: userID,
      data: values,
    });
  };

  useEffect(() => {
    if (userID) {
      fetchUserInfo(userID);
    }
  }, [userID]);

  useEffect(() => {
    if (userInfo) {
      initFormValues();
    }
  }, [userInfo]);

  useEffect(() => {
    if (isUpdating) {
      return;
    }

    if (isSuccessUpdating) {
      message.success(t('updated'));
    }

    if (isErrorUpdating) {
      message.error(t('error'));
    }
  }, [isUpdating, isErrorUpdating, isSuccessUpdating]);

  return { t, form, userInfo, isLoading, isError, onFinish, isUpdating, isGoogleAccount: userInfo?.is_google_account };
};

export default useAccountInfo;
