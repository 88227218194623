import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BluredUnavailableFeature from '../BluredUnavailableFeature/BluredUnavailableFeature';

interface StatisticsDataItem {
  id: number;
  name: string;
  value: number;
}

interface StatisticsListProps {
  data: StatisticsDataItem[];
  limit?: number;
  isUnavailable?: boolean;
}

const StatisticsList: FC<StatisticsListProps> = ({ data, limit, isUnavailable = false }) => {
  const { t } = useTranslation();

  if (limit) {
    data.splice(limit);
  }

  const renderList = data.map(({ id, name, value }) => (
    <div key={id} className={'flex items-center gap-1'}>
      <div className={`flex h-[40px] flex-grow items-center bg-grey-5 p-2 text-sm`}>{name}</div>
      <div className={`flex h-[40px] w-[60px] flex-shrink-0 items-center justify-center bg-grey-5 p-2 text-sm`}>
        {value}
      </div>
    </div>
  ));

  return (
    <div className={isUnavailable ? 'relative min-h-[406px]' : ''}>
      <header className={'mb-2 flex text-sm font-bold text-grey-40'}>
        <div className={'flex-grow'}>{t('name')}</div>
        <div className={'w-[60px]'}>{t('amount')}</div>
      </header>

      {renderList ? <div className={'flex flex-col gap-1'}>{renderList}</div> : <p>{t('no_data')}</p>}
      {isUnavailable && <BluredUnavailableFeature />}
    </div>
  );
};

export default StatisticsList;
